<template>
  <div>
    <el-date-picker
      v-model="validityFrom"
      type="datetime"
      value-format="yyyy-MM-dd HH:mm"
      format="yyyy-MM-dd HH:mm"
      :placeholder="
        item.deliveryValidityFrom == null
          ? 'Select date and time'
          : item.deliveryValidityFrom.toString()
      "
      clearable
      @change="$emit('get-Delivery-ValidityFrom', validityFrom)"
    >
    </el-date-picker>

    <!-- {{ validityFrom }} -->
  </div>
</template>

<script>
  export default {
    name: 'AppDateTimePackerTo',
    props: {
      value: {
        type: [Number, String],
        default: null
      },
      item: {
        type: Object,
        default: () => {}
      }
    },
    data: () => ({
      validityFrom: ''
    }),
    computed: {}
  };
</script>

<style></style>
