<template>
  <BannerList :type="bannerType"></BannerList>
</template>

<script>
  import BannerList from '@/views/components/banner/BannerList.vue';
  import { BannerType } from '@/core/constants/enums';

  export default {
    name: 'BannerHomeList',
    components: {
      BannerList
    },
    data: () => ({
      bannerType: BannerType.HOME
    })
  };
</script>

<style></style>
