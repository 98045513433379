<template>
  <div>
    <h6 class="mb-10 font-weight-bold text-dark text-capitalize">
      {{ $t('label.enterFormDetails', [$t('label.deliveryFees')]) }}
    </h6>
    <template v-for="(i, index) in formFields">
      <div v-if="i.layout" :key="index">
        <label class="font-size-sm text-capitalize"
          >{{ $t(`label.${i.title}`) }}
          <span v-if="i.isRequires" class="text-danger">*</span></label
        >
        <component
          ref="AppDiscount"
          v-model="$v.deliveryFees[i.model].$model"
          :state="validateObjState('deliveryFees', i.model)"
          :is="i.layout"
          :validators="i.validators"
          :validationParams="i.validationParams"
          :validationValue="$v.deliveryFees[i.model]"
          :item="deliveryFees"
          :options="i.options"
          @input="input()"
        ></component>
      </div>
      <AppFormField
        :key="index"
        v-else
        v-model="$v.deliveryFees[i.model].$model"
        :title="$t(`label.${i.title}`)"
        :formType="i.formType"
        :state="validateObjState('deliveryFees', i.model)"
        :validators="i.validators"
        :validationValue="$v.deliveryFees[i.model]"
        :validationParams="i.validationParams"
        :isRequires="i.isRequires"
        @input="input()"
        solid
      >
      </AppFormField>
    </template>
    <div style="display: none">
      <!-- WARNING: I have no idea why this line is here, but removing it breaks everything -->
      {{ $v }}
    </div>
    <b-row class="justify-content-between" no-gutters>
      <button
        v-if="!isCreate"
        :disabled="
          !checkAccessRight(moduleName, [permissionAction.EQUIPMENTS_UPDATE])
        "
        @click="initEquipmentDeliveryFees()"
        class="btn btn-secondary font-weight-bold text-uppercase"
      >
        {{ $t(`label.reset`) }}
      </button>
      <button
        v-if="!isCreate"
        :disabled="
          !checkAccessRight(moduleName, [permissionAction.EQUIPMENTS_UPDATE])
        "
        @click="updateEquipmentDeliveryFeesAction(id, deliveryFees)"
        class="btn btn-success font-weight-bold text-uppercase"
      >
        {{ $t(`label.${isCreate ? 'create' : 'update'}`) }}
      </button>
    </b-row>
  </div>
</template>

<script>
  import AppFormField from '@/views/components/bases/form-field/AppFormField';
  import AppAmountType from '@/views/components/bases/AppAmountType';
  import commonMixin from '@/core/mixins/common.mixin';
  import { GLOBAL, MODULE, ACTION, ROUTE_NAME, price } from '@/core/constants';
  import { required } from 'vuelidate/lib/validators';
  import { i18nHelper } from '@/core/utils';
  import { listService } from '@/core/services';

  import {
    EQUIPMENT_GET_EQUIPMENT_DELIVERY_FEES,
    EQUIPMENT_UPDATE_EQUIPMENT_DELIVERY_FEES,
    EQUIPMENT_INITIAL_GET_EQUIPMENT_DELIVERY_FEES_STATE,
    EQUIPMENT_INITIAL_UPDATE_EQUIPMENT_DELIVERY_FEES_STATE
  } from '@/core/store/equipment.module';

  export default {
    name: 'EquipmentDeliveryFees',
    mixins: [commonMixin],
    components: {
      AppFormField,
      AppAmountType
    },
    props: {
      id: {
        Type: String,
        default: ''
      },
      equipmentInfo: {
        Type: Object,
        default: {}
      },
      isCreate: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      global: GLOBAL,
      moduleName: MODULE.EQUIPMENTS,
      permissionAction: ACTION,
      routeName: ROUTE_NAME,
      deliveryFees: {
        deliveryFees: null,
        discountValueType: {
          type: null,
          value: null
        }
      },
      formFields: []
    }),

    mounted() {
      this.initEquipmentDeliveryFees();
    },
    computed: {
      getEquipmentDeliveryFeesComplete() {
        return this.$store.state.equipment.equipmentDeliveryFees.complete;
      },
      updateEquipmentDeliveryFeesComplete() {
        return this.$store.state.equipment.updateEquipmentDeliveryFees.complete;
      }
    },
    watch: {
      getEquipmentDeliveryFeesComplete() {
        let response = this.$store.state.equipment.equipmentDeliveryFees;
        if (response.complete) {
          this.equipmentCompleteAction(response, 'get');
        }
      },
      updateEquipmentDeliveryFeesComplete() {
        let response = this.$store.state.equipment.updateEquipmentDeliveryFees;
        if (response.complete) {
          this.equipmentCompleteAction(response, 'update');
        }
      }
    },
    methods: {
      equipmentCompleteAction(response, actionType) {
        let title = i18nHelper.getMessage('label.equipment');
        let messages = response.message;
        let data = response.data;
        let buttons = [];
        switch (actionType) {
          case 'get':
            if (data) this.deliveryFees = data;
            this.initialGetEquipmentDeliveryFees();
            break;
          case 'update':
            this.initialUploadEquipmentDeliveryFees();
            break;
        }

        if (response.code == 0) {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              if (this.isCreate) {
                this.$router.push({
                  name: ROUTE_NAME.EQUIPMENT_DETAILS,
                  params: { id: data.id }
                });
              } else {
                this.$parent.getEquipment(this.id);
              }
              this.getEquipmentDeliveryFees();
              this.closeLayoutAppModalInfo();
              this.resetForm();
            }
          });
        } else {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();
            }
          });
        }
        if (messages) this.openLayoutAppModalInfo(title, messages, buttons);
      },
      validationCheckEquipmentDeliveryFees() {
        return (
          this.$refs.AppDiscount[0].validationCheckDiscountFeild() &&
          this.validationCheck('deliveryFees')
        );
      },
      updateEquipmentDeliveryFeesAction(id, data) {
        if (this.validationCheckEquipmentDeliveryFees()) {
          this.updateEquipmentDeliveryFees(id, data);
        }
      },
      initFormFields() {
        this.formFields = [];
        this.formFields.push(
          {
            model: 'deliveryFees',
            title: 'deliveryFees',
            validators: ['required', 'price'],
            isRequires: true,
            validationParams: []
          },
          {
            title: 'discount',
            model: 'discountValueType',
            layout: 'AppAmountType',
            options: listService.getAmoutTypeList(false),
            validationParams: []
          }
        );
        this.$v.deliveryFees.$reset();
      },
      resetForm() {
        this.$v.$reset();
        this.deliveryFees = {
          deliveryFees: null,
          discountValueType: null
        };
        this.initFormFields();
      },
      input() {
        this.$emit('input', this.deliveryFees);
      },
      initEquipmentDeliveryFees() {
        this.initFormFields();
        if (!this.isCreate) this.getEquipmentDeliveryFees();
      },
      getEquipmentDeliveryFees() {
        let id = this.id;
        this.$store.dispatch(EQUIPMENT_GET_EQUIPMENT_DELIVERY_FEES, {
          id
        });
      },
      updateEquipmentDeliveryFees(id, data) {
        this.$store.dispatch(EQUIPMENT_UPDATE_EQUIPMENT_DELIVERY_FEES, {
          id,
          data
        });
      },
      initialGetEquipmentDeliveryFees() {
        this.$store.dispatch(
          EQUIPMENT_INITIAL_GET_EQUIPMENT_DELIVERY_FEES_STATE
        );
      },
      initialUploadEquipmentDeliveryFees() {
        this.$store.dispatch(
          EQUIPMENT_INITIAL_UPDATE_EQUIPMENT_DELIVERY_FEES_STATE
        );
      }
    },
    validations() {
      return {
        deliveryFees: {
          deliveryFees: {
            required,
            price
          },
          discountValueType: {
            required
          }
        }
      };
    }
  };
</script>

<style></style>
