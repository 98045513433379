<template>
  <div>
    <router-link
      v-if="value"
      :to="{ name: config.routeName, query: { itemName: config.itemName } }"
      class="text-hover-decoration-underline"
    >
      {{ value }}
    </router-link>
    <div v-else>{{ '-' }}</div>
  </div>
</template>

<script>
  export default {
    name: 'AppAverageRating',
    props: {
      value: {
        type: Number,
        default: null
      },
      config: {
        type: Object,
        default: () => {
          return {
            routeName: '',
            itemName: ''
          };
        }
      }
    }
  };
</script>

<style></style>
