<template>
  <div>
    <AppSmartList
      ref="app-smart-list"
      :breadcrumbData="breadcrumbData"
      :accessRights="accessRights"
      :filters="filters"
      :actions="actions"
      :tableHeaders="headers"
      :newRecord="newRecord"
    >
    </AppSmartList>
  </div>
</template>

<script>
  import AppSmartList from '@/views/components/bases/smart/AppSmartList.vue';
  // import AppExportExcel from '@/views/components/bases/AppExportExcel.vue';
  import { i18nHelper } from '@/core/utils';
  // import { listService } from '@/core/services';
  import {
    ROUTE_NAME,
    MODULE,
    ACTION,
    APP_COMPONENT_NAME
    // SMART_FORM_FIELD_TYPE
  } from '@/core/constants';
  import {
    EQUIPMENT_GET_EQUIPMENTS,
    EQUIPMENT_INITIAL_GET_EQUIPMENTS_STATE
  } from '@/core/store/equipment.module';

  export default {
    name: 'EquipmentList',
    components: {
      AppSmartList
      // AppExportExcel
    },
    data: () => ({
      breadcrumbData: {
        breadcrumbs: [
          {
            title: i18nHelper.getMessage('label.equipment'),
            route: { name: ROUTE_NAME.EQUIPMENT }
          },
          { title: i18nHelper.getMessage('label.equipmentList') }
        ]
      },
      accessRights: {
        moduleName: MODULE.EQUIPMENTS,
        actions: {
          read: ACTION.EQUIPMENTS_READ,
          update: ACTION.EQUIPMENTS_UPDATE,
          create: ACTION.EQUIPMENTS_CREATE
        }
      },
      filters: [
        {
          label: 'equipmentName',
          key: 'name',
          value: ''
        },
        {
          label: 'brandName',
          key: 'brandName',
          value: ''
        },
        {
          label: 'id',
          key: 'id',
          value: ''
        }
      ],
      actions: {
        viewList: {
          module: 'equipment',
          state: 'equipments',
          action: EQUIPMENT_GET_EQUIPMENTS,
          initialStateAction: EQUIPMENT_INITIAL_GET_EQUIPMENTS_STATE,
          title: i18nHelper.getMessage('label.getEquipment')
        }
      },
      headers: [
        {
          key: 'no',
          name: 'no',
          active: true,
          sortable: true
        },
        {
          key: 'id',
          name: 'id',
          active: true,
          sortable: true
        },
        {
          key: 'brandName',
          name: 'brandName',
          active: true,
          sortable: true,
          class: 'py-2'
        },
        {
          key: 'equipmentName',
          name: 'equipmentName',
          active: true,
          sortable: true,
          class: 'py-2'
        },
        {
          key: 'status',
          name: 'status',
          active: true,
          sortable: true,
          layout: APP_COMPONENT_NAME.APP_STATUS_LABEL
        },
        {
          key: 'sellingPrice',
          name: 'sellingPrice',
          active: true,
          sortable: true
        },
        {
          key: 'promotionPrice',
          name: 'promotionPrice',
          active: true,
          sortable: true
        },
        {
          key: 'deliveryFees',
          name: 'deliveryFees',
          active: true,
          sortable: true
        },
        {
          key: 'actions',
          name: 'actions',
          active: true,
          action: true,
          layout: APP_COMPONENT_NAME.APP_TABLE_ACTIONS,
          layoutActions: {
            viewDetailsRouteName: ROUTE_NAME.EQUIPMENT_DETAILS
          }
        }
      ],
      newRecord: {
        show: true,
        buttonTitle: i18nHelper.getMessage('label.createEquipment'),
        routeName: ROUTE_NAME.EQUIPMENT_CREATE
      }
    }),
    computed: {},
    watch: {},
    methods: {}
  };
</script>

<style></style>
