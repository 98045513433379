import { uiHelper } from '@/core/utils';

/* Define Module Name */
const MODULE_NAME = 'loading/';

/* Define Action Name */
const A_LOADING = 'loading';
const A_UNLOADING = 'unloading';
const A_MESSAGE = 'message';

/* Define Getter Name */

/* Define Mutation Name */
const M_LOADING = 'setLoading';
const M_UNLOADING = 'setUnloading';
const M_MESSAGE = 'setMessage';

/* Define Export Name */
export const LOADING_OPEN_LOADING = MODULE_NAME + A_LOADING;
export const LOADING_CLOSE_LOADING = MODULE_NAME + A_UNLOADING;
export const SHOW_MESSAGE = MODULE_NAME + A_MESSAGE;

const state = {
  isLoading: false
};

//Getters
const getters = {};

// Actions
const actions = {
  [A_LOADING]({ commit }) {
    commit(M_LOADING);
  },
  [A_UNLOADING]({ commit }) {
    commit(M_UNLOADING);
  },
  [A_MESSAGE]({ commit }) {
    commit(M_MESSAGE);
  }
};

// Mutations
const mutations = {
  [M_LOADING](state) {
    state.isLoading = true;
    uiHelper.freezeBodyOverflowY();
  },
  [M_UNLOADING](state) {
    state.isLoading = false;
    uiHelper.unFreezeBodyOverflowY();
  },
  [M_MESSAGE](state) {
    state.isLoading = true;
    uiHelper.unFreezeBodyOverflowY();
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
