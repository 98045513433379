import { PRODUCT } from '@/core/api';
import { apiHelper } from '@/core/utils';
import { productService } from '@/core/services';
import {
  LOADING_OPEN_LOADING,
  LOADING_CLOSE_LOADING
} from '@/core/store/loading.module';

/* Define Module Name */
const MODULE_NAME = 'product/';

/* Define Action Name */
const A_GET_PRODUCTS = 'getProducts';
const A_GET_PRODUCT = 'getProduct';
const A_SET_VISIBILITY = 'setVisibility';
const A_UPDATE_PRODUCT = 'updateProduct';

/* Define Reset State Action Name */
const A_INITIAL_GET_PRODUCTS_STATE = 'initialGetProductsState';
const A_INITIAL_GET_PRODUCT_STATE = 'initialGetProductState';
const A_INITIAL_SET_VISIBILITY_STATE = 'initialSetVisibilityState';
const A_INITIAL_UPDATE_PRODUCT = 'initialUpdateProduct';

/* Define Getter Name */

/* Define Mutation Name */
const M_GET_PRODUCTS = 'setGetProducts';
const M_GET_PRODUCT = 'setGetProduct';
const M_SET_VISIBILITY = 'setSetVisibility';
const M_UPDATE_PRODUCT = 'setUpdateProduct';

/* Define Reset State Mutation Name */
const M_INITIAL_GET_PRODUCTS_STATE = 'setInitialGetProductsState';
const M_INITIAL_GET_PRODUCT_STATE = 'setInitialGetProductState';
const M_INITIAL_SET_VISIBILITY_STATE = 'setInitialSetVisibilityState';
const M_INITIAL_UPDATE_PRODUCT = 'setInitialUpdateProductState';

/* Define Export Name */
export const PRODUCT_GET_PRODUCTS = MODULE_NAME + A_GET_PRODUCTS;
export const PRODUCT_GET_PRODUCT = MODULE_NAME + A_GET_PRODUCT;
export const PRODUCT_SET_VISIBILITY = MODULE_NAME + A_SET_VISIBILITY;
export const PRODUCT_UPDATE_PRODUCT = MODULE_NAME + A_UPDATE_PRODUCT;

/* Define Reset State Name */
export const PRODUCT_INITIAL_GET_PRODUCTS_STATE =
  MODULE_NAME + A_INITIAL_GET_PRODUCTS_STATE;
export const PRODUCT_INITIAL_GET_PRODUCT_STATE =
  MODULE_NAME + A_INITIAL_GET_PRODUCT_STATE;
export const PRODUCT_INITIAL_SET_VISIBILITY_STATE =
  MODULE_NAME + A_INITIAL_SET_VISIBILITY_STATE;
export const PRODUCT_INITIAL_UPDATE_PRODUCT_STATE =
  MODULE_NAME + A_INITIAL_UPDATE_PRODUCT;

const state = {
  products: {
    code: 0,
    complete: false,
    data: null,
    message: null,
    pagination: {
      currentPage: 0,
      total: 0,
      perPage: 0,
      lastPage: 0
    }
  },
  product: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  setVisibility: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  updateProduct: {
    code: 0,
    complete: false,
    data: null,
    message: null
  }
};

//Getters
const getters = {};

// Actions
const actions = {
  [A_GET_PRODUCTS]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    PRODUCT.getProducts(data)
      .then((response) => {
        result = response;
        commit(M_GET_PRODUCTS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_PRODUCTS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_PRODUCT]({ dispatch, commit }, { id }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    PRODUCT.getProduct(id)
      .then((response) => {
        result = response;
        commit(M_GET_PRODUCT, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_PRODUCT, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_SET_VISIBILITY]({ dispatch, commit }, { id, data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    PRODUCT.setVisibility(id, data)
      .then((response) => {
        result = response;
        commit(M_SET_VISIBILITY, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_SET_VISIBILITY, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_UPDATE_PRODUCT]({ dispatch, commit }, { id, data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    PRODUCT.updateProduct(id, data)
      .then((response) => {
        result = response;
        commit(M_UPDATE_PRODUCT, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_UPDATE_PRODUCT, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_INITIAL_GET_PRODUCTS_STATE]({ commit }) {
    commit(M_INITIAL_GET_PRODUCTS_STATE);
  },
  [A_INITIAL_GET_PRODUCT_STATE]({ commit }) {
    commit(M_INITIAL_GET_PRODUCT_STATE);
  },
  [A_INITIAL_SET_VISIBILITY_STATE]({ commit }) {
    commit(M_INITIAL_SET_VISIBILITY_STATE);
  },
  [A_INITIAL_UPDATE_PRODUCT]({ commit }) {
    commit(M_INITIAL_UPDATE_PRODUCT);
  }
};

// Mutations
const mutations = {
  [M_GET_PRODUCTS](state, result) {
    if (result.code === 0) {
      let d = result.data;
      let p = result.pagination;

      state.products = {
        code: result.code,
        complete: true,
        data: d.map((x, index) => {
          return productService.productDataMapping(x, index);
        }),
        message: null,
        pagination: {
          currentPage: p.current_page,
          total: p.total,
          perPage: p.per_page,
          lastPage: p.last_page
        }
      };
    } else {
      state.products = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message),
        pagination: {
          currentPage: 0,
          total: 0,
          perPage: 0,
          lastPage: 0
        }
      };
    }
  },
  [M_GET_PRODUCT](state, result) {
    if (result.code === 0) {
      state.product = {
        code: result.code,
        complete: true,
        data: productService.productDataMapping(result.data),
        message: null
      };
    } else {
      state.product = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_SET_VISIBILITY](state, result) {
    state.setVisibility = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_UPDATE_PRODUCT](state, result) {
    state.updateProduct = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_INITIAL_GET_PRODUCTS_STATE](state) {
    state.products = {
      code: 0,
      complete: false,
      data: null,
      message: null,
      pagination: {
        currentPage: 0,
        total: 0,
        perPage: 0,
        lastPage: 0
      }
    };
  },
  [M_INITIAL_GET_PRODUCT_STATE](state) {
    state.product = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_SET_VISIBILITY_STATE](state) {
    state.setVisibility = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_UPDATE_PRODUCT](state) {
    state.updateProduct = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
