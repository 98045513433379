import { apiService } from '@/core/services';

export const LOOKUP = {
  getUserPermissions,
  getAppUserRecipes,
  getNotification
};

const URL = {
  ADMIN: 'admin',
  LOOKUP: 'lookup',
  PERMISSIONS: 'permissions',
  RECIPES: 'recipes',
  NOTIFICATION: 'notification'
};

function getUserPermissions() {
  return apiService().get(`/${URL.ADMIN}/${URL.LOOKUP}/${URL.PERMISSIONS}`);
}

function getAppUserRecipes(data) {
  let requestOptions = {
    params: {
      id: data.id,
      name: data.name,
      is_verified: data.isVerified
    }
  };
  return apiService().get(
    `/${URL.ADMIN}/${URL.LOOKUP}/${URL.RECIPES}`,
    requestOptions
  );
}

function getNotification() {
  return apiService(null, true, false, false).get(
    `/${URL.ADMIN}/${URL.LOOKUP}/${URL.NOTIFICATION}`
  );
}
