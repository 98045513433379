<template>
  <div>
    <AppSmartList
      :breadcrumbData="breadcrumbData"
      :accessRights="accessRights"
      :filters="filters"
      :actions="actions"
      :tableHeaders="headers"
      :newRecord="newRecord"
      @app-smart-list-action-download="actionDownload"
    ></AppSmartList>
  </div>
</template>

<script>
  import commonMixin from '@/core/mixins/common.mixin';
  import AppSmartList from '@/views/components/bases/smart/AppSmartList.vue';
  import { i18nHelper, uiHelper } from '@/core/utils';
  import {
    LOCAL,
    ROUTE_NAME,
    MODULE,
    ACTION,
    APP_COMPONENT_NAME,
    SMART_FORM_FIELD_TYPE
  } from '@/core/constants';
  import { listService } from '@/core/services';
  import {
    VOUCHER_GET_VOUCHERS,
    VOUCHER_INITIAL_GET_VOUCHERS_STATE
  } from '@/core/store/voucher.module';
  import { apiService } from '@/core/services';
  import {
    LOADING_OPEN_LOADING,
    LOADING_CLOSE_LOADING
  } from '@/core/store/loading.module';

  const CONSTANTS_DATA = JSON.parse(uiHelper.getLocalStorage(LOCAL.CONSTANTS));

  export default {
    name: 'VoucherList',
    components: {
      AppSmartList
    },
    mixins: [commonMixin],
    data: () => ({
      breadcrumbData: {
        breadcrumbs: [
          {
            title: i18nHelper.getMessage('label.voucher'),
            route: { name: ROUTE_NAME.VOUCHER }
          },
          { title: i18nHelper.getMessage('label.voucherList') }
        ]
      },
      accessRights: {
        moduleName: MODULE.BANNER,
        actions: {
          create: ACTION.BANNER_CREATE,
          read: ACTION.BANNER_READ,
          update: ACTION.BANNER_UPDATE,
          delete: ACTION.BANNER_DELETE
        }
      },
      filters: [
        {
          label: 'voucherTitle',
          key: 'voucherTitle',
          value: ''
        },
        {
          label: 'id',
          key: 'id',
          value: ''
        },

        {
          label: 'status',
          key: 'status',
          value: '',
          options: listService.getVoucherStatusList(false),
          optionLabel: 'text',
          type: SMART_FORM_FIELD_TYPE.SELECT
        },
        {
          label: 'applyTo',
          key: 'applyTo',
          value: '',
          options: listService.getDynamicList(CONSTANTS_DATA.voucherApplyTo),
          optionLabel: 'text',
          type: SMART_FORM_FIELD_TYPE.SELECT
        }
      ],
      actions: {
        viewList: {
          module: 'voucher',
          state: 'vouchers',
          action: VOUCHER_GET_VOUCHERS,
          initialStateAction: VOUCHER_INITIAL_GET_VOUCHERS_STATE,
          title: i18nHelper.getMessage('label.getVoucher')
        }
      },
      headers: [
        {
          key: 'no',
          name: 'no',
          active: true,
          sortable: true
        },
        {
          key: 'id',
          name: 'id',
          active: true,
          sortable: true
        },
        {
          key: 'voucherTitle',
          name: 'voucherTitle',
          active: true,
          sortable: true,
          class: 'min-w-130px'
        },
        {
          key: 'applyTo',
          name: 'applyTo',
          active: true,
          sortable: true,
          class: 'min-w-130px'
        },
        {
          key: 'voucherType',
          name: 'voucherType',
          active: true,
          sortable: true
        },
        {
          key: 'quantity',
          name: 'redemptionQuantity',
          active: true,
          sortable: true
        },
        {
          key: 'minimumSpending',
          name: 'minimumSpending',
          active: true,
          sortable: true
        },
        {
          key: 'totalRedemption',
          name: 'totalRedemption',
          active: true,
          sortable: true
        },
        {
          key: 'totalClaim',
          name: 'totalClaim',
          active: true,
          sortable: true
        },
        {
          key: 'status',
          name: 'status',
          active: true,
          sortable: true,
          layout: 'VoucherStatusLabel'
        },
        {
          key: 'availableFrom',
          name: 'availableFrom',
          active: true,
          sortable: true
        },
        {
          key: 'availableTo',
          name: 'availableTo',
          active: true,
          sortable: true
        },
        {
          key: 'edit',
          name: 'edit',
          active: true,
          action: true,
          layout: APP_COMPONENT_NAME.APP_TABLE_ACTIONS,
          layoutActions: {
            viewDetailsRouteName: ROUTE_NAME.VOUCHER_DETAILS
          }
        },
        {
          key: 'export',
          name: 'export',
          active: true,
          action: true,
          layout: APP_COMPONENT_NAME.APP_TABLE_ACTIONS,
          layoutActions: {
            isDownload: true
          }
        }
      ],
      merchantVoucherDetailsModalObj: {
        isShow: false,
        id: 0
      },
      newRecord: {
        show: true,
        buttonTitle: i18nHelper.getMessage('label.createVoucher'),
        routeName: ROUTE_NAME.VOUCHER_CREATE
      }
    }),
    methods: {
      actionDownload(data) {
        let id = data.id;
        this.$store.dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
        apiService()
          .get(`/admin/merchant-vouchers/${id}/export-users`, {
            responseType: 'blob'
          })
          .then((response) => {
            this.disabled = false;
            this.$store.dispatch(`${LOADING_CLOSE_LOADING}`, null, {
              root: true
            });
            if (response.type == 'application/json') {
              apiService()
                .get(`/admin/merchant-vouchers/${id}/export-users`, {})
                .then((response) => {
                  let title = i18nHelper.getMessage(
                    'label.getReferralCodeDetails'
                  );
                  response = {
                    ...response,
                    message: [response.message]
                  };
                  this.openErrorLayoutAppModalInfo(title, response);
                });
            } else {
              const url = window.URL.createObjectURL(new Blob([response]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', `${id}_claimed_list.xlsx`);
              document.body.appendChild(link);
              link.click();
            }
          });
      }
    }
  };
</script>

<style></style>
