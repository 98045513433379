<template>
  <b-card data-style="custom">
    <div class="d-flex justify-content-between">
      <b-card-title class="text-capitalize">
        {{ title }}
      </b-card-title>

      <ApplicationUploadDocumentDropdown
        v-if="!noUpload"
        :id="id"
        :creatorType="creatorApplicationObj.creatorType"
        :disabled="actionDisabled"
        @complete="uploadDocumentCompleteAction"
      ></ApplicationUploadDocumentDropdown>
    </div>

    <b-row v-if="value.length > 0">
      <template v-for="(items, index) in value">
        <b-col :key="index" cols="6" md="4" xl="3" class="mt-4">
          <b-card
            no-body
            class="pointer h-100 shadow-sm card-custom rounded-0 overlay"
          >
            <div class="card-body p-0">
              <div class="overlay-wrapper">
                <div
                  :class="
                    `${isImage(items.fileType) ? 'overflow-hidden' : 'p-3'}`
                  "
                >
                  <div
                    class="h-100px h-sm-150px h-lg-150px h-xl-200px"
                    :class="
                      `${
                        isImage(items.fileType) ? 'document-image' : 'document'
                      }`
                    "
                    :style="
                      `background-image: url('${attachmentImage(
                        items.fileType,
                        items.originalUrl
                      )}');`
                    "
                  ></div>
                </div>
                <label
                  class="
                          pt-3
                          px-4
                          d-block
                          pointer
                          text-capitalize
                          font-weight-bold
                        "
                  >{{ items.documentTypeName }}</label
                >
              </div>
              <div class="overlay-layer">
                <div class="text-center">
                  <AppButtonDocumentView
                    :title="items.documentTypeName"
                    :attachmentUrl="items.originalUrl"
                    :fileType="items.fileType"
                    :buttonType="2"
                  ></AppButtonDocumentView>
                </div>
              </div>
            </div>
          </b-card>
        </b-col>
      </template>
    </b-row>
    <span v-else>{{ $t('label.thereAreNoDocumentsToShow') }}</span>
  </b-card>
</template>

<script>
  import AppButtonDocumentView from '@/views/components/bases/button/AppButtonDocumentView';
  import ApplicationUploadDocumentDropdown from '@/views/components/application/ApplicationUploadDocumentDropdown';

  export default {
    name: 'ApplicationAttachmentCard',
    components: {
      AppButtonDocumentView,
      ApplicationUploadDocumentDropdown
    },
    props: {
      id: {
        type: Number,
        default: null,
        required: true
      },
      value: {
        type: Array,
        default: () => [],
        required: true
      },
      title: {
        type: String,
        default: ''
      },
      noUpload: {
        type: Boolean,
        default: false
      },
      creatorApplicationObj: {
        type: Object,
        default: () => ({})
      },
      actionDisabled: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      uploadDocumentCompleteAction() {
        this.$parent.getCreatorApplication(this.id);
      },
      isImage(type) {
        let isImage = false;

        if (type == 'jpg' || type == 'png' || type == 'jpeg') {
          isImage = true;
        }

        return isImage;
      },
      attachmentImage(type, originalUrl) {
        let src = '';

        if (type == 'jpg' || type == 'png' || type == 'jpeg') {
          src = originalUrl;
        } else if (type == 'pdf') {
          src = '/media/svg/files/pdf.svg';
        } else if (type == 'docx' || type == 'doc') {
          src = '/media/svg/files/doc.svg';
        }
        return src;
      }
    }
  };
</script>

<style lang="scss">
  .document {
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .document-image {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }
</style>
