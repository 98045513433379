<template>
  <div>
    <!-- begin: User Claimed Table -->
    <div class="d-flex justify-content-end pb-5">
      <AppExportExcel :exportExcelObj="exportExcel"></AppExportExcel>
    </div>
    <AppSmartTable
      stickyHeader
      :stacked="false"
      :items="User.items"
      :headers="User.header"
      :pagination="User.pagination"
      @app-smart-table-pagination-change="actionPaginationChange"
    ></AppSmartTable>
    <!-- end: User Claimed Table -->
  </div>
</template>

<script>
  import userMixin from '@/core/mixins/user.mixin';
  import AppSmartTable from '@/views/components/bases/smart/AppSmartTable';
  import AppExportExcel from '@/views/components/bases/AppExportExcel.vue';
  import { ROUTE_NAME } from '@/core/constants';
  import { i18nHelper } from '@/core/utils';
  import commonMixin from '@/core/mixins/common.mixin';
  import {
    VOUCHER_GET_VOUCHER_CLAIMED_USERS,
    VOUCHER_INITIAL_GET_VOUCHER_CLAIMED_USERS_STATE
  } from '@/core/store/voucher.module';

  export default {
    name: 'VoucherClaimedList',
    components: {
      AppSmartTable,
      AppExportExcel
    },
    mixins: [commonMixin, userMixin],
    props: {
      id: {
        Type: Number,
        default: null
      },
      voucherObj: {
        type: Object,
        default: () => ({})
      }
    },
    data: () => ({
      routeName: ROUTE_NAME,
      searchCriteriaListDetails: {
        perPage: 10,
        page: 1
      },
      User: {
        header: [
          {
            key: 'no',
            name: 'no',
            sortable: true
          },
          {
            key: 'username',
            name: 'username',
            sortable: true,
            layout: 'AppFeatureDetailsRelatedButton',
            config: {
              to: ROUTE_NAME.PROFILE_DETAILS_DETAILS
            }
          },
          {
            key: 'voucherName',
            name: 'voucherName',
            headersClass: 'min-w-130px'
          },
          {
            key: 'voucherCode',
            name: 'voucherCode'
          },
          {
            key: 'redemptionAt',
            name: 'voucherRedeemedDate',
            sortable: true
          }
        ],
        items: [],
        pagination: {
          currentPage: 1,
          total: 1,
          perPage: 10,
          lastPage: 1
        }
      },
      disabled: false
    }),
    computed: {
      voucherClaimedUsersComplete() {
        return this.$store.state.voucher.voucherClaimedUsers.complete;
      },
      route() {
        return this.$route;
      },
      exportExcel() {
        let exportExcel = {
          disabled: false,
          fileName: `${this.id}_claimed_list`,
          api: `admin/merchant-vouchers/${this.id}/export-users`
        };
        return exportExcel;
      }
    },
    watch: {
      id() {
        if (this.id) {
          this.getListDetails(this.id, this.searchCriteriaReferralCodeReferees);
        }
      },
      voucherClaimedUsersComplete() {
        let response = this.$store.state.voucher.voucherClaimedUsers;
        let title = i18nHelper.getMessage('label.getClaimedUser');
        let initialStateAction =
          VOUCHER_INITIAL_GET_VOUCHER_CLAIMED_USERS_STATE;
        let successAction = () => {
          this.importListDetails(response);
        };

        if (response.complete) {
          this.actionReadRecordComplete(
            response,
            title,
            initialStateAction,
            successAction
          );
        }
      }
    },
    mounted() {
      this.getListDetails(this.id, this.searchCriteriaListDetails);
    },
    methods: {
      importListDetails(response) {
        this.User.items = response.data;
        this.User.pagination = response.pagination;
      },
      actionPaginationChange(value) {
        this.searchCriteriaListDetails = {
          perPage: value.size,
          page: value.page
        };

        this.getListDetails(this.id, this.searchCriteriaListDetails);
      },
      getListDetails(id, data) {
        this.$store.dispatch(VOUCHER_GET_VOUCHER_CLAIMED_USERS, {
          id,
          data
        });
      }
    }
  };
</script>

<style></style>
