<template>
  <b-card no-body data-style="custom">
    <!--begin: Wizard-->
    <div
      class="wizard wizard-2"
      id="referralCodeWizard"
      data-wizard-state="step-first"
      data-wizard-clickable="true"
    >
      <!--begin: Wizard Nav -->
      <div class="wizard-nav border-right py-8 px-8 py-lg-20 px-lg-10">
        <div class="wizard-steps">
          <!--begin: Wizard Steps 1 -->
          <div
            class="wizard-step"
            data-wizard-type="step"
            data-wizard-state="current"
          >
            <div class="wizard-wrapper">
              <div class="wizard-icon">
                <span class="svg-icon svg-icon-2x">
                  <inline-svg
                    src="/media/svg/icons/Communication/Clipboard-list.svg"
                  />
                </span>
              </div>
              <div class="wizard-label text-capitalize">
                <h3 class="wizard-title">
                  {{ $t('label.referralSetting') }}
                </h3>
                <div class="wizard-desc">
                  {{ $t('label.fillInInfoAndAction') }}
                </div>
              </div>
            </div>
          </div>
          <!--end: Wizard Steps 1 -->
          <!--begin: Wizard Steps 2 -->
          <div class="wizard-step" data-wizard-type="step" data-wizard-state="">
            <div class="wizard-wrapper" v-if="!isCreate">
              <div class="wizard-icon">
                <span class="svg-icon svg-icon-2x">
                  <inline-svg
                    src="/media/svg/icons/Communication/Clipboard-check.svg"
                  />
                </span>
              </div>
              <div
                class="wizard-label text-capitalize"
                :class="{
                  'text-primary cursor-pointer': referralCodeObj.refereesCount
                }"
              >
                <h3 class="wizard-title">
                  {{
                    `${referralCodeObj.refereesCount} ` + $t(`label.referees`)
                  }}
                </h3>
                <div class="wizard-desc">
                  {{ $t('label.refereesTable') }}
                </div>
              </div>
            </div>
          </div>
          <!--end: Wizard Steps 2 -->
        </div>
      </div>
      <!--end: Wizard Nav -->

      <!--begin: Wizard Body -->
      <div class="wizard-body py-6 px-8" style="overflow-x: auto; width: 100%">
        <!--begin: Wizard Form-->
        <b-row>
          <b-col offset-xl="2" xl="8">
            <!--begin: Wizard Step 1-->
            <div data-wizard-type="step-content" data-wizard-state="current">
              <div class="d-flex justify-content-end">
                <ReferralCodeStatusLabel
                  v-if="!isCreate"
                  :styleName="'font-size: 14px; padding: 15px; '"
                  :value="referralCodeObj.status"
                ></ReferralCodeStatusLabel>
              </div>

              <b-form class="form" id="kt_form">
                <b-row no-gutters>
                  <template
                    v-for="(i, index) in formFields.filter((x) => x.field == 1)"
                  >
                    <b-col :key="index" xl="6" class="p-xl-1">
                      <div
                        v-if="index == 0 || index == 4"
                        class="w-100 pt-5 font-weight-bolder text-capitalize"
                      >
                        <h6>
                          {{
                            index == 0
                              ? $t(`label.referralSetting`)
                              : $t(`label.rewardSetting`)
                          }}
                        </h6>
                        <hr width="100%" />
                      </div>
                      <div v-if="i.layout">
                        <label class="font-size-sm text-capitalize"
                          >{{ $t(`label.${i.title}`) }}
                          <span v-if="i.isRequires" class="text-danger"
                            >*</span
                          ></label
                        >
                        <component
                          v-model="referralCodeObj[i.model]"
                          :is="i.layout"
                          :validators="i.validators"
                          :validationParams="i.validationParams"
                          :validationValue="$v.referralCodeObj[i.model]"
                          :state="validateObjState('referralCodeObj', i.model)"
                          :item="referralCodeObj"
                          :value="referralCodeObj[i.model]"
                          :modelName="i.model"
                          :options="i.options"
                          :optionsValue="i.optionsValue"
                          :attachmentUrl="referralCodeObj[i.model]"
                          :isCreate="isCreate"
                          :disabled="i.disabled"
                          :fileType="i.fileType"
                          :hasReplace="true"
                          :solid="!i.disabled"
                          :accept="i.accept"
                          :config="i.config"
                          @replace-action="replaceAction"
                        >
                        </component>
                      </div>
                      <div v-else-if="i.model == 'pinVerification'">
                        <AppFormField
                          v-if="
                            referralCodeObj.voucherType !=
                            ReferralCodeVoucherType.DELIVERY_OR_PICKUP
                          "
                          v-model="$v.referralCodeObj[i.model].$model"
                          :title="$t(`label.${i.title}`)"
                          :state="validateObjState('referralCodeObj', i.model)"
                          :validationValue="$v.referralCodeObj[i.model]"
                          :type="i.type"
                          :validators="i.validators"
                          :formType="i.formType"
                          :options="i.options"
                          :textTransform="i.textTransform"
                          :solid="!i.disabled"
                          :validationParams="i.validationParams"
                          :isRequires="i.isRequires"
                          :max="i.max"
                          :config="i.config"
                        ></AppFormField>
                      </div>
                      <AppFormField
                        v-else
                        v-model="$v.referralCodeObj[i.model].$model"
                        :title="$t(`label.${i.title}`)"
                        :state="validateObjState('referralCodeObj', i.model)"
                        :validationValue="$v.referralCodeObj[i.model]"
                        :type="i.type"
                        :validators="i.validators"
                        :formType="i.formType"
                        :options="i.options"
                        :textTransform="i.textTransform"
                        :disabled="i.disabled"
                        :solid="!i.disabled"
                        :validationParams="i.validationParams"
                        :isRequires="i.isRequires"
                        :max="i.max"
                        :config="i.config"
                      ></AppFormField>
                      <div style="display: none">
                        <!-- WARNING: I have no idea why this line is here, but removing it breaks everything -->
                        {{ $v }}
                      </div>
                    </b-col>
                  </template>
                </b-row>
              </b-form>
              <!--begin: Wizard Actions -->
              <div class="d-flex justify-content-end border-top pt-10">
                <ReferralCodeStatusBtn
                  v-if="!isCreate"
                  :id="id"
                  :referralCodeObj="referralCodeObj"
                ></ReferralCodeStatusBtn>
                <button
                  :disabled="
                    !checkAccessRight(moduleName, [
                      isCreate
                        ? permissionAction.REFERRAL_CODE_CREATE
                        : permissionAction.REFERRAL_CODE_UPDATE
                    ])
                  "
                  @click="validationCheckBeforeSubmit()"
                  class="btn btn-primary font-weight-bold text-caitalize"
                >
                  {{ $t(`label.${isCreate ? 'create' : 'update'}`) }}
                </button>
              </div>
              <!--end: Wizard Actions -->
            </div>
            <!--end: Wizard Step 1-->
            <!--begin: Wizard Step 2-->
            <div
              class="pb-5"
              data-wizard-type="step-content"
              data-wizard-state=""
            >
              <!-- begin: User Referees Table Modal -->
              <ReferralCodeRefereesDetails
                v-if="!isCreate"
                :id="id"
                :referralCodeObj="referralCodeObj"
              ></ReferralCodeRefereesDetails>
              <!-- end: User Referees Table Modal -->
            </div>
            <!--end: Wizard Step 2-->
          </b-col>
          <!--end: Wizard-->
        </b-row>
      </div>
      <!--end: Wizard Body -->
    </div>
    <!--end: Wizard-->
  </b-card>
</template>

<script>
  import AppFormField from '@/views/components/bases/form-field/AppFormField';
  import AppViewAndUpload from '@/views/components/bases/AppViewAndUpload';
  import AppAmountType from '@/views/components/bases/AppAmountType';
  import ReferralCodeStatusLabel from '@/views/components/referral-code/ReferralCodeStatusLabel';
  import ReferralCodeRefereesDetails from '@/views/components/referral-code/ReferralCodeRefereesDetails';
  import ReferralCodeStatusBtn from '@/views/components/referral-code/ReferralCodeStatusBtn.vue';
  import KTUtil from '@/assets/js/components/util';
  import KTWizard from '@/assets/js/components/wizard';
  import constantsMixin from '@/core/mixins/constants.mixin';
  import commonMixin from '@/core/mixins/common.mixin';
  import { listService } from '@/core/services';
  import { validationMixin } from 'vuelidate';
  import {
    required,
    minLength,
    maxLength,
    numeric
  } from 'vuelidate/lib/validators';
  import {
    GLOBAL,
    MODULE,
    ACTION,
    ROUTE_NAME,
    fileSize,
    bannerImageType
  } from '@/core/constants';
  import { i18nHelper, uiHelper } from '@/core/utils';
  import { MediaType, ReferralCodeVoucherType } from '@/core/constants/enums';
  import {
    REFERRAL_CODE_GET_REFERAL_CODE,
    REFERRAL_CODE_CREATE_REFERAL_CODE,
    REFERRAL_CODE_UPDATE_REFERAL_CODE,
    REFERRAL_CODE_INITIAL_GET_REFERAL_CODE_STATE,
    REFERRAL_CODE_INITIAL_CREATE_REFERAL_CODE_STATE,
    REFERRAL_CODE_INITIAL_UPDATE_REFERAL_CODE_STATE
  } from '@/core/store/referral-code.module';

  export default {
    name: 'ReferralCodeDetailsTemplate',
    mixins: [commonMixin, constantsMixin, validationMixin],
    components: {
      AppFormField,
      AppViewAndUpload,
      AppAmountType,
      ReferralCodeRefereesDetails,
      ReferralCodeStatusBtn,
      ReferralCodeStatusLabel
    },
    props: {
      isCreate: {
        Type: Boolean,
        defualt: false
      }
    },
    data: () => ({
      moduleName: MODULE.REFERRAL_CODE,
      permissionAction: ACTION,
      global: GLOBAL,
      routeName: ROUTE_NAME,
      constWizard: null,
      ReferralCodeVoucherType: ReferralCodeVoucherType,
      referralCodeObj: {
        referralCode: null,
        usagePeriodFrom: null,
        usagePeriodTo: null,
        quantity: 0,
        eventName: null,
        pinVerification: null,
        rewardType: 2, //defualt value (Voucher)
        voucherPrefix: null,
        voucherValueType: {
          type: null,
          value: null
        },
        voucherType: 1, //defualt value (Dine In)
        minimumSpending: 0,
        usageLimit: null,
        merchantName: null,
        title: null,
        description: null,
        redemptionPeriodFrom: null,
        redemptionPeriodTo: null,
        tnc: null
      },
      formFields: []
    }),
    computed: {
      id() {
        return this.$route.params.id;
      },
      getReferralCodeComplete() {
        return this.$store.state.referralCode.referralCode.complete;
      },
      createReferralCodeComplete() {
        return this.$store.state.referralCode.createReferralCode.complete;
      },
      updateReferralCodeComplete() {
        return this.$store.state.referralCode.updateReferralCode.complete;
      }
    },
    watch: {
      getReferralCodeComplete() {
        let response = this.$store.state.referralCode.referralCode;
        if (response.complete) {
          this.referralCodeObj = response.data;
          this.referralCodeCompleteAction(response, 'get');
        }
      },
      createReferralCodeComplete() {
        let response = this.$store.state.referralCode.createReferralCode;
        if (response.complete) {
          this.referralCodeCompleteAction(response, 'create');
        }
      },
      updateReferralCodeComplete() {
        let response = this.$store.state.referralCode.updateReferralCode;
        if (response.complete) {
          this.referralCodeCompleteAction(response, 'update');
        }
      }
    },
    mounted() {
      this.initialReferralCode();
    },
    methods: {
      replaceAction(value) {
        this.referralCodeObj[value] = null;
      },
      referralCodeCompleteAction(response, actionType) {
        let title = i18nHelper.getMessage('label.referralCode');
        let messages = response.message;
        let buttons = [];
        switch (actionType) {
          case 'get':
            this.initialGetReferralCodeState();
            break;
          case 'create':
            this.initialCreateReferralCodeState();
            break;
          case 'update':
            this.initialUpdateReferralCodeState();
            break;
        }
        if (response.code == 0) {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();

              switch (actionType) {
                case 'create':
                  this.$router.push({
                    name: ROUTE_NAME.REFERRAL_CODE_DETAILS,
                    params: { id: response.data.id }
                  });
                  break;
                default:
                  this.getReferralCode(this.id);
              }
            }
          });
        } else {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();
            }
          });
        }
        if (messages) this.openLayoutAppModalInfo(title, messages, buttons);
      },
      initWizard() {
        // Initialize form wizard
        let vm = this;

        const wizard = new KTWizard('referralCodeWizard', {
          startStep: 1, // initial active step number
          clickableSteps: true // allow step clicking
        });

        vm.constWizard = wizard;

        // wizard.on('submit', () => {
        //   vm.validationCheckBeforeSubmit();
        // });

        //Change event
        wizard.on('change', function (/*wizardObj*/) {
          // if (wizard.currentStep == 1) {
          //   let v1 = vm.validationCheck('referralCodeObj');

          //   if (!v1) {
          //     wizard.stop();
          //   }
          // }

          KTUtil.scrollTop();
        });
      },
      initFormFields() {
        this.formFields = [
          {
            field: 1,
            model: 'referralCode',
            title: 'referralCode',
            disabled: !this.isCreate,
            isRequires: true,
            textTransform: true,
            validators: ['required', 'minLength'],
            validationParams: [
              {
                key: 'minLength',
                params: [5]
              }
            ]
          },
          {
            field: 1,
            model: 'usagePeriodFrom',
            title: 'usagePeriodFrom',
            isRequires: true,
            formType: 'date',
            validators: ['required']
          },
          {
            field: 1,
            model: 'usagePeriodTo',
            title: 'usagePeriodTo',
            isRequires: true,
            formType: 'date',
            validators: ['required']
          },
          {
            field: 1,
            model: 'quantity',
            title: 'quantity',
            type: 'number',
            validators: ['required', 'numeric']
          },
          {
            field: 1,
            model: 'eventName',
            title: 'eventName'
          },
          {
            field: 1,
            model: 'rewardType',
            title: 'rewardType',
            disabled: !this.isCreate,
            formType: 'radios',
            options: listService.getReferralCodeRewrdTypeList(false)
          },
          {
            field: 1,
            model: 'voucherPrefix',
            title: 'voucherPrefix',
            disabled: !this.isCreate,
            textTransform: true,
            isRequires: true,
            validators: ['required', 'minMaxLength'],
            validationParams: [
              {
                key: 'minMaxLength',
                params: [3, 5]
              }
            ]
          },
          {
            field: 1,
            model: 'voucherValueType',
            title: 'discount',
            layout: 'AppAmountType',
            disabled: !this.isCreate,
            isRequires: true,
            options: listService.getAmoutTypeList(false)
          },
          {
            field: 1,
            model: 'voucherType',
            title: 'voucherType',
            formType: 'radios',
            disabled: !this.isCreate,
            options: listService.getReferralCodeVoucherTypeList(false)
          },
          {
            field: 1,
            model: 'banner',
            title: 'banner',
            isRequires: true,
            layout: 'AppViewAndUpload',
            formType: 'file',
            validators: [
              'required',
              'bannerImageType',
              'fileSize'
              // 'bannerImageDimension'
            ],
            validationParams: [
              {
                key: 'bannerImageType',
                params: [
                  uiHelper.arrayToTrimmedStringWithSpace(
                    GLOBAL.AVAILABLE_BANNER_IMAGE_TYPE,
                    6
                  )
                ]
              },
              {
                key: 'fileSize',
                params: [
                  GLOBAL.BANNER_IMAGE_UPLOAD_SIZE,
                  GLOBAL.BANNER_IMAGE_UPLOAD_SIZE_TYPE
                ]
              }
              // {
              //   key: 'bannerImageDimension',
              //   params: [dimension.height, dimension.width]
              // }
            ],
            accept: GLOBAL.AVAILABLE_BANNER_IMAGE_TYPE.toString(),
            config: {
              dimension: {
                height: 400,
                width: 400
              },
              uploadSize: 5,
              mediaType: MediaType.IMAGE
            }
          },
          {
            field: 1,
            model: 'thumbnail',
            title: 'thumbnail',
            isRequires: true,
            layout: 'AppViewAndUpload',
            formType: 'file',
            validators: [
              'required',
              'bannerImageType',
              'fileSize'
              // 'bannerImageDimension'
            ],
            validationParams: [
              {
                key: 'bannerImageType',
                params: [
                  uiHelper.arrayToTrimmedStringWithSpace(
                    GLOBAL.AVAILABLE_BANNER_IMAGE_TYPE,
                    6
                  )
                ]
              },
              {
                key: 'fileSize',
                params: [
                  GLOBAL.BANNER_IMAGE_UPLOAD_SIZE,
                  GLOBAL.BANNER_VIDEO_UPLOAD_SIZE_TYPE
                ]
              }
              // {
              //   key: 'bannerImageDimension',
              //   params: [dimension.height, dimension.width]
              // }
            ],
            accept: GLOBAL.AVAILABLE_BANNER_IMAGE_TYPE.toString(),
            config: {
              dimension: {
                height: 400,
                width: 400
              },
              uploadSize: 5,
              mediaType: MediaType.IMAGE
            }
          },
          {
            field: 1,
            model: 'merchantName',
            title: 'merchantName',
            isRequires: true,
            validators: ['required']
          },
          {
            field: 1,
            model: 'pinVerification',
            title: 'pinVerification',
            textTransform: true,
            isRequires: true,
            validators: ['required', 'pinLength'],
            validationParams: [
              {
                key: 'pinLength',
                params: [4]
              }
            ]
          },
          {
            field: 1,
            model: 'title',
            title: 'voucherTitle',
            isRequires: true,
            validators: ['required']
          },
          {
            field: 1,
            model: 'description',
            title: 'description',
            isRequires: true,
            formType: 'textarea',
            validators: ['required', 'maxLength'],
            validationParams: [
              {
                key: 'maxLength',
                params: [500]
              }
            ]
          },
          {
            field: 1,
            model: 'redemptionPeriodFrom',
            title: 'redemptionPeriodFrom',
            isRequires: true,
            formType: 'date',
            validators: ['required']
          },
          {
            field: 1,
            model: 'redemptionPeriodTo',
            title: 'redemptionPeriodTo',
            isRequires: true,
            formType: 'date',
            validators: ['required']
          },
          {
            field: 1,
            model: 'tnc',
            title: 'termsAndConditions',
            isRequires: true,
            layout: 'AppViewAndUpload',
            fileType: 'pdf',
            validators: ['required']
          }
        ];
      },
      validationCheckBeforeSubmit() {
        //Submit new banner form if all the validation is no error
        if (this.validationCheck('referralCodeObj')) {
          this.submitReferralCodeForm();
        }
      },
      submitReferralCodeForm() {
        let formData = new FormData();
        var data = this.referralCodeObj;

        let usagePeriodFrom = new Date(data.usagePeriodFrom);
        let usagePeriodTo = new Date(data.usagePeriodTo);
        let redemptionPeriodFrom = new Date(data.redemptionPeriodFrom);
        let redemptionPeriodTo = new Date(data.redemptionPeriodTo);

        usagePeriodFrom = usagePeriodFrom.getTime();
        usagePeriodTo = usagePeriodTo.getTime();
        redemptionPeriodFrom = redemptionPeriodFrom.getTime();
        redemptionPeriodTo = redemptionPeriodTo.getTime();

        formData.append('title', data.title);
        formData.append(
          'usage_period_from',
          data.usagePeriodFrom == '-' || data.usagePeriodFrom == ''
            ? ''
            : usagePeriodFrom
        );
        formData.append(
          'usage_period_to',
          data.usagePeriodTo == '-' ? '' : usagePeriodTo
        );
        formData.append('quantity', data.quantity);
        formData.append('event_name', data.eventName ? data.eventName : '');
        formData.append('merchant_name', data.merchantName);
        if (data.pinVerification)
          formData.append(
            'pin_verification',
            data.pinVerification.toUpperCase()
          );
        formData.append('description', data.description);
        formData.append(
          'redemption_period_from',
          data.redemptionPeriodFrom == '' ? '' : redemptionPeriodFrom
        );
        formData.append('redemption_period_to', redemptionPeriodTo);
        formData.append(
          'banner',
          !(data.banner instanceof Blob) ? '' : data.banner
        );
        formData.append(
          'thumbnail',
          !(data.thumbnail instanceof Blob) ? '' : data.thumbnail
        );
        formData.append('tnc', !(data.tnc instanceof Blob) ? '' : data.tnc);
        formData.append('referral_code', data.referralCode.toUpperCase());
        formData.append('voucher_prefix', data.voucherPrefix.toUpperCase());
        formData.append(
          'value_type',
          data.voucherValueType ? data.voucherValueType.type : null
        );
        formData.append(
          'value',
          data.voucherValueType ? data.voucherValueType.value : null
        );
        formData.append('voucher_type', data.voucherType);
        formData.append('reward_type', 2);

        this.isCreate
          ? this.createReferralCode(formData)
          : this.updateReferralCode(this.id, formData);
      },
      getReferralCode(id) {
        this.$store.dispatch(REFERRAL_CODE_GET_REFERAL_CODE, { id });
      },
      createReferralCode(formData) {
        this.$store.dispatch(REFERRAL_CODE_CREATE_REFERAL_CODE, { formData });
      },
      updateReferralCode(id, formData) {
        this.$store.dispatch(REFERRAL_CODE_UPDATE_REFERAL_CODE, {
          id,
          formData
        });
      },
      initialGetReferralCodeState() {
        this.$store.dispatch(REFERRAL_CODE_INITIAL_GET_REFERAL_CODE_STATE);
      },
      initialCreateReferralCodeState() {
        this.$store.dispatch(REFERRAL_CODE_INITIAL_CREATE_REFERAL_CODE_STATE);
      },
      initialUpdateReferralCodeState() {
        this.$store.dispatch(REFERRAL_CODE_INITIAL_UPDATE_REFERAL_CODE_STATE);
      },
      initialReferralCode() {
        let breadcrumbData = {
          breadcrumbs: [
            {
              title: i18nHelper.getMessage('label.referralCode'),
              route: { name: ROUTE_NAME.REFERRAL_CODE }
            },
            {
              title: i18nHelper.getMessage('label.referralCodeList'),
              route: { name: ROUTE_NAME.REFERRAL_CODE_LIST }
            },
            {
              title: i18nHelper.getMessage(
                `label.${
                  this.isCreate ? 'referralCodeCreate' : 'referralCodeDetails'
                }`
              )
            }
          ],
          actions: [
            {
              text: i18nHelper.getMessage('label.back'),
              function: () => {
                this.$router.go(-1);
              }
            }
          ]
        };
        if (!this.isCreate) this.getReferralCode(this.id);
        this.initFormFields();
        this.initWizard();
        this.initBreadCrumb(breadcrumbData);
      }
    },
    validations() {
      const hasFile = (val, type) => {
        if (typeof val === 'string') {
          return false;
        } else {
          if (type == 'fileSize') {
            return fileSize(GLOBAL.BANNER_IMAGE_UPLOAD_SIZE);
          } else if (type == 'bannerImageType') {
            return bannerImageType;
          }
        }
      };
      return {
        referralCodeObj: {
          referralCode: {
            required,
            minLength: minLength(5)
          },
          usagePeriodFrom: { required },
          usagePeriodTo: { required },
          quantity: {
            required,
            numeric
          },
          eventName: {},
          pinVerification: {
            required:
              this.referralCodeObj.voucherType !=
              ReferralCodeVoucherType.DELIVERY_OR_PICKUP,
            minPinLength: minLength(4),
            maxPinLength: maxLength(4)
          },
          rewardType: {},
          voucherValueType: {},
          voucherPrefix: {
            required,
            minPrefixLength: minLength(3),
            maxPrefixLength: maxLength(5)
          },
          voucherType: {},
          banner: {
            required,
            bannerImageType: hasFile(
              this.referralCodeObj.banner,
              'bannerImageType'
            ),
            fileSize: hasFile(this.referralCodeObj.banner, 'fileSize')
          },
          thumbnail: {
            required,
            bannerImageType: hasFile(
              this.referralCodeObj.thumbnail,
              'bannerImageType'
            ),
            fileSize: hasFile(this.referralCodeObj.thumbnail, 'fileSize')
          },
          merchantName: { required },
          title: { required },
          description: { required, maxLength: maxLength(500) },
          minimumSpending: { numeric },
          usageLimit: { numeric },
          redemptionPeriodFrom: { required },
          redemptionPeriodTo: { required },
          tnc: { required }
        }
      };
    }
  };
</script>

<style lang="scss">
  @import '@/assets/sass/pages/wizard/wizard-2.scss';
</style>
