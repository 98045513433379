<template>
  <div>
    <div v-for="(item, index) in options" :key="index">
      <b-row no-gutters>
        <input
          v-model="value.deliveryMethod"
          type="checkbox"
          :id="item.text"
          :value="item.value"
          :disabled="item.disabled"
          @change="checkDeliveryMethod"
        />
        <label class="m-0 px-3" :for="item.text">{{ item.text }}</label>
      </b-row>
      <b-row
        v-if="value.deliveryMethod.includes(item.value)"
        no-gutters
        class="align-items-center justify-content-center py-2"
      >
        <div class="text-capitalize">{{ $t('label.day') }}</div>
        <input
          v-model="
            value[item.value == 1 ? 'pickupBufferDay' : 'deliveryBufferDay']
          "
          type="number"
          style="width: 6rem"
          class="form-control form-control form-control-md form-control-solid mx-3"
        />
        <div class="text-capitalize">{{ $t('label.hour') }}</div>
        <input
          v-model="
            value[item.value == 1 ? 'pickupBufferHour' : 'deliveryBufferHour']
          "
          type="number"
          style="width: 6rem"
          class="form-control form-control form-control-md form-control-solid mx-3"
        />
      </b-row>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'RecipeEcoPackDeliveryMethod',
    props: {
      options: {
        Type: Array,
        default: () => ({})
      },
      value: {
        Type: Object,
        default: () => ({
          deliveryMethod: [],
          deliveryBufferDay: null,
          deliveryBufferHour: null,
          pickupBufferDay: null,
          pickupBufferHour: null
        })
      }
    },
    data: () => ({}),
    mounted() {},
    computed: {},
    watch: {},
    methods: {
      checkDeliveryMethod() {
        if (!this.value.deliveryMethod.includes(1)) {
          this.value.pickupBufferDay = null;
          this.value.pickupBufferHour = null;
        }
      }
    }
  };
</script>

<style></style>
