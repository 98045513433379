const DeliveryStatus = {
  NO_DRIVER_ASSIGNED: 0,
  PROCESSING: 1,
  ASSIGNING_DRIVER: 2,
  ON_GOING: 3,
  PICKED_UP: 4,
  COMPLETED: 5,
  REJECTED: 6,
  CANCELLED: 7,
  EXPIRED: 8,
  MANUAL: 9,
  SELF_PICKUP: 10
};

export { DeliveryStatus };
