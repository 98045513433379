import { apiService } from '@/core/services';
// import { apiHelper } from '@/core/utils';

export const POINT = {
  getPoints,
  getPointId,
  getPointClaimedUsers,
  getPointSpecificUsers,
  createPointId,
  createPointSpecificUsers,
  updatePointId,
  updatePointIdActivate,
  deletePointId,
  deletePointSpecificUsers
};

const URL = {
  ADMIN: 'admin',
  MERCHANT_POINTS: 'points',
  UPDATE_STATUS: 'update-status',
  USERS: 'users',
  ASSIGN: 'assign',
  UNASSIGN: 'unassign'
};

function getPoints(data) {
  let requestOptions = {
    params: {
      title: data.pointTitle,
      id: data.id,
      status: data.status,
      apply_to: data.applyTo,
      per_page: data.perPage,
      page: data.page
    }
  };
  return apiService().get(
    `/${URL.ADMIN}/${URL.MERCHANT_POINTS}`,
    requestOptions
  );
}

function getPointId(id) {
  return apiService().get(`/${URL.ADMIN}/${URL.MERCHANT_POINTS}/${id} `);
}

function getPointClaimedUsers(id, data) {
  let requestOptions = {
    params: {
      per_page: data.perPage,
      page: data.page
    }
  };
  return apiService().get(
    `/${URL.ADMIN}/${URL.MERCHANT_POINTS}/${id}/${URL.USERS}`,
    requestOptions
  );
}
function getPointSpecificUsers(id, data) {
  let requestOptions = {
    params: {
      per_page: data.perPage,
      page: data.page
    }
  };
  return apiService().get(
    `/${URL.ADMIN}/${URL.MERCHANT_POINTS}/${id}/${URL.ASSIGN}`,
    requestOptions
  );
}

function createPointId(formData) {
  return apiService().post(`/${URL.ADMIN}/${URL.MERCHANT_POINTS}`, formData);
}

function createPointSpecificUsers(id, formData) {
  return apiService().post(
    `/${URL.ADMIN}/${URL.MERCHANT_POINTS}/${id}/${URL.ASSIGN}`,
    formData
  );
}

function updatePointId(id, formData) {
  return apiService().post(
    `/${URL.ADMIN}/${URL.MERCHANT_POINTS}/${id}`,
    formData
  );
}

function updatePointIdActivate(id, data) {
  let requestBody = {
    status: data
  };
  return apiService().patch(
    `/${URL.ADMIN}/${URL.MERCHANT_POINTS}/${id}/${URL.UPDATE_STATUS}`,
    requestBody
  );
}

function deletePointId(id) {
  return apiService().delete(`/${URL.ADMIN}/${URL.MERCHANT_POINTS}/${id}`);
}

function deletePointSpecificUsers(id, formData) {
  return apiService().post(
    `/${URL.ADMIN}/${URL.MERCHANT_POINTS}/${id}/${URL.UNASSIGN}`,
    formData
  );
}
