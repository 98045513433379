<template>
  <div>
    <b-button
      size="sm"
      :variant="`light-${creatorApplicationStatusColor(
        userObj.creatorApplicationStatus.value
      )}`"
      :class="{
        'cursor-default':
          userObj.creatorApplicationStatus.value !=
            creatorApplicationStatus.PENDING ||
          !checkAccessRight(module.CREATOR_APPLICATION, [
            permissionAction.CREATOR_APPLICATION_READ
          ])
      }"
      :disabled="
        userObj.creatorApplicationStatus.value !=
          creatorApplicationStatus.PENDING ||
        !checkAccessRight(module.CREATOR_APPLICATION, [
          permissionAction.CREATOR_APPLICATION_READ
        ])
      "
      @click="reviewApplicationManagement(userObj.id)"
      >{{
        userObj.creatorApplicationStatus.value ==
          creatorApplicationStatus.PENDING &&
        checkAccessRight(module.CREATOR_APPLICATION, [
          permissionAction.CREATOR_APPLICATION_READ
        ])
          ? $t('label.pendingApprovalClickToReviewApplication')
          : userObj.creatorApplicationStatus.name
      }}</b-button
    >
  </div>
</template>

<script>
  import commonMixin from '@/core/mixins/common.mixin';
  import { i18nHelper } from '@/core/utils';
  import { CreatorApplicationStatus } from '@/core/constants/enums';
  import { MODULE, ACTION, ROUTE_NAME } from '@/core/constants';
  import {
    USER_GET_USER_CREATOR_PROFILE,
    USER_INITIAL_GET_USER_CREATOR_PROFILE_STATE
  } from '@/core/store/user.module';

  export default {
    name: 'ProfileDetailsApplicationStatusButton',
    mixins: [commonMixin],
    props: {
      userObj: {
        type: Object,
        default: () => {}
      }
    },
    data: () => ({
      permissionAction: ACTION,
      module: MODULE,
      creatorApplicationStatus: CreatorApplicationStatus,
      isToggleRedirection: false
    }),
    computed: {
      userCreatorProfileComplete() {
        return this.$store.state.user.userCreatorProfile.complete;
      }
    },
    watch: {
      userCreatorProfileComplete() {
        let response = this.$store.state.user.userCreatorProfile;
        let title = i18nHelper.getMessage('label.getApplication');
        let initialStateAction = USER_INITIAL_GET_USER_CREATOR_PROFILE_STATE;
        let successAction = (response) => {
          this.toApplicationDetails(response);
        };

        if (
          response.complete &&
          this.isToggleRedirection &&
          this.userObj.creatorApplicationStatus.value ==
            CreatorApplicationStatus.PENDING
        ) {
          this.actionReadRecordComplete(
            response,
            title,
            initialStateAction,
            successAction
          );
        }
      }
    },
    methods: {
      toApplicationDetails(response) {
        this.$router.push({
          name: ROUTE_NAME.APPLICATION_DETAILS,
          params: { id: response.data.id }
        });
      },
      creatorApplicationStatusColor(value) {
        let color = {
          [CreatorApplicationStatus.NOT_APPLY]: () => 'primary',
          [CreatorApplicationStatus.PENDING]: () => 'warning',
          [CreatorApplicationStatus.APPROVED]: () => 'success',
          [CreatorApplicationStatus.REJECTED]: () => 'danger',
          [CreatorApplicationStatus.DOCUMENT_PENDING]: () => 'primary'
        };

        return color[value]();
      },
      reviewApplicationManagement(id) {
        this.isToggleRedirection = true;
        this.getUserCreatorProfile(id);
      },
      getUserCreatorProfile(id) {
        this.$store.dispatch(USER_GET_USER_CREATOR_PROFILE, { id });
      }
    }
  };
</script>

<style></style>
