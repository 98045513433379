const CuisineType = {
  WESTERN: 1,
  // MALAY: 2,
  // CHINESE: 3,
  // INDIAN: 4,
  // JAPANESE: 5,
  // KOREAN: 6,
  // OTHERS: 7,
  ASIAN: 2
};

export { CuisineType };
