var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"RolePermissionSelectionTable"},[_c('div',[_c('b-row',{staticClass:"align-items-center justify-content-between mb-6 mb-sm-0"},[_c('b-col',{attrs:{"cols":"12","sm":"5","md":"4","lg":"3"}},[_c('b-form',{staticClass:"form",attrs:{"id":"kt_form"}},[_c('label',[_vm._v(_vm._s(_vm.$t('label.name')))]),_c('b-form-group',{staticClass:"form-group"},[_c('b-form-input',{staticClass:"form-control form-control-solid",attrs:{"state":_vm.validateNewRoleNameState(),"type":"text","disabled":!_vm.checkAccessRight(_vm.moduleName, [_vm.permissionAction.ROLE_UPDATE])},model:{value:(_vm.$v.newRoleName.$model),callback:function ($$v) {_vm.$set(_vm.$v.newRoleName, "$model", $$v)},expression:"$v.newRoleName.$model"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(_vm.$t('fieldErrorMessage.requiredFieldDefault'))+" ")])],1)],1)],1),_c('b-col',{attrs:{"cols":"auto"}},[(
            _vm.isNew &&
            _vm.checkAccessRight(_vm.moduleName, [_vm.permissionAction.ROLE_CREATE])
          )?_c('b-button',{staticClass:"min-w-120px min-w-md-150px",attrs:{"variant":"success"},on:{"click":_vm.submitForm}},[_vm._v(_vm._s(_vm.$t('label.submit')))]):_vm._e(),(
            !_vm.isNew &&
            _vm.checkAccessRight(_vm.moduleName, [_vm.permissionAction.ROLE_DELETE])
          )?_c('b-button',{staticClass:"mr-2 text-capitalize",attrs:{"variant":"light-danger"},on:{"click":_vm.deleteRoleAction}},[_vm._v(_vm._s(_vm.$t('label.deleteRole')))]):_vm._e(),(
            !_vm.isNew &&
            _vm.checkAccessRight(_vm.moduleName, [_vm.permissionAction.ROLE_UPDATE])
          )?_c('b-button',{staticClass:"text-capitalize",attrs:{"variant":"light-primary"},on:{"click":_vm.submitForm}},[_vm._v(_vm._s(_vm.$t('label.saveChanges')))]):_vm._e()],1)],1)],1),_c('div',{staticClass:"overflow-x-auto"},[_c('div',{staticClass:"permissions-wrapper"},[_c('b-row',{staticClass:"font-weight-bolder p-3",attrs:{"no-gutters":""}},[_c('b-col',{attrs:{"cols":"1"}},[_c('b-form-checkbox',{staticClass:"mr-0",attrs:{"indeterminate":_vm.newPermission.indeterminate,"disabled":_vm.isNew
                ? false
                : !_vm.checkAccessRight(_vm.moduleName, [
                    _vm.permissionAction.ROLE_UPDATE
                  ]),"inline":"","size":"lg"},on:{"change":function($event){return _vm.selectAllPermissions(_vm.newPermission.active)}},model:{value:(_vm.newPermission.active),callback:function ($$v) {_vm.$set(_vm.newPermission, "active", $$v)},expression:"newPermission.active"}})],1),_c('b-col',{attrs:{"cols":"2"}},[_vm._v(_vm._s(_vm.$t('label.module')))]),_c('b-col',{attrs:{"cols":"2"}},[_vm._v(_vm._s(_vm.$t('enumCRUD.create')))]),_c('b-col',{attrs:{"cols":"2"}},[_vm._v(_vm._s(_vm.$t('enumCRUD.read')))]),_c('b-col',{attrs:{"cols":"2"}},[_vm._v(_vm._s(_vm.$t('enumCRUD.update')))]),_c('b-col',{attrs:{"cols":"2"}},[_vm._v(_vm._s(_vm.$t('enumCRUD.delete')))]),_c('b-col',{attrs:{"cols":"1"}},[_c('span',{staticClass:"d-flex justify-content-center align-items-center cursor-pointer expand-icon-span",on:{"click":function($event){_vm.isExpandAllChildrenPermissions = !_vm.isExpandAllChildrenPermissions}}},[_c('i',{class:`fas fa-angle-double-${
                !_vm.isExpandAllChildrenPermissions ? 'down' : 'up'
              }`})])])],1),_vm._l((_vm.newPermission.permissions),function(permission,index){return [_c('div',{key:index},[_c('b-row',{staticClass:"border-top p-3",attrs:{"no-gutters":""}},[_c('b-col',{attrs:{"cols":"1"}},[_c('b-form-checkbox',{attrs:{"indeterminate":permission.indeterminate,"disabled":_vm.isNew
                    ? false
                    : !_vm.checkAccessRight(_vm.moduleName, [
                        _vm.permissionAction.ROLE_UPDATE
                      ]),"size":"lg"},on:{"change":function($event){return _vm.changeMasterPermission(permission)}},model:{value:(permission.active),callback:function ($$v) {_vm.$set(permission, "active", $$v)},expression:"permission.active"}})],1),_c('b-col',{staticClass:"font-weight-bolder text-capitalize",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.$t(`enumModule.${permission.name}`))+" ")]),_vm._l((permission.actions),function(action,actionIndex){return [_c('b-col',{key:actionIndex},[(_vm.isMasterPermissionActionHidden(permission, action))?_c('b-form-checkbox',{attrs:{"indeterminate":action.indeterminate,"disabled":_vm.disabledAction(permission, action),"size":"lg"},on:{"change":function($event){return _vm.changeMasterPermissionActions(permission, action)}},model:{value:(action.active),callback:function ($$v) {_vm.$set(action, "active", $$v)},expression:"action.active"}}):_vm._e()],1)]}),_c('b-col',{attrs:{"cols":"1"}},[_c('span',{staticClass:"d-flex justify-content-center align-items-center cursor-pointer expand-icon-span",on:{"click":function($event){permission.childrenVisible = !permission.childrenVisible}}},[_c('i',{class:`fas fa-angle-${
                    !permission.childrenVisible ? 'down' : 'up'
                  }`})])])],2),_c('b-collapse',{model:{value:(permission.childrenVisible),callback:function ($$v) {_vm.$set(permission, "childrenVisible", $$v)},expression:"permission.childrenVisible"}},[_vm._l((permission.children),function(children,cIndex){return [_c('b-row',{key:cIndex,staticClass:"border-top p-3 children-row",attrs:{"no-gutters":""}},[_c('b-col',{attrs:{"cols":"1"}},[_c('b-form-checkbox',{attrs:{"indeterminate":children.indeterminate,"disabled":_vm.isNew
                        ? false
                        : !_vm.checkAccessRight(_vm.moduleName, [
                            _vm.permissionAction.ROLE_UPDATE
                          ]),"size":"lg"},on:{"change":function($event){return _vm.changeChildrenMasterPermission(permission, children)}},model:{value:(children.active),callback:function ($$v) {_vm.$set(children, "active", $$v)},expression:"children.active"}})],1),_c('b-col',{staticClass:"font-weight-bolder font-italic text-capitalize",attrs:{"cols":"2"}},[_vm._v(_vm._s(_vm.$t(`enumFeature.${children.name}`)))]),_vm._l((_vm.crudList),function(crud,crudIndex){return [_c('b-col',{key:crudIndex,attrs:{"cols":"2"}},[(children.actions.find((x) => x.name == crud))?_c('b-form-checkbox',{attrs:{"disabled":_vm.disabledChildrenAction(
                          children,
                          children.actions.find((x) => x.name == crud)
                        ),"size":"lg"},on:{"change":function($event){_vm.changeChildrenPermissionActions(
                          permission,
                          children,
                          children.actions.find((x) => x.name == crud)
                        )}},model:{value:(
                        children.actions.find((x) => x.name == crud).active
                      ),callback:function ($$v) {_vm.$set(children.actions.find((x) => x.name == crud), "active", $$v)},expression:"\n                        children.actions.find((x) => x.name == crud).active\n                      "}}):_vm._e()],1)]}),_c('b-col',{attrs:{"cols":"1"}})],2)]})],2)],1)]})],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }