<template>
  <div>
    <AppSmartList
      :breadcrumbData="breadcrumbData"
      :accessRights="accessRights"
      :filters="filters"
      :actions="actions"
      :tableHeaders="headers"
      subMenu="ecopack"
    ></AppSmartList>
  </div>
</template>

<script>
  import AppSmartList from '@/views/components/bases/smart/AppSmartList.vue';
  import { i18nHelper, uiHelper } from '@/core/utils';
  import {
    ROUTE_NAME,
    MODULE,
    ACTION,
    APP_COMPONENT_NAME,
    SMART_FORM_FIELD_TYPE,
    LOCAL
  } from '@/core/constants';
  import { listService } from '@/core/services';
  import {
    RECIPE_GET_ECOPACK_RECIPES,
    RECIPE_INITIAL_GET_RECIPES_STATE
  } from '@/core/store/recipe.module';

  const CONSTANTS_DATA = JSON.parse(uiHelper.getLocalStorage(LOCAL.CONSTANTS));

  export default {
    name: 'EcoPackList',
    components: {
      AppSmartList
    },
    data: () => ({
      breadcrumbData: {
        breadcrumbs: [
          {
            title: i18nHelper.getMessage('label.ecoPackFulfil'),
            route: { name: ROUTE_NAME.ECOPACK }
          },
          { title: i18nHelper.getMessage('label.ecoPackFulfilList') }
        ]
      },
      accessRights: {
        moduleName: MODULE.ORDERS,
        actions: {
          create: ACTION.ORDERS_CREATE,
          read: ACTION.ORDERS_READ,
          update: ACTION.ORDERS_UPDATE,
          delete: ACTION.ORDERS_DELETE
        }
      },
      filters: [
        {
          label: 'name',
          key: 'name',
          value: ''
        },
        {
          label: 'id',
          key: 'id',
          value: null
        },
        {
          label: 'createdBy',
          key: 'username',
          value: ''
        },
        {
          label: 'ecoPackOption',
          key: 'ecoPackOption',
          value: '',
          options: listService.getDynamicList(CONSTANTS_DATA.ecoPackOption),
          optionLabel: 'text',
          type: SMART_FORM_FIELD_TYPE.SELECT
        },
        {
          label: 'recipeStatus',
          key: 'recipeStatus',
          value: '',
          options: listService.getRecipeStatusList(),
          optionLabel: 'text',
          type: SMART_FORM_FIELD_TYPE.SELECT
        },
        {
          label: 'ecoPackStatus',
          key: 'ecoPackStatus',
          value: '',
          options: listService.getDynamicList(CONSTANTS_DATA.ecoPackStatus),
          optionLabel: 'text',
          type: SMART_FORM_FIELD_TYPE.SELECT
        }
      ],
      actions: {
        viewList: {
          module: 'recipe',
          state: 'recipes',
          action: RECIPE_GET_ECOPACK_RECIPES,
          initialStateAction: RECIPE_INITIAL_GET_RECIPES_STATE,
          title: i18nHelper.getMessage('label.getExperienceCookingList')
        }
      },
      headers: [
        {
          key: 'no',
          name: 'no',
          active: true,
          sortable: true
        },
        {
          key: 'name',
          name: 'name',
          active: true,
          sortable: true,
          class: 'min-w-100px'
        },
        {
          key: 'id',
          name: 'id',
          active: true,
          sortable: true
        },
        {
          key: 'username',
          name: 'createdBy',
          active: true,
          sortable: true,
          class: 'min-w-100px'
        },
        {
          key: 'availableFrom',
          name: 'availableFrom',
          active: true,
          sortable: true,
          class: 'min-w-150px'
        },
        {
          key: 'availableTo',
          name: 'availableTo',
          active: true,
          sortable: true,
          class: 'min-w-150px',
          layout: 'EcoPackAvailableTo'
        },
        {
          key: 'ecoPackStatus',
          name: 'ecoPackStatus',
          active: true,
          sortable: true,
          layout: 'RecipeEcoPackStatusLabel'
        },
        {
          key: 'status',
          name: 'recipeStatus',
          active: true,
          sortable: true,
          layout: 'RecipeStatusLabel'
        },
        {
          key: 'ecoPackOption',
          name: 'ecoPackOption',
          active: true,
          sortable: true
        },
        // {
        //   key: 'publishedAt',
        //   name: 'publishedAt',
        //   active: true,
        //   sortable: true
        // },
        {
          key: 'actions',
          name: 'actions',
          active: true,
          action: true,
          layout: APP_COMPONENT_NAME.APP_TABLE_ACTIONS,
          layoutActions: {
            viewDetailsRouteName: ROUTE_NAME.EXPERIENCE_COOKING_DETAILS
          }
        }
      ]
    })
  };
</script>

<style></style>
