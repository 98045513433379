<template>
  <b-card>
    <b-card-title class="text-capitalize font-weight-bolder">
      {{ $t('label.creatorVoucher') }}
    </b-card-title>

    <!--begin: App Smart Table-->
    <AppSmartTable
      :items="tableItems"
      :headers="tableHeaders"
      :pagination="tablePagination"
      @app-smart-table-pagination-change="actionPaginationChange"
    ></AppSmartTable>
    <!--end: App Smart Table-->
  </b-card>
</template>

<script>
  import AppSmartTable from '@/views/components/bases/smart/AppSmartTable';
  import commonMixin from '@/core/mixins/common.mixin';
  import { i18nHelper } from '@/core/utils';
  import { ROUTE_NAME, GLOBAL } from '@/core/constants';
  import {
    USER_GET_USER_CREATOR_VOUCHERS,
    USER_INITIAL_GET_USER_CREATOR_VOUCHERS_STATE
  } from '@/core/store/user.module';

  export default {
    name: 'UserCreatorVoucherCard',
    components: {
      AppSmartTable
    },
    mixins: [commonMixin],
    props: {
      id: {
        required: true
      }
    },
    data: () => ({
      routeName: ROUTE_NAME,
      searchCriteria: GLOBAL.DEFAULT_SEARCH_CRITERIA,
      tableItems: [],
      tableHeaders: [
        {
          key: 'no',
          name: 'no',
          active: true,
          sortable: true
        },
        {
          key: 'voucherTitle',
          name: 'voucherTitle',
          active: true,
          sortable: true,
          class: 'min-w-130px',
          layout: 'AppFeatureDetailsRelatedButton',
          config: {
            to: ROUTE_NAME.VOUCHER_DETAILS
          }
        },
        {
          key: 'voucherType',
          name: 'voucherType',
          active: true,
          sortable: true,
          class: 'min-w-130px'
        },
        {
          key: 'quantity',
          name: 'quantity',
          active: true,
          sortable: true
        },
        {
          key: 'minimumSpending',
          name: 'minimumSpending',
          active: true,
          sortable: true
        },
        {
          key: 'totalRedemption',
          name: 'totalRedemption',
          active: true,
          sortable: true
        },
        {
          key: 'totalClaim',
          name: 'totalClaim',
          active: true,
          sortable: true
        },
        {
          key: 'status',
          name: 'status',
          active: true,
          sortable: true,
          layout: 'VoucherStatusLabel'
        },
        {
          key: 'availableFrom',
          name: 'availableFrom',
          active: true,
          sortable: true
        },
        {
          key: 'availableTo',
          name: 'availableTo',
          active: true,
          sortable: true
        }
      ],
      tablePagination: GLOBAL.DEFAULT_TABLE_PAGINATION
    }),
    computed: {
      userCreatorVouchersComplete() {
        return this.$store.state.user.userCreatorVouchers.complete;
      }
    },
    watch: {
      userCreatorVouchersComplete() {
        let response = this.$store.state.user.userCreatorVouchers;
        let title = i18nHelper.getMessage('label.getCreatorVouchers');
        let initialStateAction = USER_INITIAL_GET_USER_CREATOR_VOUCHERS_STATE;
        let successAction = (response) => {
          this.importTableItems(response);
        };

        if (response.complete) {
          this.actionReadRecordComplete(
            response,
            title,
            initialStateAction,
            successAction
          );
        }
      }
    },
    created() {
      this.initUserCreatorVoucherCard();
    },
    methods: {
      importTableItems(response) {
        this.tableItems = response.data;
        this.tablePagination = response.pagination;
      },
      actionPaginationChange(value) {
        this.searchCriteria = {
          perPage: value.size,
          page: value.page
        };

        this.getUserCreatorVoucher(this.id, this.searchCriteria);
      },
      getUserCreatorVoucher(id, data) {
        this.$store.dispatch(USER_GET_USER_CREATOR_VOUCHERS, { id, data });
      },
      initUserCreatorVoucherCard() {
        this.getUserCreatorVoucher(this.id, this.searchCriteria);
      }
    }
  };
</script>

<style></style>
