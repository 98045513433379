import dateTimeFormat from '@/core/filters/dateTimeFormat.filter';
import { CONFIG_WATI_TESTING } from '@/core/api';
import { apiHelper } from '@/core/utils';
import {
  LOADING_OPEN_LOADING,
  LOADING_CLOSE_LOADING
} from '@/core/store/loading.module';

/* Define Module Name */
const MODULE_NAME = 'configWATITesting/';

/* Define Action Name */
const A_GET_WATI_TESTING = 'getWATITesting';
const A_UPDATE_WATI_TESTING = 'updateWATITesting';

/* Define Reset State Action Name */
const A_INITIAL_GET_WATI_TESTING_STATE = 'initialGetWATITestingState';
const A_INITIAL_UPDATE_WATI_TESTING_STATE = 'initialUpdateWATITestingState';

/* Define Getter Name */

/* Define Mutation Name */
const M_GET_WATI_TESTING = 'setGetWATITesting';
const M_UPDATE_WATI_TESTING = 'setUpdateWATITesting';

/* Define Reset State Mutation Name */
const M_INITIAL_GET_WATI_TESTING_STATE = 'setInitialGetWATITestingState';
const M_INITIAL_UPDATE_WATI_TESTING_STATE = 'setInitialUpdateWATITestingState';

/* Define Export Name */
export const GENERAL_GET_WATI_TESTING = MODULE_NAME + A_GET_WATI_TESTING;
export const GENERAL_UPDATE_WATI_TESTING = MODULE_NAME + A_UPDATE_WATI_TESTING;

/* Define Reset State Name */
export const GENERAL_INITIAL_GET_WATI_TESTING_STATE =
  MODULE_NAME + A_INITIAL_GET_WATI_TESTING_STATE;
export const GENERAL_INITIAL_UPDATE_WATI_TESTING_STATE =
  MODULE_NAME + A_INITIAL_UPDATE_WATI_TESTING_STATE;

const state = {
  getWATITesting: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  updateWATITesting: {
    code: 0,
    complete: false,
    data: null,
    message: null
  }
};

//Getters
const getters = {};

// Actions
const actions = {
  [A_GET_WATI_TESTING]({ dispatch, commit }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    CONFIG_WATI_TESTING.getWATITesting()
      .then((response) => {
        result = response;
        commit(M_GET_WATI_TESTING, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_WATI_TESTING, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_UPDATE_WATI_TESTING]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    CONFIG_WATI_TESTING.updateWATITesting(data)
      .then((response) => {
        result = response;
        commit(M_UPDATE_WATI_TESTING, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_UPDATE_WATI_TESTING, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_INITIAL_GET_WATI_TESTING_STATE]({ commit }) {
    commit(M_INITIAL_GET_WATI_TESTING_STATE);
  },
  [A_INITIAL_UPDATE_WATI_TESTING_STATE]({ commit }) {
    commit(M_INITIAL_UPDATE_WATI_TESTING_STATE);
  }
};

// Mutations
const mutations = {
  [M_GET_WATI_TESTING](state, result) {
    let d = result.data;
    let watiTestingObj = [];

    d.forEach((x, index) => {
      watiTestingObj.push({
        no: index + 1,
        id: x.id,
        type: x.type,
        typeDescription: x.type_description,
        status: Boolean(x.status),
        statusDescription: x.status_description,
        createdBy: x.created_by,
        createdAt: dateTimeFormat(x.created_at)
      });
    });
    state.getWATITesting = {
      code: result.code,
      complete: true,
      data: watiTestingObj,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_UPDATE_WATI_TESTING](state, result) {
    state.updateWATITesting = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_INITIAL_GET_WATI_TESTING_STATE](state) {
    state.getWATITesting = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_UPDATE_WATI_TESTING_STATE](state) {
    state.updateWATITesting = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
