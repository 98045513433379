<template>
  <BannerDetails
    :type="bannerType"
    :bannerDimension="bannerDimension"
  ></BannerDetails>
</template>

<script>
  import BannerDetails from '@/views/components/banner/BannerDetails.vue';
  import { BannerType } from '@/core/constants/enums';

  export default {
    name: 'BannerDiscoverDetails',
    components: {
      BannerDetails
    },
    data: () => ({
      bannerType: BannerType.DISCOVER,
      bannerDimension: {
        height: 1500,
        width: 500,
        ratio: '1:1'
      }
    })
  };
</script>

<style></style>
