<template>
  <div class="d-flex flex-column flex-root full-screen">
    <div
      class="error-page-container d-flex flex-row-fluid flex-column bgi-size-cover bgi-position-center bgi-no-repeat p-10 p-sm-30"
    >
      <!-- begin:: Content -->
      <h1 class="error-page-title font-weight-boldest text-dark-75 mt-15">
        404
      </h1>
      <p class="font-size-h3 font-weight-light">
        {{ $t('message.somethingWentWrongHere') }}
      </p>
      <a @click="backToInitialPage" class="pointer">{{
        $t(`label.${pageToGo}`)
      }}</a>
      <!-- end:: Content -->
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { AUTH_IS_AUTHENTICATED } from '@/core/store/auth.module';

  export default {
    name: 'Error404',
    computed: {
      ...mapGetters({
        isAuthenticated: [AUTH_IS_AUTHENTICATED]
      }),
      pageToGo() {
        if (this.isAuthenticated) {
          return 'goToPageHome';
        } else {
          return 'goToPageLogin';
        }
      }
    },
    methods: {
      backToInitialPage() {
        this.$parent.$parent.backToInitialPage();
      }
    }
  };
</script>
