/* eslint-disable */
import { i18nHelper } from '@/core/utils';
const TYPE = {
  cuisineType,
  serviceType
};

export default function customDescription(key, value) {
  let description;
  let newArray = [];
  if (Array.isArray(value)) {
    if (value.length > 0) {
      value.forEach((x) => {
        newArray.push(TYPE[key](x));
      });
      description = newArray.join(', ');
    } else {
      description = '-';
    }
  } else {
    if (value) {
      description = TYPE[key](value);
    } else {
      description = '-';
    }
  }
  return description;
}

function cuisineType(value) {
  if (value == 1) {
    // CuisineType.WESTERN
    return i18nHelper.getMessage(`enumCuisineType.${1}`);
  } else {
    // CuisineType.ASIAN
    return i18nHelper.getMessage(`enumCuisineType.${2}`);
  }
}

function serviceType(value) {
  if (value == 1) {
    // CuisineType.WESTERN
    return i18nHelper.getMessage(`enumCuisineType.${1}`);
  } else {
    // CuisineType.ASIAN
    return i18nHelper.getMessage(`enumCuisineType.${2}`);
  }
}
