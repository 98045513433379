import { i18nHelper } from '@/core/utils';
import { ActiveStatus } from '@/core/constants/enums';
import {
  USER_UPDATE_USER_ACTIVE_STATUS,
  USER_INITIAL_UPDATE_USER_ACTIVE_STATUS,
  USER_UPDATE_USER_REFERRAL_CODE,
  USER_INITIAL_UPDATE_USER_REFERRAL_CODE
} from '@/core/store/user.module';

const adminUserMixin = {
  computed: {
    updateUserActiveComplete() {
      return this.$store.state.user.updateUserActive.complete;
    },
    updateUserReferralCodeComplete() {
      return this.$store.state.user.updateUserReferralCode.complete;
    }
  },
  methods: {
    openRequestUpdateUserActiveStatusModal(id, status, type) {
      let title = i18nHelper.getMessage('label.updateActiveStatus', [
        i18nHelper.getMessage(`label.${type}`).toLowerCase()
      ]);
      let messages = [];
      let buttons = [];
      let isActive = status ? ActiveStatus.INACTIVE : ActiveStatus.ACTIVE;

      messages.push(
        i18nHelper.getMessage('message.requestUpdateStatusMessage', [
          i18nHelper.getMessage(`label.${type}`).toLowerCase(),
          i18nHelper
            .getMessage(`enumActivationStatus.${status ? 0 : 1}`)
            .toLowerCase()
        ])
      );

      buttons.push(
        {
          text: i18nHelper.getMessage('label.cancel'),
          variant: '',
          action: () => {
            this.closeLayoutAppModalInfo();
          }
        },
        {
          text: i18nHelper.getMessage(`enumActivationStatus.${status ? 0 : 1}`),
          isText: false,
          variant: status ? 'danger' : 'success',
          action: () => {
            this.closeLayoutAppModalInfo();
            this.updateUserActive(id, isActive, type);
          }
        }
      );

      this.openLayoutAppModalInfo(title, messages, buttons);
    },
    updateUserActive(id, isActive, type) {
      this.$store.dispatch(USER_UPDATE_USER_ACTIVE_STATUS, {
        id,
        isActive,
        type
      });
    },
    initialUpdateUserActiveState() {
      this.$store.dispatch(USER_INITIAL_UPDATE_USER_ACTIVE_STATUS);
    },
    openRequestUpdateUserReferralCodeModal(data) {
      let title = i18nHelper.getMessage('label.editReferralCode');

      let messages = [];
      let buttons = [];

      messages.push(
        i18nHelper.getMessage('message.confirmToEditReferralCode')
      );

      buttons.push(
        {
          text: i18nHelper.getMessage('label.cancel'),
          variant: '',
          action: () => {
            this.closeLayoutAppModalInfo();
          }
        },
        {
          text: i18nHelper.getMessage('label.confirm'),
          isText: false,
          variant: 'primary',
          action: () => {
            this.closeLayoutAppModalInfo();
            this.updateUserReferralCode(data.id, data.referral_code);
          }
        }
      );

      this.openLayoutAppModalInfo(title, messages, buttons);
    },
    updateUserReferralCode(id, referralCode) {
      this.$store.dispatch(USER_UPDATE_USER_REFERRAL_CODE, {
        id,
        referralCode
      });
    },
    initialUpdateReferralCodeState() {
      this.$store.dispatch(USER_INITIAL_UPDATE_USER_REFERRAL_CODE);
    },
  }
};

export default adminUserMixin;
