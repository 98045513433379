<template>
  <div class="order-price-breakdown">
    <!-- begin: Total Price -->
    <div class="order-price-breakdown__total" @click="visible = !visible">
      <span>{{ value.total | currency }}</span>
      <i :class="`flaticon2-${visible ? 'up' : 'down'} icon-sm`"></i>
    </div>
    <!-- end: Total Price -->

    <!-- begin: Collapse Breakdown Items -->
    <b-collapse
      v-model="visible"
      class="order-price-breakdown__items-wrapper mt-2"
    >
      <!-- begin: Breakdown Items -->
      <template v-for="(item, index) in breakdownItems">
        <b-row :key="index" no-gutters class="mb-3">
          <!-- begin: Label -->
          <b-col cols="4">
            <div class="d-flex justify-content-between">
              <span class="font-weight-bold text-capitalize">{{
                $t(`label.${item.label}`)
              }}</span>
              <div class="pl-2">:</div>
            </div>
          </b-col>
          <!-- end: Label -->

          <!-- begin: Content -->
          <b-col cols="8" class="pl-4">
            <!-- begin: Item Delivery 2 -->
            <div v-if="item.key == 'delivery2'">
              <OrderDeliveryFeeFormField
                :value="value[item.key]"
                :editable="editable"
                @save="saveDeliveryFeeAction"
              ></OrderDeliveryFeeFormField>
            </div>
            <!-- end: Item Delivery 2 -->
            <div v-else>
              {{ value[item.key] | currency }}
            </div>
          </b-col>
          <!-- end: Content -->
        </b-row>
      </template>
      <!-- end: Breakdown Items -->
    </b-collapse>
    <!-- end: Collapse Breakdown Items -->
  </div>
</template>

<script>
  import OrderDeliveryFeeFormField from '@/views/components/orders/OrderDeliveryFeeFormField';
  import { DeliveryStatus, DeliveryMethod } from '@/core/constants/enums';
  import { MODULE, ACTION } from '@/core/constants';
  import commonMixin from '@/core/mixins/common.mixin';

  export default {
    name: 'OrderPriceBreakdown',
    mixins: [commonMixin],
    components: {
      OrderDeliveryFeeFormField
    },
    props: {
      value: {
        type: Object,
        default: () => {
          return {
            subtotal: null,
            tax: null,
            delivery: null,
            total: null,
            deliveryStatus: null,
            deliveryMethod: null
          };
        }
      },
      parentObject: {
        type: Object,
        default: () => ({})
      }
    },
    data: () => ({
      deliveryStatus: DeliveryStatus,
      deliveryMethod: DeliveryMethod,
      visible: false,
      breakdownItems: [
        {
          key: 'subtotal',
          label: 'subtotal'
        },
        {
          key: 'delivery',
          label: 'delivery'
        },
        {
          key: 'peakHourSurcharge',
          label: 'peakHourSurcharge'
        },
        {
          key: 'delivery2',
          label: 'additionalDelivery'
        },
        {
          key: 'tax',
          label: 'tax'
        },
        {
          key: 'cookies',
          label: 'cookies'
        },
        {
          key: 'voucherByMerchant',
          label: 'voucher'
        },
        {
          key: 'voucherByPlatform',
          label: 'cookXVoucher'
        }
      ]
    }),
    computed: {
      meData() {
        return this.$store.state.auth.me.data;
      },
      editable() {
        let editable = false;
        let status = this.value.deliveryStatus;
        let method = this.value.deliveryMethod;
        let pic = this.parentObject.reassignedByAdminUsername;

        if (
          (this.checkAccessRight(MODULE.ORDERS, [ACTION.ORDERS_UPDATE]) &&
            method == DeliveryMethod.PRE_ORDER &&
            status == DeliveryStatus.CANCELLED) ||
          (this.checkAccessRight(MODULE.ORDERS, [ACTION.ORDERS_UPDATE]) &&
            method == DeliveryMethod.DELIVERY_BY_CAR &&
            status == DeliveryStatus.CANCELLED) ||
          (this.checkAccessRight(MODULE.ORDERS, [ACTION.ORDERS_UPDATE]) &&
            status == DeliveryStatus.MANUAL)
        ) {
          if (pic) {
            if (pic == this.meData.username) {
              editable = true;
            }
          } else {
            editable = false;
          }
        }

        return editable;
      }
    },
    methods: {
      saveDeliveryFeeAction(value) {
        this.$emit('save-delivery-fee-action', value);
      }
    }
  };
</script>

<style lang="scss">
  .order-price-breakdown {
    .order-price-breakdown__total {
      cursor: pointer;
      color: var(--gray-600);
      span {
        text-decoration: underline;
        padding-right: 8px;
      }
    }
    .order-price-breakdown__items-wrapper {
      color: var(--gray-600);
    }
  }
</style>
