<template>
  <b-card no-body data-type="custom">
    <!--begin: Wizard-->
    <div
      class="wizard wizard-2"
      id="adminUserNewWizard"
      data-wizard-state="step-first"
      data-wizard-clickable="true"
    >
      <!--begin: Wizard Nav -->
      <div class="wizard-nav border-right py-8 px-8 py-lg-20 px-lg-10">
        <div class="wizard-steps">
          <!--begin: Wizard Steps 1 -->
          <div
            class="wizard-step"
            data-wizard-type="step"
            data-wizard-state="current"
          >
            <div class="wizard-wrapper">
              <div class="wizard-icon">
                <span class="svg-icon svg-icon-2x">
                  <inline-svg src="/media/svg/icons/General/User.svg" />
                </span>
              </div>
              <div class="wizard-label text-capitalize">
                <h3 class="wizard-title">
                  {{ $t('label.userDetails') }}
                </h3>
                <div class="wizard-desc">
                  {{ $t('label.setupUserDetails') }}
                </div>
              </div>
            </div>
          </div>
          <!--end: Wizard Steps 1 -->

          <!--begin: Wizard Steps 2 -->
          <div class="wizard-step" data-wizard-type="step">
            <div class="wizard-wrapper">
              <div class="wizard-icon">
                <span class="svg-icon svg-icon-2x">
                  <inline-svg src="/media/svg/icons/General/Lock.svg" />
                </span>
              </div>
              <div class="wizard-label text-capitalize">
                <h3 class="wizard-title">
                  {{ $t('label.setupPassword') }}
                </h3>
                <div class="wizard-desc">
                  {{ $t('label.addUserPassword') }}
                </div>
              </div>
            </div>
          </div>
          <!--end: Wizard Steps 2 -->
        </div>
      </div>
      <!--end: Wizard Nav -->

      <!--begin: Wizard Body -->
      <div class="wizard-body py-8 px-8 py-lg-20 px-lg-10">
        <!--begin: Wizard Form-->
        <b-row>
          <b-col offset-xl="2" xl="8">
            <b-form class="form" id="kt_form">
              <!--begin: Wizard Step 1-->
              <div
                class="pb-5"
                data-wizard-type="step-content"
                data-wizard-state="current"
              >
                <h6 class="mb-10 font-weight-bold text-dark text-capitalize">
                  {{ $t('label.enterUserDetails') }}
                </h6>
                <b-row>
                  <!--begin::Username Field-->
                  <b-col xl="6" class="p-lx-1 py-0">
                    <label>{{ $t('label.name') }}</label>
                    <b-form-group class="form-group">
                      <b-form-input
                        v-model="$v.adminUserDetails.name.$model"
                        :state="validateAdminUserDetailsState('name')"
                        type="text"
                        class="form-control form-control-solid"
                      ></b-form-input>
                      <b-form-invalid-feedback>
                        {{ $t('fieldErrorMessage.requiredFieldDefault') }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <!--end::Username Field-->

                  <!--begin::Username Field-->
                  <b-col xl="6" class="p-lx-1 py-0">
                    <label>{{ $t('label.username') }}</label>
                    <b-form-group class="form-group">
                      <b-form-input
                        v-model="$v.adminUserDetails.username.$model"
                        :state="validateAdminUserDetailsState('username')"
                        type="text"
                        class="form-control form-control-solid"
                      ></b-form-input>
                      <b-form-invalid-feedback
                        v-if="!$v.adminUserDetails.username.alphaNum"
                      >
                        {{ $t('fieldErrorMessage.invalidUsernameFormat') }}
                      </b-form-invalid-feedback>
                      <b-form-invalid-feedback
                        v-else-if="
                          !$v.adminUserDetails.username.minLength ||
                          !$v.adminUserDetails.username.maxLength
                        "
                      >
                        {{
                          $t('fieldErrorMessage.requiredMinMaxLength', [
                            '6',
                            '11'
                          ])
                        }}
                      </b-form-invalid-feedback>
                      <b-form-invalid-feedback v-else>
                        {{ $t('fieldErrorMessage.requiredFieldDefault') }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <!--end::Username Field-->

                  <!--begin::Email Field-->
                  <b-col xl="6" class="p-lx-1 py-0">
                    <label>{{ $t('label.email') }}</label>
                    <b-form-group class="form-group">
                      <b-form-input
                        v-model="$v.adminUserDetails.email.$model"
                        :state="validateAdminUserDetailsState('email')"
                        type="email"
                        class="form-control form-control-solid"
                      ></b-form-input>
                      <b-form-invalid-feedback>
                        {{ $t('fieldErrorMessage.invalidEmailFormat') }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <!--end::Email Field-->

                  <!--begin::Role Field-->
                  <b-col xl="6" class="p-lx-1 py-0">
                    <label>{{ $t('label.role') }}</label>
                    <b-form-group class="form-group">
                      <b-form-select
                        v-model="$v.adminUserDetails.role.$model"
                        :state="validateAdminUserDetailsState('role')"
                        :options="constantsData.roles"
                        class="form-control form-control-solid"
                        value-field="id"
                        text-field="name"
                        disabled-field="notEnabled"
                      ></b-form-select>
                      <b-form-invalid-feedback>
                        {{ $t('fieldErrorMessage.requiredFieldDefault') }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <!--end::Role Field-->
                </b-row>
              </div>
            </b-form>
            <!--end: Wizard Step 1-->

            <!--begin: Wizard Step 2-->
            <b-form>
              <div class="pb-5" data-wizard-type="step-content">
                <h4 class="mb-10 font-weight-bold text-dark text-capitalize">
                  {{ $t('label.enterPassword') }}
                </h4>
                <b-row class="row">
                  <b-col xl="6" class="p-lx-1 py-0">
                    <label>{{ $t('label.password') }}:</label>
                    <b-form-group class="form-group">
                      <b-form-input
                        v-model="$v.adminUserPassword.password.$model"
                        :state="validateAdminUserPasswordState('password')"
                        type="password"
                        class="form-control form-control-solid"
                      ></b-form-input>
                      <b-form-invalid-feedback
                        v-if="
                          !$v.adminUserPassword.password.minLength ||
                          !$v.adminUserPassword.password.maxLength
                        "
                      >
                        {{
                          $t('fieldErrorMessage.requiredMinMaxLength', [
                            '6',
                            '12'
                          ])
                        }}
                      </b-form-invalid-feedback>
                      <b-form-invalid-feedback v-else>
                        {{ $t('fieldErrorMessage.requiredFieldDefault') }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>

                  <b-col xl="6" class="p-lx-1 py-0">
                    <label>{{ $t('label.confirmPassword') }}:</label>
                    <b-form-group class="form-group">
                      <b-form-input
                        v-model="$v.adminUserPassword.confirmPassword.$model"
                        :state="
                          validateAdminUserPasswordState('confirmPassword')
                        "
                        type="password"
                        class="form-control form-control-solid"
                      ></b-form-input>
                      <b-form-invalid-feedback
                        v-if="
                          !$v.adminUserPassword.confirmPassword.sameAsPassword
                        "
                      >
                        {{ $t('fieldErrorMessage.invalidConfirmPassword') }}
                      </b-form-invalid-feedback>
                      <b-form-invalid-feedback v-else>
                        {{ $t('fieldErrorMessage.requiredFieldDefault') }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </b-form>
            <!--end: Wizard Step 2-->

            <!--begin: Wizard Actions -->
            <div class="d-flex justify-content-between border-top pt-10">
              <div class="mr-2">
                <button
                  class="btn btn-light-primary font-weight-bold text-uppercase"
                  data-wizard-type="action-prev"
                >
                  {{ $t('label.previous') }}
                </button>
              </div>
              <div>
                <button
                  :disabled="
                    !checkAccessRight(moduleName, [
                      permissionAction.ADMIN_USER_CREATE
                    ])
                  "
                  class="btn btn-success font-weight-bold text-uppercase"
                  data-wizard-type="action-submit"
                  type="submit"
                >
                  {{ $t('label.submit') }}
                </button>
                <button
                  class="btn btn-primary font-weight-bold text-uppercase"
                  data-wizard-type="action-next"
                >
                  {{ $t('label.nextStep') }}
                </button>
              </div>
            </div>
            <!--end: Wizard Actions -->
          </b-col>
          <!--end: Wizard-->
        </b-row>
      </div>
      <!--end: Wizard Body -->
    </div>
    <!--end: Wizard-->
  </b-card>
</template>

<script>
  import KTUtil from '@/assets/js/components/util';
  import KTWizard from '@/assets/js/components/wizard';
  import constants from '@/core/mixins/constants.mixin';
  import common from '@/core/mixins/common.mixin';
  import { validationMixin } from 'vuelidate';
  import {
    required,
    email,
    minLength,
    maxLength,
    sameAs,
    alphaNum
  } from 'vuelidate/lib/validators';
  import { GLOBAL, MODULE, ACTION, ROUTE_NAME } from '@/core/constants';
  import { i18nHelper } from '@/core/utils';
  import {
    ADMIN_USER_CREATE_ADMIN_USER,
    ADMIN_USER_INITIAL_CREATE_ADMIN_USER_STATE
  } from '@/core/store/admin-user.module';

  export default {
    name: 'AccountManagementNew',
    mixins: [common, constants, validationMixin],
    data: () => ({
      moduleName: MODULE.ADMIN_USER,
      permissionAction: ACTION,
      globalConstants: GLOBAL,
      routeName: ROUTE_NAME,
      constWizard: null,
      adminUserDetails: {
        name: '',
        username: '',
        email: '',
        role: null
      },
      adminUserPassword: {
        password: '',
        confirmPassword: ''
      }
    }),
    computed: {
      newAdminUserComplete() {
        return this.$store.state.adminUser.newAdminUser.complete;
      }
    },
    watch: {
      newAdminUserComplete() {
        let response = this.$store.state.adminUser.newAdminUser;
        if (response.complete) {
          this.createNewAdminUserCompleteAction(response);
        }
      }
    },
    created() {
      this.initialAdminUserNew();
    },
    mounted() {
      this.initWizard();
    },
    methods: {
      initWizard() {
        // Initialize form wizard
        let vm = this;

        const wizard = new KTWizard('adminUserNewWizard', {
          startStep: 1, // initial active step number
          clickableSteps: true // allow step clicking
        });

        vm.constWizard = wizard;

        wizard.on('submit', () => {
          let validation1 = vm.validation1Check();
          let validation2 = vm.validation2Check();

          if (validation1 && validation2) {
            this.submitCreateNewAdminUserForm();
          }
        });

        // Change event
        wizard.on('change', function (/*wizardObj*/) {
          if (wizard.currentStep == 1) {
            let validation1 = vm.validation1Check();
            if (!validation1) {
              wizard.stop();
            }
          } else if (wizard.currentStep == 2) {
            if (wizard.newStep != 1) {
              let validation2 = vm.validation2Check();

              if (!validation2) {
                wizard.stop();
              }
            }
          }

          KTUtil.scrollTop();
        });
      },
      createNewAdminUserCompleteAction(response) {
        let title = i18nHelper.getMessage('label.createNewUser');
        let messages = response.message;
        let buttons = [];

        if (response.code == 0) {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();
              this.initialCreateAdminUserState();
              this.$router.push({ name: ROUTE_NAME.ACCOUNT_MANAGEMENT_LIST });
            }
          });
        } else {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();
              this.initialCreateAdminUserState();
            }
          });
        }

        this.openLayoutAppModalInfo(title, messages, buttons);
      },
      validation2Check() {
        this.$v.adminUserPassword.$touch();

        if (this.$v.adminUserPassword.$anyError) {
          return false;
        }

        return true;
      },
      validation1Check() {
        this.$v.adminUserDetails.$touch();

        if (this.$v.adminUserDetails.$anyError) {
          return false;
        }

        return true;
      },
      submitCreateNewAdminUserForm() {
        let aud = this.adminUserDetails;

        let data = {
          username: aud.username,
          name: aud.name,
          email: aud.email,
          roleId: aud.role,
          password: this.adminUserPassword.password
        };

        this.createAdminUser(data);
      },
      validateAdminUserPasswordState(name) {
        const { $dirty, $error } = this.$v.adminUserPassword[name];
        return $dirty ? !$error : null;
      },
      validateAdminUserDetailsState(name) {
        const { $dirty, $error } = this.$v.adminUserDetails[name];
        return $dirty ? !$error : null;
      },
      createAdminUser(data) {
        this.$store.dispatch(ADMIN_USER_CREATE_ADMIN_USER, { data });
      },
      initialCreateAdminUserState() {
        this.$store.dispatch(ADMIN_USER_INITIAL_CREATE_ADMIN_USER_STATE);
      },
      initialAdminUserNew() {
        let breadcrumbData = {
          breadcrumbs: [
            {
              title: i18nHelper.getMessage('label.accountManagement'),
              route: { name: ROUTE_NAME.ACCOUNT_MANAGEMENT }
            },
            { title: i18nHelper.getMessage('label.accountManagementNew') }
          ],
          actions: [
            {
              text: i18nHelper.getMessage('label.back'),
              function: () => {
                this.$router.go(-1);
              }
            }
          ]
        };

        this.initBreadCrumb(breadcrumbData);
      }
    },
    validations: {
      adminUserDetails: {
        name: {
          required
        },
        username: {
          required,
          alphaNum,
          minLength: minLength(6),
          maxLength: maxLength(11)
        },
        email: {
          required,
          email
        },
        role: {
          required
        }
      },
      adminUserPassword: {
        password: {
          required,
          minLength: minLength(6),
          maxLength: maxLength(12)
        },
        confirmPassword: {
          required,
          sameAsPassword: sameAs('password')
        }
      }
    }
  };
</script>

<style lang="scss">
  @import '@/assets/sass/pages/wizard/wizard-2.scss';
</style>
