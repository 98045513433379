<template>
  <transition name="fade-in-up">
    <router-view :key="$route.fullPath"></router-view>
  </transition>
</template>

<script>
  export default {
    name: 'MerchantDineIn'
  };
</script>
