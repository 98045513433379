<template>
  <div class="pt-3">
    <h6 class="mb-10 font-weight-bold text-dark text-capitalize">
      {{ $t('label.experienceCookingList') }}
    </h6>

    <AppSmartTable
      stickyHeader
      :stacked="false"
      :items="User.items"
      :headers="User.header"
      :pagination="User.pagination"
      :accessRights="accessRights"
      @app-smart-table-pagination-change="actionPaginationChange"
    ></AppSmartTable>
  </div>
</template>

<script>
  import userMixin from '@/core/mixins/user.mixin';
  import AppSmartTable from '@/views/components/bases/smart/AppSmartTable';
  import {
    MODULE,
    ACTION,
    ROUTE_NAME,
    APP_COMPONENT_NAME
  } from '@/core/constants';
  import { i18nHelper } from '@/core/utils';
  import commonMixin from '@/core/mixins/common.mixin';
  import {
    EQUIPMENT_GET_EQUIPMENT_RECIPES,
    EQUIPMENT_INITIAL_GET_EQUIPMENT_RECIPES_STATE
  } from '@/core/store/equipment.module';

  export default {
    name: 'EquipmentRecipesList',
    components: {
      AppSmartTable
    },
    mixins: [commonMixin, userMixin],
    props: {
      id: {
        Type: Number,
        default: null
      }
    },
    data: () => ({
      routeName: ROUTE_NAME,
      accessRights: {
        moduleName: MODULE.EQUIPMENTS,
        actions: {
          create: ACTION.EQUIPMENTS_CREATE,
          read: ACTION.EQUIPMENTS_READ,
          update: ACTION.EQUIPMENTS_UPDATE,
          delete: ACTION.EQUIPMENTS_DELETE
        }
      },
      specificUserObj: {},
      searchCriteriaListDetails: {
        perPage: 10,
        page: 1
      },
      User: {
        header: [
          {
            key: 'no',
            name: 'no',
            sortable: true
          },
          {
            key: 'createdBy',
            name: 'createdBy',
            class: 'min-w-120px',
            sortable: true,
            layout: 'AppFeatureDetailsRelatedButton',
            config: {
              to: ROUTE_NAME.PROFILE_DETAILS_DETAILS
            }
          },
          {
            key: 'recipeName',
            name: 'recipeName',
            class: 'min-w-120px',
            sortable: true,
            layout: 'AppFeatureDetailsRelatedButton',
            config: {
              to: ROUTE_NAME.EXPERIENCE_COOKING_DETAILS
            }
          },
          {
            key: 'availableFrom',
            name: 'availableFrom'
          },
          {
            key: 'availableTo',
            name: 'availableTo'
          },
          {
            key: 'equipmentSelling',
            name: 'equipmentSelling',
            layout: 'EquipmentRecipesStatusLabel'
          },
          {
            key: 'sellingStatus',
            name: 'sellingStatus',
            layout: 'AppStatusLabel'
          },
          {
            key: 'actions',
            name: 'actions',
            active: true,
            action: true,
            layout: APP_COMPONENT_NAME.APP_TABLE_ACTIONS,
            layoutActions: {
              viewDetailsRouteName: ROUTE_NAME.EXPERIENCE_COOKING_DETAILS
            }
          }
        ],
        items: [],
        pagination: {
          currentPage: 1,
          total: 1,
          perPage: 10,
          lastPage: 1
        }
      },
      disabled: false
    }),
    computed: {
      equipmentRecipesComplete() {
        return this.$store.state.equipment.equipmentRecipes.complete;
      },

      route() {
        return this.$route;
      }
    },
    watch: {
      id() {
        if (this.id) {
          this.getListDetails(this.id, this.searchCriteriaListDetails);
        }
      },
      equipmentRecipesComplete() {
        let response = this.$store.state.equipment.equipmentRecipes;
        let title = i18nHelper.getMessage('label.equipmentRecipes');
        let initialStateAction = EQUIPMENT_INITIAL_GET_EQUIPMENT_RECIPES_STATE;
        let successAction = () => {
          this.importListDetails(response);
        };

        if (response.complete) {
          this.actionReadRecordComplete(
            response,
            title,
            initialStateAction,
            successAction
          );
        }
      }
    },
    mounted() {
      this.getListDetails(this.id, this.searchCriteriaListDetails);
    },
    methods: {
      getListDetailsCompleteAction(response) {
        let title = i18nHelper.getMessage('label.equipmentRecipes');
        let messages = response.message;
        let buttons = [];
        if (response.code == 0) {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();
            }
          });
        } else {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();
            }
          });
        }
        if (messages) this.openLayoutAppModalInfo(title, messages, buttons);
      },
      openNewSpecificUsersModal() {
        this.specificUserObj = {
          isShow: true,
          id: this.id
        };
      },
      importListDetails(response) {
        this.User.items = response.data;
        this.User.pagination = response.pagination;
      },
      actionPaginationChange(value) {
        this.searchCriteriaListDetails = {
          perPage: value.size,
          page: value.page
        };

        this.getListDetails(this.id, this.searchCriteriaListDetails);
      },
      getListDetails(id, data) {
        this.$store.dispatch(EQUIPMENT_GET_EQUIPMENT_RECIPES, {
          id,
          data
        });
      }
    }
  };
</script>

<style></style>
