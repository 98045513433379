<template>
  <div>
    <!-- begin: User Referees Table -->
    <div class="d-flex justify-content-end pb-5">
      <b-button
        v-if="referralCodeObj.refereesCount"
        variant="success "
        class="mr-3 text-capitalize"
        @click="donloadReferralCode"
      >
        {{ $t('label.exportAsExcel') }}
      </b-button>
      <b-button
        variant="primary"
        class="mr-3 text-capitalize"
        @click="openNewReferee"
      >
        <i class="flaticon2-plus p-0 mr-2"></i>
        {{ $t('label.addNewReferee') }}
      </b-button>
    </div>
    <AppSmartTable
      stickyHeader
      :stacked="false"
      :items="ReferralCodeReferees.items"
      :headers="ReferralCodeReferees.header"
      :pagination="ReferralCodeReferees.pagination"
      @app-smart-table-pagination-change="actionPaginationChange"
    ></AppSmartTable>
    <!-- end: User Referees Table -->
    <!-- begin: Create New User Referees  Modal -->
    <RefereeCreateModal
      v-model="refereeModalObj.isShow"
      :id="refereeModalObj.id"
      :isShow="refereeModalObj.isShow"
    ></RefereeCreateModal>
    <!-- end: Create New User Referees  Modal -->
  </div>
</template>

<script>
  import userMixin from '@/core/mixins/user.mixin';
  import AppSmartTable from '@/views/components/bases/smart/AppSmartTable';
  import RefereeCreateModal from '@/views/components/referral-code/referee/RefereeCreateModal';
  import { ROUTE_NAME } from '@/core/constants';
  import { i18nHelper } from '@/core/utils';
  import commonMixin from '@/core/mixins/common.mixin';
  import {
    REFERRAL_CODE_GET_REFERAL_CODE_REFEREES,
    REFERRAL_CODE_INITIAL_GET_REFERAL_CODE_REFEREES_STATE
  } from '@/core/store/referral-code.module';
  import { apiService } from '@/core/services';
  import {
    LOADING_OPEN_LOADING,
    LOADING_CLOSE_LOADING
  } from '@/core/store/loading.module';

  export default {
    name: 'ReferralCodeRefereesDetails',
    components: {
      AppSmartTable,
      RefereeCreateModal
    },
    mixins: [commonMixin, userMixin],
    props: {
      id: {
        Type: Number,
        default: null
      },
      referralCodeObj: {
        type: Object,
        default: () => ({
          refereesCount: null
        })
      }
    },
    data: () => ({
      routeName: ROUTE_NAME,
      refereeModalObj: {
        isShow: false,
        id: 0
      },
      searchCriteriaReferralCodeReferees: {
        perPage: 10,
        page: 1
      },
      ReferralCodeReferees: {
        header: [
          {
            key: 'no',
            name: 'no',
            sortable: true
          },
          {
            key: 'id',
            name: 'id',
            sortable: true
          },
          {
            key: 'contactNumber',
            name: 'contactNumber',
            layout: 'AppFeatureDetailsRelatedButton',
            config: {
              to: ROUTE_NAME.PROFILE_DETAILS_DETAILS
            },
            sortable: true
          },
          {
            key: 'referee',
            name: 'referee',
            sortable: true
          },
          {
            key: 'voucherCode',
            name: 'voucherCode'
          },
          {
            key: 'createdAt',
            name: 'dateJoin',
            sortable: true
          },
          {
            key: 'redemptionAt',
            name: 'voucherRedeemedDate',
            sortable: true
          }
        ],
        items: [],
        pagination: {
          currentPage: 1,
          total: 1,
          perPage: 10,
          lastPage: 1
        }
      },
      disabled: false
    }),
    computed: {
      referralCodeRefereesComplete() {
        return this.$store.state.referralCode.referralCodeReferees.complete;
      },
      route() {
        return this.$route;
      }
    },
    watch: {
      id() {
        if (this.id) {
          this.getReferralCodeReferees(
            this.id,
            this.searchCriteriaReferralCodeReferees
          );
        }
      },
      referralCodeRefereesComplete() {
        let response = this.$store.state.referralCode.referralCodeReferees;
        let title = i18nHelper.getMessage('label.getReferees');
        let initialStateAction =
          REFERRAL_CODE_INITIAL_GET_REFERAL_CODE_REFEREES_STATE;
        let successAction = () => {
          this.importReferralCodeReferees(response);
        };

        if (response.complete) {
          this.actionReadRecordComplete(
            response,
            title,
            initialStateAction,
            successAction
          );
        }
      }
    },
    mounted() {
      this.getReferralCodeReferees(
        this.id,
        this.searchCriteriaReferralCodeReferees
      );
    },
    methods: {
      openNewReferee() {
        this.refereeModalObj = {
          isShow: true,
          id: this.id
        };
      },
      importReferralCodeReferees(response) {
        this.ReferralCodeReferees.items = response.data;
        this.ReferralCodeReferees.pagination = response.pagination;
      },
      actionPaginationChange(value) {
        this.searchCriteriaReferralCodeReferees = {
          perPage: value.size,
          page: value.page
        };

        this.getReferralCodeReferees(
          this.id,
          this.searchCriteriaReferralCodeReferees
        );
      },
      getReferralCodeReferees(id, data) {
        this.$store.dispatch(REFERRAL_CODE_GET_REFERAL_CODE_REFEREES, {
          id,
          data
        });
      },
      donloadReferralCode() {
        let id = this.id;
        this.$store.dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
        apiService()
          .get(`/admin/vouchers/${id}/export-referees`, {
            responseType: 'blob'
          })
          .then((response) => {
            this.disabled = false;
            this.$store.dispatch(`${LOADING_CLOSE_LOADING}`, null, {
              root: true
            });

            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${id}_referee_list.xlsx`);
            document.body.appendChild(link);
            link.click();
          });
      }
    }
  };
</script>

<style></style>
