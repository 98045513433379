<template>
  <div>
    <h5 class="mb-10 font-weight-bold text-dark text-capitalize">
      {{ $t('label.termsOfUse') }}
    </h5>
    <b-form class="mb-4">
      <b-row>
        <template v-for="(i, index) in formFields">
          <b-col :key="index" xl="6" class="p-xl-1 py-0 mb-8">
            <label class="font-size-sm">{{ $t(`label.${i.title}`) }}</label>
            <template v-if="platformInfoObj[i.key]">
              <PlatformInfoDocumentPlaceholder
                :attachmentUrl="platformInfoObj[i.key]"
                :hasReplace="
                  checkAccessRight(moduleName, [action.PLATFORM_INFO_UPDATE])
                "
                @replace-action="replaceTermsOfUse(i.key)"
              ></PlatformInfoDocumentPlaceholder>
            </template>
            <AppFormField
              v-else-if="
                checkAccessRight(moduleName, [action.PLATFORM_INFO_UPDATE])
              "
              v-model="$v.newPlatformInfoObj[i.key].$model"
              formType="file"
              :state="validateObjState('newPlatformInfoObj', i.key)"
              :validationValue="$v.newPlatformInfoObj[i.key]"
              :solid="true"
              :validators="['required', 'platformInfoApplicationType']"
              :noTitle="true"
              :validationParams="validationParams"
              :accept="
                global.AVAILABLE_PLATFORM_INFO_APPLICATION_TYPE.toString()
              "
              :fileUploadButton="true"
              :isUploading="isUploadingPlatformInfoObj[i.key]"
              @upload-file="uploadFileAction(i.key, i.platformFileType)"
              class="w-100"
            ></AppFormField>
            <div style="display: none">
              <!-- WARNING: I have no idea why this line is here, but removing it breaks everything -->
              {{ $v }}
            </div>
            <small
              v-if="
                !platformInfoObj[i.key] &&
                checkAccessRight(moduleName, [action.PLATFORM_INFO_UPDATE])
              "
              class="text-warning"
            >
              {{ $t('label.onlyPDFFilesAreAllowed') }}</small
            >
          </b-col>
        </template>
      </b-row>
    </b-form>

    <div v-if="!platformInfoObj.memberTerms || !platformInfoObj.creatorTerms">
      <div
        v-if="checkAccessRight(moduleName, [action.PLATFORM_INFO_UPDATE])"
        class="d-flex justify-content-end border-top pt-10"
      >
        <b-button
          variant="light-primary"
          class="font-weight-bold text-uppercase px-9 py-4"
          @click="resetForm"
        >
          {{ $t('label.reset') }}
        </b-button>
      </div>
      <span v-else-if="!platformInfoObj.faq">{{
        $t('label.thereAreNoDocumentsToShow')
      }}</span>
    </div>
  </div>
</template>

<script>
  import AppFormField from '@/views/components/bases/form-field/AppFormField';
  import PlatformInfoDocumentPlaceholder from '@/views/components/platform-info/PlatformInfoDocumentPlaceholder';
  import commonMixin from '@/core/mixins/common.mixin';
  import constantsMixin from '@/core/mixins/constants.mixin';
  import { validationMixin } from 'vuelidate';
  import { required } from 'vuelidate/lib/validators';
  import { PLATFORM_FILE_TYPE } from '@/core/constants/enums';
  import {
    GLOBAL,
    MODULE,
    ACTION,
    platformInfoApplicationType
  } from '@/core/constants';
  import { i18nHelper, uiHelper } from '@/core/utils';
  import {
    PLATFORM_INFO_UPLOAD_FILE,
    PLATFORM_INFO_INITIAL_UPLOAD_FILE_STATE
  } from '@/core/store/platform-info.module';

  export default {
    name: 'PlatformInfoTermsOfUse',
    mixins: [commonMixin, validationMixin, constantsMixin],
    components: {
      AppFormField,
      PlatformInfoDocumentPlaceholder
    },
    props: {
      platformInfo: {
        type: Object,
        default: () => {}
      }
    },
    data: () => ({
      global: GLOBAL,
      moduleName: MODULE.PLATFORM_INFO,
      action: ACTION,
      platformInfoObj: {
        memberTerms: null,
        creatorTerms: null,
        memberCookieTerms: null,
        creatorCookieTerms: null
      },
      newPlatformInfoObj: {
        memberTerms: null,
        creatorTerms: null,
        memberCookieTerms: null,
        creatorCookieTerms: null
      },
      isUploadingPlatformInfoObj: {
        memberTerms: false,
        creatorTerms: false,
        memberCookieTerms: false,
        creatorCookieTerms: false
      },
      formFields: [],
      validationParams: [
        {
          key: 'platformInfoApplicationType',
          params: [
            uiHelper.arrayToTrimmedStringWithSpace(
              GLOBAL.AVAILABLE_PLATFORM_INFO_APPLICATION_TYPE,
              12
            )
          ]
        }
      ]
    }),
    computed: {
      uploadFileComplete() {
        return this.$store.state.platformInfo.uploadFile.complete;
      }
    },
    watch: {
      platformInfo() {
        this.importPlatformInfoObj();
      },
      uploadFileComplete() {
        let response = this.$store.state.platformInfo.uploadFile;
        let title = i18nHelper.getMessage('label.uploadTermsOfUse');
        let initialStateAction = PLATFORM_INFO_INITIAL_UPLOAD_FILE_STATE;
        let successAction = () => {
          this.uploadFileCompleteAction();
        };
        let errorAction = () => {
          this.uploadFileCompleteAction();
        };

        if (response.complete) {
          this.stopUploadSpinner();
          this.actionUpdateRecordComplete(
            response,
            title,
            initialStateAction,
            successAction,
            errorAction
          );
        }
      }
    },
    mounted() {
      this.initFormFields();
      this.importPlatformInfoObj();
    },
    methods: {
      stopUploadSpinner() {
        this.isUploadingPlatformInfoObj = {
          memberTerms: false,
          creatorTerms: false,
          memberCookieTerms: false,
          creatorCookieTerms: false
        };
      },
      uploadFileCompleteAction() {
        this.$parent.initialPlatformInfo();
        this.resetForm();
        window.KTUtil.scrollTop();
      },
      uploadFileAction(key, type) {
        let formData = new FormData();

        formData.append('type', type);
        formData.append('file', this.newPlatformInfoObj[key]);

        if (this.validateObjState('newPlatformInfoObj', key)) {
          this.isUploadingPlatformInfoObj[key] = true;
          this.uploadFile(formData);
        }
      },
      resetForm() {
        let info = this.platformInfo;

        this.platformInfoObj = {
          memberTerms: info.memberTerms,
          creatorTerms: info.creatorTerms,
          memberCookieTerms: info.memberCookieTerms,
          creatorCookieTerms: info.creatorCookieTerms
        };

        this.newPlatformInfoObj = {
          memberTerms: null,
          creatorTerms: null,
          memberCookieTerms: null,
          creatorCookieTerms: null
        };

        this.$nextTick(() => {
          this.$v.$reset();
        });
      },
      replaceTermsOfUse(key) {
        this.platformInfoObj[key] = null;
      },
      importPlatformInfoObj() {
        let info = this.platformInfo;

        if (info) {
          this.platformInfoObj = {
            memberTerms: info.memberTerms,
            creatorTerms: info.creatorTerms,
            memberCookieTerms: info.memberCookieTerms,
            creatorCookieTerms: info.creatorCookieTerms
          };
        }
      },
      uploadFile(formData) {
        this.$store.dispatch(PLATFORM_INFO_UPLOAD_FILE, { formData });
      },
      initFormFields() {
        this.formFields.push(
          {
            key: 'memberTerms',
            title: 'memberTerms',
            validators: ['required'],
            disabled: true,
            platformFileType: PLATFORM_FILE_TYPE.MEMBER_TERMS
          },
          {
            key: 'creatorTerms',
            title: 'creatorTerms',
            validators: ['required'],
            disabled: true,
            platformFileType: PLATFORM_FILE_TYPE.CREATOR_TERMS
          },
          {
            key: 'memberCookieTerms',
            title: 'memberCookieTerms',
            validators: ['required'],
            disabled: true,
            platformFileType: PLATFORM_FILE_TYPE.MEMBER_COOKIE_TERMS
          },
          {
            key: 'creatorCookieTerms',
            title: 'creatorCookieTerms',
            validators: ['required'],
            disabled: true,
            platformFileType: PLATFORM_FILE_TYPE.CREATOR_COOKIE_TERMS
          }
        );
      }
    },
    validations: {
      newPlatformInfoObj: {
        memberTerms: {
          required,
          platformInfoApplicationType
        },
        creatorTerms: {
          required,
          platformInfoApplicationType
        },
        memberCookieTerms: {
          required,
          platformInfoApplicationType
        },
        creatorCookieTerms: {
          required,
          platformInfoApplicationType
        }
      }
    }
  };
</script>

<style lang="scss"></style>
