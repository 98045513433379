<template>
  <div>
    <AppSmartList
      :breadcrumbData="breadcrumbData"
      :accessRights="accessRights"
      :filters="filters"
      :actions="actions"
      :tableHeaders="headers"
      :newRecord="newRecord"
    >
    </AppSmartList>
  </div>
</template>

<script>
  import AppSmartList from '@/views/components/bases/smart/AppSmartList.vue';

  import { i18nHelper } from '@/core/utils';
  import {
    ROUTE_NAME,
    MODULE,
    ACTION,
    APP_COMPONENT_NAME,
    SMART_FORM_FIELD_TYPE
    // LOCAL
  } from '@/core/constants';
  import { listService } from '@/core/services';
  import {
    OFFLINE_MERCHANT_GET_OFFLINE_MERCHANTS,
    OFFLINE_MERCHANT_INITIAL_GET_OFFLINE_MERCHANTS_STATE
  } from '@/core/store/offline-merchant.module';

  // const CONSTANTS_DATA = JSON.parse(uiHelper.getLocalStorage(LOCAL.CONSTANTS));

  export default {
    name: 'OfflineMerchantList',
    components: {
      AppSmartList
    },
    data: () => ({
      breadcrumbData: {
        breadcrumbs: [
          {
            title: i18nHelper.getMessage('label.offlineMerchant'),
            route: { name: ROUTE_NAME.OFFLINE_MERCHANT }
          },
          { title: i18nHelper.getMessage('label.offlineMerchantList') }
        ]
      },
      accessRights: {
        moduleName: MODULE.BANNER,
        actions: {
          create: ACTION.BANNER_CREATE,
          read: ACTION.BANNER_READ,
          update: ACTION.BANNER_UPDATE,
          delete: ACTION.BANNER_DELETE
        }
      },
      filters: [
        {
          label: 'businessName',
          key: 'businessName',
          value: ''
        },
        {
          label: 'id',
          key: 'id',
          value: null
        },
        {
          label: 'status',
          key: 'status',
          value: '',
          options: listService.getStatusList(),
          optionLabel: 'text',
          type: SMART_FORM_FIELD_TYPE.SELECT
        }
      ],
      actions: {
        viewList: {
          module: 'offlineMerchant',
          state: 'offlineMerchants',
          action: OFFLINE_MERCHANT_GET_OFFLINE_MERCHANTS,
          initialStateAction:
            OFFLINE_MERCHANT_INITIAL_GET_OFFLINE_MERCHANTS_STATE,
          title: i18nHelper.getMessage('label.getExperienceCookingList')
        }
      },
      headers: [
        {
          key: 'no',
          name: 'no',
          active: true,
          sortable: true
        },
        {
          key: 'id',
          name: 'id',
          active: true,
          sortable: true
        },
        {
          key: 'businessName',
          name: 'businessName',
          active: true,
          sortable: true,
          class: 'min-w-100px'
        },
        {
          key: 'cuisineType',
          name: 'cuisineType',
          active: true,
          sortable: true
        },
        {
          key: 'totalBranch',
          name: 'totalBranch',
          active: true,
          sortable: true
        },
        {
          key: 'status',
          name: 'status',
          active: true,
          sortable: true,
          layout: 'AppStatusLabel'
        },
        {
          key: 'actions',
          name: 'actions',
          active: true,
          action: true,
          layout: APP_COMPONENT_NAME.APP_TABLE_ACTIONS,
          layoutActions: {
            viewDetailsRouteName: ROUTE_NAME.OFFLINE_MERCHANT_DETAILS
          }
        }
      ],
      newRecord: {
        show: true,
        buttonTitle: i18nHelper.getMessage('label.createOfflineMerchant'),
        routeName: ROUTE_NAME.OFFLINE_MERCHANT_NEW
      }
    })
  };
</script>

<style></style>
