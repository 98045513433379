<template>
  <div>
    <!-- {{ studioBookings }} -->
    <vc-calendar
      class="custom-calendar max-w-full"
      :masks="masks"
      :attributes="studioBookings"
      disable-page-swipe
      is-expanded
      is-range
      :first-day-of-week="2"
    >
      <template v-slot:day-content="{ day, attributes }">
        <div class="flex flex-col h-full z-10 overflow-hidden">
          <span class="day-label text-sm text-gray-900">{{ day.day }}</span>
          <div
            v-for="attr in attributes"
            :key="attr.key"
            class="flex-grow overflow-y-auto overflow-x-auto"
          >
            <router-link
              :to="{
                name: routeName.STUDIO_BOOKING_DETAILS,
                params: { id: attr.customData.id }
              }"
              size="sm"
            >
              <p
                class="text-xs leading-tight rounded-sm p-1 mt-0 mb-1"
                :class="
                  attr.customData.status != 1
                    ? `bg-secondary text-dark`
                    : 'bg-success text-white'
                "
              >
                {{ attr.customData.title }}
              </p></router-link
            >
          </div>
        </div>
      </template>
    </vc-calendar>
  </div>
</template>

<script>
  import commonMixin from '@/core/mixins/common.mixin';
  import { i18nHelper } from '@/core/utils';
  import { ROUTE_NAME } from '@/core/constants';
  import {
    STUDIO_GET_BOOKINGS_CALENDAR,
    STUDIO_INITIAL_GET_BOOKINGS_CALENDAR_STATE
  } from '@/core/store/studio.module';
  // const month = new Date().getMonth();
  // const year = new Date().getFullYear();

  export default {
    name: 'StudioCalendar',
    components: {},
    mixins: [commonMixin],
    data: () => ({
      routeName: ROUTE_NAME,
      breadcrumbData: {
        breadcrumbs: [
          {
            title: i18nHelper.getMessage('label.studio'),
            route: { name: ROUTE_NAME.STUDIO_BOOKING }
          },
          { title: i18nHelper.getMessage('label.studioCalendar') }
        ]
      },
      studioBookings: null,
      masks: {
        weekdays: 'WWW'
      }
    }),
    created() {
      this.initBreadCrumb(this.breadcrumbData);
    },
    mounted() {
      this.getStudioBookingsCalendar();
    },
    computed: {
      getStudioBookingsCalendarComplete() {
        return this.$store.state.studio.bookingsCanlendar.complete;
      }
    },
    watch: {
      getStudioBookingsCalendarComplete() {
        let response = this.$store.state.studio.bookingsCanlendar;
        if (response.complete) {
          this.studioBookings = response.data;
          this.studioBookingsCompleteAction(response);
        }
      }
    },
    methods: {
      studioBookingsCompleteAction(response) {
        let title = i18nHelper.getMessage('label.studioBooking');
        let messages = response.message;
        let buttons = [];
        this.initialGetStudioBookingsCalendarState();

        if (response.code == 0) {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();
            }
          });
        } else {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();
            }
          });
        }
        if (messages) this.openLayoutAppModalInfo(title, messages, buttons);
      },
      getStudioBookingsCalendar() {
        let data = {};
        this.$store.dispatch(STUDIO_GET_BOOKINGS_CALENDAR, { data });
      },
      initialGetStudioBookingsCalendarState() {
        this.$store.dispatch(STUDIO_INITIAL_GET_BOOKINGS_CALENDAR_STATE);
      }
    }
  };
</script>

<style lang="scss">
  ::-webkit-scrollbar {
    width: 0px;
  }
  ::-webkit-scrollbar-track {
    display: none;
  }
  .custom-calendar.vc-container {
    --day-border: 1px solid #b8c2cc;
    --day-border-highlight: 1px solid #b8c2cc;
    --day-width: 90px;
    --day-height: 90px;
    --weekday-bg: #f8fafc;
    --weekday-border: 1px solid #eaeaea;
    border-radius: 0;
    width: 100%;
    & .vc-day {
      padding: 0 5px 3px 5px;
      text-align: left;
      height: var(--day-height);
      min-width: var(--day-width);
      background-color: white;
      overflow: auto;
      &.weekday-1,
      &.weekday-7 {
        background-color: #eff8ff;
      }
      &:not(.on-bottom) {
        border-bottom: var(--day-border);
        &.weekday-1 {
          border-bottom: var(--day-border-highlight);
        }
      }
      &:not(.on-right) {
        border-right: var(--day-border);
      }
    }
    /* & .vc-header {
      background-color: #f1f5f8;
      padding: 10px 0;
    }
    & .vc-weeks {
      padding: 0;
    }
    & .vc-weekday {
      background-color: var(--weekday-bg);
      border-bottom: var(--weekday-border);
      border-top: var(--weekday-border);
      padding: 5px 0;
    }
    & .vc-day {
      padding: 0 5px 3px 5px;
      text-align: left;
      height: var(--day-height);
      min-width: var(--day-width);
      background-color: white;
      &.weekday-1,
      &.weekday-7 {
        background-color: #eff8ff;
      }
      &:not(.on-bottom) {
        border-bottom: var(--day-border);
        &.weekday-1 {
          border-bottom: var(--day-border-highlight);
        }
      }
      &:not(.on-right) {
        border-right: var(--day-border);
      }
    }
    & .vc-day-dots {
      margin-bottom: 5px;
    } */
  }
</style>
