const OrderStatus = {
  PENDING: 1,
  CONFIRMED: 2,
  REJECTED: 3,
  DELIVERED: 4,
  PICKED_UP: 5,
  REFUNDED: 6,
  CANCELLED: 7
};

export { OrderStatus };
