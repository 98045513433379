import dateTimeFormat from '@/core/filters/dateTimeFormat.filter';
import { CONFIG_NOTIFY_STUDIO } from '@/core/api';
import { apiHelper } from '@/core/utils';
import {
  LOADING_OPEN_LOADING,
  LOADING_CLOSE_LOADING
} from '@/core/store/loading.module';

/* Define Module Name */
const MODULE_NAME = 'configNotifyStudio/';

/* Define Action Name */
const A_GET_NOTIFY_STUDIO = 'getNotifyStudio';
const A_CREATE_NOTIFY_STUDIO = 'createNotifyStudio';
const A_UPDATE_NOTIFY_STUDIO = 'updateNotifyStudio';
const A_DELETE_NOTIFY_STUDIO = 'deleteNotifyStudio';

/* Define Reset State Action Name */
const A_INITIAL_GET_NOTIFY_STUDIO_STATE = 'initialGetNotifyStudioState';
const A_INITIAL_CREATE_NOTIFY_STUDIO_STATE = 'initialcreateNotifyStudioState';
const A_INITIAL_UPDATE_NOTIFY_STUDIO_STATE = 'initialupdateNotifyStudioState';
const A_INITIAL_DELETE_NOTIFY_STUDIO_STATE = 'initialDeleteNotifyStudioState';

/* Define Getter Name */

/* Define Mutation Name */
const M_GET_NOTIFY_STUDIO = 'setGetNotifyStudio';
const M_CREATE_NOTIFY_STUDIO = 'setCreateNotifyStudio';
const M_UPDATE_NOTIFY_STUDIO = 'setUpdateNotifyStudio';
const M_DELETE_NOTIFY_STUDIO = 'setDeleteNotifyStudio';

/* Define Reset State Mutation Name */
const M_INITIAL_GET_NOTIFY_STUDIO_STATE = 'setInitialGetNotifyStudioState';
const M_INITIAL_CREATE_NOTIFY_STUDIO_STATE =
  'setInitialCreateNotifyStudioState';
const M_INITIAL_UPDATE_NOTIFY_STUDIO_STATE =
  'setInitialUpdateNotifyStudioState';
const M_INITIAL_DELETE_NOTIFY_STUDIO_STATE =
  'setInitialDeleteNotifyStudioState';

/* Define Export Name */
export const GENERAL_GET_NOTIFY_STUDIO = MODULE_NAME + A_GET_NOTIFY_STUDIO;
export const GENERAL_CREATE_NOTIFY_STUDIO =
  MODULE_NAME + A_CREATE_NOTIFY_STUDIO;
export const GENERAL_UPDATE_NOTIFY_STUDIO =
  MODULE_NAME + A_UPDATE_NOTIFY_STUDIO;
export const GENERAL_DELETE_NOTIFY_STUDIO =
  MODULE_NAME + A_DELETE_NOTIFY_STUDIO;

/* Define Reset State Name */
export const GENERAL_INITIAL_GET_NOTIFY_STUDIO_STATE =
  MODULE_NAME + A_INITIAL_GET_NOTIFY_STUDIO_STATE;
export const GENERAL_INITIAL_CREATE_NOTIFY_STUDIO_STATE =
  MODULE_NAME + A_INITIAL_CREATE_NOTIFY_STUDIO_STATE;
export const GENERAL_INITIAL_UPDATE_NOTIFY_STUDIO_STATE =
  MODULE_NAME + A_INITIAL_UPDATE_NOTIFY_STUDIO_STATE;
export const GENERAL_INITIAL_DELETE_NOTIFY_STUDIO_STATE =
  MODULE_NAME + A_INITIAL_DELETE_NOTIFY_STUDIO_STATE;

const state = {
  notifyStudio: {
    code: 0,
    complete: false,
    data: null,
    message: null,
    pagination: {
      currentPage: 0,
      total: 0,
      perPage: 0,
      lastPage: 0
    }
  },
  createNotifyStudio: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  updateNotifyStudio: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  deleteNotifyStudio: {
    code: 0,
    complete: false,
    data: null,
    message: null
  }
};

//Getters
const getters = {};

// Actions
const actions = {
  [A_GET_NOTIFY_STUDIO]({ dispatch, commit }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    CONFIG_NOTIFY_STUDIO.getNotifyStudio()
      .then((response) => {
        result = response;
        commit(M_GET_NOTIFY_STUDIO, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_NOTIFY_STUDIO, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_CREATE_NOTIFY_STUDIO]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    CONFIG_NOTIFY_STUDIO.createNotifyStudio(data)
      .then((response) => {
        result = response;
        commit(M_CREATE_NOTIFY_STUDIO, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_CREATE_NOTIFY_STUDIO, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_UPDATE_NOTIFY_STUDIO]({ dispatch, commit }, { id, data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    CONFIG_NOTIFY_STUDIO.updateNotifyStudio(id, data)
      .then((response) => {
        result = response;
        commit(M_UPDATE_NOTIFY_STUDIO, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_UPDATE_NOTIFY_STUDIO, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_DELETE_NOTIFY_STUDIO]({ dispatch, commit }, { id, data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    CONFIG_NOTIFY_STUDIO.deleteNotifyStudio(id, data)
      .then((response) => {
        result = response;
        commit(M_DELETE_NOTIFY_STUDIO, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_DELETE_NOTIFY_STUDIO, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_INITIAL_CREATE_NOTIFY_STUDIO_STATE]({ commit }) {
    commit(M_INITIAL_CREATE_NOTIFY_STUDIO_STATE);
  },
  [A_INITIAL_GET_NOTIFY_STUDIO_STATE]({ commit }) {
    commit(M_INITIAL_GET_NOTIFY_STUDIO_STATE);
  },
  [A_INITIAL_UPDATE_NOTIFY_STUDIO_STATE]({ commit }) {
    commit(M_INITIAL_UPDATE_NOTIFY_STUDIO_STATE);
  },
  [A_INITIAL_DELETE_NOTIFY_STUDIO_STATE]({ commit }) {
    commit(M_INITIAL_DELETE_NOTIFY_STUDIO_STATE);
  }
};

// Mutations
const mutations = {
  [M_GET_NOTIFY_STUDIO](state, result) {
    let d = result.data;
    let NotifyCreateObj = [];
    d.forEach((x) => {
      NotifyCreateObj.push({
        id: x.id,
        created_by: x.created_by,
        phone: x.phone.substring(2),
        updatedAt: dateTimeFormat(x.updated_at),
        createdAt: dateTimeFormat(x.created_at)
      });
    });
    state.notifyStudio = {
      code: result.code,
      complete: true,
      data: NotifyCreateObj,
      message: apiHelper.sortOutMessage(result.message),
      pagination: {
        currentPage: 0,
        total: 0,
        perPage: 0,
        lastPage: 0
      }
    };
  },
  [M_CREATE_NOTIFY_STUDIO](state, result) {
    state.createNotifyStudio = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result.message),
      pagination: {
        currentPage: 0,
        total: 0,
        perPage: 0,
        lastPage: 0
      }
    };
  },
  [M_UPDATE_NOTIFY_STUDIO](state, result) {
    state.updateNotifyStudio = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result.message),
      pagination: {
        currentPage: 0,
        total: 0,
        perPage: 0,
        lastPage: 0
      }
    };
  },
  [M_DELETE_NOTIFY_STUDIO](state, result) {
    state.deleteNotifyStudio = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result.message),
      pagination: {
        currentPage: 0,
        total: 0,
        perPage: 0,
        lastPage: 0
      }
    };
  },
  [M_INITIAL_GET_NOTIFY_STUDIO_STATE](state) {
    state.notifyStudio = {
      code: 0,
      complete: false,
      data: null,
      message: null,
      pagination: {
        currentPage: 0,
        total: 0,
        perPage: 0,
        lastPage: 0
      }
    };
  },
  [M_INITIAL_CREATE_NOTIFY_STUDIO_STATE](state) {
    state.createNotifyStudio = {
      code: 0,
      complete: false,
      data: null,
      message: null,
      pagination: {
        currentPage: 0,
        total: 0,
        perPage: 0,
        lastPage: 0
      }
    };
  },
  [M_INITIAL_UPDATE_NOTIFY_STUDIO_STATE](state) {
    state.updateNotifyStudio = {
      code: 0,
      complete: false,
      data: null,
      message: null,
      pagination: {
        currentPage: 0,
        total: 0,
        perPage: 0,
        lastPage: 0
      }
    };
  },
  [M_INITIAL_DELETE_NOTIFY_STUDIO_STATE](state) {
    state.deleteNotifyStudio = {
      code: 0,
      complete: false,
      data: null,
      message: null,
      pagination: {
        currentPage: 0,
        total: 0,
        perPage: 0,
        lastPage: 0
      }
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
