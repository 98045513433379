import { apiService } from '@/core/services';

export const PRODUCT = {
  getProducts,
  getProduct,
  setVisibility,
  updateProduct
};

const URL = {
  ADMIN: 'admin',
  PRODUCTS: 'products',
  SET_VISIBLE: 'set-visible'
};

function getProducts(data) {
  let requestOptions = {
    params: {
      id: data.id,
      user_id: data.userId,
      name: data.name,
      username: data.username,
      food_type: data.foodType,
      is_popular: data.isPopular,
      is_trending: data.isTrending,
      is_top10: data.isTop10,
      is_active: data.isActive,
      per_page: data.perPage,
      page: data.page,
      prefilter: data.prefilter
    }
  };

  return apiService().get(`/${URL.ADMIN}/${URL.PRODUCTS}`, requestOptions);
}

function getProduct(id) {
  return apiService().get(`/${URL.ADMIN}/${URL.PRODUCTS}/${id}`);
}

function setVisibility(id, data) {
  let requestBody = {
    is_visible: data.isVisible
  };

  return apiService().put(
    `/${URL.ADMIN}/${URL.PRODUCTS}/${id}/${URL.SET_VISIBLE}`,
    requestBody
  );
}

function updateProduct(id, data) {
  let requestBody = {
    is_popular: data.isPopular,
    is_trending: data.isTrending,
    is_recommended: data.isRecommended,
    redeemable: data.redeemable,
    redeemable_point: data.redeemable_point,
    point_campaign_id: data.point_campaign_id,
    filter_category: data.filterCategory,
    uuid: data.uuid,
    is_clear: data.is_clear
  };
  return apiService().put(`/${URL.ADMIN}/${URL.PRODUCTS}/${id}`, requestBody);
}
