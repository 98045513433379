import { apiService } from '@/core/services';
// import { RecipeStatus } from '@/core/constants/enums';

export const PROMOTION_DELIVERY = {
  getRecipesDeliver,
  applyRecipesDelivery,
  applyAllRecipesDelivery,
  deleteRecipesDelivery,
  deleteAllRecipesDelivery,
  getProductsDeliver,
  applyProductsDelivery,
  applyAllProductsDelivery,
  deleteProductsDelivery,
  deleteAllProductsDelivery
};
const URL = {
  ADMIN: 'admin',
  PROMOTION: 'promotion',
  DELIVERY: 'delivery',
  RECIPES: 'recipes',
  PRODUCTS: 'products',
  UPDATE: 'update',
  DELETE: 'delete'
};

function getRecipesDeliver(data) {
  let requestOptions = {
    params: {
      name: data.name,
      creator_name: data.username,
      per_page: data.perPage,
      page: data.page,
      creator_ids: data.creatorId
    }
  };
  return apiService().get(
    `/${URL.ADMIN}/${URL.PROMOTION}/${URL.DELIVERY}/${URL.RECIPES}`,
    requestOptions
  );
}

function applyRecipesDelivery(data) {
  let requestBody = {
    ids: data.ids,
    type: data.promotionOptions,
    value: data.DeliveryAmounts,
    applied_from: data.DeliveryValidityFrom,
    applied_to: data.DeliveryValidityTo
  };
  return apiService().post(
    `/${URL.ADMIN}/${URL.PROMOTION}/${URL.DELIVERY}/${URL.RECIPES}/${URL.UPDATE}`,
    requestBody
  );
}
function applyAllRecipesDelivery(data) {
  let requestBody = {
    creator_ids: data.ids,
    type: data.promotionOptions,
    value: data.DeliveryAmounts,
    applied_from: data.DeliveryValidityFrom,
    applied_to: data.DeliveryValidityTo
  };
  return apiService().post(
    `/${URL.ADMIN}/${URL.PROMOTION}/${URL.DELIVERY}/${URL.RECIPES}/${URL.UPDATE}`,
    requestBody
  );
}

function deleteRecipesDelivery(id) {
  return apiService().delete(
    `/${URL.ADMIN}/${URL.PROMOTION}/${URL.DELIVERY}/${URL.RECIPES}/${id}`
  );
}

function deleteAllRecipesDelivery(id) {
  let requestBody = {
    creator_ids: id
  };
  return apiService().post(
    `/${URL.ADMIN}/${URL.PROMOTION}/${URL.DELIVERY}/${URL.RECIPES}/${URL.DELETE}`,
    requestBody
  );
}

// PRODUCT
function getProductsDeliver(data) {
  let requestOptions = {
    params: {
      name: data.name,
      creator_name: data.username,
      per_page: data.perPage,
      page: data.page,
      creator_ids: data.creatorId
    }
  };
  return apiService().get(
    `/${URL.ADMIN}/${URL.PROMOTION}/${URL.DELIVERY}/${URL.PRODUCTS}`,
    requestOptions
  );
}

function applyProductsDelivery(data) {
  let requestBody = {
    ids: data.ids,
    type: data.promotionOptions,
    value: data.DeliveryAmounts,
    applied_from: data.DeliveryValidityFrom,
    applied_to: data.DeliveryValidityTo
  };
  return apiService().post(
    `/${URL.ADMIN}/${URL.PROMOTION}/${URL.DELIVERY}/${URL.PRODUCTS}/${URL.UPDATE}`,
    requestBody
  );
}

function applyAllProductsDelivery(data) {
  let requestBody = {
    creator_ids: data.ids,
    type: data.promotionOptions,
    value: data.DeliveryAmounts,
    applied_from: data.DeliveryValidityFrom,
    applied_to: data.DeliveryValidityTo
  };
  return apiService().post(
    `/${URL.ADMIN}/${URL.PROMOTION}/${URL.DELIVERY}/${URL.PRODUCTS}/${URL.UPDATE}`,
    requestBody
  );
}

function deleteProductsDelivery(id) {
  return apiService().delete(
    `/${URL.ADMIN}/${URL.PROMOTION}/${URL.DELIVERY}/${URL.PRODUCTS}/${id}`
  );
}

function deleteAllProductsDelivery(id) {
  let requestBody = {
    creator_ids: id
  };
  return apiService().post(
    `/${URL.ADMIN}/${URL.PROMOTION}/${URL.DELIVERY}/${URL.PRODUCTS}/${URL.DELETE}`,
    requestBody
  );
}
