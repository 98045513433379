import { apiService } from '@/core/services';
// import { apiHelper } from '@/core/utils';

export const CONFIG_NOTIFY_CREATOR = {
  getNotifyCreator,
  createNotifyCreator,
  updateNotifyCreator,
  deleteNotifyCreator
};

const URL = {
  ADMIN: 'admin',
  CONTACT_NUMBERS: 'contact-numbers'
};

function getNotifyCreator() {
  return apiService().get(`/${URL.ADMIN}/${URL.CONTACT_NUMBERS}`);
}

function createNotifyCreator(data) {
  let requestBody = {
    contact_number: data.contactNumber
  };
  return apiService().post(`/${URL.ADMIN}/${URL.CONTACT_NUMBERS}`, requestBody);
}

function updateNotifyCreator(id, data) {
  let requestBody = {
    contact_number: data.contactNumber
  };
  return apiService().put(
    `/${URL.ADMIN}/${URL.CONTACT_NUMBERS}/${id}`,
    requestBody
  );
}

function deleteNotifyCreator(id) {
  return apiService().delete(`/${URL.ADMIN}/${URL.CONTACT_NUMBERS}/${id}`);
}
