<template>
  <ReviewAndRatingDetails
    ref="reviewAndRatingDetails"
    :breadcrumbData="breadcrumbData"
    :actions="actions"
    :accessRights="accessRights"
    :backRouteName="backRouteName"
    :visibilityStatusStoreActionsObj="visibilityStatusStoreActionsObj"
    :itemDetailsRouteName="itemDetailsRouteName"
    :appFeatureDetailsRelatedButtonDisabled="
      !checkAccessRight(module.RECIPE, [action.RECIPE_READ])
    "
  ></ReviewAndRatingDetails>
</template>

<script>
  import commonMixin from '@/core/mixins/common.mixin';
  import ReviewAndRatingDetails from '@/views/components/review-and-rating/ReviewAndRatingDetails';
  import { ROUTE_NAME, MODULE, ACTION } from '@/core/constants';
  import { i18nHelper } from '@/core/utils';
  import {
    RECIPE_REVIEW_GET_RECIPE_REVIEW,
    RECIPE_REVIEW_SET_VISIBILITY,
    RECIPE_REVIEW_INITIAL_RECIPE_REVIEW_STATE,
    RECIPE_REVIEW_INITIAL_SET_VISIBILITY_STATE
  } from '@/core/store/recipe-review.module';

  export default {
    name: 'ReviewAndRatingRecipeDetails',
    mixins: [commonMixin],
    components: {
      ReviewAndRatingDetails
    },
    data: () => ({
      module: MODULE,
      action: ACTION,
      breadcrumbData: {},
      actions: {
        readRecord: {
          module: 'recipeReview',
          state: 'recipeReview',
          action: RECIPE_REVIEW_GET_RECIPE_REVIEW,
          title: i18nHelper.getMessage(
            'label.getExperienceCookingReviewDetails'
          ),
          successAction: () => {}
        },
        initialReadRecordState: RECIPE_REVIEW_INITIAL_RECIPE_REVIEW_STATE
      },
      accessRights: {
        moduleName: MODULE.RECIPE_REVIEW,
        actions: {
          create: ACTION.RECIPE_REVIEW_CREATE,
          read: ACTION.RECIPE_REVIEW_READ,
          update: ACTION.RECIPE_REVIEW_UPDATE,
          delete: ACTION.RECIPE_REVIEW_DELETE
        }
      },
      backRouteName: ROUTE_NAME.REVIEW_AND_RATING_EXPERIENCE_COOKING_LIST,
      visibilityStatusStoreActionsObj: {
        module: 'recipeReview',
        state: 'setVisibility',
        actionSet: RECIPE_REVIEW_SET_VISIBILITY,
        actionInitial: RECIPE_REVIEW_INITIAL_SET_VISIBILITY_STATE,
        successAction: () => {},
        title: i18nHelper.getMessage('label.setVisibility'),
        message: i18nHelper.getMessage('label.review')
      },
      itemDetailsRouteName: ROUTE_NAME.EXPERIENCE_COOKING_DETAILS
    }),
    created() {
      this.initReviewAndRatingRecipeDetails();
    },
    methods: {
      importSuccessAction() {
        this.visibilityStatusStoreActionsObj.successAction = () => {
          this.$refs.reviewAndRatingDetails.initialReviewAndRatingDetails();
        };
      },
      initReviewAndRatingRecipeDetails() {
        let breadcrumbData = {
          breadcrumbs: [
            {
              title: i18nHelper.getMessage('label.reviewAndRating'),
              route: { name: ROUTE_NAME.REVIEW_AND_RATING }
            },
            {
              title: i18nHelper.getMessage('label.experienceCooking'),
              route: { name: ROUTE_NAME.REVIEW_AND_RATING_EXPERIENCE_COOKING }
            },
            {
              title: i18nHelper.getMessage(
                'label.experienceCookingReviewAndRatingDetails'
              )
            }
          ],
          actions: [
            {
              text: i18nHelper.getMessage('label.back'),
              function: () => {
                this.$router.go(-1);
              }
            }
          ]
        };

        this.breadcrumbData = breadcrumbData;
        this.importSuccessAction();
      }
    }
  };
</script>

<style></style>
