<template>
  <MerchantList :category="merchantCategory"></MerchantList>
</template>

<script>
  import MerchantList from '@/views/components/mobile-application/merchant/MerchantList';
  import { MerchantCategory } from '@/core/constants/enums';

  export default {
    name: 'MerchantDineInList',
    components: {
      MerchantList
    },
    data: () => ({
      merchantCategory: MerchantCategory.DINE_IN
    })
  };
</script>

<style></style>
