<template>
  <div>
    <!--begin:: App Smart List-->
    <AppSmartList
      ref="appSmartList"
      :breadcrumbData="breadcrumbData"
      :title="$t('label.platformOperationHour')"
      :accessRights="accessRights"
      :filters="filters"
      :actions="actions"
      :tableHeaders="headers"
      :newRecord="newRecord"
      @app-smart-list-action-new-record="actionNewRecord"
      @app-smart-list-open-modal-details="openModalDetails"
    ></AppSmartList>
    <!--end:: AppSmartList-->

    <!--begin:: Platform Operation Hour Modal-->
    <PlatformOperationHourModal
      v-model="platformOperationHourModal.isShow"
      :isNewRecord="platformOperationHourModal.isNewRecord"
      :id="platformOperationHourModal.id"
    ></PlatformOperationHourModal>
    <!--end:: Platform Operation Hour Modal-->
  </div>
</template>

<script>
  import AppSmartList from '@/views/components/bases/smart/AppSmartList.vue';
  import PlatformOperationHourModal from '@/views/components/platform-operation-hour/PlatformOperationHourModal';
  import commonMixin from '@/core/mixins/common.mixin';
  import { i18nHelper } from '@/core/utils';
  import { MODULE, ACTION, APP_COMPONENT_NAME } from '@/core/constants';
  import {
    PLATFORM_OPERATION_HOUR_GET_PLATFORM_OPERATION_HOURS,
    PLATFORM_OPERATION_HOUR_DELETE_PLATFORM_OPERATION_HOUR,
    PLATFORM_OPERATION_HOUR_INITIAL_GET_PLATFORM_OPERATION_HOURS_STATE,
    PLATFORM_OPERATION_HOUR_INITIAL_DELETE_PLATFORM_OPERATION_HOUR_STATE
  } from '@/core/store/platform-operation-hour.module';

  export default {
    name: 'PlatformOperationHour',
    components: {
      AppSmartList,
      PlatformOperationHourModal
    },
    mixins: [commonMixin],
    data: () => ({
      breadcrumbData: {
        breadcrumbs: [
          { title: i18nHelper.getMessage('label.platformOperationHour') }
        ]
      },
      accessRights: {
        moduleName: MODULE.PLATFORM_OPERATION_HOUR,
        actions: {
          create: ACTION.PLATFORM_OPERATION_HOUR_CREATE,
          read: ACTION.PLATFORM_OPERATION_HOUR_READ,
          update: ACTION.PLATFORM_OPERATION_HOUR_UPDATE,
          delete: ACTION.PLATFORM_OPERATION_HOUR_DELETE
        }
      },
      filters: [],
      actions: {
        viewList: {
          module: 'platformOperationHour',
          state: 'platformOperationHours',
          action: PLATFORM_OPERATION_HOUR_GET_PLATFORM_OPERATION_HOURS,
          initialStateAction:
            PLATFORM_OPERATION_HOUR_INITIAL_GET_PLATFORM_OPERATION_HOURS_STATE,
          title: i18nHelper.getMessage('label.getPlatformOperationHours')
        },
        deleteRecord: {
          module: 'platformOperationHour',
          state: 'deletePlatformOperationHour',
          action: PLATFORM_OPERATION_HOUR_DELETE_PLATFORM_OPERATION_HOUR,
          initialAction:
            PLATFORM_OPERATION_HOUR_INITIAL_DELETE_PLATFORM_OPERATION_HOUR_STATE,
          title: i18nHelper.getMessage('label.deletePlatformOperationHour'),
          message: i18nHelper.getMessage('message.requestDeleteMessage', [
            i18nHelper.getMessage('label.operationHour').toLowerCase()
          ]),
          successAction: () => {}
        }
      },
      headers: [
        {
          key: 'no',
          name: 'no',
          active: true,
          sortable: true
        },
        {
          key: 'startDate',
          name: 'startDate',
          active: true,
          sortable: true
        },
        {
          key: 'endDate',
          name: 'endDate',
          active: true,
          sortable: true
        },
        {
          key: 'startTime',
          name: 'startTime',
          active: true,
          sortable: true
        },
        {
          key: 'endTime',
          name: 'endTime',
          active: true,
          sortable: true
        },
        {
          key: 'createdBy',
          name: 'createdBy',
          active: true,
          sortable: true,
          class: 'min-w-130px'
        },
        {
          key: 'actions',
          name: 'actions',
          active: true,
          action: true,
          layout: APP_COMPONENT_NAME.APP_TABLE_ACTIONS,
          layoutActions: {
            isModal: true,
            hasActionDelete: true
          }
        }
      ],
      newRecord: {
        show: true,
        buttonTitle: i18nHelper.getMessage('label.newOperationHour'),
        isModal: true
      },
      platformOperationHourModal: {
        isShow: false,
        isNewRecord: false,
        id: 0
      }
    }),
    created() {
      this.initPlatformOperationHourList();
    },
    methods: {
      actionNewRecord() {
        this.openPlatformOperationHourModal(true);
      },
      openModalDetails(value) {
        this.openPlatformOperationHourModal(false, value);
      },
      openPlatformOperationHourModal(isNewRecord, id) {
        this.platformOperationHourModal = {
          isShow: true,
          isNewRecord: isNewRecord,
          id: id
        };
      },
      importSuccessAction() {
        this.actions.deleteRecord.successAction = () => {
          this.$refs.appSmartList.initAppSmartList();
        };
      },
      initPlatformOperationHourList() {
        this.importSuccessAction();
      }
    }
  };
</script>

<style></style>
