import { apiService } from '@/core/services';
import { apiHelper } from '@/core/utils';

export const USER = {
  getUsers,
  getUser,
  updateUserActiveStatus,
  getUserAddressBooks,
  getUserAddressBook,
  getUserCreatorProfile,
  getCreatorAddressBooks,
  getCreatorAddressBook,
  getUserReferees,
  getUserVouchers,
  getUserCreatorVouchers,
  updateUserCreatorProfile,
  updateUserReferralCode
};

const URL = {
  ADMIN: 'admin',
  USERS: 'users',
  SET_ACTIVE: 'set-active',
  CREATOR_SET_ACTIVE: 'creator-set-active',
  APPROVE_CREATOR: 'approve-creator',
  ADDRESS_BOOKS: 'address-books',
  CREATOR_PROFILE: 'creator-profile',
  CREATOR_ADDRESS_BOOKS: 'creator-address-books',
  REFEREES: 'referees',
  VOUCHERS: 'vouchers',
  CREATOR_VOUCHERS: 'creator-vouchers',
  SET_REFERRAL_CODE: 'update-referral-code'
};

function getUsers(data) {
  let requestOptions = {
    params: {
      email: data.email,
      username: data.username,
      contact_number: data.contactNumber,
      is_active: data.isActive,
      is_creator: data.isCreator,
      created_at_from: apiHelper.dateTimeStart(data.dateRange),
      created_at_to: apiHelper.dateTimeEnd(data.dateRange),
      register_platform: data.registerPlatform,
      platform_using: data.platformUsing,
      per_page: data.perPage,
      page: data.page
    }
  };
  return apiService().get(`/${URL.ADMIN}/${URL.USERS}`, requestOptions);
}

function getUser(id) {
  return apiService().get(`/${URL.ADMIN}/${URL.USERS}/${id}`);
}

function updateUserActiveStatus(id, isActive, type) {
  let requestBody;
  if (type == 'user') {
    requestBody = {
      is_active: isActive
    };
  } else {
    requestBody = {
      creator_is_active: isActive
    };
  }
  return apiService().put(
    `/${URL.ADMIN}/${URL.USERS}/${id}/${
      type == 'user' ? URL.SET_ACTIVE : URL.CREATOR_SET_ACTIVE
    }`,
    requestBody
  );
}

function getUserAddressBooks(id, data) {
  let requestOptions = {
    params: {
      per_page: data.perPage,
      page: data.page
    }
  };
  return apiService().get(
    `/${URL.ADMIN}/${URL.USERS}/${id}/${URL.ADDRESS_BOOKS}`,
    requestOptions
  );
}

function getUserAddressBook(userId, addressBookId) {
  return apiService().get(
    `/${URL.ADMIN}/${URL.USERS}/${userId}/${URL.ADDRESS_BOOKS}/${addressBookId}`
  );
}

function getUserCreatorProfile(id) {
  return apiService().get(
    `/${URL.ADMIN}/${URL.USERS}/${id}/${URL.CREATOR_PROFILE}`
  );
}

function getCreatorAddressBooks(id, data) {
  let requestOptions = {
    params: {
      per_page: data.perPage,
      page: data.page
    }
  };
  return apiService().get(
    `/${URL.ADMIN}/${URL.USERS}/${id}/${URL.CREATOR_ADDRESS_BOOKS}`,
    requestOptions
  );
}

function getCreatorAddressBook(parentId, id) {
  return apiService().get(
    `/${URL.ADMIN}/${URL.USERS}/${parentId}/${URL.CREATOR_ADDRESS_BOOKS}/${id}`
  );
}

function getUserReferees(id, data) {
  let requestOptions = {
    params: {
      per_page: data.perPage,
      page: data.page
    }
  };
  return apiService().get(
    `/${URL.ADMIN}/${URL.USERS}/${id}/${URL.REFEREES}`,
    requestOptions
  );
}

function getUserVouchers(id, data) {
  let requestOptions = {
    params: {
      per_page: data.perPage,
      page: data.page
    }
  };
  return apiService().get(
    `/${URL.ADMIN}/${URL.USERS}/${id}/${URL.VOUCHERS}`,
    requestOptions
  );
}

function getUserCreatorVouchers(id, data) {
  let requestOptions = {
    params: {
      per_page: data.perPage,
      page: data.page
    }
  };
  return apiService().get(
    `/${URL.ADMIN}/${URL.USERS}/${id}/${URL.CREATOR_VOUCHERS}`,
    requestOptions
  );
}

function updateUserCreatorProfile(id, data) {
  let requestBody = {
    service_type: data.serviceType,
    description: data.description,
    voucher_pin_verification: data.pinVerification
      ? data.pinVerification.toUpperCase()
      : '',
    cuisine_type: data.cuisineType,
    website_link: data.websiteLink,
    tax_rate: data.taxRate / 100
  };
  return apiService().put(
    `/${URL.ADMIN}/${URL.USERS}/${id}/${URL.CREATOR_PROFILE}`,
    requestBody
  );
}

function updateUserReferralCode(id, referralCode) {
  let requestBody = {
    referral_code: referralCode
  };
  return apiService().put(
    `/${URL.ADMIN}/${URL.USERS}/${id}/${URL.SET_REFERRAL_CODE}`,
    requestBody
  );
}
