import { POINT } from '@/core/api';
import { apiHelper } from '@/core/utils';
import { PointSourceType } from '@/core/constants/enums';
import {
  LOADING_OPEN_LOADING,
  LOADING_CLOSE_LOADING
} from '@/core/store/loading.module';
import dateFormat from '@/core/filters/dateFormat.filter';
// import constantsDescription from '@/core/filters/constants-description.filter';

/* Define Module Name */
const MODULE_NAME = 'point/';

/* Define Action Name */
const A_GET_POINTS = 'getPoints';
const A_GET_POINT = 'getPoint';
const A_GET_POINT_CLAIMED_USERS = 'getPointClaimedUsers';
const A_GET_POINT_SPECIFIC_USERS = 'getPointSpecificUsers';
const A_CREATE_POINT = 'createPoint';
const A_CREATE_POINT_SPECIFIC_USERS = 'createPointSpecificUsers';
const A_UPDATE_POINT = 'updatePoint';
const A_UPDATE_POINT_ACTIVATE = 'updatePointActivate';
const A_DELETE_POINT = 'deletePoint';
const A_DELETE_POINT_SPECIFIC_USERS = 'deletePointSpecificUsers';

/* Define Reset State Action Name */
const A_INITIAL_GET_POINTS_STATE = 'initialGetPointsState';
const A_INITIAL_GET_POINT_STATE = 'initialGetPointState';
const A_INITIAL_GET_POINT_CLAIMED_USERS_STATE =
  'initialGetPointClaimedUsersState';
const A_INITIAL_GET_POINT_SPECIFIC_USERS_STATE =
  'initialGetPointSpecificUsersState';
const A_INITIAL_CREATE_POINT_STATE = 'initialcreatePointState';
const A_INITIAL_CREATE_POINT_SPECIFIC_USERS_STATE =
  'initialcreatePointSpecificUsersState';
const A_INITIAL_UPDATE_POINT_STATE = 'initialUpdatePointState';
const A_INITIAL_UPDATE_POINT_ACTIVATE_STATE =
  'initialUpdatePointActivateState';
const A_INITIAL_DELETE_POINT_STATE = 'initialdeletePointState';
const A_INITIAL_DELETE_POINT_SPECIFIC_USERS_STATE =
  'initialdeletePointSpecificUsersState';

/* Define Getter Name */

/* Define Mutation Name */
const M_GET_POINTS = 'setGetPoints';
const M_GET_POINT = 'setGetPoint';
const M_GET_POINT_CLAIMED_USERS = 'setGetPointClaimedUsers';
const M_GET_POINT_SPECIFIC_USERS = 'setGetPointSpecificUsers';
const M_CREATE_POINT = 'setcreatePoint';
const M_CREATE_POINT_SPECIFIC_USERS = 'setcreatePointSpecificUsers';
const M_UPDATE_POINT = 'setUpdatePoint';
const M_UPDATE_POINT_ACTIVATE = 'setUpdatePointActivate';
const M_DELETE_POINT = 'setdeletePoint';
const M_DELETE_POINT_SPECIFIC_USERS = 'setdeletePointSpecificUsers';

/* Define Reset State Mutation Name */
const M_INITIAL_GET_POINTS_STATE = 'setInitialGetPointsState';
const M_INITIAL_GET_POINT_STATE = 'setInitialGetPointState';
const M_INITIAL_GET_POINT_CLAIMED_USERS_STATE =
  'setInitialGetPointClaimedUsersState';
const M_INITIAL_GET_POINT_SPECIFIC_USERS_STATE =
  'setInitialGetPointSpecificUsersState';
const M_INITIAL_CREATE_POINT_STATE = 'setInitialcreatePointState';
const M_INITIAL_CREATE_POINT_SPECIFIC_USERS_STATE =
  'setInitialcreatePointSpecificUsersState';
const M_INITIAL_UPDATE_POINT_STATE = 'setInitialUpdatePointState';
const M_INITIAL_UPDATE_POINT_ACTIVATE_STATE =
  'setInitialUpdatePointActivateState';
const M_INITIAL_DELETE_POINT_STATE = 'setInitialdeletePointState';
const M_INITIAL_DELETE_POINT_SPECIFIC_USERS_STATE =
  'setInitialdeletePointSpecificUsersState';

/* Define Export Name */
export const POINT_GET_POINTS = MODULE_NAME + A_GET_POINTS;
export const POINT_GET_POINT = MODULE_NAME + A_GET_POINT;
export const POINT_GET_POINT_CLAIMED_USERS =
  MODULE_NAME + A_GET_POINT_CLAIMED_USERS;
export const POINT_GET_POINT_SPECIFIC_USERS =
  MODULE_NAME + A_GET_POINT_SPECIFIC_USERS;
export const POINT_CREATE_POINT = MODULE_NAME + A_CREATE_POINT;
export const POINT_CREATE_POINT_SPECIFIC_USERS =
  MODULE_NAME + A_CREATE_POINT_SPECIFIC_USERS;
export const POINT_UPDATE_POINT = MODULE_NAME + A_UPDATE_POINT;
export const POINT_UPDATE_POINT_ACTIVATE =
  MODULE_NAME + A_UPDATE_POINT_ACTIVATE;
export const POINT_DELETE_POINT = MODULE_NAME + A_DELETE_POINT;
export const POINT_DELETE_POINT_SPECIFIC_USERS =
  MODULE_NAME + A_DELETE_POINT_SPECIFIC_USERS;
/* Define Reset State Name */
export const POINT_INITIAL_GET_POINTS_STATE =
  MODULE_NAME + A_INITIAL_GET_POINTS_STATE;
export const POINT_INITIAL_GET_POINT_STATE =
  MODULE_NAME + A_INITIAL_GET_POINT_STATE;
export const POINT_INITIAL_GET_POINT_CLAIMED_USERS_STATE =
  MODULE_NAME + A_INITIAL_GET_POINT_CLAIMED_USERS_STATE;
export const POINT_INITIAL_GET_POINT_SPECIFIC_USERS_STATE =
  MODULE_NAME + A_INITIAL_GET_POINT_SPECIFIC_USERS_STATE;
export const POINT_INITIAL_CREATE_POINT_STATE =
  MODULE_NAME + A_INITIAL_CREATE_POINT_STATE;
export const POINT_INITIAL_CREATE_POINT_SPECIFIC_USERS_STATE =
  MODULE_NAME + A_INITIAL_CREATE_POINT_SPECIFIC_USERS_STATE;
export const POINT_INITIAL_UPDATE_POINT_STATE =
  MODULE_NAME + A_INITIAL_UPDATE_POINT_STATE;
export const POINT_INITIAL_UPDATE_POINT_ACTIVATE_STATE =
  MODULE_NAME + A_INITIAL_UPDATE_POINT_ACTIVATE_STATE;
export const POINT_INITIAL_DELETE_POINT_STATE =
  MODULE_NAME + A_INITIAL_DELETE_POINT_STATE;
export const POINT_INITIAL_DELETE_POINT_SPECIFIC_USERS_STATE =
  MODULE_NAME + A_INITIAL_DELETE_POINT_SPECIFIC_USERS_STATE;

const state = {
  points: {
    code: 0,
    complete: false,
    data: null,
    message: null,
    pagination: {
      currentPage: 0,
      total: 0,
      perPage: 0,
      lastPage: 0
    }
  },
  point: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  pointClaimedUsers: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  pointSpecificUsers: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  createPoint: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  createPointSpecificUsers: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  updatePoint: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  updatePointActivate: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  deletePoint: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  deletePointSpecificUsers: {
    code: 0,
    complete: false,
    data: null,
    message: null
  }
};

//Getters
const getters = {};

// Actions
const actions = {
  async [A_GET_POINTS]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    await POINT.getPoints(data)
      .then((respone) => {
        let result = respone;
        commit(M_GET_POINTS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_GET_POINTS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_POINT]({ dispatch, commit }, { id }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    POINT.getPointId(id)
      .then((respone) => {
        let result = respone;
        commit(M_GET_POINT, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_GET_POINT, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_POINT_CLAIMED_USERS]({ dispatch, commit }, { id, data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    POINT.getPointClaimedUsers(id, data)
      .then((respone) => {
        let result = respone;
        commit(M_GET_POINT_CLAIMED_USERS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_GET_POINT_CLAIMED_USERS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_POINT_SPECIFIC_USERS]({ dispatch, commit }, { id, data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    POINT.getPointSpecificUsers(id, data)
      .then((respone) => {
        let result = respone;
        commit(M_GET_POINT_SPECIFIC_USERS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_GET_POINT_SPECIFIC_USERS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_CREATE_POINT]({ dispatch, commit }, { formData }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    POINT.createPointId(formData)
      .then((respone) => {
        let result = respone;
        commit(M_CREATE_POINT, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_CREATE_POINT, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_CREATE_POINT_SPECIFIC_USERS]({ dispatch, commit }, { id, formData }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    POINT.createPointSpecificUsers(id, formData)
      .then((respone) => {
        let result = respone;
        commit(M_CREATE_POINT_SPECIFIC_USERS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_CREATE_POINT_SPECIFIC_USERS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_UPDATE_POINT]({ dispatch, commit }, { id, formData }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    POINT.updatePointId(id, formData)
      .then((respone) => {
        let result = respone;
        commit(M_UPDATE_POINT, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_UPDATE_POINT, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_UPDATE_POINT_ACTIVATE]({ dispatch, commit }, { id, data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    POINT.updatePointIdActivate(id, data)
      .then((respone) => {
        let result = respone;
        commit(M_UPDATE_POINT_ACTIVATE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_UPDATE_POINT_ACTIVATE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_DELETE_POINT]({ dispatch, commit }, { id }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    POINT.deletePointId(id)
      .then((respone) => {
        let result = respone;
        commit(M_DELETE_POINT, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_DELETE_POINT, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_DELETE_POINT_SPECIFIC_USERS]({ dispatch, commit }, { id, formData }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    POINT.deletePointSpecificUsers(id, formData)
      .then((respone) => {
        let result = respone;
        commit(M_DELETE_POINT_SPECIFIC_USERS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_DELETE_POINT_SPECIFIC_USERS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_INITIAL_GET_POINTS_STATE]({ commit }) {
    commit(M_INITIAL_GET_POINTS_STATE);
  },
  [A_INITIAL_GET_POINT_STATE]({ commit }) {
    commit(M_INITIAL_GET_POINT_STATE);
  },
  [A_INITIAL_GET_POINT_CLAIMED_USERS_STATE]({ commit }) {
    commit(M_INITIAL_GET_POINT_CLAIMED_USERS_STATE);
  },
  [A_INITIAL_GET_POINT_SPECIFIC_USERS_STATE]({ commit }) {
    commit(M_INITIAL_GET_POINT_SPECIFIC_USERS_STATE);
  },
  [A_INITIAL_CREATE_POINT_STATE]({ commit }) {
    commit(M_INITIAL_CREATE_POINT_STATE);
  },
  [A_INITIAL_CREATE_POINT_SPECIFIC_USERS_STATE]({ commit }) {
    commit(M_INITIAL_CREATE_POINT_SPECIFIC_USERS_STATE);
  },
  [A_INITIAL_UPDATE_POINT_STATE]({ commit }) {
    commit(M_INITIAL_UPDATE_POINT_STATE);
  },
  [A_INITIAL_UPDATE_POINT_ACTIVATE_STATE]({ commit }) {
    commit(M_INITIAL_UPDATE_POINT_ACTIVATE_STATE);
  },
  [A_INITIAL_DELETE_POINT_STATE]({ commit }) {
    commit(M_INITIAL_DELETE_POINT_STATE);
  },
  [A_INITIAL_DELETE_POINT_SPECIFIC_USERS_STATE]({ commit }) {
    commit(M_INITIAL_DELETE_POINT_SPECIFIC_USERS_STATE);
  }
};

// Mutations
const mutations = {
  [M_GET_POINTS](state, result) {
    if (result.code === 0) {
      let d = result.data;
      let p = result.pagination;
      let points = [];

      d.forEach((x, index) => {
        points.push({
          no: index + 1,
          id: x.id,
          eventName: x.event_name,
          pointTitle: x.title,
          quantity: x.quantity,
          status: x.status,
          availableFrom: dateFormat(x.available_from),
          availableTo: dateFormat(x.available_to)
        });
      });

      state.points = {
        code: result.code,
        complete: true,
        data: points,
        pagination: {
          currentPage: p.current_page,
          total: p.total,
          perPage: p.per_page,
          lastPage: p.last_page
        }
      };
    } else {
      state.points = {
        code: result.code,
        complete: true,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_GET_POINT](state, result) {
    try {
      if (result.code === 0) {
        let d = result.data;
        console.log(d);
        let merchantDetails = d.point_merchant;
        let pointObj;

        let pointSource = merchantDetails.point_source;
        let selectedCreator = '';
        let selectedDineMerchant = '';
        let selectedBranch = [];
        let totalSpecific = '';
        let products = [];

        if (
            pointSource == PointSourceType.MERCHANT ||
            pointSource == PointSourceType.CREATOR
        ) {
          if (pointSource == PointSourceType.MERCHANT) {
            selectedDineMerchant = merchantDetails.details.merchant_ids;
          } else if (pointSource == PointSourceType.CREATOR) {
            selectedCreator = merchantDetails.details.merchant_ids;
          }
          merchantDetails.details.merchant_branches.forEach((x) => {
            selectedBranch.push(x.id);
          });

          d.products.forEach((x) => {
            products.push(x.id);
          });

          totalSpecific = d.total_user;
        }
        pointObj = {
          id: d.id,
          //status: merchantDetails.status,
          //totalClaim: d.total_claim,
          // Basic Information
          applyTo: d.apply_to,
          pointSource: pointSource,
          selectedCreator: selectedCreator,
          selectedDineMerchant: selectedDineMerchant,
          selectedBranch: selectedBranch,
          products: products,
          //groupName: d.grouping_name,
          availableFrom: dateFormat(d.available_from),
          availableTo: dateFormat(d.available_to),
          quantity: d.quantity,
          eventName: d.event_name,
          eventType: d.event_type.split(','),
          earnType: {
            type: d.earn_type,
            value: d.earn_value
          },
          totalSpecific: totalSpecific,
          // Reward Setting
          // pointCode: d.point_code,
          pointValueType: {
            type: d.value_type,
            value: d.value
          },
          minimumSpending: d.minimum_spending,
          pointValidityDay: d.point_validity_day,
          //pointType: d.point_type,
          isActive: d.is_active,
          stackable: d.stackable,
          banner: d.banner,
          thumbnail: d.thumbnail,
          title: d.title,
          description: d.description,
          redemptionPeriodFrom: dateFormat(d.redemption_period_from),
          redemptionPeriodTo: dateFormat(d.redemption_period_to),
          tnc: d.tnc
        };
        state.point = {
          code: result.code,
          complete: true,
          data: pointObj
        };
      } else {
        state.point = {
          code: result.code,
          complete: true,
          message: apiHelper.sortOutMessage(result.message)
        };
      }
    } catch (e) {
      console.log(e);
    }
  },
  [M_GET_POINT_CLAIMED_USERS](state, result) {
    if (result.code === 0) {
      let d = result.data;
      let p = result.pagination;
      let claimedUsersObj = [];

      d.forEach((x, index) => {
        claimedUsersObj.push({
          no: index + 1,
          id: x.id,
          userId: x.user_id,
          username: { id: x.user_id, name: x.username },
          point: x.point
        });
      });

      state.pointClaimedUsers = {
        code: result.code,
        complete: true,
        data: claimedUsersObj,
        pagination: {
          currentPage: p.current_page,
          total: p.total,
          perPage: p.per_page,
          lastPage: p.last_page
        }
      };
    } else {
      state.pointClaimedUsers = {
        code: result.code,
        complete: true,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_GET_POINT_SPECIFIC_USERS](state, result) {
    if (result.code === 0) {
      let d = result.data;
      let p = result.pagination;
      let specificUsersObj = [];
      d.forEach((x, index) => {
        specificUsersObj.push({
          no: index + 1,
          id: x.id,
          userId: x.id,
          username: { id: x.id, name: x.username },
          point: x.point,
          contactNumber: x.contact_number,
          pointClaimDate: dateFormat(x.point_claim_date)
        });
      });

      state.pointSpecificUsers = {
        code: result.code,
        complete: true,
        data: specificUsersObj,
        pagination: {
          currentPage: p.current_page,
          total: p.total,
          perPage: p.per_page,
          lastPage: p.last_page
        }
      };
    } else {
      state.pointSpecificUsers = {
        code: result.code,
        complete: true,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_CREATE_POINT](state, result) {
    if (result.code === 0) {
      let d = result.data;

      state.createPoint = {
        code: result.code,
        complete: true,
        data: d,
        message: apiHelper.sortOutMessage(result.message)
      };
    } else {
      state.createPoint = {
        code: result.code,
        complete: true,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_CREATE_POINT_SPECIFIC_USERS](state, result) {
    if (result.code === 0) {
      let d = result.data;

      state.createPointSpecificUsers = {
        code: result.code,
        complete: true,
        data: d,
        message: apiHelper.sortOutMessage(result.message)
      };
    } else {
      state.createPointSpecificUsers = {
        code: result.code,
        complete: true,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_UPDATE_POINT](state, result) {
    if (result.code === 0) {
      let d = result.data;

      state.updatePoint = {
        code: result.code,
        complete: true,
        data: d,
        message: apiHelper.sortOutMessage(result.message)
      };
    } else {
      state.updatePoint = {
        code: result.code,
        complete: true,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_UPDATE_POINT_ACTIVATE](state, result) {
    if (result.code === 0) {
      let d = result.data;

      state.updatePointActivate = {
        code: result.code,
        complete: true,
        data: d,
        message: apiHelper.sortOutMessage(result.message)
      };
    } else {
      state.updatePointActivate = {
        code: result.code,
        complete: true,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_DELETE_POINT](state, result) {
    if (result.code === 0) {
      let d = result.data;

      state.deletePoint = {
        code: result.code,
        complete: true,
        data: d,
        message: apiHelper.sortOutMessage(result.message)
      };
    } else {
      state.deletePoint = {
        code: result.code,
        complete: true,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_DELETE_POINT_SPECIFIC_USERS](state, result) {
    if (result.code === 0) {
      let d = result.data;

      state.deletePointSpecificUsers = {
        code: result.code,
        complete: true,
        data: d,
        message: apiHelper.sortOutMessage(result.message)
      };
    } else {
      state.deletePointSpecificUsers = {
        code: result.code,
        complete: true,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_INITIAL_GET_POINTS_STATE](state) {
    state.points = {
      code: 0,
      complete: false,
      data: null,
      message: null,
      pagination: {
        currentPage: 0,
        total: 0,
        perPage: 0,
        lastPage: 0
      }
    };
  },
  [M_INITIAL_GET_POINT_STATE](state) {
    state.point = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_GET_POINT_CLAIMED_USERS_STATE](state) {
    state.pointClaimedUsers = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_GET_POINT_SPECIFIC_USERS_STATE](state) {
    state.pointSpecificUsers = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_CREATE_POINT_STATE](state) {
    state.createPoint = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_CREATE_POINT_SPECIFIC_USERS_STATE](state) {
    state.createPointSpecificUsers = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_UPDATE_POINT_STATE](state) {
    state.updatePoint = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_UPDATE_POINT_ACTIVATE_STATE](state) {
    state.updatePointActivate = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_DELETE_POINT_STATE](state) {
    state.deletePoint = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_DELETE_POINT_SPECIFIC_USERS_STATE](state) {
    state.deletePointSpecificUsers = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
