<template>
  <div>
    <AppSmartList
      :key="reload"
      :breadcrumbData="breadcrumbData"
      :accessRights="accessRights"
      :filters="filters"
      :actions="actions"
      :tableHeaders="headers"
      :applyAllbtn="applyAllbtn"
      menu="delivery"
      @app-smart-list-action-promottion-apply="updatePromotionDeliver"
      @app-smart-list-action-promottion-apply-all="updateAllPromotionDeliver"
      @app-smart-list-action-promottion-delete="promotionDeliverDelete"
      @app-smart-list-action-promottion-delete-all="promotionDeliverDeleteAll"
    ></AppSmartList>
    <AppModalInfo
      :show="infoModal.show"
      :title="infoModal.title"
      :messages="infoModal.messages"
      :buttons="infoModal.buttons"
      :textbox="infoModal.textbox"
      :dropbox="infoModal.dropbox"
    ></AppModalInfo>
  </div>
</template>

<script>
  import AppSmartList from '@/views/components/bases/smart/AppSmartList.vue';
  import AppModalInfo from '@/views/components/bases/modal/AppModalInfo.vue';
  import commonMixin from '@/core/mixins/common.mixin';
  import { i18nHelper, uiHelper } from '@/core/utils';
  import {
    MODULE,
    ACTION,
    APP_COMPONENT_NAME,
    SMART_FORM_FIELD_TYPE,
    LOCAL
  } from '@/core/constants';
  import { listService } from '@/core/services';
  import {
    PROMOTION_RECIPES_GET_DELIVERYS,
    PROMOTION_RECIPES_UPDATE_DELIVERY,
    PROMOTION_RECIPES_UPDATE_ALL_DELIVERY,
    PROMOTION_RECIPES_DELETE_DELIVERY,
    PROMOTION_RECIPES_DELETE_ALL_DELIVERY,
    PROMOTION_RECIPES_INITIAL_GET_DELIVERYS_STATE,
    PROMOTION_RECIPES_INITIAL_UPDATE_DELIVERY_STATE,
    PROMOTION_RECIPES_INITIAL_UPDATE_ALL_DELIVERY_STATE,
    PROMOTION_RECIPES_INITIAL_DELETE_DELIVERY_STATE,
    PROMOTION_RECIPES_INITIAL_DELETE_ALL_DELIVERY_STATE
  } from '@/core/store/promoiton-recipes-delivery.module';

  const CONSTANTS_DATA = JSON.parse(uiHelper.getLocalStorage(LOCAL.CONSTANTS));

  export default {
    name: 'PromotionRecipesDeliveryList',
    mixins: [commonMixin],
    components: {
      AppSmartList,
      AppModalInfo
    },
    data: () => ({
      reload: 0,
      breadcrumbData: {
        breadcrumbs: [
          {
            title: i18nHelper.getMessage('label.delivery')
          },
          { title: i18nHelper.getMessage('label.deliveryRecipesPromotionList') }
        ]
      },
      accessRights: {
        moduleName: MODULE.RECIPE,
        actions: {
          create: ACTION.RECIPE_CREATE,
          read: ACTION.RECIPE_READ,
          update: ACTION.RECIPE_UPDATE,
          delete: ACTION.RECIPE_DELETE
        }
      },
      filters: [
        {
          label: 'creator',
          key: 'creatorId',
          options: listService.getCreatorId(),
          type: SMART_FORM_FIELD_TYPE.SEARCH_SELECT
        },
        {
          label: 'name',
          key: 'name'
        }
      ],
      actions: {
        viewList: {
          module: 'promotionRecipesDelivery',
          state: 'recipesDelivery',
          action: PROMOTION_RECIPES_GET_DELIVERYS,
          initialStateAction: PROMOTION_RECIPES_INITIAL_GET_DELIVERYS_STATE,
          title: i18nHelper.getMessage('label.getExperienceCookingList')
        }
      },
      headers: [
        {
          key: 'no',
          name: 'no',
          active: true
        },
        {
          key: 'name',
          name: 'name',
          active: true,
          class: 'min-w-100px'
        },
        {
          key: 'creatorName',
          name: 'creator',
          active: true,
          // sortable: true,
          class: 'min-w-100px'
        },
        {
          key: 'deliveryDiscount',
          name: 'deliveryDiscount',
          options: listService.getDynamicList(CONSTANTS_DATA.deliveryDiscount),
          optionLabel: 'text',
          layout: APP_COMPONENT_NAME.APP_DROPDOWN,
          active: true
        },
        {
          key: 'deliveryAmounts',
          name: 'deliveryAmounts',
          precisionNum: 0,
          active: true,
          layout: APP_COMPONENT_NAME.APP_TEXTFIELDS
        },
        {
          key: 'deliveryValidityFrom',
          name: 'deliveryValidityFrom',
          active: true,
          layout: APP_COMPONENT_NAME.APP_DATETIMEPACKERFROM
        },
        {
          key: 'deliveryValidityTo',
          name: 'deliveryValidityTo',
          active: true,
          layout: APP_COMPONENT_NAME.APP_DATETIMEPACKERTO
        },
        {
          key: 'apply',
          name: 'apply',
          active: true,
          layout: APP_COMPONENT_NAME.APP_TABLE_APPLY_BUTTON,
          headersClass: 'app-table-actions'
        },
        {
          key: 'delete',
          name: 'delete',
          active: true,
          layout: APP_COMPONENT_NAME.APP_TABLE_DELETE_BUTTON,
          headersClass: 'app-table-actions'
        }
      ],
      infoModal: {
        show: false,
        title: '',
        messages: [],
        buttons: [],
        textbox: '',
        dropbox: []
      },
      applyAllbtn: [
        {
          ids: 1,
          deliveryDiscount: null,
          deliveryAmounts: null,
          deliveryValidityFrom: null,
          deliveryValidityTo: null,
          checkPromotionDeliveryAll: true
        }
      ]
    }),
    mounted() {},
    computed: {
      updatePromotionRecipeComplete() {
        return this.$store.state.promotionRecipesDelivery.updateRecipeDelivery
          .complete;
      },
      updateAllPromotionRecipeComplete() {
        return this.$store.state.promotionRecipesDelivery
          .updateAllRecipeDelivery.complete;
      },
      deletePromotionRecipeComplete() {
        return this.$store.state.promotionRecipesDelivery.deleteRecipeDelivery
          .complete;
      },
      deleteAllPromotionRecipeComplete() {
        return this.$store.state.promotionRecipesDelivery
          .deleteAllRecipeDelivery.complete;
      }
    },
    watch: {
      updatePromotionRecipeComplete() {
        let response =
          this.$store.state.promotionRecipesDelivery.updateRecipeDelivery;
        let title = i18nHelper.getMessage('label.deliveryRecipesPromotionList');
        let messages = response.message;
        let buttons = [];
        this.initialUpdatePromotionDeliverlyState();

        if (response.code == 0) {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();
              this.reload++;
            }
          });
        } else {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();
            }
          });
        }
        if (messages) this.openLayoutAppModalInfo(title, messages, buttons);
      },
      updateAllPromotionRecipeComplete() {
        let response =
          this.$store.state.promotionRecipesDelivery.updateAllRecipeDelivery;
        let title = i18nHelper.getMessage('label.deliveryRecipesPromotionList');
        let messages = response.message;
        let buttons = [];
        this.initialUpdatePromotionAllDeliverlyState();

        if (response.code == 0) {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();
              this.reload++;
            }
          });
        } else {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();
            }
          });
        }
        if (messages) this.openLayoutAppModalInfo(title, messages, buttons);
      },
      deletePromotionRecipeComplete() {
        let response =
          this.$store.state.promotionRecipesDelivery.deleteRecipeDelivery;
        let title = i18nHelper.getMessage('label.deliveryRecipesPromotionList');
        let messages = response.message;
        let buttons = [];
        this.initialDeletePromotionDeliverlyState();
        if (response.code == 0) {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();
              this.reload++;
            }
          });
        } else {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();
            }
          });
        }
        if (messages) this.openLayoutAppModalInfo(title, messages, buttons);
      },
      deleteAllPromotionRecipeComplete() {
        let response =
          this.$store.state.promotionRecipesDelivery.deleteAllRecipeDelivery;
        let title = i18nHelper.getMessage('label.deliveryRecipesPromotionList');
        let messages = response.message;
        let buttons = [];
        this.initialDeletePromotionAllDeliverlyState();

        if (response.code == 0) {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();
              this.reload++;
            }
          });
        } else {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();
            }
          });
        }
        if (messages) this.openLayoutAppModalInfo(title, messages, buttons);
      }
    },
    methods: {
      updatePromotionDeliver(data) {
        this.$store.dispatch(PROMOTION_RECIPES_UPDATE_DELIVERY, { data });
      },
      updateAllPromotionDeliver(data) {
        this.$store.dispatch(PROMOTION_RECIPES_UPDATE_ALL_DELIVERY, {
          data
        });
      },
      promotionDeliverDelete(id) {
        let title = i18nHelper.getMessage('label.deletePromotion');
        let messages = [];
        let buttons = [];
        messages.push(
          i18nHelper.getMessage('message.requestDeletePromotionMessage')
        );

        buttons.push({
          text: i18nHelper.getMessage('label.cancel'),
          variant: 'primary',
          action: () => {
            this.closeInfoModal();
          }
        });
        buttons.push({
          text: i18nHelper.getMessage('label.delete'),
          variant: 'danger',
          action: () => {
            this.$store
              .dispatch(PROMOTION_RECIPES_DELETE_DELIVERY, {
                id
              })
              .then(() => {
                this.closeInfoModal();
              });
          }
        });
        this.openInfoModal(title, messages, buttons);
      },
      promotionDeliverDeleteAll(id) {
        let title = i18nHelper.getMessage('label.deletePromotion');
        let messages = [];
        let buttons = [];

        messages.push(
          i18nHelper.getMessage('message.requestDeletePromotionMessage')
        );

        buttons.push({
          text: i18nHelper.getMessage('label.cancel'),
          variant: 'primary',
          action: () => {
            this.closeInfoModal();
          }
        });
        buttons.push({
          text: i18nHelper.getMessage('label.delete'),
          variant: 'danger',
          action: () => {
            this.promotionDeleteAllDelivery(id);
          }
        });
        this.openInfoModal(title, messages, buttons);
      },
      promotionDeleteAllDelivery(id) {
        this.$store
          .dispatch(PROMOTION_RECIPES_DELETE_ALL_DELIVERY, {
            id
          })
          .then(() => {
            this.closeInfoModal();
          });
      },
      initialUpdatePromotionDeliverlyState() {
        this.$store.dispatch(PROMOTION_RECIPES_INITIAL_UPDATE_DELIVERY_STATE);
      },
      initialUpdatePromotionAllDeliverlyState() {
        this.$store.dispatch(
          PROMOTION_RECIPES_INITIAL_UPDATE_ALL_DELIVERY_STATE
        );
      },
      initialDeletePromotionDeliverlyState() {
        this.$store.dispatch(PROMOTION_RECIPES_INITIAL_DELETE_DELIVERY_STATE);
      },
      initialDeletePromotionAllDeliverlyState() {
        this.$store.dispatch(
          PROMOTION_RECIPES_INITIAL_DELETE_ALL_DELIVERY_STATE
        );
      },
      openInfoModal(title, messages, buttons) {
        this.infoModal = {
          title: title,
          messages: messages,
          buttons: buttons
        };
        this.infoModal.show = true;
      },
      closeInfoModal() {
        this.infoModal.show = false;
        this.infoModal.title = '';
        this.infoModal.messages = [];
        this.infoModal.buttons = [];
        this.infoModal.textbox = '';
        this.infoModal.dropbox = [];
      }
    }
  };
</script>

<style>
  /* .sortable {
    max-width: 300px;
  }
  .text-nowrap {
    white-space: unset !important;
  } */
  .app-table-actions {
    justify-content: center;
    display: flex;
  }
</style>
