<template>
  <div>
    <AppSmartList
      :breadcrumbData="breadcrumbData"
      :accessRights="accessRights"
      :filters="filters"
      :actions="actions"
      :tableHeaders="headers"
      :subMenu="category"
    >
      <template v-slot:buttons>
        <AppExportExcel
          :exportExcelObj="exportExcel"
          :isDate="true"
        ></AppExportExcel>
      </template>
    </AppSmartList>
  </div>
</template>

<script>
  import AppSmartList from '@/views/components/bases/smart/AppSmartList.vue';
  import AppExportExcel from '@/views/components/bases/AppExportExcel.vue';
  import { i18nHelper, uiHelper } from '@/core/utils';
  import {
    ROUTE_NAME,
    MODULE,
    ACTION,
    APP_COMPONENT_NAME,
    SMART_FORM_FIELD_TYPE,
    LOCAL
  } from '@/core/constants';
  import { ActiveStatusNum } from '@/core/constants/enums';
  import { listService } from '@/core/services';
  import {
    PRODUCT_GET_PRODUCTS,
    PRODUCT_INITIAL_GET_PRODUCTS_STATE
  } from '@/core/store/product.module';

  const CONSTANTS_DATA = JSON.parse(uiHelper.getLocalStorage(LOCAL.CONSTANTS));

  export default {
    name: 'ProductList',
    components: {
      AppSmartList,
      AppExportExcel
    },
    props: {
      breadcrumbData: {
        type: Object,
        defualt: () => {}
      },
      category: {
        type: String,
        defualt: ''
      }
    },
    data: () => ({
      accessRights: {
        moduleName: MODULE.PRODUCT,
        actions: {
          create: ACTION.PRODUCT_CREATE,
          read: ACTION.PRODUCT_READ,
          update: ACTION.PRODUCT_UPDATE,
          delete: ACTION.PRODUCT_DELETE
        }
      },
      filters: [],
      actions: {
        viewList: {
          module: 'product',
          state: 'products',
          action: PRODUCT_GET_PRODUCTS,
          initialStateAction: PRODUCT_INITIAL_GET_PRODUCTS_STATE,
          title: i18nHelper.getMessage('label.getPreOrderFoodList')
        }
      },
      headers: [
        {
          key: 'no',
          name: 'no',
          active: true,
          sortable: true
        },
        {
          key: 'name',
          name: 'name',
          active: true,
          sortable: true,
          class: 'min-w-100px'
        },
        {
          key: 'id',
          name: 'id',
          active: true,
          sortable: true
        },
        {
          key: 'username',
          name: 'createdBy',
          active: true,
          sortable: true,
          class: 'min-w-130px'
        },
        {
          key: 'foodType',
          name: 'foodType',
          active: true,
          sortable: true
        },
        {
          key: 'isActive',
          name: 'active',
          active: true,
          sortable: true,
          layout: APP_COMPONENT_NAME.APP_CHECK_CIRCLE
        },
        {
          key: 'isPopular',
          name: 'popular',
          active: true,
          sortable: true,
          layout: APP_COMPONENT_NAME.APP_CHECK_CIRCLE
        },
        {
          key: 'isTrending',
          name: 'trending',
          active: true,
          sortable: true,
          layout: APP_COMPONENT_NAME.APP_CHECK_CIRCLE
        },
        {
          key: 'isTop10',
          name: 'top10',
          active: true,
          sortable: true,
          layout: APP_COMPONENT_NAME.APP_CHECK_CIRCLE
        },
        {
          key: 'actions',
          name: 'actions',
          active: true,
          action: true,
          layout: APP_COMPONENT_NAME.APP_TABLE_ACTIONS,
          layoutActions: {
            viewDetailsRouteName: ROUTE_NAME.PRE_ORDER_FOOD_DETAILS
          }
        }
      ],
      exportExcel: {
        disabled: false,
        fileName: 'Pre-order food list',
        api: 'admin/products/report/listing/download'
      }
    }),
    created() {
      this.newFilters();
    },
    methods: {
      newFilters() {
        this.filters.push(
          {
            label: 'prefilter',
            key: 'prefilter',
            value: this.category,
            isHidden: true
          },
          {
            label: 'name',
            key: 'name',
            value: ''
          },
          {
            label: 'id',
            key: 'id',
            value: null
          },
          {
            label: 'userId',
            key: 'userId',
            value: null
          },
          {
            label: 'createdBy',
            key: 'username',
            value: ''
          },
          {
            label: 'foodType',
            key: 'foodType',
            value: [],
            options: listService.getDynamicList(CONSTANTS_DATA.foodType),
            optionLabel: 'text',
            type: SMART_FORM_FIELD_TYPE.MULTI_SELECT
          },
          {
            label: 'popular',
            key: 'isPopular',
            value: '',
            options: listService.getYesNoList(false),
            optionLabel: 'text',
            type: SMART_FORM_FIELD_TYPE.SELECT
          },
          {
            label: 'trending',
            key: 'isTrending',
            value: '',
            options: listService.getYesNoList(false),
            optionLabel: 'text',
            type: SMART_FORM_FIELD_TYPE.SELECT
          },
          {
            label: 'top10',
            key: 'isTop10',
            value: '',
            options: listService.getYesNoList(false),
            optionLabel: 'text',
            type: SMART_FORM_FIELD_TYPE.SELECT
          },
          {
            label: 'active',
            key: 'isActive',
            value: ActiveStatusNum.ACTIVE,
            options: listService.getYesNoList(false),
            optionLabel: 'text',
            type: SMART_FORM_FIELD_TYPE.SELECT
          }
        );
      }
    }
  };
</script>

<style></style>
