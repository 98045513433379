<template>
  <div>
    <AppSmartList
      ref="app-smart-list"
      :breadcrumbData="breadcrumbData"
      :accessRights="accessRights"
      :filters="filters"
      :actions="actions"
      :tableHeaders="headers"
      @app-smart-list-open-modal-details="openModalDetails"
    >
    </AppSmartList>

    <!--begin:: App Update Details Modal-->
    <DisplayPriorityModal
      v-model="priorityDetailsModalObj.isShow"
      :id="priorityDetailsModalObj.id"
      :obj="priorityDetailsModalObj.obj"
      :category="category"
      :actions="actions"
    ></DisplayPriorityModal>
    <!--end:: App Update Details Modal-->
  </div>
</template>

<script>
  import AppSmartList from '@/views/components/bases/smart/AppSmartList.vue';
  import DisplayPriorityModal from '@/views/components/mobile-application/DisplayPriorityModal';
  import { i18nHelper } from '@/core/utils';
  import {
    ROUTE_NAME,
    MODULE,
    ACTION,
    APP_COMPONENT_NAME,
    SMART_FORM_FIELD_TYPE
  } from '@/core/constants';
  import { listService } from '@/core/services';
  import { MerchantCategory } from '@/core/constants/enums';
  import {
    MOBILE_APPLICATION_GET_MOBILE_APPLICATION_MERCHANTS,
    MOBILE_APPLICATION_UPDATE_MOBILE_APPLICATION_MERCHANT,
    MOBILE_APPLICATION_INITIAL_GET_MOBILE_APPLICATION_MERCHANTS_STATE,
    MOBILE_APPLICATION_INITIAL_UPDATE_MOBILE_APPLICATION_MERCHANT_STATE
  } from '@/core/store/mobile-application.module';

  export default {
    name: 'MerchantList',
    components: {
      AppSmartList,
      DisplayPriorityModal
    },
    props: {
      category: {
        type: [String, Number],
        default: ''
      }
    },
    data: () => ({
      accessRights: {
        moduleName: MODULE.MOBILE_APPLICATION,
        actions: {
          read: ACTION.MOBILE_APPLICATION_READ,
          update: ACTION.MOBILE_APPLICATION_UPDATE,
          create: ACTION.MOBILE_APPLICATION_CREATE
        }
      },
      filters: [],
      actions: {
        viewList: {
          module: 'mobileApplication',
          state: 'mobileApplicationMerchants',
          action: MOBILE_APPLICATION_GET_MOBILE_APPLICATION_MERCHANTS,
          initialStateAction:
            MOBILE_APPLICATION_INITIAL_GET_MOBILE_APPLICATION_MERCHANTS_STATE,
          title: i18nHelper.getMessage('label.getMerchant')
        },
        updatePriority: {
          module: 'mobileApplication',
          state: 'updateMobileApplicationMerchant',
          action: MOBILE_APPLICATION_UPDATE_MOBILE_APPLICATION_MERCHANT,
          initialStateAction:
            MOBILE_APPLICATION_INITIAL_UPDATE_MOBILE_APPLICATION_MERCHANT_STATE
        }
      },
      headers: [],
      priorityDetailsModalObj: {
        isShow: false,
        id: 0
      }
    }),
    created() {
      this.initialMerchantsList();
    },
    computed: {},
    watch: {},
    methods: {
      initialMerchantsList() {
        let routernName, label;

        let dynamicList = [
          {
            description: i18nHelper.getMessage('label.merchant'),
            value: 1
          },
          {
            description: i18nHelper.getMessage('label.creator'),
            value: 2
          }
        ];
        switch (this.category) {
          case MerchantCategory.ALL:
            routernName = ROUTE_NAME.MERCHANT_ALL;
            label = 'All';
            break;
          case MerchantCategory.DINE_IN:
            routernName = ROUTE_NAME.MERCHANT_DINE_IN;
            label = 'DineIn';
            break;
          case MerchantCategory.ONLINE:
            routernName = ROUTE_NAME.MERCHANT_ONLINE;
            label = 'Online';
            break;
        }
        this.breadcrumbData = {
          breadcrumbs: [
            {
              title: i18nHelper.getMessage('label.merchant'),
              route: {
                name: routernName
              }
            },
            {
              title: i18nHelper.getMessage(`label.merchant${label}List`)
            }
          ]
        };
        this.filters = [
          {
            label: 'category',
            key: 'category',
            value: this.category == '3' ? null : this.category,
            isHidden: true
          },
          {
            label: 'merchantName',
            key: 'name',
            value: ''
          },
          {
            label: 'id',
            key: 'id',
            value: ''
          },
          {
            label: 'merchantType',
            key: 'merchantType',
            value: '',
            options: listService.getDynamicList(dynamicList),
            optionLabel: 'text',
            type: SMART_FORM_FIELD_TYPE.SELECT
          }
        ];

        this.headers = [
          {
            key: 'no',
            name: 'no',
            active: true,
            sortable: true
          },
          {
            key: 'id',
            name: 'id',
            active: true,
            sortable: true
          },
          {
            key: 'name',
            name: 'merchantName',
            active: true,
            sortable: true,
            class: 'min-w-120px'
          },
          {
            key: 'merchantType',
            name: 'merchantType',
            active: true,
            sortable: true
          },
          {
            key: 'serviceType',
            name: 'serviceType',
            active: true,
            sortable: true
          },
          {
            key: 'priority',
            name: 'priority',
            active: true,
            sortable: true
          },
          {
            key: 'actions',
            name: 'actions',
            active: true,
            action: true,
            layout: APP_COMPONENT_NAME.APP_TABLE_ACTIONS,
            layoutActions: {
              isModal: true
            }
          }
        ];
      },
      openModalDetails(value, obj) {
        this.openMaintenanceModal(value, obj);
      },
      openMaintenanceModal(id, obj) {
        this.priorityDetailsModalObj = {
          isShow: true,
          id: id,
          obj: obj
        };
      }
    }
  };
</script>

<style></style>
