<template>
  <div>
    <ProductListTemplate
      :breadcrumbData="breadcrumbData"
      category="SOMETHING_SWEET"
    ></ProductListTemplate>
  </div>
</template>

<script>
  import ProductListTemplate from '@/views/components/product/ProductListTemplate.vue';
  import { i18nHelper } from '@/core/utils';
  import { ROUTE_NAME } from '@/core/constants';

  export default {
    name: 'ProductSomethingSweetList',
    components: {
      ProductListTemplate
    },
    data: () => ({
      breadcrumbData: {
        breadcrumbs: [
          {
            title: i18nHelper.getMessage('label.preOrderFood'),
            route: { name: ROUTE_NAME.PRE_ORDER_FOOD }
          },
          { title: i18nHelper.getMessage('label.preOrderFoodList') }
        ]
      }
    })
  };
</script>

<style></style>
