<template>
  <b-tr>
    <b-td :stacked-heading="$t('label.property')">{{
      $t(`label.${item.key}`)
    }}</b-td>
    <template v-for="(i, index) in keys">
      <b-td :key="index" :stacked-heading="$t(`label.${i}`)">
        <template v-if="item.key == 'deliveryStatus'">
          <AppEnumLabel
            :value="item[i]"
            :config="enumLabelConfig"
          ></AppEnumLabel>
        </template>
        <div v-else>
          {{ item[i] }}
        </div>
      </b-td>
    </template>
  </b-tr>
</template>

<script>
  import AppEnumLabel from '@/views/components/bases/AppEnumLabel';
  import { DeliveryStatus } from '@/core/constants/enums';

  export default {
    name: 'ChangesOrderRow',
    components: {
      AppEnumLabel
    },
    props: {
      item: {
        type: Object,
        default: () => {}
      }
    },
    data: () => ({
      keys: ['from', 'to'],
      enumLabelConfig: {
        size: 'lg',
        enumKey: 'enumDeliveryStatus',
        statusColor: (value) => {
          const color = {
            [DeliveryStatus.NO_DRIVER_ASSIGNED]: 'danger',
            [DeliveryStatus.PROCESSING]: 'warning',
            [DeliveryStatus.ASSIGNING_DRIVER]: 'warning',
            [DeliveryStatus.ON_GOING]: 'warning',
            [DeliveryStatus.PICKED_UP]: 'warning',
            [DeliveryStatus.COMPLETED]: 'success',
            [DeliveryStatus.REJECTED]: 'danger',
            [DeliveryStatus.CANCELLED]: 'danger',
            [DeliveryStatus.EXPIRED]: 'danger',
            [DeliveryStatus.MANUAL]: 'info'
          };

          return color[value];
        }
      }
    })
  };
</script>

<style></style>
