<template>
  <div>
    <b-row no-gutters class="w-100 mt-4">
      <!--begin: Col 1-->
      <b-col cols="12" md="4">
        <b-card no-body>
          <b-card-body>
            <!--begin: Creator Information-->
            <div>
              <b-card-title class="text-capitalize mb-0">
                {{ $t('label.creatorInformation') }}
              </b-card-title>

              <div class="separator separator-dashed my-6"></div>
              <b-card-text>
                <template v-for="(i, index) in creatorInformationSection">
                  <div :key="index" class="mb-5">
                    <div class="font-weight-bolder text-capitalize mb-2">
                      {{ $t(`label.${i.name}`) }}:
                    </div>
                    <component
                      v-if="i.layout"
                      :is="i.layout"
                      :value="reviewAndRatingObj[i.key]"
                      :id="id"
                      :disabled="
                        !checkAccessRight(accessRights.moduleName, [
                          accessRights.actions.update
                        ])
                      "
                      :storeActionsObj="visibilityStatusStoreActionsObj"
                      :toDetailsRouteName="itemDetailsRouteName"
                      :appFeatureDetailsRelatedButtonDisabled="
                        appFeatureDetailsRelatedButtonDisabled
                      "
                    >
                    </component>
                    <div v-else class="text-gray-600">
                      {{ reviewAndRatingObj[i.key] }}
                    </div>
                  </div>
                  <div
                    v-if="i.key == 'isVisible'"
                    :key="index + 'separator'"
                    class="separator separator-solid my-6"
                  ></div>
                </template>
              </b-card-text>
            </div>
            <!--end: Creator Information-->
          </b-card-body>
        </b-card>
      </b-col>
      <!--end: Col 1-->

      <!--begin: Col 2-->
      <b-col cols="12" md="8" class="mt-4 mt-md-0 pl-md-4 pl-lg-8">
        <!--begin: Media-->
        <b-card no-body>
          <b-card-body>
            <b-card-title class="text-capitalize font-weight-bolder">
              {{ $t('label.images') }}
            </b-card-title>

            <div>
              <AppImageViewer
                v-if="reviewAndRatingObj.images.length > 0"
                :value="reviewAndRatingObj.images"
                :isMultiple="true"
              ></AppImageViewer>
              <span v-else>{{ $t('label.thereAreNoImagesToShow') }}</span>
            </div>
          </b-card-body>
        </b-card>
        <!--end: Media-->
      </b-col>
      <!--end Col 2-->
    </b-row>
  </div>
</template>

<script>
  import commonMixin from '@/core/mixins/common.mixin';
  import AppImageViewer from '@/views/components/bases/image-viewer/AppImageViewer';
  import AppVisibilityStatusButton from '@/views/components/bases/AppVisibilityStatusButton';
  import AppFeatureDetailsRelatedButton from '@/views/components/bases/AppFeatureDetailsRelatedButton';
  import { APP_COMPONENT_NAME } from '@/core/constants';

  export default {
    name: 'ReviewAndRatingDetails',
    mixins: [commonMixin],
    components: {
      AppImageViewer,
      AppVisibilityStatusButton,
      AppFeatureDetailsRelatedButton
    },
    props: {
      breadcrumbData: {
        type: Object,
        default: () => {}
      },
      actions: {
        type: Object,
        default: () => {}
      },
      accessRights: {
        type: Object,
        default: () => {}
      },
      backRouteName: {
        type: String,
        default: ''
      },
      visibilityStatusStoreActionsObj: {
        type: Object,
        default: () => {
          return {
            module: '',
            state: '',
            actionSet: '',
            actionInitial: '',
            successAction: () => {},
            title: '',
            message: ''
          };
        }
      },
      itemDetailsRouteName: {
        type: String,
        default: ''
      },
      appFeatureDetailsRelatedButtonDisabled: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      id: null,
      creatorInformationSection: [
        {
          key: 'itemObject',
          name: 'itemName',
          layout: APP_COMPONENT_NAME.APP_FEATURE_DETAILS_RELATED_BUTTON
        },
        {
          key: 'username',
          name: 'createdBy'
        },
        {
          key: 'rating',
          name: 'rating'
        },
        {
          key: 'description',
          name: 'review'
        },
        {
          key: 'isVisible',
          name: 'visibility',
          layout: APP_COMPONENT_NAME.APP_VISIBILITY_STATUS_BUTTON
        },
        {
          key: 'createdAt',
          name: 'createdAt'
        }
      ],
      reviewAndRatingObj: {
        images: []
      }
    }),
    computed: {
      readRecordComplete() {
        let obj = this.actions.readRecord;

        if (obj) {
          return this.$store.state[obj.module][obj.state].complete;
        } else {
          return false;
        }
      }
    },
    watch: {
      readRecordComplete() {
        let obj = this.actions.readRecord;
        let response = this.$store.state[obj.module][obj.state];
        let title = obj.title;
        let initialStateAction = this.actions.initialReadRecordState;
        let successAction = (response) => {
          this.importReviewAndRatingObj(response);
        };

        if (response) {
          if (response.complete) {
            this.actionReadRecordComplete(
              response,
              title,
              initialStateAction,
              successAction
            );
          }
        }
      }
    },
    created() {
      this.initialReviewAndRatingDetails();
    },
    methods: {
      importReviewAndRatingObj(response) {
        this.reviewAndRatingObj = response.data;
      },
      getReview(id) {
        this.$store.dispatch(this.actions.readRecord.action, { id });
      },
      initialReviewAndRatingDetails() {
        let id = Number(this.$route.params.id);

        this.id = id;
        this.getReview(id);
        this.initBreadCrumb(this.breadcrumbData);
      }
    }
  };
</script>

<style lang="scss">
  .document {
    height: 100px;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .document-image {
    height: 100px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }
</style>
