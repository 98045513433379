var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{attrs:{"title":_vm.$t('label.editPersonalInformation'),"centered":"","no-close-on-esc":"","no-close-on-backdrop":"","hide-header-close":"","scrollable":"","header-class":"text-capitalize","body-class":_vm.isEdit ? '' : 'max-h-400px'},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [(
        !_vm.isEdit &&
        !_vm.checkAccessRight(_vm.moduleName, [_vm.action.PLATFORM_OPERATION_HOUR_UPDATE])
      )?_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.actionDiscard}},[_vm._v(_vm._s(_vm.$t('label.close')))]):_c('b-button',{staticClass:"mr-2",attrs:{"variant":"white"},on:{"click":_vm.actionDiscard}},[_vm._v(_vm._s(_vm.$t('label.discard')))]),(
        _vm.isEdit &&
        _vm.checkAccessRight(_vm.moduleName, [_vm.action.PLATFORM_OPERATION_HOUR_CREATE])
      )?_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.actionSubmit}},[_vm._v(_vm._s(_vm.$t('label.submit')))]):_vm._e()]},proxy:true}]),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('b-form-group',{staticClass:"mb-0"},[_vm._l((_vm.formFields),function(i,index){return [_c('div',{key:index,staticClass:"px-2"},[_c('AppFormField',{attrs:{"state":_vm.validateObjState('profileDetailsPersonalInform', i.model),"validationValue":_vm.$v.profileDetailsPersonalInform[i.model],"title":_vm.$t(`label.${i.title}`),"solid":true,"validators":i.validators,"validationParams":i.validationParams,"formType":i.formType,"options":i.options,"textTransform":i.textTransform,"moreRemark":i.moreRemark,"isRequires":i.isRequires,"disabled":i.disabled},on:{"change":_vm.initFormFields},model:{value:(_vm.$v.profileDetailsPersonalInform[i.model].$model),callback:function ($$v) {_vm.$set(_vm.$v.profileDetailsPersonalInform[i.model], "$model", $$v)},expression:"$v.profileDetailsPersonalInform[i.model].$model"}}),_c('div',{staticStyle:{"display":"none"}},[_vm._v(" "+_vm._s(_vm.$v)+" ")])],1)]})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }