<template>
  <div class="banner-action-value-field">
    <!-- begin:: Web view input field -->
    <AppFormField
      v-if="actionType == bannerActionType.WEBVIEW"
      v-model="$v.actionValue.valueUrl.$model"
      :state="validateObjState('actionValue', 'valueUrl')"
      :validationValue="$v.actionValue.valueUrl"
      :validators="['required', 'url']"
      :title="$t('label.actionValue')"
      :disabled="disabled"
      :noTitle="noTitle"
      :solid="solid"
      placeholder="https://"
      @input="valueUrlFieldInput"
    >
    </AppFormField>
    <!-- end:: Web view input field -->

    <!-- begin:: Live Search Selected Item Display Field -->
    <div
      v-else
      class="banner-action-value-field__display-field-container"
      :class="[{ active: !disabled }]"
      @click="onClickShowLiveSearchModal"
    >
      <AppFormField
        v-model="actionValue.displayName"
        :state="validateObjState('actionValue', 'value')"
        :validationValue="$v.actionValue.value"
        :validators="['required']"
        :title="title"
        :noTitle="noTitle"
        disabled
        :solid="solid"
        :prepend="!disabled"
      >
        <template v-slot:prepend>
          <span class="input-group-text"
            ><i class="flaticon2-search-1"></i
          ></span>
        </template>
      </AppFormField>
      <div style="display: none">
        <!-- WARNING: I have no idea why this line is here, but removing it breaks everything -->

        <!-- WARNING: I have no idea why this line is here, but removing it breaks everything -->
        {{ $v }}
      </div>
    </div>
    <!-- end:: Live Search Selected Item Display Field -->

    <!--begin:: Live Search Modal-->
    <b-modal
      v-model="liveSearchModal.show"
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      centered
      :title="liveSearchModal.title"
      :ok-disabled="isOkDisabled"
      @ok="onClickOk"
      @cancel="onClickCancel"
    >
      <div class="form-group">
        <div class="input-icon input-icon-right">
          <AppFormField
            v-model="searchValue"
            :placeholder="liveSearchModal.placeholder"
            no-title
            class="mb-4"
            @keyup="onKeyupSearchAction"
          >
          </AppFormField>
          <div style="display: none">
            <!-- WARNING: I have no idea why this line is here, but removing it breaks everything -->
            {{ $v }}
          </div>
        </div>
        <span class="form-text text-muted">{{
          $t(`label.inputToSearchRecord`)
        }}</span>
      </div>

      <div class="max-h-300px overflow-y-scroll">
        <b-list-group>
          <template v-for="(item, index) in liveSearchModal.items">
            <b-list-group-item
              :key="index"
              button
              class="btn btn-hover-primary rounded-0"
              @click="onClickActionItem(item)"
            >
              {{ getDisplayName(item) }}
            </b-list-group-item>
          </template>

          <!-- begin:: No results matched -->
          <b-list-group-item v-if="liveSearchModal.items.length < 1">
            {{ `${$t('label.noResultsMatched')}` }}
          </b-list-group-item>
          <!-- end:: No results matched -->

          <!-- begin:: Increase more item -->
          <b-list-group-item
            v-if="
              liveSearchModal.pagination.currentPage !=
              liveSearchModal.pagination.lastPage
            "
            class="text-center font-weight-bold text-primary btn btn-hover-primary rounded-0 text-capitalize"
            @click="onClickLoadMore"
          >
            {{ $t('label.loadMore') }}
          </b-list-group-item>
          <!-- end:: Increase more item -->
        </b-list-group>
      </div>
    </b-modal>
    <!--end:: Live Search Modal-->
  </div>
</template>

<script>
  import AppFormField from '@/views/components/bases/form-field/AppFormField';
  import commonMixin from '@/core/mixins/common.mixin';
  import { validationMixin } from 'vuelidate';
  import { required, url } from 'vuelidate/lib/validators';
  import { i18nHelper } from '@/core/utils';
  import { GLOBAL } from '@/core/constants';
  import { BannerActionType } from '@/core/constants/enums';
  import {
    PRODUCT_GET_PRODUCTS,
    PRODUCT_GET_PRODUCT,
    PRODUCT_INITIAL_GET_PRODUCTS_STATE,
    PRODUCT_INITIAL_GET_PRODUCT_STATE
  } from '@/core/store/product.module';

  export default {
    name: 'AppLiveSearch',
    mixins: [commonMixin, validationMixin],
    components: {
      AppFormField
    },
    props: {
      value: {
        type: [Number, String, Object],
        default: null
      },
      title: {
        type: [Number, String],
        default: null
      },
      disabled: {
        type: Boolean,
        default: false
      },
      actionType: {
        type: Number,
        default: BannerActionType.NONE
      },
      noTitle: {
        type: Boolean,
        default: false
      },
      isExisting: {
        type: Boolean,
        default: false
      },
      solid: {
        type: Boolean,
        default: false
      },
      category: {}
    },
    data: () => ({
      bannerActionType: BannerActionType,
      liveSearchModal: {
        show: false,
        title: '',
        items: [],
        selectedItem: null,
        pagination: GLOBAL.DEFAULT_TABLE_PAGINATION
      },
      searchValue: null,
      timer: null,
      actionValue: {
        displayName: null,
        value: null,
        valueUrl: null
      }
    }),
    created() {
      this.initActionValueField();
    },
    computed: {
      isOkDisabled() {
        return !this.liveSearchModal.selectedItem;
      },
      productsComplete() {
        return this.$store.state.product.products.complete;
      },
      productComplete() {
        return this.$store.state.product.product.complete;
      }
    },
    watch: {
      value() {
        this.initActionValueField();
      },
      actionType() {
        this.initialActionValueField(true, false);
      },
      productsComplete() {
        let response = this.$store.state.product.products;
        let title = i18nHelper.getMessage('label.getPreOrderFoodList');
        let initialStateAction = PRODUCT_INITIAL_GET_PRODUCTS_STATE;
        let successAction = (response) => {
          this.dataMappingAction(response);
        };

        if (response.complete) {
          this.actionReadRecordComplete(
            response,
            title,
            initialStateAction,
            successAction
          );
        }
      },
      productComplete() {
        let response = this.$store.state.product.product;
        let title = i18nHelper.getMessage('label.getPreOrderFoodDetails');
        let initialStateAction = PRODUCT_INITIAL_GET_PRODUCT_STATE;
        let successAction = (response) => {
          this.detailsDataMappingAction(response);
        };

        if (response.complete) {
          this.actionReadRecordComplete(
            response,
            title,
            initialStateAction,
            successAction
          );
        }
      }
    },
    methods: {
      valueUrlFieldInput(value) {
        this.$emit('input', value);
      },
      validate() {
        return (
          this.validationObjCheck('actionValue', 'value') ||
          this.validationObjCheck('actionValue', 'valueUrl')
        );
      },
      getDisplayName(item) {
        const actionType = BannerActionType;
        var currentType = this.actionType;
        var name;
        if ([actionType.RECIPE, actionType.PRODUCT].includes(currentType)) {
          name = `${item.name} (${item.username})`;
        } else if (currentType == actionType.CREATOR) {
          name = item.username;
        } else if (currentType == actionType.VOUCHER) {
          name = item.voucherTitle;
        } else if (currentType == actionType.RECIPE_PAGE) {
          name = item.name;
        }

        return name;
      },
      onClickActionItem(item) {
        this.liveSearchModal.selectedItem = item;
      },
      onKeyupSearchAction() {
        const waitTime = 1000;
        var type = this.actionType;
        var data = {
          type: this.type,
          ...GLOBAL.DEFAULT_SEARCH_CRITERIA
        };

        // Clear timer
        clearTimeout(this.timer);
        // Wait for X ms and then process the request
        this.timer = setTimeout(() => {
          this.liveSearchModal.items = [];
          if (type == BannerActionType.PRODUCT) {
            data.name = this.searchValue;
            this.getProducts(data);
          }
        }, waitTime);
      },
      onClickLoadMore() {
        var type = this.actionType;
        var data = {
          page: this.liveSearchModal.pagination.currentPage + 1
        };

        if (!this.disabled) {
          if (type == BannerActionType.PRODUCT) {
            data.name = this.searchValue;
            this.getProducts(data);
          }
        }
      },
      detailsDataMappingAction(response) {
        var data = response.data;

        this.actionValue.displayName = this.getDisplayName(data);
        this.actionValue.value = this.value;
      },
      dataMappingAction(response) {
        this.liveSearchModal.items = [
          ...this.liveSearchModal.items,
          ...response.data
        ];
        this.liveSearchModal.pagination = response.pagination;
        this.liveSearchModal.show = true;
      },
      onClickOk() {
        const actionType = BannerActionType;
        var currentAction = this.actionType;
        var item = this.liveSearchModal.selectedItem;
        var value;
        if (currentAction == actionType.CREATOR) {
          value = item.creatorId;
        } else if (currentAction == actionType.RECIPE_PAGE) {
          value = item.id;
        } else {
          value = item.id;
        }

        this.actionValue = {
          displayName: this.getDisplayName(item),
          value: value,
          valueUrl: null,
          valueObj: item
        };
        this.$emit('input', this.actionValue.value);
        this.$emit('valueObj', this.actionValue.valueObj);
        this.initialActionValueField(false);
      },
      onClickCancel() {
        this.$emit('input', null);
        this.initialActionValueField();
      },
      initialActionValueField(
        resetActionValue = true,
        resetParentValue = true
      ) {
        this.liveSearchModal = {
          show: false,
          title: '',
          items: [],
          selectedItem: null,
          pagination: GLOBAL.DEFAULT_TABLE_PAGINATION
        };

        this.searchValue = null;

        if (resetActionValue) {
          this.actionValue = {
            displayName: null,
            value: null,
            valueUrl: null
          };
          if (resetParentValue) {
            this.$emit('input', null);
          }
        }

        this.$v.actionValue.$reset();
      },
      onClickShowLiveSearchModal() {
        var type = this.actionType;
        var data = {
          ...GLOBAL.DEFAULT_SEARCH_CRITERIA
        };
        if (!this.disabled) {
          if (type == BannerActionType.PRODUCT) {
            this.getProducts(data);
            this.liveSearchModal.title =
              i18nHelper.getMessage('label.preOrderFood');
            this.liveSearchModal.placeholder =
              i18nHelper.getMessage('label.name');
          }
        }
      },
      getProducts(data) {
        this.$store.dispatch(PRODUCT_GET_PRODUCTS, { data });
      },
      getProduct(id) {
        this.$store.dispatch(PRODUCT_GET_PRODUCT, { id });
      },

      initActionValueField() {
        const actionType = BannerActionType;
        var currentAction = this.actionType;
        var value = this.value;

        if (this.isExisting && value) {
          if (currentAction == actionType.PRODUCT) {
            this.getProduct(Number(value));
          }
        }
      }
    },
    validations: {
      actionValue: {
        value: {
          required
        },
        valueUrl: {
          required,
          url
        }
      }
    }
  };
</script>

<style lang="scss">
  .banner-action-value-field__display-field-container {
    &.active {
      cursor: pointer;
      input {
        cursor: pointer;
      }
    }
  }
</style>
