import Vue from 'vue';
import dateTimeFormat from './dateTimeFormat.filter';
import currencyFormat from './currency-format.filter';
import constantsDesc from './constants-desc.filter';
import contactNumber from './contact-number-format.filter';

Vue.filter('dateTime', dateTimeFormat);
Vue.filter('currency', currencyFormat);
Vue.filter('constants', constantsDesc);
Vue.filter('contact', contactNumber);
