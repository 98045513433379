import constantsDescription from '@/core/filters/constants-description.filter';
import { PROMOTION_DELIVERY } from '@/core/api';
import { apiHelper /*i18nHelper*/ } from '@/core/utils';
import {
  LOADING_OPEN_LOADING,
  LOADING_CLOSE_LOADING
} from '@/core/store/loading.module';

/* Define Module Name */
const MODULE_NAME = 'promotionRecipesDelivery/';

/* Define Action Name */
const A_GET_RECIPES_DELIVERY = 'getRecipesDelivery';
const A_UPDATE_RECIPES_DELIVERY = 'updateRecipesDelivery';
const A_UPDATE_RECIPES_ALL_DELIVERY = 'updateRecipesAllDelivery';
const A_DELETE_RECIPES_DELIVERY = 'deleteRecipesDelivery';
const A_DELETE_RECIPES_ALL_DELIVERY = 'deleteRecipesAllDelivery';

/* Define Reset State Action Name */
const A_INITIAL_GET_RECIPES_DELIVERY = 'initialGetRecipesDeliveryState';
const A_INITIAL_UPDATE_RECIPES_DELIVERY = 'initialUpdateRecipesDeliveryState';
const A_INITIAL_UPDATE_RECIPES_ALL_DELIVERY =
  'initialUpdateRecipesAllDeliveryState';
const A_INITIAL_DELETE_RECIPES_DELIVERY = 'initialDeleteRecipesDeliveryState';
const A_INITIAL_DELETE_RECIPES_ALL_DELIVERY =
  'initialDeleteRecipesAllDeliveryState';

/* Define Getter Name */

/* Define Mutation Name */
const M_GET_RECIPES_DELIVERY = 'setGetRecipesDelivery';
const M_UPDATE_RECIPES_DELIVERY = 'setUpdateRecipesDelivery';
const M_UPDATE_RECIPES_ALL_DELIVERY = 'setUpdateRecipesAllDelivery';
const M_DELETE_RECIPES_DELETE_DELIVERY = 'setDeleteRecipesDelivery';
const M_DELETE_RECIPES_DELETE_ALL_DELIVERY = 'setDeleteRecipesAllDelivery';

/* Define Reset State Mutation Name */
const M_INITIAL_GET_RECIPES_DELIVERY = 'setInitialGetRecipesDeliveryState';
const M_INITIAL_RECIPES_DELIVERY = 'setInitialUpdateRecipesDeliveryState';
const M_INITIAL_RECIPES_ALL_DELIVERY =
  'setInitialUpdateRecipesAllDeliveryState';
const M_INITIAL_RECIPES_DELETE_DELIVERY =
  'setInitialDeleteRecipesDeliveryState';
const M_INITIAL_RECIPES_DELETE_ALL_DELIVERY =
  'setInitialDeleteRecipesAllDeliveryState';

/* Define Export Name */
export const PROMOTION_RECIPES_GET_DELIVERYS =
  MODULE_NAME + A_GET_RECIPES_DELIVERY;
export const PROMOTION_RECIPES_UPDATE_DELIVERY =
  MODULE_NAME + A_UPDATE_RECIPES_DELIVERY;
export const PROMOTION_RECIPES_UPDATE_ALL_DELIVERY =
  MODULE_NAME + A_UPDATE_RECIPES_ALL_DELIVERY;
export const PROMOTION_RECIPES_DELETE_DELIVERY =
  MODULE_NAME + A_DELETE_RECIPES_DELIVERY;
export const PROMOTION_RECIPES_DELETE_ALL_DELIVERY =
  MODULE_NAME + A_DELETE_RECIPES_ALL_DELIVERY;

/* Define Reset State Name */
export const PROMOTION_RECIPES_INITIAL_GET_DELIVERYS_STATE =
  MODULE_NAME + A_INITIAL_GET_RECIPES_DELIVERY;
export const PROMOTION_RECIPES_INITIAL_UPDATE_DELIVERY_STATE =
  MODULE_NAME + A_INITIAL_UPDATE_RECIPES_DELIVERY;
export const PROMOTION_RECIPES_INITIAL_UPDATE_ALL_DELIVERY_STATE =
  MODULE_NAME + A_INITIAL_UPDATE_RECIPES_ALL_DELIVERY;
export const PROMOTION_RECIPES_INITIAL_DELETE_DELIVERY_STATE =
  MODULE_NAME + A_INITIAL_DELETE_RECIPES_DELIVERY;
export const PROMOTION_RECIPES_INITIAL_DELETE_ALL_DELIVERY_STATE =
  MODULE_NAME + A_INITIAL_DELETE_RECIPES_ALL_DELIVERY;

const state = {
  recipesDelivery: {
    code: 0,
    complete: false,
    data: null,
    message: null,
    pagination: {
      currentPage: 0,
      total: 0,
      perPage: 0,
      lastPage: 0
    }
  },
  updateRecipeDelivery: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  updateAllRecipeDelivery: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  deleteRecipeDelivery: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  deleteAllRecipeDelivery: {
    code: 0,
    complete: false,
    data: null,
    message: null
  }
};

//Getters
const getters = {};

// Actions
const actions = {
  //RECIPES
  [A_GET_RECIPES_DELIVERY]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    PROMOTION_DELIVERY.getRecipesDeliver(data)
      .then((response) => {
        result = response;
        commit(M_GET_RECIPES_DELIVERY, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_RECIPES_DELIVERY, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_UPDATE_RECIPES_DELIVERY]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    PROMOTION_DELIVERY.applyRecipesDelivery(data)
      .then((response) => {
        result = response;
        commit(M_UPDATE_RECIPES_DELIVERY, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_UPDATE_RECIPES_DELIVERY, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_UPDATE_RECIPES_ALL_DELIVERY]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    PROMOTION_DELIVERY.applyAllRecipesDelivery(data)
      .then((response) => {
        result = response;
        commit(M_UPDATE_RECIPES_ALL_DELIVERY, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_UPDATE_RECIPES_ALL_DELIVERY, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_DELETE_RECIPES_DELIVERY]({ dispatch, commit }, { id }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    PROMOTION_DELIVERY.deleteRecipesDelivery(id)
      .then((response) => {
        result = response;
        commit(M_DELETE_RECIPES_DELETE_DELIVERY, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_DELETE_RECIPES_DELETE_DELIVERY, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  async [A_DELETE_RECIPES_ALL_DELIVERY]({ dispatch, commit }, { id }) {
    await dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    PROMOTION_DELIVERY.deleteAllRecipesDelivery(id)
      .then((response) => {
        result = response;
        commit(M_DELETE_RECIPES_DELETE_ALL_DELIVERY, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_DELETE_RECIPES_DELETE_ALL_DELIVERY, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_INITIAL_GET_RECIPES_DELIVERY]({ commit }) {
    commit(M_INITIAL_GET_RECIPES_DELIVERY);
  },
  [A_INITIAL_UPDATE_RECIPES_DELIVERY]({ commit }) {
    commit(M_INITIAL_RECIPES_DELIVERY);
  },
  [A_INITIAL_UPDATE_RECIPES_ALL_DELIVERY]({ commit }) {
    commit(M_INITIAL_RECIPES_ALL_DELIVERY);
  },
  [A_INITIAL_DELETE_RECIPES_DELIVERY]({ commit }) {
    commit(M_INITIAL_RECIPES_DELETE_DELIVERY);
  },
  [A_INITIAL_DELETE_RECIPES_ALL_DELIVERY]({ commit }) {
    commit(M_INITIAL_RECIPES_DELETE_ALL_DELIVERY);
  }
};

// Mutations
const mutations = {
  [M_GET_RECIPES_DELIVERY](state, result) {
    if (result.code === 0) {
      let d = result.data;
      let p = result.pagination;
      let promotionRecipesDeliverylist = [];

      // constantsDescription('deliveryDiscount', 1);
      d.forEach((x, index) => {
        promotionRecipesDeliverylist.push({
          no: index + 1,
          id: x.id,
          ids: x.recipe_id,
          name: x.recipe_name,
          deliveryDiscount: constantsDescription('deliveryDiscount', x.type),
          // deliveryDiscount: x.type,
          deliveryAmounts: x.value,
          deliveryValidityFrom: x.applied_from,
          deliveryValidityTo: x.applied_to,
          creatorName: x.creator_name,
          creatorId: x.creator_id
        });
      });

      state.recipesDelivery = {
        code: result.code,
        complete: true,
        data: promotionRecipesDeliverylist,
        message: null,
        pagination: {
          currentPage: p.current_page,
          total: p.total,
          perPage: p.per_page,
          lastPage: p.last_page
        }
      };
    } else {
      state.recipesDelivery = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message),
        pagination: {
          currentPage: 0,
          total: 0,
          perPage: 0,
          lastPage: 0
        }
      };
    }
  },
  [M_UPDATE_RECIPES_DELIVERY](state, result) {
    state.updateRecipeDelivery = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_UPDATE_RECIPES_ALL_DELIVERY](state, result) {
    state.updateAllRecipeDelivery = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_DELETE_RECIPES_DELETE_DELIVERY](state, result) {
    state.deleteRecipeDelivery = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_DELETE_RECIPES_DELETE_ALL_DELIVERY](state, result) {
    state.deleteAllRecipeDelivery = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_INITIAL_GET_RECIPES_DELIVERY](state) {
    state.recipesDelivery = {
      code: 0,
      complete: false,
      data: null,
      message: null,
      pagination: {
        currentPage: 0,
        total: 0,
        perPage: 0,
        lastPage: 0
      }
    };
  },
  [M_INITIAL_RECIPES_DELIVERY](state) {
    state.updateRecipeDelivery = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_RECIPES_ALL_DELIVERY](state) {
    state.updateAllRecipeDelivery = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_RECIPES_DELETE_DELIVERY](state) {
    state.deleteRecipeDelivery = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_RECIPES_DELETE_ALL_DELIVERY](state) {
    state.deleteAllRecipeDelivery = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
