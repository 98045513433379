<template>
  <div>
    <div v-if="value.id">
      <router-link
        v-if="!appFeatureDetailsRelatedButtonDisabled"
        :to="{
          name: config.to ?? toDetailsRouteName,
          params: { id: value.id, menu: config.subMenu }
        }"
        class="text-hover-decoration-underline"
        size="sm"
        >{{ value.name }}</router-link
      >
      <span v-else class="text-gray-600">{{ value.name }}</span>
    </div>

    <span v-else class="text-gray-600">{{ '-' }}</span>
  </div>
</template>

<script>
  export default {
    name: 'AppFeatureDetailsRelatedButton',
    props: {
      value: {
        type: Object,
        default: () => {
          return {
            id: null,
            name: null
          };
        }
      },
      toDetailsRouteName: {
        type: String,
        default: ''
      },
      appFeatureDetailsRelatedButtonDisabled: {
        type: Boolean,
        default: false
      },
      config: {
        type: Object,
        default: () => {
          return {
            to: null
          };
        }
      }
    }
  };
</script>

<style></style>
