<template>
  <!--begin: Wizard Step 1-->
  <div>
    <h6 class="mb-10 font-weight-bold text-dark text-capitalize">
      {{ $t('label.enterFormDetails', [$t('label.OffDay')]) }}
    </h6>

    <b-form class="form" id="kt_form">
      <template v-for="(i, index) in formFields.filter((x) => x.field == 1)">
        <div :key="index" v-if="i.noCreate !== isCreate || !isCreate">
          <AppFormField
            v-model="$v.offDaySettingObj[i.model].$model"
            :state="validateObjState('offDaySettingObj', i.model)"
            :validationValue="$v.offDaySettingObj[i.model]"
            :title="$t(`label.${i.title}`)"
            :validators="i.validators"
            :formType="i.formType"
            :config="i.config"
            :validationParams="i.validationParams"
            :moreRemark="i.moreRemark"
            :isRequires="i.isRequires"
            :prepend="i.prepend"
            :disabled="i.disabled"
            :min="i.min"
            :solid="!i.disabled"
          >
          </AppFormField>
          <div style="display: none">
            <!-- WARNING: I have no idea why this line is here, but removing it breaks everything -->
            {{ $v }}
          </div>
        </div>
      </template>
    </b-form>
    <!--begin: Wizard Actions -->
  </div>
  <!--end: Wizard Actions -->
  <!--end: Wizard Step 1-->
</template>

<script>
  import AppFormField from '@/views/components/bases/form-field/AppFormField';
  import constantsMixin from '@/core/mixins/constants.mixin';
  import commonMixin from '@/core/mixins/common.mixin';
  import { validationMixin } from 'vuelidate';
  import { required } from 'vuelidate/lib/validators';
  import { startDateTime, endDateTime } from '@/core/constants';

  export default {
    name: 'OffDaySettingForm',
    mixins: [commonMixin, constantsMixin, validationMixin],
    components: {
      AppFormField
    },
    props: {
      id: {},
      isCreate: {
        type: Boolean,
        default: false
      },
      offDaySettingObj: {
        type: Object,
        default: () => {}
      }
    },
    data: () => ({
      formFields: []
    }),
    mounted() {
      this.initFormFields();
    },
    computed: {},
    watch: {},
    methods: {
      initFormFields() {
        this.formFields.push(
          {
            field: 1,
            model: 'eventName',
            title: 'eventName',
            validators: ['required'],
            isRequires: true
          },
          {
            field: 1,
            model: 'startDateTime',
            title: 'startDateTime',
            formType: 'dateTime',
            validators: ['required', 'startDateTime'],
            isRequires: true,
            config: {
              isStartDateTime: true
            },
            min: new Date()
          },
          {
            field: 1,
            model: 'endDateTime',
            title: 'endDateTime',
            formType: 'dateTime',
            validators: ['required', 'endDateTime'],
            isRequires: true,
            config: {
              isEndDateTime: true
            },
            min: new Date()
          }
        );
      },
      validationCheckForm() {
        //Submit new banner form if all the validation is no error
        return this.validationCheck('offDaySettingObj');
      },
      returnRecipeObj(value) {
        this.$emit('getRecipeObj', value);
      }
    },
    validations() {
      return {
        offDaySettingObj: {
          eventName: { required },
          startDateTime: {
            required,
            startDateTime: startDateTime(this.offDaySettingObj.endDateTime)
          },
          endDateTime: {
            required,
            endDateTime: endDateTime(this.offDaySettingObj.startDateTime)
          }
        }
      };
    }
  };
</script>

<style></style>
