import dateTimeFormat from '@/core/filters/dateTimeFormat.filter';
import { CONFIG_GOOGLE_CALENDAR } from '@/core/api';
import { apiHelper } from '@/core/utils';
import {
  LOADING_OPEN_LOADING,
  LOADING_CLOSE_LOADING
} from '@/core/store/loading.module';

/* Define Module Name */
const MODULE_NAME = 'configGoogleCalender/';

/* Define Action Name */
const A_GET_CALENDAR_API = 'getCalendarApi';
const A_GET_CALENDAR_REFRESH = 'getCalendarRefresh';

/* Define Reset State Action Name */
const A_INITIAL_GET_CALENDAR_API_STATE = 'initialGetCalendarApiState';
const A_INITIAL_GET_CALENDAR_REFRESH_STATE = 'initialGetCalendarRefreshState';

/* Define Getter Name */

/* Define Mutation Name */
const M_GET_CALENDAR_API = 'setGetCalendarApi';
const M_GET_CALENDAR_REFRESH = 'setGetCalendarRefresh';

/* Define Reset State Mutation Name */
const M_INITIAL_GET_CALENDAR_API_STATE = 'setInitialGetCalendarApiState';
const M_INITIAL_GET_CALENDAR_REFRESH_STATE =
  'setInitialGetCalendarRefreshState';

/* Define Export Name */
export const GENERAL_GET_CALENDAR_API = MODULE_NAME + A_GET_CALENDAR_API;
export const GENERAL_GET_CALENDAR_REFRESH =
  MODULE_NAME + A_GET_CALENDAR_REFRESH;

/* Define Reset State Name */
export const GENERAL_INITIAL_GET_CALENDAR_API_STATE =
  MODULE_NAME + A_INITIAL_GET_CALENDAR_API_STATE;
export const GENERAL_INITIAL_GET_CALENDAR_REFRESH_STATE =
  MODULE_NAME + A_INITIAL_GET_CALENDAR_REFRESH_STATE;

const state = {
  calendarApi: {
    code: 0,
    complete: false,
    data: null,
    message: null,
    pagination: {
      currentPage: 0,
      total: 0,
      perPage: 0,
      lastPage: 0
    }
  },
  calendarRefresh: {
    code: 0,
    complete: false,
    data: null,
    message: null
  }
};

//Getters
const getters = {};

// Actions
const actions = {
  [A_GET_CALENDAR_API]({ dispatch, commit }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    CONFIG_GOOGLE_CALENDAR.getCalendarAip()
      .then((response) => {
        result = response;
        commit(M_GET_CALENDAR_API, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_CALENDAR_API, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_CALENDAR_REFRESH]({ dispatch, commit }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    CONFIG_GOOGLE_CALENDAR.getCalendarRefresh()
      .then((response) => {
        result = response;
        commit(M_GET_CALENDAR_REFRESH, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_CALENDAR_REFRESH, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_INITIAL_GET_CALENDAR_REFRESH_STATE]({ commit }) {
    commit(M_INITIAL_GET_CALENDAR_REFRESH_STATE);
  },
  [A_INITIAL_GET_CALENDAR_API_STATE]({ commit }) {
    commit(M_INITIAL_GET_CALENDAR_API_STATE);
  }
};

// Mutations
const mutations = {
  [M_GET_CALENDAR_API](state, result) {
    let d = result.data;
    let calendarApiObj = [];
    d.forEach((x) => {
      calendarApiObj.push({
        updatedAt: dateTimeFormat(x.updated_at)
      });
    });

    state.calendarApi = {
      code: result.code,
      complete: true,
      data: calendarApiObj,
      message: apiHelper.sortOutMessage(result.message),
      pagination: {
        currentPage: 0,
        total: 0,
        perPage: 0,
        lastPage: 0
      }
    };
  },
  [M_GET_CALENDAR_REFRESH](state, result) {
    state.calendarRefresh = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result.message),
      pagination: {
        currentPage: 0,
        total: 0,
        perPage: 0,
        lastPage: 0
      }
    };
  },
  [M_INITIAL_GET_CALENDAR_API_STATE](state) {
    state.calendarApi = {
      code: 0,
      complete: false,
      data: null,
      message: null,
      pagination: {
        currentPage: 0,
        total: 0,
        perPage: 0,
        lastPage: 0
      }
    };
  },
  [M_INITIAL_GET_CALENDAR_REFRESH_STATE](state) {
    state.calendarRefresh = {
      code: 0,
      complete: false,
      data: null,
      message: null,
      pagination: {
        currentPage: 0,
        total: 0,
        perPage: 0,
        lastPage: 0
      }
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
