<template>
  <div>
    <template v-if="value != '-'">
      <template v-for="(item, index) in value">
        <b-form-tag
          :key="index"
          :title="item"
          variant="info"
          no-remove
          class="mb-1"
        ></b-form-tag>
      </template>
    </template>
    <template v-else>
      {{ value }}
    </template>
  </div>
</template>

<script>
  export default {
    name: 'AppTags',
    props: ['value'],
    mounted() {},
    methods: {}
  };
</script>

<style></style>
