// import dateTimeFormat from '@/core/filters/dateTimeFormat.filter';
import constantsDescription from '@/core/filters/constants-description.filter';
import { RECIPE_PAGE } from '@/core/api';
import { apiHelper } from '@/core/utils';
import {
  LOADING_OPEN_LOADING,
  LOADING_CLOSE_LOADING
} from '@/core/store/loading.module';

/* Define Module Name */
const MODULE_NAME = 'recipePage/';

/* Define Action Name */
const A_GET_RECIPE_PAGES = 'getRecipePages';
const A_GET_RECIPE_PAGE = 'getRecipePage';
const A_GET_RECIPE_PAGE_SEARCHS = 'getRecipePageSearchs';
const A_GET_RECIPE_PAGE_SEARCH = 'getRecipePageSearch';
const A_CREATE_RECIPE_PAGE = 'createRecipePage';
const A_UPDATE_RECIPE_PAGE = 'updateRecipePage';
const A_DELETE_RECIPE_PAGE = 'deleteRecipePage';

/* Define Reset State Action Name */
const A_INITIAL_GET_RECIPE_PAGES_STATE = 'initialGetRecipePagesState';
const A_INITIAL_GET_RECIPE_PAGE_STATE = 'initialGetRecipePageState';
const A_INITIAL_GET_RECIPE_PAGE_SEARCHS_STATE =
  'initialGetRecipePageSearchsState';
const A_INITIAL_GET_RECIPE_PAGE_SEARCH_STATE =
  'initialGetRecipePageSearchState';
const A_INITIAL_CREATE_RECIPE_PAGE_STATE = 'initialCreateRecipePageState';
const A_INITIAL_UPDATE_RECIPE_PAGE_STATE = 'initialUpdateRecipePageState';
const A_INITIAL_DELETE_RECIPE_PAGE_STATE = 'initialDeleteRecipePageState';

/* Define Getter Name */

/* Define Mutation Name */
const M_GET_RECIPE_PAGES = 'setGetRecipePages';
const M_GET_RECIPE_PAGE = 'setGetRecipePage';
const M_GET_RECIPE_PAGE_SEARCHS = 'setGetRecipePageSearchs';
const M_GET_RECIPE_PAGE_SEARCH = 'setGetRecipePageSearch';
const M_CREATE_RECIPE_PAGE = 'setCreateRecipePage';
const M_UPDATE_RECIPE_PAGE = 'setUpdateRecipePage';
const M_DELETE_RECIPE_PAGE = 'setDeleteRecipePage';

/* Define Reset State Mutation Name */
const M_INITIAL_GET_RECIPE_PAGES_STATE = 'setInitialGetRecipePagesState';
const M_INITIAL_GET_RECIPE_PAGE_STATE = 'setInitialGetRecipePageState';
const M_INITIAL_GET_RECIPE_PAGE_SEARCHS_STATE =
  'setInitialGetRecipePageSearchsState';
const M_INITIAL_GET_RECIPE_PAGE_SEARCH_STATE =
  'setInitialGetRecipePageSearchsState2';
const M_INITIAL_CREATE_RECIPE_PAGE_STATE = 'setInitialCreateRecipePageState';
const M_INITIAL_UPDATE_RECIPE_PAGE_STATE = 'setInitialUpdateRecipePageState';
const M_INITIAL_DELETE_RECIPE_PAGE_STATE = 'setInitialDeleteRecipePageState';

/* Define Export Name */
export const RECIPE_PAGE_GET_RECIPE_PAGES = MODULE_NAME + A_GET_RECIPE_PAGES;
export const RECIPE_PAGE_GET_RECIPE_PAGE = MODULE_NAME + A_GET_RECIPE_PAGE;
export const RECIPE_PAGE_GET_RECIPE_PAGE_SEARCHS =
  MODULE_NAME + A_GET_RECIPE_PAGE_SEARCHS;
export const RECIPE_PAGE_GET_RECIPE_PAGE_SEARCH =
  MODULE_NAME + A_GET_RECIPE_PAGE_SEARCH;
export const RECIPE_PAGE_CREATE_RECIPE_PAGE =
  MODULE_NAME + A_CREATE_RECIPE_PAGE;
export const RECIPE_PAGE_UPDATE_RECIPE_PAGE =
  MODULE_NAME + A_UPDATE_RECIPE_PAGE;
export const RECIPE_PAGE_DELETE_RECIPE_PAGE =
  MODULE_NAME + A_DELETE_RECIPE_PAGE;

/* Define Reset State Name */
export const RECIPE_PAGE_INITIAL_GET_RECIPE_PAGES_STATE =
  MODULE_NAME + A_INITIAL_GET_RECIPE_PAGES_STATE;
export const RECIPE_PAGE_INITIAL_GET_RECIPE_PAGE_STATE =
  MODULE_NAME + A_INITIAL_GET_RECIPE_PAGE_STATE;
export const RECIPE_PAGE_INITIAL_GET_RECIPE_PAGE_SEARCHS_STATE =
  MODULE_NAME + A_INITIAL_GET_RECIPE_PAGE_SEARCHS_STATE;
export const RECIPE_PAGE_INITIAL_GET_RECIPE_PAGE_SEARCH_STATE =
  MODULE_NAME + A_INITIAL_GET_RECIPE_PAGE_SEARCH_STATE;
export const RECIPE_PAGE_INITIAL_CREATE_RECIPE_PAGE_STATE =
  MODULE_NAME + A_INITIAL_CREATE_RECIPE_PAGE_STATE;
export const RECIPE_PAGE_INITIAL_UPDATE_RECIPE_PAGE_STATE =
  MODULE_NAME + A_INITIAL_UPDATE_RECIPE_PAGE_STATE;
export const RECIPE_PAGE_INITIAL_DELETE_RECIPE_PAGE_STATE =
  MODULE_NAME + A_INITIAL_DELETE_RECIPE_PAGE_STATE;

const state = {
  recipePages: {
    code: 0,
    complete: false,
    data: null,
    message: null,
    pagination: {
      currentPage: 0,
      total: 0,
      perPage: 0,
      lastPage: 0
    }
  },
  recipePage: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  recipePageSearchs: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  recipePageSearch: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  createRecipePage: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  updateRecipePage: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  deleteRecipePage: {
    code: 0,
    complete: false,
    data: null,
    message: null
  }
};

//Getters
const getters = {};

// Actions
const actions = {
  [A_GET_RECIPE_PAGES]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    RECIPE_PAGE.getRecipePages(data)
      .then((response) => {
        result = response;
        commit(M_GET_RECIPE_PAGES, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_RECIPE_PAGES, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_RECIPE_PAGE]({ dispatch, commit }, { id }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    RECIPE_PAGE.getRecipePage(id)
      .then((response) => {
        result = response;
        commit(M_GET_RECIPE_PAGE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_RECIPE_PAGE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_RECIPE_PAGE_SEARCHS]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    RECIPE_PAGE.getRecipePageSearchs(data)
      .then((response) => {
        result = response;
        commit(M_GET_RECIPE_PAGE_SEARCHS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_RECIPE_PAGE_SEARCHS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_RECIPE_PAGE_SEARCH]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    RECIPE_PAGE.getRecipePageSearch(data)
      .then((response) => {
        result = response;
        commit(M_GET_RECIPE_PAGE_SEARCH, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_RECIPE_PAGE_SEARCH, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_CREATE_RECIPE_PAGE]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    RECIPE_PAGE.createRecipePage(data)
      .then((response) => {
        result = response;
        commit(M_CREATE_RECIPE_PAGE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_CREATE_RECIPE_PAGE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_UPDATE_RECIPE_PAGE]({ dispatch, commit }, { id, data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    RECIPE_PAGE.updateRecipePage(id, data)
      .then((response) => {
        result = response;
        commit(M_UPDATE_RECIPE_PAGE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_UPDATE_RECIPE_PAGE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_DELETE_RECIPE_PAGE]({ dispatch, commit }, { id }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    RECIPE_PAGE.deleteRecipePage(id)
      .then((response) => {
        result = response;
        commit(M_DELETE_RECIPE_PAGE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_DELETE_RECIPE_PAGE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_INITIAL_GET_RECIPE_PAGES_STATE]({ commit }) {
    commit(M_INITIAL_GET_RECIPE_PAGES_STATE);
  },
  [A_INITIAL_GET_RECIPE_PAGE_STATE]({ commit }) {
    commit(M_INITIAL_GET_RECIPE_PAGE_STATE);
  },
  [A_INITIAL_GET_RECIPE_PAGE_SEARCHS_STATE]({ commit }) {
    commit(M_INITIAL_GET_RECIPE_PAGE_SEARCHS_STATE);
  },
  [A_INITIAL_GET_RECIPE_PAGE_SEARCH_STATE]({ commit }) {
    commit(M_INITIAL_GET_RECIPE_PAGE_SEARCH_STATE);
  },
  [A_INITIAL_CREATE_RECIPE_PAGE_STATE]({ commit }) {
    commit(M_INITIAL_CREATE_RECIPE_PAGE_STATE);
  },
  [A_INITIAL_UPDATE_RECIPE_PAGE_STATE]({ commit }) {
    commit(M_INITIAL_UPDATE_RECIPE_PAGE_STATE);
  },
  [A_INITIAL_DELETE_RECIPE_PAGE_STATE]({ commit }) {
    commit(M_INITIAL_DELETE_RECIPE_PAGE_STATE);
  }
};

// Mutations
const mutations = {
  [M_GET_RECIPE_PAGES](state, result) {
    if (result.code === 0) {
      let d = result.data;
      let p = result.pagination;
      state.recipePages = {
        code: result.code,
        complete: true,
        data: d.map((x, index) => {
          return {
            no: index + 1,
            id: x.id,
            recipeName: {
              name: x.name,
              id: x.recipe_id
            },
            createdBy: {
              name: x.store_name ?? x.username,
              id: x.user_id
            },
            username: x.username,
            cookingLevel: constantsDescription('cookingLevel', x.cooking_level),
            ecoPackOption: constantsDescription(
              'ecoPackOption',
              x.eco_pack_option
            ),
            topRecipe: x.is_top,
            priority: x.priority,
            status: x.status,
            recipeStatus: x.recipe_status
          };
        }),
        message: null,
        pagination: {
          currentPage: p.current_page,
          total: p.total,
          perPage: p.per_page,
          lastPage: p.last_page
        }
      };
    } else {
      state.recipePages = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message),
        pagination: {
          currentPage: 0,
          total: 0,
          perPage: 0,
          lastPage: 0
        }
      };
    }
  },
  [M_GET_RECIPE_PAGE](state, result) {
    if (result.code === 0) {
      let d = result.data;
      state.recipePage = {
        code: result.code,
        complete: true,
        data: {
          id: d.id,
          recipeId: d.recipe_id,
          recipeName: d.name,
          creatorId: d.user_id,
          creatorName: d.store_name ?? d.username,
          cookingLevel: constantsDescription('cookingLevel', d.cooking_level),
          ecoPackOption: constantsDescription(
            'ecoPackOption',
            d.eco_pack_option
          ),
          description: d.description,
          ingredients: d.ingredients.length,
          servePax: d.serve_pax,
          price: d.price,
          priority: d.priority,
          thumbnail: d.thumbnail,
          topRecipe: d.is_top,
          topAssets: d.assets.find((x) => x.checked == true)?.id,
          assets: d.assets.map((i) => ({
            id: i.id,
            mediaPath: i.media_path,
            mediaType: i.media_type,
            timer: i.timer,
            order: i.order,
            checked: i.checked
          })),
          top: d.top
            ? {
                id: d.top.id,
                recipeId: d.top.recipe_id,
                recipeName: d.top.name,
                category: d.top.category
              }
            : null
        },
        message: null
      };
    } else {
      state.recipePage = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_GET_RECIPE_PAGE_SEARCHS](state, result) {
    if (result.code === 0) {
      let d = result.data;
      let p = result.pagination;
      state.recipePageSearchs = {
        code: result.code,
        complete: true,
        data: d.map((x, index) => {
          return {
            no: index + 1,
            id: x.id,
            name: x.name,
            creatorName: x.store_name ?? x.username,
            cookingLevel: constantsDescription('cookingLevel', x.cooking_level),
            ecoPackOption: constantsDescription(
              'ecoPackOption',
              x.eco_pack_option
            ),
            description: x.description,
            ingredients: x.ingredients.length,
            servePax: x.serve_pax,
            price: x.price,
            thumbnail: x.thumbnail,
            assets: x.assets.map((i) => ({
              id: i.id,
              mediaPath: i.media_path,
              mediaType: i.media_type,
              timer: i.timer,
              order: i.order
            }))
            // top: {
            //   id: d.top.id,
            //   recipeId: d.top.recipe_id,
            //   recipeName: d.top.name,
            //   category: d.top.category
            // }
          };
        }),
        message: null,
        pagination: {
          currentPage: p.current_page,
          total: p.total,
          perPage: p.per_page,
          lastPage: p.last_page
        }
      };
    } else {
      state.recipePageSearchs = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_GET_RECIPE_PAGE_SEARCH](state, result) {
    if (result.code === 0) {
      let d = result.data[0];
      let p = result.pagination;
      state.recipePageSearch = {
        code: result.code,
        complete: true,
        data: {
          id: d.id,
          name: d.name,
          creatorName: d.username,
          cookingLevel: constantsDescription('cookingLevel', d.cooking_level),
          ecoPackOption: constantsDescription(
            'ecoPackOption',
            d.eco_pack_option
          ),
          description: d.description,
          ingredients: d.ingredients.length,
          servePax: d.serve_pax,
          price: d.price,
          thumbnail: d.thumbnail,
          assets: d.assets.map((i) => ({
            id: i.id,
            mediaPath: i.media_path,
            mediaType: i.media_type,
            timer: i.timer,
            order: i.order
          }))
          // top: {
          //   id: d.top.id,
          //   recipeId: d.top.recipe_id,
          //   recipeName: d.top.name,
          //   category: d.top.category
          // }
        },
        message: null,
        pagination: {
          currentPage: p.current_page,
          total: p.total,
          perPage: p.per_page,
          lastPage: p.last_page
        }
      };
    } else {
      state.recipePageSearch = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_CREATE_RECIPE_PAGE](state, result) {
    state.createRecipePage = {
      code: result.code,
      complete: true,
      data: result.data,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_UPDATE_RECIPE_PAGE](state, result) {
    state.updateRecipePage = {
      code: result.code,
      complete: true,
      data: result,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_DELETE_RECIPE_PAGE](state, result) {
    state.deleteRecipePage = {
      code: result.code,
      complete: true,
      data: result,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_INITIAL_GET_RECIPE_PAGES_STATE](state) {
    state.recipePages = {
      code: 0,
      complete: false,
      data: null,
      message: null,
      pagination: {
        currentPage: 0,
        total: 0,
        perPage: 0,
        lastPage: 0
      }
    };
  },
  [M_INITIAL_GET_RECIPE_PAGE_STATE](state) {
    state.recipePage = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_GET_RECIPE_PAGE_SEARCHS_STATE](state) {
    state.recipePageSearchs = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_GET_RECIPE_PAGE_SEARCH_STATE](state) {
    state.recipePageSearch = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_CREATE_RECIPE_PAGE_STATE](state) {
    state.createRecipePage = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_UPDATE_RECIPE_PAGE_STATE](state) {
    state.updateRecipePage = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_DELETE_RECIPE_PAGE_STATE](state) {
    state.deleteRecipePage = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
