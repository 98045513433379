<template>
  <div>
    <AppSmartList
      ref="app-smart-list"
      :breadcrumbData="breadcrumbData"
      :accessRights="accessRights"
      :filters="filters"
      :actions="actions"
      :tableHeaders="headers"
      :newRecord="newRecord"
    >
    </AppSmartList>
  </div>
</template>

<script>
  import AppSmartList from '@/views/components/bases/smart/AppSmartList.vue';
  // import AppExportExcel from '@/views/components/bases/AppExportExcel.vue';
  import { i18nHelper } from '@/core/utils';
  import {
    ROUTE_NAME,
    MODULE,
    ACTION,
    APP_COMPONENT_NAME,
    SMART_FORM_FIELD_TYPE
  } from '@/core/constants';
  import { listService } from '@/core/services';
  import {
    MERCHANT_GROUPING_GET_MERCHANT_GROUPINGS,
    MERCHANT_GROUPING_INITIAL_GET_MERCHANT_GROUPINGS_STATE
  } from '@/core/store/merchant-grouping.module';

  export default {
    name: 'MerchantGroupingList',
    components: {
      AppSmartList
      // AppExportExcel
    },
    data: () => ({
      breadcrumbData: {
        breadcrumbs: [
          {
            title: i18nHelper.getMessage('label.offDaySetting'),
            route: { name: ROUTE_NAME.MERCHANT_GROUPING }
          },
          { title: i18nHelper.getMessage('label.offDaySettingList') }
        ]
      },
      accessRights: {
        moduleName: MODULE.MERCHANT_GROUPING,
        actions: {
          read: ACTION.MERCHANT_GROUPING_READ,
          update: ACTION.MERCHANT_GROUPING_UPDATE,
          create: ACTION.MERCHANT_GROUPING_CREATE
        }
      },
      filters: [
        {
          label: 'id',
          key: 'id',
          value: ''
        },
        {
          label: 'corporateGroup',
          key: 'corporateGroup',
          value: ''
        },
        {
          label: 'status',
          key: 'status',
          value: '',
          options: listService.getStatusList(),
          optionLabel: 'text',
          type: SMART_FORM_FIELD_TYPE.SELECT
        }
      ],
      actions: {
        viewList: {
          module: 'merchantGrouping',
          state: 'merchantGroupings',
          action: MERCHANT_GROUPING_GET_MERCHANT_GROUPINGS,
          initialStateAction:
            MERCHANT_GROUPING_INITIAL_GET_MERCHANT_GROUPINGS_STATE,
          title: i18nHelper.getMessage('label.getOffDaySetting')
        }
      },
      headers: [
        {
          key: 'no',
          name: 'no',
          active: true,
          sortable: true
        },
        {
          key: 'id',
          name: 'id',
          active: true,
          sortable: true
        },
        {
          key: 'corporateGroup',
          name: 'corporateGroup',
          active: true,
          sortable: true
        },
        {
          key: 'totalSubsidiary',
          name: 'totalSubsidiary',
          active: true,
          sortable: true
        },
        {
          key: 'totalBranch',
          name: 'totalBranch',
          active: true,
          sortable: true
        },
        {
          key: 'status',
          name: 'status',
          active: true,
          sortable: true,
          layout: 'RecipeStatusLabel'
        },
        {
          key: 'actions',
          name: 'actions',
          active: true,
          action: true,
          layout: APP_COMPONENT_NAME.APP_TABLE_ACTIONS,
          layoutActions: {
            viewDetailsRouteName: ROUTE_NAME.MERCHANT_GROUPING_DETAILS
          }
        }
      ],
      newRecord: {
        show: true,
        buttonTitle: i18nHelper.getMessage('label.createCorporateGroup'),
        routeName: ROUTE_NAME.MERCHANT_GROUPING_CREATE
      }
    }),
    computed: {},
    watch: {},
    methods: {}
  };
</script>

<style></style>
