var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-card',{attrs:{"no-body":"","data-style":"custom"}},[_c('div',{staticClass:"wizard wizard-2",attrs:{"id":"voucherWizard","data-wizard-state":"step-first","data-wizard-clickable":"true"}},[_c('div',{staticClass:"wizard-nav border-right py-8 px-8 py-lg-20 px-lg-10"},[_c('div',{staticClass:"wizard-steps"},[_c('div',{staticClass:"wizard-step",attrs:{"data-wizard-type":"step","data-wizard-state":"current"}},[_c('div',{staticClass:"wizard-wrapper"},[_c('div',{staticClass:"wizard-icon"},[_c('span',{staticClass:"svg-icon svg-icon-2x"},[_c('inline-svg',{attrs:{"src":"/media/svg/icons/Communication/Clipboard-list.svg"}})],1)]),_c('div',{staticClass:"wizard-label text-capitalize"},[_c('h3',{staticClass:"wizard-title"},[_vm._v(" "+_vm._s(_vm.$t('label.voucher'))+" ")]),_c('div',{staticClass:"wizard-desc"},[_vm._v(" "+_vm._s(_vm.$t('label.fillInInfoAndAction'))+" ")])])])]),(!_vm.isCreate)?_c('div',{staticClass:"wizard-step",attrs:{"data-wizard-type":"step","data-wizard-state":""}},[_c('div',{staticClass:"wizard-wrapper"},[_c('div',{staticClass:"wizard-icon"},[_c('span',{staticClass:"svg-icon svg-icon-2x"},[_c('inline-svg',{attrs:{"src":"/media/svg/icons/Communication/Clipboard-check.svg"}})],1)]),_c('div',{staticClass:"wizard-label text-capitalize",class:{
                'text-primary cursor-pointer': _vm.voucherObj.totalClaim
              }},[_c('h3',{staticClass:"wizard-title"},[_vm._v(" "+_vm._s(`${_vm.voucherObj.totalClaim} ` + _vm.$t(`label.users`))+" ")]),_c('div',{staticClass:"wizard-desc"},[_vm._v(" "+_vm._s(_vm.$t('label.claimedUserTable'))+" ")])])])]):_vm._e(),_c('div',{staticClass:"wizard-step",attrs:{"data-wizard-type":"step","data-wizard-state":""}},[(
              !_vm.isCreate && _vm.voucherObj.applyTo == _vm.voucherApplyTo.SELECTED_USER
            )?_c('div',{staticClass:"wizard-wrapper"},[_c('div',{staticClass:"wizard-icon"},[_c('span',{staticClass:"svg-icon svg-icon-2x"},[_c('inline-svg',{attrs:{"src":"/media/svg/icons/Communication/Clipboard-check.svg"}})],1)]),_c('div',{staticClass:"wizard-label",class:{
                'text-primary cursor-pointer': true
              }},[_c('h3',{staticClass:"wizard-title text-capitalize"},[_vm._v(" "+_vm._s(`${_vm.voucherObj.totalSpecific} ` + _vm.$t(`label.specificUsers`))+" ")]),_c('div',{staticClass:"wizard-desc"},[_vm._v(" "+_vm._s(_vm.$t('label.leaveItEmptyIfApplyToAllUsers'))+" ")])])]):_vm._e()])])]),_c('div',{staticClass:"wizard-body py-6 px-8",staticStyle:{"overflow-x":"auto","width":"100%"}},[_c('b-row',[_c('b-col',{attrs:{"offset-xl":"2","xl":"8"}},[_c('div',{attrs:{"data-wizard-type":"step-content","data-wizard-state":"current"}},[_c('VoucherForm',{ref:"VoucherForm",attrs:{"id":_vm.id,"voucherObj":_vm.voucherObj,"isCreate":_vm.isCreate}})],1),(!_vm.isCreate)?_c('div',{staticClass:"pb-5",attrs:{"data-wizard-type":"step-content","data-wizard-state":""}},[_c('VoucherClaimedList',{attrs:{"id":_vm.id,"voucherObj":_vm.voucherObj}})],1):_vm._e(),(!_vm.isCreate)?_c('div',{staticClass:"pb-5",attrs:{"data-wizard-type":"step-content","data-wizard-state":""}},[(!_vm.isCreate)?_c('VoucherSpecificUserList',{attrs:{"id":_vm.id,"voucherObj":_vm.voucherObj}}):_vm._e()],1):_vm._e()])],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }