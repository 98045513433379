<template>
  <b-card no-body class="px-6 py-2">
    <div class="d-flex justify-content-between align-items-center">
      <div style="word-break: break-all">
        <i class="flaticon-attachment mr-4"></i>
        <span class="mb-0 text-uppercase font-size-sm">{{
          documentName(attachmentUrl)
        }}</span>
      </div>
      <div class="d-flex">
        <label
          v-if="hasReplace"
          class="text-primary cursor-pointer mb-0 mr-4 font-size-sm"
          @click="$emit('replace-action')"
        >
          {{ $t('label.replace') }}
        </label>
        <AppButtonDocumentView
          :attachmentUrl="attachmentUrl"
          :fileType="'pdf'"
        ></AppButtonDocumentView>
      </div>
    </div>
  </b-card>
</template>

<script>
  import AppButtonDocumentView from '@/views/components/bases/button/AppButtonDocumentView';

  export default {
    name: 'PlatformInfoDocumentPlaceholder',
    components: {
      AppButtonDocumentView
    },
    props: {
      attachmentUrl: {
        type: String,
        default: ''
      },
      hasReplace: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      documentName(data) {
        return data ? data.split('/').reverse()[0] : '';
      }
    }
  };
</script>

<style lang="scss"></style>
