const GLOBAL = Object.freeze({
  LAYOUT: 'Layout',
  LAYOUT_BLANK: 'LayoutBlank',
  DEFAULT_SEARCH_DATE_START: new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    2
  )
    .toISOString()
    .substr(0, 10),
  DEFAULT_SEARCH_DATE_END: new Date(
    new Date().getFullYear(),
    new Date().getMonth() + 1,
    1
  )
    .toISOString()
    .substr(0, 10),
  AVAILABLE_BANNER_IMAGE_TYPE: ['image/jpeg', 'image/jpg', 'image/png'],
  AVAILABLE_IMAGE_TYPE: ['image/jpeg', 'image/jpg', 'image/png'],
  AVAILABLE_BANNER_VIDEO_TYPE: ['video/mp4'],
  AVAILABLE_VIDEO_TYPE: ['video/mp4'],
  AVAILABLE_VIDEO_IMAGE_TYPE: [
    'image/jpeg',
    'image/jpg',
    'image/png',
    'video/mp4'
  ],
  AVAILABLE_PLATFORM_INFO_APPLICATION_TYPE: ['application/pdf'],
  BANNER_IMAGE_UPLOAD_SIZE: 500,
  BANNER_IMAGE_UPLOAD_SIZE_TYPE: 'KB',
  BANNER_VIDEO_UPLOAD_SIZE: 500,
  BANNER_VIDEO_UPLOAD_SIZE_TYPE: 'MB',
  DEFAULT_TABLE_PAGINATION: {
    currentPage: 1,
    total: 1,
    perPage: 10,
    lastPage: 1
  },
  DEFAULT_SEARCH_CRITERIA: {
    perPage: 10,
    page: 1
  },
  SUPER_ADMIN: 'superadmin',
  AVAILABLE_APPLICATION_DOCUMENT_FILE_TYPE: [
    'image/jpeg',
    'image/jpg',
    'image/png',
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ]
});

const LOCAL = Object.freeze({
  CONSTANTS: 'constants'
});

const CURRENCY = Object.freeze({
  MYR: 'myr'
});

const SMART_FORM_FIELD_TYPE = Object.freeze({
  TEXT: 'text',
  SELECT: 'select',
  MULTI_SELECT: 'multiSelect',
  SEARCH_SELECT: 'SearchSelect',
  DATE_RANGE: 'dateRange'
});

const APP_COMPONENT_NAME = Object.freeze({
  APP_CHECK_CIRCLE: 'AppCheckCircle',
  APP_STATUS_LABEL: 'AppStatusLabel',
  APP_TABLE_ACTIONS: 'AppTableActions',
  APP_TABLE_APPLY_BUTTON: 'AppTableApplyButton',
  APP_TABLE_DELETE_BUTTON: 'AppTableDeleteButton',
  APP_MEMBER_TYPE_LABEL: 'AppMemberTypeLabel',
  APP_BUSINESS_HOUR_LIST: 'AppBusinessHourList',
  APP_VISIBILITY_STATUS_BUTTON: 'AppVisibilityStatusButton',
  APP_STATUS_BUTTON: 'AppStatusButton',
  APP_TAGS: 'AppTags',
  APP_DROPDOWN: 'AppDropdown',
  APP_DROPDOWN_WITH_BUTTON: 'AppDropdownWithButton',
  APP_TEXT_TRUNCATE_CONTAINER: 'AppTextTruncateContainer',
  APP_FEATURE_DETAILS_RELATED_BUTTON: 'AppFeatureDetailsRelatedButton',
  APP_SWITCH: 'AppSwitch',
  APP_SWITCH_WITH_LABEL: 'AppSwitchWithLabel',
  APP_AVERAGE_RATING: 'AppAverageRating',
  APP_ENUM_LABEL: 'AppEnumLabel',
  APP_COLOR_PICKER: 'AppColorPicker',
  APP_TEXTFIELDS: 'AppTextFields',
  APP_DATETIMEPACKERFROM: 'AppDateTimePackerFrom',
  APP_DATETIMEPACKERTO: 'AppDateTimePackerTo',
  APP_TEXT_WITH_BUTTON_FIELDS: 'AppTextWithButtonFields'
});

const ERROR_CODE = Object.freeze({
  UNAUTHORIZED: 1001,
  MULTIPLE_LOGINS: 1008,
  INVALID_TOKEN: 1009
});

const COUNTRY_CODE = Object.freeze({
  MYR: 60
});

export {
  GLOBAL,
  CURRENCY,
  LOCAL,
  SMART_FORM_FIELD_TYPE,
  APP_COMPONENT_NAME,
  ERROR_CODE,
  COUNTRY_CODE
};
