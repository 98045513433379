import { apiService } from '@/core/services';

export const AUDIT_LOG = {
  getAuditLogs,
  getAuditLog
};

const URL = {
  ADMIN: 'admin',
  AUDIT_LOGS: 'audit-logs'
};

function getAuditLogs(data) {
  let requestOptions = {
    params: {
      username: data.username,
      action: data.action,
      type: data.type,
      model_id: data.modelId,
      per_page: data.perPage,
      page: data.page
    }
  };
  return apiService().get(`/${URL.ADMIN}/${URL.AUDIT_LOGS}`, requestOptions);
}

function getAuditLog(id) {
  return apiService().get(`/${URL.ADMIN}/${URL.AUDIT_LOGS}/${id}`);
}
