import { REFERRAL_CODE } from '@/core/api';
import { apiHelper } from '@/core/utils';
import {
  LOADING_OPEN_LOADING,
  LOADING_CLOSE_LOADING
} from '@/core/store/loading.module';
import dateFormat from '@/core/filters/dateFormat.filter';
import contactNumber from '@/core/filters/contact-number-format.filter';

/* Define Module Name */
const MODULE_NAME = 'referralCode/';

/* Define Action Name */
const A_GET_REFERAL_CODES = 'getReferralCodes';
const A_GET_REFERAL_CODE = 'getReferralCode';
const A_GET_REFERAL_CODE_EXPORT = 'getReferralCodeExport';
const A_GET_REFERAL_CODE_REFEREES = 'getReferralCodeReferees';
const A_CREATE_REFERAL_CODE = 'createReferralCode';
const A_CREATE_REFERAL_CODE_REFEREE = 'createReferralCodeReferee';
const A_UPDATE_REFERAL_CODE = 'updateReferralCode';
const A_UPDATE_REFERAL_CODE_ACTIVATE = 'updateReferralCodeExport';
const A_DELETE_REFERAL_CODE = 'deleteReferralCode';

/* Define Reset State Action Name */
const A_INITIAL_GET_REFERAL_CODES_STATE = 'initialGetReferralCodesState';
const A_INITIAL_GET_REFERAL_CODE_STATE = 'initialGetReferralCodeState';
const A_INITIAL_GET_REFERAL_CODE_EXPORT_STATE =
  'initialGetReferralCodeExportState';
const A_INITIAL_GET_REFERAL_CODE_REFEREES_STATE =
  'initialGetReferralCodeRefereesState';
const A_INITIAL_CREATE_REFERAL_CODE_STATE = 'initialCreateReferralCodeState';
const A_INITIAL_CREATE_REFERAL_CODE_REFEREE_STATE =
  'initialCreateReferralCodeRefereeState';
const A_INITIAL_UPDATE_REFERAL_CODE_STATE = 'initialUpdateReferralCodeState';
const A_INITIAL_UPDATE_REFERAL_CODE_ACTIVATE_STATE =
  'initialUpdateReferralCodeExportState';
const A_INITIAL_DELETE_REFERAL_CODE_STATE = 'initialDeleteReferralCodeState';

/* Define Getter Name */

/* Define Mutation Name */
const M_GET_REFERAL_CODES = 'setGetReferralCodes';
const M_GET_REFERAL_CODE = 'setGetReferralCode';
const M_GET_REFERAL_CODE_EXPORT = 'setGetReferralCodeExport';
const M_GET_REFERAL_CODE_REFEREES = 'setGetReferralCodeReferees';
const M_CREATE_REFERAL_CODE = 'setCreateReferralCode';
const M_CREATE_REFERAL_CODE_REFEREE = 'setCreateReferralCodeReferee';
const M_UPDATE_REFERAL_CODE = 'setUpdateReferralCode';
const M_UPDATE_REFERAL_CODE_ACTIVATE = 'setUpdateReferralCodeExport';
const M_DELETE_REFERAL_CODE = 'setDeleteReferralCode';

/* Define Reset State Mutation Name */
const M_INITIAL_GET_REFERAL_CODES_STATE = 'setInitialGetReferralCodesState';
const M_INITIAL_GET_REFERAL_CODE_STATE = 'setInitialGetReferralCodeState';
const M_INITIAL_GET_REFERAL_CODE_EXPORT_STATE =
  'setInitialGetReferralCodeExportState';
const M_INITIAL_GET_REFERAL_CODE_REFEREES_STATE =
  'setInitialGetReferralCodeRefereesState';
const M_INITIAL_CREATE_REFERAL_CODE_STATE = 'setInitialCreateReferralCodeState';
const M_INITIAL_CREATE_REFERAL_CODE_REFEREE_STATE =
  'setInitialCreateReferralCodeRefereeState';
const M_INITIAL_UPDATE_REFERAL_CODE_STATE = 'setInitialUpdateReferralCodeState';
const M_INITIAL_UPDATE_REFERAL_CODE_ACTIVATE_STATE =
  'setInitialUpdateReferralCodeExportState';
const M_INITIAL_DELETE_REFERAL_CODE_STATE = 'setInitialDeleteReferralCodeState';

/* Define Export Name */
export const REFERRAL_CODE_GET_REFERAL_CODES =
  MODULE_NAME + A_GET_REFERAL_CODES;
export const REFERRAL_CODE_GET_REFERAL_CODE = MODULE_NAME + A_GET_REFERAL_CODE;
export const REFERRAL_CODE_GET_REFERAL_CODE_EXPORT =
  MODULE_NAME + A_GET_REFERAL_CODE_EXPORT;
export const REFERRAL_CODE_GET_REFERAL_CODE_REFEREES =
  MODULE_NAME + A_GET_REFERAL_CODE_REFEREES;
export const REFERRAL_CODE_CREATE_REFERAL_CODE =
  MODULE_NAME + A_CREATE_REFERAL_CODE;
export const REFERRAL_CODE_CREATE_REFERAL_CODE_REFEREE =
  MODULE_NAME + A_CREATE_REFERAL_CODE_REFEREE;
export const REFERRAL_CODE_UPDATE_REFERAL_CODE =
  MODULE_NAME + A_UPDATE_REFERAL_CODE;
export const REFERRAL_CODE_UPDATE_REFERAL_CODE_ACTIVATE =
  MODULE_NAME + A_UPDATE_REFERAL_CODE_ACTIVATE;
export const REFERRAL_CODE_DELETE_REFERAL_CODE =
  MODULE_NAME + A_DELETE_REFERAL_CODE;
/* Define Reset State Name */
export const REFERRAL_CODE_INITIAL_GET_REFERAL_CODES_STATE =
  MODULE_NAME + A_INITIAL_GET_REFERAL_CODES_STATE;
export const REFERRAL_CODE_INITIAL_GET_REFERAL_CODE_STATE =
  MODULE_NAME + A_INITIAL_GET_REFERAL_CODE_STATE;
export const REFERRAL_CODE_INITIAL_GET_REFERAL_CODE_REFEREES_STATE =
  MODULE_NAME + A_INITIAL_GET_REFERAL_CODE_REFEREES_STATE;
export const REFERRAL_CODE_INITIAL_GET_REFERAL_CODE_EXPORT_STATE =
  MODULE_NAME + A_INITIAL_GET_REFERAL_CODE_EXPORT_STATE;
export const REFERRAL_CODE_INITIAL_CREATE_REFERAL_CODE_STATE =
  MODULE_NAME + A_INITIAL_CREATE_REFERAL_CODE_STATE;
export const REFERRAL_CODE_INITIAL_CREATE_REFERAL_CODE_REFEREE_STATE =
  MODULE_NAME + A_INITIAL_CREATE_REFERAL_CODE_REFEREE_STATE;
export const REFERRAL_CODE_INITIAL_UPDATE_REFERAL_CODE_STATE =
  MODULE_NAME + A_INITIAL_UPDATE_REFERAL_CODE_STATE;
export const REFERRAL_CODE_INITIAL_UPDATE_REFERAL_CODE_ACTIVATE_STATE =
  MODULE_NAME + A_INITIAL_UPDATE_REFERAL_CODE_ACTIVATE_STATE;
export const REFERRAL_CODE_INITIAL_DELETE_REFERAL_CODE_STATE =
  MODULE_NAME + A_INITIAL_DELETE_REFERAL_CODE_STATE;

const state = {
  referralCodes: {
    code: 0,
    complete: false,
    data: null,
    message: null,
    pagination: {
      currentPage: 0,
      total: 0,
      perPage: 0,
      lastPage: 0
    }
  },
  referralCode: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  referralCodeExport: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  referralCodeReferees: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  createReferralCode: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  createReferralCodeReferee: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  updateReferralCode: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  updateReferralCodeActivate: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  deleteReferralCode: {
    code: 0,
    complete: false,
    data: null,
    message: null
  }
};

//Getters
const getters = {};

// Actions
const actions = {
  async [A_GET_REFERAL_CODES]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    await REFERRAL_CODE.getReferralCodes(data)
      .then((respone) => {
        let result = respone;
        commit(M_GET_REFERAL_CODES, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_GET_REFERAL_CODES, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_REFERAL_CODE]({ dispatch, commit }, { id }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    REFERRAL_CODE.getReferralCodeId(id)
      .then((respone) => {
        let result = respone;
        commit(M_GET_REFERAL_CODE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_GET_REFERAL_CODE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_REFERAL_CODE_EXPORT]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    REFERRAL_CODE.getReferralCodeIdExport(data)
      .then((respone) => {
        let result = respone;
        commit(M_GET_REFERAL_CODE_EXPORT, { result, data });
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_GET_REFERAL_CODE_EXPORT, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_REFERAL_CODE_REFEREES]({ dispatch, commit }, { id, data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    REFERRAL_CODE.getReferralCodeReferees(id, data)
      .then((respone) => {
        let result = respone;
        commit(M_GET_REFERAL_CODE_REFEREES, { result });
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_GET_REFERAL_CODE_REFEREES, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_CREATE_REFERAL_CODE]({ dispatch, commit }, { formData }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    REFERRAL_CODE.createReferralCodeId(formData)
      .then((respone) => {
        let result = respone;
        commit(M_CREATE_REFERAL_CODE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_CREATE_REFERAL_CODE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_CREATE_REFERAL_CODE_REFEREE]({ dispatch, commit }, { id, data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    REFERRAL_CODE.createReferralCodeReferee(id, data)
      .then((respone) => {
        let result = respone;
        commit(M_CREATE_REFERAL_CODE_REFEREE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_CREATE_REFERAL_CODE_REFEREE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_UPDATE_REFERAL_CODE]({ dispatch, commit }, { id, formData }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    REFERRAL_CODE.updateReferralCodeId(id, formData)
      .then((respone) => {
        let result = respone;
        commit(M_UPDATE_REFERAL_CODE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_UPDATE_REFERAL_CODE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_UPDATE_REFERAL_CODE_ACTIVATE]({ dispatch, commit }, { id, data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    REFERRAL_CODE.updateReferralCodeIdActivate(id, data)
      .then((respone) => {
        let result = respone;
        commit(M_UPDATE_REFERAL_CODE_ACTIVATE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_UPDATE_REFERAL_CODE_ACTIVATE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_DELETE_REFERAL_CODE]({ dispatch, commit }, { id, formData }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    REFERRAL_CODE.deleteReferralCodeId(id, formData)
      .then((respone) => {
        let result = respone;
        commit(M_DELETE_REFERAL_CODE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_DELETE_REFERAL_CODE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_INITIAL_GET_REFERAL_CODES_STATE]({ commit }) {
    commit(M_INITIAL_GET_REFERAL_CODES_STATE);
  },
  [A_INITIAL_GET_REFERAL_CODE_STATE]({ commit }) {
    commit(M_INITIAL_GET_REFERAL_CODE_STATE);
  },
  [A_INITIAL_GET_REFERAL_CODE_EXPORT_STATE]({ commit }) {
    commit(M_INITIAL_GET_REFERAL_CODE_EXPORT_STATE);
  },
  [A_INITIAL_GET_REFERAL_CODE_REFEREES_STATE]({ commit }) {
    commit(M_INITIAL_GET_REFERAL_CODE_REFEREES_STATE);
  },
  [A_INITIAL_CREATE_REFERAL_CODE_STATE]({ commit }) {
    commit(M_INITIAL_CREATE_REFERAL_CODE_STATE);
  },
  [A_INITIAL_CREATE_REFERAL_CODE_REFEREE_STATE]({ commit }) {
    commit(M_INITIAL_CREATE_REFERAL_CODE_REFEREE_STATE);
  },
  [A_INITIAL_UPDATE_REFERAL_CODE_STATE]({ commit }) {
    commit(M_INITIAL_UPDATE_REFERAL_CODE_STATE);
  },
  [A_INITIAL_UPDATE_REFERAL_CODE_ACTIVATE_STATE]({ commit }) {
    commit(M_INITIAL_UPDATE_REFERAL_CODE_ACTIVATE_STATE);
  },
  [A_INITIAL_DELETE_REFERAL_CODE_STATE]({ commit }) {
    commit(M_INITIAL_DELETE_REFERAL_CODE_STATE);
  }
};

// Mutations
const mutations = {
  [M_GET_REFERAL_CODES](state, result) {
    if (result.code === 0) {
      let d = result.data;
      let p = result.pagination;
      let referralCodes = [];

      d.forEach((x, index) => {
        referralCodes.push({
          no: index + 1,
          id: x.id,
          rewardTypeDesc: x.reward_type_description,
          referralCode: x.referral_voucher_code.code,
          quantity: x.referral_voucher_code.quantity,
          status: x.referral_voucher_code.status,
          totalClaim: x.referral_voucher_code.referees_count,
          totalRedemption: x.total_redeem,
          availableFrom: dateFormat(x.referral_voucher_code.available_from),
          availableTo: dateFormat(x.referral_voucher_code.available_to)
        });
      });

      state.referralCodes = {
        code: result.code,
        complete: true,
        data: referralCodes,
        pagination: {
          currentPage: p.current_page,
          total: p.total,
          perPage: p.per_page,
          lastPage: p.last_page
        }
      };
    } else {
      state.referralCodes = {
        code: result.code,
        complete: true,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_GET_REFERAL_CODE](state, result) {
    if (result.code === 0) {
      let d = result.data;
      let referralCode;
      referralCode = {
        id: d.id,
        usagePeriodFrom: dateFormat(d.referral_voucher_code.available_from),
        usagePeriodTo: dateFormat(d.referral_voucher_code.available_to),
        referralCode: d.referral_voucher_code.code,
        quantity: d.referral_voucher_code.quantity,
        status: d.referral_voucher_code.status,
        eventName: d.referral_voucher_code.event_name,
        refereesCount: d.referral_voucher_code.referees_count,
        redemptionPeriodFrom: dateFormat(d.available_from),
        redemptionPeriodTo: dateFormat(d.available_to),
        banner: d.banner,
        description: d.description,
        isActive: d.is_active,
        merchantName: d.merchant_name,
        minimumSpending: d.minimum_spending,
        pinVerification: d.pin_verification,
        rewardType: d.reward_type,
        rewardType_description: d.reward_type_description,
        thumbnail: d.thumbnail,
        title: d.title,
        tnc: d.tnc,
        usageLimit: d.usage_limit,
        voucherValueType: {
          type: d.value_type,
          value: d.value
        },
        vouchervalueTypeDescription: d.value_type_description,
        voucherPrefix: d.voucher_prefix,
        voucherType: d.voucher_type,
        voucherTypeDescription: d.voucher_type_description
      };

      state.referralCode = {
        code: result.code,
        complete: true,
        data: referralCode
      };
    } else {
      state.referralCode = {
        code: result.code,
        complete: true,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_GET_REFERAL_CODE_EXPORT](state, result) {
    let d = result.result;
    let code = d ? d.code : null;
    let data = result.data;
    if (code) {
      state.referralCodeExport = {
        code: d.code,
        complete: true,
        data: d.data,
        message: apiHelper.sortOutMessage(d.message)
      };
    } else {
      state.referralCodeExport = {
        code: 0,
        complete: true,
        data: data
      };
    }
  },
  [M_GET_REFERAL_CODE_REFEREES](state, result) {
    result = result.result;
    if (result.code === 0) {
      let d = result.data;
      let p = result.pagination;

      let referralCodesReferees = [];

      d.forEach((x, index) => {
        referralCodesReferees.push({
          no: index + 1,
          id: x.user_id,
          contactNumber: {
            id: x.user_id,
            name: contactNumber(x.contact_number)
          },
          referee: x.username,
          voucherCode: x.voucher_code,
          createdAt: dateFormat(x.created_at),
          redemptionAt: dateFormat(x.redemption_at)
        });
      });

      state.referralCodeReferees = {
        code: result.code,
        complete: true,
        data: referralCodesReferees,
        pagination: {
          currentPage: p.current_page,
          total: p.total,
          perPage: p.per_page,
          lastPage: p.last_page
        }
      };
    } else {
      state.referralCodeReferees = {
        code: result.code,
        complete: true,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_CREATE_REFERAL_CODE](state, result) {
    if (result.code === 0) {
      let d = result.data;

      state.createReferralCode = {
        code: result.code,
        complete: true,
        data: d,
        message: apiHelper.sortOutMessage(result.message)
      };
    } else {
      state.createReferralCode = {
        code: result.code,
        complete: true,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_CREATE_REFERAL_CODE_REFEREE](state, result) {
    if (result.code === 0) {
      let d = result.data;

      state.createReferralCodeReferee = {
        code: result.code,
        complete: true,
        data: d,
        message: apiHelper.sortOutMessage(result.message)
      };
    } else {
      state.createReferralCodeReferee = {
        code: result.code,
        complete: true,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_UPDATE_REFERAL_CODE](state, result) {
    if (result.code === 0) {
      let d = result.data;

      state.updateReferralCode = {
        code: result.code,
        complete: true,
        data: d,
        message: apiHelper.sortOutMessage(result.message)
      };
    } else {
      state.updateReferralCode = {
        code: result.code,
        complete: true,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_UPDATE_REFERAL_CODE_ACTIVATE](state, result) {
    if (result.code === 0) {
      let d = result.data;

      state.updateReferralCodeActivate = {
        code: result.code,
        complete: true,
        data: d,
        message: apiHelper.sortOutMessage(result.message)
      };
    } else {
      state.updateReferralCodeActivate = {
        code: result.code,
        complete: true,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_DELETE_REFERAL_CODE](state, result) {
    if (result.code === 0) {
      let d = result.data;

      state.deleteReferralCode = {
        code: result.code,
        complete: true,
        data: d,
        message: apiHelper.sortOutMessage(result.message)
      };
    } else {
      state.deleteReferralCode = {
        code: result.code,
        complete: true,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_INITIAL_GET_REFERAL_CODES_STATE](state) {
    state.referralCodes = {
      code: 0,
      complete: false,
      data: null,
      message: null,
      pagination: {
        currentPage: 0,
        total: 0,
        perPage: 0,
        lastPage: 0
      }
    };
  },
  [M_INITIAL_GET_REFERAL_CODE_STATE](state) {
    state.referralCode = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_GET_REFERAL_CODE_EXPORT_STATE](state) {
    state.referralCodeExport = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_GET_REFERAL_CODE_REFEREES_STATE](state) {
    state.referralCodeReferees = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_CREATE_REFERAL_CODE_STATE](state) {
    state.createReferralCode = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_CREATE_REFERAL_CODE_REFEREE_STATE](state) {
    state.createReferralCodeReferee = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_UPDATE_REFERAL_CODE_STATE](state) {
    state.updateReferralCode = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_UPDATE_REFERAL_CODE_ACTIVATE_STATE](state) {
    state.updateReferralCodeActivate = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_DELETE_REFERAL_CODE_STATE](state) {
    state.deleteReferralCode = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
