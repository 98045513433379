<template>
  <b-card no-body data-style="custom">
    <!--begin: Wizard-->
    <div
      class="wizard wizard-2"
      id="equipmentWizard"
      data-wizard-state="step-first"
      data-wizard-clickable="true"
    >
      <!--begin: Wizard Nav -->
      <div class="wizard-nav border-right py-8 px-8 py-lg-20 px-lg-10">
        <div class="wizard-steps">
          <!--begin: Wizard Steps 1 -->
          <template
            v-for="(i, index) in wizardSteps.filter(
              (x) => x.isCreate !== isCreate
            )"
          >
            <div
              :key="index"
              class="wizard-step"
              data-wizard-type="step"
              :data-wizard-state="index < 1 ? 'current' : ''"
            >
              <WizardWrapper
                :icon="i.icon"
                :title="$t(`label.${i.title}`)"
                :description="$t(`label.${i.description}`)"
              ></WizardWrapper>
            </div>
          </template>

          <!--end: Wizard Steps 1 -->
        </div>
      </div>
      <!--end: Wizard Nav -->

      <!--begin: Wizard Body -->
      <div class="w-100 px-8 px-lg-10 py-8 overflow-x-auto">
        <div class="text-right">
          <EquipmentStatusLabel
            v-if="!isCreate"
            :value="equipmentInfo.status"
            class="p-4 font-size-sm"
          ></EquipmentStatusLabel>
        </div>
        <div class="wizard-body">
          <!--begin: Wizard Form-->
          <div data-wizard-type="step-content" :data-wizard-state="'current'">
            <EquipmentInfoForm
              ref="equipmentInfoForm"
              :id="id"
              :equipmentInfo="equipmentInfo"
              :isCreate="isCreate"
            ></EquipmentInfoForm>
            <EquipmentStatusBtn
              ref="EquipmentStatusBtn"
              :id="id"
              :equipmentInfo="equipmentInfo"
              :isCreate="isCreate"
              :fileMediaType="fileMediaType"
              :deliveryFees="deliveryFees"
            ></EquipmentStatusBtn>
          </div>
          <div data-wizard-type="step-content" :data-wizard-state="''">
            <EquipmentDocuments
              ref="EquipmentDocuments"
              v-model="fileMediaType"
              :id="id"
              :equipmentInfo="equipmentInfo"
              :isCreate="isCreate"
            ></EquipmentDocuments>
          </div>
          <div data-wizard-type="step-content" :data-wizard-state="''">
            <EquipmentDeliveryFees
              ref="EquipmentDeliveryFees"
              v-model="deliveryFees"
              :id="id"
              :equipmentInfo="equipmentInfo"
              :isCreate="isCreate"
            ></EquipmentDeliveryFees>
          </div>
          <div
            v-if="!isCreate"
            data-wizard-type="step-content"
            :data-wizard-state="''"
          >
            <EquipmentRecipesList
              ref="EquipmentRecipesList"
              :id="id"
              :equipmentInfo="equipmentInfo"
              :isCreate="isCreate"
            ></EquipmentRecipesList>
          </div>
          <div
            v-if="isCreate"
            class="d-flex justify-content-between border-top pt-10"
          >
            <div class="mr-2">
              <button
                class="btn btn-light-primary font-weight-bold text-uppercase"
                data-wizard-type="action-prev"
              >
                {{ $t('label.previous') }}
              </button>
            </div>
            <div>
              <button
                :disabled="
                  !checkAccessRight(moduleName, [
                    permissionAction.EQUIPMENTS_CREATE
                  ])
                "
                class="btn btn-success font-weight-bold text-uppercase"
                data-wizard-type="action-submit"
                type="submit"
              >
                {{ $t('label.create') }}
              </button>
              <button
                class="btn btn-primary font-weight-bold text-uppercase"
                data-wizard-type="action-next"
              >
                {{ $t('label.nextStep') }}
              </button>
            </div>
          </div>
        </div>
        <!--end: Wizard Body -->
      </div>
    </div>
    <!--end: Wizard-->
  </b-card>
</template>

<script>
  import WizardWrapper from '@/views/components/wizard/WizardWrapper';
  import EquipmentInfoForm from '@/views/components/equipment/EquipmentInfoForm.vue';
  import EquipmentDocuments from '@/views/components/equipment/EquipmentDocuments.vue';
  import EquipmentDeliveryFees from '@/views/components/equipment/EquipmentDeliveryFees.vue';
  import EquipmentStatusLabel from '@/views/components/equipment/EquipmentStatusLabel.vue';
  import EquipmentRecipesList from '@/views/components/equipment/recipe/EquipmentRecipesList.vue';
  import EquipmentStatusBtn from '@/views/components/equipment/EquipmentStatusBtn.vue';
  import KTUtil from '@/assets/js/components/util';
  import KTWizard from '@/assets/js/components/wizard';
  import constantsMixin from '@/core/mixins/constants.mixin';
  import commonMixin from '@/core/mixins/common.mixin';
  import { validationMixin } from 'vuelidate';
  import { GLOBAL, MODULE, ACTION, ROUTE_NAME } from '@/core/constants';
  import { i18nHelper } from '@/core/utils';
  import {
    EQUIPMENT_GET_EQUIPMENT,
    EQUIPMENT_INITIAL_GET_EQUIPMENT_STATE
  } from '@/core/store/equipment.module';

  export default {
    name: 'EquipmentDetailsTemplate',
    mixins: [commonMixin, constantsMixin, validationMixin],
    components: {
      WizardWrapper,
      EquipmentInfoForm,
      EquipmentDocuments,
      EquipmentDeliveryFees,
      EquipmentStatusLabel,
      EquipmentRecipesList,
      EquipmentStatusBtn
    },
    props: {
      isCreate: {
        Type: Boolean,
        defualt: false
      }
    },
    data: () => ({
      moduleName: MODULE.EQUIPMENTS,
      permissionAction: ACTION,
      global: GLOBAL,
      routeName: ROUTE_NAME,
      constWizard: {
        currentStep: 1
      },
      wizardSteps: [
        {
          step: 1,
          title: 'equipment',
          description: 'fillInInfoAndAction',
          icon: 'fab fa-wpforms',
          component: 'EquipmentInfoForm'
        },
        {
          step: 2,
          title: 'media',
          description: 'UploadDocuments',
          icon: 'fas fa-image',
          component: 'EquipmentDocuments'
        },
        {
          step: 3,
          title: 'deliveryFees',
          description: 'setUpTheDeliveryFeeForEquipmentSelling',
          icon: 'fas fa-truck',
          component: 'EquipmentDeliveryFees'
        },
        {
          step: 3,
          title: 'experienceCooking',
          description: 'linkExperienceCookingTable',
          icon: 'fas fa-list',
          component: 'EquipmentRecipesList',
          isCreate: true
        }
      ],
      equipmentInfo: {
        brandName: null,
        equipmentName: null,
        thumbnail: null,
        sellingPrice: null,
        discountValueType: {
          type: null,
          value: null
        },

        features: null,
        specification: null,
        warrantyInfo: null,
        productAssurance: null,
        termsAndConditionApply: null,
        remark: null
      },
      formFields: [],
      fileMediaType: null,
      deliveryFees: null
    }),
    computed: {
      id() {
        return this.$route.params.id;
      },
      getEquipmentComplete() {
        return this.$store.state.equipment.equipment.complete;
      }
    },
    watch: {
      getEquipmentComplete() {
        let response = this.$store.state.equipment.equipment;
        if (response.complete) {
          this.equipmentInfo = response.data;
          this.equipmentCompleteAction(response, 'get');
        }
      }
    },
    mounted() {
      this.initialEquipment();
    },
    methods: {
      equipmentCompleteAction(response, actionType) {
        let title = i18nHelper.getMessage('label.equipment');
        let messages = response.message;
        let buttons = [];
        switch (actionType) {
          case 'get':
            this.initialGetEquipmentState();
            break;
        }
        if (response.code == 0) {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();
              this.getEquipment(this.id);
            }
          });
        } else {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();
            }
          });
        }
        if (messages) this.openLayoutAppModalInfo(title, messages, buttons);
      },
      initWizard() {
        // Initialize form wizard
        let vm = this;

        const wizard = new KTWizard('equipmentWizard', {
          startStep: 1, // initial active step number
          clickableSteps: true // allow step clicking
        });

        vm.constWizard = wizard;
        wizard.on('submit', () => {
          if (
            vm.$refs.EquipmentDeliveryFees.validationCheckEquipmentDeliveryFees()
          ) {
            vm.$refs.EquipmentStatusBtn.submitEquipmentFormAction();
          }
        });

        //Change event
        wizard.on('change', function (/*wizardObj*/) {
          let v1,
            v2 = true;
          v1 = vm.$refs.equipmentInfoForm.validationCheckEquipmentInfo();
          if (vm.fileMediaType) {
            v2 = vm.$refs.EquipmentDocuments.validationCheckEquipmentDocuments(
              vm.fileMediaType
            );
          } else {
            if (vm.isCreate) {
              vm.$refs.EquipmentDocuments.validationCheckEquipmentDocuments(
                'image'
              );
              vm.$refs.EquipmentDocuments.validationCheckEquipmentDocuments(
                'video'
              );
              v2 = false;
            }
          }
          switch (wizard.currentStep) {
            case 1:
              if (!v1) {
                wizard.stop();
              }
              break;
            case 2:
              if (!v2) {
                wizard.stop();
              }
              break;
            default:
              KTUtil.scrollTop();
          }
        });
      },
      getEquipment(id) {
        this.$store.dispatch(EQUIPMENT_GET_EQUIPMENT, { id });
      },
      initialGetEquipmentState() {
        this.$store.dispatch(EQUIPMENT_INITIAL_GET_EQUIPMENT_STATE);
      },
      initialEquipment() {
        let breadcrumbData = {
          breadcrumbs: [
            {
              title: i18nHelper.getMessage('label.equipment'),
              route: { name: ROUTE_NAME.EQUIPMENT }
            },
            {
              title: i18nHelper.getMessage('label.equipmentList'),
              route: { name: ROUTE_NAME.EQUIPMENT_LIST }
            },
            {
              title: i18nHelper.getMessage(`label.equipmentDetails`)
            }
          ],
          actions: [
            {
              text: i18nHelper.getMessage('label.back'),
              function: () => {
                this.$router.go(-1);
              }
            }
          ]
        };

        this.initWizard();
        this.initBreadCrumb(breadcrumbData);
        if (!this.isCreate) this.getEquipment(this.id);
      }
    }
  };
</script>

<style lang="scss">
  @import '@/assets/sass/pages/wizard/wizard-2.scss';
</style>
