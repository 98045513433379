import { apiService } from '@/core/services';
// import { apiHelper } from '@/core/utils';

export const REFERRAL_CODE = {
  getReferralCodes,
  getReferralCodeId,
  getReferralCodeReferees,
  getReferralCodeIdExport,
  createReferralCodeId,
  createReferralCodeReferee,
  updateReferralCodeId,
  updateReferralCodeIdActivate,
  deleteReferralCodeId
};

const URL = {
  ADMIN: 'admin',
  VOUCHERS: 'vouchers',
  REFEREES: 'referees',
  EXPORT_REFEREES: 'export-referees',
  UPDATE_STATUS_REFERRAL_CODE: 'update-status-referral-code',
  ADD_NEW_REFEREE: 'add-new-referee'
};

function getReferralCodes(data) {
  let requestOptions = {
    params: {
      referral_code: data.referralCode,
      id: data.id,
      status: data.status,
      per_page: data.perPage,
      page: data.page
    }
  };

  return apiService().get(`/${URL.ADMIN}/${URL.VOUCHERS}`, requestOptions);
}

function getReferralCodeId(id) {
  return apiService().get(`/${URL.ADMIN}/${URL.VOUCHERS}/${id} `);
}

function getReferralCodeReferees(id, data) {
  let requestOptions = {
    params: {
      per_page: data.perPage,
      page: data.page
    }
  };
  return apiService().get(
    `/${URL.ADMIN}/${URL.VOUCHERS}/${id}/${URL.REFEREES}`,
    requestOptions
  );
}

function getReferralCodeIdExport(data) {
  let id = data.id;
  return apiService().get(
    `/${URL.ADMIN}/${URL.VOUCHERS}/${id}/${URL.EXPORT_REFEREES}`
  );
}

function createReferralCodeId(formData) {
  return apiService().post(`/${URL.ADMIN}/${URL.VOUCHERS}`, formData);
}

function createReferralCodeReferee(id, data) {
  let requestBody = {
    voucher_id: id,
    user_id: data.id
  };
  return apiService().post(
    `/${URL.ADMIN}/${URL.VOUCHERS}/${URL.ADD_NEW_REFEREE}`,
    requestBody
  );
}

function updateReferralCodeId(id, formData) {
  return apiService().post(`/${URL.ADMIN}/${URL.VOUCHERS}/${id}`, formData);
}

function updateReferralCodeIdActivate(id, data) {
  let requestBody = {
    status: data
  };
  return apiService().put(
    `/${URL.ADMIN}/${URL.VOUCHERS}/${id}/${URL.UPDATE_STATUS_REFERRAL_CODE}`,
    requestBody
  );
}

function deleteReferralCodeId(id) {
  return apiService().delete(`/${URL.ADMIN}/${URL.VOUCHERS}/${id}`);
}
