<template>
  <b-modal
    v-model="value"
    :title="title"
    centered
    hide-header-close
    no-close-on-esc
    no-close-on-backdrop
    header-class="text-capitalize"
    size="xl"
    body-class="p-0"
    modal-class="app-modal-description-list"
  >
    <div class="max-h-400px overflow-y-scroll">
      <template v-for="(i, index) in descriptions">
        <b-row :key="index" no-gutters class="descriptions-row px-10 py-5">
          <b-col cols="12" md="4">
            <label class="text-capitalize description-label">{{
              $t(`label.${i.label}`)
            }}</label>
          </b-col>
          <b-col cols="12" md="8">
            <div v-if="i.layout">
              <component
                v-if="i.layout"
                :is="i.layout"
                :value="dataObject[i.key]"
              >
              </component>
            </div>
            <div v-else>
              {{ dataObject[i.key] }}
            </div>
          </b-col>
        </b-row>
      </template>
    </div>
    <template #modal-footer>
      <b-button class="ml-2" variant="primary" @click="actionClose">{{
        $t('label.close')
      }}</b-button>
    </template>
  </b-modal>
</template>

<script>
  import AppCheckCircle from '@/views/components/bases/AppCheckCircle';
  import AppStatusLabel from '@/views/components/bases/AppStatusLabel';
  import AppMemberTypeLabel from '@/views/components/bases/AppMemberTypeLabel';
  import AppBusinessHourList from '@/views/components/bases/AppBusinessHourList';
  import AuditLogCompareTable from '@/views/components/audit-log/AuditLogCompareTable';

  export default {
    name: 'AppModalDescriptionList',
    components: {
      AppCheckCircle,
      AppStatusLabel,
      AppMemberTypeLabel,
      AppBusinessHourList,
      AuditLogCompareTable
    },
    props: {
      value: {
        type: Boolean,
        default: false,
        required: true
      },
      title: {
        type: String,
        default: '',
        required: true
      },
      descriptions: {
        type: Array,
        default: () => [],
        required: true
      },
      dataObject: {
        type: Object,
        default: () => {},
        required: true
      }
    },
    methods: {
      actionClose() {
        this.input(false);
      },
      input(value) {
        this.$emit('input', value);
      }
    }
  };
</script>

<style lang="scss">
  .app-modal-description-list {
    .description-label {
      font-weight: 500;
    }
  }
  .descriptions-row {
    &:nth-child(odd) {
      background-color: #f8fbfb;
    }
  }
</style>
