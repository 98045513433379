<template>
  <AllVideosList :category="creationType"></AllVideosList>
</template>

<script>
  import AllVideosList from '@/views/components/mobile-application/all-videos/AllVideosList';
  import { CreationType } from '@/core/constants/enums';

  export default {
    name: 'AllVideosProductList',
    components: {
      AllVideosList
    },
    data: () => ({
      creationType: CreationType.PRODUCT
    })
  };
</script>

<style></style>
