<template>
  <div>
    <AppSmartList
      :breadcrumbData="breadcrumbData"
      :accessRights="accessRights"
      :filters="filters"
      :actions="actions"
      :tableHeaders="headers"
    >
      <template v-slot:buttons>
        <AppExportExcel
          :exportExcelObj="exportExcel"
          :isDate="true"
        ></AppExportExcel>
      </template>
    </AppSmartList>
  </div>
</template>

<script>
  import AppSmartList from '@/views/components/bases/smart/AppSmartList.vue';
  import AppExportExcel from '@/views/components/bases/AppExportExcel.vue';
  import {
    LOCAL,
    ROUTE_NAME,
    MODULE,
    ACTION,
    APP_COMPONENT_NAME,
    SMART_FORM_FIELD_TYPE
  } from '@/core/constants';
  import { i18nHelper, uiHelper, validationHelper } from '@/core/utils';
  import { listService } from '@/core/services';
  import {
    USER_GET_USERS,
    USER_INITIAL_GET_USERS_STATE
  } from '@/core/store/user.module';

  const CONSTANTS_DATA = JSON.parse(uiHelper.getLocalStorage(LOCAL.CONSTANTS));

  export default {
    name: 'ProfileDetailsList',
    components: {
      AppSmartList,
      AppExportExcel
    },
    data: () => ({
      breadcrumbData: {
        breadcrumbs: [
          {
            title: i18nHelper.getMessage('label.profileDetails'),
            route: { name: ROUTE_NAME.PROFILE_DETAILS }
          },
          { title: i18nHelper.getMessage('label.profileDetailsList') }
        ]
      },
      accessRights: {
        moduleName: MODULE.USER,
        actions: {
          create: ACTION.USER_CREATE,
          read: ACTION.USER_READ,
          update: ACTION.USER_UPDATE,
          delete: ACTION.USER_DELETE
        }
      },
      filters: [
        {
          label: 'username',
          key: 'username',
          value: ''
        },
        {
          label: 'status',
          key: 'isActive',
          value: '',
          options: listService.getStatusList(),
          optionLabel: 'text',
          type: SMART_FORM_FIELD_TYPE.SELECT
        },
        {
          label: 'memberType',
          key: 'isCreator',
          value: '',
          options: listService.getMemberTypeList(),
          optionLabel: 'text',
          type: SMART_FORM_FIELD_TYPE.SELECT
        },
        {
          label: 'registerDateRange',
          key: 'dateRange',
          value: null,
          type: SMART_FORM_FIELD_TYPE.DATE_RANGE
        },
        {
          label: 'contactNumber',
          key: 'contactNumber',
          value: '',
          isOptional: true,
          rules: (value) => {
            return validationHelper.rulesMobileNumber(value);
          }
        },
        {
          label: 'email',
          key: 'email',
          value: ''
        },
        {
          label: 'registerPlatform',
          key: 'registerPlatform',
          value: '',
          options: listService.getDynamicList(CONSTANTS_DATA.registerPlatform),
          optionLabel: 'text',
          type: SMART_FORM_FIELD_TYPE.SELECT
        },
        {
          label: 'platformUsing',
          key: 'platformUsing',
          value: '',
          options: listService.getDynamicList(CONSTANTS_DATA.platformUsing),
          optionLabel: 'text',
          type: SMART_FORM_FIELD_TYPE.SELECT
        }
      ],
      actions: {
        viewList: {
          module: 'user',
          state: 'users',
          action: USER_GET_USERS,
          initialStateAction: USER_INITIAL_GET_USERS_STATE,
          title: i18nHelper.getMessage('label.getUsers')
        }
      },
      headers: [
        {
          key: 'no',
          name: 'no',
          active: true,
          sortable: true
        },
        {
          key: 'id',
          name: 'id',
          active: true,
          sortable: true
        },
        {
          key: 'contactNumber',
          name: 'contactNumber',
          active: true,
          sortable: true
        },
        {
          key: 'username',
          name: 'username',
          active: true,
          sortable: true,
          class: 'min-w-130px'
        },
        {
          key: 'storeName',
          name: 'storeName',
          active: true,
          sortable: true
        },
        {
          key: 'registerPlatform',
          name: 'registerPlatform',
          active: true,
          sortable: true
        },
        {
          key: 'platformUsing',
          name: 'platformUsing',
          active: true,
          sortable: true
        },
        {
          key: 'isActive',
          name: 'status',
          active: true,
          sortable: true,
          layout: APP_COMPONENT_NAME.APP_STATUS_LABEL
        },
        {
          key: 'isCreator',
          name: 'memberType',
          active: true,
          sortable: true,
          layout: APP_COMPONENT_NAME.APP_MEMBER_TYPE_LABEL
        },
        {
          key: 'createdAt',
          name: 'registerDateTime',
          active: true,
          sortable: true
        },
        {
          key: 'actions',
          name: 'actions',
          active: true,
          action: true,
          layout: APP_COMPONENT_NAME.APP_TABLE_ACTIONS,
          layoutActions: {
            viewDetailsRouteName: ROUTE_NAME.PROFILE_DETAILS_DETAILS
          }
        }
      ],
      exportExcel: {
        disabled: false,
        title: 'users',
        fileName: 'cookx_user_list',
        api: 'admin/users/export'
      }
    })
  };
</script>

<style></style>
