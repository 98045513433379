<template>
  <b-modal
    v-model="show"
    :title="title"
    centered
    hide-header-close
    no-close-on-esc
    no-close-on-backdrop
  >
    <div class="min-h-100px" v-for="(i, index) in formField" :key="index">
      <AppFormField
        v-model="$v.equipment[i.model].$model"
        :formType="i.formType"
        :options="i.options"
        :state="validateObjState('equipment', i.model)"
        :validationValue="$v.equipment[i.model]"
        :title="$t(`label.${i.title}`)"
        :validationParams="i.validationParams"
        :validators="i.validators"
        :config="i.config"
        prepend
      >
      </AppFormField>
      <div style="display: none">
        <!-- WARNING: I have no idea why this line is here, but removing it breaks everything -->
        {{ $v }}
      </div>
    </div>
    <template #modal-footer>
      <b-button @click="cancel">{{ $t('label.cancel') }}</b-button>
      <b-button variant="success" @click="submit">{{
        $t('label.submit')
      }}</b-button>
    </template>
  </b-modal>
</template>

<script>
  import AppFormField from '@/views/components/bases/form-field/AppFormField';
  import commonMixin from '@/core/mixins/common.mixin';
  import { validationMixin } from 'vuelidate';
  import { required } from 'vuelidate/lib/validators';
  import { listApiService } from '@/core/services';
  import { uiHelper } from '@/core/utils';
  import {
    LOADING_OPEN_LOADING,
    LOADING_CLOSE_LOADING
  } from '@/core/store/loading.module';

  export default {
    name: 'RecipeDetailsUpdateModal',
    components: {
      AppFormField
    },
    mixins: [validationMixin, commonMixin],
    props: {
      show: {
        type: Boolean,
        default: false,
        required: true
      },
      title: {
        type: String,
        default: '',
        required: true
      },
      type: {
        type: Number,
        required: true
      },
      id: {
        type: Number,
        required: true
      },
      initData: {
        type: Object,
        default: () => ({}),
        required: true
      }
    },
    data: () => ({
      formField: [],
      equipment: {
        equipmentId: null,
        sellingPeriodFrom: null,
        sellingPeriodTo: null
      }
    }),
    watch: {
      show() {
        if (this.show) {
          this.initailFormField();
          this.injectInitData();
          uiHelper.freezeBodyOverflowY();
        } else {
          uiHelper.unFreezeBodyOverflowY();
        }
      }
    },
    methods: {
      cancel() {
        this.resetForm();
        this.$emit('cancel');
      },
      submit() {
        let data = { id: this.id, ...this.equipment };
        this.$v.equipment.$touch();
        if (!this.$v.equipment.$anyError) {
          this.resetForm();
          this.$v.equipment.$reset();
          this.$emit('submit', data);
        }
      },
      resetForm() {
        this.equipment = {
          equipmentId: null,
          sellingPeriodFrom: null,
          sellingPeriodTo: null
        };
        this.formField = [];
        this.$v.equipment.$reset();
      },
      async initailFormField() {
        this.$store.dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });

        this.formField = [
          {
            model: 'equipmentId',
            title: 'equipment',
            formType: 'select',
            options: await listApiService.getEquipmentId(),
            validators: ['required']
            // option:
          },
          {
            model: 'sellingPeriodFrom',
            title: 'sellingPeriodFrom',
            formType: 'dateTime',
            config: {
              isStartDateTime: true
            },
            min: new Date(),
            validators: ['required']
          },
          {
            model: 'sellingPeriodTo',
            title: 'sellingPeriodTo',
            formType: 'dateTime',
            config: {
              isStartDateTime: true
            },
            min: new Date(),
            validators: ['required']
          }
        ];
        this.$store.dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      },
      injectInitData() {
        this.equipment = {
          equipmentId: this.initData.equipmentId,
          sellingPeriodFrom: this.initData.sellingPeriodFrom,
          sellingPeriodTo: this.initData.sellingPeriodTo
        };
        this.initailFormField();
      }
    },
    validations: {
      equipment: {
        equipmentId: {
          required
        },
        sellingPeriodFrom: {
          required
        },
        sellingPeriodTo: {
          required
        }
      }
    }
  };
</script>

<style></style>
