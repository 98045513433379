import { PLATFORM_OPERATION_HOUR } from '@/core/api';
import { apiHelper } from '@/core/utils';
import {
  LOADING_OPEN_LOADING,
  LOADING_CLOSE_LOADING
} from '@/core/store/loading.module';
import dateTimeFormat from '@/core/filters/dateTimeFormat.filter';

/* Define Module Name */
const MODULE_NAME = 'platformOperationHour/';

/* Define Action Name */
const A_GET_PLATFORM_OPERATION_HOURS = 'getPlatformOperationHours';
const A_GET_PLATFORM_OPERATION_HOUR = 'getPlatformOperationHour';
const A_CREATE_PLATFORM_OPERATION_HOUR = 'createPlatformOperationHour';
const A_UPDATE_PLATFORM_OPERATION_HOUR = 'updatePlatformOperationHour';
const A_DELETE_PLATFORM_OPERATION_HOUR = 'deletePlatformOperationHour';

/* Define Reset State Action Name */
const A_INITIAL_GET_PLATFORM_OPERATION_HOURS_STATE =
  'initialGetPlatformOperationHoursState';
const A_INITIAL_GET_PLATFORM_OPERATION_HOUR_STATE =
  'initialGetPlatformOperationHourState';
const A_INITIAL_CREATE_PLATFORM_OPERATION_HOUR_STATE =
  'initialCreatePlatformOperationHourState';
const A_INITIAL_UPDATE_PLATFORM_OPERATION_HOUR_STATE =
  'initialUpdatePlatformOperationHourState';
const A_INITIAL_DELETE_PLATFORM_OPERATION_HOUR_STATE =
  'initialDeletePlatformOperationHourState';

/* Define Getter Name */

/* Define Mutation Name */
const M_GET_PLATFORM_OPERATION_HOURS = 'setGetPlatformOperationHours';
const M_GET_PLATFORM_OPERATION_HOUR = 'setGetPlatformOperationHour';
const M_CREATE_PLATFORM_OPERATION_HOUR = 'setCreatePlatformOperationHour';
const M_UPDATE_PLATFORM_OPERATION_HOUR = 'setUpdatePlatformOperationHour';
const M_DELETE_PLATFORM_OPERATION_HOUR = 'setDeletePlatformOperationHour';

/* Define Reset State Mutation Name */
const M_INITIAL_GET_PLATFORM_OPERATION_HOURS_STATE =
  'setInitialPlatformOperationHoursState';
const M_INITIAL_GET_PLATFORM_OPERATION_HOUR_STATE =
  'setInitialPlatformOperationHourState';
const M_INITIAL_CREATE_PLATFORM_OPERATION_HOUR_STATE =
  'setInitialCreatePlatformOperationHourState';
const M_INITIAL_UPDATE_PLATFORM_OPERATION_HOUR_STATE =
  'setInitialUpdatePlatformOperationHourState';
const M_INITIAL_DELETE_PLATFORM_OPERATION_HOUR_STATE =
  'setInitialDeletePlatformOperationHourState';

/* Define Export Name */
export const PLATFORM_OPERATION_HOUR_GET_PLATFORM_OPERATION_HOURS =
  MODULE_NAME + A_GET_PLATFORM_OPERATION_HOURS;
export const PLATFORM_OPERATION_HOUR_GET_PLATFORM_OPERATION_HOUR =
  MODULE_NAME + A_GET_PLATFORM_OPERATION_HOUR;
export const PLATFORM_OPERATION_HOUR_CREATE_PLATFORM_OPERATION_HOUR =
  MODULE_NAME + A_CREATE_PLATFORM_OPERATION_HOUR;
export const PLATFORM_OPERATION_HOUR_UPDATE_PLATFORM_OPERATION_HOUR =
  MODULE_NAME + A_UPDATE_PLATFORM_OPERATION_HOUR;
export const PLATFORM_OPERATION_HOUR_DELETE_PLATFORM_OPERATION_HOUR =
  MODULE_NAME + A_DELETE_PLATFORM_OPERATION_HOUR;

/* Define Reset State Name */
export const PLATFORM_OPERATION_HOUR_INITIAL_GET_PLATFORM_OPERATION_HOURS_STATE =
  MODULE_NAME + A_INITIAL_GET_PLATFORM_OPERATION_HOURS_STATE;
export const PLATFORM_OPERATION_HOUR_INITIAL_GET_PLATFORM_OPERATION_HOUR_STATE =
  MODULE_NAME + A_INITIAL_GET_PLATFORM_OPERATION_HOUR_STATE;
export const PLATFORM_OPERATION_HOUR_INITIAL_CREATE_PLATFORM_OPERATION_HOUR_STATE =
  MODULE_NAME + A_INITIAL_CREATE_PLATFORM_OPERATION_HOUR_STATE;
export const PLATFORM_OPERATION_HOUR_INITIAL_UPDATE_PLATFORM_OPERATION_HOUR_STATE =
  MODULE_NAME + A_INITIAL_UPDATE_PLATFORM_OPERATION_HOUR_STATE;
export const PLATFORM_OPERATION_HOUR_INITIAL_DELETE_PLATFORM_OPERATION_HOUR_STATE =
  MODULE_NAME + A_INITIAL_DELETE_PLATFORM_OPERATION_HOUR_STATE;

const state = {
  platformOperationHours: {
    code: 0,
    complete: false,
    data: null,
    message: null,
    pagination: {
      currentPage: 0,
      total: 0,
      perPage: 0,
      lastPage: 0
    }
  },
  platformOperationHour: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  createPlatformOperationHour: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  updatePlatformOperationHour: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  deletePlatformOperationHour: {
    code: 0,
    complete: false,
    data: null,
    message: null
  }
};

//Getters
const getters = {};

// Actions
const actions = {
  [A_GET_PLATFORM_OPERATION_HOURS]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    PLATFORM_OPERATION_HOUR.getPlatformOperationHours(data)
      .then((response) => {
        let result = response;
        commit(M_GET_PLATFORM_OPERATION_HOURS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_GET_PLATFORM_OPERATION_HOURS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_PLATFORM_OPERATION_HOUR]({ dispatch, commit }, { id }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    PLATFORM_OPERATION_HOUR.getPlatformOperationHour(id)
      .then((response) => {
        let result = response;
        commit(M_GET_PLATFORM_OPERATION_HOUR, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_GET_PLATFORM_OPERATION_HOUR, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_CREATE_PLATFORM_OPERATION_HOUR]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    PLATFORM_OPERATION_HOUR.createPlatformOperationHour(data)
      .then((response) => {
        let result = response;
        commit(M_CREATE_PLATFORM_OPERATION_HOUR, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_CREATE_PLATFORM_OPERATION_HOUR, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_UPDATE_PLATFORM_OPERATION_HOUR]({ dispatch, commit }, { id, data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    PLATFORM_OPERATION_HOUR.updatePlatformOperationHour(id, data)
      .then((response) => {
        let result = response;
        commit(M_UPDATE_PLATFORM_OPERATION_HOUR, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_UPDATE_PLATFORM_OPERATION_HOUR, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_DELETE_PLATFORM_OPERATION_HOUR]({ dispatch, commit }, { id }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    PLATFORM_OPERATION_HOUR.deletePlatformOperationHour(id)
      .then((response) => {
        let result = response;
        commit(M_DELETE_PLATFORM_OPERATION_HOUR, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_DELETE_PLATFORM_OPERATION_HOUR, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_INITIAL_GET_PLATFORM_OPERATION_HOURS_STATE]({ commit }) {
    commit(M_INITIAL_GET_PLATFORM_OPERATION_HOURS_STATE);
  },
  [A_INITIAL_GET_PLATFORM_OPERATION_HOUR_STATE]({ commit }) {
    commit(M_INITIAL_GET_PLATFORM_OPERATION_HOUR_STATE);
  },
  [A_INITIAL_CREATE_PLATFORM_OPERATION_HOUR_STATE]({ commit }) {
    commit(M_INITIAL_CREATE_PLATFORM_OPERATION_HOUR_STATE);
  },
  [A_INITIAL_UPDATE_PLATFORM_OPERATION_HOUR_STATE]({ commit }) {
    commit(M_INITIAL_UPDATE_PLATFORM_OPERATION_HOUR_STATE);
  },
  [A_INITIAL_DELETE_PLATFORM_OPERATION_HOUR_STATE]({ commit }) {
    commit(M_INITIAL_DELETE_PLATFORM_OPERATION_HOUR_STATE);
  }
};

// Mutations
const mutations = {
  [M_GET_PLATFORM_OPERATION_HOURS](state, result) {
    if (result.code === 0) {
      let d = result.data;
      let p = result.pagination;

      state.platformOperationHours = {
        code: result.code,
        complete: true,
        data: d.map((x, index) => {
          let endDate = dateTimeFormat(x.end_date);
          let endTime = dateTimeFormat(x.end_time);

          return {
            no: index + 1,
            id: x.id,
            startDate: dateTimeFormat(x.start_date).substr(0, 11),
            endDate: endDate != '-' ? endDate.substr(0, 11) : endDate,
            startTime: dateTimeFormat(x.start_time).substr(11),
            endTime: endTime.substr(11),
            createdAt: dateTimeFormat(x.created_at),
            updatedAt: dateTimeFormat(x.updated_at),
            createdBy: x.created_by
          };
        }),
        message: null,
        pagination: {
          currentPage: p.current_page,
          total: p.total,
          perPage: p.per_page,
          lastPage: p.last_page
        }
      };
    } else {
      state.platformOperationHours = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_GET_PLATFORM_OPERATION_HOUR](state, result) {
    if (result.code === 0) {
      let d = result.data;
      let endDate = dateTimeFormat(d.end_date);
      let endTime = dateTimeFormat(d.end_time);

      state.platformOperationHour = {
        code: result.code,
        complete: true,
        data: {
          id: d.id,
          startDate: dateTimeFormat(d.start_date).substr(0, 11),
          endDate: endDate != '-' ? endDate.substr(0, 11) : null,
          startTime: dateTimeFormat(d.start_time).substr(11),
          endTime: endTime.substr(11),
          createdAt: dateTimeFormat(d.created_at),
          updatedAt: dateTimeFormat(d.updated_at),
          createdBy: d.created_by
        },
        message: null
      };
    } else {
      state.platformOperationHour = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_CREATE_PLATFORM_OPERATION_HOUR](state, result) {
    state.createPlatformOperationHour = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_UPDATE_PLATFORM_OPERATION_HOUR](state, result) {
    state.updatePlatformOperationHour = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_DELETE_PLATFORM_OPERATION_HOUR](state, result) {
    state.deletePlatformOperationHour = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_INITIAL_GET_PLATFORM_OPERATION_HOURS_STATE](state) {
    state.platformOperationHours = {
      code: 0,
      complete: false,
      data: null,
      message: null,
      pagination: {
        currentPage: 0,
        total: 0,
        perPage: 0,
        lastPage: 0
      }
    };
  },
  [M_INITIAL_GET_PLATFORM_OPERATION_HOUR_STATE](state) {
    state.platformOperationHour = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_CREATE_PLATFORM_OPERATION_HOUR_STATE](state) {
    state.createPlatformOperationHour = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_UPDATE_PLATFORM_OPERATION_HOUR_STATE](state) {
    state.updatePlatformOperationHour = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_DELETE_PLATFORM_OPERATION_HOUR_STATE](state) {
    state.deletePlatformOperationHour = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
