<template>
  <div>
    <!--          
       Magic. I am not sure why this works, but it fixes the bug 
    -->
    <!--begin::App Form Field-->
    <div :class="[{ 'pb-7': !noTitle }]">
      <label class="font-size-sm text-capitalize" v-if="!noTitle"
        >{{ title }} <span v-if="isRequires" class="text-danger">*</span>
        <span v-if="remark">{{ remark }}</span></label
      >
      <b-form-group :class="horizontal ? 'form-group' : 'no-form-group'">
        <div class="input-group">
          <div v-if="prepend" class="input-group-prepend">
            <slot name="prepend"> </slot>
          </div>

          <!--begin::Input Field-->
          <b-form-input
            v-if="formType == 'input'"
            :value="value"
            :state="state"
            :type="type"
            :size="size"
            class="form-control"
            :class="{
              'form-control-solid': solid,
              'text-uppercase': textTransform,
              'custom-number': type == 'number'
            }"
            :disabled="disabled"
            :min="min"
            :max="max"
            :placeholder="placeholder"
            @input="input"
            @change="change"
            @keyup="keyup"
          ></b-form-input>
          <!--end::Input Field-->

          <!--begin::Range Field-->
          <b-form-input
            v-if="formType == 'range'"
            :value="value"
            :size="size"
            type="range"
            class="form-control"
            :class="{
              'form-control-solid': solid,
              'text-uppercase': textTransform,
              'custom-number': type == 'number'
            }"
            :disabled="disabled"
            :min="min"
            :max="max"
            :placeholder="placeholder"
            @input="input"
            @change="change"
            @keyup="keyup"
          ></b-form-input>
          <b-form-input
            v-if="formType == 'range'"
            :value="value"
            :state="state"
            :type="type"
            :size="size"
            class="form-control col-3"
            :class="{
              'form-control-solid': solid,
              'text-uppercase': textTransform,
              'custom-number': type == 'number'
            }"
            :disabled="disabled"
            :min="min"
            :max="max"
            :placeholder="placeholder"
            @input="input"
            @change="change"
            @keyup="keyup"
          ></b-form-input>
          <!--end::Range Field-->

          <!--begin:: Text Editor Field-->
          <!-- this plugin form primevue/editor -->
          <Editor
            v-if="formType == 'textEditor'"
            :value="value"
            class="w-100"
            style="height: fit-content"
            @input="input"
            @change="change"
            @keyup="keyup"
          >
            <template v-slot:toolbar>
              <span class="ql-formats">
                <button v-tooltip.bottom="'Bold'" class="ql-bold"></button>
                <button v-tooltip.bottom="'Italic'" class="ql-italic"></button>
                <button
                  v-tooltip.bottom="'Underline'"
                  class="ql-underline"
                ></button>
              </span>
              <span class="ql-formats">
                <button
                  v-tooltip.bottom="'list'"
                  value="bullet"
                  class="ql-list"
                ></button>
                <button
                  v-tooltip.bottom="'list'"
                  value="ordered"
                  class="ql-list"
                ></button>
              </span>
            </template>
          </Editor>
          <!--end:: Text Editor Field-->

          <!--begin:: Single Checkbox Field-->
          <b-row
            v-if="formType == 'singleCheckbox'"
            class="align-items-center"
            no-gutters
          >
            <b-form-checkbox
              :checked="value"
              switch
              :size="size"
              :state="state"
              :disabled="disabled"
              @input="input"
              @change="change"
            >
            </b-form-checkbox>
            <b-row no-gutters v-if="config.label">
              <span class="pr-3">:</span>
              <router-link
                :to="{ name: config.routerName, params: { id: config.id } }"
                class="text-hover-decoration-underline"
                size="sm"
              >
                {{ config.name }}
              </router-link>
            </b-row>
          </b-row>
          <!--end:: Single Checkbox Field-->

          <!--begin:: Checkbox Field-->
          <b-form-checkbox-group
            v-if="formType == 'checkbox'"
            :checked="value"
            :state="state"
            :size="size"
            :options="options"
            :disabled="disabled"
            disabled-field="notEnabled"
            @input="input"
            @change="change"
          ></b-form-checkbox-group>
          <!-- Option Value:  array { text: 'A', value: 'a' } -->
          <!--end:: Checkbox Field-->

          <!--begin:: Radios Field-->
          <b-form-radio-group
            v-if="formType == 'radios'"
            :checked="value"
            :state="state"
            :size="size"
            :options="options"
            :disabled="disabled"
            @input="input"
          ></b-form-radio-group>
          <!-- Option Value:  array { text: 'A', value: 'A' },-->
          <!--end:: Radios Field-->
          <!--begin::Select Field-->
          <b-form-select
            v-if="formType == 'select'"
            :value="value"
            :state="state"
            :size="size"
            :class="{
              'form-control-solid form-control': solid
            }"
            :options="options"
            :disabled="disabled"
            :text-field="textField"
            @input="input"
            @change="change"
          ></b-form-select>
          <!-- {{ options }} -->
          <!--end::Select Field-->

          <!--begin::Search Select Field-->
          <!-- {{ options }} -->
          <VueMultiselect
            v-if="formType == 'searchSelect'"
            label="text"
            :class="{ 'form-control-solid': solid }"
            :value="value"
            :options="options"
            :disabled="disabled"
            :placeholder="placeholder"
            :searchable="true"
            @change="change"
            @input="input"
            @search-change="searchChange"
          >
            <template v-if="config.caret" #caret>
              <i class="fas fa-search pr-3"></i>
            </template>
          </VueMultiselect>
          <!--end::Select Field-->

          <!--begin::Search Select All Field-->
          <!-- {{ options }} -->
          <!-- {{ value }} -->
          <VueMultiselect
            v-if="formType == 'searchSelectAll'"
            :options="[
              {
                label: 'ALL',
                options: options
              }
            ]"
            :value="value"
            style="height: auto"
            display="chip"
            mode="tags"
            label="text"
            class="form-control p-1"
            :class="{ 'form-control-solid': solid }"
            :track-by="config.trackBy"
            :loading="config.isLoading"
            :disabled="disabled"
            :placeholder="placeholder"
            :groups="true"
            :searchable="true"
            @change="change"
            @input="input"
            @search-change="searchChange"
          >
            <template v-if="config.caret" #caret>
              <i class="fas fa-search pr-3"></i>
            </template>
          </VueMultiselect>
          <!--end::Select Field-->
          <!--begin::File Field-->
          <b-form-file
            v-if="formType == 'file'"
            :id="id"
            :value="value"
            :state="
              config.isVideoFile
                ? videoFileState == false
                  ? videoFileState
                  : state
                : state
            "
            :accept="accept"
            :size="size"
            :disabled="disabled"
            @input="input"
            @change="fileFieldChange"
            class="app-form-field__file-field d-inline-flex"
          ></b-form-file>
          <b-button
            v-if="formType == 'file' && fileUploadButton"
            :variant="
              value
                ? state
                  ? 'success'
                  : 'light-danger'
                : state == null
                ? ''
                : 'light-danger'
            "
            :disabled="!value || !state"
            :class="{ 'cursor-default': !value || !state }"
            class="ml-4"
            @click="uploadFile"
          >
            <b-spinner v-if="isUploading" small></b-spinner>
            <i v-else class="flaticon-upload p-0"></i>
          </b-button>
          <!--end::File Field-->
          <!--begin::View & File Field-->
          <template v-if="formType == 'fileView'">
            <div v-if="documentName(value)" class="w-100 text-break">
              <span
                class="mb-0 font-size-sm form-control form-control-md form-control-solid overflow-hidden"
                style="white-space: nowrap; text-overflow: ellipsis"
              >
                {{ documentName(value) }}
              </span>
              <div class="d-flex">
                <label
                  class="text-primary cursor-pointer mb-0 mr-4 font-size-sm"
                  @click="$emit('replace-action', config.model)"
                >
                  {{ $t('label.replace') }}
                </label>
                <AppButtonDocumentView
                  :attachmentUrl="value"
                  :fileType="config.mediaType"
                ></AppButtonDocumentView>
              </div>
            </div>
            <div v-else class="w-100 text-break">
              <b-form-file
                :id="id"
                :value="value"
                :state="
                  config.isVideoFile
                    ? videoFileState == false
                      ? videoFileState
                      : state
                    : state
                "
                :accept="accept"
                :size="size"
                :disabled="disabled"
                @input="input"
                @change="fileFieldChange"
                class="app-form-field__file-field d-inline-flex"
              ></b-form-file>
              <AppMediaFileStandardMessage
                :mediaType="config.mediaType"
                :dimension="config.dimension"
                :uploadSize="config.uploadSize"
                :sizeType="config.sizeType"
              ></AppMediaFileStandardMessage>
            </div>
          </template>
          <!--end::View & File Field-->

          <!--begin::Text Area Field-->
          <b-form-textarea
            v-if="formType == 'textarea'"
            :value="value"
            :state="state"
            :size="size"
            class="form-control"
            :class="{ 'form-control-solid': solid }"
            :disabled="disabled"
            :rows="rows"
            :max-rows="maxRows"
            @input="input"
          ></b-form-textarea>
          <!--end::Text Area Field-->

          <!--begin::Active/Inactive Field-->
          <b-button
            v-if="formType == 'status'"
            :class="disabled ? 'cursor-default' : ''"
            :variant="`light-${value ? 'success' : 'danger'}`"
            :disabled="disabled"
            >{{ buttonText }}</b-button
          >
          <!--end::Active/Inactive Field-->

          <!--begin::Date Time Field-->
          <b-form-input
            v-if="formType == 'dateTime'"
            :value="value"
            :state="state"
            type="text"
            :size="size"
            class="form-control"
            :class="[
              { 'form-control-solid': solid },
              !disabled ? 'cursor-pointer' : 'cursor-default'
            ]"
            :disabled="disabled"
            readonly
            @click="showDateTimeModal = true"
          ></b-form-input>
          <!--end::Date Time Field-->

          <!--begin::Date Field-->
          <b-form-input
            v-if="formType == 'date'"
            :value="value"
            :state="state"
            type="text"
            :size="size"
            class="form-control"
            :class="[
              { 'form-control-solid': solid },
              !disabled ? 'cursor-pointer' : 'cursor-default'
            ]"
            :disabled="disabled"
            readonly
            @click="showDateModal = true"
          ></b-form-input>
          <!--end::Date Field-->

          <!--begin::Time Field-->
          <b-form-input
            v-if="formType == 'time'"
            :value="value"
            :state="state"
            type="text"
            :size="size"
            class="form-control"
            :class="[
              { 'form-control-solid': solid },
              !disabled ? 'cursor-pointer' : 'cursor-default'
            ]"
            :disabled="disabled"
            readonly
            @click="showTimeModal = true"
          ></b-form-input>
          <!--end::Time Field-->

          <!--begin::Hour Selection Field-->
          <b-form-input
            v-if="formType == 'hourSelection'"
            :value="value"
            :state="state"
            type="text"
            :size="size"
            class="form-control"
            :class="[
              { 'form-control-solid': solid },
              !disabled ? 'cursor-pointer' : 'cursor-default'
            ]"
            :disabled="disabled"
            readonly
            @click="showHourSelectionModal = true"
          ></b-form-input>
          <!--end::Hour Selection Field-->

          <!--begin::Invalid Feedback Messages-->
          <template v-for="(feedback, index) in invalidFeedBackList">
            <b-form-invalid-feedback
              :key="index"
              v-if="showInvalidFeedBack(feedback.key)"
              :state="state"
            >
              {{
                $t(
                  `fieldErrorMessage.${feedback.message}`,
                  feedback.hasParams ? getValidationParams(feedback.key) : []
                )
              }}
            </b-form-invalid-feedback>
          </template>
          <!--end::Invalid Feedback Messages-->

          <!--begin::Remark of input Messages-->
          <div class="font-size-xs w-100">
            {{ moreRemark }}
          </div>
          <!--end::Remark of input Messages-->

          <!--begin::Remark of input file Messages-->
          <div v-if="prepend">
            <slot name="fileMessages">
              <slot name="otherMessages"> </slot>
            </slot>
          </div>
          <!--end::Remark of input file Messages-->
        </div>
      </b-form-group>
    </div>
    <!--end::App Form Field-->

    <!--begin::Date Time Modal-->
    <b-modal
      v-model="showDateTimeModal"
      no-close-on-esc
      hide-header
      centered
      size="sm"
      body-class="p-0"
      footer-class="p-1"
      @hide="dateTime.time && dateTime.date && dateTimeModalOKAction"
    >
      <b-tabs content-class="mt-3" fill>
        <b-tab :title="$t('label.date')" active>
          <b-calendar
            v-model="dateTime.date"
            hide-header
            block
            :date-format-options="{
              day: 'numeric',
              month: 'short',
              year: 'numeric'
            }"
            :min="min"
            :max="max"
            label-help=""
            locale="en-US"
            class="m-2"
            @context="onDateContext"
          ></b-calendar>
        </b-tab>
        <b-tab :title="$t('label.time')">
          <div class="d-flex justify-content-center">
            <b-time
              v-model="dateTime.time"
              hide-header
              show-seconds
              locale="en-US"
              class="m-2"
              @context="onTimeContext"
            ></b-time>
          </div>
        </b-tab>
      </b-tabs>
      <template #modal-footer>
        <b-button variant="white" @click="dateTimeModalClearAction">
          {{ $t('label.clear') }}
        </b-button>
        <b-button
          :disabled="!dateTime.time || !dateTime.date"
          variant="primary"
          :class="{ 'cursor-default': !dateTime.time || !dateTime.date }"
          @click="dateTimeModalOKAction"
        >
          {{ $t('label.ok') }}
        </b-button>
      </template>
    </b-modal>
    <!--end::Date Time Modal-->

    <!--begin::Date Modal-->
    <b-modal
      v-model="showDateModal"
      no-close-on-esc
      hide-header
      centered
      size="sm"
      body-class="p-0"
      footer-class="p-1"
      @hide="dateModalOKAction"
    >
      <b-calendar
        v-model="date"
        hide-header
        block
        :date-format-options="{
          day: 'numeric',
          month: 'short',
          year: 'numeric'
        }"
        label-help=""
        locale="en-US"
        class="m-2"
        :min="config.min"
        :max="config.max"
        @context="onDateContext"
      ></b-calendar>

      <template #modal-footer>
        <b-button variant="white" @click="dateModalClearAction">
          {{ $t('label.clear') }}
        </b-button>
        <b-button
          :disabled="!date"
          variant="primary"
          :class="{ 'cursor-default': !date }"
          @click="dateModalOKAction"
        >
          {{ $t('label.ok') }}
        </b-button>
      </template>
    </b-modal>
    <!--end::Date Modal-->

    <!--begin::Time Modal-->
    <b-modal
      v-model="showTimeModal"
      no-close-on-esc
      hide-header
      centered
      size="sm"
      body-class="p-0"
      footer-class="p-1"
      @hide="timeModalOKAction"
      modal-class="d-flex"
    >
      <b-time
        v-model="time"
        hide-header
        show-seconds
        locale="en-US"
        class="m-2"
        @context="onTimeContext"
      ></b-time>
      <template #modal-footer>
        <b-button variant="white" @click="timeModalClearAction">
          {{ $t('label.clear') }}
        </b-button>
        <b-button
          :disabled="!time"
          variant="primary"
          :class="{ 'cursor-default': !date }"
          @click="timeModalOKAction"
        >
          {{ $t('label.ok') }}
        </b-button>
      </template>
    </b-modal>
    <!--end::Time Modal-->

    <!--begin::Hour Selection Modal-->
    <b-modal
      v-model="showHourSelectionModal"
      no-close-on-esc
      hide-header
      centered
      size="sm"
      body-class="p-0"
      footer-class="p-1"
      @hide="hourSelectionModalOKAction"
      modal-class="d-flex"
    >
      <div class="d-flex justify-content-center px-8 py-4">
        <b-form-spinbutton
          v-model="hour"
          :min="1"
          :max="12"
          wrap
          vertical
          @input="hourSpinbuttonInput"
        ></b-form-spinbutton>

        <b-form-spinbutton
          v-model="optionAmPm"
          :formatter-fn="amPmFormatter"
          min="0"
          max="1"
          wrap
          vertical
          title="AM/PM"
          class="ml-2"
          @input="hourSpinbuttonInput"
        ></b-form-spinbutton>
      </div>
      <template #modal-footer>
        <b-button variant="white" @click="hourSelectionModalClearAction">
          {{ $t('label.clear') }}
        </b-button>
        <b-button
          :disabled="!hour || optionAmPm == null"
          variant="primary"
          :class="{ 'cursor-default': !hour || optionAmPm == null }"
          @click="hourSelectionModalOKAction"
        >
          {{ $t('label.ok') }}
        </b-button>
      </template>
    </b-modal>
    <!--end::Hour Selection Modal-->

    <!--begin::Video file input preview placeholder-->
    <video
      v-if="formType == 'file'"
      id="video-tag"
      class="d-none"
      src=""
    ></video>
    <!--end::Video file input preview placeholder-->
  </div>
</template>

<script>
  import moment from 'moment';
  import AppButtonDocumentView from '@/views/components/bases/button/AppButtonDocumentView';
  import AppMediaFileStandardMessage from '@/views/components/bases/AppMediaFileStandardMessage';

  const INITIAL_DATE = moment(new Date()).format('YYYY-MM-DD');
  const INITIAL_TIME = '23:59:59';
  const DEFAULT_START_DATE_TIME = new Date(
    new Date(new Date().setHours(0)).setMinutes(0)
  ).setSeconds(0);
  const DEFAULT_END_DATE_TIME = new Date(
    new Date(new Date().setHours(23)).setMinutes(59)
  ).setSeconds(59);

  export default {
    name: 'AppFormField',
    components: { AppButtonDocumentView, AppMediaFileStandardMessage },
    props: {
      id: {},
      value: {},
      uncheckedValue: {},
      state: {
        type: Boolean,
        default: null
      },
      title: {
        type: String,
        default: ''
      },
      formType: {
        type: String,
        default: 'input'
      },
      options: {
        type: Array,
        default: () => []
      },
      disabled: {
        type: Boolean,
        default: false
      },
      buttonText: {
        type: String,
        default: ''
      },
      accept: {
        type: String,
        default: ''
      },
      validationValue: {
        type: Object,
        default: () => {}
      },
      validators: {
        type: Array,
        default: () => []
      },
      solid: {
        type: Boolean,
        default: false
      },
      optional: {
        type: Boolean,
        default: false
      },
      textField: {
        type: String,
        default: 'text'
      },
      size: {
        type: String,
        default: 'md'
      },
      validationParams: {
        type: Array,
        default: () => []
      },
      noTitle: {
        type: Boolean,
        default: false
      },
      rows: {
        type: Number,
        default: 3
      },
      maxRows: {
        type: Number,
        default: 6
      },
      remark: {
        type: String,
        default: ''
      },
      moreRemark: {
        type: String,
        default: ''
      },
      fileUploadButton: {
        type: Boolean,
        default: false
      },
      isUploading: {
        type: Boolean,
        default: false
      },
      config: {
        type: Object,
        default: () => ({})
      },
      min: {
        default: null
      },
      max: {
        default: null
      },
      type: {
        type: String,
        default: 'text'
      },
      prepend: {
        type: Boolean,
        default: false
      },
      placeholder: {
        type: String,
        default: ''
      },
      textTransform: {
        type: Boolean,
        default: false
      },
      horizontal: {
        type: Boolean,
        default: false
      },
      isRequires: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      selecttest: 'first',
      optionstest: [
        { text: 'Toggle this custom radio', value: 'first' },
        { text: 'Or toggle this other custom radio', value: 'second' },
        { text: 'This one is Disabled', value: 'third', disabled: true },
        { text: 'This is the 4th radio', value: { fourth: 4 } }
      ],
      showDateTimeModal: false,
      showDateModal: false,
      showTimeModal: false,
      showHourSelectionModal: false,
      date: '',
      time: '',
      dateTime: {
        date: '',
        time: ''
      },
      dateContext: '',
      timeContext: '',
      invalidFeedBackList: [
        {
          key: 'required',
          message: 'requiredFieldDefault'
        },
        {
          key: 'minLength',
          message: 'requiredMinLength',
          hasParams: true
        },
        {
          key: 'maxLength',
          message: 'requiredMaxLength',
          hasParams: true
        },
        {
          key: 'minMaxLength',
          message: 'requiredMinMaxLength',
          hasParams: true
        },
        {
          key: 'pinLength',
          message: 'requiredPinLength',
          hasParams: true
        },
        {
          key: 'numeric',
          message: 'invalidNumericFormat'
        },
        {
          key: 'url',
          message: 'invalidUrlFormat'
        },
        {
          key: 'bannerImageType',
          message: 'invalidBannerImageFormat',
          hasParams: true
        },
        {
          key: 'bannerVideoType',
          message: 'invalidBannerVideoFormat',
          hasParams: true
        },
        {
          key: 'fileSize',
          message: 'fileSizeExceeds',
          hasParams: true
        },
        {
          key: 'email',
          message: 'invalidEmailFormat'
        },
        {
          key: 'platformInfoApplicationType',
          message: 'invalidApplicationFormat',
          hasParams: true
        },
        {
          key: 'startDateTime',
          message: 'invalidStartDateTime'
        },
        {
          key: 'endDateTime',
          message: 'invalidEndDateTime'
        },
        {
          key: 'urlOptionalProtocol',
          message: 'invalidUrlOptionalProtocolFormat'
        },
        {
          key: 'startDate',
          message: 'invalidStartDate'
        },
        {
          key: 'endDate',
          message: 'invalidEndDate'
        },
        {
          key: 'startTime',
          message: 'invalidStartTime'
        },
        {
          key: 'endTime',
          message: 'invalidEndTime'
        },
        {
          key: 'bannerImageDimension',
          message: 'invalidImageDimensionsSquare',
          hasParams: true
        },
        {
          key: 'bannerVideoDimension',
          message: 'invalidVideoDimensionsSquare',
          hasParams: true
        },
        {
          key: 'maxValue',
          message: 'exceedMaxValue',
          hasParams: true
        },
        {
          key: 'minValue',
          message: 'lessThanMinValue',
          hasParams: true
        },
        {
          key: 'price',
          message: 'invalidPriceFormat'
        },
        {
          key: 'mobileNumber',
          message: 'invalidMobileNumberFormat'
        },
        {
          key: 'between',
          message: 'invalidNumberBounds',
          hasParams: true
        }
      ],
      hourOptions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      hour: 1,
      amPms: ['AM', 'PM'],
      optionAmPm: 1,
      videoFileState: null
    }),
    watch: {
      showDateTimeModal() {
        let value = this.value;
        let config = this.config;

        if (!value || value == '-') {
          if (config.isStartDateTime) {
            value = DEFAULT_START_DATE_TIME;
          } else if (config.isEndDateTime) {
            value = DEFAULT_END_DATE_TIME;
          }
        }
        if (this.showDateTimeModal) {
          this.updateDateTime(value);
        }
      },
      showDateModal() {
        let value = this.value;

        if (value && this.showDateModal) {
          this.updateDate(value);
        }
      },
      showTimeModal() {
        let value = this.value;

        if (value && this.showTimeModal) {
          this.updateTime(value);
        }
      },
      showHourSelectionModal() {
        let value = this.value;

        if (this.showHourSelectionModal) {
          if (value) {
            this.updateHourSelectionValue(value);
          } else {
            this.setDefaultHourSelectionValue();
          }
        }
      }
    },
    mounted() {
      this.reviseInvalidFeedBackList();
    },
    methods: {
      change(e) {
        this.$emit('change', e);
      },
      keyup(e) {
        this.$emit('keyup', e);
      },
      searchChange(e) {
        this.$emit('searchChange', e);
      },
      documentName(data) {
        return typeof data === 'string' ? data.split('/').reverse()[0] : '';
      },
      reviseInvalidFeedBackList() {
        this.validationParams.forEach((x) => {
          if (x.key == 'bannerImageDimension') {
            if (x.params[0] != x.params[1]) {
              this.invalidFeedBackList.find((y) => x.key == y.key).message =
                'invalidImageDimensions';
            }
          } else if (x.key == 'bannerVideoDimension') {
            if (x.params[0] != x.params[1]) {
              this.invalidFeedBackList.find((y) => x.key == y.key).message =
                'invalidVideoDimensions';
            }
          } else if (x.customizeMessage) {
            this.invalidFeedBackList.find((y) => x.key == y.key).message =
              x.customizeMessage;
          }
        });
      },
      fileFieldChange(event) {
        if (this.config.isVideoFile) {
          let videoTag = document.querySelector('#video-tag');
          let file = event.target.files[0];
          let params = this.validationParams.find(
            (x) => x.key == 'bannerVideoDimension'
          ).params;
          let standardHeight = params[0];
          let standardWidth = params[1];

          let videoDimensionValidation = async (file) => {
            try {
              await readUploadFileAsDataURL(file);

              if (standardHeight == standardWidth) {
                videoTag.onloadedmetadata = () => {
                  this.videoFileState =
                    videoTag.videoHeight >= standardHeight &&
                    videoTag.videoWidth >= standardWidth &&
                    videoTag.videoHeight == videoTag.videoWidth;
                };
              } else {
                videoTag.onloadedmetadata = () => {
                  this.videoFileState =
                    videoTag.videoHeight == standardHeight &&
                    videoTag.videoWidth == standardWidth;
                };
              }
            } catch (e) {
              console.warn(e.message);
            }
          };

          const readUploadFileAsDataURL = (file) => {
            if (file) {
              const reader = new FileReader();

              return new Promise((resolve, reject) => {
                reader.onerror = () => {
                  reader.abort();
                  reject(new DOMException('Problem parsing input file.'));
                };

                reader.onload = (e) => {
                  videoTag.src = e.target.result;
                  videoTag.load();
                  resolve(reader.result);
                };

                reader.readAsDataURL(file);
              });
            }
          };

          return videoDimensionValidation(file);
        } else {
          this.change(event);
        }
      },
      updateHourSelectionValue(value) {
        let date = new Date(
          new Date().toISOString().substr(0, 10) + ' ' + value
        );
        this.hour = Number(moment(date).format('hh'));
        this.optionAmPm = Number(
          this.amPms.findIndex(
            (x) => x == moment(date).format('a').toUpperCase()
          )
        );
      },
      setDefaultHourSelectionValue() {
        let config = this.config;

        if (config.isStartHour) {
          this.hour = 12;
          this.optionAmPm = 0;
        } else if (config.isEndHour) {
          this.hour = 11;
          this.optionAmPm = 1;
        }
        this.hourSpinbuttonInput();
      },
      amPmFormatter(value) {
        return this.amPms[value];
      },
      hourSpinbuttonInput() {
        let valueAmPm = this.amPms[this.optionAmPm];
        let value = `${this.hour}:00:00 ${valueAmPm}`;

        if (!this.hour && !this.optionAmPm) {
          value = null;
        }

        this.input(value);
      },
      hourSelectionModalOKAction() {
        this.showHourSelectionModal = false;
      },
      hourSelectionModalClearAction() {
        this.hour = null;
        this.optionAmPm = null;
        this.input(null);
        this.showHourSelectionModal = false;
      },
      uploadFile() {
        this.$emit('upload-file');
      },
      input(value) {
        this.$emit('input', value);
      },
      getValidationParams(key) {
        return this.validationParams.find((x) => x.key == key).params;
      },
      showInvalidFeedBack(name) {
        let show = false;
        if (name != 'bannerVideoDimension') {
          if (this.validators.includes(name)) {
            show = !this.validationValue[name];
          } else {
            show = false;
          }
        } else {
          let acceptArray = this.accept.split(',');

          // To cater show video dimension validation error message
          // due to normal input not able check video file dimension
          if (
            this.value &&
            this.videoFileState == false &&
            acceptArray.includes(this.value.type)
          ) {
            show = true;
          }
        }
        return show;
      },
      dateModalOKAction() {
        this.showDateModal = false;
      },
      dateModalClearAction() {
        this.date = '';
        this.input(this.date);
        this.showDateModal = false;
      },
      timeModalOKAction() {
        this.showTimeModal = false;
      },
      timeModalClearAction() {
        this.time = '';
        this.input(this.time);
        this.showTimeModal = false;
      },
      dateTimeModalOKAction() {
        let dt = this.dateTime;
        if (dt.date && !dt.time) {
          this.dateTime.time = INITIAL_TIME;
        } else if (!dt.date && dt.time) {
          this.dateTime.date = INITIAL_DATE;
        }

        this.showDateTimeModal = false;
      },
      dateTimeModalClearAction() {
        this.dateTime = {
          date: null,
          time: null
        };
        this.showDateTimeModal = false;
      },
      updateDate(value) {
        if (value != '-') {
          this.date = moment(new Date(value)).format('YYYY-MM-DD');
        } else {
          this.date = '';
        }
      },
      updateTime(value) {
        let date = new Date(
          new Date().toISOString().substr(0, 10) + ' ' + value
        );

        this.time = moment(date).format('HH:mm:ss');
      },
      updateDateTime(value) {
        let newDateTime = moment(new Date(value));

        this.dateTime = {
          date: newDateTime.format('YYYY-MM-DD'),
          time: newDateTime.format('HH:mm:ss')
        };
      },
      inputDate() {
        let date = this.dateContext;

        if (date) this.input(date);
      },
      inputTime() {
        let time = this.timeContext;

        if (time) this.input(time);
      },
      inputDateTime() {
        let dateTime = '';

        if (this.dateContext && this.timeContext) {
          dateTime = this.dateContext + ' ' + this.timeContext;
        }

        this.input(dateTime);
      },
      onTimeContext(ctx) {
        this.timeContext = ctx.value ? ctx.formatted : '';

        switch (this.formType) {
          case 'dateTime':
            this.inputDateTime();
            break;
          case 'time':
            this.inputTime();
            break;
        }
      },
      onDateContext(ctx) {
        this.dateContext = ctx.selectedYMD
          ? moment(ctx.selectedYMD).format('DD MMM YYYY')
          : '';

        switch (this.formType) {
          case 'dateTime':
            this.inputDateTime();
            break;
          case 'date':
            this.inputDate();
            break;
        }
      }
    }
  };
</script>

<style lang="scss">
  .form-group {
    .multiselect {
      background-color: #e4e6ef !important;
      &.is-active {
        box-shadow: unset !important;
      }
      .multiselect-search {
        background-color: #e4e6ef !important;
      }
      .multiselect-wrapper {
        max-height: 200px !important;
        display: flex !important;
        overflow: hidden auto;
        height: auto;
      }
      .multiselect-tags {
        max-height: 150px !important;
        overflow: hidden auto;
      }
      .multiselect-dropdown {
        background-color: #e4e6ef !important;
        .multiselect-option {
          background-color: #e4e6ef !important;
          &.is-selected {
            background-color: #3699ff !important;
            color: #fff;
          }
          &.is-pointed {
            background-color: #3699ff !important;
            color: #fff;
          }
        }
      }
    }
  }
</style>
