<template>
  <!-- begin:: Header Topbar -->
  <div class="topbar">
    <!--begin: Notifications -->
    <b-dropdown
      size="sm"
      variant="link"
      toggle-class="topbar-item text-decoration-none"
      no-caret
      right
      no-flip
    >
      <template v-slot:button-content>
        <div
          class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 pulse-primary"
          :class="[{ pulse: isAuthorizedAlerts.length > 0 }]"
        >
          <span class="svg-icon svg-icon-xl svg-icon-primary">
            <inline-svg src="/media/svg/icons/General/Notifications1.svg" />
          </span>
          <span class="pulse-ring"></span>
        </div>
      </template>
      <b-dropdown-text tag="div" class="min-w-md-350px">
        <form>
          <KTDropdownNotification
            :isAuthorizedAlerts="isAuthorizedAlerts"
          ></KTDropdownNotification>
        </form>
      </b-dropdown-text>
    </b-dropdown>
    <!--end: Notifications -->

    <!--begin: Language bar -->
    <div class="topbar-item">
      <b-dropdown
        size="sm"
        variant="link"
        toggle-class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 text-decoration-none"
        no-caret
        right
        no-flip
      >
        <template v-slot:button-content>
          <img
            class="h-20px w-20px rounded-sm"
            :src="languageFlag || getLanguageFlag"
            alt=""
          />
        </template>
        <b-dropdown-text tag="div" class="min-w-md-175px">
          <KTDropdownLanguage
            v-on:language-changed="onLanguageChanged"
          ></KTDropdownLanguage>
        </b-dropdown-text>
      </b-dropdown>
    </div>
    <!--end: Language bar -->

    <!--begin: User Bar -->
    <KTQuickUser></KTQuickUser>
    <!--end: User Bar -->
  </div>
  <!-- end:: Header Topbar -->
</template>

<style lang="scss">
  .topbar {
    .dropdown-toggle {
      padding: 0;
      &:hover {
        text-decoration: none;
      }

      &.dropdown-toggle-no-caret {
        &:after {
          content: none;
        }
      }
    }

    .dropdown-menu {
      margin: 0;
      padding: 0;
      outline: none;
      .b-dropdown-text {
        padding: 0;
      }
    }
  }
</style>

<script>
  import KTDropdownLanguage from '@/views/layout/extras/dropdown/DropdownLanguage.vue';
  import KTQuickUser from '@/views/layout/extras/offcanvas/QuickUser.vue';
  import KTDropdownNotification from '@/views/layout/extras/dropdown/DropdownNotification';
  import { i18nService } from '@/core/services';
  import { I18N_CONSTANTS, ROUTE_NAME, MODULE, ACTION } from '@/core/constants';
  import { DeliveryStatus } from '@/core/constants/enums';
  import {
    RecipeStatus,
    CreatorApplicationStatus2
  } from '@/core/constants/enums';
  import commonMixin from '@/core/mixins/common.mixin';

  export default {
    name: 'KTTopbar',
    mixins: [commonMixin],
    components: {
      KTDropdownLanguage,
      KTQuickUser,
      KTDropdownNotification
    },
    data: () => ({
      languageFlag: '',
      languages: I18N_CONSTANTS.AVAILABLE_LANGUAGES,
      alerts: [
        {
          title: 'application',
          desc: 'pendingApproval',
          color: 'primary',
          key: 'application',
          route: ROUTE_NAME.APPLICATION,
          query: { status: CreatorApplicationStatus2.PENDING_APPROVED },
          permission: {
            module: MODULE.CREATOR_APPLICATION,
            action: ACTION.CREATOR_APPLICATION_READ
          }
        },
        {
          title: 'experienceCooking',
          desc: 'pendingVerification',
          color: 'warning',
          key: 'recipes',
          route: ROUTE_NAME.EXPERIENCE_COOKING,
          query: { status: RecipeStatus.PENDING },
          permission: {
            module: MODULE.RECIPE,
            action: ACTION.RECIPE_READ
          }
        },
        {
          title: 'order',
          desc: 'ordersThatRequireManualDelivery',
          color: 'success',
          key: 'ordersCancelled',
          route: ROUTE_NAME.ORDERS,
          query: { deliveryStatus: DeliveryStatus.CANCELLED },
          permission: {
            module: MODULE.ORDERS,
            action: ACTION.ORDERS_READ
          }
        },
        {
          title: 'ecoPackFulfil',
          desc: 'pendingVerification',
          color: 'success',
          key: 'ecoPackFulfil',
          route: ROUTE_NAME.ECOPACK,
          query: { ecoPackStatus: 0 },
          permission: {
            module: MODULE.ORDERS,
            action: ACTION.ORDERS_READ
          }
        }
      ]
    }),
    computed: {
      getLanguageFlag() {
        return this.onLanguageChanged();
      },
      notificationData() {
        return this.$store.state.lookup.notification.data;
      },
      isAuthorizedAlerts() {
        let newAlerts = [];

        this.alerts.forEach((x) => {
          if (this.notificationData[x.key]) {
            if (
              this.checkAccessRight(x.permission.module, [x.permission.action])
            ) {
              newAlerts.push(x);
            }
          }
        });
        return newAlerts;
      }
    },
    methods: {
      onLanguageChanged() {
        this.languageFlag = this.languages.find((val) => {
          return val.lang === i18nService.getActiveLanguage();
        }).flag;
      }
    }
  };
</script>
