<template>
  <b-modal
    v-model="modal"
    :title="isNewRecord ? $t('label.newSchedule') : $t('label.scheduleDetails')"
    centered
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
    scrollable
    header-class="text-capitalize"
    :body-class="isNewRecord ? '' : 'max-h-325px'"
  >
    <b-form-group class="mb-0">
      <template v-for="(i, index) in formFields">
        <div :key="index" class="px-2">
          <AppFormField
            v-if="i.field == 'form'"
            v-model="$v.maintenanceScheduleObj[i.model].$model"
            :state="validateObjState('maintenanceScheduleObj', i.model)"
            :validationValue="$v.maintenanceScheduleObj[i.model]"
            :title="$t(`label.${i.title}`)"
            :solid="true"
            :validators="i.validators"
            :formType="i.formType"
            :disabled="
              isNewRecord
                ? !checkAccessRight(moduleName, [
                    action.MAINTENANCE_SCHEDULE_CREATE
                  ])
                : !checkAccessRight(moduleName, [
                    action.MAINTENANCE_SCHEDULE_UPDATE
                  ])
            "
            :config="i.config"
            :min="i.min"
            :max="i.max"
          ></AppFormField>
          <div v-else-if="!isNewRecord" class="mb-3">
            <div class="font-weight-bolder text-capitalize mb-2">
              {{ $t(`label.${i.title}`) }}:
            </div>
            <div class="text-gray-600">
              {{ maintenanceScheduleObj[i.model] }}
            </div>
          </div>
          <div style="display: none">
            <!-- WARNING: I have no idea why this line is here, but removing it breaks everything -->
            {{ $v }}
          </div>
        </div>
        <div
          v-if="!isNewRecord && i.model == 'endDateTime'"
          :key="index + 'separator'"
          class="separator separator-solid my-6"
        ></div>
      </template>
    </b-form-group>
    <template #modal-footer>
      <b-button
        v-if="
          !isNewRecord &&
          !checkAccessRight(moduleName, [action.MAINTENANCE_SCHEDULE_UPDATE])
        "
        variant="primary"
        @click="actionDiscard"
        >{{ $t('label.close') }}</b-button
      >
      <b-button v-else class="mr-2" variant="white" @click="actionDiscard">{{
        $t('label.discard')
      }}</b-button>
      <b-button
        v-if="
          isNewRecord &&
          checkAccessRight(moduleName, [action.MAINTENANCE_SCHEDULE_CREATE])
        "
        variant="success"
        @click="actionSubmit"
        >{{ $t('label.submit') }}</b-button
      >
      <b-button
        v-if="
          !isNewRecord &&
          checkAccessRight(moduleName, [action.MAINTENANCE_SCHEDULE_UPDATE])
        "
        variant="primary"
        @click="actionSaveChanges"
        >{{ $t('label.saveChanges') }}</b-button
      >
    </template>
  </b-modal>
</template>

<script>
  import AppFormField from '@/views/components/bases/form-field/AppFormField';
  import commonMixin from '@/core/mixins/common.mixin';
  import { MODULE, ACTION, startDateTime, endDateTime } from '@/core/constants';
  import { validationMixin } from 'vuelidate';
  import { required } from 'vuelidate/lib/validators';
  import { i18nHelper, uiHelper } from '@/core/utils';
  import {
    MAINTENANCE_SCHEDULE_GET_MAINTENANCE_SCHEDULE,
    MAINTENANCE_SCHEDULE_CREATE_MAINTENANCE_SCHEDULE,
    MAINTENANCE_SCHEDULE_UPDATE_MAINTENANCE_SCHEDULE,
    MAINTENANCE_SCHEDULE_INITIAL_GET_MAINTENANCE_SCHEDULE_STATE,
    MAINTENANCE_SCHEDULE_INITIAL_CREATE_MAINTENANCE_SCHEDULE_STATE,
    MAINTENANCE_SCHEDULE_INITIAL_UPDATE_MAINTENANCE_SCHEDULE_STATE
  } from '@/core/store/maintenance-schedule.module';

  export default {
    name: 'MaintenanceModal',
    mixins: [commonMixin, validationMixin],
    components: {
      AppFormField
    },
    props: {
      value: {
        type: Boolean,
        default: false,
        required: true
      },
      id: {
        type: Number,
        default: 0,
        required: true
      },
      isNewRecord: {
        type: Boolean,
        default: false,
        required: true
      }
    },
    data: () => ({
      moduleName: MODULE.MAINTENANCE_SCHEDULE,
      action: ACTION,
      modal: false,
      maintenanceScheduleObj: {
        title: '',
        startDateTime: '',
        endDateTime: '',
        createdBy: '',
        createdAt: '',
        updatedAt: ''
      },
      formFields: []
    }),
    computed: {
      maintenanceScheduleComplete() {
        return this.$store.state.maintenanceSchedule.maintenanceSchedule
          .complete;
      },
      createMaintenanceScheduleComplete() {
        return this.$store.state.maintenanceSchedule.createMaintenanceSchedule
          .complete;
      },
      updateMaintenanceScheduleComplete() {
        return this.$store.state.maintenanceSchedule.updateMaintenanceSchedule
          .complete;
      }
    },
    watch: {
      value() {
        if (this.value) {
          if (this.isNewRecord) {
            this.modal = true;
          } else {
            this.getMaintenanceSchedule(this.id);
          }
        }
      },
      modal() {
        this.modal
          ? uiHelper.freezeBodyOverflowY()
          : uiHelper.unFreezeBodyOverflowY();
      },
      maintenanceScheduleComplete() {
        let response =
          this.$store.state.maintenanceSchedule.maintenanceSchedule;
        let title = i18nHelper.getMessage('label.getMaintenanceSchedule');
        let initialStateAction =
          MAINTENANCE_SCHEDULE_INITIAL_GET_MAINTENANCE_SCHEDULE_STATE;
        let successAction = (response) => {
          this.getMaintenanceScheduleCompleteActions(response);
        };

        if (response.complete) {
          this.actionReadRecordComplete(
            response,
            title,
            initialStateAction,
            successAction
          );
        }
      },
      createMaintenanceScheduleComplete() {
        let response =
          this.$store.state.maintenanceSchedule.createMaintenanceSchedule;
        let title = i18nHelper.getMessage('label.newMaintenanceSchedule');
        let initialStateAction =
          MAINTENANCE_SCHEDULE_INITIAL_CREATE_MAINTENANCE_SCHEDULE_STATE;
        let successAction = () => {
          this.actionCloseModal();
          this.initialList();
        };
        let errorAction = () => {};

        if (response.complete) {
          this.actionCreateRecordComplete(
            response,
            title,
            initialStateAction,
            successAction,
            errorAction
          );
        }
      },
      updateMaintenanceScheduleComplete() {
        let response =
          this.$store.state.maintenanceSchedule.updateMaintenanceSchedule;
        let title = i18nHelper.getMessage('label.updateMaintenanceSchedule');
        let initialStateAction =
          MAINTENANCE_SCHEDULE_INITIAL_UPDATE_MAINTENANCE_SCHEDULE_STATE;
        let successAction = () => {
          this.actionCloseModal();
          this.initialList();
        };
        let errorAction = () => {};

        if (response.complete) {
          this.actionUpdateRecordComplete(
            response,
            title,
            initialStateAction,
            successAction,
            errorAction
          );
        }
      }
    },
    mounted() {
      this.initFormFields();
    },
    methods: {
      initialList() {
        this.$parent.$refs.appSmartList.initAppSmartList();
      },
      getMaintenanceScheduleCompleteActions(response) {
        this.maintenanceScheduleObj = response.data;
        this.modal = true;
      },
      actionDiscard() {
        this.actionCloseModal();
      },
      actionSaveChanges() {
        if (this.validationCheck('maintenanceScheduleObj')) {
          this.updateMaintenanceSchedule(this.id, this.maintenanceScheduleObj);
        }
      },
      actionSubmit() {
        if (this.validationCheck('maintenanceScheduleObj')) {
          this.createMaintenanceSchedule(this.maintenanceScheduleObj);
        }
      },
      resetForm() {
        this.maintenanceScheduleObj = {
          title: '',
          startDateTime: '',
          endDateTime: '',
          createdBy: '',
          createdAt: '',
          updatedAt: ''
        };
        this.$v.$reset();
      },
      actionCloseModal() {
        this.resetForm();
        this.input(false);
        this.modal = false;
      },
      input(value) {
        this.$emit('input', value);
      },
      getMaintenanceSchedule(id) {
        this.$store.dispatch(MAINTENANCE_SCHEDULE_GET_MAINTENANCE_SCHEDULE, {
          id
        });
      },
      createMaintenanceSchedule(data) {
        this.$store.dispatch(MAINTENANCE_SCHEDULE_CREATE_MAINTENANCE_SCHEDULE, {
          data
        });
      },
      updateMaintenanceSchedule(id, data) {
        this.$store.dispatch(MAINTENANCE_SCHEDULE_UPDATE_MAINTENANCE_SCHEDULE, {
          id,
          data
        });
      },
      initFormFields() {
        this.formFields.push(
          {
            field: 'form',
            model: 'title',
            title: 'title',
            validators: ['required']
          },
          {
            field: 'form',
            model: 'startDateTime',
            title: 'startDateTime',
            validators: ['required', 'startDateTime'],
            formType: 'dateTime',
            config: {
              isStartDateTime: true
            },
            min: new Date()
          },
          {
            field: 'form',
            model: 'endDateTime',
            title: 'endDateTime',
            validators: ['required', 'endDateTime'],
            formType: 'dateTime',
            config: {
              isEndDateTime: true
            },
            min: new Date()
          },
          {
            model: 'createdBy',
            title: 'createdBy'
          },
          {
            model: 'createdAt',
            title: 'createdAt'
          },
          {
            model: 'updatedAt',
            title: 'updatedAt'
          }
        );
      }
    },
    validations() {
      return {
        maintenanceScheduleObj: {
          title: {
            required
          },
          startDateTime: {
            required,
            startDateTime: startDateTime(
              this.maintenanceScheduleObj.endDateTime
            )
          },
          endDateTime: {
            required,
            endDateTime: endDateTime(this.maintenanceScheduleObj.startDateTime)
          }
        }
      };
    }
  };
</script>

<style lang="scss"></style>
