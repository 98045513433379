<template>
  <div class="text-warning">
    <!-- mediaType: 1, //1 = image, 2 = video -->
    <small class="d-block">
      **
      {{
        $t(
          `message.${
            dimension.height == dimension.width
              ? 'mediaDimensionSquare'
              : 'mediaDimension'
          }`,
          ['', dimension.height, dimension.width, dimension.ratio]
        )
      }}
    </small>
    <small v-if="uploadSize" class="d-block">
      **{{
        $t(`message.mediaSizeShouldBeLessThan`, [
          MediaTypeName,
          uploadSize,
          sizeType
        ])
      }}
    </small>
    <small class="d-block">
      **{{ $t('message.mediaFileType', ['', fileTypes]) }}
    </small>
    <slot name="otherMessages"> </slot>
  </div>
</template>

<script>
  import { GLOBAL } from '@/core/constants';
  import { MediaType } from '@/core/constants/enums';
  import { uiHelper } from '@/core/utils';

  export default {
    name: 'AppMediaFileStandardMessage',
    props: {
      MediaTypeName: {
        type: StaticRange,
        default: null
      },
      mediaType: {
        type: Number,
        default: MediaType.IMAGE
      },
      dimension: {
        type: Object,
        default: () => {
          return {
            height: 0,
            width: 0
          };
        }
      },
      uploadSize: {
        type: Number
      },
      sizeType: {
        type: String,
        default: 'Mb'
      }
    },
    data: () => ({
      global: GLOBAL
    }),
    computed: {
      fileTypes() {
        let types = {
          [MediaType.IMAGE]: GLOBAL.AVAILABLE_IMAGE_TYPE,
          [MediaType.VIDEO]: GLOBAL.AVAILABLE_VIDEO_TYPE,
          [MediaType.VIDEOIMAGE]: GLOBAL.AVAILABLE_VIDEO_IMAGE_TYPE
        };

        return uiHelper.arrayToTrimmedStringWithSpace(types[this.mediaType], 6);
      }
    }
  };
</script>

<style></style>
