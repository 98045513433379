const PLATFORM_FILE_TYPE = {
  MEMBER_TERMS: 1,
  FAQS: 2,
  PRIVACY_POLICY: 3,
  CREATOR_TERMS: 4,
  MEMBER_COOKIE_TERMS: 5,
  CREATOR_COOKIE_TERMS: 6,
  TERMS_CONDITION: 7
};

export { PLATFORM_FILE_TYPE };
