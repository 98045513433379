import dateTimeFormat from '@/core/filters/dateTimeFormat.filter';
// import dateFormat from '@/core/filters/dateFormat.filter';
import { EQUIPMENT } from '@/core/api';
import { apiHelper } from '@/core/utils';
import {
  LOADING_OPEN_LOADING,
  LOADING_CLOSE_LOADING
} from '@/core/store/loading.module';
import currencyFormat from '@/core/filters/currency-format.filter';

/* Define Module Name */
const MODULE_NAME = 'equipment/';

/* Define Action Name */
const A_GET_EQUIPMENTS = 'getEquipments';
const A_GET_EQUIPMENT = 'getEquipment';
const A_GET_EQUIPMENT_RECIPES = 'getEquipmentRecipes';
const A_GET_EQUIPMENT_DELIVERY_FEES = 'getEquipmentDeliveryFees';
const A_CREATE_EQUIPMENT = 'createEquipment';
const A_UPDATE_EQUIPMENT = 'updateEquipment';
const A_UPDATE_EQUIPMENT_STATUS = 'updateEquipmentStatus';
const A_UPDATE_EQUIPMENT_DELIVERY_FEES = 'updateEquipmentDeliveryFees';
const A_UPLOAD_EQUIPMENT_DOCUMENTS = 'uploadEquipmentDocuments';
const A_DELETE_EQUIPMENT_DOCUMENTS = 'deleteEquipmentDocuments';
const A_DELETE_EQUIPMENT = 'deleteEquipment';

/* Define Reset State Action Name */
const A_INITIAL_GET_EQUIPMENTS_STATE = 'initialGetEquipmentsState';
const A_INITIAL_GET_EQUIPMENT_STATE = 'initialGetEquipmentState';
const A_INITIAL_GET_EQUIPMENT_RECIPES_STATE = 'initialGetEquipmentRecipesState';
const A_INITIAL_GET_EQUIPMENT_DELIVERY_FEES_STATE =
  'initialGetEquipmentDeliveryFeesState';
const A_INITIAL_CREATE_EQUIPMENT_STATE = 'initialCreateEquipmentState';
const A_INITIAL_UPDATE_EQUIPMENT_STATE = 'initialUpdateEquipmentState';
const A_INITIAL_UPDATE_EQUIPMENT_STATUS_STATE =
  'initialUpdateEquipmentStatusState';
const A_INITIAL_UPDATE_EQUIPMENT_DELIVERY_FEES_STATE =
  'initialUpdateEquipmentDeliveryFeesState';
const A_INITIAL_UPLOAD_EQUIPMENT_DOCUMENTS_STATE =
  'initialUploadEquipmentDocumentsState';
const A_INITIAL_DELETE_EQUIPMENT_DOCUMENTS_STATE =
  'initialDeleteEquipmentDocumentsState';
const A_INITIAL_DELETE_EQUIPMENT_STATE = 'initialDeleteEquipmentState';

/* Define Getter Name */

/* Define Mutation Name */
const M_GET_EQUIPMENTS = 'setGetEquipments';
const M_GET_EQUIPMENT = 'setGetEquipment';
const M_GET_EQUIPMENT_RECIPES = 'setGetEquipmentRecipes';
const M_GET_EQUIPMENT_DELIVERY_FEES = 'setGetEquipmentDeliveryFees';
const M_CREATE_EQUIPMENT = 'setCreateEquipment';
const M_UPDATE_EQUIPMENT = 'setUpdateEquipment';
const M_UPDATE_EQUIPMENT_STATUS = 'setUpdateEquipmentStatus';
const M_UPDATE_EQUIPMENT_DELIVERY_FEES = 'setUpdateEquipmentDeliveryFees';
const M_UPLOAD_EQUIPMENT_DOCUMENTS = 'setUploadEquipmentDocuments';
const M_DELETE_EQUIPMENT_DOCUMENTS = 'setDeleteEquipmentDocuments';
const M_DELETE_EQUIPMENT = 'setDeleteEquipment';

/* Define Reset State Mutation Name */
const M_INITIAL_GET_EQUIPMENTS_STATE = 'setInitialGetEquipmentsState';
const M_INITIAL_GET_EQUIPMENT_STATE = 'setInitialGetEquipmentState';
const M_INITIAL_GET_EQUIPMENT_RECIPES_STATE =
  'setInitialGetEquipmentRecipesState';
const M_INITIAL_GET_EQUIPMENT_DELIVERY_FEES_STATE =
  'setInitialGetEquipmentDeliveryFeesState';
const M_INITIAL_CREATE_EQUIPMENT_STATE = 'setInitialCreateEquipmentState';
const M_INITIAL_UPDATE_EQUIPMENT_STATE = 'setInitialUpdateEquipmentState';
const M_INITIAL_UPDATE_EQUIPMENT_STATUS_STATE =
  'setInitialUpdateEquipmentStatusState';
const M_INITIAL_UPDATE_EQUIPMENT_DELIVERY_FEES_STATE =
  'setInitialUpdateEquipmentDeliveryFeesState';
const M_INITIAL_UPLOAD_EQUIPMENT_DOCUMENTS_STATE =
  'setInitialUploadEquipmentDocumentsState';
const M_INITIAL_DELETE_EQUIPMENT_DOCUMENTS_STATE =
  'setInitialDeleteEquipmentDocumentsState';
const M_INITIAL_DELETE_EQUIPMENT_STATE = 'setInitialDeleteEquipmentState';

/* Define Export Name */
export const EQUIPMENT_GET_EQUIPMENTS = MODULE_NAME + A_GET_EQUIPMENTS;
export const EQUIPMENT_GET_EQUIPMENT = MODULE_NAME + A_GET_EQUIPMENT;
export const EQUIPMENT_GET_EQUIPMENT_RECIPES =
  MODULE_NAME + A_GET_EQUIPMENT_RECIPES;
export const EQUIPMENT_GET_EQUIPMENT_DELIVERY_FEES =
  MODULE_NAME + A_GET_EQUIPMENT_DELIVERY_FEES;
export const EQUIPMENT_CREATE_EQUIPMENT = MODULE_NAME + A_CREATE_EQUIPMENT;
export const EQUIPMENT_UPDATE_EQUIPMENT = MODULE_NAME + A_UPDATE_EQUIPMENT;
export const EQUIPMENT_UPDATE_EQUIPMENT_STATUS =
  MODULE_NAME + A_UPDATE_EQUIPMENT_STATUS;
export const EQUIPMENT_UPDATE_EQUIPMENT_DELIVERY_FEES =
  MODULE_NAME + A_UPDATE_EQUIPMENT_DELIVERY_FEES;
export const EQUIPMENT_UPLOAD_EQUIPMENT_DOCUMENTS =
  MODULE_NAME + A_UPLOAD_EQUIPMENT_DOCUMENTS;
export const EQUIPMENT_DELETE_EQUIPMENT_DOCUMENTS =
  MODULE_NAME + A_DELETE_EQUIPMENT_DOCUMENTS;
export const EQUIPMENT_DELETE_EQUIPMENT = MODULE_NAME + A_DELETE_EQUIPMENT;

/* Define Reset State Name */
export const EQUIPMENT_INITIAL_GET_EQUIPMENTS_STATE =
  MODULE_NAME + A_INITIAL_GET_EQUIPMENTS_STATE;
export const EQUIPMENT_INITIAL_GET_EQUIPMENT_STATE =
  MODULE_NAME + A_INITIAL_GET_EQUIPMENT_STATE;
export const EQUIPMENT_INITIAL_GET_EQUIPMENT_RECIPES_STATE =
  MODULE_NAME + A_INITIAL_GET_EQUIPMENT_RECIPES_STATE;
export const EQUIPMENT_INITIAL_GET_EQUIPMENT_DELIVERY_FEES_STATE =
  MODULE_NAME + A_INITIAL_GET_EQUIPMENT_DELIVERY_FEES_STATE;
export const EQUIPMENT_INITIAL_CREATE_EQUIPMENT_STATE =
  MODULE_NAME + A_INITIAL_CREATE_EQUIPMENT_STATE;
export const EQUIPMENT_INITIAL_UPDATE_EQUIPMENT_STATE =
  MODULE_NAME + A_INITIAL_UPDATE_EQUIPMENT_STATE;
export const EQUIPMENT_INITIAL_UPDATE_EQUIPMENT_STATUS_STATE =
  MODULE_NAME + A_INITIAL_UPDATE_EQUIPMENT_STATUS_STATE;
export const EQUIPMENT_INITIAL_UPDATE_EQUIPMENT_DELIVERY_FEES_STATE =
  MODULE_NAME + A_INITIAL_UPDATE_EQUIPMENT_DELIVERY_FEES_STATE;
export const EQUIPMENT_INITIAL_UPLOAD_EQUIPMENT_DOCUMENTS_STATE =
  MODULE_NAME + A_INITIAL_UPLOAD_EQUIPMENT_DOCUMENTS_STATE;
export const EQUIPMENT_INITIAL_DELETE_EQUIPMENT_DOCUMENTS_STATE =
  MODULE_NAME + A_INITIAL_DELETE_EQUIPMENT_DOCUMENTS_STATE;
export const EQUIPMENT_INITIAL_DELETE_EQUIPMENT_STATE =
  MODULE_NAME + A_INITIAL_DELETE_EQUIPMENT_STATE;

const state = {
  equipments: {
    code: 0,
    complete: false,
    data: null,
    message: null,
    pagination: {
      currentPage: 0,
      total: 0,
      perPage: 0,
      lastPage: 0
    }
  },
  equipment: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  equipmentRecipes: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  equipmentDeliveryFees: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  createEquipment: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  updateEquipment: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  updateEquipmentStatus: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  updateEquipmentDeliveryFees: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  uploadEquipmentDocuments: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  deleteEquipmentDocuments: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  deleteEquipment: {
    code: 0,
    complete: false,
    data: null,
    message: null
  }
};

//Getters
const getters = {};

// Actions
const actions = {
  [A_GET_EQUIPMENTS]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    EQUIPMENT.getEquipments(data)
      .then((response) => {
        result = response;
        commit(M_GET_EQUIPMENTS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_EQUIPMENTS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_EQUIPMENT]({ dispatch, commit }, { id }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    EQUIPMENT.getEquipment(id)
      .then((response) => {
        result = response;
        commit(M_GET_EQUIPMENT, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_EQUIPMENT, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_EQUIPMENT_RECIPES]({ dispatch, commit }, { id, data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    EQUIPMENT.getEquipmentRecipes(id, data)
      .then((response) => {
        result = response;
        commit(M_GET_EQUIPMENT_RECIPES, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_EQUIPMENT_RECIPES, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_EQUIPMENT_DELIVERY_FEES]({ dispatch, commit }, { id, data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    EQUIPMENT.getEquipmentDeliveryFees(id, data)
      .then((response) => {
        result = response;
        commit(M_GET_EQUIPMENT_DELIVERY_FEES, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_EQUIPMENT_DELIVERY_FEES, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_CREATE_EQUIPMENT]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    EQUIPMENT.createEquipment(data)
      .then((response) => {
        result = response;
        commit(M_CREATE_EQUIPMENT, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_CREATE_EQUIPMENT, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_UPDATE_EQUIPMENT]({ dispatch, commit }, { id, data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    EQUIPMENT.updateEquipment(id, data)
      .then((response) => {
        result = response;
        commit(M_UPDATE_EQUIPMENT, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_UPDATE_EQUIPMENT, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_UPDATE_EQUIPMENT_STATUS]({ dispatch, commit }, { id, data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    EQUIPMENT.updateEquipmentStatus(id, data)
      .then((response) => {
        result = response;
        commit(M_UPDATE_EQUIPMENT_STATUS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_UPDATE_EQUIPMENT_STATUS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_UPDATE_EQUIPMENT_DELIVERY_FEES]({ dispatch, commit }, { id, data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    EQUIPMENT.updateEquipmentDeliveryFees(id, data)
      .then((response) => {
        result = response;
        commit(M_UPDATE_EQUIPMENT_DELIVERY_FEES, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_UPDATE_EQUIPMENT_DELIVERY_FEES, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_UPLOAD_EQUIPMENT_DOCUMENTS]({ dispatch, commit }, { id, formData }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    EQUIPMENT.uploadEquipmentDocuments(formData)
      .then((response) => {
        result = { id: id, ...response };
        commit(M_UPLOAD_EQUIPMENT_DOCUMENTS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_UPLOAD_EQUIPMENT_DOCUMENTS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_DELETE_EQUIPMENT_DOCUMENTS]({ dispatch, commit }, { id, fileId }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    EQUIPMENT.deleteEquipmentDocuments(id, fileId)
      .then((response) => {
        result = response;
        commit(M_DELETE_EQUIPMENT_DOCUMENTS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_DELETE_EQUIPMENT_DOCUMENTS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_DELETE_EQUIPMENT]({ dispatch, commit }, { id }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    EQUIPMENT.deleteEquipment(id)
      .then((response) => {
        result = response;
        commit(M_DELETE_EQUIPMENT, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_DELETE_EQUIPMENT, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_INITIAL_GET_EQUIPMENTS_STATE]({ commit }) {
    commit(M_INITIAL_GET_EQUIPMENTS_STATE);
  },
  [A_INITIAL_GET_EQUIPMENT_STATE]({ commit }) {
    commit(M_INITIAL_GET_EQUIPMENT_STATE);
  },
  [A_INITIAL_GET_EQUIPMENT_RECIPES_STATE]({ commit }) {
    commit(M_INITIAL_GET_EQUIPMENT_RECIPES_STATE);
  },
  [A_INITIAL_GET_EQUIPMENT_DELIVERY_FEES_STATE]({ commit }) {
    commit(M_INITIAL_GET_EQUIPMENT_DELIVERY_FEES_STATE);
  },
  [A_INITIAL_CREATE_EQUIPMENT_STATE]({ commit }) {
    commit(M_INITIAL_CREATE_EQUIPMENT_STATE);
  },
  [A_INITIAL_UPDATE_EQUIPMENT_STATE]({ commit }) {
    commit(M_INITIAL_UPDATE_EQUIPMENT_STATE);
  },
  [A_INITIAL_UPDATE_EQUIPMENT_STATUS_STATE]({ commit }) {
    commit(M_INITIAL_UPDATE_EQUIPMENT_STATUS_STATE);
  },
  [A_INITIAL_UPDATE_EQUIPMENT_DELIVERY_FEES_STATE]({ commit }) {
    commit(M_INITIAL_UPDATE_EQUIPMENT_DELIVERY_FEES_STATE);
  },
  [A_INITIAL_UPLOAD_EQUIPMENT_DOCUMENTS_STATE]({ commit }) {
    commit(M_INITIAL_UPLOAD_EQUIPMENT_DOCUMENTS_STATE);
  },
  [A_INITIAL_DELETE_EQUIPMENT_DOCUMENTS_STATE]({ commit }) {
    commit(M_INITIAL_DELETE_EQUIPMENT_DOCUMENTS_STATE);
  },
  [A_INITIAL_DELETE_EQUIPMENT_STATE]({ commit }) {
    commit(M_INITIAL_DELETE_EQUIPMENT_STATE);
  }
};

// Mutations
const mutations = {
  [M_GET_EQUIPMENTS](state, result) {
    if (result.code === 0) {
      let d = result.data;
      let p = result.pagination;
      state.equipments = {
        code: result.code,
        complete: true,
        data: d.map((x, index) => {
          return {
            no: index + 1,
            id: x.id,
            equipmentName: x.name,
            brandName: x.brand_name,
            sellingPrice: currencyFormat(x.price),
            promotionPrice: currencyFormat(x.offer_price),
            deliveryFees: currencyFormat(x.delivery_fee),
            status: x.is_active
          };
        }),
        message: null,
        pagination: {
          currentPage: p.current_page,
          total: p.total,
          perPage: p.per_page,
          lastPage: p.last_page
        }
      };
    } else {
      state.equipments = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message),
        pagination: {
          currentPage: 0,
          total: 0,
          perPage: 0,
          lastPage: 0
        }
      };
    }
  },
  [M_GET_EQUIPMENT](state, result) {
    if (result.code === 0) {
      let d = result.data;

      state.equipment = {
        code: result.code,
        complete: true,
        data: {
          id: d.id,
          equipmentName: d.name,
          brandName: d.brand_name,
          thumbnail: d.thumbnail_url,
          sellingPrice: d.price,
          discountValueType: {
            type: d.discount_type,
            value: d.discount
          },
          features: d.feature,
          specification: d.specification,
          warrantyInfo: d.warranty_info,
          productAssurance: d.product_assurance,
          termsAndConditionApply: d.term_and_condition,
          remark: d.remark,
          status: d.is_active,
          documents: d.media.map((x) => {
            return {
              id: x.id,
              mediaPath: x.media_path,
              mediaType: x.media_type,
              timer: x.timer
            };
          })
        },
        message: null
      };
    } else {
      state.equipment = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_GET_EQUIPMENT_DELIVERY_FEES](state, result) {
    if (result.code === 0) {
      let d = result.data;

      state.equipmentDeliveryFees = {
        code: result.code,
        complete: true,
        data: {
          id: d.id,
          equipment_id: d.equipment_id,
          equipment: {
            brandName: d.equipment.brand_name,
            name: d.equipment.name
          },
          deliveryFees: d.fees,
          discountValueType: { type: d.discount_type, value: d.discount_value },
          discountTypeDescription: d.discount_type_description,
          createdAt: d.created_at,
          updatedAt: d.updated_at
        },
        message: null
      };
    } else {
      state.equipmentDeliveryFees = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_GET_EQUIPMENT_RECIPES](state, result) {
    if (result.code === 0) {
      let d = result.data;
      let p = result.pagination;
      state.equipmentRecipes = {
        code: result.code,
        complete: true,
        data: d.map((x, index) => {
          return {
            no: index + 1,
            id: x.recipe_id,
            createdBy: { name: x.created_by, id: x.created_id },
            recipeName: { name: x.recipe_name, id: x.recipe_id },
            availableFrom: dateTimeFormat(x.available_from),
            availableTo: dateTimeFormat(x.available_to),
            equipmentSelling: x.eco_status,
            sellingStatus: x.status
          };
        }),
        message: null,
        pagination: {
          currentPage: p.current_page,
          total: p.total,
          perPage: p.per_page,
          lastPage: p.last_page
        }
      };
    } else {
      state.equipmentRecipes = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message),
        pagination: {
          currentPage: 0,
          total: 0,
          perPage: 0,
          lastPage: 0
        }
      };
    }
  },
  [M_CREATE_EQUIPMENT](state, result) {
    state.createEquipment = {
      code: result.code,
      complete: true,
      data: result.data,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_UPDATE_EQUIPMENT](state, result) {
    state.updateEquipment = {
      code: result.code,
      complete: true,
      data: result.data,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_UPDATE_EQUIPMENT_STATUS](state, result) {
    state.updateEquipmentStatus = {
      code: result.code,
      complete: true,
      data: result.data,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_UPDATE_EQUIPMENT_DELIVERY_FEES](state, result) {
    state.updateEquipmentDeliveryFees = {
      code: result.code,
      complete: true,
      data: result.data,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_UPLOAD_EQUIPMENT_DOCUMENTS](state, result) {
    var data = {
      id: result.id
    };
    state.uploadEquipmentDocuments = {
      code: result.code,
      complete: true,
      data: data,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_DELETE_EQUIPMENT_DOCUMENTS](state, result) {
    state.deleteEquipmentDocuments = {
      code: result.code,
      complete: true,
      data: result.data,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_DELETE_EQUIPMENT](state, result) {
    state.deleteEquipment = {
      code: result.code,
      complete: true,
      data: result.data,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_INITIAL_GET_EQUIPMENTS_STATE](state) {
    state.equipments = {
      code: 0,
      complete: false,
      data: null,
      message: null,
      pagination: {
        currentPage: 0,
        total: 0,
        perPage: 0,
        lastPage: 0
      }
    };
  },
  [M_INITIAL_GET_EQUIPMENT_STATE](state) {
    state.equipment = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_GET_EQUIPMENT_RECIPES_STATE](state) {
    state.equipmentRecipes = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_GET_EQUIPMENT_DELIVERY_FEES_STATE](state) {
    state.equipmentDeliveryFees = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_CREATE_EQUIPMENT_STATE](state) {
    state.createEquipment = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_UPDATE_EQUIPMENT_STATE](state) {
    state.updateEquipment = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_UPDATE_EQUIPMENT_STATUS_STATE](state) {
    state.updateEquipmentStatus = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_UPDATE_EQUIPMENT_DELIVERY_FEES_STATE](state) {
    state.updateEquipmentDeliveryFees = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_UPLOAD_EQUIPMENT_DOCUMENTS_STATE](state) {
    state.uploadEquipmentDocuments = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_DELETE_EQUIPMENT_DOCUMENTS_STATE](state) {
    state.deleteEquipmentDocuments = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_DELETE_EQUIPMENT_STATE](state) {
    state.deleteEquipment = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
