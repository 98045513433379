import { apiService } from '@/core/services';

export const BANNER = {
  getBanners,
  getBanner,
  createBanner,
  updateBanner,
  updateBannerActiveInactive,
  deleteBanner
};

const URL = {
  ADMIN: 'admin',
  CONTENT_BANNERS: 'content-banners',
  SET_ACTIVE: 'set-active'
};

function getBanners(data) {
  let requestOptions = {
    params: {
      type: data.type,
      title: data.title,
      media_type: data.mediaType,
      action_type: data.actionType,
      priority: data.priority,
      is_active: data.isActive,
      sort_priority: data.sortPriority,
      per_page: data.perPage,
      page: data.page
    }
  };
  return apiService().get(
    `/${URL.ADMIN}/${URL.CONTENT_BANNERS}`,
    requestOptions
  );
}

function getBanner(id) {
  return apiService().get(`/${URL.ADMIN}/${URL.CONTENT_BANNERS}/${id}`);
}

function createBanner(formData) {
  return apiService(null, true, true).post(
    `/${URL.ADMIN}/${URL.CONTENT_BANNERS}`,
    formData
  );
}

function updateBanner(formData, id) {
  return apiService(null, true, true).post(
    `/${URL.ADMIN}/${URL.CONTENT_BANNERS}/${id}`,
    formData
  );
}

function updateBannerActiveInactive(id, isActive) {
  let requestBody = {
    is_active: isActive
  };
  return apiService().put(
    `/${URL.ADMIN}/${URL.CONTENT_BANNERS}/${id}/${URL.SET_ACTIVE}`,
    requestBody
  );
}

function deleteBanner(id) {
  return apiService().delete(`/${URL.ADMIN}/${URL.CONTENT_BANNERS}/${id}`);
}
