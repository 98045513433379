<template>
  <div>
    <AppSmartList
      :breadcrumbData="breadcrumbData"
      :accessRights="accessRights"
      :filters="filters"
      :actions="actions"
      :tableHeaders="headers"
    ></AppSmartList>
  </div>
</template>

<script>
  import AppSmartList from '@/views/components/bases/smart/AppSmartList.vue';
  import { SMART_FORM_FIELD_TYPE } from '@/core/constants';
  import { listService } from '@/core/services';

  export default {
    name: 'ReviewAndRatingList',
    components: {
      AppSmartList
    },
    props: {
      breadcrumbData: {
        type: Object,
        default: () => {}
      },
      actions: {
        type: Object,
        default: () => {
          return {
            viewList: {
              module: '',
              state: '',
              action: '',
              initialStateAction: '',
              title: ''
            },
            viewDetails: {
              routeName: ''
            }
          };
        }
      },
      accessRights: {
        type: Object,
        default: () => {
          return {
            moduleName: '',
            actions: {
              create: '',
              read: '',
              update: '',
              delete: ''
            }
          };
        }
      },
      headers: {
        type: Array,
        default: () => []
      }
    },
    data: () => ({
      filters: [
        {
          label: 'itemName',
          key: 'itemName',
          value: ''
        },
        {
          label: 'rating',
          key: 'rating',
          value: null
        },
        {
          label: 'createdBy',
          key: 'username',
          value: ''
        },
        {
          label: 'visibility',
          key: 'isVisible',
          value: null,
          options: listService.getYesNoList(false),
          optionLabel: 'text',
          type: SMART_FORM_FIELD_TYPE.SELECT
        }
      ]
    })
  };
</script>

<style></style>
