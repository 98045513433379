import { apiService } from '@/core/services';
import { apiHelper } from '@/core/utils';

export const ROLE = {
  getRoles,
  getRole,
  createRole,
  updateRole,
  deleteRole
};

const URL = {
  ADMIN: 'admin',
  ROLES: 'roles'
};

function getRoles(data) {
  let requestOptions = {
    params: {
      name: data.name,
      created_at_from: apiHelper.dateTimeStart(data.dateRange),
      created_at_to: apiHelper.dateTimeEnd(data.dateRange),
      per_page: data.perPage,
      page: data.page
    }
  };
  return apiService().get(`/${URL.ADMIN}/${URL.ROLES}`, requestOptions);
}

function getRole(id) {
  return apiService().get(`/${URL.ADMIN}/${URL.ROLES}/${id}`);
}

function createRole(data) {
  let requestBody = {
    name: data.name,
    permissions: data.permissions
  };
  return apiService().post(`/${URL.ADMIN}/${URL.ROLES}`, requestBody);
}

function updateRole(id, data) {
  let requestBody = {
    name: data.name,
    permissions: data.permissions
  };
  return apiService().put(`/${URL.ADMIN}/${URL.ROLES}/${id}`, requestBody);
}

function deleteRole(id) {
  return apiService().delete(`/${URL.ADMIN}/${URL.ROLES}/${id}`);
}
