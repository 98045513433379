<template>
  <div>
    <div
      v-if="notificationData[name]"
      class="d-flex justify-content-center align-items-center"
    >
      <div
        class="text-white bg-danger text-center rounded-circle w-20px h-20px p-0"
      >
        {{ notificationData[name] }}
      </div>
    </div>

    <div v-else-if="notificationData[type]">
      <div
        v-if="notificationData.orders[name]"
        class="text-white bg-danger text-center rounded-circle w-20px h-20px p-0"
      >
        {{ notificationData.orders[name] }}
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'NotificationBadge',
    props: {
      name: {
        type: String,
        default: ''
      },
      type: {
        type: String,
        default: ''
      }
    },
    computed: {
      notificationData() {
        return this.$store.state.lookup.notification.data;
      }
    }
  };
</script>

<style></style>
