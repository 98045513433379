import { apiService } from '@/core/services';

export const PLATFORM_OPERATION_HOUR = {
  getPlatformOperationHours,
  getPlatformOperationHour,
  createPlatformOperationHour,
  updatePlatformOperationHour,
  deletePlatformOperationHour
};

const URL = {
  ADMIN: 'admin',
  PLATFORM_OPERATION_HOURS: 'platform-operation-hours'
};

function getPlatformOperationHours(data) {
  let requestOptions = {
    params: {
      per_page: data.perPage,
      page: data.page
    }
  };
  return apiService().get(
    `/${URL.ADMIN}/${URL.PLATFORM_OPERATION_HOURS}`,
    requestOptions
  );
}

function getPlatformOperationHour(id) {
  return apiService().get(
    `/${URL.ADMIN}/${URL.PLATFORM_OPERATION_HOURS}/${id}`
  );
}

function createPlatformOperationHour(data) {
  let requestBody = {
    start_date: data.startDate,
    end_date: data.endDate,
    start_time: data.startTime,
    end_time: data.endTime
  };

  return apiService().post(
    `/${URL.ADMIN}/${URL.PLATFORM_OPERATION_HOURS}`,
    requestBody
  );
}

function updatePlatformOperationHour(id, data) {
  let requestBody = {
    start_date: data.startDate,
    end_date: data.endDate,
    start_time: data.startTime,
    end_time: data.endTime
  };

  return apiService().put(
    `/${URL.ADMIN}/${URL.PLATFORM_OPERATION_HOURS}/${id}`,
    requestBody
  );
}

function deletePlatformOperationHour(id) {
  return apiService().delete(
    `/${URL.ADMIN}/${URL.PLATFORM_OPERATION_HOURS}/${id}`
  );
}
