<template>
  <div>
    <!-- begin: Button Type 1 -->
    <label
      v-if="buttonType == 1"
      class="text-primary cursor-pointer mb-0 font-size-sm"
      @click="viewDocument"
    >
      {{ $t('label.view') }}
    </label>
    <!-- end: Button Type 1 -->
    <!-- begin: Button Type 2 -->
    <b-button
      v-else-if="buttonType == 2"
      variant="primary shadow-sm font-size-sm"
      @click="viewDocument"
    >
      {{ $t('label.preview') }}
    </b-button>
    <!-- end: Button Type 2 -->

    <!-- begin::Video Viewer -->
    <transition name="fade">
      <div
        v-if="videoOverlay"
        style=""
        class="app-media-viewer__video-viewer"
        @click="closeVideoOverlay"
      >
        <div
          class="app-media-viewer__video-viewer-button"
          @click="closeVideoOverlay"
        >
          <i class="flaticon2-cancel-music icon-nm text-gray"></i>
        </div>
        <div
          class="app-media-viewer__video-viewer-content d-flex justify-content-center align-items-center"
        >
          <video controls autoplay>
            <source :src="selectedVideoPath" />
          </video>
        </div>
      </div>
    </transition>
    <!-- end::Video Viewer -->
  </div>
</template>

<script>
  import { uiHelper } from '@/core/utils';
  import { MediaType } from '@/core/constants/enums';

  export default {
    name: 'AppButtonDocumentView',
    props: {
      title: {
        type: String,
        default: ''
      },
      attachmentUrl: {
        type: String,
        default: ''
      },
      fileType: {},
      buttonType: {
        type: Number,
        default: 1
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      modal: false,
      imageTypes: ['jpg', 'png', 'jpeg'],
      videoOverlay: false,
      selectedVideoPath: null
    }),
    methods: {
      viewDocument() {
        let imagesURL = [this.attachmentUrl];
        if (
          this.imageTypes.includes(this.fileType) ||
          this.fileType == MediaType.IMAGE
        ) {
          /* Toolbar options refer to
            https://github.com/fengyuanchen/viewerjs */
          this.$viewerApi({
            options: {
              toolbar: {
                zoomIn: true,
                zoomOut: true,
                oneToOne: true,
                reset: true,
                prev: false,
                play: {
                  show: true,
                  size: 'large'
                },
                next: false,
                rotateLeft: true,
                rotateRight: true,
                flipHorizontal: true,
                flipVertical: true
              },
              navbar: false
            },
            images: imagesURL
          });
        } else if (
          this.fileType == 'pdf' ||
          this.fileType == MediaType.APPLICATION
        ) {
          uiHelper.openPage(
            this.attachmentUrl +
              '?random=' +
              new Date().getTime() +
              Math.floor(Math.random() * 1000000),
            '_blank',
            'fullscreen=1'
          );
        } else if (this.fileType == MediaType.VIDEO) {
          this.selectedVideoPath = this.attachmentUrl;
          this.videoOverlay = true;
        } else {
          uiHelper.openPage(this.attachmentUrl, '_parent');
        }
      },
      closeVideoOverlay() {
        this.videoOverlay = false;
        this.selectedVideoPath = null;
      }
    }
  };
</script>

<style lang="scss">
  .app-media-viewer {
    .single-media {
      object-fit: cover;
      max-height: 375px;
    }
    .multiple-media {
      object-fit: cover;
      height: 150px;
    }

    .app-media-viewer__media-container {
      position: relative;
    }

    .media {
      opacity: 1;
      display: block;
      transition: 0.3s ease;
      backface-visibility: hidden;
    }

    .middle {
      min-width: 100px;
      transition: 0.3s ease;
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
    }

    .app-media-viewer__media-container:hover .media {
      opacity: 0.2;
    }

    .app-media-viewer__media-container:hover .middle {
      opacity: 1;
    }

    .text {
      text-align: center;
      color: #000000;
      font-size: 14px;
      padding: 4px;
    }
  }
  .app-media-viewer__video-viewer {
    inset: 0px;
    z-index: 1000;
    background: rgb(0, 0, 0, 0.5);
    position: fixed;
    height: 100%;
    width: 100%;
    transition: all 0.3s;
    .app-media-viewer__video-viewer-button {
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 50%;
      cursor: pointer;
      height: 80px;
      overflow: hidden;
      position: absolute;
      right: -40px;
      top: -40px;
      -webkit-transition: background-color 0.15s;
      transition: background-color 0.15s;
      width: 80px;
      > i {
        bottom: 15px;
        left: 19px;
        position: absolute;
      }
    }
    .app-media-viewer__video-viewer-content {
      width: 100%;
      height: 100%;
      > video {
        max-width: 25%;
      }
    }
  }
</style>
