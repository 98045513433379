<template>
  <!--begin: Wizard Step 1-->
  <div>
    <h6 class="mb-10 font-weight-bold text-dark text-capitalize">
      {{ $t('label.enterRecipePageDetails') }}
    </h6>
    <b-form class="form" id="kt_form">
      <b-row no-gutters>
        <template v-for="(i, index) in formFields.filter((x) => x.field == 1)">
          <b-col
            :key="index"
            xl="6"
            class="p-xl-1"
            v-if="i.noCreate !== isCreate || !isCreate"
          >
            <div v-if="i.layout">
              <label
                v-if="i.title == 'thumbnail'"
                class="font-size-sm text-capitalize"
                >{{ $t(`label.${i.title}`) }}
              </label>
              <component
                :ref="i.ref"
                v-model="$v.recipeObj[i.model].$model"
                :title="$t('label.recipeName')"
                :is="i.layout"
                :attachmentUrl="recipeObj[i.model]"
                :state="validateObjState('recipeObj', i.model)"
                :disabled="i.disabled"
                :actionType="actionType.RECIPE_PAGE"
                :category="getRecipeCategoryType(category)"
                :noReplace="true"
                :isExisting="!isCreate"
                @valueObj="returnRecipeObj"
                :solid="!i.disabled"
              >
              </component>
            </div>

            <AppFormField
              v-else
              v-model="$v.recipeObj[i.model].$model"
              :state="validateObjState('recipeObj', i.model)"
              :validationValue="$v.recipeObj[i.model]"
              :title="$t(`label.${i.title}`)"
              :validators="i.validators"
              :formType="i.formType"
              :config="i.config"
              :validationParams="i.validationParams"
              :moreRemark="i.moreRemark"
              :isRequires="i.isRequires"
              :prepend="i.prepend"
              :disabled="i.disabled"
              :min="i.min"
              :solid="!i.disabled"
            >
              <template v-slot:prepend>
                <span class="input-group-text text-uppercase">{{
                  $t(`currency.${currency.MYR}`)
                }}</span>
              </template>
            </AppFormField>
            <div style="display: none">
              <!-- WARNING: I have no idea why this line is here, but removing it breaks everything -->
              {{ $v }}
            </div>
          </b-col>
        </template>
      </b-row>
    </b-form>
    <!--begin: Wizard Actions -->
  </div>
  <!--end: Wizard Actions -->
  <!--end: Wizard Step 1-->
</template>

<script>
  import AppLiveSearch from '@/views/components/bases/AppLiveSearch';
  import AppFormField from '@/views/components/bases/form-field/AppFormField';
  import AppViewAndUpload from '@/views/components/bases/AppViewAndUpload';
  import constantsMixin from '@/core/mixins/constants.mixin';
  import commonMixin from '@/core/mixins/common.mixin';
  import recipePageMixin from '@/core/mixins/recipe-page.mixin';
  import { validationMixin } from 'vuelidate';
  import {
    GLOBAL,
    MODULE,
    ACTION,
    ROUTE_NAME,
    CURRENCY,
    price
  } from '@/core/constants';
  import { BannerActionType } from '@/core/constants/enums';

  import { required, numeric } from 'vuelidate/lib/validators';

  export default {
    name: 'RecipePageForm',
    mixins: [commonMixin, constantsMixin, validationMixin, recipePageMixin],
    components: {
      AppFormField,
      AppLiveSearch,
      AppViewAndUpload
    },
    props: {
      id: {},
      isCreate: {
        type: Boolean,
        default: false
      },
      category: {
        type: String,
        default: ''
      },
      recipeObj: {
        type: Object,
        default: () => {}
      }
    },
    data: () => ({
      moduleName: MODULE.RECIPE_PAGE,
      permissionAction: ACTION,
      global: GLOBAL,
      routeName: ROUTE_NAME,
      currency: CURRENCY,
      actionType: BannerActionType,
      formFields: []
    }),
    mounted() {
      if (!this.isCreate) this.getRecipePage(this.id);
      this.initFormFields();
    },
    computed: {},
    watch: {},
    methods: {
      initFormFields() {
        this.formFields.push(
          {
            field: 1,
            model: 'recipeId',
            title: 'recipeId',
            ref: 'recipeId',
            validators: ['required'],
            layout: 'AppLiveSearch',
            isRequires: true,
            disabled: !this.isCreate
          },
          {
            field: 1,
            model: 'creatorName',
            title: 'creatorName',
            validators: ['required'],
            isRequires: true,
            disabled: true
          },
          {
            field: 1,
            model: 'ingredients',
            title: 'ingredients',
            validators: ['required', 'numeric'],
            isRequires: true,
            disabled: true
          },
          {
            field: 1,
            model: 'servePax',
            title: 'servePax',
            validators: ['required', 'numeric'],
            isRequires: true,
            disabled: true
          },

          {
            field: 1,
            model: 'price',
            title: 'price',
            validators: ['price'],
            prepend: true,
            disabled: true
          },
          {
            field: 1,
            model: 'description',
            title: 'description',
            validators: ['required'],
            isRequires: true,
            disabled: true
          },
          {
            field: 1,
            model: 'priority',
            title: 'priority',
            validators: ['required', 'numeric'],
            isRequires: true
          },
          {
            field: 1,
            model: 'thumbnail',
            title: 'thumbnail',
            validators: ['required'],
            layout: 'AppViewAndUpload',
            isRequires: true,
            disabled: true
          }
        );
      },
      validationCheckForm() {
        //Submit new banner form if all the validation is no error
        return this.validationCheck('recipeObj');
      },
      validationActionValueCheck() {
        var isValid = true;
        isValid = this.$refs['recipeId'][0].validate();
        return isValid;
      },
      returnRecipeObj(value) {
        this.$emit('getRecipeObj', value);
      }
    },
    validations() {
      return {
        recipeObj: {
          recipeId: { required },
          creatorName: { required },
          ingredients: { required, numeric },
          servePax: { required, numeric },
          price: { price },
          description: { required },
          priority: { required, numeric },
          thumbnail: { required }
        }
      };
    }
  };
</script>

<style></style>
