import dateTimeFormat from '@/core/filters/dateTimeFormat.filter';
import { MERCHANT_GROUPING } from '@/core/api';
import { apiHelper } from '@/core/utils';
import {
  LOADING_OPEN_LOADING,
  LOADING_CLOSE_LOADING
} from '@/core/store/loading.module';

/* Define Module Name */
const MODULE_NAME = 'merchantGrouping/';

/* Define Action Name */
const A_GET_MERCHANT_GROUPINGS = 'getMerchantGroupings';
const A_GET_MERCHANT_GROUPING = 'getMerchantGrouping';
const A_CREATE_MERCHANT_GROUPING = 'createMerchantGrouping';
const A_UPDATE_MERCHANT_GROUPING = 'updateMerchantGrouping';
const A_DELETE_MERCHANT_GROUPING = 'deleteMerchantGrouping';

/* Define Reset State Action Name */
const A_INITIAL_GET_MERCHANT_GROUPINGS_STATE =
  'initialGetMerchantGroupingsState';
const A_INITIAL_GET_MERCHANT_GROUPING_STATE = 'initialGetMerchantGroupingState';
const A_INITIAL_CREATE_MERCHANT_GROUPING_STATE =
  'initialCreateMerchantGroupingState';
const A_INITIAL_UPDATE_MERCHANT_GROUPING_STATE =
  'initialUpdateMerchantGroupingState';
const A_INITIAL_DELETE_MERCHANT_GROUPING_STATE =
  'initialDeleteMerchantGroupingState';

/* Define Getter Name */

/* Define Mutation Name */
const M_GET_MERCHANT_GROUPINGS = 'setGetMerchantGroupings';
const M_GET_MERCHANT_GROUPING = 'setGetMerchantGrouping';
const M_CREATE_MERCHANT_GROUPING = 'setCreateMerchantGrouping';
const M_UPDATE_MERCHANT_GROUPING = 'setUpdateMerchantGrouping';
const M_DELETE_MERCHANT_GROUPING = 'setDeleteMerchantGrouping';

/* Define Reset State Mutation Name */
const M_INITIAL_GET_MERCHANT_GROUPINGS_STATE =
  'setInitialGetMerchantGroupingsState';
const M_INITIAL_GET_MERCHANT_GROUPING_STATE =
  'setInitialGetMerchantGroupingState';
const M_INITIAL_CREATE_MERCHANT_GROUPING_STATE =
  'setInitialCreateMerchantGroupingState';
const M_INITIAL_UPDATE_MERCHANT_GROUPING_STATE =
  'setInitialUpdateMerchantGroupingState';
const M_INITIAL_DELETE_MERCHANT_GROUPING_STATE =
  'setInitialDeleteMerchantGroupingState';

/* Define Export Name */
export const MERCHANT_GROUPING_GET_MERCHANT_GROUPINGS =
  MODULE_NAME + A_GET_MERCHANT_GROUPINGS;
export const MERCHANT_GROUPING_GET_MERCHANT_GROUPING =
  MODULE_NAME + A_GET_MERCHANT_GROUPING;
export const MERCHANT_GROUPING_CREATE_MERCHANT_GROUPING =
  MODULE_NAME + A_CREATE_MERCHANT_GROUPING;
export const MERCHANT_GROUPING_UPDATE_MERCHANT_GROUPING =
  MODULE_NAME + A_UPDATE_MERCHANT_GROUPING;
export const MERCHANT_GROUPING_DELETE_MERCHANT_GROUPING =
  MODULE_NAME + A_DELETE_MERCHANT_GROUPING;

/* Define Reset State Name */
export const MERCHANT_GROUPING_INITIAL_GET_MERCHANT_GROUPINGS_STATE =
  MODULE_NAME + A_INITIAL_GET_MERCHANT_GROUPINGS_STATE;
export const MERCHANT_GROUPING_INITIAL_GET_MERCHANT_GROUPING_STATE =
  MODULE_NAME + A_INITIAL_GET_MERCHANT_GROUPING_STATE;
export const MERCHANT_GROUPING_INITIAL_CREATE_MERCHANT_GROUPING_STATE =
  MODULE_NAME + A_INITIAL_CREATE_MERCHANT_GROUPING_STATE;
export const MERCHANT_GROUPING_INITIAL_UPDATE_MERCHANT_GROUPING_STATE =
  MODULE_NAME + A_INITIAL_UPDATE_MERCHANT_GROUPING_STATE;
export const MERCHANT_GROUPING_INITIAL_DELETE_MERCHANT_GROUPING_STATE =
  MODULE_NAME + A_INITIAL_DELETE_MERCHANT_GROUPING_STATE;

const state = {
  merchantGroupings: {
    code: 0,
    complete: false,
    data: null,
    message: null,
    pagination: {
      currentPage: 0,
      total: 0,
      perPage: 0,
      lastPage: 0
    }
  },
  merchantGrouping: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  createMerchantGrouping: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  updateMerchantGrouping: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  deleteMerchantGrouping: {
    code: 0,
    complete: false,
    data: null,
    message: null
  }
};

//Getters
const getters = {};

// Actions
const actions = {
  [A_GET_MERCHANT_GROUPINGS]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    MERCHANT_GROUPING.getMerchantGroupings(data)
      .then((response) => {
        result = response;
        commit(M_GET_MERCHANT_GROUPINGS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_MERCHANT_GROUPINGS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_MERCHANT_GROUPING]({ dispatch, commit }, { id }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    MERCHANT_GROUPING.getMerchantGrouping(id)
      .then((response) => {
        result = response;
        commit(M_GET_MERCHANT_GROUPING, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_MERCHANT_GROUPING, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_CREATE_MERCHANT_GROUPING]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    MERCHANT_GROUPING.createMerchantGrouping(data)
      .then((response) => {
        result = response;
        commit(M_CREATE_MERCHANT_GROUPING, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_CREATE_MERCHANT_GROUPING, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_UPDATE_MERCHANT_GROUPING]({ dispatch, commit }, { id, data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    MERCHANT_GROUPING.updateMerchantGrouping(id, data)
      .then((response) => {
        result = response;
        commit(M_UPDATE_MERCHANT_GROUPING, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_UPDATE_MERCHANT_GROUPING, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_DELETE_MERCHANT_GROUPING]({ dispatch, commit }, { id }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    MERCHANT_GROUPING.deleteMerchantGrouping(id)
      .then((response) => {
        result = response;
        commit(M_DELETE_MERCHANT_GROUPING, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_DELETE_MERCHANT_GROUPING, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_INITIAL_GET_MERCHANT_GROUPINGS_STATE]({ commit }) {
    commit(M_INITIAL_GET_MERCHANT_GROUPINGS_STATE);
  },
  [A_INITIAL_GET_MERCHANT_GROUPING_STATE]({ commit }) {
    commit(M_INITIAL_GET_MERCHANT_GROUPING_STATE);
  },
  [A_INITIAL_CREATE_MERCHANT_GROUPING_STATE]({ commit }) {
    commit(M_INITIAL_CREATE_MERCHANT_GROUPING_STATE);
  },
  [A_INITIAL_UPDATE_MERCHANT_GROUPING_STATE]({ commit }) {
    commit(M_INITIAL_UPDATE_MERCHANT_GROUPING_STATE);
  },
  [A_INITIAL_DELETE_MERCHANT_GROUPING_STATE]({ commit }) {
    commit(M_INITIAL_DELETE_MERCHANT_GROUPING_STATE);
  }
};

// Mutations
const mutations = {
  [M_GET_MERCHANT_GROUPINGS](state, result) {
    if (result.code === 0) {
      let d = result.data;
      let p = result.pagination;
      state.merchantGroupings = {
        code: result.code,
        complete: true,
        data: d.map((x, index) => {
          return {
            no: index + 1,
            id: x.id,
            corporateGroup: 'x.corporateGroup',
            totalSubsidiary: 5,
            totalBranch: 4,
            status: 1
          };
        }),
        message: null,
        pagination: {
          currentPage: p.current_page,
          total: p.total,
          perPage: p.per_page,
          lastPage: p.last_page
        }
      };
    } else {
      state.merchantGroupings = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message),
        pagination: {
          currentPage: 0,
          total: 0,
          perPage: 0,
          lastPage: 0
        }
      };
    }
  },
  [M_GET_MERCHANT_GROUPING](state, result) {
    if (result.code === 0) {
      let d = result.data;

      state.merchantGrouping = {
        code: result.code,
        complete: true,
        data: {
          id: d.id,
          eventName: 'd.title',
          startDateTime: dateTimeFormat(new Date()),
          endDateTime: dateTimeFormat(new Date()),
          createdBy: d.created_by
        },
        message: null
      };
    } else {
      state.merchantGrouping = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_CREATE_MERCHANT_GROUPING](state, result) {
    state.createMerchantGrouping = {
      code: result.code,
      complete: true,
      data: result.data,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_UPDATE_MERCHANT_GROUPING](state, result) {
    state.updateMerchantGrouping = {
      code: result.code,
      complete: true,
      data: result,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_DELETE_MERCHANT_GROUPING](state, result) {
    state.deleteMerchantGrouping = {
      code: result.code,
      complete: true,
      data: result,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_INITIAL_GET_MERCHANT_GROUPINGS_STATE](state) {
    state.merchantGroupings = {
      code: 0,
      complete: false,
      data: null,
      message: null,
      pagination: {
        currentPage: 0,
        total: 0,
        perPage: 0,
        lastPage: 0
      }
    };
  },
  [M_INITIAL_GET_MERCHANT_GROUPING_STATE](state) {
    state.merchantGrouping = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_CREATE_MERCHANT_GROUPING_STATE](state) {
    state.createMerchantGrouping = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_UPDATE_MERCHANT_GROUPING_STATE](state) {
    state.updateMerchantGrouping = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_DELETE_MERCHANT_GROUPING_STATE](state) {
    state.deleteMerchantGrouping = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
