import { apiService } from '@/core/services';
import { RecipeStatus } from '@/core/constants/enums';
import { apiHelper } from '@/core/utils';

export const RECIPE = {
  getRecipes,
  getRecipe,
  getIngredients,
  getEquipment,
  getInstructions,
  getEcoPackRecipes,
  updateRecipe,
  updateIngredients,
  updateEquipment,
  updateTerminateEcoPack,
  updateRequestEcopack,
  updateEcoPackVariations,
  verifyRecipe,
  setVisibility,
  setEditEcopack,
  setRequestEcopack,
  rejectRecipe,
  rejectEcoPack,
  approveEcoPack,
  notifyCreatorEcoPack
};

const URL = {
  ADMIN: 'admin',
  RECIPES: 'recipes',
  INGREDIENTS: 'ingredients',
  EQUIPMENT: 'equipment',
  INSTRUCTIONS: 'instructions',
  VERIFY: 'verify',
  SET_VISIBLE: 'set-visible',
  REJECT: 'reject',
  ECO_PACK_LISTING: 'eco-pack-listing',
  COOKX_ECO_PACK: 'cookx-eco-pack',
  ALLOW_CREATOR_EDIT: 'allow-creator-edit',
  COOKX_EQUIPMENT: 'cookx-equipment',
  REJECT_REQUEST_COOKX: 'reject-request-cookx',
  APPROVE_REQUEST_COOKX: 'approve-request-cookx',
  STOP: 'stop',
  UPDATE: 'update',
  NOTIFY: 'notify',
  VARIATION_GROUPS: 'variation-groups'
};

function getRecipes(data) {
  let status = data.status;
  let isVerified = null;
  let isDeleted = null;

  switch (status) {
    case RecipeStatus.PENDING:
      isVerified = 0;
      break;
    case RecipeStatus.VERIFIED:
      isVerified = 1;
      break;
    case RecipeStatus.DELETED:
      isDeleted = 1;
      break;
  }

  let requestOptions = {
    params: {
      id: data.id,
      name: data.name,
      username: data.username,
      cooking_level: data.cookingLevel,
      cuisine_type: data.cuisineType,
      food_type: data.foodType,
      is_popular: data.isPopular,
      is_trending: data.isTrending,
      is_top10: data.isTop10,
      is_recommended: data.isRecommended,
      is_verified: isVerified,
      is_deleted: isDeleted,
      is_active: data.isActive,
      eco_pack_option: data.ecoPackOption,
      user_id: data.userId,
      per_page: data.perPage,
      page: data.page,
      prefilter: data.prefilter,
      is_cookx_eco_pack: data.isCookxEcoPack
    }
  };
  return apiService().get(`/${URL.ADMIN}/${URL.RECIPES}`, requestOptions);
}

function getEcoPackRecipes(data) {
  let recipeStatus = data.recipeStatus;
  let isVerified = null;
  let isDeleted = null;

  switch (recipeStatus) {
    case RecipeStatus.PENDING:
      isVerified = 0;
      break;
    case RecipeStatus.VERIFIED:
      isVerified = 1;
      break;
    case RecipeStatus.DELETED:
      isDeleted = 1;
      break;
  }

  let requestOptions = {
    params: {
      id: data.id,
      name: data.name,
      username: data.username,
      eco_pack_option: data.ecoPackOption,
      per_page: data.perPage,
      page: data.page,
      prefilter: data.prefilter,
      is_verified: isVerified,
      is_deleted: isDeleted,
      eco_pack_status: data.ecoPackStatus,
      // is_cookx_eco_pack: data.isCookxEcoPack,
      available_from: apiHelper.dateTimeStart(data.ecoPackAvailableFrom),
      available_to: apiHelper.dateTimeEnd(data.ecoPackAvailableFrom)
    }
  };
  return apiService().get(
    `/${URL.ADMIN}/${URL.RECIPES}/${URL.ECO_PACK_LISTING}`,
    requestOptions
  );
}

function getRecipe(id) {
  return apiService().get(`/${URL.ADMIN}/${URL.RECIPES}/${id}`);
}

function getIngredients(id, data) {
  let requestOptions = {
    params: {
      per_page: data.perPage,
      page: data.page
    }
  };
  return apiService().get(
    `/${URL.ADMIN}/${URL.RECIPES}/${id}/${URL.INGREDIENTS}`,
    requestOptions
  );
}

function getEquipment(id, data) {
  let requestOptions = {
    params: {
      per_page: data.perPage,
      page: data.page
    }
  };
  return apiService().get(
    `/${URL.ADMIN}/${URL.RECIPES}/${id}/${URL.EQUIPMENT}`,
    requestOptions
  );
}

function getInstructions(id, data) {
  let requestOptions = {
    params: {
      per_page: data.perPage,
      page: data.page
    }
  };
  return apiService().get(
    `/${URL.ADMIN}/${URL.RECIPES}/${id}/${URL.INSTRUCTIONS}`,
    requestOptions
  );
}

function updateIngredients(id, data, ingredientId) {
  let requestBody = {
    price: data.price
  };
  return apiService().put(
    `/${URL.ADMIN}/${URL.RECIPES}/${id}/${URL.INGREDIENTS}/${ingredientId}`,
    requestBody
  );
}

function updateEquipment(id, data) {
  let requestBody = {
    equipment_id: data.equipmentId,
    available_from: data.sellingPeriodFrom,
    available_to: data.sellingPeriodTo
  };
  return apiService().put(
    `/${URL.ADMIN}/${URL.RECIPES}/${id}/${URL.COOKX_EQUIPMENT}`,
    requestBody
  );
}

function updateTerminateEcoPack(id) {
  return apiService().put(
    `/${URL.ADMIN}/${URL.RECIPES}/${id}/${URL.COOKX_ECO_PACK}/${URL.STOP}`
  );
}

function updateRequestEcopack(id, data) {
  const calculateBufferTime = (day, hour) => {
    let dayHour = day * 24;
    return Number(dayHour) + Number(hour);
  };

  let deliveryObj = data.deliveryObj;
  let deliveryMethod = deliveryObj.deliveryMethod;
  if (
    [data.vehicleType].some((val) => val == 3) &&
    !deliveryMethod.some((val) => val == 3)
  ) {
    deliveryMethod.push(data.vehicleType);
  } else if (deliveryMethod.some((val) => val == 3) && data.vehicleType == 2) {
    let index = deliveryMethod.indexOf(3);
    deliveryMethod.splice(index, 1);
  }
  let requestBody = {
    price: data.ecoPackPrice,
    promotion_price: data.promoPrice,
    selling_limit: data.sellingQuantity.type,
    selling_quantity: data.sellingQuantity.value,
    delivery_method: deliveryMethod,
    available_from: new Date(data.sellingPeriodFrom)
      .toISOString()
      .split('T')[0],
    available_to: new Date(data.sellingPeriodTo).toISOString().split('T')[0],
    delivery_buffer_hour: calculateBufferTime(
      deliveryObj.deliveryBufferDay,
      deliveryObj.deliveryBufferHour
    ),
    pickup_buffer_hour: calculateBufferTime(
      deliveryObj.pickupBufferDay,
      deliveryObj.pickupBufferHour
    )
  };

  return apiService().put(
    `/${URL.ADMIN}/${URL.RECIPES}/${id}/${URL.COOKX_ECO_PACK}/${URL.UPDATE}`,
    requestBody
  );
}

function updateEcoPackVariations(id, data) {
  let requestBody = {
    variations: data.map((x) => ({
      name: x.name,
      is_active: x.isActive,
      variation_requirement: {
        no_of_requirement: Number(x.variationRequirement.noOfRequirement),
        is_active: x.variationRequirement.isActive
      },
      variation_options: x.variationOptions.map((x) => {
        return {
          name: x.name,
          [x.uuid != '' ? 'uuid' : '']: x.uuid,
          price: Number(x.price),
          is_active: x.isActive
        };
      })
    }))
  };

  return apiService().put(
    `/${URL.ADMIN}/${URL.RECIPES}/${id}/${URL.VARIATION_GROUPS}`,
    requestBody
  );
}

function verifyRecipe(id) {
  return apiService().put(`/${URL.ADMIN}/${URL.RECIPES}/${id}/${URL.VERIFY}`);
}

function setVisibility(id, data) {
  let requestBody = {
    is_visible: data.isVisible
  };

  return apiService().put(
    `/${URL.ADMIN}/${URL.RECIPES}/${id}/${URL.SET_VISIBLE}`,
    requestBody
  );
}

function setEditEcopack(id) {
  return apiService().post(
    `/${URL.ADMIN}/${URL.RECIPES}/${id}/${URL.COOKX_ECO_PACK}/${URL.ALLOW_CREATOR_EDIT}`
  );
}

function setRequestEcopack(id, data) {
  const calculateBufferTime = (day, hour) => {
    let dayHour = day * 24;
    return Number(dayHour) + Number(hour);
  };

  let deliveryObj = data.deliveryObj;
  let deliveryMethod = deliveryObj.deliveryMethod;
  if (
    [data.vehicleType].some((val) => val == 3) &&
    !deliveryMethod.some((val) => val == 3)
  ) {
    deliveryMethod.push(data.vehicleType);
  } else if (deliveryMethod.some((val) => val == 3) && data.vehicleType == 2) {
    let index = deliveryMethod.indexOf(3);
    deliveryMethod.splice(index, 1);
  }

  let requestBody = {
    price: data.ecoPackPrice,
    promotion_price: data.promoPrice,
    selling_limit: data.sellingQuantity.type,
    selling_quantity: data.sellingQuantity.value,
    delivery_method: deliveryMethod,
    available_from: new Date(data.sellingPeriodFrom)
      .toISOString()
      .split('T')[0],
    available_to: new Date(data.sellingPeriodTo).toISOString().split('T')[0],
    delivery_buffer_hour: calculateBufferTime(
      deliveryObj.deliveryBufferDay,
      deliveryObj.deliveryBufferHour
    ),
    pickup_buffer_hour: calculateBufferTime(
      deliveryObj.pickupBufferDay,
      deliveryObj.pickupBufferHour
    )
  };

  return apiService().put(
    `/${URL.ADMIN}/${URL.RECIPES}/${id}/${URL.COOKX_ECO_PACK}`,
    requestBody
  );
}

function updateRecipe(id, data) {
  let requestBody = {
    is_popular: data.isPopular,
    is_recommended: data.isRecommended,
    is_trending: data.isTrending,
    is_cookx_eco_pack: data.isCookxEcoPack,
    filter_category: data.filterCategory
  };
  return apiService().put(`/${URL.ADMIN}/${URL.RECIPES}/${id}`, requestBody);
}

function rejectRecipe(id, data) {
  let requestBody = {
    rejected_remark: data.rejectedRemark,
    rejected_reason: data.rejectedReason
  };
  return apiService().put(
    `/${URL.ADMIN}/${URL.RECIPES}/${id}/${URL.REJECT}`,
    requestBody
  );
}

function rejectEcoPack(id, data) {
  let requestBody = {
    rejected_reason: data.rejectedReason
  };
  return apiService().put(
    `/${URL.ADMIN}/${URL.RECIPES}/${id}/${URL.REJECT_REQUEST_COOKX}`,
    requestBody
  );
}
function approveEcoPack(id) {
  return apiService().put(
    `/${URL.ADMIN}/${URL.RECIPES}/${id}/${URL.APPROVE_REQUEST_COOKX}`
  );
}

function notifyCreatorEcoPack(id) {
  return apiService().get(
    `/${URL.ADMIN}/${URL.RECIPES}/${id}/${URL.COOKX_ECO_PACK}/${URL.NOTIFY}`
  );
}
