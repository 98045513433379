<template>
  <div>
    <!--  * * * * * * * * * * * * * * * * * * * * * * * * * * *  *
          *                                                      *
          *  .=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-.  *
          *  |                     ______                     |  *
          *  |                  .-"      "-.                  |  *
          *  |                 /            \                 |  *
          *  |     _          |              |          _     |  *
          *  |    ( \         |,  .-.  .-.  ,|         / )    |  *
          *  |     > "=._     | )(__/  \__)( |     _.=" <     |  *
          *  |    (_/"=._"=._ |/     /\     \| _.="_.="\_)    |  *
          *  |           "=._"(_     ^^     _)"_.="           |  *
          *  |               "=\__|IIIIII|__/="               |  *
          *  |              _.="| \IIIIII/ |"=._              |  *
          *  |    _     _.="_.="\          /"=._"=._     _    |  *
          *  |   ( \_.="_.="     `--------`     "=._"=._/ )   |  *
          *  |    > _.="                            "=._ <    |  *
          *  |   (_/                                    \_)   |  *
          *  |                                                |  *
          *  '-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-='  *
          *                                                      *
          * * * * * * * * * * * * * * * * * * * * * * * * * * *  * 
          LEAVE EVERY HOPE, YOU COME IN
     -->
    <OfflineMerchantForm :isCreate="isCreate"></OfflineMerchantForm>
  </div>
</template>

<script>
  import OfflineMerchantForm from '@/views/components/offline-merchant/OfflineMerchantForm.vue';
  export default {
    name: 'OfflineMerchantDetails',
    components: {
      OfflineMerchantForm
    },
    data: () => ({
      isCreate: false
    }),
    mounted() {},
    methods: {}
  };
</script>

<style></style>
