<template>
  <div>
    <button
      v-if="!checkAccessRight(moduleName, [permissionAction.POINT_DELETE])"
      class="btn btn-light-danger font-weight-bold text-caitalize mr-3"
      @click="deletepoint"
    >
      {{ $t('label.delete') }}
    </button>
    <button
      v-if="!checkAccessRight(moduleName, [permissionAction.POINT_UPDATE])"
      class="btn btn-warning font-weight-bold text-caitalize mr-3"
      @click="activatepoint"
    >
      {{
        pointObj.status == 1 ? $t('label.deactivate') : $t('label.activate')
      }}
    </button>
  </div>
</template>

<script>
  import commonMixin from '@/core/mixins/common.mixin';
  import { MODULE, ACTION, ROUTE_NAME } from '@/core/constants';
  import { i18nHelper } from '@/core/utils';
  import {
    POINT_UPDATE_POINT_ACTIVATE,
    POINT_DELETE_POINT,
    POINT_INITIAL_UPDATE_POINT_ACTIVATE_STATE,
    POINT_INITIAL_DELETE_POINT_STATE
  } from '@/core/store/point.module';

  export default {
    name: 'pointStatusBtn',
    mixins: [commonMixin],
    data: () => ({
      moduleName: MODULE.STUDIO,
      permissionAction: ACTION,
      routeName: ROUTE_NAME
    }),
    props: {
      id: {
        Type: Number,
        default: null
      },
      pointObj: {
        Type: Object,
        default: {
          status: null
        }
      }
    },
    computed: {
      // updatepointStatusComplete() {
      //   return this.$store.state.point.updatepointActivate.complete;
      // },
      deletepointComplete() {
        return this.$store.state.point.deletepoint.complete;
      }
    },
    watch: {
      // updatepointStatusComplete() {
      //   let response = this.$store.state.point.updatepointActivate;
      //   if (response.complete) {
      //     this.pointCompleteAction(response, 'update');
      //   }
      // },
      deletepointComplete() {
        let response = this.$store.state.point.deletepoint;
        if (response.complete) {
          this.pointCompleteAction(response, 'delete');
        }
      }
    },
    methods: {
      pointCompleteAction(response, actionType) {
        let title = i18nHelper.getMessage('label.point');
        let messages = response.message;
        let buttons = [];
        switch (actionType) {
          case 'update':
            this.initialUpdatepointStatus();
            break;
          case 'delete':
            this.initialDeletepointState();
            break;
        }
        if (response.code == 0) {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();
              switch (actionType) {
                case 'delete':
                  this.$router.push({
                    name: ROUTE_NAME.POINT_LIST
                  });
                  break;
                default:
                  this.$parent.$parent.getpoint(this.id);
              }
            }
          });
        } else {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();
            }
          });
        }
        if (messages) this.openLayoutAppModalInfo(title, messages, buttons);
      },
      activatepoint() {
        let id = this.id;
        let data;

        switch (this.pointObj.status) {
          case 0:
            data = 1;
            break;
          case 1:
            data = 0;
            break;
          case 2:
            data = 1;
            break;
        }
        this.$store.dispatch(POINT_UPDATE_POINT_ACTIVATE, {
          id,
          data
        });
      },
      deletepoint() {
        let id = this.id;

        this.$store.dispatch(POINT_DELETE_POINT, { id });
      },
      initialUpdatepointStatus() {
        this.$store.dispatch(POINT_INITIAL_UPDATE_POINT_ACTIVATE_STATE);
      },
      initialDeletepointState() {
        this.$store.dispatch(POINT_INITIAL_DELETE_POINT_STATE);
      }
    }
  };
</script>

<style></style>
