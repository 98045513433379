const RecipeEcoPackStatus = {
  PENDING_ACCEPTANCE: 0,
  ACCEPTED: 1,
  CANCEL: 2,
  EXPIRED: 3,
  REJECTED: 4,
  PENDING_APPROVAL: 5,
  COOKX_ACCEPTED: 6
};

export { RecipeEcoPackStatus };
