<template>
  <b-card no-body data-style="custom">
    <!--begin: Wizard-->
    <div
      class="wizard wizard-2"
      id="voucherWizard"
      data-wizard-state="step-first"
      data-wizard-clickable="true"
    >
      <!--begin: Wizard Nav -->
      <div class="wizard-nav border-right py-8 px-8 py-lg-20 px-lg-10">
        <div class="wizard-steps">
          <!--begin: Wizard Steps 1 -->
          <div
            class="wizard-step"
            data-wizard-type="step"
            data-wizard-state="current"
          >
            <div class="wizard-wrapper">
              <div class="wizard-icon">
                <span class="svg-icon svg-icon-2x">
                  <inline-svg
                    src="/media/svg/icons/Communication/Clipboard-list.svg"
                  />
                </span>
              </div>
              <div class="wizard-label text-capitalize">
                <h3 class="wizard-title">
                  {{ $t('label.voucher') }}
                </h3>
                <div class="wizard-desc">
                  {{ $t('label.fillInInfoAndAction') }}
                </div>
              </div>
            </div>
          </div>
          <!--end: Wizard Steps 1 -->
          <!--begin: Wizard Steps 2 -->
          <div
            v-if="!isCreate"
            class="wizard-step"
            data-wizard-type="step"
            data-wizard-state=""
          >
            <div class="wizard-wrapper">
              <div class="wizard-icon">
                <span class="svg-icon svg-icon-2x">
                  <inline-svg
                    src="/media/svg/icons/Communication/Clipboard-check.svg"
                  />
                </span>
              </div>
              <div
                class="wizard-label text-capitalize"
                :class="{
                  'text-primary cursor-pointer': voucherObj.totalClaim
                }"
              >
                <h3 class="wizard-title">
                  {{ `${voucherObj.totalClaim} ` + $t(`label.users`) }}
                </h3>
                <div class="wizard-desc">
                  {{ $t('label.claimedUserTable') }}
                </div>
              </div>
            </div>
          </div>
          <!--end: Wizard Steps 2 -->
          <!--begin: Wizard Steps 3 -->
          <div class="wizard-step" data-wizard-type="step" data-wizard-state="">
            <div
              class="wizard-wrapper"
              v-if="
                !isCreate && voucherObj.applyTo == voucherApplyTo.SELECTED_USER
              "
            >
              <div class="wizard-icon">
                <span class="svg-icon svg-icon-2x">
                  <inline-svg
                    src="/media/svg/icons/Communication/Clipboard-check.svg"
                  />
                </span>
              </div>
              <div
                class="wizard-label"
                :class="{
                  'text-primary cursor-pointer': true
                }"
              >
                <h3 class="wizard-title text-capitalize">
                  {{
                    `${voucherObj.totalSpecific} ` + $t(`label.specificUsers`)
                  }}
                </h3>
                <div class="wizard-desc">
                  {{ $t('label.leaveItEmptyIfApplyToAllUsers') }}
                </div>
              </div>
            </div>
          </div>
          <!--end: Wizard Steps 3 -->
        </div>
      </div>
      <!--end: Wizard Nav -->

      <!--begin: Wizard Body -->
      <div class="wizard-body py-6 px-8" style="overflow-x: auto; width: 100%">
        <!--begin: Wizard Form-->
        <b-row>
          <b-col offset-xl="2" xl="8">
            <!--begin: Wizard Step 1-->
            <div data-wizard-type="step-content" data-wizard-state="current">
              <VoucherForm
                ref="VoucherForm"
                :id="id"
                :voucherObj="voucherObj"
                :isCreate="isCreate"
              ></VoucherForm>
            </div>
            <!--end: Wizard Step 1-->
            <!--begin: Wizard Step 2-->
            <div
              v-if="!isCreate"
              class="pb-5"
              data-wizard-type="step-content"
              data-wizard-state=""
            >
              <!-- begin: Voucher Claimed Table -->
              <VoucherClaimedList
                :id="id"
                :voucherObj="voucherObj"
              ></VoucherClaimedList>
              <!-- end: Voucher Claimed Table -->
            </div>
            <!--end: Wizard Step 2-->
            <!--begin: Wizard Step 3-->
            <div
              v-if="!isCreate"
              class="pb-5"
              data-wizard-type="step-content"
              data-wizard-state=""
            >
              <!-- begin: Voucher Specific User Table -->
              <VoucherSpecificUserList
                v-if="!isCreate"
                :id="id"
                :voucherObj="voucherObj"
              ></VoucherSpecificUserList>
              <!-- end: Voucher Specific User Table -->
            </div>
            <!--end: Wizard Step 3-->
          </b-col>
          <!--end: Wizard-->
        </b-row>
      </div>
      <!--end: Wizard Body -->
    </div>
    <!--end: Wizard-->
  </b-card>
</template>

<script>
  import VoucherForm from '@/views/components/voucher/VoucherForm';
  import VoucherClaimedList from '@/views/components/voucher/VoucherClaimedList';
  import VoucherSpecificUserList from '@/views/components/voucher/VoucherSpecificUserList';
  import KTUtil from '@/assets/js/components/util';
  import KTWizard from '@/assets/js/components/wizard';
  import constantsMixin from '@/core/mixins/constants.mixin';
  import dateTimeFormat from '@/core/filters/dateTimeFormat.filter';
  import commonMixin from '@/core/mixins/common.mixin';
  import { GLOBAL, MODULE, ACTION, ROUTE_NAME } from '@/core/constants';
  import { VoucherApplyTo } from '@/core/constants/enums';
  import { i18nHelper } from '@/core/utils';
  // import { listService } from '@/core/services';
  import {
    VOUCHER_GET_VOUCHER,
    VOUCHER_CREATE_VOUCHER,
    VOUCHER_UPDATE_VOUCHER,
    VOUCHER_INITIAL_GET_VOUCHER_STATE,
    VOUCHER_INITIAL_CREATE_VOUCHER_STATE,
    VOUCHER_INITIAL_UPDATE_VOUCHER_STATE
  } from '@/core/store/voucher.module';

  export default {
    name: 'VoucherDetailsTemplate',
    mixins: [commonMixin, constantsMixin],
    components: {
      VoucherForm,
      VoucherClaimedList,
      VoucherSpecificUserList
    },
    props: {
      isCreate: {
        Type: Boolean,
        defualt: false
      }
    },
    data: () => ({
      moduleName: MODULE.VOUCHER,
      permissionAction: ACTION,
      global: GLOBAL,
      routeName: ROUTE_NAME,
      voucherApplyTo: VoucherApplyTo,
      constWizard: null,
      bannerFileSize: 5,
      voucherObj: {
        applyTo: null,
        voucherSource: null,
        selectedCreator: null,
        selectedDineMerchant: null,
        selectedBranch: null,
        groupName: '',
        usagePeriodFrom: null,
        usagePeriodTo: null,
        quantity: 0,
        eventName: null,
        voucherPrefix: null,
        voucherValueType: {
          type: null,
          value: null
        },
        minimumSpending: null,
        voucherType: null,
        banner: null,
        thumbnail: null,
        voucherTitle: null,
        description: null,
        redemptionPeriodFrom: null,
        redemptionPeriodTo: null,
        tnc: null
      },
      formFields: [],
      isLoading: false,
      selectedId: null,
      selectedType: null,
      disabledCreator: true,
      disabledMerchant: true,
      disabledBranch: true,
      disabledCookx: false,
      userContactNumber: null,
      getMerchantCreatorId: [],
      getDineInMerchantId: [],
      getMerchantOrCreatorBranch: [],
      isMultiple: false
    }),
    computed: {
      id() {
        return this.$route.params.id;
      },
      voucherComplete() {
        return this.$store.state.voucher.voucher.complete;
      },
      createVoucherComplete() {
        return this.$store.state.voucher.createVoucher.complete;
      },
      updateVoucherComplete() {
        return this.$store.state.voucher.updateVoucher.complete;
      },
      checkUsagePeriodFrom() {
        let now = dateTimeFormat(new Date());
        let UsagePeriodFrom = this.voucherObj.usagePeriodFrom;
        if (
          UsagePeriodFrom > now ||
          UsagePeriodFrom == '-' ||
          UsagePeriodFrom == null
        ) {
          return false;
        } else {
          return true;
        }
      }
    },
    watch: {
      async voucherComplete() {
        let response = this.$store.state.voucher.voucher;
        if (response.complete) {
          this.voucherObj = response.data;
          // await this.updateWizard();
          this.voucherCompleteAction(response, 'get');
        }
      },
      createVoucherComplete() {
        let response = this.$store.state.voucher.createVoucher;
        if (response.complete) {
          this.voucherCompleteAction(response, 'create');
        }
      },
      updateVoucherComplete() {
        let response = this.$store.state.voucher.updateVoucher;
        if (response.complete) {
          this.voucherCompleteAction(response, 'update');
        }
      }
    },
    mounted() {
      this.initialMerchhantVoucher();
    },
    methods: {
      async voucherCompleteAction(response, actionType) {
        let title = i18nHelper.getMessage('label.voucher');
        let messages = response.message;
        let buttons = [];
        switch (actionType) {
          case 'get':
            this.initialGetVoucherState();
            await this.$refs.VoucherForm.getVoucherCompleteActions(response);
            break;
          case 'create':
            this.initialCreateVoucherState();
            break;
          case 'update':
            this.initialUpdateVoucherState();
            break;
        }
        if (response.code == 0) {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();

              switch (actionType) {
                case 'create':
                  this.$router.push({
                    name: ROUTE_NAME.VOUCHER_DETAILS,
                    params: { id: response.data.id }
                  });
                  break;
                default:
                  this.getVoucher(this.id);
              }
            }
          });
        } else {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();
            }
          });
        }
        if (messages) this.openLayoutAppModalInfo(title, messages, buttons);
      },
      initWizard() {
        // Initialize form wizard
        let vm = this;

        const wizard = new KTWizard('voucherWizard', {
          startStep: 1, // initial active step number
          clickableSteps: true // allow step clicking
        });

        vm.constWizard = wizard;
        // wizard.on('submit', () => {
        //   vm.validationCheckBeforeSubmit();
        // });

        //Change event
        wizard.on('change', function (/*wizardObj*/) {
          // if (wizard.currentStep == 1) {
          //   let v1 = vm.validationCheck('voucherObj');

          //   if (!v1) {
          //     wizard.stop();
          //   }
          // }

          KTUtil.scrollTop();
        });
      },
      updateWizard() {
        let vm = this;
        const wizard = new KTWizard('voucherWizard', {
          startStep: 1, // initial active step number
          clickableSteps: true // allow step clicking
        });
        vm.constWizard = wizard;
        vm.constWizard.updateConstruct();
        vm.constWizard.init();

        this.initWizard();
      },
      goToWizard(value) {
        let vm = this;
        const wizard = new KTWizard('voucherWizard', {
          startStep: 1, // initial active step number
          clickableSteps: true // allow step clicking
        });

        vm.constWizard = wizard;
        vm.constWizard.goTo(value);
      },
      // Reset Form Action : Start
      resetForm() {
        this.formFields = [];
        this.voucherObj = {
          applyTo: null,
          voucherSource: null,
          selectedCreator: null,
          selectedDineMerchant: null,
          selectedBranch: null,
          groupName: null,
          usagePeriodFrom: null,
          usagePeriodTo: null,
          quantity: 0,
          eventName: null,
          voucherPrefix: null,
          voucherValueType: {
            type: null,
            value: null
          },
          minimumSpending: null,
          voucherType: null,
          banner: null,
          thumbnail: null,
          voucherTitle: null,
          description: null,
          redemptionPeriodFrom: null,
          redemptionPeriodTo: null,
          tnc: null
        };
        this.disabledCreator = true;
        this.disabledMerchant = true;
        this.disabledBranch = true;
        this.disabledCookx = false;
        this.selectedType = null;
        this.isMultiple = false;
      },
      // Reset Form End : End
      // Processing Front Data Action : Start
      submitVoucher() {
        let formData = new FormData();
        let data = this.voucherObj;
        let selectedBranch = data.selectedBranch;

        let usagePeriodFrom = new Date(data.usagePeriodFrom);
        let usagePeriodTo = new Date(data.usagePeriodTo);
        let redemptionPeriodFrom = new Date(data.redemptionPeriodFrom);
        let redemptionPeriodTo = new Date(data.redemptionPeriodTo);

        usagePeriodFrom = usagePeriodFrom.getTime();
        usagePeriodTo = usagePeriodTo.getTime();
        redemptionPeriodFrom = redemptionPeriodFrom.getTime();
        redemptionPeriodTo = redemptionPeriodTo.getTime();

        formData.append('apply_to', data.applyTo);
        formData.append('voucher_source', data.voucherSource);
        if (data.selectedDineMerchant) {
          data.selectedDineMerchant.forEach((item) =>
            formData.append('merchant_id[]', item)
          );
        } else {
          formData.append('merchant_id[]', '');
        }
        if (data.selectedCreator) {
          data.selectedCreator.forEach((item) =>
            formData.append('creator_id[]', item)
          );
        } else {
          formData.append('creator_id[]', '');
        }
        if (selectedBranch) {
          selectedBranch.forEach((item) =>
            formData.append('branch_ids[]', item)
          );
        } else {
          formData.append('branch_ids[]', '');
        }
        formData.append('grouping_name', data.groupName);
        formData.append(
          'usage_period_from',
          data.usagePeriodFrom == '-' || data.usagePeriodFrom == ''
            ? ''
            : usagePeriodFrom
        );
        formData.append(
          'usage_period_to',
          data.usagePeriodTo == '-' ? '' : usagePeriodTo
        );
        formData.append('quantity', data.quantity);
        formData.append('event_name', data.eventName ? data.eventName : '');
        formData.append('voucher_prefix', data.voucherPrefix.toUpperCase());
        formData.append(
          'value_type',
          data.voucherValueType ? data.voucherValueType.type : null
        );
        formData.append(
          'value',
          data.voucherValueType ? data.voucherValueType.value : null
        );
        formData.append('minimum_spending', data.minimumSpending);
        formData.append('voucher_type', data.voucherType);
        formData.append(
          'banner',
          !(data.banner instanceof Blob) ? '' : data.banner
        );
        formData.append(
          'thumbnail',
          !(data.thumbnail instanceof Blob) ? '' : data.thumbnail
        );

        formData.append('voucher_title', data.voucherTitle);
        formData.append('description', data.description);
        formData.append(
          'redemption_period_from',
          data.redemptionPeriodFrom == '' ? '' : redemptionPeriodFrom
        );
        formData.append('redemption_period_to', redemptionPeriodTo);
        formData.append('tnc', data.tnc);

        this.isCreate
          ? this.createVoucher(formData)
          : this.updateVoucher(this.id, formData);
      },
      // Processing Front Data Action : End
      getVoucher(id) {
        this.$store.dispatch(VOUCHER_GET_VOUCHER, { id });
      },
      createVoucher(formData) {
        this.$store.dispatch(VOUCHER_CREATE_VOUCHER, {
          formData
        });
      },
      updateVoucher(id, formData) {
        this.$store.dispatch(VOUCHER_UPDATE_VOUCHER, {
          id,
          formData
        });
      },
      initialGetVoucherState() {
        this.$store.dispatch(VOUCHER_INITIAL_GET_VOUCHER_STATE);
      },
      initialCreateVoucherState() {
        this.$store.dispatch(VOUCHER_INITIAL_CREATE_VOUCHER_STATE);
      },
      initialUpdateVoucherState() {
        this.$store.dispatch(VOUCHER_INITIAL_UPDATE_VOUCHER_STATE);
      },
      initialMerchhantVoucher() {
        let breadcrumbData = {
          breadcrumbs: [
            {
              title: i18nHelper.getMessage('label.voucher'),
              route: { name: ROUTE_NAME.VOUCHER }
            },
            {
              title: i18nHelper.getMessage('label.voucherList'),
              route: { name: ROUTE_NAME.VOUCHER_LIST }
            },
            {
              title: i18nHelper.getMessage(
                `label.${this.isCreate ? 'voucherCreate' : 'voucherDetails'}`
              )
            }
          ],
          actions: [
            {
              text: i18nHelper.getMessage('label.back'),
              function: () => {
                this.$router.go(-1);
              }
            }
          ]
        };
        this.initWizard();
        this.initBreadCrumb(breadcrumbData);
        if (!this.isCreate) this.getVoucher(this.id);
      }
    }
  };
</script>

<style lang="scss">
  @import '@/assets/sass/pages/wizard/wizard-2.scss';
</style>
