import { CONSTANTS } from '@/core/api';
import { LOCAL } from '@/core/constants';
import {
  permissionHelper,
  uiHelper,
  apiHelper,
  i18nHelper
} from '@/core/utils';
import {
  LOADING_OPEN_LOADING,
  LOADING_CLOSE_LOADING
} from '@/core/store/loading.module';

/* Define Module Name */
const MODULE_NAME = 'constants/';

/* Define Action Name */
const A_CONSTANTS = 'getConstants';
/* Define Reset State Action Name */
const A_INITIAL_CONSTANTS_STATE = 'initialConstantsState';

/* Define Getter Name */
const G_AVAILABLE_PERMISSIONS = 'availablePermissions';
const G_CONSTANTS = 'constants';

/* Define Mutation Name */
const M_CONSTANTS = 'setConstants';

/* Define Reset State Mutation Name */
const M_INITIAL_CONSTANTS_STATE = 'setInitialConstantState';

/* Define Export Name */
export const CONSTANTS_GET_CONSTANTS = MODULE_NAME + A_CONSTANTS;
export const CONSTANTS_AVAILABLE_PERMISSIONS =
  MODULE_NAME + G_AVAILABLE_PERMISSIONS;
export const CONSTANTS_CONSTANTS = MODULE_NAME + G_CONSTANTS;

/* Define Reset State Name */
export const CONSTANTS_INITIAL_CONSTANTS_STATE =
  MODULE_NAME + A_INITIAL_CONSTANTS_STATE;

const state = {
  constants: {
    code: 0,
    complete: false,
    data: {
      roles: [],
      permissions: [],
      cookingLevel: [],
      cuisineType: [],
      foodCategory: [],
      foodType: [],
      filterType: [],
      priceRange: [],
      servePax: [],
      baseFilter: [],
      bannerAction: [],
      bannerMediaType: [],
      bannerType: [],
      state: [],
      deliveryMethod: [],
      unitType: [],
      auditLogAction: [],
      auditLogType: [],
      orderStatus: [],
      deliveryStatus: [],
      deliveryVendor: [],
      creatorType: [],
      ecoPackOption: [],
      ecoPackStatus: [],
      creatorApplicationDocumentType: [],
      deliveryDiscount: [],
      voucherType: [],
      serviceType: [],
      merchantCategory: [],
      limitType: [],
      registerPlatform: [],
      platformUsing: [],
      voucherApplyTo: []
    },
    message: null
  }
};

//Getters
const getters = {
  [G_AVAILABLE_PERMISSIONS]: (state) => {
    let constants = uiHelper.getLocalStorage(LOCAL.CONSTANTS)
      ? JSON.parse(uiHelper.getLocalStorage(LOCAL.CONSTANTS))
      : state.constants.data;
    let permissions = permissionHelper.sortPermissionsByModule(
      constants.permissions
    );

    return permissions;
  },
  [G_CONSTANTS]: (state) => {
    let constants = uiHelper.getLocalStorage(LOCAL.CONSTANTS);

    return constants ? JSON.parse(constants) : state.constants.data;
  }
};

// Actions
const actions = {
  async [A_CONSTANTS]({ dispatch, commit }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    await CONSTANTS.getConstants()
      .then((response) => {
        let result = response;
        commit(M_CONSTANTS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_CONSTANTS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_INITIAL_CONSTANTS_STATE]({ commit }) {
    commit(M_INITIAL_CONSTANTS_STATE);
  }
};

// Mutations
const mutations = {
  [M_CONSTANTS](state, result) {
    if (result.code == 0) {
      let d = result.data;
      state.constants = {
        code: result.code,
        complete: true,
        data: {
          roles: d.roles,

          permissions: [
            ...d.permissions
            // For test new permission, if Backend not update permission on API
            // 'test-permissions.read',
            // 'test-permissions.create',
            // 'test-permissions.update',
            // 'test-permissions.delete'
          ],
          cookingLevel: d.cooking_level,
          cuisineType: d.cuisine_type,
          foodCategory: d.food_category,
          limitType: d.limit_type,
          foodType: d.food_type,
          filterType: d.filter_type,
          priceRange: d.price_range,
          servePax: d.serve_pax,
          baseFilter: d.base_filter,
          bannerAction: d.banner_action,
          bannerMediaType: d.banner_media_type,
          bannerType: d.banner_type,
          state: d.state,
          deliveryMethod: d.delivery_method,
          unitType: d.unit_type,
          auditLogAction: d.audit_log_action.map((x) => {
            return {
              key: x.key,
              value: x.value,
              description: i18nHelper.getMessage(`enumAuditLogAction.${x.key}`)
            };
          }),
          auditLogType: d.audit_log_type.map((x) => {
            return {
              key: x.key,
              value: x.value,
              description: i18nHelper.getMessage(`enumAuditLogType.${x.key}`)
            };
          }),
          platformFileType: d.platform_file_type,
          orderStatus: d.order_status,
          deliveryStatus: d.delivery_status,
          deliveryVendor: d.delivery_vendor,
          creatorType: d.creator_type,
          ecoPackOption: d.eco_pack_option,
          ecoPackStatus: d.eco_pack_status,
          creatorApplicationDocumentType: d.creator_application_document_type,
          deliveryDiscount: d.promotion_delivery,
          voucherType: d.voucher_type,
          serviceType: d.service_type,
          merchantCategory: d.merchant_category,
          registerPlatform: d.register_platform,
          platformUsing: [
            ...d.platform_using,
            {
              key: 'COOKX_MAHSING',
              value: '1,2',
              description: 'CookX, Mahsing'
            }
          ],
          voucherApplyTo: d.voucher_apply_to,
          pointApplyTo: d.point_apply_to_type
        },
        message: null
      };
      uiHelper.setLocalStorage(
        LOCAL.CONSTANTS,
        JSON.stringify(state.constants.data)
      );
    } else {
      state.user = {
        code: result.code,
        complete: false,
        data: {
          roles: [],
          permissions: [],
          cookingLevel: [],
          cuisineType: [],
          foodCategory: [],
          foodType: [],
          filterType: [],
          priceRange: [],
          servePax: [],
          baseFilter: [],
          bannerAction: [],
          bannerMediaType: [],
          bannerType: [],
          state: [],
          deliveryMethod: [],
          unitType: [],
          auditLogAction: [],
          auditLogType: [],
          orderStatus: [],
          deliveryStatus: [],
          deliveryVendor: [],
          creatorType: [],
          ecoPackOption: [],
          ecoPackStatus: [],
          creatorApplicationDocumentType: [],
          deliveryDiscount: [],
          voucherType: [],
          serviceType: [],
          merchantCategory: [],
          limitType: [],
          registerPlatform: [],
          platformUsing: [],
          voucherApplyTo: [],
          pointApplyTo: []
        },
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_INITIAL_CONSTANTS_STATE](state) {
    state.constants = {
      code: 0,
      complete: false,
      data: {
        roles: [],
        permissions: [],
        cookingLevel: [],
        cuisineType: [],
        foodCategory: [],
        foodType: [],
        filterType: [],
        priceRange: [],
        servePax: [],
        baseFilter: [],
        bannerAction: [],
        bannerMediaType: [],
        bannerType: [],
        state: [],
        deliveryMethod: [],
        unitType: [],
        auditLogAction: [],
        auditLogType: [],
        orderStatus: [],
        deliveryStatus: [],
        deliveryVendor: [],
        creatorType: [],
        ecoPackOption: [],
        ecoPackStatus: [],
        creatorApplicationDocumentType: [],
        deliveryDiscount: [],
        voucherType: [],
        serviceType: [],
        merchantCategory: [],
        limitType: [],
        registerPlatform: [],
        platformUsing: [],
        voucherApplyTo: [],
        pointApplyTo: []

      },
      message: null
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
