import { i18nHelper } from '@/core/utils';
import { apiService } from '@/core/services';

import {
  CreatorApplicationStatus2,
  IsReadyToType,
  YesNo,
  RecipeBaseType,
  BannerMediaType,
  ActiveStatus,
  MemberType,
  RecipeStatus,
  ReferalCodeStatus,
  CreatorType,
  ReferralCodeRewardType,
  ReferralCodeVoucherType,
  PointType,
  PointStatus,
  PointEventType,
  PointEarnType,
  AmountType,
  SortOrderType,
  CuisineType,
  StudioBookingStatus,
  LimitType,
  VehicleType,
  RegisterPlatform
} from '@/core/constants/enums';

export const listService = {
  getCreatorApplicationStatusList,
  getRecipeIsReadyToList,
  getDynamicList,
  getYesNoList,
  getRecipeBaseTypeList,
  getBannerMediaTypeList,
  getStatusList,
  getMemberTypeList,
  getRecipeStatusList,
  getBannerActionTypeList,
  getCreatorId,
  getReferralCodeStatusList,
  getReferralCodeRewrdTypeList,
  getReferralCodeVoucherTypeList,
  getAmoutTypeList,
  getCreatorTypeList,
  getSortOrderList,
  getOffileMerchantCuisineTypeList,
  getVoucherStatusList,
  getVoucherTypeList,
  getPointStatusList,
  getPointTypeList,
  getPointEarnTypeList,
  getPointEventTypeList,
  getStudioBookingStatusList,
  getsellingQuantityList,
  getVehicleTypeList,
  getRegisterPlatform
};

function getCreatorId() {
  let list = [];
  let result = null;
  let requestOptions = {
    params: {
      status: 'approved',
      per_page: 9999,
      creator_status: 1
    }
  };
  apiService()
    .get(`admin/creator-applications`, requestOptions)
    .then((response) => {
      result = response.data;

      for (var i = 0; i < result.length; ++i) {
        list.push({ value: result[i].id, label: result[i].store_name });
      }
    });
  return list;
}

function getDynamicList(originList, hasOptionAll = false) {
  let list = [];

  if (hasOptionAll) {
    list.push({
      text: i18nHelper.getMessage(`label.all`),
      value: ''
    });
  }

  originList.forEach((x) => {
    list.push({
      text: x.description,
      value: x.value
    });
  });
  return list;
}

function getCreatorApplicationStatusList(hasOptionAll = true) {
  let list = [];
  let status = CreatorApplicationStatus2;

  if (hasOptionAll) {
    list.push({
      text: i18nHelper.getMessage(`label.all`),
      value: ''
    });
  }

  list.push({
    text: i18nHelper.getMessage(
      `enumCreatorApplicationStatus[2].${status.DOCUMENT_PENDING}`
    ),
    value: status.DOCUMENT_PENDING
  });

  list.push({
    text: i18nHelper.getMessage(
      `enumCreatorApplicationStatus[2].${status.PENDING_APPROVED}`
    ),
    value: status.PENDING_APPROVED
  });

  list.push({
    text: i18nHelper.getMessage(
      `enumCreatorApplicationStatus[2].${status.APPROVED}`
    ),
    value: status.APPROVED
  });

  list.push({
    text: i18nHelper.getMessage(
      `enumCreatorApplicationStatus[2].${status.REJECTED}`
    ),
    value: status.REJECTED
  });

  return list;
}

function getRecipeIsReadyToList(hasOptionAll = true) {
  let list = [];

  if (hasOptionAll) {
    list.push({
      text: i18nHelper.getMessage(`label.all`),
      value: ''
    });
  }

  list.push({
    text: i18nHelper.getMessage(`enumIsReadyTo.${IsReadyToType.EAT}`),
    value: IsReadyToType.EAT
  });

  list.push({
    text: i18nHelper.getMessage(`enumIsReadyTo.${IsReadyToType.COOK}`),
    value: IsReadyToType.COOK
  });

  return list;
}

function getYesNoList(hasOptionAll = true) {
  let list = [];

  if (hasOptionAll) {
    list.push({
      text: i18nHelper.getMessage(`label.all`),
      value: ''
    });
  }

  list.push({
    text: i18nHelper.getMessage(`enumYesNo.${YesNo.YES}`),
    value: YesNo.YES
  });

  list.push({
    text: i18nHelper.getMessage(`enumYesNo.${YesNo.NO}`),
    value: YesNo.NO
  });

  return list;
}

function getRecipeBaseTypeList(hasOptionAll = true) {
  let list = [];

  if (hasOptionAll) {
    list.push({
      text: i18nHelper.getMessage(`label.all`),
      value: ''
    });
  }

  list.push({
    text: i18nHelper.getMessage(`enumRecipeBaseType.${RecipeBaseType.POPULAR}`),
    value: RecipeBaseType.POPULAR
  });

  list.push({
    text: i18nHelper.getMessage(
      `enumRecipeBaseType.${RecipeBaseType.RECOMMENDED}`
    ),
    value: RecipeBaseType.RECOMMENDED
  });

  list.push({
    text: i18nHelper.getMessage(`enumRecipeBaseType.${RecipeBaseType.RATED}`),
    value: RecipeBaseType.RATED
  });

  return list;
}

function getBannerMediaTypeList(hasOptionAll = true) {
  let list = [];

  if (hasOptionAll) {
    list.push({
      text: i18nHelper.getMessage(`label.all`),
      value: ''
    });
  }

  list.push({
    text: i18nHelper.getMessage(`enumBannerMediaType.${BannerMediaType.IMAGE}`),
    value: BannerMediaType.IMAGE
  });

  list.push({
    text: i18nHelper.getMessage(`enumBannerMediaType.${BannerMediaType.VIDEO}`),
    value: BannerMediaType.VIDEO
  });

  return list;
}

function getStatusList() {
  let list = [];

  list.push({
    text: i18nHelper.getMessage(
      `enumActivationStatus.${Number(ActiveStatus.ACTIVE)}`
    ),
    value: Number(ActiveStatus.ACTIVE)
  });

  list.push({
    text: i18nHelper.getMessage(
      `enumActivationStatus.${Number(ActiveStatus.INACTIVE)}`
    ),
    value: Number(ActiveStatus.INACTIVE)
  });

  return list;
}

function getSortOrderList() {
  let list = [];
  list.push({
    text: i18nHelper.getMessage(`enumSortOrderType.${SortOrderType.ASC}`),
    value: SortOrderType.ASC
  });

  list.push({
    text: i18nHelper.getMessage(`enumSortOrderType.${SortOrderType.DESC}`),
    value: SortOrderType.DESC
  });

  return list;
}

function getReferralCodeStatusList() {
  let list = [];

  list.push({
    text: i18nHelper.getMessage(
      `enumReferralCodeStatus.${Number(ReferalCodeStatus.ACTIVE)}`
    ),
    value: Number(ReferalCodeStatus.ACTIVE)
  });

  list.push({
    text: i18nHelper.getMessage(
      `enumReferralCodeStatus.${Number(ReferalCodeStatus.INACTIVE)}`
    ),
    value: Number(ReferalCodeStatus.INACTIVE)
  });

  list.push({
    text: i18nHelper.getMessage(
      `enumReferralCodeStatus.${Number(ReferalCodeStatus.EXPIRED)}`
    ),
    value: Number(ReferalCodeStatus.EXPIRED)
  });

  return list;
}

function getVoucherStatusList() {
  let list = [];

  list.push({
    text: i18nHelper.getMessage(
      `enumVoucherStatus.${Number(ReferalCodeStatus.ACTIVE)}`
    ),
    value: Number(ReferalCodeStatus.ACTIVE)
  });

  list.push({
    text: i18nHelper.getMessage(
      `enumVoucherStatus.${Number(ReferalCodeStatus.INACTIVE)}`
    ),
    value: Number(ReferalCodeStatus.INACTIVE)
  });

  list.push({
    text: i18nHelper.getMessage(
      `enumVoucherStatus.${Number(ReferalCodeStatus.EXPIRED)}`
    ),
    value: Number(ReferalCodeStatus.EXPIRED)
  });

  return list;
}

function getMemberTypeList() {
  let list = [];

  list.push({
    text: i18nHelper.getMessage(`enumMemberType.${MemberType.MEMBER}`),
    value: MemberType.MEMBER
  });

  list.push({
    text: i18nHelper.getMessage(`enumMemberType.${MemberType.CREATOR}`),
    value: MemberType.CREATOR
  });

  return list;
}

function getReferralCodeRewrdTypeList() {
  let list = [];

  // list.push({
  //   text: i18nHelper.getMessage(
  //     `enumReferralCodeRewrdType.${ReferralCodeRewardType.COOKIES}`
  //   ),
  //   value: ReferralCodeRewardType.COOKIES
  // });

  list.push({
    text: i18nHelper.getMessage(
      `enumReferralCodeRewrdType.${ReferralCodeRewardType.VOUCHER}`
    ),
    value: ReferralCodeRewardType.VOUCHER
  });

  return list;
}

function getReferralCodeVoucherTypeList() {
  let list = [];

  list.push({
    text: i18nHelper.getMessage(
      `enumReferralCodeVoucherType.${ReferralCodeVoucherType.DINEIN}`
    ),
    value: ReferralCodeVoucherType.DINEIN
  });

  list.push({
    text: i18nHelper.getMessage(
      `enumReferralCodeVoucherType.${ReferralCodeVoucherType.DELIVERY_OR_PICKUP}`
    ),
    value: ReferralCodeVoucherType.DELIVERY_OR_PICKUP
  });

  return list;
}
function getVoucherTypeList() {
  let list = [];

  list.push({
    text: i18nHelper.getMessage(
      `enumReferralCodeVoucherType.${ReferralCodeVoucherType.DINEIN}`
    ),
    value: ReferralCodeVoucherType.DINEIN
  });

  list.push({
    text: i18nHelper.getMessage(
      `enumReferralCodeVoucherType.${ReferralCodeVoucherType.DELIVERY_OR_PICKUP}`
    ),
    value: ReferralCodeVoucherType.DELIVERY_OR_PICKUP
  });

  return list;
}

function getPointStatusList() {
  let list = [];

  list.push({
    text: i18nHelper.getMessage(`enumPointStatus.${PointStatus.ACTIVE}`),
    value: PointStatus.ACTIVE
  });

  list.push({
    text: i18nHelper.getMessage(`enumPointStatus.${PointStatus.INACTIVE}`),
    value: PointStatus.INACTIVE
  });

  list.push({
    text: i18nHelper.getMessage(`enumPointStatus.${PointStatus.EXPIRED}`),
    value: PointStatus.EXPIRED
  });

  return list;
}

function getPointTypeList() {
  let list = [];

  list.push({
    text: i18nHelper.getMessage(`enumPointType.${PointType.BEACON}`),
    value: PointType.BEACON
  });

  list.push({
    text: i18nHelper.getMessage(`enumPointType.${PointType.PROMOTION}`),
    value: PointType.PROMOTION
  });

  return list;
}

function getPointEventTypeList() {
  let list = [];

  list.push({
    text: i18nHelper.getMessage(
      `enumPointEventType.${PointEventType.CHECKOUT}`
    ),
    value: PointEventType.CHECKOUT
  });

  list.push({
    text: i18nHelper.getMessage(
      `enumPointEventType.${PointEventType.REGISTER}`
    ),
    value: PointEventType.REGISTER
  });

  return list;
}

function getPointEarnTypeList() {
  let list = [];

  list.push({
    text: i18nHelper.getMessage(
      `enumPointEarnType.${PointEarnType.PERCENTAGE}`
    ),
    value: PointEarnType.PERCENTAGE
  });

  list.push({
    text: i18nHelper.getMessage(
      `enumPointEarnType.${PointEarnType.POINT_VALUE}`
    ),
    value: PointEarnType.POINT_VALUE
  });

  return list;
}

function getAmoutTypeList() {
  let list = [];

  list.push({
    text: i18nHelper.getMessage(`enumAmountType.${AmountType.FIXAMOUNT}`),
    value: AmountType.FIXAMOUNT
  });

  list.push({
    text: i18nHelper.getMessage(`enumAmountType.${AmountType.PERCENTAGE}`),
    value: AmountType.PERCENTAGE
  });

  return list;
}

function getRecipeStatusList() {
  let list = [];

  list.push({
    text: i18nHelper.getMessage(`enumRecipeStatus.${RecipeStatus.PENDING}`),
    value: RecipeStatus.PENDING
  });

  list.push({
    text: i18nHelper.getMessage(`enumRecipeStatus.${RecipeStatus.VERIFIED}`),
    value: RecipeStatus.VERIFIED
  });

  list.push({
    text: i18nHelper.getMessage(`enumRecipeStatus.${RecipeStatus.DELETED}`),
    value: RecipeStatus.DELETED
  });

  return list;
}

function getStudioBookingStatusList() {
  let list = [];

  list.push({
    text: i18nHelper.getMessage(
      `enumStudioBookingStatus.${StudioBookingStatus.PENDING}`
    ),
    value: StudioBookingStatus.PENDING
  });

  list.push({
    text: i18nHelper.getMessage(
      `enumStudioBookingStatus.${StudioBookingStatus.CONFIRMED}`
    ),
    value: StudioBookingStatus.CONFIRMED
  });

  list.push({
    text: i18nHelper.getMessage(
      `enumStudioBookingStatus.${StudioBookingStatus.CANCELLER}`
    ),
    value: StudioBookingStatus.CANCELLER
  });
  return list;
}

function getBannerActionTypeList(actions) {
  let list = [];

  actions.forEach((x) => {
    list.push({
      text: i18nHelper.getMessage(`enumBannerAction.${x.value}`),
      value: x.value
    });
  });

  return list;
}

function getCreatorTypeList() {
  let list = [];
  Object.entries(CreatorType).forEach((x) => {
    list.push({
      text: i18nHelper.getMessage(`enumCreatorType.${x[1]}`),
      value: x[1]
    });
  });
  return list;
}

function getOffileMerchantCuisineTypeList() {
  CuisineType;
  let list = [];
  list.push({
    text: i18nHelper.getMessage(`enumCuisineType.${CuisineType.ASIAN}`),
    value: CuisineType.ASIAN
  });

  list.push({
    text: i18nHelper.getMessage(`enumCuisineType.${CuisineType.WESTERN}`),
    value: CuisineType.WESTERN
  });

  return list;
}

function getsellingQuantityList() {
  let list = [];
  list.push({
    text: i18nHelper.getMessage(`enumLimitType.${LimitType.DAILY}`),
    value: LimitType.DAILY
  });

  list.push({
    text: i18nHelper.getMessage(`enumLimitType.${LimitType.WEEKLY}`),
    value: LimitType.WEEKLY
  });

  return list;
}
function getVehicleTypeList() {
  let list = [];
  list.push({
    text: i18nHelper.getMessage(`enumVehicleType.${VehicleType.ANY_VEHICLE}`),
    value: VehicleType.ANY_VEHICLE
  });

  list.push({
    text: i18nHelper.getMessage(`enumVehicleType.${VehicleType.CAR}`),
    value: VehicleType.CAR
  });

  return list;
}

function getRegisterPlatform() {
  let list = [];
  list.push({
    text: i18nHelper.getMessage(
      `enumRegisterPlatform.${RegisterPlatform.COOKX}`
    ),
    value: RegisterPlatform.COOKX
  });

  list.push({
    text: i18nHelper.getMessage(
      `enumRegisterPlatform.${RegisterPlatform.MAHSING}`
    ),
    value: RegisterPlatform.MAHSING
  });

  return list;
}
