import { permissionHelper } from '@/core/utils';
import { ROUTE_NAME, MODULE } from '@/core/constants';
import { ASIDE_MENU_CATEGORY } from '@/core/constants/enums';
export const accessRightsService = {
  checkUserAccessRights,
  authorizedNavigationList
};

const navigationList = [
  //************************************* HOME *************************************//
  // Home ::Start
  {
    path: '/home',
    routeName: ROUTE_NAME.HOME,
    name: 'home',
    isActive: true,
    icon: 'flaticon-home-2',
    category: ASIDE_MENU_CATEGORY.MAIN,
    authorizeModules: [],
    children: []
  },
  // Home ::End
  //************************************* HOME *************************************//

  //************************************* MEMBER *************************************//
  // Profile Details ::Start
  {
    path: '/profile-details',
    routeName: ROUTE_NAME.PROFILE_DETAILS,
    name: 'profileDetails',
    isActive: false,
    icon: 'flaticon2-avatar',
    category: ASIDE_MENU_CATEGORY.MEMBER,
    authorizeModules: [MODULE.USER],
    children: []
  },
  // Profile Details ::End
  // Application ::Start
  {
    path: '/application',
    routeName: ROUTE_NAME.CREATOR_APPLICATION,
    name: 'application',
    isActive: false,
    icon: 'flaticon2-sheet',
    notification: 'application',
    category: ASIDE_MENU_CATEGORY.MEMBER,
    authorizeModules: [MODULE.CREATOR_APPLICATION],
    children: []
  },
  // Application ::End
  // Newsletter Subscribers ::Start
  {
    path: '/newsletter-subscribers',
    routeName: ROUTE_NAME.SUBSCRIPTION,
    name: 'newsletterSubscribers',
    isActive: false,
    icon: 'flaticon-black',
    notification: 'subscription',
    category: ASIDE_MENU_CATEGORY.MEMBER,
    authorizeModules: [MODULE.CREATOR_APPLICATION],
    children: []
  },
  // Newsletter Subscribers ::End
  //************************************* MEMBER *************************************//

  //************************************* MERCHANT *************************************//
  // Merchant Grouping ::Start
  {
    path: '/merchant-grouping',
    routeName: ROUTE_NAME.MERCHANT_GROUPING,
    name: 'merchantGrouping',
    isActive: false,
    icon: 'fas fa-building',
    notification: 'merchantGrouping',
    category: ASIDE_MENU_CATEGORY.MERCHANT,
    authorizeModules: [MODULE.MERCHANT_GROUPING],
    children: []
  },
  // Merchant Grouping ::End
  // Offline Merchant ::Start
  {
    path: '/offline-merchant',
    routeName: ROUTE_NAME.OFFLINE_MERCHANT,
    name: 'offlineMerchant',
    isActive: false,
    icon: 'fas fa-store-alt',
    notification: 'offlineMerchant',
    category: ASIDE_MENU_CATEGORY.MERCHANT,
    authorizeModules: [MODULE.OFFLINE_MERCHANT],
    children: []
  },
  // Offline Merchant ::End
  // Popular Search ::Start
  {
    path: '/popular-search',
    routeName: ROUTE_NAME.POPULAR_SEARCH,
    name: 'popularSearch',
    isActive: false,
    icon: 'fas fa-fire',
    notification: 'popularSearch',
    category: ASIDE_MENU_CATEGORY.MERCHANT,
    authorizeModules: [MODULE.POPULAR_SEARCH],
    children: []
  },
  // Popular Search ::End
  //************************************* MERCHANT *************************************//

  //************************************* PURCHASE *************************************//
  // Eco-Pack Fulfil By CookX ::Start
  {
    path: '/eco-pack-fulfil',
    routeName: ROUTE_NAME.ECOPACK,
    name: 'ecoPackFulfil',
    isActive: false,
    icon: 'fas fa-archive',
    notification: 'ecoPackFulfil',
    category: ASIDE_MENU_CATEGORY.PURCHASE,
    authorizeModules: [MODULE.ORDERS],
    children: []
  },
  // Eco-Pack Fulfil By CookX ::End
  // Equipmennt ::Start
  {
    path: '/equipment',
    routeName: ROUTE_NAME.EQUIPMENT,
    name: 'equipment',
    isActive: false,
    icon: 'fas fa-wine-glass-alt',
    notification: 'equipment',
    category: ASIDE_MENU_CATEGORY.PURCHASE,
    authorizeModules: [MODULE.EQUIPMENTS],
    children: []
  },
  // Equipmennt ::End
  // Order ::Start
  {
    path: '/orders',
    routeName: ROUTE_NAME.ORDERS,
    name: 'orders',
    isActive: false,
    icon: 'fas fa-truck',
    notification: 'ordersBadge',
    type: 'orders',
    category: ASIDE_MENU_CATEGORY.PURCHASE,
    authorizeModules: [MODULE.ORDERS],
    children: [
      {
        path: 'all',
        routeName: ROUTE_NAME,
        name: 'all',
        isActive: false,
        icon: 'fas fa-truck',
        notification: 'all',
        authorizeModules: [MODULE.ORDERS],
        children: []
      },
      {
        path: 'order-eco-pack-fulfil',
        routeName: ROUTE_NAME.ORDERS_ECO_PACK_FULFIL,
        name: 'orderecoPackFulfil',
        isActive: false,
        icon: 'fas fa-truck',
        notification: 'ecoPack',
        authorizeModules: [MODULE.ORDERS],
        children: []
      },
      {
        path: 'xchefs',
        routeName: ROUTE_NAME.ORDERS_XCHEFS,
        name: 'xChefs',
        isActive: false,
        icon: 'fas fa-truck',
        notification: 'xChefs',
        authorizeModules: [MODULE.ORDERS],
        children: []
      }
    ]
  },
  // Order ::End
  //************************************* PURCHASE *************************************//

  //************************************* POST *************************************//
  // Experience Cooking (Recipe) ::Start
  {
    path: '/experience-cooking',
    routeName: ROUTE_NAME.EXPERIENCE_COOKING,
    name: 'experienceCooking',
    isActive: false,
    icon: 'fas fa-mitten',
    notification: 'recipes',
    category: ASIDE_MENU_CATEGORY.POST,
    authorizeModules: [MODULE.RECIPE],
    children: [
      {
        path: 'all',
        routeName: ROUTE_NAME.EXPERIENCE_COOKING,
        name: 'all',
        isActive: false,
        icon: 'fas fa-mitten',
        notification: '',
        category: ASIDE_MENU_CATEGORY.POST,
        authorizeModules: [MODULE.RECIPE],
        children: []
      },
      {
        path: 'trending',
        routeName: ROUTE_NAME,
        name: 'trending',
        isActive: false,
        authorizeModules: [MODULE.RECIPE],
        children: []
      },
      {
        path: 'bakery',
        routeName: ROUTE_NAME,
        name: 'bakery',
        isActive: false,
        authorizeModules: [MODULE.RECIPE],
        children: []
      },
      {
        path: 'quick&Easy',
        routeName: ROUTE_NAME,
        name: 'quick&easy',
        isActive: false,
        authorizeModules: [MODULE.RECIPE],
        children: []
      },
      {
        path: 'cook-like-and-expert',
        routeName: ROUTE_NAME,
        name: 'cooklikeandexpert',
        isActive: false,
        authorizeModules: [MODULE.RECIPE],
        children: []
      }
    ]
  },
  // Experience Cooking (Recipe) ::End
  // Pre-Order Food (Product) ::Start
  {
    path: '/pre-order-food',
    routeName: ROUTE_NAME.PRE_ORDER_FOOD,
    name: 'preOrderFood',
    isActive: false,
    icon: 'flaticon2-box-1',
    category: ASIDE_MENU_CATEGORY.POST,
    authorizeModules: [MODULE.PRODUCT],
    children: [
      {
        path: 'all',
        routeName: ROUTE_NAME,
        name: 'all',
        isActive: false,
        authorizeModules: [MODULE.PRODUCT],
        children: []
      },
      {
        path: 'trending',
        routeName: ROUTE_NAME,
        name: 'trending',
        isActive: false,
        authorizeModules: [MODULE.PRODUCT],
        children: []
      },
      {
        path: 'something-sweet',
        routeName: ROUTE_NAME,
        name: 'somethingsweet',
        isActive: false,
        authorizeModules: [MODULE.PRODUCT],
        children: []
      },
      {
        path: 'asia',
        routeName: ROUTE_NAME,
        name: 'asia',
        isActive: false,
        authorizeModules: [MODULE.PRODUCT],
        children: []
      },
      {
        path: 'western',
        routeName: ROUTE_NAME,
        name: 'western',
        isActive: false,
        authorizeModules: [MODULE.PRODUCT],
        children: []
      }
    ]
  },
  // Pre-Order Food (Product) ::End
  // Bulletin ::Start
  {
    path: '/xstreet',
    routeName: ROUTE_NAME.XSTREET,
    name: 'bulletin',
    isActive: false,
    icon: 'fas fa-city',
    category: ASIDE_MENU_CATEGORY.POST,
    authorizeModules: [MODULE.XSTREET],
    children: []
  },
  // Bulletin ::End
  // Review and Rating ::Start
  {
    path: '/review-and-rating',
    routeName: ROUTE_NAME.REVIEW_AND_RATING,
    name: 'reviewAndRating',
    isActive: false,
    icon: 'fas fa-star',
    category: ASIDE_MENU_CATEGORY.POST,
    authorizeModules: [MODULE.RECIPE_REVIEW, MODULE.PRODUCT_REVIEW],
    children: [
      {
        path: 'experience-cooking',
        routeName: ROUTE_NAME.REVIEW_AND_RATING_EXPERIENCE_COOKING,
        name: 'experienceCooking',
        isActive: false,
        authorizeModules: [MODULE.RECIPE_REVIEW],
        children: []
      },
      {
        path: 'pre-order-food',
        routeName: ROUTE_NAME.REVIEW_AND_RATING_PRE_ORDER_FOOD,
        name: 'preOrderFood',
        isActive: false,
        authorizeModules: [MODULE.PRODUCT_REVIEW],
        children: []
      }
    ]
  },
  // Review and Rating ::End
  //************************************* POST *************************************//

  //************************************* COMPANY WEBSITE *************************************//
  // Studio ::Start
  {
    path: '/studio',
    routeName: ROUTE_NAME.STUDIO,
    name: 'studio',
    isActive: false,
    icon: 'fas fa-store-alt',
    category: ASIDE_MENU_CATEGORY.COMPANY_WEBSITE,
    authorizeModules: [MODULE.STUDIO],
    children: [
      {
        path: 'studio-booking',
        routeName: ROUTE_NAME.STUDIO_BOOKING,
        name: 'studioBooking',
        isActive: false,
        authorizeModules: [MODULE.STUDIO],
        children: []
      },
      {
        path: 'studio-calendar',
        routeName: ROUTE_NAME.STUDIO_CALENDAR,
        name: 'studioCalendar',
        isActive: false,
        authorizeModules: [MODULE.STUDIO],
        children: []
      }
    ]
  },
  // Studio ::End
  // Recipe Page ::Start
  {
    path: '/recipe-page',
    routeName: ROUTE_NAME.RECIPE_PAGE,
    name: 'recipePage',
    isActive: false,
    icon: 'fas fa-utensils',
    category: ASIDE_MENU_CATEGORY.COMPANY_WEBSITE,
    authorizeModules: [MODULE.RECIPE_PAGE],
    children: [
      {
        path: 'bakery',
        routeName: ROUTE_NAME.RECIPE_PAGE_BAKERY,
        name: 'bakery',
        isActive: false,
        authorizeModules: [MODULE.RECIPE_PAGE],
        children: []
      },
      {
        path: 'asian',
        routeName: ROUTE_NAME.RECIPE_PAGE_ASIAN,
        name: 'asian',
        isActive: false,
        authorizeModules: [MODULE.RECIPE_PAGE],
        children: []
      },
      {
        path: 'western',
        routeName: ROUTE_NAME.RECIPE_PAGE_WESTERN,
        name: 'western',
        isActive: false,
        authorizeModules: [MODULE.RECIPE_PAGE],
        children: []
      }
    ]
  },
  // Recipe Page ::End
  //************************************* COMPANY WEBSITE *************************************//

  //************************************* MOBILE APPLICATION *************************************//
  // All Videos ::Start
  {
    path: '/all-videos',
    routeName: ROUTE_NAME.ALL_VIDEOS,
    name: 'allVideos',
    isActive: false,
    icon: 'fas fa-store-alt',
    category: ASIDE_MENU_CATEGORY.MOBILE_APPLICATION,
    authorizeModules: [MODULE.MOBILE_APPLICATION],
    children: [
      {
        path: 'videos-experience-cooking',
        routeName: ROUTE_NAME.ALL_VIDEOS_EXPERIENCE_COOKING,
        name: 'experienceCooking',
        isActive: false,
        authorizeModules: [MODULE.MOBILE_APPLICATION],
        children: []
      },
      {
        path: 'videos-pre-order-food',
        routeName: ROUTE_NAME.ALL_VIDEOS_PRE_ORDER_FOOD,
        name: 'preOrderFood',
        isActive: false,
        authorizeModules: [MODULE.MOBILE_APPLICATION],
        children: []
      }
    ]
  },
  // All Videos ::End
  // Eco-Pack ::Start
  {
    path: '/eco-pack',
    routeName: ROUTE_NAME.ECO_PACK,
    name: 'ecoPack',
    isActive: false,
    icon: 'fas fa-asterisk',
    category: ASIDE_MENU_CATEGORY.MOBILE_APPLICATION,
    authorizeModules: [MODULE.MOBILE_APPLICATION],
    children: []
  },
  // Eco-Pack ::End

  // Merchant ::Start
  {
    path: '/merchant',
    routeName: ROUTE_NAME.MERCHANT,
    name: 'merchant',
    isActive: false,
    icon: 'fas fa-utensils',
    category: ASIDE_MENU_CATEGORY.MOBILE_APPLICATION,
    authorizeModules: [MODULE.MOBILE_APPLICATION],
    children: [
      {
        path: 'all',
        routeName: ROUTE_NAME.MERCHANT_ALL,
        name: 'all',
        isActive: false,
        authorizeModules: [MODULE.MOBILE_APPLICATION],
        children: []
      },
      {
        path: 'dine-in',
        routeName: ROUTE_NAME.MERCHANT_DINE_IN,
        name: 'dineIn',
        isActive: false,
        authorizeModules: [MODULE.MOBILE_APPLICATION],
        children: []
      },
      {
        path: 'online',
        routeName: ROUTE_NAME.MERCHANT_ONLINE,
        name: 'online',
        isActive: false,
        authorizeModules: [MODULE.MOBILE_APPLICATION],
        children: []
      }
    ]
  },
  // Merchant ::End
  //************************************* MOBILE APPLICATION *************************************//

  //************************************* PROMOTION *************************************//
  // Promotion ::Start
  {
    path: '/promotion',
    routeName: ROUTE_NAME.PROMOTION,
    name: 'promotion',
    isActive: false,
    icon: 'fas fa-images',
    category: ASIDE_MENU_CATEGORY.PROMOTION,
    authorizeModules: [MODULE.REFERRAL_CODE],
    children: [
      {
        path: 'recipes-delivery',
        routeName: ROUTE_NAME.PROMOTION_RECIPES_DELIVERY_LIST,
        name: 'recipesDelivery',
        isActive: false,
        authorizeModules: [MODULE.REFERRAL_CODE],
        children: []
      },
      {
        path: 'product-delivery',
        routeName: ROUTE_NAME.PROMOTION_PRODUCT_DELIVERY_LIST,
        name: 'productDelivery',
        isActive: false,
        authorizeModules: [MODULE.REFERRAL_CODE],
        children: []
      }
    ]
  },
  // Promotion ::End
  // Referral Voucher ::Start
  {
    path: '/referral-code',
    routeName: ROUTE_NAME.REFERRAL_CODE,
    name: 'referralVoucher',
    isActive: false,
    icon: 'fas fa-asterisk',
    category: ASIDE_MENU_CATEGORY.PROMOTION,
    authorizeModules: [MODULE.REFERRAL_CODE],
    children: []
  },
  // Referral Voucher ::End
  // Voucher ::Start
  {
    path: '/voucher',
    routeName: ROUTE_NAME.VOUCHER,
    name: 'voucher',
    isActive: false,
    icon: 'fas fa-percentage',
    category: ASIDE_MENU_CATEGORY.PROMOTION,
    authorizeModules: [MODULE.VOUCHER],
    children: []
  },
  // Voucher ::End
  // Points ::Start
  {
    path: '/point',
    routeName: ROUTE_NAME.POINT,
    name: 'point',
    isActive: false,
    icon: 'fas fa-certificate',
    category: ASIDE_MENU_CATEGORY.PROMOTION,
    authorizeModules: [MODULE.POINT],
    children: []
  },
  // Voucher ::End
  //************************************* PROMOTION *************************************//

  //************************************* TRANSACTIONS *************************************//
  // Finance Reports ::Start
  {
    path: '/transactions/finance-reports',
    routeName: ROUTE_NAME.FINANCE_REPORTS,
    name: 'financeReport',
    isActive: false,
    icon: 'far fa-file-alt',
    category: ASIDE_MENU_CATEGORY.TRANSACTIONS,
    authorizeModules: [MODULE.ORDERS],
    children: []
  },
  // Finance Reports ::End
  //************************************* TRANSACTIONS *************************************//

  //************************************* CONTENT MANAGEMENT *************************************//
  // Banners ::Start
  {
    path: '/banners',
    routeName: ROUTE_NAME.BANNER,
    name: 'banners',
    isActive: false,
    icon: 'fas fa-images',
    category: ASIDE_MENU_CATEGORY.CONTENT_MANAGEMENT,
    authorizeModules: [MODULE.BANNER],
    children: [
      {
        path: 'home',
        routeName: ROUTE_NAME.BANNER_HOME,
        name: 'home',
        isActive: false,
        authorizeModules: [MODULE.BANNER],
        children: []
      },
      {
        path: 'discover',
        routeName: ROUTE_NAME.BANNER_DISCOVER,
        name: 'discover',
        isActive: false,
        authorizeModules: [MODULE.BANNER],
        children: []
      },
      {
        path: 'xStreet',
        routeName: ROUTE_NAME.BANNER_X_STREET,
        name: 'bulletin',
        isActive: false,
        authorizeModules: [MODULE.BANNER],
        children: []
      }
    ]
  },
  // Banners ::End
  // Home Page Button ::Start
  {
    path: '/home-page-button',
    routeName: ROUTE_NAME.HOME_PAGE_BUTTON,
    name: 'homePageButton',
    isActive: false,
    icon: 'fas fa-images',
    category: ASIDE_MENU_CATEGORY.CONTENT_MANAGEMENT,
    authorizeModules: [MODULE.PLATFORM_INFO],
    children: [
      {
        path: 'home-experience-cooking',
        routeName: ROUTE_NAME.HOME_PAGE_EXPERIENCEE,
        name: 'experienceCooking',
        isActive: false,
        authorizeModules: [MODULE.PLATFORM_INFO],
        children: []
      },
      {
        path: 'home-pre-order-food',
        routeName: ROUTE_NAME.HOME_PAGE_PREORDER,
        name: 'preOrderFood',
        isActive: false,
        authorizeModules: [MODULE.PLATFORM_INFO],
        children: []
      },
      {
        path: 'home-dine-in',
        routeName: ROUTE_NAME.HOME_DINE_IN,
        name: 'dineIn',
        isActive: false,
        authorizeModules: [MODULE.PLATFORM_INFO],
        children: []
      }
    ]
  },
  // Home Page Button ::End
  // Platform Info ::Start
  {
    path: '/platform-info',
    routeName: ROUTE_NAME.PLATFORM_INFO,
    name: 'platformInfo',
    isActive: false,
    icon: 'fas fa-info-circle',
    category: ASIDE_MENU_CATEGORY.CONTENT_MANAGEMENT,
    authorizeModules: [MODULE.PLATFORM_INFO],
    children: []
  },
  // Platform Info ::End
  //************************************* CONTENT MANAGEMENT *************************************//

  //************************************* ADMIN USER *************************************//
  // Account Management ::Start
  {
    path: '/account-management',
    routeName: ROUTE_NAME.ACCOUNT_MANAGEMENT,
    name: 'accountManagement',
    isActive: false,
    icon: 'flaticon2-user',
    category: ASIDE_MENU_CATEGORY.ADMIN_USER,
    authorizeModules: [MODULE.ADMIN_USER],
    children: []
  },
  // Account Management ::End
  // Role Permission ::Start
  {
    path: '/role-permission',
    routeName: ROUTE_NAME.ROLE_PERMISSION,
    name: 'rolePermission',
    isActive: false,
    icon: 'flaticon2-shield',
    category: ASIDE_MENU_CATEGORY.ADMIN_USER,
    authorizeModules: [MODULE.ROLE],
    children: []
  },
  // Role Permission ::End
  //************************************* ADMIN USER *************************************//

  //************************************* SETTING *************************************//
  // App Update ::Start
  {
    path: '/app-update',
    routeName: ROUTE_NAME.APP_UPDATE,
    name: 'appUpdate',
    isActive: false,
    icon: 'fas fa-mobile-alt',
    category: ASIDE_MENU_CATEGORY.SETTINGS,
    authorizeModules: [MODULE.APP_INFO],
    children: []
  },
  // App Update ::End
  // Maintenance ::Start
  {
    path: '/maintenance',
    routeName: ROUTE_NAME.MAINTENANCE,
    name: 'maintenance',
    isActive: false,
    icon: 'fas fa-tools',
    category: ASIDE_MENU_CATEGORY.SETTINGS,
    authorizeModules: [MODULE.MAINTENANCE_SCHEDULE],
    children: []
  },
  // Maintenance ::End
  // Platform Operation Hour ::Start
  {
    path: '/platform-operation-hour',
    routeName: ROUTE_NAME.PLATFORM_OPERATION_HOUR,
    name: 'platformOperationHour',
    isActive: false,
    icon: 'fas fa-business-time',
    category: ASIDE_MENU_CATEGORY.SETTINGS,
    authorizeModules: [MODULE.PLATFORM_OPERATION_HOUR],
    children: []
  },
  // Platform Operation Hour ::End
  // Off Day Setting ::Start
  {
    path: '/off-day-setting',
    routeName: ROUTE_NAME.OFF_DAY_SETTING,
    name: 'offDaySetting',
    isActive: false,
    icon: 'fas fa-store-slash',
    category: ASIDE_MENU_CATEGORY.SETTINGS,
    authorizeModules: [MODULE.OFF_DAY_SETTING],
    children: []
  },
  // Off Day Setting ::End
  // Configuration ::Start
  {
    path: '/configuration',
    routeName: ROUTE_NAME.CONFIGURATION,
    name: 'configuration',
    isActive: false,
    icon: 'fas fa-wrench',
    category: ASIDE_MENU_CATEGORY.SETTINGS,
    authorizeModules: [MODULE.CONFIGURATION],
    children: []
  }
  // Configuration ::End
  //************************************* SETTING *************************************//
];

function checkUserAccessRights(moduleName, action) {
  let showAction = false;
  let permissions = permissionHelper.getUserPermissions();
  if (moduleName != '' && action != null && permissions != null) {
    permissions.forEach((x) => {
      if (x.name == moduleName) {
        action.forEach((y) => {
          if (x.actions.find((z) => z.value == y)) {
            showAction = true;
          }
        });
      }
    });
  }
  return showAction;
}

function authorizedNavigationList() {
  let permissions = permissionHelper.getUserPermissions().map((x) => {
    return x.name;
  });
  let newlist = [];
  navigationList.forEach((x) => {
    if (x.name == 'home') newlist.push(x);

    x.authorizeModules.forEach((y) => {
      if (permissions.includes(y)) {
        if (newlist.findIndex((z) => z.name == x.name) == -1) {
          newlist.push(x);
        }
      }
    });
  });

  newlist.forEach((x) => {
    let newChildren = [];
    x.children.forEach((y) => {
      y.authorizeModules.forEach((z) => {
        if (permissions.includes(z)) {
          newChildren.push(y);
        }
      });
    });
    x.children = newChildren;
  });

  return newlist;
}
