<template>
  <div>
    <!-- begin: Vouchers Table Modal -->
    <b-modal
      v-model="ClaimedTableModal"
      :title="$t('label.getVoucher')"
      centered
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      hide-footer
      header-class="text-capitalize"
      size="lg"
    >
      <AppSmartTable
        stickyHeader
        :stacked="false"
        :items="detailsObj.items"
        :headers="detailsObj.header"
        :pagination="detailsObj.pagination"
        @app-smart-table-pagination-change="actionPaginationChange"
      ></AppSmartTable>
      <div class="d-flex justify-content-end align-items-center pt-5">
        <b-button variant="primary" @click="$emit('input', false)">{{
          $t('label.close')
        }}</b-button>
      </div>
    </b-modal>
    <!-- end: Vouchers Table Modal -->
  </div>
</template>

<script>
  import userMixin from '@/core/mixins/user.mixin';
  import AppSmartTable from '@/views/components/bases/smart/AppSmartTable';
  import { ROUTE_NAME } from '@/core/constants';
  import { i18nHelper } from '@/core/utils';
  import commonMixin from '@/core/mixins/common.mixin';
  import {
    OFFLINE_MERCHANT_GET_OFFLINE_MERCHANT_VOUCHERS,
    OFFLINE_MERCHANT_INITIAL_GET_OFFLINE_MERCHANT_VOUCHERS_STATE
  } from '@/core/store/offline-merchant.module';

  export default {
    name: 'OfflineMerchantVoucherList',
    components: {
      AppSmartTable
    },
    mixins: [commonMixin, userMixin],
    props: {
      id: {},
      ClaimedTableModal: {
        TypeError: Boolean,
        default: false
      }
    },
    data: () => ({
      routeName: ROUTE_NAME,
      searchCriteriaListDetails: {
        perPage: 10,
        page: 1
      },
      detailsObj: {
        header: [
          {
            key: 'no',
            name: 'no',
            active: true,
            sortable: true
          },
          {
            key: 'id',
            name: 'id',
            active: true,
            sortable: true
          },
          {
            key: 'voucherTitle',
            name: 'voucherTitle',
            active: true,
            sortable: true,
            class: 'min-w-130px'
            // layout: 'AppFeatureDetailsRelatedButton',
            // config: {
            //   to: ROUTE_NAME.MERCHANT_VOUCHER_LIST
            // }
          },
          {
            key: 'voucherType',
            name: 'voucherType',
            active: true,
            sortable: true
          },
          {
            key: 'quantity',
            name: 'redemptionQuantity',
            active: true,
            sortable: true
          },
          {
            key: 'minimumSpending',
            name: 'minimumSpending',
            active: true,
            sortable: true
          },
          {
            key: 'totalRedemption',
            name: 'totalRedemption',
            active: true,
            sortable: true
          },
          {
            key: 'totalClaim',
            name: 'totalClaim',
            active: true,
            sortable: true
          },
          {
            key: 'status',
            name: 'status',
            active: true,
            sortable: true,
            layout: 'VoucherStatusLabel'
          },
          {
            key: 'availableFrom',
            name: 'availableFrom',
            active: true,
            sortable: true
          },
          {
            key: 'availableTo',
            name: 'availableTo',
            active: true,
            sortable: true
          }
        ],
        items: [],
        pagination: {
          currentPage: 1,
          total: 1,
          perPage: 10,
          lastPage: 1
        }
      },
      disabled: false
    }),
    computed: {
      offlineMerchantComplete() {
        return this.$store.state.offlineMerchant.offlineMerchantVouchers
          .complete;
      },
      route() {
        return this.$route;
      }
    },
    watch: {
      id() {
        if (this.id) {
          this.getListDetails(this.id, this.searchCriteriaListDetails);
        }
      },
      offlineMerchantComplete() {
        let response =
          this.$store.state.offlineMerchant.offlineMerchantVouchers;
        let title = i18nHelper.getMessage('label.getVoucher');
        let initialStateAction =
          OFFLINE_MERCHANT_INITIAL_GET_OFFLINE_MERCHANT_VOUCHERS_STATE;
        let successAction = () => {
          this.importListDetails(response);
        };

        if (response.complete) {
          this.actionReadRecordComplete(
            response,
            title,
            initialStateAction,
            successAction
          );
        }
      },
      route() {
        this.ClaimedTableModal = false;
      }
    },
    mounted() {
      this.getListDetails(this.id, this.searchCriteriaListDetails);
    },
    methods: {
      actionDiscard() {
        this.ClaimedTableModal == false;
      },
      importListDetails(response) {
        this.detailsObj.items = response.data;
        this.detailsObj.pagination = response.pagination;
      },
      actionPaginationChange(value) {
        this.searchCriteriaListDetails = {
          perPage: value.size,
          page: value.page
        };

        this.getListDetails(this.id, this.searchCriteriaListDetails);
      },
      getListDetails(id, data) {
        this.$store.dispatch(OFFLINE_MERCHANT_GET_OFFLINE_MERCHANT_VOUCHERS, {
          id,
          data
        });
      }
    }
  };
</script>

<style></style>
