<template>
  <b-card>
    <b-card-title class="text-capitalize font-weight-bolder">
      {{ $t('label.userVoucher') }}
    </b-card-title>

    <!--begin: App Smart Table-->
    <AppSmartTable
      :items="tableItems"
      :headers="tableHeaders"
      :pagination="tablePagination"
      @app-smart-table-pagination-change="actionPaginationChange"
    ></AppSmartTable>
    <!--end: App Smart Table-->
  </b-card>
</template>

<script>
  import AppSmartTable from '@/views/components/bases/smart/AppSmartTable';
  import commonMixin from '@/core/mixins/common.mixin';
  import { i18nHelper } from '@/core/utils';
  import { GLOBAL, APP_COMPONENT_NAME } from '@/core/constants';
  import {
    USER_GET_USER_VOUCHERS,
    USER_INITIAL_GET_USER_VOUCHERS_STATE
  } from '@/core/store/user.module';

  export default {
    name: 'UserVoucherCard',
    components: {
      AppSmartTable
    },
    mixins: [commonMixin],
    props: {
      id: {
        required: true
      }
    },
    data: () => ({
      searchCriteria: GLOBAL.DEFAULT_SEARCH_CRITERIA,
      tableItems: [],
      tableHeaders: [
        {
          key: 'no',
          name: 'no',
          sortable: true
        },
        {
          key: 'name',
          name: 'name',
          sortable: true,
          class: 'min-w-150px'
        },
        {
          key: 'value',
          name: 'value',
          sortable: true
        },
        {
          key: 'minimumSpending',
          name: 'minimumSpending',
          sortable: true
        },
        {
          key: 'usageLimit',
          name: 'usageLimit',
          sortable: true
        },
        {
          key: 'numberOfUses',
          name: 'numberOfUses',
          sortable: true
        },
        {
          key: 'isActive',
          name: 'isActive',
          sortable: true,
          layout: APP_COMPONENT_NAME.APP_CHECK_CIRCLE
        },
        {
          key: 'awardedAt',
          name: 'awardedAt',
          sortable: true
        },
        {
          key: 'availableFrom',
          name: 'availableFrom',
          sortable: true
        },
        {
          key: 'availableTo',
          name: 'availableTo',
          sortable: true
        }
      ],
      tablePagination: GLOBAL.DEFAULT_TABLE_PAGINATION
    }),
    computed: {
      userVouchersComplete() {
        return this.$store.state.user.userVouchers.complete;
      }
    },
    watch: {
      userVouchersComplete() {
        let response = this.$store.state.user.userVouchers;
        let title = i18nHelper.getMessage('label.getUserVouchers');
        let initialStateAction = USER_INITIAL_GET_USER_VOUCHERS_STATE;
        let successAction = (response) => {
          this.importTableItems(response);
        };

        if (response.complete) {
          this.actionReadRecordComplete(
            response,
            title,
            initialStateAction,
            successAction
          );
        }
      }
    },
    created() {
      this.initUserVoucherCard();
    },
    methods: {
      importTableItems(response) {
        this.tableItems = response.data;
        this.tablePagination = response.pagination;
      },
      actionPaginationChange(value) {
        this.searchCriteria = {
          perPage: value.size,
          page: value.page
        };

        this.getUserVoucher(this.id, this.searchCriteria);
      },
      getUserVoucher(id, data) {
        this.$store.dispatch(USER_GET_USER_VOUCHERS, { id, data });
      },
      initUserVoucherCard() {
        this.getUserVoucher(this.id, this.searchCriteria);
      }
    }
  };
</script>

<style></style>
