<template>
  <div>
    <AppSmartList
      :breadcrumbData="breadcrumbData"
      :accessRights="accessRights"
      :filters="filters"
      :actions="actions"
      :tableHeaders="headers"
      :newRecord="newRecord"
    ></AppSmartList>
  </div>
</template>

<script>
  import AppSmartList from '@/views/components/bases/smart/AppSmartList.vue';
  import {
    ROUTE_NAME,
    MODULE,
    ACTION,
    APP_COMPONENT_NAME
  } from '@/core/constants';
  import { i18nHelper } from '@/core/utils';
  import {
    ROLE_GET_ROLES,
    ROLE_INITIAL_GET_ROLES_STATE
  } from '@/core/store/role.module';

  export default {
    name: 'RolePermissionList',
    components: {
      AppSmartList
    },
    data: () => ({
      breadcrumbData: {
        breadcrumbs: [
          {
            title: i18nHelper.getMessage('label.rolePermission'),
            route: { name: ROUTE_NAME.ROLE_PERMISSION }
          },
          { title: i18nHelper.getMessage('label.rolePermissionList') }
        ]
      },
      accessRights: {
        moduleName: MODULE.ROLE,
        actions: {
          create: ACTION.ROLE_CREATE,
          read: ACTION.ROLE_READ,
          update: ACTION.ROLE_UPDATE,
          delete: ACTION.ROLE_DELETE
        }
      },
      filters: [
        {
          label: 'name',
          key: 'name',
          value: ''
        }
      ],
      actions: {
        viewList: {
          module: 'role',
          state: 'roles',
          action: ROLE_GET_ROLES,
          initialStateAction: ROLE_INITIAL_GET_ROLES_STATE,
          title: i18nHelper.getMessage('label.getRoles')
        }
      },
      headers: [
        {
          key: 'no',
          name: 'no',
          active: true,
          sortable: true
        },
        {
          key: 'name',
          name: 'name',
          active: true,
          sortable: true,
          class: 'min-w-100px'
        },
        {
          key: 'createdAt',
          name: 'createdAt',
          active: true,
          sortable: true
        },
        {
          key: 'updatedAt',
          name: 'updatedAt',
          active: true,
          sortable: true
        },
        {
          key: 'actions',
          name: 'actions',
          active: true,
          action: true,
          layout: APP_COMPONENT_NAME.APP_TABLE_ACTIONS,
          layoutActions: {
            viewDetailsRouteName: ROUTE_NAME.ROLE_PERMISSION_DETAILS
          }
        }
      ],
      newRecord: {
        show: true,
        buttonTitle: i18nHelper.getMessage('label.newRole'),
        routeName: ROUTE_NAME.ROLE_PERMISSION_NEW
      }
    })
  };
</script>

<style></style>
