export const REGEX = Object.freeze({
  address: /^[a-zA-Z0-9.,/\u4e00-\u9eff_]+( [a-zA-Z0-9.,/\u4e00-\u9eff_]+)*$/,
  alphanumeric: /^[a-zA-Z0-9]+$/,
  username: /^[a-zA-Z0-9]+$/,
  password: /^[a-zA-Z0-9]+$/,
  mobileNumberMalaysia: /^[01]{2}[0-9]{8,9}$/,
  mobileNumber: /[0-9]{8,14}$/,
  realname:
    /^[a-zA-Z\u1780-\u17FF\u19E0-\u19FF./]+( [a-zA-Z\u1780-\u17FF\u19E0-\u19FF./]+)*$/,
  invalidSpecialCharacters: /^[^;:：；! @ # $ % & * () <>《》=*]+$/,
  number: /^[\d]+$/,
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  integer: /^[0-9]+(\.[0-9]{0,0})?$/,
  decimalTwo: /^-?[0-9]+(\.[0-9]{0,2})?$/
});
