<template>
  <VoucherDetailsTemplate :isCreate="true"></VoucherDetailsTemplate>
</template>

<script>
  import VoucherDetailsTemplate from '@/views/components/voucher/VoucherDetailsTemplate.vue';

  export default {
    name: 'VoucherCreate',
    components: {
      VoucherDetailsTemplate
    }
  };
</script>

<style></style>
