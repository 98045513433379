export const uiHelper = {
  /* Cookie */
  getCookie,
  setCookie,
  removeCookie,
  /* Local Storage */
  getLocalStorage,
  setLocalStorage,
  removeLocalStorage,
  /* Session Storage */
  getSessionStorage,
  setSessionStorage,
  removeSessionStorage,
  /* Location */
  reloadPage,
  /* Window */
  openPage,
  /* Variable Transform */
  arrayToTrimmedStringWithSpace,
  /* Document */
  freezeBodyOverflowY,
  unFreezeBodyOverflowY,
  /* Other */
  removeObjectWithoutValue,
  getObjectValue,
  getObjectValue2,
  objectValueToString,
  objectIsShallowEqual,
  arrayToObject,
  arrayToObject2
};

const DEFAULT_COOKIE_EXPIRY_DAY = 100;

function getCookie(keyName) {
  let name = keyName + '=';
  let decodedCookie = decodeURIComponent(document.cookie);
  let cookies = decodedCookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];
    while (cookie.charAt(0) == ' ') {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(name) == 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return null;
}

function setCookie(keyName, keyValue, exDays = DEFAULT_COOKIE_EXPIRY_DAY) {
  let date = new Date();
  date.setTime(date.getTime() + exDays * 24 * 60 * 60 * 1000);
  let expires = 'expires=' + date.toUTCString();
  document.cookie = keyName + '=' + keyValue + ';' + expires + ';path=/';
}

function removeCookie(keyName) {
  document.cookie =
    keyName + '= ' + '; expires = Thu, 01 Jan 1970 00:00:00 GMT';
}

function getLocalStorage(keyName) {
  return localStorage.getItem(keyName);
}

function setLocalStorage(keyName, keyValue) {
  localStorage.setItem(keyName, keyValue);
}

function removeLocalStorage(keyName) {
  localStorage.removeItem(keyName);
}

function getSessionStorage(keyName) {
  return sessionStorage.getItem(keyName);
}

function setSessionStorage(keyName, keyValue) {
  sessionStorage.setItem(keyName, keyValue);
}

function removeSessionStorage(keyName) {
  sessionStorage.removeItem(keyName);
}

function reloadPage() {
  location.reload();
}

function openPage(URL, name, specs) {
  window.open(URL, name, specs);
}

function arrayToTrimmedStringWithSpace(array, trimStart) {
  return array.map((x) => x.substring(trimStart)).join(', ');
}

function freezeBodyOverflowY() {
  document.getElementsByTagName('body')['0'].style.overflowY = 'hidden';
}

function unFreezeBodyOverflowY() {
  document.getElementsByTagName('body')['0'].style.overflowY = 'auto';
}

function removeObjectWithoutValue(obj) {
  for (var keyName in obj) {
    if (
      obj[keyName] === null ||
      obj[keyName] === undefined ||
      obj[keyName] === ''
    ) {
      delete obj[keyName];
    }
  }
  return obj;
}

function getObjectValue(obj, property) {
  let value = null;
  if (!['', null, undefined].includes(obj)) {
    value = obj[property];
  }

  return value;
}

function getObjectValue2(obj) {
  let value = null;
  if (!['', null, undefined].includes(obj)) {
    value = obj;
  }

  return value;
}

function objectValueToString(obj) {
  for (var keyName in obj) {
    obj[keyName] = String(obj[keyName]);
  }
  return obj;
}

function objectIsShallowEqual(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (let key of keys1) {
    if (object1[key] !== object2[key]) {
      return false;
    }
  }
  return true;
}

function arrayToObject(array, key, value) {
  let newObject = new Object();
  array.forEach((x) => {
    newObject[x[key]] = x[value];
  });
  return newObject;
}

function arrayToObject2(array, key) {
  let newObject = new Object();
  array.forEach((x) => {
    newObject[x[key]] = x;
  });
  return newObject;
}
