import { apiService } from '@/core/services';
import { apiHelper } from '@/core/utils';

export const RECIPE_REVIEW = {
  getRecipeReviews,
  getRecipeReview,
  setVisibility
};

const URL = {
  ADMIN: 'admin',
  RECIPE_REVIEWS: 'recipe-reviews',
  SET_VISIBLE: 'set-visible'
};

function getRecipeReviews(data) {
  let requestOptions = {
    params: {
      rating: data.rating,
      username: data.username,
      is_visible: data.isVisible,
      item_name: data.itemName,
      created_at_from: apiHelper.dateTimeStart(data.dateRange),
      created_at_to: apiHelper.dateTimeEnd(data.dateRange),
      per_page: data.perPage,
      page: data.page
    }
  };
  return apiService().get(
    `/${URL.ADMIN}/${URL.RECIPE_REVIEWS}`,
    requestOptions
  );
}

function getRecipeReview(id) {
  return apiService().get(`/${URL.ADMIN}/${URL.RECIPE_REVIEWS}/${id}`);
}

function setVisibility(id, data) {
  let requestBody = {
    is_visible: data.isVisible
  };

  return apiService().put(
    `/${URL.ADMIN}/${URL.RECIPE_REVIEWS}/${id}/${URL.SET_VISIBLE}`,
    requestBody
  );
}
