<template>
  <div>
    <AppSingleDisplayFormField
      ref="appSingleDisplayFormField"
      :value="value"
      :permissions="permissions"
      @save="onSave"
    ></AppSingleDisplayFormField>
  </div>
</template>

<script>
  import AppSingleDisplayFormField from '@/views/components/bases/form-field/AppSingleDisplayFormField';
  import commonMixin from '@/core/mixins/common.mixin';
  import { MODULE, ACTION } from '@/core/constants';
  import { i18nHelper } from '@/core/utils';
  import {
    USER_UPDATE_USER_CREATOR_PROFILE,
    USER_INITIAL_UPDATE_USER_CREATOR_PROFILE
  } from '@/core/store/user.module';

  export default {
    name: 'UserCreatorTaxRateForm',
    components: {
      AppSingleDisplayFormField
    },
    mixins: [commonMixin],
    props: {
      value: {
        type: Number,
        default: 0
      },
      id: {
        type: Number
      }
    },
    data: () => ({
      permissions: {
        module: MODULE.USER,
        action: [ACTION.USER_UPDATE]
      }
    }),
    computed: {
      updateUserCreatorProfileComplete() {
        return this.$store.state.user.updateUserCreatorProfile.complete;
      }
    },
    watch: {
      updateUserCreatorProfileComplete() {
        let response = this.$store.state.user.updateUserCreatorProfile;
        let title = i18nHelper.getMessage('label.updateUserCreatorProfile');
        let initialStateAction = USER_INITIAL_UPDATE_USER_CREATOR_PROFILE;
        let successAction = () => {
          this.updateUserCreatorProfileSuccessAction();
        };
        let errorAction = () => {
          this.updateUserCreatorProfileErrorAction();
        };

        if (response.complete) {
          this.actionUpdateRecordComplete(
            response,
            title,
            initialStateAction,
            successAction,
            errorAction
          );
        }
      }
    },
    methods: {
      updateUserCreatorProfileErrorAction() {
        this.$parent.getCreatorProfile();
        this.$refs.appSingleDisplayFormField.onReset();
      },
      updateUserCreatorProfileSuccessAction() {
        this.$parent.getCreatorProfile();
      },
      onSave(value) {
        let data = {
          taxRate: value / 100
        };
        this.updateUserCreatorProfile(this.id, data);
      },
      updateUserCreatorProfile(id, data) {
        this.$store.dispatch(USER_UPDATE_USER_CREATOR_PROFILE, { id, data });
      }
    }
  };
</script>

<style></style>
