<template>
  <OfflineMerchantForm :isCreate="isCreate"></OfflineMerchantForm>
</template>

<script>
  import OfflineMerchantForm from '@/views/components/offline-merchant/OfflineMerchantForm.vue';
  export default {
    name: 'OfflineMerchantNew',
    components: {
      OfflineMerchantForm
    },
    data: () => ({
      isCreate: true
    })
  };
</script>

<style></style>
