<template>
  <div>
    <div
      v-if="checkAccessRight(permissions.module, permissions.action)"
      class="d-flex align-content-center"
    >
      <AppFormField
        v-model="$v.newValue.$model"
        :state="validateState('newValue')"
        :validationValue="$v.newValue"
        :validators="validators"
        :validationParams="validationParams"
        type="number"
        max="100"
        :disabled="disabled"
        noTitle
        class="d-inline-flex"
      ></AppFormField>
      <div style="display: none">
        <!-- WARNING: I have no idea why this line is here, but removing it breaks everything -->
        {{ $v }}
      </div>
      <i
        v-if="disabled"
        class="fa la-edit icon-xl ml-4 pt-3 cursor-pointer"
        @click="disabled = false"
      ></i>
      <div v-else class="ml-4">
        <i class="fa la-times icon-xl pt-3 cursor-pointer" @click="onReset"></i>
        <i
          class="fa la-save icon-xl ml-4 pt-3 cursor-pointer"
          @click="onSave"
        ></i>
      </div>
    </div>
    <div v-else>
      {{ value }}
    </div>
  </div>
</template>

<script>
  import AppFormField from '@/views/components/bases/form-field/AppFormField';
  import commonMixin from '@/core/mixins/common.mixin';
  import { validationMixin } from 'vuelidate';
  import { required, maxValue, numeric } from 'vuelidate/lib/validators';

  export default {
    name: 'AppSingleDisplayFormField',
    mixins: [commonMixin, validationMixin],
    components: {
      AppFormField
    },
    props: {
      value: {
        type: [Number, String],
        default: null
      },
      config: {
        type: Object,
        default: () => {
          return {
            valueType: 'number',
            maxValue: 100
          };
        }
      },
      permissions: {
        type: Object,
        default: () => {
          return {
            module: '',
            action: []
          };
        }
      }
    },
    data: (vm) => ({
      newValue: null,
      disabled: true,
      validators: ['required'],
      validationParams: [
        {
          key: 'maxValue',
          params: [vm.config.maxValue]
        }
      ]
    }),
    watch: {
      value() {
        this.newValue = this.value;
      }
    },
    mounted() {
      this.newValue = this.value;

      if (this.config.valueType == 'number') {
        this.validators.push('numeric', 'maxValue');
      }
    },
    methods: {
      onSave() {
        this.$emit('save', this.newValue);
        this.disabled = true;
        this.$v.$reset();
      },
      onReset() {
        this.newValue = this.value;
        this.disabled = true;
        this.$v.$reset();
      }
    },
    validations() {
      let validation = new Object();
      let config = this.config;

      if (config.valueType == 'number') {
        validation = {
          newValue: {
            required,
            numeric,
            maxValue: maxValue(config.maxValue)
          }
        };
      } else {
        validation = {
          newValue: {
            required
          }
        };
      }

      return validation;
    }
  };
</script>

<style lang="scss"></style>
