import { render, staticRenderFns } from "./DropdownLanguage.vue?vue&type=template&id=3cd78d07"
import script from "./DropdownLanguage.vue?vue&type=script&lang=js"
export * from "./DropdownLanguage.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.5_cache-loader@4.1.0_webpack@4.47.0_webpack-cli@3.3._5renkeqd53owwajzg6f5xvbcve/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports