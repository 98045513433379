<template ref="StudioBookingDetailsTemplate">
  <b-card no-body data-style="custom">
    <!--begin: Wizard-->
    <div
      class="wizard wizard-2"
      id="studioBookingWizard"
      data-wizard-state="step-first"
      data-wizard-clickable="true"
    >
      <!--begin: Wizard Nav -->
      <div class="wizard-nav border-right py-8 px-8 py-lg-20 px-lg-10">
        <div class="wizard-steps">
          <!--begin: Wizard Steps 1 -->
          <template
            v-for="(i, index) in wizardSteps.filter(
              (x) => x.hideCreate !== isCreate
            )"
          >
            <div
              :key="index"
              class="wizard-step"
              data-wizard-type="step"
              :data-wizard-state="index < 1 ? 'current' : ''"
            >
              <WizardWrapper
                :icon="i.icon"
                :title="$t(`label.${i.title}`)"
                :description="$t(`label.${i.description}`)"
              ></WizardWrapper>
            </div>
          </template>

          <!--end: Wizard Steps 1 -->
        </div>
      </div>
      <!--end: Wizard Nav -->

      <!--begin: Wizard Body -->
      <StudioBookingStatusLabel
        v-if="!isCreate"
        :styleName="'font-size: 14px;padding: 14px;right: 35px;margin-top: 25px;position: absolute;'"
        :value="studioBookingInfo.status"
      ></StudioBookingStatusLabel>
      <div class="wizard-body py-8 px-8 py-lg-20 px-lg-10">
        <!--begin: Wizard Form-->
        <b-row>
          <b-col offset-xl="2" xl="8">
            <!--begin: Wizard Step -->
            <template
              v-for="(i, index) in wizardSteps.filter(
                (x) => x.hideCreate !== isCreate
              )"
            >
              <div
                :key="index"
                class="pb-5"
                data-wizard-type="step-content"
                :data-wizard-state="index < 1 ? 'current' : ''"
              >
                <component
                  v-if="constWizard.currentStep == i.step"
                  :is="i.component"
                  :id="id"
                  :studioBookingInfo="studioBookingInfo"
                  :isCreate="isCreate"
                ></component>
              </div>
            </template>
            <!--end: Wizard Step -->
          </b-col>
          <!--end: Wizard-->
        </b-row>
      </div>
      <!--end: Wizard Body -->
    </div>
    <!--end: Wizard-->
  </b-card>
</template>

<script>
  import WizardWrapper from '@/views/components/wizard/WizardWrapper';
  import StudioBookingForm from '@/views/components/studio/StudioBookingForm.vue';
  import StudioBookingDocuments from '@/views/components/studio/StudioBookingDocuments.vue';
  import StudioBookingStatusLabel from '@/views/components/studio/StudioBookingStatusLabel.vue';
  import KTUtil from '@/assets/js/components/util';
  import KTWizard from '@/assets/js/components/wizard';
  import constantsMixin from '@/core/mixins/constants.mixin';
  import commonMixin from '@/core/mixins/common.mixin';
  import { ROUTE_NAME } from '@/core/constants';
  import { i18nHelper } from '@/core/utils';
  import {
    STUDIO_GET_BOOKING,
    STUDIO_INITIAL_GET_BOOKING_STATE
  } from '@/core/store/studio.module';

  export default {
    name: 'StudioBookingDetailsTemplate',
    mixins: [commonMixin, constantsMixin],
    components: {
      WizardWrapper,
      StudioBookingForm,
      StudioBookingDocuments,
      StudioBookingStatusLabel
    },
    props: {
      isCreate: {
        Type: Boolean,
        defualt: false
      }
    },
    data: () => ({
      constWizard: {
        currentStep: 1
      },
      wizardSteps: [
        {
          step: 1,
          title: 'studioBookingDetails',
          description: 'fillInInfoAndAction',
          icon: 'fab fa-wpforms',
          component: 'StudioBookingForm',
          hideCreate: false
        },
        {
          step: 2,
          title: 'media',
          description: 'UploadDocuments',
          icon: 'fas fa-image',
          component: 'StudioBookingDocuments',
          hideCreate: true
        }
      ],
      studioBookingInfo: {
        bookingId: null,
        companyName: null,
        name: null,
        contactNumber: null,
        email: null,
        preferredStart: null,
        preferredEnd: null,
        numberOfPax: null,
        remark: null
      },
      formFields: []
    }),
    computed: {
      id() {
        return this.$route.params.id;
      },
      getStudioBookingComplete() {
        return this.$store.state.studio.booking.complete;
      }
    },
    watch: {
      getStudioBookingComplete() {
        let response = this.$store.state.studio.booking;
        if (response.complete) {
          this.studioBookingInfo = response.data;
          this.studioBookingCompleteAction(response, 'get');
        }
      }
    },
    mounted() {
      this.initialStudioBooking();
    },
    methods: {
      studioBookingCompleteAction(response, actionType) {
        let title = i18nHelper.getMessage('label.studioBooking');
        let messages = response.message;
        let buttons = [];
        switch (actionType) {
          case 'get':
            this.initialGetStudioBookingState();
            break;
        }
        if (response.code == 0) {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();
              this.getStudioBooking(this.id);
            }
          });
        } else {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();
            }
          });
        }
        if (messages) this.openLayoutAppModalInfo(title, messages, buttons);
      },
      initWizard() {
        // Initialize form wizard
        let vm = this;

        const wizard = new KTWizard('studioBookingWizard', {
          startStep: 1, // initial active step number
          clickableSteps: true // allow step clicking
        });

        vm.constWizard = wizard;

        // wizard.on('submit', () => {
        //   vm.validationCheckBeforeSubmit();
        // });

        //Change event
        wizard.on('change', function (/*wizardObj*/) {
          // if (wizard.currentStep == 1) {
          //   let v1 = vm.validationCheck('studioBookingInfo');

          //   if (!v1) {
          //     wizard.stop();
          //   }
          // }

          KTUtil.scrollTop();
        });
      },
      getStudioBooking(id) {
        this.$store.dispatch(STUDIO_GET_BOOKING, { id });
      },
      initialGetStudioBookingState() {
        this.$store.dispatch(STUDIO_INITIAL_GET_BOOKING_STATE);
      },
      initialStudioBooking() {
        let breadcrumbData = {
          breadcrumbs: [
            {
              title: i18nHelper.getMessage('label.studioBooking'),
              route: { name: ROUTE_NAME.STUDIO }
            },
            {
              title: i18nHelper.getMessage('label.studioBookingList'),
              route: { name: ROUTE_NAME.STUDIO_BOOKING_LIST }
            },
            {
              title: i18nHelper.getMessage(
                `label.${
                  this.isCreate ? 'createStudioBooking' : 'studioBookingDetails'
                }`
              )
            }
          ],
          actions: [
            {
              text: i18nHelper.getMessage('label.back'),
              function: () => {
                this.$router.go(-1);
              }
            }
          ]
        };

        this.initWizard();
        this.initBreadCrumb(breadcrumbData);
        if (!this.isCreate) this.getStudioBooking(this.id);
      }
    }
  };
</script>

<style lang="scss">
  @import '@/assets/sass/pages/wizard/wizard-2.scss';
</style>
