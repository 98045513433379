<template>
  <div>
    <template v-for="(i, index) in value">
      <b-row :key="index" class="mb-2">
        <b-col cols="2"
          ><div>
            <span class="font-weight-boldest mr-2">{{ $t('label.day') }}:</span>
            {{ +i.day }}
          </div></b-col
        >
        <b-col>
          <div class="font-weight-boldest mb-2">
            {{ $t('label.operationHour') }}:
          </div>
          <template v-for="(item, itemIndex) in i.operationHour">
            <div :key="itemIndex">
              <b-row>
                <b-col cols="3">{{ $t('label.startTime') }}:</b-col>
                <b-col cols="9">{{ item.startTime }}</b-col>
              </b-row>
              <b-row>
                <b-col cols="3">{{ $t('label.endTime') }}:</b-col>
                <b-col cols="9">{{ item.endTime }}</b-col>
              </b-row>
            </div>
          </template>
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
  export default {
    name: 'AppBusinessHourList',
    props: {
      value: {
        type: Array,
        default: () => []
      }
    }
  };
</script>

<style></style>
