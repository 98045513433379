import dateTimeFormat from '@/core/filters/dateTimeFormat.filter';
import constantsDescription from '@/core/filters/constants-description.filter';
import { BANNER } from '@/core/api';
import { apiHelper } from '@/core/utils';
import {
  LOADING_OPEN_LOADING,
  LOADING_CLOSE_LOADING
} from '@/core/store/loading.module';

/* Define Module Name */
const MODULE_NAME = 'banner/';

/* Define Action Name */
const A_GET_BANNERS = 'getBanners';
const A_GET_BANNER = 'getBanner';
const A_CREATE_BANNER = 'createBanner';
const A_UPDATE_BANNER = 'updateBanner';
const A_UPDATE_BANNER_ACTIVE_INACTIVE = 'updateBannerActiveInactive';
const A_DELETE_BANNER = 'deleteBanner';
/* Define Reset State Action Name */
const A_INITIAL_GET_BANNERS_STATE = 'initialGetBannersState';
const A_INITIAL_GET_BANNER_STATE = 'initialGetBannerState';
const A_INITIAL_CREATE_BANNER_STATE = 'initialCreateBanner';
const A_INITIAL_UPDATE_BANNER_STATE = 'initialUpdateBanner';
const A_INITIAL_UPDATE_BANNER_ACTIVE_INACTIVE =
  'initialUpdateBannerActiveInactive';
const A_INITIAL_DELETE_BANNER_STATE = 'initialDeleteBanner';

/* Define Getter Name */

/* Define Mutation Name */
const M_GET_BANNERS = 'setGetBanners';
const M_GET_BANNER = 'setGetBanner';
const M_CREATE_BANNER = 'setCreateBanner';
const M_UPDATE_BANNER = 'setUpdateBanner';
const M_UPDATE_BANNER_ACTIVE_INACTIVE = 'setUpdateBannerActiveInactive';
const M_DELETE_BANNER = 'setDeleteBanner';
/* Define Reset State Mutation Name */
const M_INITIAL_GET_BANNERS_STATE = 'setInitialGetBannersState';
const M_INITIAL_GET_BANNER_STATE = 'setInitialGetBannerState';
const M_INITIAL_CREATE_BANNER_STATE = 'setInitialCreateBannerState';
const M_INITIAL_UPDATE_BANNER_STATE = 'setInitialUpdateBanner';
const M_INITIAL_UPDATE_BANNER_ACTIVE_INACTIVE =
  'setInitialUpdateBannerActiveInactive';
const M_INITIAL_DELETE_BANNER_STATE = 'setInitialDeleteBanner';

/* Define Export Name */
export const BANNER_GET_BANNERS = MODULE_NAME + A_GET_BANNERS;
export const BANNER_GET_BANNER = MODULE_NAME + A_GET_BANNER;
export const BANNER_CREATE_BANNER = MODULE_NAME + A_CREATE_BANNER;
export const BANNER_UPDATE_BANNER = MODULE_NAME + A_UPDATE_BANNER;
export const BANNER_UPDATE_BANNER_ACTIVE_INACTIVE =
  MODULE_NAME + A_UPDATE_BANNER_ACTIVE_INACTIVE;
export const BANNER_DELETE_BANNER = MODULE_NAME + A_DELETE_BANNER;
/* Define Reset State Name */
export const BANNER_INITIAL_GET_BANNERS_STATE =
  MODULE_NAME + A_INITIAL_GET_BANNERS_STATE;
export const BANNER_INITIAL_GET_BANNER_STATE =
  MODULE_NAME + A_INITIAL_GET_BANNER_STATE;
export const BANNER_INITIAL_CREATE_BANNER_STATE =
  MODULE_NAME + A_INITIAL_CREATE_BANNER_STATE;
export const BANNER_INITIAL_UPDATE_BANNER_STATE =
  MODULE_NAME + A_INITIAL_UPDATE_BANNER_STATE;
export const BANNER_INITIAL_UPDATE_BANNER_ACTIVE_INACTIVE =
  MODULE_NAME + A_INITIAL_UPDATE_BANNER_ACTIVE_INACTIVE;
export const BANNER_INITIAL_DELETE_BANNER_STATE =
  MODULE_NAME + A_INITIAL_DELETE_BANNER_STATE;

const state = {
  banners: {
    code: 0,
    complete: false,
    data: null,
    message: null,
    pagination: {
      currentPage: 0,
      total: 0,
      perPage: 0,
      lastPage: 0
    }
  },
  banner: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  createBanner: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  updateBanner: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  updateBannerActiveInactive: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  deleteBanner: {
    code: 0,
    complete: false,
    data: null,
    message: null
  }
};

//Getters
const getters = {};

// Actions
const actions = {
  async [A_GET_BANNERS]({ dispatch, commit }, { data }) {
    let result = null;
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    await BANNER.getBanners(data)
      .then((response) => {
        result = response;
        commit(M_GET_BANNERS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_BANNERS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_BANNER]({ dispatch, commit }, { id }) {
    let result = null;
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    BANNER.getBanner(id)
      .then((response) => {
        result = response;
        commit(M_GET_BANNER, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_BANNER, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_CREATE_BANNER]({ dispatch, commit }, { formData }) {
    let result = null;
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    BANNER.createBanner(formData)
      .then((response) => {
        result = response;
        commit(M_CREATE_BANNER, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_CREATE_BANNER, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_UPDATE_BANNER]({ dispatch, commit }, { formData, id }) {
    let result = null;
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    BANNER.updateBanner(formData, id)
      .then((response) => {
        result = response;
        commit(M_UPDATE_BANNER, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_UPDATE_BANNER, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_UPDATE_BANNER_ACTIVE_INACTIVE]({ dispatch, commit }, { id, isActive }) {
    let result = null;
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    BANNER.updateBannerActiveInactive(id, isActive)
      .then((response) => {
        result = response;
        commit(M_UPDATE_BANNER_ACTIVE_INACTIVE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_UPDATE_BANNER_ACTIVE_INACTIVE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_DELETE_BANNER]({ dispatch, commit }, { id }) {
    let result = null;
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    BANNER.deleteBanner(id)
      .then((response) => {
        result = response;
        commit(M_DELETE_BANNER, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_DELETE_BANNER, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_INITIAL_GET_BANNERS_STATE]({ commit }) {
    commit(M_INITIAL_GET_BANNERS_STATE);
  },
  [A_INITIAL_GET_BANNER_STATE]({ commit }) {
    commit(M_INITIAL_GET_BANNER_STATE);
  },
  [A_INITIAL_CREATE_BANNER_STATE]({ commit }) {
    commit(M_INITIAL_CREATE_BANNER_STATE);
  },
  [A_INITIAL_UPDATE_BANNER_STATE]({ commit }) {
    commit(M_INITIAL_UPDATE_BANNER_STATE);
  },
  [A_INITIAL_UPDATE_BANNER_ACTIVE_INACTIVE]({ commit }) {
    commit(M_INITIAL_UPDATE_BANNER_ACTIVE_INACTIVE);
  },
  [A_INITIAL_DELETE_BANNER_STATE]({ commit }) {
    commit(M_INITIAL_DELETE_BANNER_STATE);
  }
};

// Mutations
const mutations = {
  [M_GET_BANNERS](state, result) {
    if (result.code === 0) {
      let p = result.pagination;
      let banners = [];

      result.data.forEach((x, index) => {
        banners.push({
          no: index + 1,
          id: x.id,
          title: x.title,
          description: x.description ? x.description : '-',
          type: x.type,
          contentLabel: x.content_label ? x.content_label : '-',
          tagLabel: x.tag_label ? x.tag_label : '-',
          mediaPath: x.media_path,
          mediaType: constantsDescription('bannerMediaType', x.media_type),
          actionType: constantsDescription('bannerAction', x.action_type),
          actionValue: x.action_value,
          priority: x.priority,
          isActive: x.is_active,
          createdAt: dateTimeFormat(x.created_at),
          updatedAt: dateTimeFormat(x.updated_at)
        });
      });

      state.banners = {
        code: result.code,
        complete: true,
        data: banners,
        message: null,
        pagination: {
          currentPage: p.current_page,
          total: p.total,
          perPage: p.per_page,
          lastPage: p.last_page
        }
      };
    } else {
      state.banners = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message),
        pagination: {
          currentPage: 0,
          total: 0,
          perPage: 0,
          lastPage: 0
        }
      };
    }
  },
  [M_GET_BANNER](state, result) {
    if (result.code === 0) {
      let d = result.data;

      state.banner = {
        code: result.code,
        complete: true,
        data: {
          id: d.id,
          title: d.title,
          description: d.description,
          contentLabel: d.content_label,
          tagLabel: d.tag_label,
          type: d.type,
          mediaPath: d.media_path,
          mediaType: d.media_type,
          thumbnailPath: d.thumbnail_path,
          actionType: d.action_type,
          actionValue: d.action_value,
          priority: d.priority,
          isActive: d.is_active,
          createdAt: dateTimeFormat(d.created_at),
          updatedAt: dateTimeFormat(d.updated_at)
        },
        message: null
      };
    } else {
      state.banner = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_CREATE_BANNER](state, result) {
    state.createBanner = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_UPDATE_BANNER](state, result) {
    state.updateBanner = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_UPDATE_BANNER_ACTIVE_INACTIVE](state, result) {
    state.updateBannerActiveInactive = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_DELETE_BANNER](state, result) {
    state.deleteBanner = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_INITIAL_GET_BANNERS_STATE](state) {
    state.banners = {
      code: 0,
      complete: false,
      data: null,
      message: null,
      pagination: {
        currentPage: 0,
        total: 0,
        perPage: 0,
        lastPage: 0
      }
    };
  },
  [M_INITIAL_GET_BANNER_STATE](state) {
    state.banner = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_CREATE_BANNER_STATE](state) {
    state.createBanner = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_UPDATE_BANNER_STATE](state) {
    state.updateBanner = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_UPDATE_BANNER_ACTIVE_INACTIVE](state) {
    state.updateBannerActiveInactive = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_DELETE_BANNER_STATE](state) {
    state.deleteBanner = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
