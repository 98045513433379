<template>
  <b-modal
    v-model="modal"
    :title="$t('label.editPersonalInformation')"
    centered
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
    scrollable
    header-class="text-capitalize"
    :body-class="isEdit ? '' : 'max-h-400px'"
  >
    <b-form-group class="mb-0">
      <template v-for="(i, index) in formFields">
        <div :key="index" class="px-2">
          <AppFormField
            v-model="$v.profileDetailsPersonalInform[i.model].$model"
            :state="validateObjState('profileDetailsPersonalInform', i.model)"
            :validationValue="$v.profileDetailsPersonalInform[i.model]"
            :title="$t(`label.${i.title}`)"
            :solid="true"
            :validators="i.validators"
            :validationParams="i.validationParams"
            :formType="i.formType"
            :options="i.options"
            :textTransform="i.textTransform"
            :moreRemark="i.moreRemark"
            :isRequires="i.isRequires"
            :disabled="i.disabled"
            @change="initFormFields"
          ></AppFormField>
          <div style="display: none">
            <!-- WARNING: I have no idea why this line is here, but removing it breaks everything -->
            {{ $v }}
          </div>
        </div>
      </template>
    </b-form-group>
    <template #modal-footer>
      <b-button
        v-if="
          !isEdit &&
          !checkAccessRight(moduleName, [action.PLATFORM_OPERATION_HOUR_UPDATE])
        "
        variant="primary"
        @click="actionDiscard"
        >{{ $t('label.close') }}</b-button
      >
      <b-button v-else class="mr-2" variant="white" @click="actionDiscard">{{
        $t('label.discard')
      }}</b-button>
      <b-button
        v-if="
          isEdit &&
          checkAccessRight(moduleName, [action.PLATFORM_OPERATION_HOUR_CREATE])
        "
        variant="success"
        @click="actionSubmit"
        >{{ $t('label.submit') }}</b-button
      >
    </template>
  </b-modal>
</template>

<script>
  import AppFormField from '@/views/components/bases/form-field/AppFormField';
  import commonMixin from '@/core/mixins/common.mixin';
  import { MODULE, ACTION } from '@/core/constants';
  import { validationMixin } from 'vuelidate';
  import {
    required,
    maxValue,
    numeric,
    url,
    minLength,
    maxLength
  } from 'vuelidate/lib/validators';
  import { i18nHelper, uiHelper } from '@/core/utils';
  import {
    USER_UPDATE_USER_CREATOR_PROFILE,
    USER_INITIAL_UPDATE_USER_CREATOR_PROFILE
  } from '@/core/store/user.module';
  import { listService } from '@/core/services';

  export default {
    name: 'ProfileDetailsPersonalInformModal',
    mixins: [commonMixin, validationMixin],
    components: {
      AppFormField
    },
    props: {
      value: {
        type: Boolean,
        default: false
      },
      id: {
        type: Number,
        default: 0,
        required: true
      },
      creatorProfileObj: {
        type: Object,
        default: () => {},
        required: true
      },
      isEdit: {
        type: Boolean,
        default: false,
        required: true
      }
    },
    data: () => ({
      moduleName: MODULE.PLATFORM_OPERATION_HOUR,
      action: ACTION,
      modal: false,
      profileDetailsPersonalInform: {
        serviceType: [2],
        description: '',
        pinVerification: '',
        cuisineType: [],
        websiteLink: '',
        taxRate: ''
      },
      formFields: []
    }),
    computed: {
      updateProfileDetailPresonalInformComplete() {
        return this.$store.state.user.updateUserCreatorProfile.complete;
      }
    },
    watch: {
      value() {
        if (this.value) {
          this.modal = true;
          this.initialObj();
          this.initFormFields();
        }
      },
      modal() {
        this.modal
          ? uiHelper.freezeBodyOverflowY()
          : uiHelper.unFreezeBodyOverflowY();
      },
      updateProfileDetailPresonalInformComplete() {
        let response = this.$store.state.user.updateUserCreatorProfile;
        let title = i18nHelper.getMessage('label.updateUserCreatorProfile');
        let initialStateAction = USER_INITIAL_UPDATE_USER_CREATOR_PROFILE;
        let successAction = () => {
          this.actionCloseModal();
          this.initialList();
        };
        let errorAction = () => {
          this.actionCompletedModal();
        };

        if (response.complete) {
          this.actionUpdateRecordComplete(
            response,
            title,
            initialStateAction,
            successAction,
            errorAction
          );
        }
      }
    },
    mounted() {},
    methods: {
      initialList() {
        this.$parent.getCreatorProfile();
      },
      actionDiscard() {
        this.actionCloseModal();
      },
      actionSubmit() {
        let data = this.profileDetailsPersonalInform;
        if (this.validationCheck('profileDetailsPersonalInform')) {
          this.updateProfileDetailPresonalInform(data);
        }
      },
      resetForm() {
        this.profileDetailsPersonalInform = {
          serviceType: [2],
          description: '',
          pinVerification: '',
          cuisineType: [],
          websiteLink: '',
          taxRate: ''
        };
        this.$v.$reset();
      },
      actionCloseModal() {
        this.resetForm();
        this.input(false);
        this.modal = false;
      },
      actionCompletedModal() {
        this.initFormFields();
      },
      input(value) {
        this.$emit('input', value);
      },
      updateProfileDetailPresonalInform(data) {
        let id = this.id;
        this.$store.dispatch(USER_UPDATE_USER_CREATOR_PROFILE, {
          id,
          data
        });
      },
      initialObj() {
        let data = this.creatorProfileObj;
        this.profileDetailsPersonalInform = {
          serviceType: data.serviceTypeValue,
          description: data.description == '-' ? '' : data.description,
          pinVerification: data.pinVerification,
          cuisineType: data.cuisineTypeValue,
          websiteLink: data.websiteLink == '-' ? '' : data.websiteLink,
          taxRate: data.taxRate
        };
      },
      checkServiceType() {
        let serviceType = this.profileDetailsPersonalInform.serviceType;
        if (serviceType.includes(1)) {
          return true;
        } else {
          this.profileDetailsPersonalInform.cuisineType = [];
          return false;
        }
      },
      initFormFields() {
        this.formFields = [];
        this.formFields.push(
          {
            model: 'serviceType',
            title: 'serviceType',
            formType: 'checkbox',
            value: true,
            options: [
              { text: 'Online', value: 2, notEnabled: true },
              { text: 'Dine-in', value: 1 }
            ],
            validators: ['required'],
            isRequires: true
          },
          {
            model: 'cuisineType',
            title: 'cuisineType',
            formType: 'checkbox',
            options: listService.getOffileMerchantCuisineTypeList(),
            isRequires: this.checkServiceType(),
            disabled: !this.checkServiceType()
          },
          {
            model: 'pinVerification',
            title: 'pinVerification',
            validators: ['minLength', 'maxLength'],
            validationParams: [
              {
                key: 'minLength',
                params: [4]
              },
              {
                key: 'maxLength',
                params: [4]
              }
            ],
            isRequires: this.checkServiceType(),
            textTransform: true
          },
          {
            model: 'description',
            title: 'description',
            formType: 'textarea',
            validators: ['maxLength'],
            validationParams: [
              {
                key: 'maxLength',
                params: [500]
              }
            ],
            moreRemark: 'Maximum limit 500 characters'
          },

          {
            model: 'websiteLink',
            title: 'websiteLink',
            validators: ['url']
          },
          {
            model: 'taxRate',
            title: 'taxRate%',
            validators: ['required', 'numeric', 'maxValue'],
            validationParams: [
              {
                key: 'maxValue',
                params: [100]
              }
            ]
          }
        );
      }
    },
    validations() {
      return {
        profileDetailsPersonalInform: {
          serviceType: {
            required
          },
          description: {
            maxLength: maxLength(500)
          },
          pinVerification: {
            // required,
            minLength: minLength(4),
            maxLength: maxLength(4)
          },
          cuisineType: {
            // required: this.checkServiceType()
          },
          websiteLink: {
            url
          },
          taxRate: {
            required,
            numeric,
            maxValue: maxValue(100)
          }
        }
      };
    }
  };
</script>

<style lang="scss"></style>
