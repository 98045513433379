<template>
  <div>
    <button
      v-if="!checkAccessRight(moduleName, [permissionAction.VOUCHER_DELETE])"
      class="btn btn-light-danger font-weight-bold text-caitalize mr-3"
      @click="deleteVoucher"
    >
      {{ $t('label.delete') }}
    </button>
    <button
      v-if="!checkAccessRight(moduleName, [permissionAction.VOUCHER_UPDATE])"
      class="btn btn-warning font-weight-bold text-caitalize mr-3"
      @click="activateVoucher"
    >
      {{
        voucherObj.status == 1 ? $t('label.deactivate') : $t('label.activate')
      }}
    </button>
  </div>
</template>

<script>
  import commonMixin from '@/core/mixins/common.mixin';
  import { MODULE, ACTION, ROUTE_NAME } from '@/core/constants';
  import { i18nHelper } from '@/core/utils';
  import {
    VOUCHER_UPDATE_VOUCHER_ACTIVATE,
    VOUCHER_DELETE_VOUCHER,
    VOUCHER_INITIAL_UPDATE_VOUCHER_ACTIVATE_STATE,
    VOUCHER_INITIAL_DELETE_VOUCHER_STATE
  } from '@/core/store/voucher.module';

  export default {
    name: 'VoucherStatusBtn',
    mixins: [commonMixin],
    data: () => ({
      moduleName: MODULE.STUDIO,
      permissionAction: ACTION,
      routeName: ROUTE_NAME
    }),
    props: {
      id: {
        Type: Number,
        default: null
      },
      voucherObj: {
        Type: Object,
        default: {
          status: null
        }
      }
    },
    computed: {
      updateVoucherStatusComplete() {
        return this.$store.state.voucher.updateVoucherActivate.complete;
      },
      deleteVoucherComplete() {
        return this.$store.state.voucher.deleteVoucher.complete;
      }
    },
    watch: {
      updateVoucherStatusComplete() {
        let response = this.$store.state.voucher.updateVoucherActivate;
        if (response.complete) {
          this.VoucherCompleteAction(response, 'update');
        }
      },
      deleteVoucherComplete() {
        let response = this.$store.state.voucher.deleteVoucher;
        if (response.complete) {
          this.VoucherCompleteAction(response, 'delete');
        }
      }
    },
    methods: {
      VoucherCompleteAction(response, actionType) {
        let title = i18nHelper.getMessage('label.voucher');
        let messages = response.message;
        let buttons = [];
        switch (actionType) {
          case 'update':
            this.initialUpdateVoucherStatus();
            break;
          case 'delete':
            this.initialDeleteVoucherState();
            break;
        }
        if (response.code == 0) {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();
              switch (actionType) {
                case 'delete':
                  this.$router.push({
                    name: ROUTE_NAME.VOUCHER_LIST
                  });
                  break;
                default:
                  this.$parent.$parent.getVoucher(this.id);
              }
            }
          });
        } else {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();
            }
          });
        }
        if (messages) this.openLayoutAppModalInfo(title, messages, buttons);
      },
      activateVoucher() {
        let id = this.id;
        let data;

        switch (this.voucherObj.status) {
          case 0:
            data = 1;
            break;
          case 1:
            data = 0;
            break;
          case 2:
            data = 1;
            break;
        }
        this.$store.dispatch(VOUCHER_UPDATE_VOUCHER_ACTIVATE, {
          id,
          data
        });
      },
      deleteVoucher() {
        let id = this.id;

        this.$store.dispatch(VOUCHER_DELETE_VOUCHER, { id });
      },
      initialUpdateVoucherStatus() {
        this.$store.dispatch(VOUCHER_INITIAL_UPDATE_VOUCHER_ACTIVATE_STATE);
      },
      initialDeleteVoucherState() {
        this.$store.dispatch(VOUCHER_INITIAL_DELETE_VOUCHER_STATE);
      }
    }
  };
</script>

<style></style>
