import { apiService } from '@/core/services';
import { apiHelper } from '@/core/utils';

export const CREATOR_APPLICATION = {
  getCreatorApplications,
  getCreatorApplication,
  approveCreatorApplication,
  rejectCreatorApplication,
  creatorDocument,
  uploadDocument,
  getRejectReasonApplication
};

const URL = {
  ADMIN: 'admin',
  CREATORS: 'creator-applications',
  APPROVE: 'approve',
  REJECT: 'reject',
  UPLOAD_DOCUMENT: 'upload-document',
  GET_DOCUMENT: 'get-document',
  REJECT_REASON: 'rejectreason',
  BULK_UPLOAD_DOCUMENT: 'bulk-upload-document'
};

function getCreatorApplications(data) {
  let requestOptions = {
    params: {
      username: data.username,
      store_name: data.storeName,
      status: data.status,
      creator_type: data.creatorType,
      submitted_at_from: apiHelper.dateTimeStart(data.dateRange),
      submitted_at_to: apiHelper.dateTimeEnd(data.dateRange),
      per_page: data.perPage,
      page: data.page
    }
  };
  return apiService().get(`/${URL.ADMIN}/${URL.CREATORS}`, requestOptions);
}

function getRejectReasonApplication() {
  return apiService().get(`/${URL.ADMIN}/${URL.REJECT_REASON}`);
}

function getCreatorApplication(id) {
  return apiService().get(`/${URL.ADMIN}/${URL.CREATORS}/${id}`);
}

function approveCreatorApplication(id) {
  return apiService().put(`/${URL.ADMIN}/${URL.CREATORS}/${id}/${URL.APPROVE}`);
}

function rejectCreatorApplication(id, data) {
  let requestBody = {
    rejected_reason: data.rejected_reason,
    rejected_remark: data.rejected_remark
  };
  return apiService().put(
    `/${URL.ADMIN}/${URL.CREATORS}/${id}/${URL.REJECT}`,
    requestBody
  );
}

function creatorDocument(id) {
  return apiService().get(
    `/${URL.ADMIN}/${URL.CREATORS}/${id}/${URL.GET_DOCUMENT}`
  );
}

function uploadDocument(id, data) {
  let formData = new FormData();
  formData.append('creator_type', data.creatorType);
  // "doc_type_3": "BANK_STATEMENT"
  formData.append(
    'doc_type_3',
    typeof data.bankStatement !== 'string' ? data.bankStatement : ''
  );
  if (data.creatorType == 0) {
    // "doc_type_0": "IC_FRONT"
    formData.append(
      'doc_type_0',
      typeof data.icFront !== 'string' ? data.icFront : ''
    );
    // "doc_type_1": "IC_BACK"
    formData.append(
      'doc_type_1',
      typeof data.icBack !== 'string' ? data.icBack : ''
    );
  } else {
    // "doc_type_2": "BUSINESS_LICENSE"
    formData.append(
      'doc_type_2',
      typeof data.businessLicense !== 'string' ? data.businessLicense : ''
    );
    // "doc_type_4": "HALAL_CERT"
    formData.append(
      'doc_type_4',
      typeof data.halalCert !== 'string' ? data.halalCert : ''
    );
    // "doc_type_5": "SST_CERT"
    formData.append(
      'doc_type_5',
      typeof data.sstCert !== 'string' ? data.sstCert : ''
    );
  }

  return apiService().post(
    `/${URL.ADMIN}/${URL.CREATORS}/${id}/${URL.BULK_UPLOAD_DOCUMENT}`,
    formData
  );
}
