<template>
  <div>
    <InputSwitch
      class="app-switch"
      :disabled="disabled"
      :value="value"
      @input="input"
    />
  </div>
</template>

<script>
  import commonMixin from '@/core/mixins/common.mixin';

  export default {
    name: 'AppSwitch',
    mixins: [commonMixin],
    props: {
      value: {
        type: Boolean,
        default: false
      },
      id: {
        type: Number,
        default: null
      },
      itemKey: {
        type: String,
        default: ''
      },
      disabled: {
        type: Boolean,
        default: false
      },
      switchActionsOnj: {
        type: Object,
        default: () => {
          return {
            module: '',
            state: '',
            actionSet: '',
            actionInitial: '',
            successAction: () => {},
            errorActionL: () => {},
            title: ''
          };
        }
      }
    },
    computed: {
      updateSwitchComplete() {
        let obj = this.switchActionsOnj;

        return this.$store.state[obj.module][obj.state].complete;
      }
    },
    watch: {
      updateSwitchComplete() {
        let obj = this.switchActionsOnj;
        let response = this.$store.state[obj.module][obj.state];
        let title = obj.title;
        let initialStateAction = obj.actionInitial;
        let successAction = obj.successAction;
        let errorAction = obj.errorAction;

        if (response.complete) {
          this.actionUpdateRecordCompleteWithoutSuccessModal(
            response,
            title,
            initialStateAction,
            successAction,
            errorAction
          );
        }
      }
    },
    methods: {
      input(value) {
        let data = {
          [this.itemKey]: value
        };
        this.updateAction(this.id, data);
      },
      updateAction(id, data) {
        this.$store.dispatch(this.switchActionsOnj.actionSet, { id, data });
      }
    }
  };
</script>

<style lang="scss">
  .app-switch {
    .p-inputswitch-slider {
      border-radius: 12px !important;
      &::before {
        border-radius: 8px !important;
      }
    }
  }
</style>
