<template>
  <div>
    <!-- Subsidiary Branch -->
    <!-- begin: Merchant Grouping Subsidiary Branch -->
    <MerchantGroupingBranch
      :moduleName="moduleName"
      :permissionAction="permissionAction"
    >
    </MerchantGroupingBranch>
    <!-- end: Merchant Grouping Subsidiary Branch -->
    <hr />
    <!-- Form -->
    <MerchantGroupingSubsidiaryForm
      :moduleName="moduleName"
      :permissionAction="permissionAction"
    ></MerchantGroupingSubsidiaryForm>
  </div>
</template>

<script>
  import constantsMixin from '@/core/mixins/constants.mixin';
  import commonMixin from '@/core/mixins/common.mixin';
  import MerchantGroupingBranch from '@/views/components/merchant-grouping/MerchantGroupingBranch';
  import MerchantGroupingSubsidiaryForm from '@/views/components/merchant-grouping/form/MerchantGroupingSubsidiaryForm';
  import { validationMixin } from 'vuelidate';

  export default {
    name: 'MerchantGroupingSubsidiary',
    mixins: [commonMixin, constantsMixin, validationMixin],
    components: {
      MerchantGroupingBranch,
      MerchantGroupingSubsidiaryForm
    },
    props: {
      id: {},
      isCreate: {
        type: Boolean,
        default: false
      },
      moduleName: {},
      permissionAction: {}
    },
    data: () => ({}),
    mounted() {},
    computed: {},
    watch: {},
    methods: {}
  };
</script>

<style></style>
