import { apiService } from '@/core/services';

export const SUBSCRIPTION = {
  getSubscriptionsList,
  exportSubscriptionExcel
};

const URL = {
  ADMIN: 'admin',
  EMAIL_SUBSCRIPTIONS: 'email-subscriptions',
  EXPORT: 'export'
};

function getSubscriptionsList(data) {
  let requestOptions = {
    params: {
      email: data.email,
      per_page: data.perPage,
      page: data.page
    }
  };
  return apiService().get(
    `/${URL.ADMIN}/${URL.EMAIL_SUBSCRIPTIONS}`,
    requestOptions
  );
}

function exportSubscriptionExcel() {
  return apiService().get(
    `/${URL.ADMIN}/${URL.EMAIL_SUBSCRIPTIONS}/${URL.EXPORT}`
  );
}
