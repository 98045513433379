<template>
  <div>
    <button
      v-if="
        !checkAccessRight(moduleName, [permissionAction.REFERRAL_CODE_DELETE])
      "
      class="btn btn-light-danger font-weight-bold text-caitalize mr-3"
      @click="deleteReferralCode"
    >
      {{ $t('label.delete') }}
    </button>
    <button
      v-if="
        !checkAccessRight(moduleName, [permissionAction.REFERRAL_CODE_UPDATE])
      "
      class="btn btn-warning font-weight-bold text-caitalize mr-3"
      @click="activateReferralCode"
    >
      {{
        referralCodeObj.status == 1
          ? $t('label.deactivate')
          : $t('label.activate')
      }}
    </button>
  </div>
</template>

<script>
  import commonMixin from '@/core/mixins/common.mixin';
  import { MODULE, ACTION, ROUTE_NAME } from '@/core/constants';
  import { i18nHelper } from '@/core/utils';
  import {
    REFERRAL_CODE_UPDATE_REFERAL_CODE_ACTIVATE,
    REFERRAL_CODE_DELETE_REFERAL_CODE,
    REFERRAL_CODE_INITIAL_UPDATE_REFERAL_CODE_ACTIVATE_STATE,
    REFERRAL_CODE_INITIAL_DELETE_REFERAL_CODE_STATE
  } from '@/core/store/referral-code.module';

  export default {
    name: 'ReferralCodeStatusBtn',
    mixins: [commonMixin],
    data: () => ({
      moduleName: MODULE.STUDIO,
      permissionAction: ACTION,
      routeName: ROUTE_NAME
    }),
    props: {
      id: {
        Type: Number,
        default: null
      },
      referralCodeObj: {
        Type: Object,
        default: {
          status: null
        }
      }
    },
    computed: {
      updateReferralCodeStatusComplete() {
        return this.$store.state.referralCode.updateReferralCodeActivate
          .complete;
      },
      deleteReferralCodeComplete() {
        return this.$store.state.referralCode.deleteReferralCode.complete;
      }
    },
    watch: {
      updateReferralCodeStatusComplete() {
        let response =
          this.$store.state.referralCode.updateReferralCodeActivate;
        if (response.complete) {
          this.ReferralCodeCompleteAction(response, 'update');
        }
      },
      deleteReferralCodeComplete() {
        let response = this.$store.state.referralCode.deleteReferralCode;
        if (response.complete) {
          this.ReferralCodeCompleteAction(response, 'delete');
        }
      }
    },
    methods: {
      ReferralCodeCompleteAction(response, actionType) {
        let title = i18nHelper.getMessage('label.referralCode');
        let messages = response.message;
        let buttons = [];
        switch (actionType) {
          case 'update':
            this.initialUpdateReferralCodeStatus();
            break;
          case 'delete':
            this.initialDeleteReferralCodeState();
            break;
        }
        if (response.code == 0) {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();
              switch (actionType) {
                case 'delete':
                  this.$router.push({
                    name: ROUTE_NAME.REFERRAL_CODE_LIST
                  });
                  break;
                default:
                  this.$parent.getReferralCode(this.id);
              }
            }
          });
        } else {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();
            }
          });
        }
        if (messages) this.openLayoutAppModalInfo(title, messages, buttons);
      },
      activateReferralCode() {
        let id = this.id;
        let data;

        switch (this.referralCodeObj.status) {
          case 0:
            data = 1;
            break;
          case 1:
            data = 0;
            break;
          case 2:
            data = 1;
            break;
        }
        this.$store.dispatch(REFERRAL_CODE_UPDATE_REFERAL_CODE_ACTIVATE, {
          id,
          data
        });
      },
      deleteReferralCode() {
        let id = this.id;

        this.$store.dispatch(REFERRAL_CODE_DELETE_REFERAL_CODE, { id });
      },
      initialUpdateReferralCodeStatus() {
        this.$store.dispatch(
          REFERRAL_CODE_INITIAL_UPDATE_REFERAL_CODE_ACTIVATE_STATE
        );
      },
      initialDeleteReferralCodeState() {
        this.$store.dispatch(REFERRAL_CODE_INITIAL_DELETE_REFERAL_CODE_STATE);
      }
    }
  };
</script>

<style></style>
