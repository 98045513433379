const ActiveStatus = {
  INACTIVE: false,
  ACTIVE: true
};

const ActiveStatusNum = {
  INACTIVE: 0,
  ACTIVE: 1
};

export { ActiveStatus, ActiveStatusNum };
