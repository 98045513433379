<template>
  <!--begin: Wizard Step 1-->
  <div>
    <h6 class="mb-10 font-weight-bold text-dark text-capitalize">
      {{ $t('label.enterFormDetails', [$t('label.equipment')]) }}
    </h6>
    <b-form class="form" id="kt_form">
      <b-row no-gutters>
        <template v-for="(i, index) in formFields.filter((x) => x.field == 1)">
          <b-col
            :key="index"
            xl="6"
            class="p-xl-1"
            v-if="i.noCreate !== isCreate || !isCreate"
          >
            <div v-if="i.layout">
              <label class="font-size-sm text-capitalize"
                >{{ $t(`label.${i.title}`) }}
                <span v-if="i.isRequires" class="text-danger">*</span></label
              >
              <component
                v-model="$v.equipmentInfo[i.model].$model"
                :state="validateObjState('equipmentInfo', i.model)"
                :is="i.layout"
                :validators="i.validators"
                :validationParams="i.validationParams"
                :validationValue="$v.equipmentInfo[i.model]"
                :item="equipmentInfo"
                :options="i.options"
                :isCreate="isCreate"
                :disabled="i.disabled"
              ></component>
            </div>
            <AppFormField
              v-else
              v-model="$v.equipmentInfo[i.model].$model"
              :state="validateObjState('equipmentInfo', i.model)"
              :validationValue="$v.equipmentInfo[i.model]"
              :title="$t(`label.${i.title}`)"
              :validators="i.validators"
              :validationParams="i.validationParams"
              :formType="i.formType"
              :config="i.config"
              :moreRemark="i.moreRemark"
              :isRequires="i.isRequires"
              :prepend="i.prepend"
              :disabled="i.disabled"
              :solid="!i.solid"
              :min="i.min"
              :accept="i.accept"
              @replace-action="replaceAction"
            >
            </AppFormField>
            <div style="display: none">
              <!-- WARNING: I have no idea why this line is here, but removing it breaks everything -->
              {{ $v }}
            </div>
          </b-col>
        </template>
      </b-row>
    </b-form>
  </div>
</template>

<script>
  import AppFormField from '@/views/components/bases/form-field/AppFormField';
  import AppAmountType from '@/views/components/bases/AppAmountType';
  import constantsMixin from '@/core/mixins/constants.mixin';
  import commonMixin from '@/core/mixins/common.mixin';
  import { MediaType } from '@/core/constants/enums';
  import { validationMixin } from 'vuelidate';
  import {
    GLOBAL,
    MODULE,
    ACTION,
    ROUTE_NAME,
    bannerImageType,
    fileSizeImage,
    price
  } from '@/core/constants';
  import { required } from 'vuelidate/lib/validators';
  import { listService } from '@/core/services';
  import { uiHelper } from '@/core/utils';

  export default {
    name: 'EquipmentInfoForm',
    mixins: [commonMixin, constantsMixin, validationMixin],
    components: {
      AppFormField,
      AppAmountType
    },
    props: {
      equipmentInfo: {
        type: Object,
        default: () => ({})
      },
      isCreate: {
        type: Boolean,
        default: false
      },
      id: {}
    },
    data: () => ({
      moduleName: MODULE.EQUIPMENTS,
      permissionAction: ACTION,
      global: GLOBAL,
      routeName: ROUTE_NAME,
      formFields: [],
      isFileReplace: {
        thumbnail: false
      }
    }),
    mounted() {
      this.initFormFields();
    },
    computed: {},
    watch: {},
    methods: {
      initFormFields() {
        this.formFields.push(
          {
            field: 1,
            model: 'brandName',
            title: 'brandName',
            validators: ['required'],
            isRequires: true
          },
          {
            field: 1,
            model: 'equipmentName',
            title: 'equipmentName',
            validators: ['required'],
            isRequires: true
          },
          {
            field: 1,
            model: 'thumbnail',
            title: 'thumbnail',
            formType: 'fileView',
            validators: ['required', 'bannerImageType', 'fileSize'],
            validationParams: [
              {
                key: 'bannerImageType',
                params: [
                  uiHelper.arrayToTrimmedStringWithSpace(
                    GLOBAL.AVAILABLE_IMAGE_TYPE,
                    6
                  )
                ]
              },
              {
                key: 'fileSize',
                params: [
                  GLOBAL.BANNER_IMAGE_UPLOAD_SIZE,
                  GLOBAL.BANNER_IMAGE_UPLOAD_SIZE_TYPE
                ]
              }
            ],
            accept: GLOBAL.AVAILABLE_IMAGE_TYPE.toString(),
            isRequires: true,
            config: {
              model: 'thumbnail',
              dimension: {
                height: 400,
                width: 400
              },
              uploadSize: GLOBAL.BANNER_IMAGE_UPLOAD_SIZE,
              mediaType: MediaType.IMAGE,
              sizeType: GLOBAL.BANNER_IMAGE_UPLOAD_SIZE_TYPE
            }
          },
          {
            field: 1,
            model: 'sellingPrice',
            title: 'sellingPrice',
            validators: ['required', 'price'],
            isRequires: true
          },
          {
            field: 1,
            title: 'discount',
            model: 'discountValueType',
            layout: 'AppAmountType',
            options: listService.getAmoutTypeList(false)
          },
          {
            field: 1,
            model: 'features',
            title: 'features',
            formType: 'textarea',
            isRequires: true,
            validators: ['required']
          },
          {
            field: 1,
            model: 'specification',
            title: 'specification',
            formType: 'textarea',
            isRequires: true,
            validators: ['required']
          },
          {
            field: 1,
            model: 'warrantyInfo',
            title: 'warrantyInfo',
            formType: 'textarea',
            isRequires: true,
            validators: ['required']
          },
          {
            field: 1,
            model: 'productAssurance',
            title: 'productAssurance',
            formType: 'textarea',
            isRequires: true,
            validators: ['required']
          },
          {
            field: 1,
            model: 'termsAndConditionApply',
            title: 'termsAndConditionApply',
            formType: 'textarea',
            isRequires: true,
            validators: ['required']
          },
          {
            field: 1,
            model: 'remark',
            title: 'remark',
            formType: 'textarea',
            isRequires: true,
            validators: ['required']
          }
        );
      },
      validationCheckEquipmentInfo() {
        //Submit new banner form if all the validation is no error
        return this.validationCheck('equipmentInfo');
      },
      replaceAction(value) {
        this.equipmentInfo[value] = null;
        this.isFileReplace[value] = true;
      },
      resetForm() {
        this.isFileReplace = {
          thumbnail: false
        };
        this.$v.$reset();
      }
    },
    validations() {
      let isFileReplace = this.isFileReplace;
      let isCreate = this.isCreate;
      return {
        equipmentInfo: {
          brandName: {
            required
          },
          equipmentName: {
            required
          },
          thumbnail: {
            required,
            bannerImageType:
              isFileReplace.thumbnail || isCreate ? bannerImageType : '',
            fileSize:
              isFileReplace.thumbnail || isCreate
                ? fileSizeImage(GLOBAL.BANNER_IMAGE_UPLOAD_SIZE)
                : ''
          },
          sellingPrice: {
            required,
            price
          },
          discountValueType: {},
          features: {
            required
          },
          specification: {
            required
          },
          warrantyInfo: {
            required
          },
          productAssurance: {
            required
          },
          termsAndConditionApply: {
            required
          },
          remark: {
            required
          }
        }
      };
    }
  };
</script>

<style></style>
