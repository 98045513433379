<template>
  <div>
    <AppSmartList
      ref="app-smart-list"
      :breadcrumbData="breadcrumbData"
      :accessRights="accessRights"
      :filters="filters"
      :actions="actions"
      :tableHeaders="headers"
      @app-smart-list-open-modal-details="openModalDetails"
    >
    </AppSmartList>

    <!--begin:: App Update Details Modal-->
    <DisplayPriorityModal
      v-model="priorityDetailsModalObj.isShow"
      :id="priorityDetailsModalObj.id"
      :obj="priorityDetailsModalObj.obj"
      :category="category"
      :actions="actions"
    ></DisplayPriorityModal>
    <!--end:: App Update Details Modal-->
  </div>
</template>

<script>
  import AppSmartList from '@/views/components/bases/smart/AppSmartList.vue';
  import DisplayPriorityModal from '@/views/components/mobile-application/DisplayPriorityModal';
  import { listService } from '@/core/services';
  import { i18nHelper, uiHelper } from '@/core/utils';
  import {
    LOCAL,
    ROUTE_NAME,
    MODULE,
    ACTION,
    APP_COMPONENT_NAME,
    SMART_FORM_FIELD_TYPE
  } from '@/core/constants';
  import { RecipeStatus, CreationType } from '@/core/constants/enums';
  import {
    MOBILE_APPLICATION_GET_MOBILE_APPLICATION_ALL_VIDEOS,
    MOBILE_APPLICATION_UPDATE_MOBILE_APPLICATION_ALL_VIDEO,
    MOBILE_APPLICATION_INITIAL_GET_MOBILE_APPLICATION_ALL_VIDEOS_STATE,
    MOBILE_APPLICATION_INITIAL_UPDATE_MOBILE_APPLICATION_ALL_VIDEO_STATE
  } from '@/core/store/mobile-application.module';

  const CONSTANTS_DATA = JSON.parse(uiHelper.getLocalStorage(LOCAL.CONSTANTS));

  export default {
    name: 'AllVideosList',
    components: {
      AppSmartList,
      DisplayPriorityModal
    },
    props: {
      category: {
        type: [String, Number],
        default: ''
      }
    },
    data: () => ({
      accessRights: {
        moduleName: MODULE.MOBILE_APPLICATION,
        actions: {
          read: ACTION.MOBILE_APPLICATION_READ,
          update: ACTION.MOBILE_APPLICATION_UPDATE,
          create: ACTION.MOBILE_APPLICATION_CREATE
        }
      },
      filters: [],
      actions: {
        viewList: {
          module: 'mobileApplication',
          state: 'mobileApplicationAllVideos',
          action: MOBILE_APPLICATION_GET_MOBILE_APPLICATION_ALL_VIDEOS,
          initialStateAction:
            MOBILE_APPLICATION_INITIAL_GET_MOBILE_APPLICATION_ALL_VIDEOS_STATE,
          title: i18nHelper.getMessage('label.getAllVideo')
        },
        updatePriority: {
          module: 'mobileApplication',
          state: 'updateMobileApplicationAllVideo',
          action: MOBILE_APPLICATION_UPDATE_MOBILE_APPLICATION_ALL_VIDEO,
          initialStateAction:
            MOBILE_APPLICATION_INITIAL_UPDATE_MOBILE_APPLICATION_ALL_VIDEO_STATE
        }
      },
      headers: [],
      priorityDetailsModalObj: {
        isShow: false,
        id: 0,
        obj: () => {}
      }
    }),
    created() {
      this.initialAllVideosList();
    },
    computed: {},
    watch: {},
    methods: {
      initialAllVideosList() {
        this.breadcrumbData = {
          breadcrumbs: [
            {
              title: i18nHelper.getMessage('label.allVideos'),
              route: {
                name:
                  this.category == CreationType.RECIPE
                    ? ROUTE_NAME.ALL_VIDEOS_EXPERIENCE_COOKING
                    : ROUTE_NAME.ALL_VIDEOS_PRE_ORDER_FOOD
              }
            },
            {
              title: i18nHelper.getMessage(
                `label.${
                  this.category == CreationType.RECIPE
                    ? 'experienceCookings'
                    : 'preOrderFoods'
                }VideoList`
              )
            }
          ]
        };
        this.filters = [
          {
            label: 'category',
            key: 'category',
            value: this.category,
            isHidden: true
          },
          {
            label: 'name',
            key: 'name',
            value: ''
          },
          {
            label: 'id',
            key: 'id',
            value: ''
          },
          {
            label: 'createdBy',
            key: 'createdBy',
            value: ''
          }
        ];
        if (this.category == CreationType.RECIPE) {
          this.filters.push(
            {
              label: 'ecoPackOption',
              key: 'ecoPackOption',
              value: '',
              options: listService.getDynamicList(CONSTANTS_DATA.ecoPackOption),
              optionLabel: 'text',
              type: SMART_FORM_FIELD_TYPE.SELECT
            },
            {
              label: 'status',
              key: 'status',
              value: '',
              options: listService.getRecipeStatusList(),
              optionLabel: 'text',
              type: SMART_FORM_FIELD_TYPE.SELECT
            }
          );

          this.headers = [
            {
              key: 'no',
              name: 'no',
              active: true,
              sortable: true
            },
            {
              key: 'id',
              name: 'id',
              active: true,
              sortable: true
            },
            {
              key: 'name',
              name: 'recipeName',
              layout: APP_COMPONENT_NAME.APP_FEATURE_DETAILS_RELATED_BUTTON,
              config: {
                subMenu: 'ALL',
                to: ROUTE_NAME.EXPERIENCE_COOKING_DETAILS
              },
              active: true,
              sortable: true,
              class: 'min-w-120px'
            },
            {
              key: 'createdBy',
              name: 'createdBy',
              layout: APP_COMPONENT_NAME.APP_FEATURE_DETAILS_RELATED_BUTTON,
              config: {
                to: ROUTE_NAME.PROFILE_DETAILS_DETAILS
              },
              active: true,
              sortable: true
            },
            {
              key: 'cookingLevel',
              name: 'cookingLevel',
              active: true,
              sortable: true
            },
            {
              key: 'foodType',
              name: 'foodType',
              active: true,
              sortable: true
            },
            {
              key: 'status',
              name: 'status',
              active: true,
              sortable: true,
              layout: APP_COMPONENT_NAME.APP_ENUM_LABEL,
              config: {
                enumKey: 'enumRecipeStatus',
                statusColor: (value) => {
                  let color = {
                    [RecipeStatus.PENDING]: 'primary',
                    [RecipeStatus.VERIFIED]: 'success',
                    [RecipeStatus.DELETED]: 'warning'
                  };

                  return color[value];
                }
              }
            },
            {
              key: 'ecoPackOption',
              name: 'ecoPackOption',
              active: true,
              sortable: true
            },
            {
              key: 'priority',
              name: 'priority',
              active: true,
              sortable: true
            },
            {
              key: 'actions',
              name: 'actions',
              active: true,
              action: true,
              layout: APP_COMPONENT_NAME.APP_TABLE_ACTIONS,
              layoutActions: {
                isModal: true
              }
            }
          ];
        } else {
          this.headers = [
            {
              key: 'no',
              name: 'no',
              active: true,
              sortable: true
            },
            {
              key: 'id',
              name: 'id',
              active: true,
              sortable: true
            },
            {
              key: 'name',
              name: 'productName',
              layout: APP_COMPONENT_NAME.APP_FEATURE_DETAILS_RELATED_BUTTON,
              config: {
                subMenu: 'ALL',
                to: ROUTE_NAME.PRE_ORDER_FOOD_DETAILS
              },
              active: true,
              sortable: true,
              class: 'min-w-120px'
            },
            {
              key: 'createdBy',
              name: 'createdBy',
              layout: APP_COMPONENT_NAME.APP_FEATURE_DETAILS_RELATED_BUTTON,
              config: {
                to: ROUTE_NAME.PROFILE_DETAILS_DETAILS
              },
              active: true,
              sortable: true
            },
            {
              key: 'foodType',
              name: 'foodType',
              active: true,
              sortable: true
            },
            {
              key: 'priority',
              name: 'priority',
              active: true,
              sortable: true
            },
            {
              key: 'actions',
              name: 'actions',
              active: true,
              action: true,
              layout: APP_COMPONENT_NAME.APP_TABLE_ACTIONS,
              layoutActions: {
                isModal: true
              }
            }
          ];
        }
      },
      openModalDetails(value, obj) {
        this.openMaintenanceModal(value, obj);
      },
      openMaintenanceModal(id, obj) {
        this.priorityDetailsModalObj = {
          isShow: true,
          id: id,
          obj: obj
        };
      }
    }
  };
</script>

<style></style>
