<template>
  <StudioBookingDetailsTemplate></StudioBookingDetailsTemplate>
</template>

<script>
  import StudioBookingDetailsTemplate from '@/views/components/studio/StudioBookingDetailsTemplate.vue';

  export default {
    name: 'StudioBookingDetails',
    components: {
      StudioBookingDetailsTemplate
    }
  };
</script>

<style></style>
