<template>
  <b-card no-body data-style="custom">
    <!--begin: Wizard-->
    <div
      class="wizard wizard-2"
      id="initailWizard"
      data-wizard-state="step-first"
      data-wizard-clickable="true"
    >
      <!--begin: Wizard Nav -->
      <div class="wizard-nav border-right py-8 px-8 py-lg-20 px-lg-10">
        <div class="wizard-steps">
          <!--begin: Wizard Steps 1 -->
          <template
            v-for="(i, index) in wizardSteps.filter(
              (x) => x.hideCreate !== isCreate
            )"
          >
            <div
              :key="index"
              class="wizard-step"
              data-wizard-type="step"
              :data-wizard-state="index < 1 ? 'current' : ''"
            >
              <WizardWrapper
                :icon="i.icon"
                :title="$t(`label.${i.title}`)"
                :description="$t(`label.${i.description}`)"
              ></WizardWrapper>
            </div>
          </template>
          <!--end: Wizard Steps 1 -->
        </div>
      </div>
      <!--end: Wizard Nav -->

      <!--begin: Wizard Body -->
      <div class="wizard-body py-8 px-8 py-lg-20 px-lg-10">
        <!--begin: Wizard Form-->
        <b-row>
          <b-col offset-xl="2" xl="8" class="pb-5">
            <!--begin: Wizard Step 1 -->
            <div data-wizard-type="step-content" :data-wizard-state="'current'">
              <RecipePageForm
                ref="RecipePageForm"
                v-model="recipeObj"
                :id="id"
                :isCreate="isCreate"
                :category="category"
                :recipeObj="recipeObj"
                @getRecipeObj="getRecipeObj"
              ></RecipePageForm>
            </div>
            <div data-wizard-type="step-content" :data-wizard-state="''">
              <RecipePageMedia
                ref="RecipePageMedia"
                v-model="recipeObj"
                :id="id"
                :isCreate="isCreate"
                :category="category"
                :recipeObj="recipeObj"
              ></RecipePageMedia>
            </div>
            <div class="d-flex justify-content-between border-top pt-10">
              <div class="mr-2">
                <button
                  class="btn btn-light-primary font-weight-bold text-uppercase"
                  data-wizard-type="action-prev"
                >
                  {{ $t('label.previous') }}
                </button>
              </div>
              <div>
                <button
                  v-if="!isCreate"
                  :disabled="
                    !checkAccessRight(moduleName, [
                      permissionAction.RECIPE_PAGE_CREATE
                    ])
                  "
                  class="btn btn-danger font-weight-bold text-uppercase mr-3"
                  @click="deleteRecipePage(id)"
                >
                  {{ $t('label.delete') }}
                </button>
                <button
                  :disabled="
                    !checkAccessRight(moduleName, [
                      permissionAction.RECIPE_PAGE_CREATE
                    ])
                  "
                  class="btn btn-success font-weight-bold text-uppercase"
                  data-wizard-type="action-submit"
                  type="submit"
                >
                  {{ $t(`label.${isCreate ? 'create' : 'update'}`) }}
                </button>
                <button
                  class="btn btn-primary font-weight-bold text-uppercase"
                  data-wizard-type="action-next"
                >
                  {{ $t('label.nextStep') }}
                </button>
              </div>
            </div>
            <!--end: Wizard Step 1 -->
          </b-col>
          <!--end: Wizard-->
        </b-row>
      </div>
      <!--end: Wizard Body -->
    </div>
    <!--end: Wizard-->
  </b-card>
</template>

<script>
  import WizardWrapper from '@/views/components/wizard/WizardWrapper';
  import RecipePageForm from '@/views/components/recipe-page/RecipePageForm.vue';
  import RecipePageMedia from '@/views/components/recipe-page/RecipePageMedia.vue';
  import KTUtil from '@/assets/js/components/util';
  import KTWizard from '@/assets/js/components/wizard';
  import constantsMixin from '@/core/mixins/constants.mixin';
  import commonMixin from '@/core/mixins/common.mixin';
  import recipePageMixin from '@/core/mixins/recipe-page.mixin';
  import { i18nHelper } from '@/core/utils';
  import {
    GLOBAL,
    MODULE,
    ACTION
    // ROUTE_NAME,
  } from '@/core/constants';

  export default {
    name: 'RecipePageDetails',
    mixins: [commonMixin, constantsMixin, recipePageMixin],
    components: {
      WizardWrapper,
      RecipePageForm,
      RecipePageMedia
    },
    props: {
      isCreate: {
        Type: Boolean,
        defualt: false
      },
      category: {
        Type: String,
        defualt: ''
      }
    },
    data: () => ({
      moduleName: MODULE.RECIPE_PAGE,
      permissionAction: ACTION,
      global: GLOBAL,
      constWizard: {
        currentStep: 1
      },
      wizardSteps: [
        {
          step: 1,
          title: 'recipePageDetails',
          description: 'fillInInfoAndAction',
          icon: 'fab fa-wpforms',
          hideCreate: false
        },
        {
          step: 2,
          title: 'recipePageMedia',
          description: 'fillInInfoAndAction',
          icon: 'fab fa-wpforms',
          hideCreate: false
        }
      ],
      recipeObj: {
        recipeId: null,
        creatorName: null,
        ingredients: null,
        servePax: null,
        price: null,
        description: null,
        priority: null,
        thumbnail: null,
        topRecipe: false,
        topAssets: null
      }
    }),
    computed: {
      id() {
        return this.$route.params.id;
      }
    },
    watch: {},
    mounted() {
      this.initialRecipePage();
    },
    methods: {
      initWizard() {
        // Initialize form wizard
        let vm = this;

        const wizard = new KTWizard('initailWizard', {
          startStep: 1, // initial active step number
          clickableSteps: true // allow step clicking
        });

        vm.constWizard = wizard;

        wizard.on('submit', () => {
          vm.validationCheckBeforeSubmit();
        });

        //Change event
        wizard.on('change', function (/*wizardObj*/) {
          if (wizard.currentStep == 1) {
            let v1 = vm.$refs.RecipePageForm.validationCheckForm();
            let v2 = vm.$refs.RecipePageForm.validationActionValueCheck();

            if (!v1 || !v2) {
              wizard.stop();
            }
          }

          KTUtil.scrollTop();
        });
      },
      initialRecipePage() {
        let breadcrumbData = {
          breadcrumbs: [
            {
              title: i18nHelper.getMessage('label.recipePage'),
              route: { name: this.getRecipePageRouteName(this.category) }
            },
            {
              title: i18nHelper.getMessage(`label.${this.category}List`),
              route: { name: this.getRecipePageRouteName(this.category) }
            },
            {
              title: i18nHelper.getMessage(
                `label.${
                  this.isCreate ? 'createRecipePage' : 'recipePageDetails'
                }`
              )
            }
          ],
          actions: [
            {
              text: i18nHelper.getMessage('label.back'),
              function: () => {
                this.$router.go(-1);
              }
            }
          ]
        };
        this.initWizard();
        this.initBreadCrumb(breadcrumbData);
        !this.isCreate ?? this.getRecipePage(this.id);
      },
      getRecipeObj(value) {
        if (this.isCreate) {
          this.recipeObj = {
            recipeId: value.name,
            recipeName: value.name,
            creatorName: value.creatorName,
            ingredients: value.ingredients,
            servePax: value.servePax,
            price: value.price,
            description: value.description,
            priority: this.recipeObj.priority,
            thumbnail: value.thumbnail,
            topRecipe: false,
            topAssets: null,
            ...value
          };
        }
        // console.log(this.recipeObj);
        this.$refs.RecipePageMedia.recipeObj = {
          topRecipe: false,
          topAssets: ''
        };
      },
      validationCheckBeforeSubmit() {
        //Submit new banner form if all the validation is no error
        if (
          this.$refs.RecipePageForm.validationCheckForm() &&
          this.$refs.RecipePageMedia.validationCheckForm()
        ) {
          this.submitCreateRecipePageForm();
        }
      },
      submitCreateRecipePageForm() {
        let data = {
          ...this.recipeObj,
          category: this.getRecipeCategoryType(this.category),
          recipeId: this.isCreate ? this.recipeObj.id : this.recipeObj.recipeId
        };
        this.isCreate
          ? this.createRecipePage(data)
          : this.updateRecipePage(this.id, data);
      }
    }
  };
</script>

<style lang="scss">
  @import '@/assets/sass/pages/wizard/wizard-2.scss';
</style>
