<template>
  <!--begin::Login-->
  <div class="login d-flex flex-row-fluid full-screen" id="kt_login">
    <div
      class="login-container d-flex flex-center flex-row-fluid bgi-size-cover bgi-position-top bgi-no-repeat"
    >
      <div class="login-form text-center p-7 position-relative overflow-hidden">
        <!--begin::Login Header-->
        <div class="d-flex flex-center mb-10">
          <img src="/media/logos/logo.png" class="max-h-100px" alt="" />
        </div>
        <!--end::Login Header-->

        <!--begin::Login form-->
        <div class="login-signin">
          <div class="mb-20">
            <h3
              class="font-weight-bolder text-center font-size-h2 font-size-h1-lg"
            >
              {{ $t('title.projectName') }}
            </h3>
            <div class="text-muted font-weight-bold">
              {{ $t('label.loginMessage') }}
            </div>
          </div>
          <b-form @submit.stop.prevent="onLogin" id="kt_login_signin_form">
            <b-form-group class="form-group">
              <b-form-input
                v-model="$v.loginObj.username.$model"
                :placeholder="$t('label.username')"
                :state="validateLoginObjState('username')"
                type="text"
                size="lg"
                class="form-control form-control-solid"
              ></b-form-input>
              <b-form-invalid-feedback>
                {{ $t('fieldErrorMessage.requiredMinMaxLength', ['6', '11']) }}
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group class="form-group">
              <b-form-input
                v-model="$v.loginObj.password.$model"
                :placeholder="$t('label.password')"
                :state="validateLoginObjState('password')"
                type="password"
                size="lg"
                class="form-control form-control-solid"
              ></b-form-input>
              <b-form-invalid-feedback>
                {{ $t('fieldErrorMessage.requiredFieldDefault') }}
              </b-form-invalid-feedback>
            </b-form-group>
            <b-button
              ref="kt_login_signin_submit"
              variant="primary"
              type="submit"
              class="font-weight-bold font-size-h6 px-15 py-4 my-3 mx-4 text-capitalize"
            >
              {{ $t('label.signIn') }}
            </b-button>
          </b-form>
        </div>
        <!--end::Login form-->
      </div>
    </div>
  </div>
  <!--end::Login-->
</template>

<script>
  import { mapGetters } from 'vuex';
  import { validationMixin } from 'vuelidate';
  import { required, minLength, maxLength } from 'vuelidate/lib/validators';
  import { i18nHelper } from '@/core/utils';
  import { ROUTE_NAME } from '@/core/constants';
  import {
    LOOKUP_GET_USER_PERMISSIONS,
    LOOKUP_INITIAL_USER_PERMISSIONS_STATE
  } from '@/core/store/lookup.module';
  import {
    AUTH_LOGIN,
    AUTH_IS_AUTHENTICATED,
    AUTH_INITIAL_LOGIN_STATE
  } from '@/core/store/auth.module';

  export default {
    name: 'Login',
    metaInfo: {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: i18nHelper.getMessage('metaInfo.title.login'),
      // all titles will be injected into this template
      titleTemplate: `%s | ${i18nHelper.getMessage('title.projectName')}`
    },
    mixins: [validationMixin],
    data: () => ({
      loginCompleteMessage: '',
      showPassword: false,
      loginObj: {
        username: '',
        password: ''
      }
    }),
    computed: {
      ...mapGetters({
        isAuthenticated: [AUTH_IS_AUTHENTICATED]
      }),
      loginComplete() {
        return this.$store.state.auth.loginResponse.complete;
      },
      userPermissionsComplete() {
        return this.$store.state.lookup.userPermissions.complete;
      }
    },
    watch: {
      async loginComplete() {
        let response = this.$store.state.auth.loginResponse;
        if (response.complete) {
          this.loginCompleteAction(response);
        }
      },
      userPermissionsComplete() {
        let response = this.$store.state.lookup.userPermissions;
        if (response.complete) {
          this.userPermissionsCompleteAction(response);
        }
      }
    },
    methods: {
      userPermissionsCompleteAction(response) {
        if (response.code == 0) {
          this.$toast.success(this.loginCompleteMessage);
          this.$router.push({ name: ROUTE_NAME.HOME });
        } else {
          this.$toast.error(response.message);
        }
        this.initialGetUserPermissionsState();
        this.removeButtonSpinner();
      },
      async loginCompleteAction(response) {
        if (response.code == 0 && this.isAuthenticated) {
          this.loginCompleteMessage = response.message;
          this.getUserPermissions();
        } else {
          this.removeButtonSpinner();
          this.$toast.error(response.message);
          this.initialLoginState();
        }
      },
      removeButtonSpinner() {
        const submitButton = this.$refs['kt_login_signin_submit'];

        submitButton.classList.remove(
          'spinner',
          'spinner-light',
          'spinner-right'
        );
      },
      onLogin() {
        let submitButton = this.$refs['kt_login_signin_submit'];
        let data = {
          username: this.loginObj.username,
          password: this.loginObj.password
        };

        this.$v.loginObj.$touch();

        if (!this.$v.loginObj.$anyError) {
          submitButton.classList.add(
            'spinner',
            'spinner-light',
            'spinner-right'
          );
          this.login(data);
        }
      },
      validateLoginObjState(name) {
        const { $dirty, $error } = this.$v.loginObj[name];
        return $dirty ? !$error : null;
      },
      login(data) {
        this.$store.dispatch(AUTH_LOGIN, { data });
      },
      getUserPermissions() {
        this.$store.dispatch(LOOKUP_GET_USER_PERMISSIONS);
      },
      initialLoginState() {
        this.$store.dispatch(AUTH_INITIAL_LOGIN_STATE);
      },
      initialGetUserPermissionsState() {
        this.$store.dispatch(LOOKUP_INITIAL_USER_PERMISSIONS_STATE);
      }
    },
    validations: {
      loginObj: {
        password: {
          required
        },
        username: {
          required,
          minLength: minLength(6),
          maxLength: maxLength(11)
        }
      }
    }
  };
</script>

<style lang="scss">
  .login .login-form {
    width: 100%;
    max-width: 450px;
  }

  .login-container {
    background-image: url('/media/bg/bg-3.jpg');
  }

  @media (max-width: 599.98px) {
    .login .login-form {
      width: 100%;
      max-width: 100%;
    }
  }
</style>
