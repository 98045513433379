import { apiService } from '@/core/services';
import { RecipeStatus } from '@/core/constants/enums';

export const MOBILE_APPLICATION = {
  getMobileApplicationAllVideos,
  getMobileApplicationEcoPacks,
  getMobileApplicationMerchants,
  updateMobileApplicationAllVideo,
  updateMobileApplicationEcoPack,
  updateMobileApplicationMerchant
};

const URL = {
  ADMIN: 'admin',
  MOBILE_APPLICATIONS: 'mobile-applications',
  ALL_VIDEOS: 'all-videos',
  ECO_PACKS: 'eco-packs',
  MERCHANTS: 'merchants'
};

function getMobileApplicationAllVideos(data) {
  let status = data.status;
  let isVerified = null;
  let isDeleted = null;

  switch (status) {
    case RecipeStatus.PENDING:
      isVerified = 0;
      break;
    case RecipeStatus.VERIFIED:
      isVerified = 1;
      break;
    case RecipeStatus.DELETED:
      isDeleted = 1;
      break;
  }
  let requestOptions = {
    params: {
      item: data.category,
      id: data.id,
      name: data.name,
      username: data.createdBy,
      eco_pack_option: data.ecoPackOption,
      is_verified: isVerified,
      is_deleted: isDeleted,
      per_page: data.perPage,
      page: data.page
    }
  };
  return apiService().get(
    `/${URL.ADMIN}/${URL.MOBILE_APPLICATIONS}/${URL.ALL_VIDEOS}`,
    requestOptions
  );
}

function getMobileApplicationEcoPacks(data) {
  let status = data.status;
  let isVerified = null;
  let isDeleted = null;

  switch (status) {
    case RecipeStatus.PENDING:
      isVerified = 0;
      break;
    case RecipeStatus.VERIFIED:
      isVerified = 1;
      break;
    case RecipeStatus.DELETED:
      isDeleted = 1;
      break;
  }
  let requestOptions = {
    params: {
      item: data.category,
      id: data.id,
      name: data.name,
      username: data.createdBy,
      eco_pack_option: data.ecoPackOption,
      is_verified: isVerified,
      is_deleted: isDeleted,
      per_page: data.perPage,
      page: data.page
    }
  };

  return apiService().get(
    `/${URL.ADMIN}/${URL.MOBILE_APPLICATIONS}/${URL.ECO_PACKS}`,
    requestOptions
  );
}
function getMobileApplicationMerchants(data) {
  let requestOptions = {
    params: {
      id: data.id,
      service_type: data.category,
      name: data.name,
      merchant_type: data.merchantType,
      per_page: data.perPage,
      page: data.page
    }
  };
  return apiService().get(
    `/${URL.ADMIN}/${URL.MOBILE_APPLICATIONS}/${URL.MERCHANTS}`,
    requestOptions
  );
}

function updateMobileApplicationAllVideo(id, data) {
  let requestBody = {
    item: data.category, // 1 - pre order food, 2 - experienced cook
    priority: data.priority
  };
  return apiService().patch(
    `/${URL.ADMIN}/${URL.MOBILE_APPLICATIONS}/${URL.ALL_VIDEOS}/${id}`,
    requestBody
  );
}

function updateMobileApplicationEcoPack(id, data) {
  let requestBody = {
    priority: data.priority
  };
  return apiService().patch(
    `/${URL.ADMIN}/${URL.MOBILE_APPLICATIONS}/${URL.ECO_PACKS}/${id}`,
    requestBody
  );
}

function updateMobileApplicationMerchant(id, data) {
  let requestBody = {
    service_type: data.category != 3 ? data.category : null,
    merchant_id: data.merchantId, // The merchant id field is required when creator id is not present.
    creator_id: data.creatorId, // The creator id field is required when merchant id is not present.
    priority: data.priority
  };

  return apiService().post(
    `/${URL.ADMIN}/${URL.MOBILE_APPLICATIONS}/${URL.MERCHANTS}`,
    requestBody
  );
}
