<template>
  <div>
    <b-row no-gutters class="w-100 mt-4">
      <!--begin: Col 1-->
      <b-col cols="12" md="4">
        <b-card data-style="custom">
          <!--begin: Creator Information-->

          <div class="d-flex justify-content-between">
            <b-card-title class="text-capitalize">
              {{ $t('label.creatorInformation') }}
            </b-card-title>

            <!-- begin: Actions Dropdown -->
            <div>
              <b-dropdown
                :disabled="isActionsDisabled"
                size="sm"
                :text="$t('label.actions')"
                variant="primary"
                :toggle-class="[
                  'text-decoration-none',
                  { 'cursor-default': isActionsDisabled }
                ]"
              >
                <b-dropdown-item
                  :disabled="!isApplicationSubmitted"
                  @click="openActionRequestModal(true)"
                  >{{ $t('label.approve') }}</b-dropdown-item
                >
                <b-dropdown-item
                  variant="danger"
                  :disabled="!isApplicationSubmitted"
                  @click="openActionRequestModal(false)"
                  >{{ $t('label.reject') }}</b-dropdown-item
                >
              </b-dropdown>
            </div>
            <!-- end: Actions Dropdown -->
          </div>

          <b-card-text>
            <template v-for="(i, index) in creatorInformationSection">
              <div :key="i + index" class="mb-5">
                <div class="font-weight-bolder text-capitalize mb-2">
                  {{ $t(`label.${i.name}`) }}:
                </div>
                <div class="text-gray-600">
                  <template v-if="i.field == 'address'">
                    {{ creatorApplicationObj.address[i.key] }}
                  </template>
                  <template v-else-if="i.layout">
                    <component
                      :is="i.layout"
                      :value="creatorApplicationObj[i.key]"
                      :config="i.config"
                    ></component>
                  </template>
                  <template v-else>
                    {{ creatorApplicationObj[i.key] }}
                  </template>
                </div>
              </div>
              <div
                v-if="i.key == 'remark'"
                :key="index + 'separator'"
                class="separator separator-solid my-6"
              ></div>
            </template>
          </b-card-text>
        </b-card>
      </b-col>
      <!--end: Col 1-->

      <!--begin: Col 2-->
      <b-col cols="12" md="8" class="mt-4 mt-md-0 pl-md-4 pl-lg-8">
        <!--begin: Attachments-->
        <ApplicationAttachmentCard
          :title="$t('label.attachments')"
          :id="id"
          :value="creatorApplicationObj.documents"
          :creatorApplicationObj="creatorApplicationObj"
          :actionDisabled="isUploadActionsDisabled"
          class="mb-4"
        ></ApplicationAttachmentCard>
        <!--end: Attachments-->

        <!--begin: Existing Attachments-->
        <ApplicationAttachmentCard
          :title="$t('label.existingAttachments')"
          :id="id"
          :value="creatorApplicationObj.existingDocuments"
          :creatorApplicationObj="creatorApplicationObj"
          noUpload
        ></ApplicationAttachmentCard>
        <!--end: Existing Attachments-->
      </b-col>
      <!--end: Col 2-->
    </b-row>

    <!--begin: Preview Attachment Modal-->
    <b-modal
      v-model="previewAttachment.modalShow"
      centered
      ok-only
      :title="previewAttachment.title"
    >
      <div class="review-attachment-modal-img-container">
        <b-img fluid-grow :src="previewAttachment.url"></b-img>
      </div>
    </b-modal>
    <!--end: Preview Attachment Modal-->
  </div>
</template>

<script>
  import commonMixin from '@/core/mixins/common.mixin';
  import AppButtonDocumentView from '@/views/components/bases/button/AppButtonDocumentView';
  import AppEnumLabel from '@/views/components/bases/AppEnumLabel';
  import ApplicationUploadDocumentDropdown from '@/views/components/application/ApplicationUploadDocumentDropdown';
  import ApplicationAttachmentCard from '@/views/components/application/ApplicationAttachmentCard';
  import {
    ROUTE_NAME,
    MODULE,
    ACTION,
    APP_COMPONENT_NAME
  } from '@/core/constants';
  import { CreatorType } from '@/core/constants/enums';
  import { i18nHelper } from '@/core/utils';
  import {
    CREATOR_APPLICATION_GET_CREATOR_APPLICATION,
    CREATOR_APPLICATION_APPROVE_CREATOR_APPLICATION,
    CREATOR_APPLICATION_REJECT_CREATOR_APPLICATION,
    CREATOR_APPLICATION_REJECT_REASON,
    CREATOR_APPLICATION_INITIAL_GET_CREATOR_APPLICATION_STATE,
    CREATOR_APPLICATION_INITIAL_APPROVE_CREATOR_APPLICATION_STATE,
    CREATOR_APPLICATION_INITIAL_REJECT_CREATOR_APPLICATION_STATE
  } from '@/core/store/creator-application.module';

  export default {
    name: 'ApplicationDetails',
    mixins: [commonMixin],
    components: {
      AppButtonDocumentView,
      AppEnumLabel,
      ApplicationUploadDocumentDropdown,
      ApplicationAttachmentCard
    },
    data: () => ({
      routeName: ROUTE_NAME,
      moduleName: MODULE.CREATOR_APPLICATION,
      permissionAction: ACTION,
      id: null,
      previewAttachment: {
        modalShow: false,
        title: '',
        url: null
      },
      creatorInformationSection: [
        {
          key: 'username',
          name: 'username'
        },
        {
          key: 'creatorName',
          name: 'personInChargeFullName'
        },
        {
          key: 'contactNumber',
          name: 'contactNumber',
          field: 'address'
        },
        {
          key: 'contactEmail',
          name: 'email',
          field: 'address'
        },
        {
          key: 'creatorType',
          name: 'creatorType',
          layout: APP_COMPONENT_NAME.APP_ENUM_LABEL,
          config: {
            enumKey: 'enumCreatorType',
            statusColor: (value) => {
              let color = {
                [CreatorType.INDIVIDUAL]: 'primary',
                [CreatorType.CORPORATE]: 'warning'
              };

              return color[value];
            }
          }
        },
        {
          key: 'storeName',
          name: 'storeName'
        },
        {
          key: 'placeName',
          name: 'placeName',
          field: 'address'
        },
        {
          key: 'address',
          name: 'businessAddress',
          field: 'address'
        },
        {
          key: 'postcode',
          name: 'postcode',
          field: 'address'
        },
        {
          key: 'city',
          name: 'city',
          field: 'address'
        },
        {
          key: 'state',
          name: 'state',
          field: 'address'
        },
        {
          key: 'remark',
          name: 'remark',
          field: 'address'
        },
        {
          key: 'personInCharge',
          name: 'personInCharge'
        },
        {
          key: 'submittedAt',
          name: 'submittedAt'
        },
        {
          key: 'approvedAt',
          name: 'approvedAt'
        },
        {
          key: 'rejectedAt',
          name: 'rejectedAt'
        },
        {
          key: 'createdAt',
          name: 'createdAt'
        },
        {
          key: 'updatedAt',
          name: 'updatedAt'
        }
      ],
      applicationDateTimeSection: [
        {
          key: 'submittedAt',
          name: 'submittedAt'
        },
        {
          key: 'approvedAt',
          name: 'approvedAt'
        },
        {
          key: 'rejectedAt',
          name: 'rejectedAt'
        },
        {
          key: 'createdAt',
          name: 'createdAt'
        },
        {
          key: 'updatedAt',
          name: 'updatedAt'
        }
      ],
      attachmentTable: [
        {
          key: 'no',
          name: 'no'
        },
        {
          key: 'originalUrl',
          name: 'preview'
        },
        {
          key: 'documentType',
          name: 'documentType'
        },
        {
          key: 'actions',
          name: 'actions'
        }
      ],
      creatorApplicationObj: {
        id: null,
        userId: null,
        username: null,
        storeName: null,
        hashtag: null,
        isApproved: true,
        isRejected: true,
        submittedAt: null,
        approvedAt: null,
        rejectedAt: null,
        createdAt: null,
        updatedAt: null,
        businessUrl: null,
        documents: [],
        address: {},
        creatorType: null
      }
    }),
    computed: {
      creatorApplicationComplete() {
        return this.$store.state.creatorApplication.creatorApplication.complete;
      },
      approveCreatorApplicationComplete() {
        return this.$store.state.creatorApplication.approveCreatorApplication
          .complete;
      },
      rejectCreatorApplicationComplete() {
        return this.$store.state.creatorApplication.rejectCreatorApplication
          .complete;
      },
      isApplicationSubmitted() {
        return this.creatorApplicationObj.submittedAt != '-';
      },
      isActionsDisabled() {
        let applicationObj = this.creatorApplicationObj;

        return (
          applicationObj.isApproved ||
          !this.checkAccessRight(MODULE.CREATOR_APPLICATION, [
            ACTION.CREATOR_APPLICATION_UPDATE
          ])
        );
      },
      isUploadActionsDisabled() {
        return !this.checkAccessRight(MODULE.CREATOR_APPLICATION, [
          ACTION.CREATOR_APPLICATION_UPDATE
        ]);
      }
    },
    watch: {
      creatorApplicationComplete() {
        let response = this.$store.state.creatorApplication.creatorApplication;
        if (response.complete) {
          this.getCreatorApplicationCompleteAction(response);
        }
      },
      approveCreatorApplicationComplete() {
        let response =
          this.$store.state.creatorApplication.approveCreatorApplication;
        if (response.complete) {
          this.approveCreatorApplicationCompleteAction(response);
        }
      },
      rejectCreatorApplicationComplete() {
        let response =
          this.$store.state.creatorApplication.rejectCreatorApplication;
        if (response.complete) {
          this.rejectCreatorApplicationCompleteAction(response);
        }
      }
    },
    created() {
      this.initialCreatorApplicationDetails();
      this.getRejectReasonApplication();
    },
    methods: {
      uploadDocumentCompleteAction() {
        this.getCreatorApplication(this.id);
      },
      approveCreatorApplicationCompleteAction(response) {
        let title = i18nHelper.getMessage('label.approveApplication');
        let messages = response.message;
        let buttons = [];

        buttons.push({
          text: i18nHelper.getMessage('label.ok'),
          variant: 'primary',
          action: () => {
            this.getCreatorApplication(this.id);
            this.layoutGetNotification();
            this.closeLayoutAppModalInfo();
          }
        });
        this.openLayoutAppModalInfo(title, messages, buttons);

        this.initialApproveCreatorApplicationState();
      },
      rejectCreatorApplicationCompleteAction(response) {
        let title = i18nHelper.getMessage('label.rejectApplication');
        let messages = response.message;
        let buttons = [];

        buttons.push({
          text: i18nHelper.getMessage('label.ok'),
          variant: 'primary',
          action: () => {
            this.getCreatorApplication(this.id);
            this.layoutGetNotification();
            this.closeLayoutAppModalInfo();
          }
        });
        this.openLayoutAppModalInfo(title, messages, buttons);

        this.initialRejectCreatorApplicationState();
      },
      getCreatorApplicationCompleteAction(response) {
        let data = response.data;
        let title = i18nHelper.getMessage('label.applicationDetails');
        let messages = response.message;
        let buttons = [];

        if (response.code == 0) {
          this.creatorApplicationObj = data;
        } else {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();
            }
          });
          this.openLayoutAppModalInfo(title, messages, buttons);
        }
        this.initialGetCreatorApplicationState();
      },
      openActionRequestModal(isApprove) {
        let title = i18nHelper.getMessage(
          `label.${isApprove ? 'approve' : 'reject'}Application`
        );

        let messages = [];
        let buttons = [];
        let code = [];
        let textbox = '';
        let dropbox = [];
        messages.push(
          i18nHelper.getMessage('message.requestUpdateApplicationMessage', [
            i18nHelper
              .getMessage(`label.${isApprove ? 'approve' : 'reject'}`)
              .toLowerCase()
          ])
        );

        buttons.push({
          text: i18nHelper.getMessage('label.cancel'),
          variant: '',
          action: () => {
            this.closeLayoutAppModalInfo();
          }
        });

        if (isApprove) {
          buttons.push({
            text: i18nHelper.getMessage('label.approve'),
            variant: 'success',
            action: () => {
              this.closeLayoutAppModalInfo();
              this.approveCreatorApplication(this.id);
            }
          });
        } else {
          let result = this.$store.state.creatorApplication.rejectReason;
          let reason = result.data;

          textbox = '1';
          messages.push(i18nHelper.getMessage(`label.reason`));

          for (var i = 0; i < reason.length; i++) {
            dropbox.push({
              text: reason[i],
              action: () => {}
            });
          }

          buttons.push({
            text: i18nHelper.getMessage('label.reject'),
            variant: 'danger',
            action: () => {
              this.closeLayoutAppModalInfo();
              this.rejectCreatorApplication(this.id);
            }
          });
        }

        this.openLayoutAppModalInfo(
          title,
          messages,
          buttons,
          code,
          textbox,
          dropbox
        );
      },
      isImage(type) {
        let isImage = false;

        if (type == 'jpg' || type == 'png' || type == 'jpeg') {
          isImage = true;
        }

        return isImage;
      },
      attachmentImage(type, originalUrl) {
        let src = '';

        if (type == 'jpg' || type == 'png' || type == 'jpeg') {
          src = originalUrl;
        } else if (type == 'pdf') {
          src = '/media/svg/files/pdf.svg';
        } else if (type == 'docx' || type == 'doc') {
          src = '/media/svg/files/doc.svg';
        }
        return src;
      },
      layoutGetNotification() {
        this.$root.$children[0].$refs.layout.getNotification();
      },
      approveCreatorApplication(id) {
        this.$store.dispatch(CREATOR_APPLICATION_APPROVE_CREATOR_APPLICATION, {
          id
        });
      },
      rejectCreatorApplication(id) {
        let rejected_reason = document.getElementById('selectReason').value;
        let rejected_remark = document.getElementById('txtReason').value;

        const data = {
          rejected_reason: rejected_reason,
          rejected_remark: rejected_remark
        };

        this.$store.dispatch(CREATOR_APPLICATION_REJECT_CREATOR_APPLICATION, {
          id,
          data
        });
      },
      getCreatorApplication(id) {
        this.$store.dispatch(CREATOR_APPLICATION_GET_CREATOR_APPLICATION, {
          id
        });
      },
      getRejectReasonApplication() {
        this.$store.dispatch(CREATOR_APPLICATION_REJECT_REASON);
      },
      initialGetCreatorApplicationState() {
        this.$store.dispatch(
          CREATOR_APPLICATION_INITIAL_GET_CREATOR_APPLICATION_STATE
        );
      },
      initialApproveCreatorApplicationState() {
        this.$store.dispatch(
          CREATOR_APPLICATION_INITIAL_APPROVE_CREATOR_APPLICATION_STATE
        );
      },
      initialRejectCreatorApplicationState() {
        this.$store.dispatch(
          CREATOR_APPLICATION_INITIAL_REJECT_CREATOR_APPLICATION_STATE
        );
      },
      initialCreatorApplicationDetails() {
        let id = Number(this.$route.params.id);
        let breadcrumbData = {
          breadcrumbs: [
            {
              title: i18nHelper.getMessage('label.application'),
              route: { name: ROUTE_NAME.APPLICATION }
            },
            { title: i18nHelper.getMessage('label.applicationDetails') }
          ],
          actions: [
            {
              text: i18nHelper.getMessage('label.back'),
              function: () => {
                this.$router.go(-1);
              }
            }
          ]
        };

        this.id = id;
        this.getCreatorApplication(id);
        this.initBreadCrumb(breadcrumbData);
      }
    }
  };
</script>

<style lang="scss">
  .document {
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .document-image {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }
</style>
