<template>
  <!--begin: Wizard Step 1-->
  <div>
    <b-form class="form" id="kt_form">
      <b-row no-gutters>
        <template v-for="(i, index) in formFields.filter((x) => x.field == 1)">
          <b-col
            :key="index"
            xl="6"
            class="p-xl-1"
            v-if="i.noCreate !== isCreate || !isCreate"
          >
            <AppFormField
              v-model="$v.studioBookingInfo[i.model].$model"
              :state="validateObjState('studioBookingInfo', i.model)"
              :validationValue="$v.studioBookingInfo[i.model]"
              :title="$t(`label.${i.title}`)"
              :validators="i.validators"
              :formType="i.formType"
              :config="i.config"
              :validationParams="i.validationParams"
              :moreRemark="i.moreRemark"
              :isRequires="i.isRequires"
              :prepend="i.prepend"
              :disabled="i.disabled"
              :solid="!i.solid"
              :min="i.min"
            >
              <template v-slot:prepend>
                <span class="input-group-text text-uppercase">+60</span>
              </template></AppFormField
            >

            <div style="display: none">
              <!-- WARNING: I have no idea why this line is here, but removing it breaks everything -->
              {{ $v }}
            </div>
          </b-col>
        </template>
      </b-row>
    </b-form>
    <!--begin: Wizard Actions -->
    <div class="d-flex justify-content-end border-top pt-10">
      <div>
        <StudioBookingStatusBtn
          v-if="!isCreate"
          :id="id"
          :studioBookingInfo="studioBookingInfo"
        ></StudioBookingStatusBtn>

        <button
          :disabled="
            !checkAccessRight(moduleName, [
              isCreate
                ? permissionAction.STUDIO_CREATE
                : permissionAction.STUDIO_UPDATE
            ])
          "
          @click="validationCheckBeforeSubmit()"
          class="btn btn-success font-weight-bold text-uppercase"
        >
          {{ $t(`label.${isCreate ? 'create' : 'update'}`) }}
        </button>
      </div>
    </div>
  </div>
  <!--end: Wizard Actions -->
  <!--end: Wizard Step 1-->
</template>

<script>
  import AppFormField from '@/views/components/bases/form-field/AppFormField';
  import StudioBookingStatusBtn from '@/views/components/studio/StudioBookingStatusBtn.vue';
  import constantsMixin from '@/core/mixins/constants.mixin';
  import commonMixin from '@/core/mixins/common.mixin';
  import { i18nHelper } from '@/core/utils';
  import { validationMixin } from 'vuelidate';
  import {
    GLOBAL,
    MODULE,
    ACTION,
    ROUTE_NAME,
    mobileNumber
  } from '@/core/constants';
  import { required, numeric, email } from 'vuelidate/lib/validators';
  import {
    STUDIO_CREATE_BOOKING,
    STUDIO_UPDATE_BOOKING,
    STUDIO_INITIAL_CREATE_BOOKING_STATE,
    STUDIO_INITIAL_UPDATE_BOOKING_STATE
  } from '@/core/store/studio.module';

  export default {
    name: 'StudioBookingForm',
    mixins: [commonMixin, constantsMixin, validationMixin],
    components: {
      AppFormField,
      StudioBookingStatusBtn
    },
    props: {
      studioBookingInfo: {
        type: Object,
        default: () => {}
      },
      isCreate: {
        type: Boolean,
        default: false
      },
      id: {}
    },
    data: () => ({
      moduleName: MODULE.STUDIO,
      permissionAction: ACTION,
      global: GLOBAL,
      routeName: ROUTE_NAME,
      formFields: []
    }),
    mounted() {
      this.initFormFields();
    },
    computed: {
      createStudioBookingComplete() {
        return this.$store.state.studio.createBooking.complete;
      },
      updateStudioBookingComplete() {
        return this.$store.state.studio.updateBooking.complete;
      }
    },
    watch: {
      createStudioBookingComplete() {
        let response = this.$store.state.studio.createBooking;
        if (response.complete) {
          this.studioBookingCompleteAction(response, 'create');
        }
      },
      updateStudioBookingComplete() {
        let response = this.$store.state.studio.updateBooking;
        if (response.complete) {
          this.studioBookingCompleteAction(response, 'update');
        }
      }
    },
    methods: {
      studioBookingCompleteAction(response, actionType) {
        let title = i18nHelper.getMessage('label.studioBooking');
        let messages = response.message;
        let buttons = [];
        switch (actionType) {
          case 'create':
            this.initialCreateStudioBookingState();
            break;
          case 'update':
            this.initialUpdateStudioBookingState();
            break;
        }
        if (response.code == 0) {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();

              switch (actionType) {
                case 'create':
                  this.$router.push({
                    name: ROUTE_NAME.STUDIO_BOOKING_DETAILS,
                    params: { id: response.data.id }
                  });
                  break;
                default:
                  this.$parent.getStudioBooking(this.id);
              }
            }
          });
        } else {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();
            }
          });
        }
        if (messages) this.openLayoutAppModalInfo(title, messages, buttons);
      },
      initFormFields() {
        this.formFields.push(
          {
            field: 1,
            model: 'bookingId',
            title: 'bookingId',
            disabled: true,
            solid: true,
            noCreate: true
          },
          {
            field: 1,
            model: 'name',
            title: 'contactPersonName',
            validators: ['required'],
            isRequires: true
          },
          {
            field: 1,
            model: 'contactNumber',
            title: 'contactNumber',
            validators: ['required', 'mobileNumber', 'numeric'],
            isRequires: true,
            prepend: true
          },
          {
            field: 1,
            model: 'companyName',
            title: 'companyName'
          },
          {
            field: 1,
            model: 'email',
            title: 'email',
            validators: ['email']
          },
          {
            field: 1,
            model: 'preferredStart',
            title: 'preferredStartDateTime',
            formType: 'dateTime',
            validators: ['required'],
            min: new Date(),
            config: {
              isStartDateTime: true
            },
            isRequires: true
          },
          {
            field: 1,
            model: 'preferredEnd',
            title: 'preferredEndDateTime',
            formType: 'dateTime',
            validators: ['required'],
            min: new Date(),
            config: {
              isEndDateTime: true
            },
            isRequires: true
          },
          {
            field: 1,
            model: 'remark',
            title: 'remark'
          },
          {
            field: 1,
            model: 'numberOfPax',
            title: 'numberOfPax',
            validators: ['numeric']
          }
        );
      },
      validationCheckBeforeSubmit() {
        //Submit new banner form if all the validation is no error
        if (this.validationCheck('studioBookingInfo')) {
          this.submitCreateStudioBookingForm();
        }
      },
      submitCreateStudioBookingForm() {
        var data = this.studioBookingInfo;

        this.isCreate
          ? this.createStudioBooking(data)
          : this.updateStudioBooking(this.id, data);
      },
      createStudioBooking(data) {
        this.$store.dispatch(STUDIO_CREATE_BOOKING, { data });
      },
      updateStudioBooking(id, data) {
        this.$store.dispatch(STUDIO_UPDATE_BOOKING, { id, data });
      },
      initialCreateStudioBookingState() {
        this.$store.dispatch(STUDIO_INITIAL_CREATE_BOOKING_STATE);
      },
      initialUpdateStudioBookingState() {
        this.$store.dispatch(STUDIO_INITIAL_UPDATE_BOOKING_STATE);
      }
    },
    validations() {
      return {
        studioBookingInfo: {
          bookingId: {},
          name: {
            required
          },
          contactNumber: {
            required,
            mobileNumber,
            numeric
          },
          companyName: {},
          email: {
            email
          },
          preferredStart: {
            required
          },
          preferredEnd: {
            required
          },
          remark: {},
          numberOfPax: {
            numeric
          }
        }
      };
    }
  };
</script>

<style></style>
