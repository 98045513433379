import dateTimeFormat from '@/core/filters/dateTimeFormat.filter';
import { OFF_DAY_SETTING } from '@/core/api';
import { apiHelper } from '@/core/utils';
import {
  LOADING_OPEN_LOADING,
  LOADING_CLOSE_LOADING
} from '@/core/store/loading.module';

/* Define Module Name */
const MODULE_NAME = 'offDaySetting/';

/* Define Action Name */
const A_GET_OFF_DAY_SETTINGS = 'getOffDaySettings';
const A_GET_OFF_DAY_SETTING = 'getOffDaySetting';
const A_CREATE_OFF_DAY_SETTING = 'createOffDaySetting';
const A_UPDATE_OFF_DAY_SETTING = 'updateOffDaySetting';
const A_DELETE_OFF_DAY_SETTING = 'deleteOffDaySetting';

/* Define Reset State Action Name */
const A_INITIAL_GET_OFF_DAY_SETTINGS_STATE = 'initialGetOffDaySettingsState';
const A_INITIAL_GET_OFF_DAY_SETTING_STATE = 'initialGetOffDaySettingState';
const A_INITIAL_CREATE_OFF_DAY_SETTING_STATE =
  'initialCreateOffDaySettingState';
const A_INITIAL_UPDATE_OFF_DAY_SETTING_STATE =
  'initialUpdateOffDaySettingState';
const A_INITIAL_DELETE_OFF_DAY_SETTING_STATE =
  'initialDeleteOffDaySettingState';

/* Define Getter Name */

/* Define Mutation Name */
const M_GET_OFF_DAY_SETTINGS = 'setGetOffDaySettings';
const M_GET_OFF_DAY_SETTING = 'setGetOffDaySetting';
const M_CREATE_OFF_DAY_SETTING = 'setCreateOffDaySetting';
const M_UPDATE_OFF_DAY_SETTING = 'setUpdateOffDaySetting';
const M_DELETE_OFF_DAY_SETTING = 'setDeleteOffDaySetting';

/* Define Reset State Mutation Name */
const M_INITIAL_GET_OFF_DAY_SETTINGS_STATE = 'setInitialGetOffDaySettingsState';
const M_INITIAL_GET_OFF_DAY_SETTING_STATE = 'setInitialGetOffDaySettingState';
const M_INITIAL_CREATE_OFF_DAY_SETTING_STATE =
  'setInitialCreateOffDaySettingState';
const M_INITIAL_UPDATE_OFF_DAY_SETTING_STATE =
  'setInitialUpdateOffDaySettingState';
const M_INITIAL_DELETE_OFF_DAY_SETTING_STATE =
  'setInitialDeleteOffDaySettingState';

/* Define Export Name */
export const OFF_DAY_SETTING_GET_OFF_DAY_SETTINGS =
  MODULE_NAME + A_GET_OFF_DAY_SETTINGS;
export const OFF_DAY_SETTING_GET_OFF_DAY_SETTING =
  MODULE_NAME + A_GET_OFF_DAY_SETTING;
export const OFF_DAY_SETTING_CREATE_OFF_DAY_SETTING =
  MODULE_NAME + A_CREATE_OFF_DAY_SETTING;
export const OFF_DAY_SETTING_UPDATE_OFF_DAY_SETTING =
  MODULE_NAME + A_UPDATE_OFF_DAY_SETTING;
export const OFF_DAY_SETTING_DELETE_OFF_DAY_SETTING =
  MODULE_NAME + A_DELETE_OFF_DAY_SETTING;

/* Define Reset State Name */
export const OFF_DAY_SETTING_INITIAL_GET_OFF_DAY_SETTINGS_STATE =
  MODULE_NAME + A_INITIAL_GET_OFF_DAY_SETTINGS_STATE;
export const OFF_DAY_SETTING_INITIAL_GET_OFF_DAY_SETTING_STATE =
  MODULE_NAME + A_INITIAL_GET_OFF_DAY_SETTING_STATE;
export const OFF_DAY_SETTING_INITIAL_CREATE_OFF_DAY_SETTING_STATE =
  MODULE_NAME + A_INITIAL_CREATE_OFF_DAY_SETTING_STATE;
export const OFF_DAY_SETTING_INITIAL_UPDATE_OFF_DAY_SETTING_STATE =
  MODULE_NAME + A_INITIAL_UPDATE_OFF_DAY_SETTING_STATE;
export const OFF_DAY_SETTING_INITIAL_DELETE_OFF_DAY_SETTING_STATE =
  MODULE_NAME + A_INITIAL_DELETE_OFF_DAY_SETTING_STATE;

const state = {
  offDaySettings: {
    code: 0,
    complete: false,
    data: null,
    message: null,
    pagination: {
      currentPage: 0,
      total: 0,
      perPage: 0,
      lastPage: 0
    }
  },
  offDaySetting: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  createOffDaySetting: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  updateOffDaySetting: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  deleteOffDaySetting: {
    code: 0,
    complete: false,
    data: null,
    message: null
  }
};

//Getters
const getters = {};

// Actions
const actions = {
  [A_GET_OFF_DAY_SETTINGS]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    OFF_DAY_SETTING.getOffDaySettings(data)
      .then((response) => {
        result = response;
        commit(M_GET_OFF_DAY_SETTINGS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_OFF_DAY_SETTINGS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_OFF_DAY_SETTING]({ dispatch, commit }, { id }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    OFF_DAY_SETTING.getOffDaySetting(id)
      .then((response) => {
        result = response;
        commit(M_GET_OFF_DAY_SETTING, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_OFF_DAY_SETTING, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_CREATE_OFF_DAY_SETTING]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    OFF_DAY_SETTING.createOffDaySetting(data)
      .then((response) => {
        result = response;
        commit(M_CREATE_OFF_DAY_SETTING, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_CREATE_OFF_DAY_SETTING, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_UPDATE_OFF_DAY_SETTING]({ dispatch, commit }, { id, data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    OFF_DAY_SETTING.updateOffDaySetting(id, data)
      .then((response) => {
        result = response;
        commit(M_UPDATE_OFF_DAY_SETTING, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_UPDATE_OFF_DAY_SETTING, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_DELETE_OFF_DAY_SETTING]({ dispatch, commit }, { id }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    OFF_DAY_SETTING.deleteOffDaySetting(id)
      .then((response) => {
        result = response;
        commit(M_DELETE_OFF_DAY_SETTING, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_DELETE_OFF_DAY_SETTING, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_INITIAL_GET_OFF_DAY_SETTINGS_STATE]({ commit }) {
    commit(M_INITIAL_GET_OFF_DAY_SETTINGS_STATE);
  },
  [A_INITIAL_GET_OFF_DAY_SETTING_STATE]({ commit }) {
    commit(M_INITIAL_GET_OFF_DAY_SETTING_STATE);
  },
  [A_INITIAL_CREATE_OFF_DAY_SETTING_STATE]({ commit }) {
    commit(M_INITIAL_CREATE_OFF_DAY_SETTING_STATE);
  },
  [A_INITIAL_UPDATE_OFF_DAY_SETTING_STATE]({ commit }) {
    commit(M_INITIAL_UPDATE_OFF_DAY_SETTING_STATE);
  },
  [A_INITIAL_DELETE_OFF_DAY_SETTING_STATE]({ commit }) {
    commit(M_INITIAL_DELETE_OFF_DAY_SETTING_STATE);
  }
};

// Mutations
const mutations = {
  [M_GET_OFF_DAY_SETTINGS](state, result) {
    if (result.code === 0) {
      let d = result.data;
      let p = result.pagination;
      state.offDaySettings = {
        code: result.code,
        complete: true,
        data: d.map((x, index) => {
          return {
            no: index + 1,
            id: x.id,
            eventName: x.title,
            startDateTime: dateTimeFormat(x.start_date),
            endDateTime: dateTimeFormat(x.end_date),
            createdBy: x.created_by
          };
        }),
        message: null,
        pagination: {
          currentPage: p.current_page,
          total: p.total,
          perPage: p.per_page,
          lastPage: p.last_page
        }
      };
    } else {
      state.offDaySettings = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message),
        pagination: {
          currentPage: 0,
          total: 0,
          perPage: 0,
          lastPage: 0
        }
      };
    }
  },
  [M_GET_OFF_DAY_SETTING](state, result) {
    if (result.code === 0) {
      let d = result.data;

      state.offDaySetting = {
        code: result.code,
        complete: true,
        data: {
          id: d.id,
          eventName: d.title,
          startDateTime: dateTimeFormat(d.start_date),
          endDateTime: dateTimeFormat(d.end_date),
          createdBy: d.created_by
        },
        message: null
      };
    } else {
      state.offDaySetting = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_CREATE_OFF_DAY_SETTING](state, result) {
    state.createOffDaySetting = {
      code: result.code,
      complete: true,
      data: result.data,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_UPDATE_OFF_DAY_SETTING](state, result) {
    state.updateOffDaySetting = {
      code: result.code,
      complete: true,
      data: result,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_DELETE_OFF_DAY_SETTING](state, result) {
    state.deleteOffDaySetting = {
      code: result.code,
      complete: true,
      data: result,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_INITIAL_GET_OFF_DAY_SETTINGS_STATE](state) {
    state.offDaySettings = {
      code: 0,
      complete: false,
      data: null,
      message: null,
      pagination: {
        currentPage: 0,
        total: 0,
        perPage: 0,
        lastPage: 0
      }
    };
  },
  [M_INITIAL_GET_OFF_DAY_SETTING_STATE](state) {
    state.offDaySetting = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_CREATE_OFF_DAY_SETTING_STATE](state) {
    state.createOffDaySetting = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_UPDATE_OFF_DAY_SETTING_STATE](state) {
    state.updateOffDaySetting = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_DELETE_OFF_DAY_SETTING_STATE](state) {
    state.deleteOffDaySetting = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
