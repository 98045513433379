<template>
  <PointDetailsTemplate></PointDetailsTemplate>
</template>

<script>
  import PointDetailsTemplate from '@/views/components/point/PointDetailsTemplate.vue';

  export default {
    name: 'PointDetails',
    components: {
      PointDetailsTemplate
    }
  };
</script>

<style></style>
