var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{attrs:{"title":_vm.isNewRecord ? _vm.$t('label.newSchedule') : _vm.$t('label.scheduleDetails'),"centered":"","no-close-on-esc":"","no-close-on-backdrop":"","hide-header-close":"","scrollable":"","header-class":"text-capitalize","body-class":_vm.isNewRecord ? '' : 'max-h-325px'},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [(
        !_vm.isNewRecord &&
        !_vm.checkAccessRight(_vm.moduleName, [_vm.action.MAINTENANCE_SCHEDULE_UPDATE])
      )?_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.actionDiscard}},[_vm._v(_vm._s(_vm.$t('label.close')))]):_c('b-button',{staticClass:"mr-2",attrs:{"variant":"white"},on:{"click":_vm.actionDiscard}},[_vm._v(_vm._s(_vm.$t('label.discard')))]),(
        _vm.isNewRecord &&
        _vm.checkAccessRight(_vm.moduleName, [_vm.action.MAINTENANCE_SCHEDULE_CREATE])
      )?_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.actionSubmit}},[_vm._v(_vm._s(_vm.$t('label.submit')))]):_vm._e(),(
        !_vm.isNewRecord &&
        _vm.checkAccessRight(_vm.moduleName, [_vm.action.MAINTENANCE_SCHEDULE_UPDATE])
      )?_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.actionSaveChanges}},[_vm._v(_vm._s(_vm.$t('label.saveChanges')))]):_vm._e()]},proxy:true}]),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('b-form-group',{staticClass:"mb-0"},[_vm._l((_vm.formFields),function(i,index){return [_c('div',{key:index,staticClass:"px-2"},[(i.field == 'form')?_c('AppFormField',{attrs:{"state":_vm.validateObjState('maintenanceScheduleObj', i.model),"validationValue":_vm.$v.maintenanceScheduleObj[i.model],"title":_vm.$t(`label.${i.title}`),"solid":true,"validators":i.validators,"formType":i.formType,"disabled":_vm.isNewRecord
              ? !_vm.checkAccessRight(_vm.moduleName, [
                  _vm.action.MAINTENANCE_SCHEDULE_CREATE
                ])
              : !_vm.checkAccessRight(_vm.moduleName, [
                  _vm.action.MAINTENANCE_SCHEDULE_UPDATE
                ]),"config":i.config,"min":i.min,"max":i.max},model:{value:(_vm.$v.maintenanceScheduleObj[i.model].$model),callback:function ($$v) {_vm.$set(_vm.$v.maintenanceScheduleObj[i.model], "$model", $$v)},expression:"$v.maintenanceScheduleObj[i.model].$model"}}):(!_vm.isNewRecord)?_c('div',{staticClass:"mb-3"},[_c('div',{staticClass:"font-weight-bolder text-capitalize mb-2"},[_vm._v(" "+_vm._s(_vm.$t(`label.${i.title}`))+": ")]),_c('div',{staticClass:"text-gray-600"},[_vm._v(" "+_vm._s(_vm.maintenanceScheduleObj[i.model])+" ")])]):_vm._e(),_c('div',{staticStyle:{"display":"none"}},[_vm._v(" "+_vm._s(_vm.$v)+" ")])],1),(!_vm.isNewRecord && i.model == 'endDateTime')?_c('div',{key:index + 'separator',staticClass:"separator separator-solid my-6"}):_vm._e()]})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }