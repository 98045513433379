import dateTimeFormat from '@/core/filters/dateTimeFormat.filter';
import { RECIPE_REVIEW } from '@/core/api';
import { apiHelper } from '@/core/utils';
import {
  LOADING_OPEN_LOADING,
  LOADING_CLOSE_LOADING
} from '@/core/store/loading.module';

/* Define Module Name */
const MODULE_NAME = 'recipeReview/';

/* Define Action Name */
const A_GET_RECIPE_REVIEWS = 'getRecipeReviews';
const A_GET_RECIPE_REVIEW = 'getRecipeReview';
const A_SET_VISIBILITY = 'setVisibility';

/* Define Reset State Action Name */
const A_INITIAL_RECIPE_REVIEWS_STATE = 'initialRecipeReviewsState';
const A_INITIAL_RECIPE_REVIEW_STATE = 'initialRecipeReviewState';
const A_INITIAL_SET_VISIBILITY_STATE = 'initialSetVisibilityState';

/* Define Getter Name */

/* Define Mutation Name */
const M_RECIPE_REVIEWS = 'setRecipeReviews';
const M_RECIPE_REVIEW = 'setRecipeReview';
const M_SET_VISIBILITY = 'setSetVisibility';

/* Define Reset State Mutation Name */
const M_INITIAL_RECIPE_REVIEWS_STATE = 'setInitialRecipeReviewsState';
const M_INITIAL_RECIPE_REVIEW_STATE = 'setInitialRecipeReviewState';
const M_INITIAL_SET_VISIBILITY_STATE = 'setInitialSetVisibilityState';

/* Define Export Name */
export const RECIPE_REVIEW_GET_RECIPE_REVIEWS =
  MODULE_NAME + A_GET_RECIPE_REVIEWS;
export const RECIPE_REVIEW_GET_RECIPE_REVIEW =
  MODULE_NAME + A_GET_RECIPE_REVIEW;
export const RECIPE_REVIEW_SET_VISIBILITY = MODULE_NAME + A_SET_VISIBILITY;

/* Define Reset State Name */
export const RECIPE_REVIEW_INITIAL_RECIPE_REVIEWS_STATE =
  MODULE_NAME + A_INITIAL_RECIPE_REVIEWS_STATE;
export const RECIPE_REVIEW_INITIAL_RECIPE_REVIEW_STATE =
  MODULE_NAME + A_INITIAL_RECIPE_REVIEW_STATE;
export const RECIPE_REVIEW_INITIAL_SET_VISIBILITY_STATE =
  MODULE_NAME + A_INITIAL_SET_VISIBILITY_STATE;

const state = {
  recipeReviews: {
    code: 0,
    complete: false,
    data: null,
    message: null,
    pagination: {
      currentPage: 0,
      total: 0,
      perPage: 0,
      lastPage: 0
    }
  },
  recipeReview: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  setVisibility: {
    code: 0,
    complete: false,
    data: null,
    message: null
  }
};

//Getters
const getters = {};

// Actions
const actions = {
  [A_GET_RECIPE_REVIEWS]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    RECIPE_REVIEW.getRecipeReviews(data)
      .then((response) => {
        result = response;
        commit(M_RECIPE_REVIEWS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_RECIPE_REVIEWS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_RECIPE_REVIEW]({ dispatch, commit }, { id }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result;
    RECIPE_REVIEW.getRecipeReview(id)
      .then((response) => {
        result = response;
        commit(M_RECIPE_REVIEW, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_RECIPE_REVIEW, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_SET_VISIBILITY]({ dispatch, commit }, { id, data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    RECIPE_REVIEW.setVisibility(id, data)
      .then((response) => {
        result = response;
        commit(M_SET_VISIBILITY, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_SET_VISIBILITY, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_INITIAL_RECIPE_REVIEWS_STATE]({ commit }) {
    commit(M_INITIAL_RECIPE_REVIEWS_STATE);
  },
  [A_INITIAL_RECIPE_REVIEW_STATE]({ commit }) {
    commit(M_INITIAL_RECIPE_REVIEW_STATE);
  },
  [A_INITIAL_SET_VISIBILITY_STATE]({ commit }) {
    commit(M_INITIAL_SET_VISIBILITY_STATE);
  }
};

// Mutations
const mutations = {
  [M_RECIPE_REVIEWS](state, result) {
    if (result.code === 0) {
      let d = result.data;
      let p = result.pagination;

      state.recipeReviews = {
        code: result.code,
        complete: true,
        data: d.map((x, index) => {
          return {
            no: index + 1,
            id: x.id,
            userId: x.user_id,
            recipeId: x.recipe_id,
            itemName: x.item_name,
            username: x.username,
            rating: x.rating,
            description: x.description,
            isVisible: Boolean(x.is_visible),
            images: x.images,
            createdAt: dateTimeFormat(x.created_at)
          };
        }),
        message: null,
        pagination: {
          currentPage: p.current_page,
          total: p.total,
          perPage: p.per_page,
          lastPage: p.last_page
        }
      };
    } else {
      state.recipeReviews = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message),
        pagination: {
          currentPage: 0,
          total: 0,
          perPage: 0,
          lastPage: 0
        }
      };
    }
  },
  [M_RECIPE_REVIEW](state, result) {
    if (result.code === 0) {
      let d = result.data;

      state.recipeReview = {
        code: result.code,
        complete: true,
        data: {
          id: d.id,
          userId: d.user_id,
          recipeId: d.recipe_id,
          itemName: d.item_name,
          username: d.username,
          rating: d.rating,
          description: d.description,
          isVisible: Boolean(d.is_visible),
          images: d.images,
          createdAt: dateTimeFormat(d.created_at),
          itemObject: {
            id: d.recipe_id,
            name: d.item_name
          }
        },
        message: null
      };
    } else {
      state.recipeReview = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_SET_VISIBILITY](state, result) {
    state.setVisibility = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_INITIAL_RECIPE_REVIEWS_STATE](state) {
    state.recipeReviews = {
      code: 0,
      complete: false,
      data: null,
      message: null,
      pagination: {
        currentPage: 0,
        total: 0,
        perPage: 0,
        lastPage: 0
      }
    };
  },
  [M_INITIAL_RECIPE_REVIEW_STATE](state) {
    state.recipeReview = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_SET_VISIBILITY_STATE](state) {
    state.setVisibility = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
