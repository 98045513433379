<template>
  <div>
    <AppSmartList
      :breadcrumbData="breadcrumbData"
      :accessRights="accessRights"
      :filters="filters"
      :actions="actions"
      :tableHeaders="headers"
      :newRecord="newRecord"
    ></AppSmartList>
  </div>
</template>

<script>
  import AppSmartList from '@/views/components/bases/smart/AppSmartList.vue';
  import { ROUTE_NAME, MODULE, ACTION } from '@/core/constants';
  import { i18nHelper } from '@/core/utils';
  import {
    ADMIN_USER_GET_ADMIN_USERS,
    ADMIN_USER_INITIAL_GET_ADMIN_USERS_STATE
  } from '@/core/store/admin-user.module';

  export default {
    name: 'AccountManagementList',
    components: {
      AppSmartList
    },
    data: () => ({
      breadcrumbData: {
        breadcrumbs: [
          {
            title: i18nHelper.getMessage('label.accountManagement'),
            route: { name: ROUTE_NAME.ACCOUNT_MANAGEMENT }
          },
          { title: i18nHelper.getMessage('label.accountManagementList') }
        ]
      },
      accessRights: {
        moduleName: MODULE.ADMIN_USER,
        actions: {
          create: ACTION.ADMIN_USER_CREATE,
          read: ACTION.ADMIN_USER_READ,
          update: ACTION.ADMIN_USER_UPDATE,
          delete: ACTION.ADMIN_USER_DELETE
        }
      },
      filters: [
        {
          label: 'name',
          key: 'name',
          value: ''
        },
        {
          label: 'username',
          key: 'username',
          value: ''
        },
        {
          label: 'email',
          key: 'email',
          value: ''
        }
      ],
      actions: {
        viewList: {
          module: 'adminUser',
          state: 'adminUsers',
          action: ADMIN_USER_GET_ADMIN_USERS,
          initialStateAction: ADMIN_USER_INITIAL_GET_ADMIN_USERS_STATE,
          title: i18nHelper.getMessage('label.getAdminUsers')
        }
      },
      headers: [
        {
          key: 'no',
          name: 'no',
          active: true,
          sortable: true
        },
        {
          key: 'name',
          name: 'name',
          active: true,
          sortable: true
        },
        {
          key: 'username',
          name: 'username',
          active: true,
          sortable: true
        },
        {
          key: 'role',
          name: 'role',
          active: true,
          sortable: true
        },
        {
          key: 'email',
          name: 'email',
          active: true,
          sortable: true
        },
        {
          key: 'createdAt',
          name: 'createdAt',
          active: true,
          sortable: true
        },
        {
          key: 'updatedAt',
          name: 'updatedAt',
          active: true,
          sortable: true
        },
        {
          key: 'actions',
          name: 'actions',
          active: true,
          action: true,
          layout: 'AppTableActions',
          layoutActions: {
            viewDetailsRouteName: ROUTE_NAME.ACCOUNT_MANAGEMENT_DETAILS
          }
        }
      ],
      newRecord: {
        show: true,
        buttonTitle: i18nHelper.getMessage('label.newUser'),
        routeName: ROUTE_NAME.ACCOUNT_MANAGEMENT_NEW
      }
    })
  };
</script>

<style></style>
