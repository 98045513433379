<template>
  <b-card no-body data-style="custom">
    <!--begin: Wizard-->
    <div
      class="wizard wizard-2"
      id="OfflineMerchantNewWizard"
      data-wizard-state="step-first"
      data-wizard-clickable="true"
    >
      <!--begin: Wizard Nav -->
      <div class="wizard-nav border-right py-8 px-8 py-lg-20 px-lg-10">
        <div class="wizard-steps">
          <!--begin: Wizard Steps 1 -->
          <div
            class="wizard-step"
            data-wizard-type="step"
            data-wizard-state="current"
          >
            <div class="wizard-wrapper">
              <div class="wizard-icon">
                <span class="svg-icon svg-icon-2x">
                  <i class="fas fa-paste"></i>
                </span>
              </div>
              <div class="wizard-label text-capitalize">
                <h3 class="wizard-title">
                  {{ $t('label.offlineMerchantDetails') }}
                </h3>
                <div class="wizard-desc">
                  {{ $t('label.fillInInfoAndAction') }}
                </div>
              </div>
            </div>
          </div>
          <!--end: Wizard Steps 1 -->

          <!--begin: Wizard Steps 2 -->
          <template v-for="(x, index) in branch">
            <div :key="index" class="wizard-step" data-wizard-type="step">
              <div class="wizard-wrapper">
                <div class="wizard-icon">
                  <span class="svg-icon svg-icon-2x">
                    <i class="fas fa-file-image"></i>
                  </span>
                </div>
                <div class="wizard-label text-capitalize">
                  <h3 class="wizard-title">
                    {{ branchSettingTitle(x) }}
                  </h3>
                  <div class="wizard-desc">
                    {{ $t('label.fillInInfoAndAction') }}
                  </div>
                </div>
              </div>
            </div>
          </template>
          <!--end: Wizard Steps 3 -->
          <div
            class="wizard-step"
            v-if="btnDisableAddBranch && !isCreate"
            @click="addNewBranch"
          >
            <div class="wizard-wrapper">
              <div class="wizard-icon">
                <span class="svg-icon svg-icon-2x">
                  <i class="fas fa-plus"></i>
                </span>
              </div>
              <div class="wizard-label text-capitalize">
                <h3 class="wizard-title">
                  {{ $t('label.addBranch') }}
                </h3>
                <div class="wizard-desc">
                  {{ $t('label.addNewBranchSetting') }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--end: Wizard Nav -->

      <!--begin: Wizard Body -->
      <div class="wizard-body py-8 px-8 py-lg-20 px-lg-10">
        <!--begin: Wizard Form-->
        <b-row>
          <b-col offset-xl="2" xl="8">
            <b-form class="form" id="kt_form">
              <!--begin: Wizard Step 1-->
              <div
                class="pb-5"
                data-wizard-type="step-content"
                data-wizard-state="current"
              >
                <div class="d-flex justify-content-between align-items-conter">
                  <h6 class="mb-10 font-weight-bold text-dark">
                    {{ $t('label.offlineMerchantSetting') }}
                  </h6>
                  <div
                    v-if="offlineMerchant.totalVoucher"
                    :class="{
                      'text-primary cursor-pointer font-weight-bolder':
                        offlineMerchant.totalVoucher
                    }"
                    @click="
                      openVouchersTableModal(offlineMerchant.totalVoucher)
                    "
                    class="text-capitalize text-underline"
                  >
                    <u>
                      {{
                        offlineMerchant.totalVoucher +
                        ' ' +
                        $t(`label.voucher`) +
                        ' >'
                      }}
                    </u>
                  </div>
                </div>
                <b-row
                  v-for="(i, index) in formFields.filter((x) => x.field == 1)"
                  :key="index"
                  class="align-items-center"
                  no-gutters
                >
                  <b-col cols="4">
                    <label class="font-size-m">
                      {{ $t(`label.${i.title}`) }}
                      <span v-if="i.isRequires" class="text-danger">*</span>
                    </label>
                  </b-col>
                  <b-col cols="8">
                    <component
                      v-if="i.layout"
                      v-model="$v.offlineMerchant[i.model].$model"
                      :is="i.layout"
                      :state="validateObjState('offlineMerchant', i.model)"
                      :validationValue="$v.offlineMerchant[i.model]"
                      :attachmentUrl="$v.offlineMerchant[i.model].$model"
                      :modelName="i.model"
                      :validators="i.validators"
                      :validationParams="i.validationParams"
                      :accept="i.accept"
                      :moreRemark="i.remark"
                      @replace-action="replaceAction"
                    >
                    </component>
                    <AppFormField
                      v-else
                      v-model="$v.offlineMerchant[i.model].$model"
                      :state="validateObjState('offlineMerchant', i.model)"
                      :validationValue="$v.offlineMerchant[i.model]"
                      :title="$t(`label.${i.title}`)"
                      :validators="i.validators"
                      :validationParams="i.validationParams"
                      :formType="i.formType"
                      :type="i.type"
                      :options="i.options"
                      :value="i.value"
                      :uncheckedValue="i.uncheckedValue"
                      :textTransform="i.textTransform"
                      :size="i.size"
                      :noTitle="true"
                      :horizontal="true"
                      :prepend="i.prepend"
                      solid
                    >
                      <template v-slot:prepend>
                        <span class="input-group-text text-uppercase">+60</span>
                      </template>
                    </AppFormField>
                  </b-col>
                  <div style="display: none">
                    <!-- WARNING: I have no idea why this line is here, but removing it breaks everything -->

                    <!-- WARNING: I have no idea why this line is here, but removing it breaks everything -->
                    {{ $v }}
                  </div>
                </b-row>
                <div
                  v-if="!isCreate"
                  class="d-flex justify-content-end border-top mt-10 pt-10"
                >
                  <div
                    class="btn btn-warning font-weight-bold text-uppercase mr-3"
                    @click="updateOfflineMerchantActivation"
                  >
                    {{
                      offlineMerchant.status == 1
                        ? $t('label.deactivate')
                        : $t('label.activate')
                    }}
                  </div>
                  <div
                    class="btn btn-primary font-weight-bold text-uppercase"
                    @click="
                      validationCheckBeforeSubmit('offlineMerchant', 'save')
                    "
                  >
                    {{ $t('label.save') }}
                  </div>
                </div>
              </div>
            </b-form>
            <!--end: Wizard Step 1-->

            <!--begin: Wizard Step 2-->
            <template v-for="(x, branchindex) in branch">
              <b-form :key="branchindex">
                <div class="pb-5" data-wizard-type="step-content">
                  <h6 class="mb-10 font-weight-bold text-dark text-capitalize">
                    {{ branchSettingTitle(x) }}
                  </h6>
                  <b-row
                    v-for="(i, index) in formFields.filter((x) => x.field == 2)"
                    :key="index"
                    class="align-items-center"
                    no-gutters
                  >
                    <b-col cols="4">
                      <label class="font-size-m">
                        {{ $t(`label.${i.title}`) }}
                        <span v-if="i.isRequires" class="text-danger">*</span>
                      </label>
                    </b-col>
                    <b-col cols="8">
                      <component
                        v-if="i.layout"
                        v-model="merchantBranch[branchindex][i.model]"
                        :is="i.layout"
                        :horizontal="true"
                      >
                      </component>
                      <AppFormField
                        v-else
                        v-model="
                          $v.merchantBranch.$each[branchindex][i.model].$model
                        "
                        :validationValue="
                          $v.merchantBranch.$each[branchindex][i.model]
                        "
                        :state="
                          validateObjState(
                            'merchantBranch',
                            i.model,
                            true,
                            branchindex
                          )
                        "
                        :validators="i.validators"
                        :validationParams="i.validationParams"
                        :title="$t(`label.${i.title}`)"
                        :formType="i.formType"
                        :type="i.type"
                        :options="i.options"
                        :noTitle="true"
                        :horizontal="true"
                        :value="i.value"
                        :uncheckedValue="i.uncheckedValue"
                        :size="i.size"
                        :prepend="i.prepend"
                        :textTransform="i.textTransform"
                        solid
                      >
                        <template v-slot:prepend>
                          <span class="input-group-text text-uppercase"
                            >+60</span
                          >
                        </template>
                      </AppFormField>
                    </b-col>
                    <div style="display: none">
                      <!-- WARNING: I have no idea why this line is here, but removing it breaks everything -->
                      {{ $v }}
                    </div>
                  </b-row>
                  <div
                    v-if="!isCreate"
                    class="d-flex justify-content-end border-top mt-10 pt-10"
                  >
                    <div
                      v-if="merchantBranch[branchindex].id == null"
                      class="btn btn-primary font-weight-bold text-uppercase"
                      @click="
                        validationCheckBeforeSubmit(
                          'merchantBranch',
                          '',
                          branchindex
                        )
                      "
                    >
                      {{ $t('label.create') }}
                    </div>
                    <div v-else>
                      <div
                        v-if="merchantBranch.length != 1"
                        class="btn btn-danger font-weight-bold text-uppercase mr-3"
                        @click="deleteOfflineMerchantBranch(branchindex)"
                      >
                        {{ $t('label.delete') }}
                      </div>
                      <div
                        class="btn btn-warning font-weight-bold text-uppercase mr-3"
                        @click="
                          updateOfflineMerchantBranchActivation(branchindex)
                        "
                      >
                        {{
                          merchantBranch[branchindex].status == 1
                            ? $t('label.deactivate')
                            : $t('label.activate')
                        }}
                      </div>
                      <div
                        class="btn btn-primary font-weight-bold text-uppercase"
                        @click="
                          validationCheckBeforeSubmit(
                            'merchantBranch',
                            'save',
                            branchindex
                          )
                        "
                      >
                        {{ $t('label.save') }}
                      </div>
                    </div>
                  </div>
                </div>
              </b-form>
            </template>
            <!--end: Wizard Step 2-->

            <!--begin: Wizard Actions -->
            <div
              v-if="isCreate"
              class="d-flex justify-content-end border-top pt-10"
            >
              <div>
                <button
                  :disabled="
                    !checkAccessRight(moduleName, [
                      permissionAction.BANNER_CREATE
                    ])
                  "
                  class="btn btn-success font-weight-bold text-uppercase"
                  data-wizard-type="action-submit"
                  type="submit"
                >
                  {{ $t('label.submit') }}
                </button>
                <button
                  class="btn btn-primary font-weight-bold text-uppercase"
                  data-wizard-type="action-next"
                >
                  {{ $t('label.nextStep') }}
                </button>
              </div>
            </div>
            <!--end: Wizard Actions -->
          </b-col>
          <!--end: Wizard-->
        </b-row>
      </div>
      <!--end: Wizard Body -->
    </div>
    <!--end: Wizard-->
    <!-- begin: User Referees Table Modal -->
    <MerchantVoucherClaimedUsers
      v-if="ClaimedTableModal"
      v-model="ClaimedTableModal"
      :ClaimedTableModal="ClaimedTableModal"
      :id="id"
    ></MerchantVoucherClaimedUsers>
  </b-card>
  <!-- end: User Referees Table Modal -->
</template>

<script>
  import BannerMediaFileStandardMessage from '@/views/components/banner/BannerMediaFileStandardMessage';
  import MerchantVoucherClaimedUsers from '@/views/components/offline-merchant/OfflineMerchantVoucherList';
  import AppFormField from '@/views/components/bases/form-field/AppFormField';
  import AppViewAndUpload from '@/views/components/bases/AppViewAndUpload';
  import KTUtil from '@/assets/js/components/util';
  import KTWizard from '@/assets/js/components/wizard';
  import constantsMixin from '@/core/mixins/constants.mixin';
  import commonMixin from '@/core/mixins/common.mixin';
  import bannerMixin from '@/core/mixins/banner.mixin';
  import { validationMixin } from 'vuelidate';
  import {
    required,
    minLength,
    maxLength,
    url,
    email,
    numeric
  } from 'vuelidate/lib/validators';
  import {
    GLOBAL,
    MODULE,
    ACTION,
    ROUTE_NAME,
    mobileNumber,
    bannerImageType,
    fileSize
  } from '@/core/constants';
  import { i18nHelper, uiHelper } from '@/core/utils';
  import { listService } from '@/core/services';
  import {
    OFFLINE_MERCHANT_GET_OFFLINE_MERCHANT,
    OFFLINE_MERCHANT_GET_OFFLINE_MERCHANT_BRANCH,
    OFFLINE_MERCHANT_CREATE_OFFLINE_MERCHANT,
    OFFLINE_MERCHANT_CREATE_OFFLINE_MERCHANT_BRANCH,
    OFFLINE_MERCHANT_UPDATE_OFFLINE_MERCHANT,
    OFFLINE_MERCHANT_UPDATE_OFFLINE_MERCHANT_BRANCH,
    OFFLINE_MERCHANT_UPDATE_OFFLINE_MERCHANT_ACTIVATION,
    OFFLINE_MERCHANT_UPDATE_OFFLINE_MERCHANT_BRANCH_ACTIVATION,
    OFFLINE_MERCHANT_DELETE_OFFLINE_MERCHANT_BRANCH,
    OFFLINE_MERCHANT_INITIAL_GET_OFFLINE_MERCHANT_STATE,
    OFFLINE_MERCHANT_INITIAL_GET_OFFLINE_MERCHANT_BRANCH_STATE,
    OFFLINE_MERCHANT_INITIAL_CREATE_OFFLINE_MERCHANT_STATE,
    OFFLINE_MERCHANT_INITIAL_CREATE_OFFLINE_MERCHANT_BRANCH_STATE,
    OFFLINE_MERCHANT_INITIAL_UPDATE_OFFLINE_MERCHANT_STATE,
    OFFLINE_MERCHANT_INITIAL_UPDATE_OFFLINE_MERCHANT_BRANCH_STATE,
    OFFLINE_MERCHANT_INITIAL_UPDATE_OFFLINE_MERCHANT_ACTIVATION_STATE,
    OFFLINE_MERCHANT_INITIAL_UPDATE_OFFLINE_MERCHANT_BRANCH_ACTIVATION_STATE,
    OFFLINE_MERCHANT_INITIAL_DELETE_OFFLINE_MERCHANT_BRANCH_STATE
  } from '@/core/store/offline-merchant.module';

  export default {
    name: 'OfflineMerchantForm',
    mixins: [commonMixin, constantsMixin, validationMixin, bannerMixin],
    components: {
      AppFormField,
      AppViewAndUpload,
      BannerMediaFileStandardMessage,
      MerchantVoucherClaimedUsers
    },
    props: {
      isCreate: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      id: null,
      moduleName: MODULE.BANNER,
      permissionAction: ACTION,
      global: GLOBAL,
      routeName: ROUTE_NAME,
      ClaimedTableModal: false,
      constWizard: null,
      offlineMerchant: {
        businessName: null,
        businessLogo: null,
        businessBanner: null,
        contactPerson1: '',
        contactNumber1: '',
        contactPerson2: '',
        contactNumber2: '',
        description: '',
        serviceType: 1,
        pinVerification: null,
        cuisineType: [],
        websiteLink: null
      },
      merchantBranch: [
        {
          registerCompanyName: null,
          businessNumber: null,
          addressName: null,
          streetAddress: null,
          addressDetails: null,
          hideBusinessAddressInProfilePage: false,
          contactPerson1: '',
          contactNumber1: '',
          contactPerson2: '',
          contactNumber2: '',
          contactPerson3: '',
          contactNumber3: '',
          email: null,
          pinVerification: null
        }
      ],
      isFilePlace: { businessLogo: false, businessBanner: false },
      btnDisableAddBranch: true,
      branch: 1,
      formFields: [],
      bannerDimension: {
        height: 1500,
        width: 500
      }
    }),
    computed: {
      OfflineMerchantComplete() {
        return this.$store.state.offlineMerchant.offlineMerchant.complete;
      },
      OfflineMerchantBranchComplete() {
        return this.$store.state.offlineMerchant.offlineMerchantBranch.complete;
      },
      createOfflineMerchantComplete() {
        return this.$store.state.offlineMerchant.createOfflineMerchant.complete;
      },
      createOfflineMerchantBranchComplete() {
        return this.$store.state.offlineMerchant.createOfflineMerchantBranch
          .complete;
      },
      updateOfflineMerchantComplete() {
        return this.$store.state.offlineMerchant.updateOfflineMerchant.complete;
      },
      updateOfflineMerchantBranchComplete() {
        return this.$store.state.offlineMerchant.updateOfflineMerchantBranch
          .complete;
      },
      updateOfflineMerchantActivationComplete() {
        return this.$store.state.offlineMerchant.updateOfflineMerchantActivation
          .complete;
      },
      updateOfflineMerchantBranchActivationComplete() {
        return this.$store.state.offlineMerchant
          .updateOfflineMerchantBranchActivation.complete;
      },
      deleteOfflineMerchantBranchComplete() {
        return this.$store.state.offlineMerchant.deleteOfflineMerchantBranch
          .complete;
      }
    },
    watch: {
      OfflineMerchantComplete() {
        let response = this.$store.state.offlineMerchant.offlineMerchant;
        if (response.complete) {
          this.getOfflineMerchantCompleteAction(response);
        }
      },
      OfflineMerchantBranchComplete() {
        let response = this.$store.state.offlineMerchant.offlineMerchantBranch;
        if (response.complete) {
          this.getOfflineMerchantBranchCompleteAction(response);
        }
      },
      createOfflineMerchantComplete() {
        let response = this.$store.state.offlineMerchant.createOfflineMerchant;
        if (response.complete) {
          this.createOfflineMerchantCompleteAction(response);
        }
      },
      createOfflineMerchantBranchComplete() {
        let response =
          this.$store.state.offlineMerchant.createOfflineMerchantBranch;
        if (response.complete) {
          this.createOfflineMerchantBranchCompleteAction(response);
        }
      },
      updateOfflineMerchantComplete() {
        let response = this.$store.state.offlineMerchant.updateOfflineMerchant;
        if (response.complete) {
          this.updateOfflineMerchantCompleteAction(response);
        }
      },
      updateOfflineMerchantBranchComplete() {
        let response =
          this.$store.state.offlineMerchant.updateOfflineMerchantBranch;
        if (response.complete) {
          this.updateOfflineMerchantBranchCompleteAction(response);
        }
      },
      updateOfflineMerchantActivationComplete() {
        let response =
          this.$store.state.offlineMerchant.updateOfflineMerchantActivation;
        if (response.complete) {
          this.updateOfflineMerchantActivationCompleteAction(response);
        }
      },
      updateOfflineMerchantBranchActivationComplete() {
        let response =
          this.$store.state.offlineMerchant
            .updateOfflineMerchantBranchActivation;
        if (response.complete) {
          this.updateOfflineMerchantBranchActivationCompleteAction(response);
        }
      },
      deleteOfflineMerchantBranchComplete() {
        let response =
          this.$store.state.offlineMerchant.deleteOfflineMerchantBranch;
        if (response.complete) {
          this.deleteOfflineMerchantBranchCompleteAction(response);
        }
      }
    },
    mounted() {
      this.initialOffileMerchantNew();
      this.initWizard();
      this.initFormFields();
    },
    methods: {
      branchSettingTitle(data) {
        if (data.addressName) {
          return data.addressName;
        } else {
          return i18nHelper.getMessage('label.newBranchSetting');
        }
      },
      openVouchersTableModal(total) {
        if (total) this.ClaimedTableModal = true;
      },
      getOfflineMerchantCompleteAction(response) {
        let data = response.data;
        let title = i18nHelper.getMessage('label.offlineMerchantSetting');
        let messages = response.message;
        let buttons = [];
        if (response.code == 0) {
          this.offlineMerchant = data;
        } else {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();
            }
          });
          this.openLayoutAppModalInfo(title, messages, buttons);
        }
        this.initialGetOfflineMerchantState();
      },
      async getOfflineMerchantBranchCompleteAction(response) {
        let data = response.data;
        let title = i18nHelper.getMessage('label.offlineMerchantSetting');
        let messages = response.message;
        let buttons = [];
        if (response.code == 0) {
          this.merchantBranch = data;
          this.branch = this.merchantBranch;
          await this.branch;
          this.updateWizard();
        } else {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();
            }
          });
          this.openLayoutAppModalInfo(title, messages, buttons);
        }
        this.initialGetOfflineMerchantBranchState();
      },
      createOfflineMerchantCompleteAction(response) {
        let title = i18nHelper.getMessage('label.offlineMerchantSetting');
        let messages = response.message;
        let buttons = [];

        if (response.code == 0) {
          let data = response.data;
          this.id = data.id;
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.initialCreateOfflineMerchantState();
              this.closeLayoutAppModalInfo();
              this.initialOffileMerchantNew();
              this.submitMerchantBranchForm(false, 0);
            }
          });
        } else {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();
              this.initialCreateOfflineMerchantState();
            }
          });
        }
        this.openLayoutAppModalInfo(title, messages, buttons);
      },
      createOfflineMerchantBranchCompleteAction(response) {
        let title = i18nHelper.getMessage('label.offlineMerchantSetting');
        let messages = response.message;
        let buttons = [];
        if (response.code == 0) {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.initialCreateOfflineMerchantBranchState();
              this.closeLayoutAppModalInfo();
              this.initialOffileMerchantNew();
              this.btnDisableAddBranch = true;
              this.$router.push({
                name: ROUTE_NAME.OFFLINE_MERCHANT_DETAILS,
                params: { id: this.id }
              });
            }
          });
        } else {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();
              this.initialCreateOfflineMerchantBranchState();
            }
          });
        }
        this.openLayoutAppModalInfo(title, messages, buttons);
      },
      updateOfflineMerchantCompleteAction(response) {
        let title = i18nHelper.getMessage('label.offlineMerchantSetting');
        let messages = response.message;
        let buttons = [];

        if (response.code == 0) {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.initialUpdateOfflineMerchantState();
              this.closeLayoutAppModalInfo();
              this.initialOffileMerchantNew();
            }
          });
        } else {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();
              this.initialUpdateOfflineMerchantState();
            }
          });
        }
        this.openLayoutAppModalInfo(title, messages, buttons);
      },
      updateOfflineMerchantBranchCompleteAction(response) {
        let title = i18nHelper.getMessage('label.offlineMerchantSetting');
        let messages = response.message;
        let buttons = [];

        if (response.code == 0) {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.initialUpdateOfflineMerchantBranchState();
              this.closeLayoutAppModalInfo();
              this.initialOffileMerchantNew();
            }
          });
        } else {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();
              this.initialUpdateOfflineMerchantBranchState();
            }
          });
        }
        this.openLayoutAppModalInfo(title, messages, buttons);
      },
      updateOfflineMerchantActivationCompleteAction(response) {
        let title = i18nHelper.getMessage('label.offlineMerchantSetting');
        let messages = response.message;
        let buttons = [];

        if (response.code == 0) {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.initialUpdateOfflineMerchantActivationState();
              this.closeLayoutAppModalInfo();
              this.initialOffileMerchantNew();
            }
          });
        } else {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();
              this.initialUpdateOfflineMerchantActivationState();
            }
          });
        }
        this.openLayoutAppModalInfo(title, messages, buttons);
      },
      updateOfflineMerchantBranchActivationCompleteAction(response) {
        let title = i18nHelper.getMessage('label.offlineMerchantSetting');
        let messages = response.message;
        let buttons = [];

        if (response.code == 0) {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.initialUpdateOfflineMerchantBranchActivationState();
              this.closeLayoutAppModalInfo();
              this.initialOffileMerchantNew();
            }
          });
        } else {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();
              this.initialUpdateOfflineMerchantBranchActivationState();
            }
          });
        }
        this.openLayoutAppModalInfo(title, messages, buttons);
      },
      deleteOfflineMerchantBranchCompleteAction(response) {
        let title = i18nHelper.getMessage('label.offlineMerchantSetting');
        let messages = response.message;
        let buttons = [];

        if (response.code == 0) {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.initialDeleteOfflineMerchantBranchState();
              this.closeLayoutAppModalInfo();
              this.initialOffileMerchantNew();
            }
          });
        } else {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.initialDeleteOfflineMerchantBranchState();
              this.closeLayoutAppModalInfo();
            }
          });
        }
        this.openLayoutAppModalInfo(title, messages, buttons);
      },
      initWizard() {
        // Initialize form wizard
        let vm = this;

        const wizard = new KTWizard('OfflineMerchantNewWizard', {
          startStep: 1, // initial active step number
          clickableSteps: true // allow step clicking
        });

        vm.constWizard = wizard;
        wizard.on('submit', () => {
          if (vm.validationCheck('merchantBranch')) {
            vm.validationCheckBeforeSubmit('offlineMerchant');
          }
        });

        //Change event
        wizard.on('change', function (/*wizardObj*/) {
          if (wizard.currentStep == 1) {
            let v1 = vm.validationCheck('offlineMerchant');
            if (!v1) {
              wizard.stop();
            }
          }

          KTUtil.scrollTop();
        });
      },
      updateWizard() {
        let vm = this;
        const wizard = new KTWizard('OfflineMerchantNewWizard', {
          startStep: 1, // initial active step number
          clickableSteps: true // allow step clicking
        });
        vm.constWizard = wizard;
        vm.constWizard.updateConstruct();

        this.initWizard();
      },
      initFormFields() {
        this.formFields.push(
          {
            field: 1,
            model: 'businessName',
            title: 'businessName',
            validators: ['required'],
            isRequires: true
          },
          {
            field: 1,
            model: 'businessLogo',
            title: 'businessLogo',
            layout: 'AppViewAndUpload',
            validators: [
              'required',
              'bannerImageType',
              'fileSize'
              // 'bannerImageDimension'
            ],
            validationParams: [
              {
                key: 'bannerImageType',
                params: [
                  uiHelper.arrayToTrimmedStringWithSpace(
                    GLOBAL.AVAILABLE_BANNER_IMAGE_TYPE,
                    6
                  )
                ]
              },
              {
                key: 'fileSize',
                params: [GLOBAL.BANNER_IMAGE_UPLOAD_SIZE]
              }
              // {
              //   key: 'bannerImageDimension',
              //   params: [dimension.height, dimension.width]
              // }
            ],
            accept: GLOBAL.AVAILABLE_BANNER_IMAGE_TYPE.toString(),
            isRequires: true,
            remark:
              'Recommended 400 x 400 pixels for 1:1 aspect ratio. Max file size of 5MB'
          },
          {
            field: 1,
            model: 'businessBanner',
            title: 'businessBanner',
            layout: 'AppViewAndUpload',
            validators: [
              'required',
              'bannerImageType',
              'fileSize'
              // 'bannerImageDimension'
            ],
            validationParams: [
              {
                key: 'bannerImageType',
                params: [
                  uiHelper.arrayToTrimmedStringWithSpace(
                    GLOBAL.AVAILABLE_BANNER_IMAGE_TYPE,
                    6
                  )
                ]
              },
              {
                key: 'fileSize',
                params: [GLOBAL.BANNER_IMAGE_UPLOAD_SIZE]
              }
              // {
              //   key: 'bannerImageDimension',
              //   params: [dimension.height, dimension.width]
              // }
            ],
            accept: GLOBAL.AVAILABLE_BANNER_IMAGE_TYPE.toString(),
            isRequires: true,
            remark:
              'Recommended 400 x 400 pixels for 1:1 aspect ratio. Max file size of 5MB'
          },
          {
            field: 1,
            model: 'contactPerson1',
            title: 'contactPerson1'
          },
          {
            field: 1,
            model: 'contactNumber1',
            title: 'contactNumber1',
            type: 'number',
            validators: ['mobileNumber', 'numeric'],
            prepend: true
          },
          {
            field: 1,
            model: 'contactPerson2',
            title: 'contactPerson2'
          },
          {
            field: 1,
            model: 'contactNumber2',
            title: 'contactNumber2',
            type: 'number',
            validators: ['mobileNumber', 'numeric'],
            prepend: true
          },
          {
            field: 1,
            model: 'description',
            title: 'description',
            formType: 'textarea',
            validators: ['maxLength'],
            validationParams: [
              {
                key: 'maxLength',
                params: [500]
              }
            ],
            remark: 'Maximum limit 500 characters'
          },
          {
            field: 1,
            model: 'serviceType',
            title: 'serviceType',
            formType: 'radios',
            value: true,
            validators: ['required'],
            options: [{ text: 'Dine-in', value: 1 }],
            isRequires: true
          },
          {
            field: 1,
            model: 'pinVerification',
            title: 'pinVerification',
            validators: ['required', 'minLength', 'maxLength'],
            validationParams: [
              {
                key: 'minLength',
                params: [4]
              },
              {
                key: 'maxLength',
                params: [4]
              }
            ],
            isRequires: true,
            textTransform: true
          },
          {
            field: 1,
            model: 'cuisineType',
            title: 'cuisineType',
            formType: 'checkbox',
            options: listService.getOffileMerchantCuisineTypeList(),
            validators: ['required'],
            isRequires: true
          },
          {
            field: 1,
            model: 'websiteLink',
            title: 'websiteLink',
            validators: ['url']
          },
          {
            field: 2,
            model: 'registerCompanyName',
            title: 'registerCompanyName',
            validators: ['required'],
            isRequires: true
          },
          {
            field: 2,
            model: 'businessNumber',
            title: 'businessNumber',
            validators: ['required'],
            isRequires: true
          },
          {
            field: 2,
            model: 'addressName',
            title: 'addressName',
            validators: ['required'],
            isRequires: true
          },
          {
            field: 2,
            model: 'streetAddress',
            title: 'streetAddress',
            validators: ['required'],
            isRequires: true
          },
          {
            field: 2,
            model: 'addressDetails',
            title: 'addressDetails'
          },
          {
            field: 2,
            model: 'hideBusinessAddressInProfilePage',
            title: 'hideBusinessAddressInProfilePage',
            formType: 'singleCheckbox',
            size: 'lg'
          },
          {
            field: 2,
            model: 'contactPerson1',
            title: 'contactPerson1'
          },
          {
            field: 2,
            model: 'contactNumber1',
            title: 'contactNumber1',
            type: 'number',
            validators: ['required', 'mobileNumber', 'numeric'],
            isRequires: true,
            prepend: true
          },
          {
            field: 2,
            model: 'contactPerson2',
            title: 'contactPerson2'
          },
          {
            field: 2,
            model: 'contactNumber2',
            title: 'contactNumber2',
            type: 'number',
            validators: ['mobileNumber', 'numeric'],
            prepend: true
          },
          {
            field: 2,
            model: 'contactPerson3',
            title: 'contactPerson3'
          },
          {
            field: 2,
            model: 'contactNumber3',
            title: 'contactNumber3',
            type: 'number',
            validators: ['mobileNumber', 'numeric'],
            prepend: true
          },
          {
            field: 2,
            model: 'email',
            title: 'email',
            validators: ['email']
          },
          {
            field: 2,
            model: 'pinVerification',
            title: 'pinVerification',
            validators: ['minLength', 'maxLength'],
            validationParams: [
              {
                key: 'minLength',
                params: [4]
              },
              {
                key: 'maxLength',
                params: [4]
              }
            ],
            textTransform: true
          }
        );
      },
      // Replace Document Action : Start
      replaceAction(value) {
        this.offlineMerchant[value] = null;
        this.isFilePlace[value] = true;
      },
      // Replace Document Action : End
      validationCheckBeforeSubmit(type, isSave, branchNo) {
        //Submit new banner form if all the validation is no error
        if (
          type == 'offlineMerchant' &&
          this.validationCheck('offlineMerchant')
        ) {
          this.submitOfflineMerchantForm(isSave);
        } else if (
          type == 'merchantBranch' &&
          this.validationCheckEach(this.$v.merchantBranch.$each[branchNo])
          // this.validationCheckEach('merchantBranch', branchNo)
        ) {
          this.submitMerchantBranchForm(isSave, branchNo);
        }
      },
      submitOfflineMerchantForm(isSave) {
        var offlineMerchant = this.offlineMerchant;
        var formData = new FormData();

        let businessLogo = offlineMerchant.businessLogo;
        let businessBanner = offlineMerchant.businessBanner;

        let contactPerson = [];
        let contactNumber = [];

        if (offlineMerchant.contactNumber1) {
          contactPerson.push(offlineMerchant.contactPerson1);
          contactNumber.push(offlineMerchant.contactNumber1);
        }
        if (offlineMerchant.contactNumber2) {
          contactPerson.push(offlineMerchant.contactPerson2);
          contactNumber.push(offlineMerchant.contactNumber2);
        }

        formData.append('name', offlineMerchant.businessName);
        formData.append(
          'logo',
          typeof businessLogo != 'string' ? businessLogo : ''
        );
        formData.append(
          'banner',
          typeof businessBanner != 'string' ? businessBanner : ''
        );
        formData.append('description', offlineMerchant.description ?? '');
        contactPerson.forEach((item, index) =>
          formData.append(
            `additional_contact[${index}][contact_name]`,
            item ?? ''
          )
        );
        contactNumber.forEach((item, index) =>
          formData.append(
            `additional_contact[${index}][contact_number]`,
            item ?? ''
          )
        );
        formData.append(
          'pin_verification',
          offlineMerchant.pinVerification.toUpperCase()
        );
        offlineMerchant.cuisineType.forEach((item) =>
          formData.append('cuisine_type[]', item)
        );
        formData.append(
          'website_link',
          offlineMerchant.websiteLink ? offlineMerchant.websiteLink : ''
        );
        if (isSave) {
          this.updateOfflineMerchant(formData);
        } else {
          this.createOfflineMerchant(formData);
        }
      },
      submitMerchantBranchForm(isSave, branchNo) {
        var merchantBranch = this.merchantBranch[branchNo];
        let data = merchantBranch;
        if (isSave) {
          let branchId = this.merchantBranch[branchNo].id;
          this.updateOfflineMerchantBranch(data, branchId);
        } else {
          this.createOfflineMerchantBranch(data);
        }
      },
      resetForm() {
        this.btnDisableAddBranch = true;
        this.isFilePlace = { businessLogo: false, businessBanner: false };
      },
      getOfflineMerchant(id) {
        this.resetForm();
        this.$store.dispatch(OFFLINE_MERCHANT_GET_OFFLINE_MERCHANT, {
          id
        });
      },
      getOfflineMerchantBranch(id) {
        this.$store.dispatch(OFFLINE_MERCHANT_GET_OFFLINE_MERCHANT_BRANCH, {
          id
        });
      },
      createOfflineMerchant(formData) {
        this.$store.dispatch(OFFLINE_MERCHANT_CREATE_OFFLINE_MERCHANT, {
          formData
        });
      },
      createOfflineMerchantBranch(data) {
        let id = this.id;
        this.$store.dispatch(OFFLINE_MERCHANT_CREATE_OFFLINE_MERCHANT_BRANCH, {
          id,
          data
        });
      },
      updateOfflineMerchant(formData) {
        let id = this.id;
        this.$store.dispatch(OFFLINE_MERCHANT_UPDATE_OFFLINE_MERCHANT, {
          id,
          formData
        });
      },
      updateOfflineMerchantBranch(data, branchId) {
        let id = this.id;
        this.$store.dispatch(OFFLINE_MERCHANT_UPDATE_OFFLINE_MERCHANT_BRANCH, {
          id,
          data,
          branchId
        });
      },
      updateOfflineMerchantActivation() {
        let id = this.id;
        let data;
        switch (this.offlineMerchant.status) {
          case 0:
            data = 1;
            break;
          case 1:
            data = 0;
            break;
        }
        this.$store.dispatch(
          OFFLINE_MERCHANT_UPDATE_OFFLINE_MERCHANT_ACTIVATION,
          {
            id,
            data
          }
        );
      },
      updateOfflineMerchantBranchActivation(branchindex) {
        let id = this.id;
        let data;
        let branchId = this.merchantBranch[branchindex].id;

        switch (this.merchantBranch[branchindex].status) {
          case 0:
            data = 1;
            break;
          case 1:
            data = 0;
            break;
          case 2:
            data = 1;
            break;
        }
        this.$store.dispatch(
          OFFLINE_MERCHANT_UPDATE_OFFLINE_MERCHANT_BRANCH_ACTIVATION,
          {
            id,
            data,
            branchId
          }
        );
      },
      deleteOfflineMerchantBranch(branchindex) {
        let id = this.id;
        let branchId = this.merchantBranch[branchindex].id;

        this.$store.dispatch(OFFLINE_MERCHANT_DELETE_OFFLINE_MERCHANT_BRANCH, {
          id,
          branchId
        });
      },
      initialGetOfflineMerchantState() {
        this.$store.dispatch(
          OFFLINE_MERCHANT_INITIAL_GET_OFFLINE_MERCHANT_STATE
        );
      },
      initialGetOfflineMerchantBranchState() {
        this.$store.dispatch(
          OFFLINE_MERCHANT_INITIAL_GET_OFFLINE_MERCHANT_BRANCH_STATE
        );
      },
      initialCreateOfflineMerchantState() {
        this.$store.dispatch(
          OFFLINE_MERCHANT_INITIAL_CREATE_OFFLINE_MERCHANT_STATE
        );
      },
      initialCreateOfflineMerchantBranchState() {
        this.$store.dispatch(
          OFFLINE_MERCHANT_INITIAL_CREATE_OFFLINE_MERCHANT_BRANCH_STATE
        );
      },
      initialUpdateOfflineMerchantState() {
        this.$store.dispatch(
          OFFLINE_MERCHANT_INITIAL_UPDATE_OFFLINE_MERCHANT_STATE
        );
      },
      initialUpdateOfflineMerchantBranchState() {
        this.$store.dispatch(
          OFFLINE_MERCHANT_INITIAL_UPDATE_OFFLINE_MERCHANT_BRANCH_STATE
        );
      },
      initialUpdateOfflineMerchantActivationState() {
        this.$store.dispatch(
          OFFLINE_MERCHANT_INITIAL_UPDATE_OFFLINE_MERCHANT_ACTIVATION_STATE
        );
      },
      initialUpdateOfflineMerchantBranchActivationState() {
        this.$store.dispatch(
          OFFLINE_MERCHANT_INITIAL_UPDATE_OFFLINE_MERCHANT_BRANCH_ACTIVATION_STATE
        );
      },
      initialDeleteOfflineMerchantBranchState() {
        this.$store.dispatch(
          OFFLINE_MERCHANT_INITIAL_DELETE_OFFLINE_MERCHANT_BRANCH_STATE
        );
      },
      initialOffileMerchantNew() {
        let breadcrumbData = {
          breadcrumbs: [
            {
              title: i18nHelper.getMessage(`label.offlineMerchant`),
              route: { name: ROUTE_NAME.OFFLINE_MERCHANT }
            },
            {
              title: i18nHelper.getMessage(
                `label.${
                  this.isCreate
                    ? 'createOfflineMerchantSetting'
                    : 'offlineMerchantSetting'
                }`
              )
            }
          ],
          actions: [
            {
              text: i18nHelper.getMessage('label.back'),
              function: () => {
                this.$router.go(-1);
              }
            }
          ]
        };
        this.initBreadCrumb(breadcrumbData);
        if (!this.isCreate) {
          this.id = this.$route.params.id;
          this.getOfflineMerchant(this.id);
          this.getOfflineMerchantBranch(this.id);
        }
      },
      async addNewBranch() {
        this.btnDisableAddBranch = false;

        await this.branch.push({
          registerCompanyName: null,
          businessNumber: null,
          addressName: null,
          streetAddress: null,
          addressDetails: null,
          hideBusinessAddressInProfilePage: false,
          contactPerson1: null,
          contactNumber1: null,
          contactPerson2: null,
          contactNumber2: null,
          contactPerson3: null,
          contactNumber3: null,
          email: null,
          pinVerification: null
        });

        await this.updateWizard();
        let vm = this;
        const wizard = new KTWizard('OfflineMerchantNewWizard', {
          startStep: 1, // initial active step number
          clickableSteps: true // allow step clicking
        });
        vm.constWizard = wizard;
        vm.constWizard.goTo(this.branch.length + 1);
      }
    },
    validations() {
      let isFilePlace = this.isFilePlace;
      return {
        offlineMerchant: {
          businessName: {
            required
          },
          businessLogo: {
            required,
            bannerImageType: isFilePlace.businessLogo ? bannerImageType : '',
            fileSize: isFilePlace.businessLogo
              ? fileSize(GLOBAL.BANNER_IMAGE_UPLOAD_SIZE)
              : ''
          },
          businessBanner: {
            required,
            bannerImageType: isFilePlace.businessBanner ? bannerImageType : '',
            fileSize: isFilePlace.businessBanner
              ? fileSize(GLOBAL.BANNER_IMAGE_UPLOAD_SIZE)
              : ''
          },
          contactPerson1: {},
          contactNumber1: {
            mobileNumber,
            numeric
          },
          contactPerson2: {},
          contactNumber2: {
            mobileNumber,
            numeric
          },
          description: {
            maxLength: maxLength(500)
          },
          serviceType: {
            required
          },
          pinVerification: {
            required,
            minLength: minLength(4),
            maxLength: maxLength(4)
          },
          cuisineType: {
            required
          },
          websiteLink: {
            url
          }
        },
        merchantBranch: {
          $each: {
            registerCompanyName: { required },
            businessNumber: { required },
            addressName: { required },
            streetAddress: { required },
            addressDetails: {},
            hideBusinessAddressInProfilePage: {},
            contactPerson1: {},
            contactNumber1: { required, mobileNumber, numeric },
            contactPerson2: {},
            contactNumber2: { mobileNumber, numeric },
            contactPerson3: {},
            contactNumber3: { mobileNumber, numeric },
            email: { email },
            pinVerification: {
              minLength: minLength(4),
              maxLength: maxLength(4)
            }
          }
        }
      };
    }
  };
</script>

<style lang="scss">
  .svg-icon-2x i {
    font-size: 1.5rem;
  }
  @import '@/assets/sass/pages/wizard/wizard-2.scss';
</style>
