import dateTimeFormat from '@/core/filters/dateTimeFormat.filter';
import currencyFormat from '@/core/filters/currency-format.filter';
import constantsDesc from '@/core/filters/constants-desc.filter';
import contactNumber from '@/core/filters/contact-number-format.filter';
import { ORDERS } from '@/core/api';
import { apiHelper } from '@/core/utils';
import {
  LOADING_OPEN_LOADING,
  LOADING_CLOSE_LOADING
} from '@/core/store/loading.module';
import constantsDescription from '@/core/filters/constants-description.filter';

/* Define Module Name */
const MODULE_NAME = 'orders/';

/* Define Action Name */
const A_GET_ORDERS = 'getOrders';
const A_GET_ECO_PACK_ORDERS = 'getEcoPackOrders';
const A_GET_XCHEFS_ORDERS = 'getOrdersXChefs';
const A_GET_ORDER = 'getOrder';
const A_UPDATE_ORDER_STATUS = 'updateOrderStatus';
const A_EXPORT_ORDER_EXCEL = 'exportOrderExcel';
const A_UPDATE_ORDER = 'updateOrder';
const A_UPDATE_ORDER_REASSIGNED_DRIVER_COMMENT =
  'updateOrderAssignedDriverComment';
const A_CONFIRM_ORDER = 'confirmOrder';
const A_REJECT_ORDER = 'rejectOrder';
const A_CANCEL_ORDER = 'cancelOrder';

/* Define Reset State Action Name */
const A_INITIAL_ORDERS_STATE = 'initialOrdersState';
const A_INITIAL_ORDER_STATE = 'initialOrderState';
const A_INITIAL_UPDATE_ORDER_STATUS_STATE = 'initialUpdateOrderStatusState';
const A_INITIAL_EXPORT_ORDER_EXCEL_STATE = 'initialExportOrderExcelState';
const A_INITIAL_UPDATE_ORDER_STATE = 'initialUpdateOrderState';
const A_INITIAL_CONFIRM_ORDER_STATE = 'initialConfirmOrderState';
const A_INITIAL_REJECT_ORDER_STATE = 'initialRejectOrderState';
const A_INITIAL_CANCEL_ORDER_STATE = 'initialCancelOrderState';
const A_INITIAL_UPDATE_ORDER_REASSIGNED_DRIVER_COMMENT_STATE =
  'initialUpdateOrderAssignedDriverCommentState';

/* Define Getter Name */

/* Define Mutation Name */
const M_ORDERS = 'setOrders';
const M_ORDER = 'setOrder';
const M_UPDATE_ORDER_STATUS = 'setUpdateOrderStatus';
const M_EXPORT_ORDER_EXCEL = 'setExportOrderExcel';
const M_UPDATE_ORDER = 'setUpdateOrder';
const M_UPDATE_ORDER_REASSIGNED_DRIVER_COMMENT =
  'setUpdateOrderAssignedDriverComment';
const M_CONFIRM_ORDER = 'setConfirmOrder';
const M_REJECT_ORDER = 'setRejectOrder';
const M_CANCEL_ORDER = 'setCancelOrder';

/* Define Reset State Mutation Name */
const M_INITIAL_ORDERS_STATE = 'setInitialOrdersState';
const M_INITIAL_ORDER_STATE = 'setInitialOrderState';
const M_INITIAL_UPDATE_ORDER_STATUS_STATE = 'setInitialUpdateOrderStatusState';
const M_INITIAL_EXPORT_ORDER_EXCEL_STATE = 'setInitialExportOrderExcelState';
const M_INITIAL_UPDATE_ORDER_STATE = 'setInitialUpdateOrderState';
const M_INITIAL_REJECT_ORDER_STATE = 'setInitialRejectOrderState';
const M_INITIAL_CANCEL_ORDER_STATE = 'setInitialCancelOrderState';
const M_INITIAL_CONFIRM_ORDER_STATE = 'setInitialConfirmOrderState';
const M_INITIAL_UPDATE_ORDER_REASSIGNED_DRIVER_COMMENT_STATE =
  'setInitialUpdateOrderAssignedDriverCommentState';

/* Define Export Name */
export const ORDERS_GET_ORDERS = MODULE_NAME + A_GET_ORDERS;
export const ORDERS_GET_ECO_PACK_ORDERS = MODULE_NAME + A_GET_ECO_PACK_ORDERS;
export const ORDERS_GET_XCHEFS_ORDERS = MODULE_NAME + A_GET_XCHEFS_ORDERS;
export const ORDERS_GET_ORDER = MODULE_NAME + A_GET_ORDER;
export const ORDERS_UPDATE_ORDER_STATUS = MODULE_NAME + A_UPDATE_ORDER_STATUS;
export const ORDERS_EXPORT_ORDER_EXCEL = MODULE_NAME + A_EXPORT_ORDER_EXCEL;
export const ORDERS_UPDATE_ORDER = MODULE_NAME + A_UPDATE_ORDER;
export const ORDERS_UPDATE_ORDER_REASSIGNED_DRIVER_COMMENT =
  MODULE_NAME + A_UPDATE_ORDER_REASSIGNED_DRIVER_COMMENT;
export const ORDER_CONFIRM_ORDER = MODULE_NAME + A_CONFIRM_ORDER;
export const ORDER_REJECT_ORDER = MODULE_NAME + A_REJECT_ORDER;
export const ORDER_CANCEL_ORDER = MODULE_NAME + A_CANCEL_ORDER;

/* Define Reset State Name */
export const ORDERS_INITIAL_ORDERS_STATE = MODULE_NAME + A_INITIAL_ORDERS_STATE;
export const ORDERS_INITIAL_ORDER_STATE = MODULE_NAME + A_INITIAL_ORDER_STATE;
export const ORDERS_INITIAL_UPDATE_ORDER_STATUS_STATE =
  MODULE_NAME + A_INITIAL_UPDATE_ORDER_STATUS_STATE;
export const ORDERS_INITIAL_EXPORT_ORDER_EXCEL_STATE =
  MODULE_NAME + A_INITIAL_EXPORT_ORDER_EXCEL_STATE;
export const ORDERS_INITIAL_UPDATE_ORDER_STATE =
  MODULE_NAME + A_INITIAL_UPDATE_ORDER_STATE;
export const ORDERS_INITIAL_REJECT_ORDER_STATE =
  MODULE_NAME + A_INITIAL_REJECT_ORDER_STATE;
export const ORDERS_INITIAL_CONFIRM_ORDER_STATE =
  MODULE_NAME + A_INITIAL_CONFIRM_ORDER_STATE;
export const ORDERS_INITIAL_CANCEL_ORDER_STATE =
  MODULE_NAME + A_INITIAL_CANCEL_ORDER_STATE;
export const ORDERS_INITIAL_UPDATE_ORDER_REASSIGNED_DRIVER_COMMENT_STATE =
  MODULE_NAME + A_INITIAL_UPDATE_ORDER_REASSIGNED_DRIVER_COMMENT_STATE;

const state = {
  orders: {
    code: 0,
    complete: false,
    data: null,
    message: null,
    pagination: {
      currentPage: 0,
      total: 0,
      perPage: 0,
      lastPage: 0
    }
  },
  order: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  updateOrderStatus: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  exportOrderExcel: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  updateOrder: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  updateOrderAssignedDriverComment: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  confirmOrder: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  rejectOrder: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  cancelOrder: {
    code: 0,
    complete: false,
    data: null,
    message: null
  }
};

//Getters
const getters = {};

// Actions
const actions = {
  [A_GET_ORDERS]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    ORDERS.getOrders(data)
      .then((response) => {
        result = response;
        commit(M_ORDERS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_ORDERS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_ECO_PACK_ORDERS]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    data.isCookxEcoPack = 1;
    ORDERS.getOrders(data)
      .then((response) => {
        result = response;
        commit(M_ORDERS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_ORDERS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_XCHEFS_ORDERS]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    data.is_xchefs = 1;
    ORDERS.getOrders(data)
      .then((response) => {
        result = response;
        commit(M_ORDERS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_ORDERS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_ORDER]({ dispatch, commit }, { id }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    ORDERS.getOrder(id)
      .then((response) => {
        result = response;
        commit(M_ORDER, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_ORDER, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_UPDATE_ORDER_STATUS]({ dispatch, commit }, { id, data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    ORDERS.updateOrderStatus(id, data)
      .then((response) => {
        result = response;
        commit(M_UPDATE_ORDER_STATUS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_UPDATE_ORDER_STATUS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },

  [A_EXPORT_ORDER_EXCEL]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    ORDERS.exportOrderExcel(data)
      .then((response) => {
        result = response;
        commit(M_EXPORT_ORDER_EXCEL, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_EXPORT_ORDER_EXCEL, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_UPDATE_ORDER]({ dispatch, commit }, { id, data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    ORDERS.updateOrder(id, data)
      .then((response) => {
        result = response;
        commit(M_UPDATE_ORDER, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_UPDATE_ORDER, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_UPDATE_ORDER_REASSIGNED_DRIVER_COMMENT](
    { dispatch, commit },
    { id, data }
  ) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    ORDERS.updateOrder(id, data)
      .then((response) => {
        result = response;
        commit(M_UPDATE_ORDER_REASSIGNED_DRIVER_COMMENT, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_UPDATE_ORDER_REASSIGNED_DRIVER_COMMENT, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_CONFIRM_ORDER]({ dispatch, commit }, { id, data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    ORDERS.confirmOrder(id, data)
      .then((response) => {
        result = response;
        commit(M_CONFIRM_ORDER, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_CONFIRM_ORDER, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_REJECT_ORDER]({ dispatch, commit }, { id, data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    ORDERS.rejectOrder(id, data)
      .then((response) => {
        result = response;
        commit(M_REJECT_ORDER, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_REJECT_ORDER, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_CANCEL_ORDER]({ dispatch, commit }, { id, data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    ORDERS.cancelOrder(id, data)
      .then((response) => {
        result = response;
        commit(M_CANCEL_ORDER, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_CANCEL_ORDER, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_INITIAL_ORDERS_STATE]({ commit }) {
    commit(M_INITIAL_ORDERS_STATE);
  },
  [A_INITIAL_ORDER_STATE]({ commit }) {
    commit(M_INITIAL_ORDER_STATE);
  },
  [A_INITIAL_UPDATE_ORDER_STATUS_STATE]({ commit }) {
    commit(M_INITIAL_UPDATE_ORDER_STATUS_STATE);
  },
  [A_INITIAL_EXPORT_ORDER_EXCEL_STATE]({ commit }) {
    commit(M_INITIAL_EXPORT_ORDER_EXCEL_STATE);
  },
  [A_INITIAL_UPDATE_ORDER_STATE]({ commit }) {
    commit(M_INITIAL_UPDATE_ORDER_STATE);
  },
  [A_INITIAL_CONFIRM_ORDER_STATE]({ commit }) {
    commit(M_INITIAL_CONFIRM_ORDER_STATE);
  },
  [A_INITIAL_REJECT_ORDER_STATE]({ commit }) {
    commit(M_INITIAL_REJECT_ORDER_STATE);
  },
  [A_INITIAL_CANCEL_ORDER_STATE]({ commit }) {
    commit(M_INITIAL_CANCEL_ORDER_STATE);
  },
  [A_INITIAL_UPDATE_ORDER_REASSIGNED_DRIVER_COMMENT_STATE]({ commit }) {
    commit(M_INITIAL_UPDATE_ORDER_REASSIGNED_DRIVER_COMMENT_STATE);
  }
};

// Mutations
const mutations = {
  [M_ORDERS](state, result) {
    if (result.code === 0) {
      let d = result.data;
      let p = result.pagination;

      state.orders = {
        code: result.code,
        complete: true,
        data: d.map((x, index) => {
          return {
            no: index + 1,
            id: x.id,
            orderIdentifier: x.order_identifier,
            transactionIdentifier: x.transaction_identifier,
            orderStatus: x.order_status,
            deliveryStatus: x.delivery_status,
            deliveryMethod: constantsDescription(
              'deliveryMethod',
              x.delivery_method
            ),
            orderBy: x.order_by,
            orderById: x.order_by_id,
            orderFrom: x.order_from,
            orderFromId: x.order_from_id,
            deliveryOrderId: x.delivery_order_id ? x.delivery_order_id : '-',
            createdAt: dateTimeFormat(x.created_at),
            updatedAt: dateTimeFormat(x.updated_at),
            total: currencyFormat(x.total),
            stripePaymentIntentId: x.stripe_payment_intent_id,
            scheduledAt: dateTimeFormat(x.scheduled_at),
            reassignedByAdminUsername: x.reassigned_by_admin_username
              ? x.reassigned_by_admin_username
              : '-'
          };
        }),
        message: null,
        pagination: {
          currentPage: p.current_page,
          total: p.total,
          perPage: p.per_page,
          lastPage: p.last_page
        }
      };
    } else {
      state.orders = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message),
        pagination: {
          currentPage: 0,
          total: 0,
          perPage: 0,
          lastPage: 0
        }
      };
    }
  },
  [M_ORDER](state, result) {
    function recipeOptions(data) {
      return {
        isEco: Number(data.is_eco),
        remark: data.remark,
        equipment: data.equipment
          ? data.equipment.map((x, index) => {
              return {
                no: index + 1,
                id: x.id,
                uuid: x.uuid,
                recipeId: x.recipe_id,
                quantity: x.quantity,
                name: x.name,
                brandName: x.brand_name,
                price: currencyFormat(x.price),
                createdAt: dateTimeFormat(x.created_at),
                updatedAt: dateTimeFormat(x.updated_at)
              };
            })
          : [],
        ingredients: data.ingredients
          ? data.ingredients.map((x, index) => {
              return {
                no: index + 1,
                id: x.id,
                name: x.name,
                unit: constantsDesc(x.unit, 'unitType'),
                uuid: x.uuid,
                count: x.count,
                price: currencyFormat(x.price),
                recipeId: x.recipe_id,
                createdAt: dateTimeFormat(x.created_at),
                updatedAt: dateTimeFormat(x.updated_at)
              };
            })
          : [],
        variations: data.variation_options
          ? data.variation_options.map((x) => {
              return {
                name: x.name,
                price: currencyFormat(x.price),
                isActive: x.is_active,
                uuid: x.uuid
              };
            })
          : []
      };
    }

    function productOptions(data) {
      return {
        remark: data.remark,
        addOns: data.add_ons
          ? data.add_ons.map((x, index) => {
              return {
                no: index + 1,
                name: x.name,
                uuid: x.uuid,
                price: currencyFormat(x.price),
                quantity: x.quantity ? x.quantity : '-',
                isActive: x.is_active
              };
            })
          : [],
        variation: {
          name: data.variation.name,
          uuid: data.variation.uuid,
          price: currencyFormat(data.variation.price),
          isActive: data.variation.is_active
        }
      };
    }

    if (result.code === 0) {
      let d = result.data;

      state.order = {
        code: result.code,
        complete: true,
        data: {
          id: d.id,
          orderIdentifier: d.order_identifier,
          transactionIdentifier: d.transaction_identifier,
          orderStatus: d.order_status,
          deliveryStatus: d.delivery_status,
          orderBy: d.order_by,
          orderById: d.order_by_id,
          orderFrom: d.order_from,
          orderFromId: d.order_from_id,
          deliveryOrderId: d.delivery_order_id,
          subtotal: d.subtotal,
          tax: d.tax,
          deliveryFee: d.delivery_fee,
          deliveryFee2: d.delivery_fee_2,
          deliveryVendor: d.delivery_vendor,
          total: d.total,
          paymentIntentId: d.payment_intent_id,
          createdAt: dateTimeFormat(d.created_at),
          updatedAt: dateTimeFormat(d.updated_at),
          orderItems: d.order_items.map((x, index) => {
            return {
              no: index + 1,
              id: x.id,
              model: x.model.toLowerCase(),
              modelId: x.model_id,
              name: x.name,
              description: x.description,
              options:
                x.model.toLowerCase() == 'recipe'
                  ? recipeOptions(x.options)
                  : productOptions(x.options),
              quantity: x.quantity,
              price: x.price,
              subtotal: x.subtotal,
              tax: x.tax,
              createdAt: dateTimeFormat(x.created_at),
              updatedAt: dateTimeFormat(x.updated_at),
              thumbnail: x.thumbnail
            };
          }),
          deliveryMethod: d.delivery_method,
          deliveryFrom: {
            name: d.delivery_from.name,
            address: d.delivery_from.address,
            contactNumber: contactNumber(d.delivery_from.contact_number)
          },
          deliveryTo: d.delivery_to
            ? {
                name: d.delivery_to.name,
                address: d.delivery_to.address ? d.delivery_to.address : null,
                shareLink: d.delivery_to.share_link
                  ? d.delivery_to.share_link
                  : null,
                contactNumber: contactNumber(d.delivery_to.contact_number),
                deliveryRetriedAt: d.delivery_to.delivery_retried_at
                  ? dateTimeFormat(d.delivery_to.delivery_retried_at)
                  : null,
                remark: d.delivery_to.remarks ? d.delivery_to.remarks : null
              }
            : null,
          scheduledAt: dateTimeFormat(d.scheduled_at),
          completedAt: dateTimeFormat(d.completed_at),
          orderByObject: {
            id: d.order_by_id,
            name: d.order_by
          },
          orderFromObject: {
            id: d.order_from_id,
            name: d.order_from
          },
          priceBreakdownObject: {
            subtotal: d.subtotal,
            peakHourSurcharge: d.delivery_surcharge_fee,
            tax: d.tax,
            delivery: d.delivery_fee,
            delivery2: d.delivery_fee_2,
            total: d.total,
            cookies: d.cookies_price,
            voucherByMerchant: d.voucher_by_merchant_price,
            voucherByPlatform: d.voucher_by_platform_price,
            deliveryStatus: d.delivery_status,
            deliveryMethod: d.delivery_method
          },
          reassignedByAdminUsername: d.reassigned_by_admin_username,
          reassignedDriverComment: d.reassigned_driver_comment
        },
        message: null
      };
    } else {
      state.order = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_UPDATE_ORDER_STATUS](state, result) {
    state.updateOrderStatus = {
      code: result.code,
      complete: true,
      data: null,
      message:
        result.code != 0 ? apiHelper.sortOutMessage(result.message) : null
    };
  },
  [M_EXPORT_ORDER_EXCEL](state, result) {
    state.exportOrderExcel = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_UPDATE_ORDER](state, result) {
    state.updateOrder = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_CONFIRM_ORDER](state, result) {
    state.confirmOrder = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_REJECT_ORDER](state, result) {
    state.rejectOrder = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_CANCEL_ORDER](state, result) {
    state.cancelOrder = {
      code: 0,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_UPDATE_ORDER_REASSIGNED_DRIVER_COMMENT](state, result) {
    state.updateOrderAssignedDriverComment = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_INITIAL_ORDERS_STATE](state) {
    state.orders = {
      code: 0,
      complete: false,
      data: null,
      message: null,
      pagination: {
        currentPage: 0,
        total: 0,
        perPage: 0,
        lastPage: 0
      }
    };
  },
  [M_INITIAL_ORDER_STATE](state) {
    state.order = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_UPDATE_ORDER_STATUS_STATE](state) {
    state.updateOrderStatus = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_EXPORT_ORDER_EXCEL_STATE](state) {
    state.exportOrderExcel = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_UPDATE_ORDER_STATE](state) {
    state.updateOrder = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_CONFIRM_ORDER_STATE](state) {
    state.confirmOrder = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_REJECT_ORDER_STATE](state) {
    state.rejectOrder = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_CANCEL_ORDER_STATE](state) {
    state.cancelOrder = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_UPDATE_ORDER_REASSIGNED_DRIVER_COMMENT_STATE](state) {
    state.updateOrderAssignedDriverComment = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
