<template>
  <div>
    <div v-for="(i, index) in configuration" :key="index">
      <div class="p-5 py-10">
        <components :is="i.leyout"></components>
      </div>
      <div class="separator separator-solid"></div>
    </div>
  </div>
</template>

<script>
  import ConfigGoogleCalendar from '@/views/components/configuration/ConfigGoogleCalendar.vue';
  import ConfigEmailsRecipient from '@/views/components/configuration/ConfigEmailsRecipient.vue';
  import ConfigWATIWhatsappTesting from '@/views/components/configuration/ConfigWATIWhatsappTesting.vue';
  import ConfigNotifyCreator from '@/views/components/configuration/ConfigNotifyCreator.vue';
  import ConfigNotifyStudio from '@/views/components/configuration/ConfigNotifyStudio.vue';

  export default {
    name: 'config-general',
    components: {
      ConfigGoogleCalendar,
      ConfigEmailsRecipient,
      ConfigWATIWhatsappTesting,
      ConfigNotifyCreator,
      ConfigNotifyStudio
    },
    data: () => ({
      configuration: [
        //Google API
        {
          leyout: 'ConfigGoogleCalendar'
        },
        // Admin Email's Recipients
        {
          leyout: 'ConfigEmailsRecipient'
        },
        // WATI Testing
        {
          leyout: 'ConfigWATIWhatsappTesting'
        },
        // Notify Creator WhatsApp
        {
          leyout: 'ConfigNotifyCreator'
        },
        // Notify Studio WhatsApp
        {
          leyout: 'ConfigNotifyStudio'
        }
      ]
    })
  };
</script>

<style></style>
