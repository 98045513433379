<template>
  <div>
    <AppSmartList
      :breadcrumbData="breadcrumbData"
      :accessRights="accessRights"
      :filters="filters"
      :actions="actions"
      :tableHeaders="headers"
      :newRecord="newRecord"
      @app-smart-list-action-download="actionDownload"
    ></AppSmartList>
  </div>
</template>

<script>
  import commonMixin from '@/core/mixins/common.mixin';
  import AppSmartList from '@/views/components/bases/smart/AppSmartList.vue';
  import { i18nHelper } from '@/core/utils';
  import {
    ROUTE_NAME,
    MODULE,
    ACTION,
    APP_COMPONENT_NAME,
    SMART_FORM_FIELD_TYPE
  } from '@/core/constants';
  import { listService } from '@/core/services';
  import {
    REFERRAL_CODE_GET_REFERAL_CODES,
    REFERRAL_CODE_INITIAL_GET_REFERAL_CODES_STATE
  } from '@/core/store/referral-code.module';
  import { apiService } from '@/core/services';
  import {
    LOADING_OPEN_LOADING,
    LOADING_CLOSE_LOADING
  } from '@/core/store/loading.module';

  export default {
    name: 'ReferralCodeList',
    components: {
      AppSmartList
    },
    mixins: [commonMixin],

    data: () => ({
      breadcrumbData: {
        breadcrumbs: [
          {
            title: i18nHelper.getMessage('label.referralCode'),
            route: { name: ROUTE_NAME.REFERRAL_CODE }
          },
          { title: i18nHelper.getMessage('label.referralCodeList') }
        ]
      },
      accessRights: {
        moduleName: MODULE.REFERRAL_CODE,
        actions: {
          create: ACTION.REFERRAL_CODE_CREATE,
          read: ACTION.REFERRAL_CODE_READ,
          update: ACTION.REFERRAL_CODE_UPDATE,
          delete: ACTION.REFERRAL_CODE_DELETE
        }
      },
      filters: [
        {
          label: 'referralCode',
          key: 'referralCode',
          value: ''
        },
        {
          label: 'id',
          key: 'id',
          value: ''
        },
        {
          label: 'status',
          key: 'status',
          value: '',
          options: listService.getReferralCodeStatusList(false),
          optionLabel: 'text',
          type: SMART_FORM_FIELD_TYPE.SELECT
        }
      ],
      actions: {
        viewList: {
          module: 'referralCode',
          state: 'referralCodes',
          action: REFERRAL_CODE_GET_REFERAL_CODES,
          initialStateAction: REFERRAL_CODE_INITIAL_GET_REFERAL_CODES_STATE,
          title: i18nHelper.getMessage('label.getCreatorApplications')
        }
      },
      headers: [
        {
          key: 'no',
          name: 'no',
          active: true,
          sortable: true
        },
        {
          key: 'id',
          name: 'id',
          active: true,
          sortable: true
        },
        {
          key: 'referralCode',
          name: 'referralCode',
          active: true,
          sortable: true,
          class: 'min-w-130px'
        },
        {
          key: 'rewardTypeDesc',
          name: 'rewardType',
          active: true,
          sortable: true
        },
        {
          key: 'quantity',
          name: 'quantity',
          active: true,
          sortable: true
        },
        {
          key: 'totalRedemption',
          name: 'totalRedemption',
          active: true,
          sortable: true
        },
        {
          key: 'totalClaim',
          name: 'totalClaim',
          active: true,
          sortable: true
        },
        {
          key: 'status',
          name: 'status',
          active: true,
          sortable: true,
          layout: 'ReferralCodeStatusLabel'
        },
        {
          key: 'availableFrom',
          name: 'availableFrom',
          active: true,
          sortable: true
        },
        {
          key: 'availableTo',
          name: 'availableTo',
          active: true,
          sortable: true
        },
        {
          key: 'edit',
          name: 'edit',
          active: true,
          action: true,
          layout: APP_COMPONENT_NAME.APP_TABLE_ACTIONS,
          layoutActions: {
            viewDetailsRouteName: ROUTE_NAME.REFERRAL_CODE_DETAILS
          }
        },
        {
          key: 'export',
          name: 'export',
          active: true,
          action: true,
          layout: APP_COMPONENT_NAME.APP_TABLE_ACTIONS,
          layoutActions: {
            isDownload: true
          }
        }
      ],
      newRecord: {
        show: true,
        buttonTitle: i18nHelper.getMessage('label.newReferral'),
        routeName: ROUTE_NAME.REFERRAL_CODE_CREATE
      }
    }),
    methods: {
      actionDownload(data) {
        let id = data.id;
        this.$store.dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
        apiService()
          .get(`/admin/vouchers/${id}/export-referees`, {
            responseType: 'blob'
          })
          .then((response) => {
            this.disabled = false;
            this.$store.dispatch(`${LOADING_CLOSE_LOADING}`, null, {
              root: true
            });
            if (response.type == 'application/json') {
              apiService()
                .get(`/admin/vouchers/${id}/export-referees`, {})
                .then((response) => {
                  let title = i18nHelper.getMessage(
                    'label.getReferralCodeDetails'
                  );
                  response = {
                    ...response,
                    message: [response.message]
                  };
                  this.openErrorLayoutAppModalInfo(title, response);
                });
            } else {
              const url = window.URL.createObjectURL(new Blob([response]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', `${id}_referee_list.xlsx`);
              document.body.appendChild(link);
              link.click();
            }
          });
      }
    }
  };
</script>

<style></style>
