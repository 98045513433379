<template>
  <b-modal
    :visible="modal"
    :title="$t('label.reassignedDriverComment')"
    centered
    no-close-on-backdrop
    @close="closeAction"
  >
    <AppFormField
      v-model="$v.comment.$model"
      :state="validateState('comment')"
      :validationValue="$v.comment"
      :title="$t('label.comment')"
      :validators="['required']"
      formType="textarea"
    >
    </AppFormField>
    <div style="display: none">
      <!-- WARNING: I have no idea why this line is here, but removing it breaks everything -->
      {{ $v }}
    </div>
    <template #modal-footer>
      <b-button @click="cancelAction">{{ $t('label.cancel') }}</b-button>
      <b-button variant="success" @click="submitAction">{{
        $t('label.submit')
      }}</b-button>
    </template>
  </b-modal>
</template>

<script>
  import AppFormField from '@/views/components/bases/form-field/AppFormField';
  import { validationMixin } from 'vuelidate';
  import { required } from 'vuelidate/lib/validators';
  import commonMixin from '@/core/mixins/common.mixin';

  export default {
    name: 'OrderReassignedDriverCommentModal',
    components: {
      AppFormField
    },
    mixins: [validationMixin, commonMixin],
    props: {
      value: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      modal: false,
      comment: null
    }),
    watch: {
      value() {
        this.modal = this.value;
      }
    },
    methods: {
      closeAction() {
        this.reset();
      },
      cancelAction() {
        this.reset();
      },
      submitAction() {
        if (this.validationCheck('comment')) {
          this.$emit('submit', this.comment);
          this.reset();
        }
      },
      reset() {
        this.comment = null;
        this.$v.$reset();
        this.$emit('input', false);
      }
    },
    validations: {
      comment: {
        required
      }
    }
  };
</script>

<style></style>
