<template>
  <RecipePageList :category="recipeType"></RecipePageList>
</template>

<script>
  import RecipePageList from '@/views/components/recipe-page/RecipePageList';
  import { RecipePageType } from '@/core/constants/enums';

  export default {
    name: 'RecipePageBakeryList',
    components: {
      RecipePageList
    },
    data: () => ({
      recipeType: RecipePageType.BAKERY
    })
  };
</script>

<style></style>
