<template>
  <div>
    <b-row no-gutters>
      <b-col cols="12" class="pb-5">
        <div class="wizard-label">
          <h3 class="wizard-title text-capitalize">
            {{ $t(`label.studioBookingEnquiryMessageNotification`) }}
          </h3>
          <div class="wizard-desc">
            {{ $t(`message.studioBookingEnquiryMessageNotificationMessage`) }}
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row no-gutters>
      <b-col cols="12">
        <div v-if="notifyStudioObj.length < 6">
          <ConfigNumberFormField
            :editable="true"
            @save="createNotifyStudioNumberAction"
          >
          </ConfigNumberFormField>
        </div>
        <div v-for="(item, index) in notifyStudioObj" :key="index">
          <ConfigNumberFormField
            :item="item"
            :value="item.phone"
            :editable="true"
            @save="updateNotifyStudioNumberAction"
            @delete="deleteNotifyStudioNumberAction"
          >
          </ConfigNumberFormField>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import commonMixin from '@/core/mixins/common.mixin';
  import { i18nHelper } from '@/core/utils';
  import ConfigNumberFormField from '@/views/components/configuration/form-field/ConfigNumberFormField.vue';
  import {
    GENERAL_GET_NOTIFY_STUDIO,
    GENERAL_INITIAL_GET_NOTIFY_STUDIO_STATE,
    GENERAL_CREATE_NOTIFY_STUDIO,
    GENERAL_INITIAL_CREATE_NOTIFY_STUDIO_STATE,
    GENERAL_UPDATE_NOTIFY_STUDIO,
    GENERAL_INITIAL_UPDATE_NOTIFY_STUDIO_STATE,
    GENERAL_DELETE_NOTIFY_STUDIO,
    GENERAL_INITIAL_DELETE_NOTIFY_STUDIO_STATE
  } from '@/core/store/configuration/config-notify-studio.module';

  export default {
    name: 'ConfigNotifyStudio',
    mixins: [commonMixin],
    components: {
      ConfigNumberFormField
    },
    data: () => ({
      notifyStudioObj: []
    }),
    mounted() {
      this.initialConfiguration();
    },
    computed: {
      notifyStudioComplete() {
        return this.$store.state.configNotifyStudio.notifyStudio.complete;
      },
      createNotifyStudioComplete() {
        return this.$store.state.configNotifyStudio.createNotifyStudio.complete;
      },
      updateNotifyStudioComplete() {
        return this.$store.state.configNotifyStudio.updateNotifyStudio.complete;
      },
      deleteNotifyStudioComplete() {
        return this.$store.state.configNotifyStudio.deleteNotifyStudio.complete;
      }
    },
    watch: {
      notifyStudioComplete() {
        let response = this.$store.state.configNotifyStudio.notifyStudio;
        let title = i18nHelper.getMessage('label.newOrderMessageNotification');
        let initialStateAction = GENERAL_INITIAL_GET_NOTIFY_STUDIO_STATE;
        let successAction = (response) => {
          this.importNotifyStudioObj(response);
        };
        if (response.complete) {
          this.actionReadRecordComplete(
            response,
            title,
            initialStateAction,
            successAction
          );
        }
      },
      createNotifyStudioComplete() {
        let response = this.$store.state.configNotifyStudio.createNotifyStudio;
        if (response.complete) {
          this.getNotifyStudio();
          this.initialCreateNotifyStudio();
        }
      },
      updateNotifyStudioComplete() {
        let response = this.$store.state.configNotifyStudio.updateNotifyStudio;
        if (response.complete) {
          this.getNotifyStudio();
          this.initialUpdateNotifyStudio();
        }
      },
      deleteNotifyStudioComplete() {
        let response = this.$store.state.configNotifyStudio.deleteNotifyStudio;
        if (response.complete) {
          this.getNotifyStudio();
          this.initialDeleteNotifyStudio();
        }
      }
    },
    methods: {
      importNotifyStudioObj(response) {
        this.notifyStudioObj = response.data;
      },
      getNotifyStudio() {
        this.$store.dispatch(GENERAL_GET_NOTIFY_STUDIO);
      },
      createNotifyStudioNumberAction(value) {
        let data = {
          contactNumber: 60 + value.contactNumber
        };
        this.$store.dispatch(GENERAL_CREATE_NOTIFY_STUDIO, { data });
      },
      updateNotifyStudioNumberAction(value) {
        let id = value.id;
        let data = {
          contactNumber: 60 + value.contactNumber
        };
        this.notifyStudioObj = '';
        this.$store.dispatch(GENERAL_UPDATE_NOTIFY_STUDIO, { id, data });
      },
      deleteNotifyStudioNumberAction(value) {
        let id = value.id;
        this.$store.dispatch(GENERAL_DELETE_NOTIFY_STUDIO, { id });
      },
      initialCreateNotifyStudio() {
        this.$store.dispatch(GENERAL_INITIAL_CREATE_NOTIFY_STUDIO_STATE, {});
      },
      initialUpdateNotifyStudio() {
        this.$store.dispatch(GENERAL_INITIAL_UPDATE_NOTIFY_STUDIO_STATE, {});
      },
      initialDeleteNotifyStudio() {
        this.$store.dispatch(GENERAL_INITIAL_DELETE_NOTIFY_STUDIO_STATE, {});
      },
      initialConfiguration() {
        this.getNotifyStudio();
      }
    }
  };
</script>

<style></style>
