<template>
  <label
    class="label label-md label-inline"
    :class="[`label-light-${ecoPackStatusColor(value)}`, `font-size-${size}`]"
    :style="styleName"
  >
    {{ $t(`enumRecipeEcoPackStatus.${value}`) }}
  </label>
</template>

<script>
  import { RecipeEcoPackStatus } from '@/core/constants/enums';

  export default {
    name: 'RecipeEcoPackStatusLabel',
    props: {
      value: { default: 0 },
      styleName: {
        type: String,
        default: null
      },
      size: {
        type: String,
        default: null
      }
    },
    methods: {
      ecoPackStatusColor(value) {
        let color = {
          [RecipeEcoPackStatus.PENDING_ACCEPTANCE]: () => 'primary',
          [RecipeEcoPackStatus.ACCEPTED]: () => 'success',
          [RecipeEcoPackStatus.CANCEL]: () => 'danger',
          [RecipeEcoPackStatus.EXPIRED]: () => 'warning',
          [RecipeEcoPackStatus.REJECTED]: () => 'danger',
          [RecipeEcoPackStatus.PENDING_APPROVAL]: () => 'primary',
          [RecipeEcoPackStatus.COOKX_ACCEPTED]: () => 'success'
        };
        return color[value]();
      }
    }
  };
</script>

<style></style>
