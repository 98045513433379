<template>
  <div>
    <AppSmartList
      :breadcrumbData="breadcrumbData"
      :accessRights="accessRights"
      :filters="filters"
      :actions="actions"
      :tableHeaders="headers"
    ></AppSmartList>
  </div>
</template>

<script>
  import AppSmartList from '@/views/components/bases/smart/AppSmartList.vue';
  import { i18nHelper } from '@/core/utils';
  import {
    ROUTE_NAME,
    MODULE,
    ACTION,
    APP_COMPONENT_NAME,
    SMART_FORM_FIELD_TYPE
  } from '@/core/constants';
  import { listService } from '@/core/services';
  import {
    XSTREET_GET_XSTREET_LIST,
    XSTREET_INITIAL_GET_XSTREET_LIST_STATE
  } from '@/core/store/xstreet.module';

  export default {
    name: 'XStreetList',
    components: {
      AppSmartList
    },
    data: () => ({
      breadcrumbData: {
        breadcrumbs: [
          {
            title: i18nHelper.getMessage('label.bulletin'),
            route: { name: ROUTE_NAME.XSTREET }
          },
          { title: i18nHelper.getMessage('label.bulletinList') }
        ]
      },
      accessRights: {
        moduleName: MODULE.XSTREET,
        actions: {
          create: ACTION.XSTREET_CREATE,
          read: ACTION.XSTREET_READ,
          update: ACTION.XSTREET_UPDATE,
          delete: ACTION.XSTREET_DELETE
        }
      },
      filters: [
        {
          label: 'title',
          key: 'title',
          value: ''
        },
        {
          label: 'createdBy',
          key: 'username',
          value: ''
        },
        {
          label: 'createdDate',
          key: 'dateRange',
          value: null,
          type: SMART_FORM_FIELD_TYPE.DATE_RANGE
        },
        {
          label: 'visibility',
          key: 'isVisible',
          value: null,
          options: listService.getYesNoList(false),
          optionLabel: 'text',
          type: SMART_FORM_FIELD_TYPE.SELECT
        }
      ],
      actions: {
        viewList: {
          module: 'xstreet',
          state: 'XStreetList',
          action: XSTREET_GET_XSTREET_LIST,
          initialStateAction: XSTREET_INITIAL_GET_XSTREET_LIST_STATE,
          title: i18nHelper.getMessage('label.getBulletinList')
        }
      },
      headers: [
        {
          key: 'no',
          name: 'no',
          active: true,
          sortable: true
        },
        {
          key: 'title',
          name: 'title',
          active: true,
          sortable: true,
          class: 'min-w-100px'
        },
        {
          key: 'username',
          name: 'createdBy',
          active: true,
          sortable: true
        },

        {
          key: 'createdAt',
          name: 'createdAt',
          active: true,
          sortable: true
        },
        {
          key: 'isVisible',
          name: 'visibility',
          active: true,
          sortable: true,
          layout: APP_COMPONENT_NAME.APP_CHECK_CIRCLE
        },
        {
          key: 'actions',
          name: 'actions',
          active: true,
          action: true,
          layout: APP_COMPONENT_NAME.APP_TABLE_ACTIONS,
          layoutActions: {
            viewDetailsRouteName: ROUTE_NAME.XSTREET_DETAILS
          }
        }
      ]
    })
  };
</script>

<style></style>
