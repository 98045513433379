<template>
  <div>
    <label
      v-if="![null, undefined, ''].includes(value)"
      class="label label-inline"
      :class="`label-light-${config.statusColor(value)} label-${
        config.size || 'md'
      }`"
    >
      {{ $t(`${config.enumKey}.${value}`) }}
    </label>
    <div v-else>
      {{ '-' }}
    </div>
  </div>
</template>

<script>
  export default {
    name: 'AppEnumLabel',
    props: {
      value: {
        type: [Number, String],
        default: null
      },
      config: {
        type: Object,
        default: () => {
          return {
            size: 'md',
            enumKey: '',
            statusColor: (value) => {
              let color = {
                1: 'primary',
                2: 'warning',
                3: 'danger'
              };

              return color[value];
            }
          };
        }
      }
    }
  };
</script>

<style></style>
