import { apiService } from '@/core/services';
// import { apiHelper } from '@/core/utils';

export const CONFIG_POINT_SETTING = {
  getPointSetting,
  updatePointSetting,
  updateRedeemableProduct
};

const URL = {
  ADMIN: 'admin',
  POINT_SETTING: 'point-setting',
  REDEEMABLE_PRODUCT: 'redeemable-product-setting',
};

function getPointSetting() {
  return apiService().get(`/${URL.ADMIN}/${URL.POINT_SETTING}`);
}

function updatePointSetting(data) {
  let requestBody = {
    point: data
  };
  return apiService().post(
    `/${URL.ADMIN}/${URL.POINT_SETTING}`,
    requestBody
  );
}

function updateRedeemableProduct(formData) {
  return apiService().post(
    `/${URL.ADMIN}/${URL.REDEEMABLE_PRODUCT}`,
      formData
  );
}
