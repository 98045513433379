<template>
  <div>
    <b-dropdown
      v-if="!deliveryStatusDisabled"
      size="sm"
      :text="$t(`enumDeliveryStatus.${value}`)"
      :variant="DeliveryStatusColor(value)"
    >
      <template
        v-for="(item, index) in availableStatus.find((x) => x.value == value)
          .relatedStatus"
      >
        <b-dropdown-item
          :key="index"
          class="d-flex align-center cursor-pointer"
          @click="selectStatusAction(item)"
        >
          <label
            class="label label-lg label-inline cursor-pointer"
            :class="`label-light-${DeliveryStatusColor(value)}`"
          >
            {{ $t(`enumDeliveryStatus.${value}`) }}</label
          >
          <i class="fas fa-arrow-right my-auto mx-4"></i>
          <label
            class="label label-lg label-inline cursor-pointer"
            :class="`label-light-${DeliveryStatusColor(item)}`"
          >
            {{ $t(`enumDeliveryStatus.${item}`) }}</label
          >
        </b-dropdown-item>
      </template>
    </b-dropdown>
    <label
      v-else
      :class="`label label-xl label-inline text-capitalize cursor-default text-white py-5 bg-${DeliveryStatusColor(
        value
      )}`"
    >
      {{ $t(`enumDeliveryStatus.${value}`) }}
    </label>
  </div>
</template>

<script>
  import {
    DeliveryStatus,
    DeliveryMethod,
    DeliveryVendor
  } from '@/core/constants/enums';

  export default {
    name: 'DeliveryStatusDropdown',
    props: {
      value: {
        type: Number,
        default: DeliveryStatus.NO_DRIVER_ASSIGNED
      },
      parentObject: {
        type: Object,
        default: () => {}
      }
    },
    data: () => ({
      availableStatus: [
        {
          value: DeliveryStatus.NO_DRIVER_ASSIGNED
        },
        {
          value: DeliveryStatus.PROCESSING,
          relatedStatus: []
        },
        {
          value: DeliveryStatus.ASSIGNING_DRIVER,
          relatedStatus: []
        },
        {
          value: DeliveryStatus.ON_GOING,
          relatedStatus: [DeliveryStatus.PICKED_UP]
        },
        {
          value: DeliveryStatus.PICKED_UP,
          relatedStatus: [DeliveryStatus.ON_GOING, DeliveryStatus.COMPLETED]
        },
        {
          value: DeliveryStatus.COMPLETED,
          relatedStatus: []
        },
        {
          value: DeliveryStatus.REJECTED,
          relatedStatus: []
        },
        {
          value: DeliveryStatus.CANCELLED,
          relatedStatus: [DeliveryStatus.ON_GOING]
        },
        {
          value: DeliveryStatus.EXPIRED,
          relatedStatus: []
        },
        {
          value: DeliveryStatus.MANUAL,
          relatedStatus: [DeliveryStatus.ON_GOING]
        },
        {
          value: DeliveryStatus.SELF_PICKUP
        }
      ]
    }),
    computed: {
      meData() {
        return this.$store.state.auth.me.data;
      },
      deliveryStatusDisabled() {
        let order = this.parentObject;
        let pic = this.parentObject.reassignedByAdminUsername;
        if (
          order.deliveryMethod == DeliveryMethod.PRE_ORDER ||
          order.deliveryMethod == DeliveryMethod.DELIVERY_BY_CAR
        ) {
          if (
            (order.deliveryVendor == DeliveryVendor.LALAMOVE &&
              order.deliveryStatus == DeliveryStatus.CANCELLED) ||
            (order.deliveryVendor == DeliveryVendor.MANUAL &&
              [DeliveryStatus.ON_GOING, DeliveryStatus.PICKED_UP].includes(
                order.deliveryStatus
              )) ||
            [
              DeliveryStatus.ON_GOING,
              DeliveryStatus.PICKED_UP,
              DeliveryStatus.MANUAL
            ].includes(order.deliveryStatus)
          ) {
            if (pic) {
              return !(pic == this.meData.username);
            } else {
              return true;
            }
          } else {
            return true;
          }
        } else {
          return true;
        }
      }
    },
    methods: {
      selectStatusAction(value) {
        this.$emit('select-delivery-status', value);
      },
      DeliveryStatusColor(value) {
        let color = {
          [DeliveryStatus.NO_DRIVER_ASSIGNED]: 'danger',
          [DeliveryStatus.PROCESSING]: 'warning',
          [DeliveryStatus.ASSIGNING_DRIVER]: 'warning',
          [DeliveryStatus.ON_GOING]: 'warning',
          [DeliveryStatus.PICKED_UP]: 'primary',
          [DeliveryStatus.COMPLETED]: 'success',
          [DeliveryStatus.REJECTED]: 'danger',
          [DeliveryStatus.CANCELLED]: 'danger',
          [DeliveryStatus.EXPIRED]: 'danger',
          [DeliveryStatus.MANUAL]: 'info',
          [DeliveryStatus.SELF_PICKUP]: 'success'
        };

        return color[value];
      }
    }
  };
</script>

<style></style>
