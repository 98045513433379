<template>
  <div>
    <template v-for="(group, groupIndex) in groupsList">
      <!--begin: Group-->
      <div :key="groupIndex">
        <!--begin: Section Title-->
        <div class="d-flex justify-content-between align-items-center">
          <b-card-title class="text-capitalize font-weight-boldest px-5 mt-8">
            {{ $t(`label.${group}`) }}
          </b-card-title>
          <b-button
            v-if="group == 'creatorInformation'"
            size="sm"
            variant="primary"
            class="ml-2 text-capitalize d-flex align-items-center"
            @click="actionEditPersonalInform"
          >
            <i class="fas fa-user-edit p-0 mr-2"></i>
            {{ $t('label.editCreatorInformation') }}
          </b-button>

          <!--end: Section Title-->
        </div>

        <!--begin: Details List-->
        <div
          v-if="!['addressBook'].includes(group)"
          class="separator separator-solid"
        ></div>

        <div v-if="!['addressBook'].includes(group)">
          <template
            v-for="(i, index) in creatorDetailList.filter(
              (x) => x.group == groupIndex + 1
            )"
          >
            <div :key="index">
              <b-row no-gutters class="p-5">
                <b-col cols="4">
                  <label class="text-capitalize">{{
                    $t(`label.${i.label}`)
                  }}</label>
                </b-col>
                <b-col cols="8">
                  <div v-if="i.key == 'hashtag'">
                    <span v-if="creatorProfileObj.hashtag != '-'"
                      ><template
                        v-for="(items, index) in creatorProfileObj.hashtag"
                      >
                        <b-form-tag
                          :key="index"
                          :title="items"
                          variant="info"
                          no-remove
                          class="mb-1"
                        ></b-form-tag>
                      </template>
                    </span>
                    <span v-else>{{ creatorProfileObj.hashtag }}</span>
                  </div>
                  <div
                    v-else-if="['recipeCount', 'productCount'].includes(i.key)"
                  >
                    <div v-if="creatorProfileObj[i.key] != 0">
                      <router-link
                        v-if="creatorProfileObj[i.key].active"
                        :to="{
                          name:
                            i.key == 'recipeCount'
                              ? routeName.EXPERIENCE_COOKING_ALL
                              : routeName.PRE_ORDER_FOOD_ALL,
                          query: { userId: id, isActive: 1 }
                        }"
                        class="text-hover-decoration-underline"
                        >{{ creatorProfileObj[i.key].active }}</router-link
                      >
                      <router-link
                        v-if="creatorProfileObj[i.key].inactive"
                        :to="{
                          name:
                            i.key == 'recipeCount'
                              ? routeName.EXPERIENCE_COOKING_ALL
                              : routeName.PRE_ORDER_FOOD_ALL,
                          query: { userId: id, isActive: 0 }
                        }"
                        class="text-hover-decoration-underline text-danger ml-1"
                        >({{ creatorProfileObj[i.key].inactive }})</router-link
                      >
                    </div>
                    <span v-else class="font-size-sm">{{
                      creatorProfileObj[i.key]
                    }}</span>
                  </div>
                  <div
                    v-else-if="i.key == 'documents'"
                    class="document-list-container"
                  >
                    <template
                      v-for="(doc, docIndex) in creatorProfileObj[i.key]"
                    >
                      <b-card :key="docIndex" no-body class="px-6 py-3">
                        <div
                          class="d-flex justify-content-between align-items-center"
                        >
                          <div>
                            <i class="flaticon-attachment mr-4"></i>
                            <span class="mb-0 text-capitalize">{{
                              doc.documentTypeName
                            }}</span>
                          </div>
                          <AppButtonDocumentView
                            :title="doc.documentTypeName"
                            :attachmentUrl="doc.originalUrl"
                            :fileType="doc.fileType"
                          ></AppButtonDocumentView>
                        </div>
                      </b-card>
                    </template>
                  </div>
                  <div v-else-if="i.layout">
                    <component
                      :is="i.layout"
                      :id="Number(id)"
                      :value="creatorProfileObj[i.key]"
                      :config="i.config"
                    ></component>
                  </div>
                  <!-- Creator Status Button :Start-->
                  <div v-else-if="i.key == 'isActive'">
                    <AppStatusLabel
                      :value="creatorProfileObj[i.key]"
                      :modifiable="true"
                      :disabled="
                        !checkAccessRight(actions.readRecord.module, [
                          permissionAction.USER_UPDATE
                        ])
                      "
                      @app-status-label-action-click="statusActionClick"
                    ></AppStatusLabel>
                  </div>
                  <!-- Creator Status Button :End-->
                  <span v-else class="font-size-sm">
                    {{ creatorProfileObj[i.key] }}
                  </span>
                </b-col>
              </b-row>
              <div class="separator separator-solid"></div>
            </div>
          </template>
        </div>
        <!--end: Details List-->

        <!--begin: Creator Address Book Table-->
        <div v-if="group == 'addressBook'">
          <UserAddressBookTable
            ref="userAddressBookTable"
            :id="id"
            :tableHeaders="addressBookTableHeaders"
            :parentActions="addressBookTableActions"
            :descriptions="creatorAddressBookDescriptions"
          ></UserAddressBookTable>
        </div>
        <!--end: Creator Address Book Table-->
      </div>
      <!--end: Group-->
    </template>

    <!-- Modal -->
    <ProfileDetailsPersonalInformModal
      v-model="creatorInformationModalObj.isShow"
      :id="creatorInformationModalObj.id"
      :isEdit="creatorInformationModalObj.isEdit"
      :creatorProfileObj="creatorProfileObj"
    ></ProfileDetailsPersonalInformModal>
  </div>
</template>

<script>
  import userMixin from '@/core/mixins/user.mixin';
  import crudMixin from '@/core/mixins/crud.mixin';
  import commonMixin from '@/core/mixins/common.mixin';
  import AppStatusLabel from '@/views/components/bases/AppStatusLabel';
  import AppButtonDocumentView from '@/views/components/bases/button/AppButtonDocumentView';
  import UserAddressBookTable from '@/views/components/user/UserAddressBookTable';
  import AppEnumLabel from '@/views/components/bases/AppEnumLabel';
  import UserCreatorTaxRateForm from '@/views/components/user/UserCreatorTaxRateForm';
  import ProfileDetailsPersonalInformModal from '@/views/components/profile-details/ProfileDetailsPersonalInformModal';
  import { i18nHelper } from '@/core/utils';
  import {
    APP_COMPONENT_NAME,
    ROUTE_NAME,
    MODULE,
    ACTION
  } from '@/core/constants';
  import { CreatorType } from '@/core/constants/enums';
  import {
    USER_GET_USER_CREATOR_PROFILE,
    USER_GET_CREATOR_ADDRESS_BOOKS,
    USER_GET_CREATOR_ADDRESS_BOOK,
    USER_INITIAL_GET_USER_CREATOR_PROFILE_STATE,
    USER_INITIAL_GET_CREATOR_ADDRESS_BOOKS,
    USER_INITIAL_GET_CREATOR_ADDRESS_BOOK
  } from '@/core/store/user.module';

  export default {
    name: 'UserCreatorProfileDetails',
    components: {
      AppButtonDocumentView,
      UserAddressBookTable,
      AppEnumLabel,
      UserCreatorTaxRateForm,
      AppStatusLabel,
      ProfileDetailsPersonalInformModal
    },
    mixins: [crudMixin, commonMixin, userMixin],
    props: {
      id: {
        required: true
      }
    },
    data: () => ({
      permissionAction: ACTION,
      moduleName: MODULE.USER,
      routeName: ROUTE_NAME,
      actions: {
        readRecord: {
          module: 'user',
          state: 'userCreatorProfile',
          action: USER_GET_USER_CREATOR_PROFILE,
          title: i18nHelper.getMessage('label.getCreatorProfile'),
          successAction: () => {}
        },
        initialReadRecordState: USER_INITIAL_GET_USER_CREATOR_PROFILE_STATE
      },
      creatorProfileObj: {},
      groupsList: [
        'creatorInformation',
        'attachments',
        'accountInformation',
        'addressBook'
      ],
      creatorDetailList: [
        {
          label: 'fullName',
          key: 'creatorName',
          group: 1
        },
        {
          label: 'businessStoreName',
          key: 'storeName',
          group: 1
        },
        {
          label: 'hashtag',
          key: 'hashtag',
          group: 1
        },
        {
          label: 'follower',
          key: 'followerCount',
          group: 1
        },
        {
          label: 'creatorType',
          key: 'creatorType',
          group: 1,
          layout: APP_COMPONENT_NAME.APP_ENUM_LABEL,
          config: {
            size: 'xl',
            enumKey: 'enumCreatorType',
            statusColor: (value) => {
              let color = {
                [CreatorType.INDIVIDUAL]: 'primary',
                [CreatorType.CORPORATE]: 'warning'
              };

              return color[value];
            }
          }
        },
        {
          label: 'serviceType',
          key: 'serviceType',
          group: 1
        },
        {
          label: 'description',
          key: 'description',
          group: 1
        },
        {
          label: 'pinVerification',
          key: 'pinVerification',
          group: 1
        },
        {
          label: 'cuisineType',
          key: 'cuisineType',
          group: 1
        },
        {
          label: 'websiteLink',
          key: 'websiteLink',
          group: 1
        },
        {
          label: 'experienceCooking',
          key: 'recipeCount',
          group: 1
        },
        {
          label: 'preOrderFood',
          key: 'productCount',
          group: 1
        },
        {
          label: 'taxRate%',
          key: 'taxRate',
          group: 1
          // layout: 'UserCreatorTaxRateForm'
        },
        {
          label: 'documents',
          key: 'documents',
          group: 2
        },
        {
          label: 'status',
          key: 'isActive',
          group: 3
        },
        {
          label: 'submittedAt',
          key: 'submittedAt',
          group: 3
        },
        {
          label: 'approvedAt',
          key: 'approvedAt',
          group: 3
        },
        {
          label: 'createdAt',
          key: 'createdAt',
          group: 3
        },
        {
          label: 'updatedAt',
          key: 'updatedAt',
          group: 3
        }
      ],
      userAddressBookDescriptions: [
        {
          label: 'placeName',
          key: 'placeName'
        },
        {
          label: 'contactName',
          key: 'contactName'
        },
        {
          label: 'contactNumber',
          key: 'contactNumber'
        },
        {
          label: 'postcode',
          key: 'postcode'
        },
        {
          label: 'city',
          key: 'city'
        },
        {
          label: 'state',
          key: 'state'
        },
        {
          label: 'address',
          key: 'address'
        },
        {
          label: 'noteToRider',
          key: 'noteToRider'
        },
        {
          label: 'isDefault',
          key: 'isDefault',
          layout: APP_COMPONENT_NAME.APP_CHECK_CIRCLE
        },
        {
          label: 'createdAt',
          key: 'createdAt'
        },
        {
          label: 'updatedAt',
          key: 'updatedAt'
        }
      ],
      addressBookTableActions: {
        readListRecordWithId: {
          module: 'user',
          state: 'creatorAddressBooks',
          action: USER_GET_CREATOR_ADDRESS_BOOKS,
          title: i18nHelper.getMessage('label.getCreatorAddressBooks'),
          successAction: () => {}
        },
        initialReadListRecordWithIdState:
          USER_INITIAL_GET_CREATOR_ADDRESS_BOOKS,
        readRecordWithExtraId: {
          module: 'user',
          state: 'creatorAddressBook',
          action: USER_GET_CREATOR_ADDRESS_BOOK,
          title: i18nHelper.getMessage('label.getCreatorAddressBook'),
          successAction: () => {}
        },
        initialReadRecordWithExtraIdState: USER_INITIAL_GET_CREATOR_ADDRESS_BOOK
      },
      addressBookTableHeaders: [
        {
          key: 'no',
          name: 'no'
        },
        {
          key: 'placeName',
          name: 'placeName',
          sortable: true
        },
        {
          key: 'contactNumber',
          name: 'contactNumber',
          sortable: true
        },
        {
          key: 'contactEmail',
          name: 'email',
          sortable: true
        },
        {
          key: 'address',
          name: 'address',
          sortable: true
        },
        {
          key: 'createdAt',
          name: 'createdAt',
          sortable: true
        },
        {
          key: 'updatedAt',
          name: 'updatedAt',
          sortable: true
        }
        // {
        //   key: 'actions',
        //   name: 'actions',
        //   active: true,
        //   action: true,
        //   layout: APP_COMPONENT_NAME.APP_TABLE_ACTIONS
        // }
      ],
      creatorAddressBookDescriptions: [
        {
          label: 'placeName',
          key: 'placeName'
        },
        {
          label: 'address',
          key: 'address'
        },
        {
          label: 'postcode',
          key: 'postcode'
        },
        {
          label: 'city',
          key: 'city'
        },
        {
          label: 'state',
          key: 'state'
        },
        {
          label: 'businessHours',
          key: 'businessHours',
          layout: APP_COMPONENT_NAME.APP_BUSINESS_HOUR_LIST
        },
        {
          label: 'contactNumber',
          key: 'contactNumber'
        },
        {
          label: 'email',
          key: 'contactEmail'
        },
        {
          label: 'remark',
          key: 'remark'
        },
        {
          label: 'createdAt',
          key: 'createdAt'
        },
        {
          label: 'updatedAt',
          key: 'updatedAt'
        }
      ],
      creatorInformationModalObj: {
        id: 0,
        isShow: false,
        isEdit: true
      }
    }),
    created() {
      this.initUserCreatorProfileDetails();
    },
    methods: {
      importParentHeaderImages(response) {
        let data = response.data;

        this.$parent.$parent.$parent.importHeaderImages(
          data.creatorPhotoUrl,
          data.creatorBackgroundUrl
        );
      },
      getCreatorProfile() {
        this.readRecord(this.id);
        this.$refs.userAddressBookTable[0].getAddressBooks();
      },
      importData(response) {
        this.creatorProfileObj = response.data;
      },
      importSuccessAction() {
        this.actions.readRecord.successAction = (response) => {
          this.importData(response);
          this.importParentHeaderImages(response);
        };
      },
      initUserCreatorProfileDetails() {
        this.importSuccessAction();
      },
      statusActionClick(value) {
        this.openRequestUpdateUserActiveStatusModal(this.id, value, 'creator');
      },
      actionEditPersonalInform() {
        this.creatorInformationModalObj = {
          id: this.id,
          isShow: true,
          isEdit: true
        };
      }
    }
  };
</script>

<style lang="scss">
  .document-list-container {
    .card {
      border-radius: 0 !important;
      border-bottom: 0;
      &:first-child {
        border-radius: 0.42rem 0.42rem 0 0 !important;
      }
      &:last-child {
        border-radius: 0 0 0.42rem 0.42rem !important;
        border-bottom: 1px solid #ebedf3;
      }
    }
  }
</style>
