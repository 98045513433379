<template>
  <b-row v-if="editable" no-gutters class="mb-3">
    <b-col cols="8">
      <AppFormField
        v-model="$v.email.$model"
        :state="validateState('email')"
        :validationValue="$v.email"
        :validators="validators"
        :disabled="disabled"
        noTitle
        prepend
      >
      </AppFormField>
      <div style="display: none">
        <!-- WARNING: I have no idea why this line is here, but removing it breaks everything -->
        {{ $v }}
      </div>
    </b-col>
    <b-col cols="4">
      <i
        v-if="disabled"
        class="fa la-edit icon-xl ml-4 pt-2 cursor-pointer"
        @click="disabled = false"
      ></i>
      <div v-else class="ml-4">
        <i class="fa la-times icon-xl pt-2 cursor-pointer" @click="onReset"></i>
        <i
          class="fa la-save icon-xl ml-4 pt-2 cursor-pointer"
          @click="onSave"
        ></i>
        <i
          class="fa la-trash icon-xl ml-4 pt-2 cursor-pointer"
          @click="onDelete"
        ></i>
      </div>
    </b-col>
  </b-row>
</template>

<script>
  import AppFormField from '@/views/components/bases/form-field/AppFormField';
  import commonMixin from '@/core/mixins/common.mixin';
  import { validationMixin } from 'vuelidate';
  import { required, email } from 'vuelidate/lib/validators';
  import { MODULE, ACTION } from '@/core/constants';

  export default {
    name: 'ConfigEmailFormField',
    mixins: [commonMixin, validationMixin],
    components: {
      AppFormField
    },
    props: {
      item: {
        type: Object,
        default: () => ({})
      },
      value: {
        type: [Number, String],
        default: null
      },
      editable: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      module: MODULE,
      action: ACTION,
      email: null,
      disabled: true,
      validators: ['required', 'email']
    }),
    watch: {
      value() {
        this.email = this.value;
      }
    },
    mounted() {
      this.email = this.value;
    },
    methods: {
      onSave() {
        let data = {
          id: this.item.id,
          email: this.email
        };
        this.$emit('save', data);
        this.email = null;
        this.disabled = true;
        this.$v.$reset();
      },
      onDelete() {
        let data = {
          id: this.item.id
        };
        this.$emit('delete', data);
        this.email = null;
        this.disabled = true;
        this.$v.$reset();
      },
      onReset() {
        this.email = this.value;
        this.disabled = true;
        this.$v.$reset();
      }
    },
    validations: {
      email: {
        required,
        email
      }
    }
  };
</script>

<style lang="scss"></style>
