<template>
  <div>
    <b-row no-gutters>
      <b-col cols="6">
        <div class="wizard-label">
          <h3 class="wizard-title text-capitalize">
            {{ $t(`label.googleCalendarApi`) }}
          </h3>
          <div class="wizard-desc">
            Updated At {{ calendarApiObj.updatedAt }}
          </div>
        </div>
      </b-col>
      <b-col cols="6">
        <b-button @click="getCalendarRefresh">{{
          $t(`label.refresh`)
        }}</b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import commonMixin from '@/core/mixins/common.mixin';
  import { i18nHelper } from '@/core/utils';
  import { MODULE, ACTION } from '@/core/constants';
  import {
    GENERAL_GET_CALENDAR_API,
    GENERAL_INITIAL_GET_CALENDAR_API_STATE,
    GENERAL_GET_CALENDAR_REFRESH,
    GENERAL_INITIAL_GET_CALENDAR_REFRESH_STATE
  } from '@/core/store/configuration/config-google-calender.module';

  export default {
    name: 'ConfigGoogleCalendar',
    mixins: [commonMixin],
    data: () => ({
      accessRights: {
        moduleName: MODULE.CONFIGURATION,
        actions: {
          create: ACTION.CONFIGURATION_CREATE,
          read: ACTION.CONFIGURATION_READ,
          update: ACTION.CONFIGURATION_UPDATE,
          delete: ACTION.CONFIGURATION_DELETE
        }
      },
      calendarApiObj: [],
      filters: []
    }),
    computed: {
      calendarApiComplete() {
        return this.$store.state.configGoogleCalender.calendarApi.complete;
      }
    },
    watch: {
      calendarApiComplete() {
        let response = this.$store.state.configGoogleCalender.calendarApi;
        let title = i18nHelper.getMessage('label.googleCalendarApi');
        let initialStateAction = GENERAL_INITIAL_GET_CALENDAR_API_STATE;
        let successAction = (response) => {
          this.importCanlendarApiObj(response);
        };
        if (response.complete) {
          this.actionReadRecordComplete(
            response,
            title,
            initialStateAction,
            successAction
          );
        }
      }
    },
    mounted() {
      this.initialConfiguration();
    },
    methods: {
      importCanlendarApiObj(response) {
        this.calendarApiObj = response.data[1];
      },
      getCalendarApi() {
        this.$store.dispatch(GENERAL_GET_CALENDAR_API);
      },
      getCalendarRefresh() {
        this.$store.dispatch(GENERAL_GET_CALENDAR_REFRESH, {});
        this.initialGetCalendarRefresh();
      },
      initialGetCalendarRefresh() {
        this.$store.dispatch(GENERAL_INITIAL_GET_CALENDAR_REFRESH_STATE, {});
        this.getCalendarApi();
      },
      initialConfiguration() {
        let breadcrumbData = {
          breadcrumbs: [
            {
              title: i18nHelper.getMessage('label.configuration')
            }
          ]
        };
        this.getCalendarApi();
        this.initBreadCrumb(breadcrumbData);
      }
    }
  };
</script>

<style></style>
