<template>
  <div id="app">
    <!-- 
      //        .==.        .==.          
      //       //`^\\      //^`\\         
      //      // ^ ^\(\__/)/^ ^^\\        
      //     //^ ^^ ^/6  6\ ^^ ^ \\       
      //    //^ ^^ ^/( .. )\^ ^ ^ \\      
      //   // ^^ ^/\| v""v |/\^ ^ ^\\     
      //  // ^^/\/ /  `~~`  \ \/\^ ^\\    
      //  -----------------------------
      /// HERE BE DRAGONS
      /// Dear future me, I am so sorry for what I'm about to write
      /// /**
        _____ ___  ____   ___                     _   _   _     _       _                              
      |_   _/ _ \|  _ \ / _ \ _    ___ _ __   __| | | |_| |__ (_)___  | |_   _ _ __   __ _  ___ _   _ 
        | || | | | | | | | | (_)  / _ \ '_ \ / _` | | __| '_ \| / __| | | | | | '_ \ / _` |/ __| | | |
        | || |_| | |_| | |_| |_  |  __/ | | | (_| | | |_| | | | \__ \ | | |_| | | | | (_| | (__| |_| |
        |_| \___/|____/ \___/(_)  \___|_| |_|\__,_|  \__|_| |_|_|___/ |_|\__,_|_| |_|\__,_|\___|\__, |
                                                                                                |___/ 
*/
     -->
    <component ref="layout" :is="layout">
      <router-view />
    </component>
  </div>
</template>

<style lang="scss">
  // 3rd party plugins css
  // @import '~bootstrap-vue/dist/bootstrap-vue.css';
  @import '~perfect-scrollbar/css/perfect-scrollbar.css';
  @import '~socicon/css/socicon.css';
  @import '~animate.css';
  @import '~@fortawesome/fontawesome-free/css/all.css';
  @import '~line-awesome/dist/line-awesome/css/line-awesome.css';
  @import 'assets/plugins/flaticon/flaticon.css';
  @import 'assets/plugins/flaticon2/flaticon.css';
  @import 'assets/plugins/keenthemes-icons/font/ki.css';

  // Main demo style scss
  @import 'assets/sass/style.vue';

  // Check documentation for RTL css
  // Update HTML with RTL attribute at public/index.html
  /*@import "assets/css/style.vue.rtl";*/
</style>

<script>
  import { GLOBAL } from '@/core/constants';
  import { i18nHelper } from '@/core/utils';
  import { OVERRIDE_LAYOUT_CONFIG } from '@/core/store/config.module';
  import Layout from '@/views/layout/Layout.vue';
  import LayoutBlank from '@/views/layout/LayoutBlank.vue';

  export default {
    name: 'App',
    metaInfo: {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: i18nHelper.getMessage('title.projectName'),
      // all titles will be injected into this template
      titleTemplate: `%s | ${i18nHelper.getMessage('title.projectName')}`
    },
    components: {
      Layout,
      LayoutBlank
    },
    data: () => ({
      infoModal: {
        show: false,
        title: '',
        messages: [],
        buttons: []
      }
    }),
    computed: {
      layout() {
        return this.$route.meta.layout || GLOBAL.LAYOUT_BLANK;
      }
    },
    mounted() {
      /**
       * this is to override the layout config using saved data from localStorage
       * remove this to use config only from static json (@/core/config/layout.config.json)
       */
      this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG);
    }
  };
</script>

<style lang="scss">
  #app {
    height: 100%;
    width: 100%;
  }
</style>
