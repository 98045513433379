import Vue from 'vue';
import * as VueGoogleMaps from 'vue2-google-maps';

Vue.config.productionTip = false;

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAP660371o3E7UQJhZFmKWVUWxVvy7WTz4',
    libraries: 'places'
  }
});
