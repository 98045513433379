import { apiService } from '@/core/services';

export const RECIPE_PAGE = {
  getRecipePages,
  getRecipePageSearchs,
  getRecipePageSearch,
  getRecipePage,
  createRecipePage,
  updateRecipePage,
  deleteRecipePage
};

const URL = {
  ADMIN: 'admin',
  SEARCH: 'search',
  RECIPES: 'recipes',
  RECIPE_PAGES: 'recipe-pages'
};

function getRecipePages(data) {
  let requestOptions = {
    params: {
      id: data.id,
      name: data.recipeName,
      recipe_id: data.recipeId,
      category: data.category,
      status: data.status,
      recipe_status: data.recipeStatus,
      per_page: data.perPage,
      page: data.page
    }
  };
  return apiService().get(`/${URL.ADMIN}/${URL.RECIPE_PAGES}`, requestOptions);
}

function getRecipePageSearchs(data) {
  let requestOptions = {
    params: {
      id: data.id,
      name: data.name,
      // category: data.category,
      per_page: data.perPage,
      page: data.page
    }
  };
  return apiService().get(
    `/${URL.ADMIN}/${URL.SEARCH}/${URL.RECIPES}`,
    requestOptions
  );
}

function getRecipePageSearch(data) {
  let requestOptions = {
    params: {
      id: data.id,
      edit: 1
    }
  };
  return apiService().get(
    `/${URL.ADMIN}/${URL.SEARCH}/${URL.RECIPES}`,
    requestOptions
  );
}

function getRecipePage(id) {
  return apiService().get(`/${URL.ADMIN}/${URL.RECIPE_PAGES}/${id}`);
}

function createRecipePage(data) {
  let requestBody = {
    category: data.category,
    recipe_id: data.recipeId,
    name: data.recipeName,
    media_asset_id: data.topAssets,
    is_top: data.topRecipe,
    priority: data.priority
  };
  return apiService().post(`/${URL.ADMIN}/${URL.RECIPE_PAGES}`, requestBody);
}

function updateRecipePage(id, data) {
  let requestBody = {
    media_asset_id: data.topAssets,
    is_top: data.topRecipe,
    priority: data.priority
  };
  return apiService().put(
    `/${URL.ADMIN}/${URL.RECIPE_PAGES}/${id}`,
    requestBody
  );
}

function deleteRecipePage(id) {
  return apiService().delete(`/${URL.ADMIN}/${URL.RECIPE_PAGES}/${id}`);
}
