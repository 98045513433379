<template>
  <b-button
    size="sm"
    :variant="`light-${value ? 'success' : 'success'}`"
    :class="{ 'cursor-default': disabled }"
    class="d-block text-capitalize"
    @click="clickedApi(value)"
    >{{ config.name }}</b-button
  >
</template>

<script>
  import commonMixin from '@/core/mixins/common.mixin';
  import { i18nHelper } from '@/core/utils';

  export default {
    name: 'RecipeEditStatusButton',
    mixins: [commonMixin],
    props: {
      value: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      id: {
        type: Number,
        default: null
      },
      config: {
        type: Object,
        default: () => ({
          module: '',
          state: '',
          actionSet: '',
          actionInitial: '',
          successAction: () => {},
          title: '',
          message: ''
        })
      },
      stateObj: {
        Type: Object,
        default: () => {}
      }
    },
    computed: {
      setVisibilityComplete() {
        let obj = this.config;

        return this.$store.state[obj.module][obj.state].complete;
      }
    },
    watch: {
      setVisibilityComplete() {
        let obj = this.config;
        let response = this.$store.state[obj.module][obj.state];
        let title = obj.title;
        let initialStateAction = obj.actionInitial;
        let successAction = obj.successAction;
        let errorAction = obj.errorAction;
        successAction = () => {
          this.$parent.getRecipe(this.id);
        };
        if (response.complete) {
          this.actionUpdateRecordComplete(
            response,
            title,
            initialStateAction,
            successAction,
            errorAction
          );
        }
      }
    },
    methods: {
      clickedApi(value) {
        this.openRequestSetApiModal(value);
      },
      openRequestSetApiModal(status) {
        let actionObj = this.config;
        let title = actionObj.title;
        let messages = [];
        let buttons = [];

        messages.push(actionObj.message);

        buttons.push(
          {
            text: i18nHelper.getMessage('label.cancel'),
            variant: '',
            action: () => {
              this.closeLayoutAppModalInfo();
            }
          },
          {
            text: i18nHelper.getMessage(`label.confirm`),
            isText: false,
            variant: status ? 'success' : 'danger',
            action: () => {
              this.closeLayoutAppModalInfo();
              this.callApi(this.id);
            }
          }
        );

        this.openLayoutAppModalInfo(title, messages, buttons);
      },
      callApi(id) {
        this.$store.dispatch(this.config.actionSet, { id });
      }
    }
  };
</script>

<style></style>
