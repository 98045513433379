<template>
  <b-row class="pt-3">
    <b-col cols="12" sm="3" class="text-md-right">
      <label v-if="!noTitle" class="text-capitalize">{{ title }}</label>
    </b-col>
    <b-col cols="12" sm="9">
      <slot />
    </b-col>
  </b-row>
</template>

<script>
  export default {
    name: 'AppField1',
    props: {
      noTitle: {
        type: Boolean,
        default: false
      },

      title: {
        type: String,
        default: ''
      }
    }
  };
</script>

<style></style>
