<template>
  <div>
    <AppSmartList
      :breadcrumbData="breadcrumbData"
      :title="$t(`enumBannerType.${type}`)"
      :accessRights="accessRights"
      :filters="filters"
      :actions="actions"
      :tableHeaders="headers"
      :newRecord="newRecord"
    ></AppSmartList>
  </div>
</template>

<script>
  import AppSmartList from '@/views/components/bases/smart/AppSmartList.vue';
  import {
    ROUTE_NAME,
    MODULE,
    ACTION,
    APP_COMPONENT_NAME,
    SMART_FORM_FIELD_TYPE
  } from '@/core/constants';
  import { i18nHelper, validationHelper } from '@/core/utils';
  import {
    BANNER_GET_BANNERS,
    BANNER_INITIAL_GET_BANNERS_STATE
  } from '@/core/store/banner.module';
  import { listService } from '@/core/services';
  import bannerMixin from '@/core/mixins/banner.mixin';
  import constantsMixin from '@/core/mixins/constants.mixin';
  import { BannerType } from '@/core/constants/enums';

  export default {
    name: 'BannerList',
    components: {
      AppSmartList
    },
    mixins: [bannerMixin, constantsMixin],
    props: {
      type: {
        type: Number
      }
    },
    data: () => ({
      breadCrumbs: [],
      breadcrumbData: {},
      accessRights: {
        moduleName: MODULE.BANNER,
        actions: {
          create: ACTION.BANNER_CREATE,
          read: ACTION.BANNER_READ,
          update: ACTION.BANNER_UPDATE,
          delete: ACTION.BANNER_DELETE
        }
      },
      filters: [],
      actions: {
        viewList: {
          module: 'banner',
          state: 'banners',
          action: BANNER_GET_BANNERS,
          initialStateAction: BANNER_INITIAL_GET_BANNERS_STATE,
          title: i18nHelper.getMessage('label.getBannersList')
        }
      },
      headers: [
        {
          key: 'no',
          name: 'no',
          active: true,
          sortable: true
        },
        {
          key: 'title',
          name: 'title',
          active: true,
          sortable: true,
          class: 'min-w-100px'
        },
        {
          key: 'description',
          name: 'description',
          active: true,
          class: 'min-w-100px'
        },
        {
          key: 'priority',
          name: 'priority',
          active: true,
          sortable: true
        },
        {
          key: 'isActive',
          name: 'status',
          active: true,
          sortable: true,
          layout: APP_COMPONENT_NAME.APP_STATUS_LABEL
        },
        {
          key: 'contentLabel',
          name: 'contentLabel',
          active: true
        },
        {
          key: 'tagLabel',
          name: 'tagLabel',
          active: true
        },
        {
          key: 'mediaType',
          name: 'mediaType',
          active: true,
          sortable: true
        },
        {
          key: 'actionType',
          name: 'actionType',
          active: true,
          sortable: true
        },
        {
          key: 'createdAt',
          name: 'createdAt',
          active: true,
          sortable: true
        },
        {
          key: 'updatedAt',
          name: 'updatedAt',
          active: true,
          sortable: true
        },
        {
          key: 'actions',
          name: 'actions',
          active: true,
          action: true,
          layout: APP_COMPONENT_NAME.APP_TABLE_ACTIONS,
          layoutActions: {
            viewDetailsRouteName: ROUTE_NAME.BANNER_HOME_DETAILS
          }
        }
      ],
      newRecord: {
        show: true,
        buttonTitle: i18nHelper.getMessage('label.newBanner'),
        routeName: ROUTE_NAME.BANNER_HOME_NEW
      }
    }),
    created() {
      this.initBannerList();
    },
    methods: {
      getBannerDetailsRouteName() {
        let routeName = {
          [BannerType.HOME]: ROUTE_NAME.BANNER_HOME_DETAILS,
          [BannerType.DISCOVER]: ROUTE_NAME.BANNER_DISCOVER_DETAILS,
          [BannerType.X_STREET]: ROUTE_NAME.BANNER_X_STREET_DETAILS
        };

        return routeName[this.type];
      },
      getBannerNewRouteName() {
        let routeName = {
          [BannerType.HOME]: ROUTE_NAME.BANNER_HOME_NEW,
          [BannerType.DISCOVER]: ROUTE_NAME.BANNER_DISCOVER_NEW,
          [BannerType.X_STREET]: ROUTE_NAME.BANNER_X_STREET_NEW
        };

        return routeName[this.type];
      },
      initBannerList() {
        let breadcrumbData = {
          breadcrumbs: [
            {
              title: i18nHelper.getMessage('label.banners'),
              route: { name: ROUTE_NAME.BANNER }
            },
            {
              title: i18nHelper.getMessage(`enumBannerType.${this.type}`),
              route: { name: this.getBannerRouteName(this.type) }
            },
            {
              title: i18nHelper.getMessage(
                `label.${i18nHelper
                  .getMessage(`enumBannerType.${this.type}`)
                  .toLowerCase()}BannerList`
              )
            }
          ]
        };

        this.breadcrumbData = breadcrumbData;

        this.filters.push(
          {
            label: 'type',
            key: 'type',
            value: this.type,
            isHidden: true
          },
          {
            label: 'title',
            key: 'title',
            value: ''
          },
          {
            label: 'priority',
            key: 'priority',
            value: '',
            isOptional: true,
            rules: (value) => {
              return validationHelper.rulesNumeric(value);
            }
          },
          {
            label: 'sortPriority',
            key: 'sortPriority',
            value: '',
            options: listService.getSortOrderList(),
            optionLabel: 'text',
            type: SMART_FORM_FIELD_TYPE.SELECT
          },
          {
            label: 'status',
            key: 'isActive',
            value: '',
            options: listService.getStatusList(),
            optionLabel: 'text',
            type: SMART_FORM_FIELD_TYPE.SELECT
          },
          {
            label: 'mediaType',
            key: 'mediaType',
            value: '',
            options: listService.getDynamicList(
              this.constantsData.bannerMediaType
            ),
            optionLabel: 'text',
            type: SMART_FORM_FIELD_TYPE.SELECT
          },
          {
            label: 'actionType',
            key: 'actionType',
            value: '',
            options: listService.getDynamicList(
              this.constantsData.bannerAction
            ),
            optionLabel: 'text',
            type: SMART_FORM_FIELD_TYPE.SELECT
          }
        );

        this.newRecord.routeName = this.getBannerNewRouteName();

        this.headers.find(
          (x) => x.key == 'actions'
        ).layoutActions.viewDetailsRouteName = this.getBannerDetailsRouteName();
      }
    }
  };
</script>

<style></style>
