<template>
  <div>
    <!-- {{ item }} -->
    <b-row v-if="editable" no-gutters class="d-flex">
      <b-col cols="7">
        <AppFormField
          v-model="$v.referralCode.$model"
          @input="
            (v) => {
              referralCode = v.toUpperCase();
            }
          "
          :state="validateState('referralCode')"
          :validationValue="$v.referralCode"
          :validators="validators"
          :validationParams="validationParams"
          :disabled="!disabled"
          noTitle
        >
        </AppFormField>
        <div style="display: none">
          <!-- WARNING: I have no idea why this line is here, but removing it breaks everything -->
          {{ $v }}
        </div>
      </b-col>

      <b-col cols="5">
        <div class="ml-4">
          <i
            class="fa la-times icon-xl pt-2 cursor-pointer"
            @click="onReset"
          ></i>
          <i
            class="fa la-save icon-xl ml-4 pt-2 cursor-pointer"
            @click="onSave"
          ></i>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import AppFormField from '@/views/components/bases/form-field/AppFormField';
  import commonMixin from '@/core/mixins/common.mixin';
  import { validationMixin } from 'vuelidate';
  import { required, minLength, maxLength } from 'vuelidate/lib/validators';
  import { MODULE, ACTION, CURRENCY } from '@/core/constants';

  export default {
    name: 'ConfigNotifyCreatorNumberFormField',
    mixins: [commonMixin, validationMixin],
    components: {
      AppFormField
    },
    props: {
      item: {
        type: Object,
        default: () => ({})
      },
      value: {
        type: [String],
        default: null
      },
      editable: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      module: MODULE,
      action: ACTION,
      currency: CURRENCY,
      referralCode: null,
      disabled: true,
      // validators: ['required', 'mobileNumber']
      validators: ['required', 'minLength', 'maxLength'],
      validationParams: [
        {
          key: 'minLength',
          params: [5]
        },
        {
          key: 'maxLength',
          params: [20]
        }
      ]
    }),
    watch: {
      value() {
        this.referralCode = this.value.toUpperCase();
      }
    },
    mounted() {
      this.referralCode = this.value.toUpperCase();
    },
    methods: {
      onSave() {
        let data = {
          id: this.item.id,
          referral_code: this.referralCode
        };
        this.$emit('save', data);
        this.$emit('disable', false);
        this.referralCode = null;
        this.disabled = true;
        this.$v.$reset();
      },
      onReset() {
        this.referralCode = this.value.toUpperCase();
        this.$emit('disable', false);
        this.disabled = true;
        this.$v.$reset();
      }
    },
    validations: {
      referralCode: {
        required,
        minLength: minLength(5),
        maxLength: maxLength(20)
      }
    }
  };
</script>

<style lang="scss"></style>
