<template>
  <div>
    <b-modal
      v-model="isShow"
      centered
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      header-class="text-capitalize"
      body-class="max-h-600px"
      size="lg"
    >
      <template #modal-header>
        <!-- Emulate built in modal header close button action -->
        <div class="w-100 d-flex justify-content-between align-items-center">
          <h3>
            {{ $t('label.addNewReferee') }}
          </h3>
        </div>
      </template>
      <template v-for="(i, index) in formFields">
        <div :key="index">
          <AppFormField
            v-model="$v.refereeObj[i.model].$model"
            :state="validateObjState('refereeObj', i.model)"
            :validationValue="$v.refereeObj[i.model]"
            :validationParams="i.validationParams"
            :title="$t(`label.${i.title}`)"
            :options="i.options"
            :validators="i.validators"
            :formType="i.formType"
            :isRequires="i.isRequires"
            :prepend="i.prepend"
            :disabled="i.disabled"
            :solid="!i.solid"
            :placeholder="i.placeholder"
            :config="i.config"
            @change="i.action"
            @searchChange="i.searchChange"
          >
            <template v-slot:prepend>
              <span class="input-group-text text-uppercase">
                <i class="fas fa-search"></i>
              </span>
            </template>
          </AppFormField>
          <div style="display: none">
            <!-- WARNING: I have no idea why this line is here, but removing it breaks everything -->
            {{ $v }}
          </div>
        </div>
      </template>
      <template #modal-footer>
        <template
          v-if="checkAccessRight(moduleName, [action.REFERRAL_CODE_CREATE])"
        >
          <b-button class="mr-2" variant="white" @click="actionDiscard">{{
            $t('label.discard')
          }}</b-button>
          <b-button variant="primary" @click="actionSaveChanges">{{
            $t('label.save')
          }}</b-button>
        </template>
        <b-button v-else variant="primary" @click="actionDiscard">{{
          $t('label.close')
        }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
  import AppFormField from '@/views/components/bases/form-field/AppFormField';
  import commonMixin from '@/core/mixins/common.mixin';
  import { MODULE, ACTION } from '@/core/constants';
  import { validationMixin } from 'vuelidate';
  import { required } from 'vuelidate/lib/validators';
  import { i18nHelper, uiHelper } from '@/core/utils';
  import { listApiService } from '@/core/services';
  import {
    REFERRAL_CODE_CREATE_REFERAL_CODE_REFEREE,
    REFERRAL_CODE_INITIAL_CREATE_REFERAL_CODE_REFEREE_STATE
  } from '@/core/store/referral-code.module';

  export default {
    name: 'RefereeCreateModal',
    mixins: [commonMixin, validationMixin],
    components: {
      AppFormField
    },
    props: {
      id: {},
      value: {
        type: Boolean,
        default: false,
        required: true
      },
      isShow: {
        type: Boolean,
        default: false,
        required: true
      }
    },
    data: () => ({
      moduleName: MODULE.REFERRAL_CODE,
      action: ACTION,
      modal: false,
      refereeObj: {
        username: null,
        contactNumber: null
      },
      userObj: {},
      formFields: [],
      userContactNumber: null
    }),
    mounted() {},
    computed: {
      createReferralCodeRefereeComplete() {
        return this.$store.state.referralCode.createReferralCodeReferee
          .complete;
      }
    },
    watch: {
      value() {
        this.initFormFields();
      },
      modal() {
        this.modal
          ? uiHelper.freezeBodyOverflowY()
          : uiHelper.unFreezeBodyOverflowY();
      },
      createReferralCodeRefereeComplete() {
        let response = this.$store.state.referralCode.createReferralCodeReferee;
        if (response.complete) {
          this.referralCodeRefereeCompleteAction(response, 'update');
        }
      }
    },
    methods: {
      referralCodeRefereeCompleteAction(response, actionType) {
        let title = i18nHelper.getMessage('label.addNewReferee');
        let messages = response.message;
        let buttons = [];
        switch (actionType) {
          case 'update':
            this.initialCreateReferralCodeRefereeState();
            break;
        }
        if (response.code == 0) {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              switch (actionType) {
                case 'update':
                  this.$parent.getReferralCodeReferees(
                    this.id,
                    this.$parent.searchCriteriaReferralCodeReferees
                  );
                  this.$parent.$parent.initialReferralCode();
                  break;
              }
              this.actionCloseModal();
              this.closeLayoutAppModalInfo();
            }
          });
        } else {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();
            }
          });
        }
        if (messages) this.openLayoutAppModalInfo(title, messages, buttons);
      },
      actionDiscard() {
        this.actionCloseModal();
      },
      actionSaveChanges() {
        if (this.validationCheck('refereeObj')) {
          this.openActionRequestModal(this.userObj);
        }
      },
      resetForm() {
        this.$v.$reset();
        this.refereeObj = {
          username: null,
          contactNumber: null
        };
      },
      actionCloseModal() {
        this.resetForm();
        this.$emit('input', false);
      },
      async initFormFields() {
        this.formFields = [
          {
            model: 'contactNumber',
            title: 'contactNumber',
            validators: ['required'],
            formType: 'searchSelect',
            options: await listApiService.getUserContactNumber(
              this.userContactNumber
            ),
            placeholder: 'Type to search for contact number',
            isRequires: true,
            prepend: true,
            action: (val) => {
              if (val) {
                let value = JSON.parse(val);
                this.refereeObj.username = value.name;
                this.userObj = value;
              } else {
                this.refereeObj.username = '';
              }
            },
            searchChange: (val) => {
              this.userContactNumber = val;
              this.initFormFields();
            }
          },
          {
            model: 'username',
            title: 'username',
            validators: ['required'],
            isRequires: true,
            solid: true,
            disabled: true,
            action: () => {},
            searchChange: () => {}
          }
        ];
      },
      openActionRequestModal(data) {
        let title = i18nHelper.getMessage(`label.addNewReferee`);
        let messages = [];
        let buttons = [];
        let code = [];
        let textbox = '';
        let dropbox = [];
        messages.push(
          i18nHelper.getMessage('message.requestAddRefereeMessage', [data.name])
        );

        buttons.push({
          text: i18nHelper.getMessage('label.cancel'),
          variant: '',
          action: () => {
            this.closeLayoutAppModalInfo();
          }
        });

        buttons.push({
          text: i18nHelper.getMessage('label.confirm'),
          variant: 'success',
          action: () => {
            this.closeLayoutAppModalInfo();
            this.createReferralCodeReferee(data);
          }
        });

        this.openLayoutAppModalInfo(
          title,
          messages,
          buttons,
          code,
          textbox,
          dropbox
        );
      },
      createReferralCodeReferee(data) {
        let id = this.id;
        this.$store.dispatch(REFERRAL_CODE_CREATE_REFERAL_CODE_REFEREE, {
          id,
          data
        });
      },
      initialCreateReferralCodeRefereeState() {
        this.$store.dispatch(
          REFERRAL_CODE_INITIAL_CREATE_REFERAL_CODE_REFEREE_STATE,
          {}
        );
      }
    },
    validations: {
      refereeObj: {
        contactNumber: {
          required
        },
        username: {
          required
        }
      }
    }
  };
</script>

<style lang="scss">
  .referral-code {
    .form-group {
      margin: unset !important;
    }
  }
</style>
