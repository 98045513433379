<template>
  <div>
    <AppSmartList
      ref="app-smart-list"
      :breadcrumbData="breadcrumbData"
      :accessRights="accessRights"
      :filters="filters"
      :actions="actions"
      :tableHeaders="headers"
    >
      <template v-slot:buttons>
        <AppExportExcel :exportExcelObj="exportExcel"></AppExportExcel>
      </template>
    </AppSmartList>
  </div>
</template>

<script>
  import AppSmartList from '@/views/components/bases/smart/AppSmartList.vue';
  import AppExportExcel from '@/views/components/bases/AppExportExcel.vue';
  import { i18nHelper } from '@/core/utils';
  import {
    ROUTE_NAME,
    MODULE,
    ACTION
    // SMART_FORM_FIELD_TYPE
  } from '@/core/constants';
  import {
    SUBSCRIPTION_GET_SUBSCRIPTIONS,
    SUBSCRIPTION_INITIAL_SUBSCRIPTIONS_STATE
  } from '@/core/store/subscription.module';

  export default {
    name: 'SubscriptionList',
    components: {
      AppSmartList,
      AppExportExcel
    },
    data: () => ({
      breadcrumbData: {
        breadcrumbs: [
          {
            title: i18nHelper.getMessage('label.subscription'),
            route: { name: ROUTE_NAME.SUBSCRIPTION }
          },
          { title: i18nHelper.getMessage('label.subscriptionList') }
        ]
      },
      accessRights: {
        moduleName: MODULE.ORDERS,
        actions: {
          read: ACTION.ORDERS_READ,
          update: ACTION.ORDERS_UPDATE
        }
      },
      filters: [
        // {
        //   label: 'email',
        //   key: 'email',
        //   value: ''
        // }
        // {
        //   label: 'subscriptionAt',
        //   key: 'subscriptionAt',
        //   value: '',
        //   type: SMART_FORM_FIELD_TYPE.DATE_RANGE
        // }
      ],
      actions: {
        viewList: {
          module: 'subscription',
          state: 'subscriptions',
          action: SUBSCRIPTION_GET_SUBSCRIPTIONS,
          initialStateAction: SUBSCRIPTION_INITIAL_SUBSCRIPTIONS_STATE,
          title: i18nHelper.getMessage('label.getSubscription')
        }
      },
      headers: [
        {
          key: 'no',
          name: 'no',
          active: true,
          sortable: true
        },
        {
          key: 'email',
          name: 'email',
          active: true,
          sortable: true,
          class: 'py-2'
        },
        {
          key: 'subscriptionAt',
          name: 'subscriptionAt',
          active: true,
          sortable: true
        }
      ],
      exportExcel: {
        disabled: false,
        fileName: 'subscribers_list',
        api: 'admin/email-subscriptions/export'
      }
    }),
    computed: {},
    watch: {},
    methods: {}
  };
</script>

<style></style>
