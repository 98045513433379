<template>
  <div>
    <h5 class="mb-10 font-weight-bold text-dark text-capitalize">
      {{ $t('label.privacyPolicy') }}
    </h5>
    <b-form class="mb-4">
      <b-row>
        <b-col xl="6" class="p-xl-1 py-0">
          <template v-if="platformInfoObj.privacyPolicy">
            <PlatformInfoDocumentPlaceholder
              :attachmentUrl="platformInfoObj.privacyPolicy"
              :hasReplace="
                checkAccessRight(moduleName, [action.PLATFORM_INFO_UPDATE])
              "
              @replace-action="replaceFAQ"
            ></PlatformInfoDocumentPlaceholder>
          </template>
          <AppFormField
            v-else-if="
              checkAccessRight(moduleName, [action.PLATFORM_INFO_UPDATE])
            "
            v-model="$v.newPlatformInfoObj.privacyPolicy.$model"
            formType="file"
            :state="validateObjState('newPlatformInfoObj', 'privacyPolicy')"
            :validationValue="$v.newPlatformInfoObj.privacyPolicy"
            :solid="true"
            :validators="['required', 'platformInfoApplicationType']"
            :noTitle="true"
            :validationParams="validationParams"
            :accept="global.AVAILABLE_PLATFORM_INFO_APPLICATION_TYPE.toString()"
            :fileUploadButton="true"
            :isUploading="isUploadingPlatformInfoObj.privacyPolicy"
            @upload-file="uploadFileAction()"
            class="w-100"
          ></AppFormField>
          <div style="display: none">
            <!-- WARNING: I have no idea why this line is here, but removing it breaks everything -->
            {{ $v }}
          </div>
        </b-col>
      </b-row>

      <div class="text-warning mt-5">
        <small
          v-if="
            !platformInfoObj.termsCondition &&
            checkAccessRight(moduleName, [action.PLATFORM_INFO_UPDATE])
          "
        >
          {{ $t('label.onlyPDFFilesAreAllowed') }}</small
        >
      </div>
    </b-form>

    <div v-if="!platformInfoObj.privacyPolicy">
      <div
        v-if="checkAccessRight(moduleName, [action.PLATFORM_INFO_UPDATE])"
        class="d-flex justify-content-end border-top pt-10"
      >
        <b-button
          variant="light-primary"
          class="font-weight-bold text-uppercase px-9 py-4 mr-4"
          @click="resetForm"
        >
          {{ $t('label.reset') }}
        </b-button>
      </div>
      <span v-else-if="!platformInfoObj.privacyPolicy">{{
        $t('label.thereAreNoDocumentsToShow')
      }}</span>
    </div>
  </div>
</template>

<script>
  import AppFormField from '@/views/components/bases/form-field/AppFormField';
  import PlatformInfoDocumentPlaceholder from '@/views/components/platform-info/PlatformInfoDocumentPlaceholder';
  import commonMixin from '@/core/mixins/common.mixin';
  import constantsMixin from '@/core/mixins/constants.mixin';
  import { validationMixin } from 'vuelidate';
  import { PLATFORM_FILE_TYPE } from '@/core/constants/enums';
  import { required } from 'vuelidate/lib/validators';
  import {
    GLOBAL,
    MODULE,
    ACTION,
    platformInfoApplicationType
  } from '@/core/constants';
  import { i18nHelper, uiHelper } from '@/core/utils';
  import {
    PLATFORM_INFO_UPLOAD_FILE,
    PLATFORM_INFO_INITIAL_UPLOAD_FILE_STATE
  } from '@/core/store/platform-info.module';

  export default {
    name: 'PlatformInfoPrivacyPolicy',
    mixins: [commonMixin, validationMixin, constantsMixin],
    components: {
      AppFormField,
      PlatformInfoDocumentPlaceholder
    },
    props: {
      platformInfo: {
        type: Object,
        default: () => {}
      }
    },
    data: () => ({
      global: GLOBAL,
      moduleName: MODULE.PLATFORM_INFO,
      action: ACTION,
      platformInfoObj: {
        privacyPolicy: null
      },
      newPlatformInfoObj: {
        privacyPolicy: null
      },
      isUploadingPlatformInfoObj: {
        privacyPolicy: false
      },
      formFields: [],
      validationParams: [
        {
          key: 'platformInfoApplicationType',
          params: [
            uiHelper.arrayToTrimmedStringWithSpace(
              GLOBAL.AVAILABLE_PLATFORM_INFO_APPLICATION_TYPE,
              12
            )
          ]
        }
      ]
    }),
    computed: {
      uploadFileComplete() {
        return this.$store.state.platformInfo.uploadFile.complete;
      }
    },
    watch: {
      platformInfo() {
        this.importPlatformInfoObj();
      },
      uploadFileComplete() {
        let response = this.$store.state.platformInfo.uploadFile;
        let title = i18nHelper.getMessage('label.uploadPrivacyPolicy');
        let initialStateAction = PLATFORM_INFO_INITIAL_UPLOAD_FILE_STATE;
        let successAction = () => {
          this.uploadFileCompleteAction();
        };
        let errorAction = () => {
          this.uploadFileCompleteAction();
        };

        if (response.complete) {
          this.stopUploadSpinner();
          this.actionUpdateRecordComplete(
            response,
            title,
            initialStateAction,
            successAction,
            errorAction
          );
        }
      }
    },
    mounted() {
      this.initFormFields();
      this.importPlatformInfoObj();
    },
    methods: {
      stopUploadSpinner() {
        this.isUploadingPlatformInfoObj.privacyPolicy = false;
      },
      uploadFileCompleteAction() {
        this.$parent.initialPlatformInfo();
        this.resetForm();
        window.KTUtil.scrollTop();
      },
      uploadFileAction() {
        let formData = new FormData();

        formData.append('type', PLATFORM_FILE_TYPE.PRIVACY_POLICY);
        formData.append('file', this.newPlatformInfoObj.privacyPolicy);

        if (this.validationCheck('newPlatformInfoObj')) {
          this.isUploadingPlatformInfoObj.privacyPolicy = true;
          this.uploadFile(formData);
        }
      },
      resetForm() {
        this.platformInfoObj.privacyPolicy = this.platformInfo.privacyPolicy;
        this.newPlatformInfoObj.privacyPolicy = null;
        this.$nextTick(() => {
          this.$v.$reset();
        });
      },
      replaceFAQ() {
        this.platformInfoObj.privacyPolicy = null;
      },
      importPlatformInfoObj() {
        let info = this.platformInfo;

        if (info) {
          this.platformInfoObj.privacyPolicy = info.privacyPolicy;
        }
      },
      uploadFile(formData) {
        this.$store.dispatch(PLATFORM_INFO_UPLOAD_FILE, { formData });
      },
      initFormFields() {
        this.formFields.push({
          field: 1,
          model: 'privacyPolicy',
          title: 'document',
          validators: ['required'],
          disabled: true
        });
      }
    },
    validations: {
      newPlatformInfoObj: {
        privacyPolicy: {
          required,
          platformInfoApplicationType
        }
      }
    }
  };
</script>

<style lang="scss"></style>
