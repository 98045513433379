import { VOUCHER } from '@/core/api';
import { apiHelper } from '@/core/utils';
import { VoucherSourceType } from '@/core/constants/enums';
import {
  LOADING_OPEN_LOADING,
  LOADING_CLOSE_LOADING
} from '@/core/store/loading.module';
import dateFormat from '@/core/filters/dateFormat.filter';
import constantsDescription from '@/core/filters/constants-description.filter';

/* Define Module Name */
const MODULE_NAME = 'voucher/';

/* Define Action Name */
const A_GET_VOUCHERS = 'getVouchers';
const A_GET_VOUCHER = 'getVoucher';
const A_GET_VOUCHER_CLAIMED_USERS = 'getVoucherClaimedUsers';
const A_GET_VOUCHER_SPECIFIC_USERS = 'getVoucherSpecificUsers';
const A_CREATE_VOUCHER = 'createVoucher';
const A_CREATE_VOUCHER_SPECIFIC_USERS = 'createVoucherSpecificUsers';
const A_UPDATE_VOUCHER = 'updateVoucher';
const A_UPDATE_VOUCHER_ACTIVATE = 'updateVoucherActivate';
const A_DELETE_VOUCHER = 'deleteVoucher';
const A_DELETE_VOUCHER_SPECIFIC_USERS = 'deleteVoucherSpecificUsers';

/* Define Reset State Action Name */
const A_INITIAL_GET_VOUCHERS_STATE = 'initialGetVouchersState';
const A_INITIAL_GET_VOUCHER_STATE = 'initialGetVoucherState';
const A_INITIAL_GET_VOUCHER_CLAIMED_USERS_STATE =
  'initialGetVoucherClaimedUsersState';
const A_INITIAL_GET_VOUCHER_SPECIFIC_USERS_STATE =
  'initialGetVoucherSpecificUsersState';
const A_INITIAL_CREATE_VOUCHER_STATE = 'initialcreateVoucherState';
const A_INITIAL_CREATE_VOUCHER_SPECIFIC_USERS_STATE =
  'initialcreateVoucherSpecificUsersState';
const A_INITIAL_UPDATE_VOUCHER_STATE = 'initialUpdateVoucherState';
const A_INITIAL_UPDATE_VOUCHER_ACTIVATE_STATE =
  'initialUpdateVoucherActivateState';
const A_INITIAL_DELETE_VOUCHER_STATE = 'initialdeleteVoucherState';
const A_INITIAL_DELETE_VOUCHER_SPECIFIC_USERS_STATE =
  'initialdeleteVoucherSpecificUsersState';

/* Define Getter Name */

/* Define Mutation Name */
const M_GET_VOUCHERS = 'setGetVouchers';
const M_GET_VOUCHER = 'setGetVoucher';
const M_GET_VOUCHER_CLAIMED_USERS = 'setGetVoucherClaimedUsers';
const M_GET_VOUCHER_SPECIFIC_USERS = 'setGetVoucherSpecificUsers';
const M_CREATE_VOUCHER = 'setcreateVoucher';
const M_CREATE_VOUCHER_SPECIFIC_USERS = 'setcreateVoucherSpecificUsers';
const M_UPDATE_VOUCHER = 'setUpdateVoucher';
const M_UPDATE_VOUCHER_ACTIVATE = 'setUpdateVoucherActivate';
const M_DELETE_VOUCHER = 'setdeleteVoucher';
const M_DELETE_VOUCHER_SPECIFIC_USERS = 'setdeleteVoucherSpecificUsers';

/* Define Reset State Mutation Name */
const M_INITIAL_GET_VOUCHERS_STATE = 'setInitialGetVouchersState';
const M_INITIAL_GET_VOUCHER_STATE = 'setInitialGetVoucherState';
const M_INITIAL_GET_VOUCHER_CLAIMED_USERS_STATE =
  'setInitialGetVoucherClaimedUsersState';
const M_INITIAL_GET_VOUCHER_SPECIFIC_USERS_STATE =
  'setInitialGetVoucherSpecificUsersState';
const M_INITIAL_CREATE_VOUCHER_STATE = 'setInitialcreateVoucherState';
const M_INITIAL_CREATE_VOUCHER_SPECIFIC_USERS_STATE =
  'setInitialcreateVoucherSpecificUsersState';
const M_INITIAL_UPDATE_VOUCHER_STATE = 'setInitialUpdateVoucherState';
const M_INITIAL_UPDATE_VOUCHER_ACTIVATE_STATE =
  'setInitialUpdateVoucherActivateState';
const M_INITIAL_DELETE_VOUCHER_STATE = 'setInitialdeleteVoucherState';
const M_INITIAL_DELETE_VOUCHER_SPECIFIC_USERS_STATE =
  'setInitialdeleteVoucherSpecificUsersState';

/* Define Export Name */
export const VOUCHER_GET_VOUCHERS = MODULE_NAME + A_GET_VOUCHERS;
export const VOUCHER_GET_VOUCHER = MODULE_NAME + A_GET_VOUCHER;
export const VOUCHER_GET_VOUCHER_CLAIMED_USERS =
  MODULE_NAME + A_GET_VOUCHER_CLAIMED_USERS;
export const VOUCHER_GET_VOUCHER_SPECIFIC_USERS =
  MODULE_NAME + A_GET_VOUCHER_SPECIFIC_USERS;
export const VOUCHER_CREATE_VOUCHER = MODULE_NAME + A_CREATE_VOUCHER;
export const VOUCHER_CREATE_VOUCHER_SPECIFIC_USERS =
  MODULE_NAME + A_CREATE_VOUCHER_SPECIFIC_USERS;
export const VOUCHER_UPDATE_VOUCHER = MODULE_NAME + A_UPDATE_VOUCHER;
export const VOUCHER_UPDATE_VOUCHER_ACTIVATE =
  MODULE_NAME + A_UPDATE_VOUCHER_ACTIVATE;
export const VOUCHER_DELETE_VOUCHER = MODULE_NAME + A_DELETE_VOUCHER;
export const VOUCHER_DELETE_VOUCHER_SPECIFIC_USERS =
  MODULE_NAME + A_DELETE_VOUCHER_SPECIFIC_USERS;
/* Define Reset State Name */
export const VOUCHER_INITIAL_GET_VOUCHERS_STATE =
  MODULE_NAME + A_INITIAL_GET_VOUCHERS_STATE;
export const VOUCHER_INITIAL_GET_VOUCHER_STATE =
  MODULE_NAME + A_INITIAL_GET_VOUCHER_STATE;
export const VOUCHER_INITIAL_GET_VOUCHER_CLAIMED_USERS_STATE =
  MODULE_NAME + A_INITIAL_GET_VOUCHER_CLAIMED_USERS_STATE;
export const VOUCHER_INITIAL_GET_VOUCHER_SPECIFIC_USERS_STATE =
  MODULE_NAME + A_INITIAL_GET_VOUCHER_SPECIFIC_USERS_STATE;
export const VOUCHER_INITIAL_CREATE_VOUCHER_STATE =
  MODULE_NAME + A_INITIAL_CREATE_VOUCHER_STATE;
export const VOUCHER_INITIAL_CREATE_VOUCHER_SPECIFIC_USERS_STATE =
  MODULE_NAME + A_INITIAL_CREATE_VOUCHER_SPECIFIC_USERS_STATE;
export const VOUCHER_INITIAL_UPDATE_VOUCHER_STATE =
  MODULE_NAME + A_INITIAL_UPDATE_VOUCHER_STATE;
export const VOUCHER_INITIAL_UPDATE_VOUCHER_ACTIVATE_STATE =
  MODULE_NAME + A_INITIAL_UPDATE_VOUCHER_ACTIVATE_STATE;
export const VOUCHER_INITIAL_DELETE_VOUCHER_STATE =
  MODULE_NAME + A_INITIAL_DELETE_VOUCHER_STATE;
export const VOUCHER_INITIAL_DELETE_VOUCHER_SPECIFIC_USERS_STATE =
  MODULE_NAME + A_INITIAL_DELETE_VOUCHER_SPECIFIC_USERS_STATE;

const state = {
  vouchers: {
    code: 0,
    complete: false,
    data: null,
    message: null,
    pagination: {
      currentPage: 0,
      total: 0,
      perPage: 0,
      lastPage: 0
    }
  },
  voucher: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  voucherClaimedUsers: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  voucherSpecificUsers: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  createVoucher: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  createVoucherSpecificUsers: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  updateVoucher: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  updateVoucherActivate: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  deleteVoucher: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  deleteVoucherSpecificUsers: {
    code: 0,
    complete: false,
    data: null,
    message: null
  }
};

//Getters
const getters = {};

// Actions
const actions = {
  async [A_GET_VOUCHERS]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    await VOUCHER.getVouchers(data)
      .then((respone) => {
        let result = respone;
        commit(M_GET_VOUCHERS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_GET_VOUCHERS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_VOUCHER]({ dispatch, commit }, { id }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    VOUCHER.getVoucherId(id)
      .then((respone) => {
        let result = respone;
        commit(M_GET_VOUCHER, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_GET_VOUCHER, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_VOUCHER_CLAIMED_USERS]({ dispatch, commit }, { id, data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    VOUCHER.getVoucherCliamedUsers(id, data)
      .then((respone) => {
        let result = respone;
        commit(M_GET_VOUCHER_CLAIMED_USERS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_GET_VOUCHER_CLAIMED_USERS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_VOUCHER_SPECIFIC_USERS]({ dispatch, commit }, { id, data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    VOUCHER.getVoucherSpecificUsers(id, data)
      .then((respone) => {
        let result = respone;
        commit(M_GET_VOUCHER_SPECIFIC_USERS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_GET_VOUCHER_SPECIFIC_USERS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_CREATE_VOUCHER]({ dispatch, commit }, { formData }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    VOUCHER.createVoucherId(formData)
      .then((respone) => {
        let result = respone;
        commit(M_CREATE_VOUCHER, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_CREATE_VOUCHER, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_CREATE_VOUCHER_SPECIFIC_USERS]({ dispatch, commit }, { id, formData }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    VOUCHER.createVoucherSpecificUsers(id, formData)
      .then((respone) => {
        let result = respone;
        commit(M_CREATE_VOUCHER_SPECIFIC_USERS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_CREATE_VOUCHER_SPECIFIC_USERS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_UPDATE_VOUCHER]({ dispatch, commit }, { id, formData }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    VOUCHER.updateVoucherId(id, formData)
      .then((respone) => {
        let result = respone;
        commit(M_UPDATE_VOUCHER, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_UPDATE_VOUCHER, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_UPDATE_VOUCHER_ACTIVATE]({ dispatch, commit }, { id, data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    VOUCHER.updateVoucherIdActivate(id, data)
      .then((respone) => {
        let result = respone;
        commit(M_UPDATE_VOUCHER_ACTIVATE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_UPDATE_VOUCHER_ACTIVATE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_DELETE_VOUCHER]({ dispatch, commit }, { id }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    VOUCHER.deleteVoucherId(id)
      .then((respone) => {
        let result = respone;
        commit(M_DELETE_VOUCHER, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_DELETE_VOUCHER, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_DELETE_VOUCHER_SPECIFIC_USERS]({ dispatch, commit }, { id, formData }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    VOUCHER.deleteVoucherSpecificUsers(id, formData)
      .then((respone) => {
        let result = respone;
        commit(M_DELETE_VOUCHER_SPECIFIC_USERS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_DELETE_VOUCHER_SPECIFIC_USERS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_INITIAL_GET_VOUCHERS_STATE]({ commit }) {
    commit(M_INITIAL_GET_VOUCHERS_STATE);
  },
  [A_INITIAL_GET_VOUCHER_STATE]({ commit }) {
    commit(M_INITIAL_GET_VOUCHER_STATE);
  },
  [A_INITIAL_GET_VOUCHER_CLAIMED_USERS_STATE]({ commit }) {
    commit(M_INITIAL_GET_VOUCHER_CLAIMED_USERS_STATE);
  },
  [A_INITIAL_GET_VOUCHER_SPECIFIC_USERS_STATE]({ commit }) {
    commit(M_INITIAL_GET_VOUCHER_SPECIFIC_USERS_STATE);
  },
  [A_INITIAL_CREATE_VOUCHER_STATE]({ commit }) {
    commit(M_INITIAL_CREATE_VOUCHER_STATE);
  },
  [A_INITIAL_CREATE_VOUCHER_SPECIFIC_USERS_STATE]({ commit }) {
    commit(M_INITIAL_CREATE_VOUCHER_SPECIFIC_USERS_STATE);
  },
  [A_INITIAL_UPDATE_VOUCHER_STATE]({ commit }) {
    commit(M_INITIAL_UPDATE_VOUCHER_STATE);
  },
  [A_INITIAL_UPDATE_VOUCHER_ACTIVATE_STATE]({ commit }) {
    commit(M_INITIAL_UPDATE_VOUCHER_ACTIVATE_STATE);
  },
  [A_INITIAL_DELETE_VOUCHER_STATE]({ commit }) {
    commit(M_INITIAL_DELETE_VOUCHER_STATE);
  },
  [A_INITIAL_DELETE_VOUCHER_SPECIFIC_USERS_STATE]({ commit }) {
    commit(M_INITIAL_DELETE_VOUCHER_SPECIFIC_USERS_STATE);
  }
};

// Mutations
const mutations = {
  [M_GET_VOUCHERS](state, result) {
    if (result.code === 0) {
      let d = result.data;
      let p = result.pagination;
      let vouchers = [];

      d.forEach((x, index) => {
        vouchers.push({
          no: index + 1,
          id: x.id,
          eventName: x.voucher_merchant.event_name,
          voucherTitle: x.title,
          voucherType: constantsDescription('serviceType', x.voucher_type),
          applyTo: constantsDescription('voucherApplyTo', x.apply_to),
          quantity: x.voucher_merchant.quantity,
          minimumSpending: x.minimum_spending,
          status: x.voucher_merchant.status,
          totalClaim: x.total_claim,
          totalRedemption: x.total_redeem,
          availableFrom: dateFormat(x.voucher_merchant.available_from),
          availableTo: dateFormat(x.voucher_merchant.available_to)
        });
      });

      state.vouchers = {
        code: result.code,
        complete: true,
        data: vouchers,
        pagination: {
          currentPage: p.current_page,
          total: p.total,
          perPage: p.per_page,
          lastPage: p.last_page
        }
      };
    } else {
      state.vouchers = {
        code: result.code,
        complete: true,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_GET_VOUCHER](state, result) {
    if (result.code === 0) {
      let d = result.data;
      let merchantDetails = d.voucher_merchant;
      let voucherObj;

      let voucherSource = merchantDetails.voucher_source;
      let selectedCreator = '';
      let selectedDineMerchant = '';
      let selectedBranch = [];
      let totalSpecific = '';

      if (
        voucherSource == VoucherSourceType.MERCHANT ||
        voucherSource == VoucherSourceType.CREATOR
      ) {
        if (voucherSource == VoucherSourceType.MERCHANT) {
          selectedCreator = merchantDetails.details.merchant_ids;
        } else if (voucherSource == VoucherSourceType.CREATOR) {
          selectedDineMerchant = merchantDetails.details.merchant_ids;
        }
        merchantDetails.details.merchant_branches.forEach((x) => {
          selectedBranch.push(x.id);
        });
        totalSpecific = d.total_user;
      }
      voucherObj = {
        id: d.id,
        status: merchantDetails.status,
        totalClaim: d.total_claim,
        // Basic Information
        applyTo: d.apply_to,
        voucherSource: voucherSource,
        selectedCreator: selectedCreator,
        selectedDineMerchant: selectedDineMerchant,
        selectedBranch: selectedBranch,
        groupName: d.grouping_name,
        usagePeriodFrom: dateFormat(merchantDetails.available_from),
        usagePeriodTo: dateFormat(merchantDetails.available_to),
        quantity: merchantDetails.quantity,
        eventName: merchantDetails.event_name,
        totalSpecific: totalSpecific,
        // Reward Setting
        voucherPrefix: d.voucher_prefix,
        voucherValueType: {
          type: d.value_type,
          value: d.value
        },
        minimumSpending: d.minimum_spending,
        voucherType: d.voucher_type,
        banner: d.banner,
        thumbnail: d.thumbnail,
        voucherTitle: d.title,
        description: d.description,
        redemptionPeriodFrom: dateFormat(d.available_from),
        redemptionPeriodTo: dateFormat(d.available_to),
        tnc: d.tnc
      };
      state.voucher = {
        code: result.code,
        complete: true,
        data: voucherObj
      };
    } else {
      state.voucher = {
        code: result.code,
        complete: true,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_GET_VOUCHER_CLAIMED_USERS](state, result) {
    if (result.code === 0) {
      let d = result.data;
      let p = result.pagination;
      let claimedUsersObj = [];

      d.forEach((x, index) => {
        claimedUsersObj.push({
          no: index + 1,
          id: x.id,
          userId: x.user_id,
          username: { id: x.user_id, name: x.username },
          voucherName: x.voucher_name,
          voucherCode: x.voucher_code ?? '-',
          redemptionAt: dateFormat(x.redemption_at)
        });
      });

      state.voucherClaimedUsers = {
        code: result.code,
        complete: true,
        data: claimedUsersObj,
        pagination: {
          currentPage: p.current_page,
          total: p.total,
          perPage: p.per_page,
          lastPage: p.last_page
        }
      };
    } else {
      state.voucherClaimedUsers = {
        code: result.code,
        complete: true,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_GET_VOUCHER_SPECIFIC_USERS](state, result) {
    if (result.code === 0) {
      let d = result.data;
      let p = result.pagination;
      let specificUsersObj = [];
      d.forEach((x, index) => {
        specificUsersObj.push({
          no: index + 1,
          id: x.id,
          userId: x.id,
          username: { id: x.id, name: x.username },
          contactNumber: x.contact_number,
          voucherClaimDate: dateFormat(x.voucher_claim_date)
        });
      });

      state.voucherSpecificUsers = {
        code: result.code,
        complete: true,
        data: specificUsersObj,
        pagination: {
          currentPage: p.current_page,
          total: p.total,
          perPage: p.per_page,
          lastPage: p.last_page
        }
      };
    } else {
      state.voucherSpecificUsers = {
        code: result.code,
        complete: true,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_CREATE_VOUCHER](state, result) {
    if (result.code === 0) {
      let d = result.data;

      state.createVoucher = {
        code: result.code,
        complete: true,
        data: d,
        message: apiHelper.sortOutMessage(result.message)
      };
    } else {
      state.createVoucher = {
        code: result.code,
        complete: true,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_CREATE_VOUCHER_SPECIFIC_USERS](state, result) {
    if (result.code === 0) {
      let d = result.data;

      state.createVoucherSpecificUsers = {
        code: result.code,
        complete: true,
        data: d,
        message: apiHelper.sortOutMessage(result.message)
      };
    } else {
      state.createVoucherSpecificUsers = {
        code: result.code,
        complete: true,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_UPDATE_VOUCHER](state, result) {
    if (result.code === 0) {
      let d = result.data;

      state.updateVoucher = {
        code: result.code,
        complete: true,
        data: d,
        message: apiHelper.sortOutMessage(result.message)
      };
    } else {
      state.updateVoucher = {
        code: result.code,
        complete: true,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_UPDATE_VOUCHER_ACTIVATE](state, result) {
    if (result.code === 0) {
      let d = result.data;

      state.updateVoucherActivate = {
        code: result.code,
        complete: true,
        data: d,
        message: apiHelper.sortOutMessage(result.message)
      };
    } else {
      state.updateVoucherActivate = {
        code: result.code,
        complete: true,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_DELETE_VOUCHER](state, result) {
    if (result.code === 0) {
      let d = result.data;

      state.deleteVoucher = {
        code: result.code,
        complete: true,
        data: d,
        message: apiHelper.sortOutMessage(result.message)
      };
    } else {
      state.deleteVoucher = {
        code: result.code,
        complete: true,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_DELETE_VOUCHER_SPECIFIC_USERS](state, result) {
    if (result.code === 0) {
      let d = result.data;

      state.deleteVoucherSpecificUsers = {
        code: result.code,
        complete: true,
        data: d,
        message: apiHelper.sortOutMessage(result.message)
      };
    } else {
      state.deleteVoucherSpecificUsers = {
        code: result.code,
        complete: true,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_INITIAL_GET_VOUCHERS_STATE](state) {
    state.vouchers = {
      code: 0,
      complete: false,
      data: null,
      message: null,
      pagination: {
        currentPage: 0,
        total: 0,
        perPage: 0,
        lastPage: 0
      }
    };
  },
  [M_INITIAL_GET_VOUCHER_STATE](state) {
    state.voucher = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_GET_VOUCHER_CLAIMED_USERS_STATE](state) {
    state.voucherClaimedUsers = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_GET_VOUCHER_SPECIFIC_USERS_STATE](state) {
    state.voucherSpecificUsers = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_CREATE_VOUCHER_STATE](state) {
    state.createVoucher = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_CREATE_VOUCHER_SPECIFIC_USERS_STATE](state) {
    state.createVoucherSpecificUsers = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_UPDATE_VOUCHER_STATE](state) {
    state.updateVoucher = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_UPDATE_VOUCHER_ACTIVATE_STATE](state) {
    state.updateVoucherActivate = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_DELETE_VOUCHER_STATE](state) {
    state.deleteVoucher = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_DELETE_VOUCHER_SPECIFIC_USERS_STATE](state) {
    state.deleteVoucherSpecificUsers = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
