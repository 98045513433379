import { apiService } from '@/core/services';
// import { apiHelper } from '@/core/utils';

export const VOUCHER = {
  getVouchers,
  getVoucherId,
  getVoucherCliamedUsers,
  getVoucherSpecificUsers,
  createVoucherId,
  createVoucherSpecificUsers,
  updateVoucherId,
  updateVoucherIdActivate,
  deleteVoucherId,
  deleteVoucherSpecificUsers
};

const URL = {
  ADMIN: 'admin',
  MERCHANT_VOUCHERS: 'merchant-vouchers',
  UPDATE_STATUS: 'update-status',
  USERS: 'users',
  ASSIGN: 'assign',
  UNASSIGN: 'unassign'
};

function getVouchers(data) {
  let requestOptions = {
    params: {
      title: data.voucherTitle,
      id: data.id,
      status: data.status,
      apply_to: data.applyTo,
      per_page: data.perPage,
      page: data.page
    }
  };
  return apiService().get(
    `/${URL.ADMIN}/${URL.MERCHANT_VOUCHERS}`,
    requestOptions
  );
}

function getVoucherId(id) {
  return apiService().get(`/${URL.ADMIN}/${URL.MERCHANT_VOUCHERS}/${id} `);
}

function getVoucherCliamedUsers(id, data) {
  let requestOptions = {
    params: {
      per_page: data.perPage,
      page: data.page
    }
  };
  return apiService().get(
    `/${URL.ADMIN}/${URL.MERCHANT_VOUCHERS}/${id}/${URL.USERS}`,
    requestOptions
  );
}
function getVoucherSpecificUsers(id, data) {
  let requestOptions = {
    params: {
      per_page: data.perPage,
      page: data.page
    }
  };
  return apiService().get(
    `/${URL.ADMIN}/${URL.MERCHANT_VOUCHERS}/${id}/${URL.ASSIGN}`,
    requestOptions
  );
}

function createVoucherId(formData) {
  return apiService().post(`/${URL.ADMIN}/${URL.MERCHANT_VOUCHERS}`, formData);
}

function createVoucherSpecificUsers(id, formData) {
  return apiService().post(
    `/${URL.ADMIN}/${URL.MERCHANT_VOUCHERS}/${id}/${URL.ASSIGN}`,
    formData
  );
}

function updateVoucherId(id, formData) {
  return apiService().post(
    `/${URL.ADMIN}/${URL.MERCHANT_VOUCHERS}/${id}`,
    formData
  );
}

function updateVoucherIdActivate(id, data) {
  let requestBody = {
    status: data
  };
  return apiService().patch(
    `/${URL.ADMIN}/${URL.MERCHANT_VOUCHERS}/${id}/${URL.UPDATE_STATUS}`,
    requestBody
  );
}

function deleteVoucherId(id) {
  return apiService().delete(`/${URL.ADMIN}/${URL.MERCHANT_VOUCHERS}/${id}`);
}

function deleteVoucherSpecificUsers(id, formData) {
  return apiService().post(
    `/${URL.ADMIN}/${URL.MERCHANT_VOUCHERS}/${id}/${URL.UNASSIGN}`,
    formData
  );
}
