<template>
  <div>
    <template
      v-for="(i, index) in formFields.filter(
        (x) => x.field == 1 && x.hidden == false
      )"
    >
      <div :key="index">
        <label class="font-size-sm text-capitalize"
          >{{ $t(`label.${i.title}`) }}
        </label>
        <div v-if="i.assets">
          <b-row>
            <b-col
              v-for="(item, index) in recipeObj.assets"
              :key="index"
              cols="6"
              md="4"
              xl="3"
            >
              <b-card
                no-body
                class="pointer h-100 shadow-sm card-custom rounded-0 overlay text-center"
              >
                <div class="card-body p-0">
                  <div class="overlay-wrapper">
                    <div class="overflow-hidden">
                      <div
                        v-if="item.mediaType == mediaType.IMAGE"
                        class="h-100px h-sm-150px h-lg-150px h-xl-200px"
                        :class="`${
                          isImage(item.mediaType)
                            ? 'document-image'
                            : 'document'
                        }`"
                        :style="`background-image: url('${attachmentImage(
                          item.mediaType,
                          item.mediaPath
                        )}'); background-size: 100% 100%;`"
                      ></div>
                      <video
                        v-else-if="item.mediaType == mediaType.VIDEO"
                        class="h-100px h-sm-150px h-lg-150px h-xl-200px"
                      >
                        <source :src="item.mediaPath" />
                      </video>
                    </div>
                  </div>
                  <div class="overlay-layer" style="display: none">
                    <div class="text-center">
                      <AppButtonDocumentView
                        :attachmentUrl="item.mediaPath"
                        :fileType="item.mediaType"
                        :buttonType="2"
                      ></AppButtonDocumentView>
                    </div>
                  </div>
                </div>
                <!-- {{ item.checked }} -->
                <b-form-checkbox
                  v-model="$v.recipeObj[i.model].$model"
                  :validationValue="$v.recipeObj[i.model]"
                  :validators="i.validators"
                  :state="validateObjState('recipeObj', i.model)"
                  :value="item.id"
                  :checked="item.id"
                  size="lg"
                  switch
                  @input="input"
                >
                </b-form-checkbox>
              </b-card>
            </b-col>
          </b-row>
          <b-form-invalid-feedback
            class="py-3"
            :state="validateObjState('recipeObj', i.model)"
          >
            {{ $t(`fieldErrorMessage.requiredFieldDefault`) }}
          </b-form-invalid-feedback>
        </div>
        <div v-else>
          <AppFormField
            v-model="recipeObj[i.model]"
            :title="$t(`label.${i.title}`)"
            :formType="i.formType"
            :disabled="i.disabled"
            :size="'lg'"
            :config="{
              label: recipeObj?.top,
              routerName: getRecipePageDetailsRouteName(category),
              name: recipeObj?.top?.recipeName,
              id: recipeObj?.top?.id
            }"
            horizontal
            solid
            noTitle
            @input="input"
          ></AppFormField>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
  import AppButtonDocumentView from '@/views/components/bases/button/AppButtonDocumentView';
  import AppFormField from '@/views/components/bases/form-field/AppFormField';
  import constantsMixin from '@/core/mixins/constants.mixin';
  import commonMixin from '@/core/mixins/common.mixin';
  import recipePageMixin from '@/core/mixins/recipe-page.mixin';
  import { validationMixin } from 'vuelidate';
  import { MediaType } from '@/core/constants/enums';
  import { required } from 'vuelidate/lib/validators';

  export default {
    name: 'RecipePageMedia',
    mixins: [commonMixin, constantsMixin, validationMixin, recipePageMixin],
    components: { AppFormField, AppButtonDocumentView },
    props: {
      id: {},
      isCreate: {
        type: Boolean,
        default: false
      },
      category: {
        type: String,
        default: ''
      },
      recipeObj: {
        type: Object,
        default: () => {}
      }
    },
    data: () => ({
      mediaType: MediaType,
      formFields: []
    }),
    created() {
      this.initRecipePageMedia();
    },
    mounted() {},
    watch: {
      recipeObj() {
        this.initFormFields();
      }
    },
    methods: {
      input() {
        if (!this.recipeObj.topAssets) this.recipeObj.topAssets = '';
        this.$emit('input', this.recipeObj);
      },
      validationCheckForm() {
        return this.validationCheck('recipeObj');
      },
      isImage(type) {
        let isImage = false;

        if (type == MediaType.IMAGE) {
          isImage = true;
        }
        return isImage;
      },
      attachmentImage(type, originalUrl) {
        let src = '';

        if (type == MediaType.IMAGE) {
          src = originalUrl;
        } else if (type == MediaType.APPLICATION) {
          src = '/media/svg/files/pdf.svg';
        } else if (type == 'docx' || type == 'doc') {
          src = '/media/svg/files/doc.svg';
        }
        return src;
      },
      checkTopRecipe() {
        if (!this.recipeObj.topRecipe) {
          if (this.recipeObj.top) {
            return true;
          } else {
            return false;
          }
        }
        return false;
      },
      initRecipePageMedia() {
        this.initFormFields();
      },
      initFormFields() {
        this.formFields = [];
        this.formFields.push(
          {
            field: 1,
            model: 'topRecipe',
            title: 'topRecipe',
            formType: 'singleCheckbox',
            hidden: this.isCreate,
            disabled: this.checkTopRecipe()
          },
          {
            field: 1,
            assets: true,
            model: 'topAssets',
            title: 'mediaAssets',
            validators: ['required'],
            formType: 'singleCheckbox',
            hidden: false
          }
        );
      }
    },
    validations() {
      return {
        recipeObj: {
          topAssets: { required }
        }
      };
    }
  };
</script>

<style></style>
