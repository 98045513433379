<template>
  <b-card>
    <b-card-title class="text-capitalize font-weight-bolder">
      {{ $t('label.pickupLocation') }}
    </b-card-title>

    <table>
      <tbody>
        <template v-for="(item, index) in pickupLocationDetails">
          <tr :key="index" class="text-gray-600">
            <td class="text-capitalize font-weight-bolder pr-6 min-w-150px">
              {{ $t(`label.${item.name}`) }}:
            </td>
            <td v-if="value.deliveryFrom" class="text-break">
              {{ value.deliveryFrom[item.key] }}
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </b-card>
</template>

<script>
  export default {
    name: 'OrderPickupLocationCard',
    props: {
      value: {
        type: Object,
        default: () => {}
      }
    },
    data: () => ({
      pickupLocationDetails: [
        {
          key: 'name',
          name: 'name'
        },
        {
          key: 'address',
          name: 'address'
        },
        {
          key: 'contactNumber',
          name: 'contactNumber'
        }
      ]
    })
  };
</script>

<style></style>
