<template>
  <div>
    <AppSmartList
      ref="appSmartList"
      :breadcrumbData="breadcrumbData"
      :accessRights="accessRights"
      :filters="filters"
      :actions="actions"
      :tableHeaders="headers"
      :newRecord="newRecord"
      @app-smart-list-action-new-record="actionNewRecord"
      @app-smart-list-open-modal-details="openModalDetails"
    >
    </AppSmartList>

    <!--begin:: Maintenance Modal-->
    <PopularSearchModal
      v-model="thisModal.isShow"
      :isNewRecord="thisModal.isNewRecord"
      :id="thisModal.id"
    ></PopularSearchModal>
  </div>
</template>

<script>
  const CONSTANTS_DATA = JSON.parse(uiHelper.getLocalStorage(LOCAL.CONSTANTS));

  import AppSmartList from '@/views/components/bases/smart/AppSmartList.vue';
  import PopularSearchModal from '@/views/components/popular-search/PopularSearchModal';
  import constantsMixin from '@/core/mixins/constants.mixin';
  import { i18nHelper, uiHelper } from '@/core/utils';
  import { listService } from '@/core/services';
  import {
    ROUTE_NAME,
    MODULE,
    ACTION,
    LOCAL,
    APP_COMPONENT_NAME,
    SMART_FORM_FIELD_TYPE
  } from '@/core/constants';
  import {
    POPULAR_SEARCH_GET_POPULAR_SEARCHES,
    POPULAR_SEARCH_INITIAL_GET_POPULAR_SEARCHES_STATE
  } from '@/core/store/popular-search.module';

  export default {
    name: 'PopularSearchList',
    components: {
      AppSmartList,
      PopularSearchModal
    },
    mixins: [constantsMixin],
    data: () => ({
      breadcrumbData: {
        breadcrumbs: [
          {
            title: i18nHelper.getMessage('label.popularSearch'),
            route: { name: ROUTE_NAME.POPULAR_SEARCH }
          },
          { title: i18nHelper.getMessage('label.popularSearchList') }
        ]
      },
      accessRights: {
        moduleName: MODULE.POPULAR_SEARCH,
        actions: {
          read: ACTION.POPULAR_SEARCH_READ,
          update: ACTION.POPULAR_SEARCH_UPDATE,
          create: ACTION.POPULAR_SEARCH_CREATE
        }
      },
      filters: [
        {
          label: 'merchantName',
          key: 'merchantName',
          value: ''
        },
        {
          label: 'category',
          key: 'category',
          value: null,
          options: listService.getDynamicList(CONSTANTS_DATA.merchantCategory),
          optionLabel: 'text',
          type: SMART_FORM_FIELD_TYPE.SELECT
        }
      ],
      actions: {
        viewList: {
          module: 'popularSearch',
          state: 'popularSearches',
          action: POPULAR_SEARCH_GET_POPULAR_SEARCHES,
          initialStateAction: POPULAR_SEARCH_INITIAL_GET_POPULAR_SEARCHES_STATE,
          title: i18nHelper.getMessage('label.getPopularSearch')
        }
      },
      headers: [
        {
          key: 'no',
          name: 'no',
          active: true,
          sortable: true
        },
        {
          key: 'merchantName',
          name: 'merchantName',
          active: true,
          sortable: true
        },
        {
          key: 'merchantType',
          name: 'merchantType',
          active: true,
          sortable: true,
          class: 'py-2'
        },
        {
          key: 'searchCategory',
          name: 'searchCategory',
          active: true,
          sortable: true,
          class: 'py-2'
        },
        {
          key: 'actions',
          name: 'actions',
          active: true,
          action: true,
          layout: APP_COMPONENT_NAME.APP_TABLE_ACTIONS,
          layoutActions: {
            isModal: true
          }
        }
      ],
      newRecord: {
        show: true,
        buttonTitle: i18nHelper.getMessage('label.addPopularSearch'),
        isModal: true
      },
      thisModal: {
        isShow: false,
        isNewRecord: false,
        id: 0
      }
    }),
    computed: {},
    watch: {},
    methods: {
      actionNewRecord() {
        this.openModal(true);
      },
      openModalDetails(value) {
        this.openModal(false, value);
      },
      openModal(isNewRecord, id) {
        this.thisModal = {
          isShow: true,
          isNewRecord: isNewRecord,
          id: id
        };
      }
    }
  };
</script>

<style></style>
