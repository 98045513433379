import { CONFIG_POINT_SETTING } from '@/core/api';
import { apiHelper } from '@/core/utils';
import {
  LOADING_OPEN_LOADING,
  LOADING_CLOSE_LOADING
} from '@/core/store/loading.module';

/* Define Module Name */
const MODULE_NAME = 'configPointSetting/';

/* Define Action Name */
const A_GET_POINT_SETTING = 'getPointSetting';
const A_CREATE_POINT_SETTING = 'createPointSetting';
const A_UPDATE_POINT_SETTING = 'updatePointSetting';
const A_UPDATE_REDEEMABLE_PRODUCT = 'updateRedeemableProduct';

/* Define Reset State Action Name */
const A_INITIAL_GET_POINT_SETTING_STATE = 'initialGetPointSettingState';
const A_INITIAL_UPDATE_POINT_SETTING_STATE =
  'initialupdatePointSettingState';


/* Define Getter Name */

/* Define Mutation Name */
const M_GET_POINT_SETTING = 'setGetPointSetting';
const M_UPDATE_POINT_SETTING = 'setUpdatePointSetting';

/* Define Reset State Mutation Name */
const M_INITIAL_GET_POINT_SETTING_STATE = 'setInitialGetPointSettingState';

const M_INITIAL_UPDATE_POINT_SETTING_STATE =
  'setInitialUpdatePointSettingState';
const M_INITIAL_DELETE_POINT_SETTING_STATE =
  'setInitialDeletePointSettingState';

/* Define Export Name */
export const GENERAL_GET_POINT_SETTING = MODULE_NAME + A_GET_POINT_SETTING;
export const GENERAL_CREATE_POINT_SETTING =
  MODULE_NAME + A_CREATE_POINT_SETTING;
export const GENERAL_UPDATE_POINT_SETTING =
  MODULE_NAME + A_UPDATE_POINT_SETTING;
export const GENERAL_UPDATE_REDEEMABLE_PRODUCT =
  MODULE_NAME + A_UPDATE_REDEEMABLE_PRODUCT;

/* Define Reset State Name */
export const GENERAL_INITIAL_GET_POINT_SETTING_STATE =
  MODULE_NAME + A_INITIAL_GET_POINT_SETTING_STATE;

export const GENERAL_INITIAL_UPDATE_POINT_SETTING_STATE =
  MODULE_NAME + A_INITIAL_UPDATE_POINT_SETTING_STATE;


const state = {
  PointSetting: {
    code: 0,
    complete: false,
    data: null,
    message: null,
    pagination: {
      currentPage: 0,
      total: 0,
      perPage: 0,
      lastPage: 0
    }
  },
  createPointSetting: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  updatePointSetting: {
    code: 0,
    complete: false,
    data: null,
    message: null
  }
};

//Getters
const getters = {};

// Actions
const actions = {
  [A_GET_POINT_SETTING]({ dispatch, commit }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    CONFIG_POINT_SETTING.getPointSetting()
      .then((response) => {
        result = response;
        commit(M_GET_POINT_SETTING, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_POINT_SETTING, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_UPDATE_POINT_SETTING]({ dispatch, commit }, data) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    CONFIG_POINT_SETTING.updatePointSetting(data)
      .then((response) => {
        result = response;
        commit(M_UPDATE_POINT_SETTING, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_UPDATE_POINT_SETTING, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_UPDATE_REDEEMABLE_PRODUCT]({ dispatch, commit }, { formData }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    console.log(formData)
    CONFIG_POINT_SETTING.updateRedeemableProduct(formData)
      .then((response) => {
        result = response;
        commit(M_UPDATE_POINT_SETTING, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_UPDATE_POINT_SETTING, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_INITIAL_GET_POINT_SETTING_STATE]({ commit }) {
    commit(M_INITIAL_GET_POINT_SETTING_STATE);
  },
  [A_INITIAL_UPDATE_POINT_SETTING_STATE]({ commit }) {
    commit(M_INITIAL_UPDATE_POINT_SETTING_STATE);
  }
};

// Mutations
const mutations = {
  [M_GET_POINT_SETTING](state, result) {
    let d = result.data;
    state.PointSetting = {
      code: result.code,
      complete: true,
      data: d,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_UPDATE_POINT_SETTING](state, result) {
    state.updatePointSetting = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result.message),
      pagination: {
        currentPage: 0,
        total: 0,
        perPage: 0,
        lastPage: 0
      }
    };
  },
  [M_INITIAL_GET_POINT_SETTING_STATE](state) {
    state.PointSetting = {
      code: 0,
      complete: false,
      data: null,
      message: null,
      pagination: {
        currentPage: 0,
        total: 0,
        perPage: 0,
        lastPage: 0
      }
    };
  },
  [M_INITIAL_UPDATE_POINT_SETTING_STATE](state) {
    state.updatePointSetting = {
      code: 0,
      complete: false,
      data: null,
      message: null,
      pagination: {
        currentPage: 0,
        total: 0,
        perPage: 0,
        lastPage: 0
      }
    };
  },
  [M_INITIAL_DELETE_POINT_SETTING_STATE](state) {
    state.deletePointSetting = {
      code: 0,
      complete: false,
      data: null,
      message: null,
      pagination: {
        currentPage: 0,
        total: 0,
        perPage: 0,
        lastPage: 0
      }
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
