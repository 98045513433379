<template>
  <b-modal
    v-if="visible"
    :visible="visible"
    centered
    hide-header-close
    no-close-on-esc
    no-close-on-backdrop
    :title="$t('label.rejectEcoPackRequest')"
    header-class="text-capitalize"
  >
    <p>
      {{ $t('message.requestRejectMessage', [$t('label.ecoPackRequest')]) }}
    </p>
    <template v-for="(i, index) in formFields">
      <div :key="index" class="px-2">
        <AppFormField
          v-model="$v.rejectedReason[i.model].$model"
          :solid="true"
          :state="validateState('rejectedReason', i.model)"
          :validationValue="$v.rejectedReason[i.model]"
          :title="$t(`label.${i.title}`)"
          :validators="i.validators"
          :formType="i.formType"
          :options="i.options"
        >
        </AppFormField>

        <div style="display: none">
          <!-- WARNING: I have no idea why this line is here, but removing it breaks everything -->
          {{ $v }}
        </div>
      </div>
    </template>

    <template #modal-footer>
      <b-button @click="onClickCancel">{{ $t('label.cancel') }}</b-button>
      <b-button variant="danger" @click="onClickReject">{{
        $t('label.reject')
      }}</b-button>
    </template>
  </b-modal>
</template>

<script>
  import AppFormField from '@/views/components/bases/form-field/AppFormField';
  import CommonMixin from '@/core/mixins/common.mixin';
  import { validationMixin } from 'vuelidate';
  import { required } from 'vuelidate/lib/validators';

  export default {
    name: 'RecipeRejectEcoPackModal',
    components: {
      AppFormField
    },
    mixins: [CommonMixin, validationMixin],
    data: () => ({
      visible: false,
      rejectedReason: {
        reason: null
      },
      formFields: []
    }),
    mounted() {
      this.initFormFields();
    },
    methods: {
      initFormFields() {
        this.formFields.push({
          model: 'reason',
          title: 'reason',
          validators: ['required']
        });
      },
      onClickCancel() {
        this.close();
      },
      onClickReject() {
        if (this.validationCheck('rejectedReason')) {
          this.$emit('reject', this.rejectedReason);
          this.close();
        }
      },
      show() {
        this.visible = true;
      },
      close() {
        this.visible = false;
        this.rejectedReason = {
          reason: null
        };
        this.$v.$reset();
      }
    },
    validations: {
      rejectedReason: {
        reason: {
          required
        }
      }
    }
  };
</script>

<style></style>
