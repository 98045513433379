<template>
  <b-modal
    v-model="modal"
    :title="$t('label.displayPrioritySetting')"
    centered
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
    scrollable
    header-class="text-capitalize"
    body-class="max-h-325px"
  >
    <b-form-group class="mb-0">
      <template v-for="(i, index) in formFields">
        <div :key="index" class="px-2">
          <AppFormField
            v-model="$v.displayPriorityObj[i.model].$model"
            :state="validateObjState('displayPriorityObj', i.model)"
            :validationValue="$v.displayPriorityObj[i.model]"
            :title="$t(`label.${i.title}`)"
            :solid="true"
            :validators="i.validators"
            :formType="i.formType"
            :options="i.options"
            :disabled="
              !checkAccessRight(moduleName, [action.MOBILE_APPLICATION_UPDATE])
            "
          ></AppFormField>
          <div style="display: none">
            <!-- WARNING: I have no idea why this line is here, but removing it breaks everything -->
            {{ $v }}
          </div>
        </div>
      </template>
    </b-form-group>
    <template #modal-footer>
      <template
        v-if="checkAccessRight(moduleName, [action.MOBILE_APPLICATION_UPDATE])"
      >
        <b-button class="mr-2" variant="white" @click="actionDiscard">{{
          $t('label.discard')
        }}</b-button>

        <b-button variant="primary" @click="actionSaveChanges">{{
          $t('label.saveChanges')
        }}</b-button>
      </template>
      <b-button v-else variant="primary" @click="actionDiscard">{{
        $t('label.close')
      }}</b-button>
    </template>
  </b-modal>
</template>

<script>
  import AppFormField from '@/views/components/bases/form-field/AppFormField';
  import commonMixin from '@/core/mixins/common.mixin';
  import { MODULE, ACTION } from '@/core/constants';
  import { validationMixin } from 'vuelidate';
  import { /*required,*/ numeric } from 'vuelidate/lib/validators';
  import { i18nHelper, uiHelper } from '@/core/utils';

  export default {
    name: 'DisplayPriorityModal',
    mixins: [commonMixin, validationMixin],
    components: {
      AppFormField
    },
    props: {
      value: {
        type: Boolean,
        default: false,
        required: true
      },
      id: {
        type: Number,
        default: 0,
        required: true
      },
      category: {
        type: [Number, String],
        default: 1,
        required: true
      },
      actions: {
        type: Object,
        default: () => {},
        required: true
      },
      obj: {}
    },
    data: () => ({
      moduleName: MODULE.MOBILE_APPLICATION,
      action: ACTION,
      modal: false,
      displayPriorityObj: {
        priority: null
      },
      formFields: []
    }),
    computed: {
      updatePriorityComplete() {
        let obj = this.actions.updatePriority;
        return this.$store.state[obj.module][obj.state].complete;
      }
    },
    watch: {
      value() {
        if (this.value) {
          this.displayPriorityObj.priority =
            this.obj.priority == '-' ? null : this.obj.priority;
          this.initFormFields();
        }
      },
      modal() {
        this.modal
          ? uiHelper.freezeBodyOverflowY()
          : uiHelper.unFreezeBodyOverflowY();
      },
      updatePriorityComplete() {
        let obj = this.actions.updatePriority;
        let response = this.$store.state[obj.module][obj.state];

        let title = i18nHelper.getMessage('label.updatePriority');
        let initialStateAction = obj.initialStateAction;

        let successAction = () => {
          this.actionCloseModal();
          this.initialList();
        };
        let errorAction = () => {
          this.actionCloseModal();
        };

        if (response.complete) {
          this.actionUpdateRecordComplete(
            response,
            title,
            initialStateAction,
            successAction,
            errorAction
          );
        }
      }
    },
    mounted() {},
    methods: {
      initialList() {
        this.$parent.$refs['app-smart-list'].initAppSmartList();
      },
      actionDiscard() {
        this.actionCloseModal();
      },
      actionSaveChanges() {
        if (this.validationCheck('displayPriorityObj')) {
          this.updatePriority(this.id, this.displayPriorityObj);
        }
      },
      resetForm() {
        this.$v.$reset();
        this.formFields = [];
        this.displayPriorityObj = {
          priority: null
        };
      },
      actionCloseModal() {
        this.resetForm();
        this.input(false);
        this.modal = false;
      },
      input(value) {
        this.$emit('input', value);
      },
      updatePriority(id, data) {
        let obj = this.actions.updatePriority;
        let merchantType = this.obj ? this.obj.merchantType : null;
        let merchantId, creatorId;

        switch (merchantType) {
          case 'merchant':
            merchantId = id;
            break;
          case 'creator':
            creatorId = id;
            break;
        }

        data = {
          ...data,
          category: this.category,
          merchantId: merchantId,
          creatorId: creatorId
        };

        this.$store.dispatch(obj.action, {
          id,
          data
        });
        this.$v.$reset();
      },
      initFormFields() {
        this.modal = true;
        this.formFields.push({
          model: 'priority',
          title: 'priority',
          validators: ['required', 'numeric']
        });
      }
    },
    validations: {
      displayPriorityObj: {
        priority: {
          // required,
          numeric
        }
      }
    }
  };
</script>

<style lang="scss"></style>
