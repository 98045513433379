<template>
  <div class="w-100">
    <b-row v-if="editable" no-gutters class="d-flex align-content-center w-100">
      <b-col cols="9">
        <AppFormField
          v-model="$v.shareLink.$model"
          :state="validateState('shareLink')"
          :validationValue="$v.shareLink"
          :validators="['required']"
          :disabled="disabled"
          formType="textarea"
          noTitle
        >
        </AppFormField>
        <div style="display: none">
          <!-- WARNING: I have no idea why this line is here, but removing it breaks everything -->
          {{ $v }}
        </div>
      </b-col>

      <b-col cols="3">
        <i
          v-if="disabled"
          class="fa la-edit icon-xl ml-4 pt-3 cursor-pointer"
          @click="disabled = false"
        ></i>
        <div v-else class="ml-4">
          <i
            class="fa la-times icon-xl pt-3 cursor-pointer"
            @click="onReset"
          ></i>
          <i
            class="fa la-save icon-xl ml-4 pt-3 cursor-pointer"
            @click="onSave"
          ></i>
        </div>
      </b-col>
    </b-row>
    <div v-else>
      <a :href="value" target="_blank">
        {{ value || '-' }}
      </a>
    </div>
  </div>
</template>

<script>
  import AppFormField from '@/views/components/bases/form-field/AppFormField';
  import commonMixin from '@/core/mixins/common.mixin';
  import { validationMixin } from 'vuelidate';
  import { required } from 'vuelidate/lib/validators';

  export default {
    name: 'OrderDeliveryShareLinkFormField',
    mixins: [commonMixin, validationMixin],
    components: {
      AppFormField
    },
    props: {
      value: {
        type: [Number, String],
        default: null
      },
      editable: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      shareLink: null,
      disabled: true
    }),
    watch: {
      value() {
        this.shareLink = this.value;
      }
    },
    mounted() {
      this.shareLink = this.value;
    },
    methods: {
      onSave() {
        if (this.validateState('shareLink')) {
          this.$emit('save', this.shareLink);
          this.disabled = true;
          this.$v.$reset();
        }
      },
      onReset() {
        this.shareLink = this.value;
        this.disabled = true;
        this.$v.$reset();
      }
    },
    validations: {
      shareLink: {
        required
      }
    }
  };
</script>

<style lang="scss"></style>
