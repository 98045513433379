<template>
  <b-modal
    v-model="modal"
    :title="
      isNewRecord
        ? $t('label.newOperationHour')
        : $t('label.operationHourDetails')
    "
    centered
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
    scrollable
    header-class="text-capitalize"
    :body-class="isNewRecord ? '' : 'max-h-400px'"
  >
    <b-form-group class="mb-0">
      <template v-for="(i, index) in formFields">
        <div :key="index" class="px-2">
          <AppFormField
            v-if="i.field == 'form'"
            v-model="$v.platformOperationHourObj[i.model].$model"
            :state="validateObjState('platformOperationHourObj', i.model)"
            :validationValue="$v.platformOperationHourObj[i.model]"
            :title="$t(`label.${i.title}`)"
            :remark="i.remark"
            :solid="true"
            :validators="i.validators"
            :validationParams="i.validationParams"
            :formType="i.formType"
            :disabled="
              isNewRecord
                ? !checkAccessRight(moduleName, [
                    action.PLATFORM_OPERATION_HOUR_CREATE
                  ])
                : !checkAccessRight(moduleName, [
                    action.PLATFORM_OPERATION_HOUR_UPDATE
                  ])
            "
            :config="i.config"
          ></AppFormField>
          <div v-else-if="!isNewRecord" class="mb-3">
            <div class="font-weight-bolder text-capitalize mb-2">
              {{ $t(`label.${i.title}`) }}:
            </div>
            <div class="text-gray-600">
              {{ platformOperationHourObj[i.model] }}
            </div>
          </div>
          <div style="display: none">
            <!-- WARNING: I have no idea why this line is here, but removing it breaks everything -->
            {{ $v }}
          </div>
        </div>
        <div
          v-if="!isNewRecord && i.model == 'endTime'"
          :key="index + 'separator'"
          class="separator separator-solid my-6"
        ></div>
      </template>
    </b-form-group>
    <template #modal-footer>
      <b-button
        v-if="
          !isNewRecord &&
          !checkAccessRight(moduleName, [action.PLATFORM_OPERATION_HOUR_UPDATE])
        "
        variant="primary"
        @click="actionDiscard"
        >{{ $t('label.close') }}</b-button
      >
      <b-button v-else class="mr-2" variant="white" @click="actionDiscard">{{
        $t('label.discard')
      }}</b-button>
      <b-button
        v-if="
          isNewRecord &&
          checkAccessRight(moduleName, [action.PLATFORM_OPERATION_HOUR_CREATE])
        "
        variant="success"
        @click="actionSubmit"
        >{{ $t('label.submit') }}</b-button
      >
      <b-button
        v-if="
          !isNewRecord &&
          checkAccessRight(moduleName, [action.PLATFORM_OPERATION_HOUR_UPDATE])
        "
        variant="primary"
        @click="actionSaveChanges"
        >{{ $t('label.saveChanges') }}</b-button
      >
    </template>
  </b-modal>
</template>

<script>
  import AppFormField from '@/views/components/bases/form-field/AppFormField';
  import commonMixin from '@/core/mixins/common.mixin';
  import {
    MODULE,
    ACTION,
    startDate,
    endDate,
    startTime,
    endTime
  } from '@/core/constants';
  import { validationMixin } from 'vuelidate';
  import { required } from 'vuelidate/lib/validators';
  import { i18nHelper, uiHelper } from '@/core/utils';
  import {
    PLATFORM_OPERATION_HOUR_GET_PLATFORM_OPERATION_HOUR,
    PLATFORM_OPERATION_HOUR_CREATE_PLATFORM_OPERATION_HOUR,
    PLATFORM_OPERATION_HOUR_UPDATE_PLATFORM_OPERATION_HOUR,
    PLATFORM_OPERATION_HOUR_INITIAL_GET_PLATFORM_OPERATION_HOUR_STATE,
    PLATFORM_OPERATION_HOUR_INITIAL_CREATE_PLATFORM_OPERATION_HOUR_STATE,
    PLATFORM_OPERATION_HOUR_INITIAL_UPDATE_PLATFORM_OPERATION_HOUR_STATE
  } from '@/core/store/platform-operation-hour.module';

  export default {
    name: 'PlatformOperationHourModal',
    mixins: [commonMixin, validationMixin],
    components: {
      AppFormField
    },
    props: {
      value: {
        type: Boolean,
        default: false,
        required: true
      },
      id: {
        type: Number,
        default: 0,
        required: true
      },
      isNewRecord: {
        type: Boolean,
        default: false,
        required: true
      }
    },
    data: () => ({
      moduleName: MODULE.PLATFORM_OPERATION_HOUR,
      action: ACTION,
      modal: false,
      platformOperationHourObj: {
        title: '',
        startDate: '',
        endDate: '',
        startTime: '',
        endTime: '',
        createdBy: '',
        createdAt: '',
        updatedAt: ''
      },
      formFields: []
    }),
    computed: {
      platformOperationHourComplete() {
        return this.$store.state.platformOperationHour.platformOperationHour
          .complete;
      },
      createPlatformOperationHourComplete() {
        return this.$store.state.platformOperationHour
          .createPlatformOperationHour.complete;
      },
      updatePlatformOperationHourComplete() {
        return this.$store.state.platformOperationHour
          .updatePlatformOperationHour.complete;
      }
    },
    watch: {
      value() {
        if (this.value) {
          if (this.isNewRecord) {
            this.modal = true;
          } else {
            this.getPlatformOperationHour(this.id);
          }
        }
      },
      modal() {
        this.modal
          ? uiHelper.freezeBodyOverflowY()
          : uiHelper.unFreezeBodyOverflowY();
      },
      platformOperationHourComplete() {
        let response =
          this.$store.state.platformOperationHour.platformOperationHour;
        let title = i18nHelper.getMessage('label.getPlatformOperationHour');
        let initialStateAction =
          PLATFORM_OPERATION_HOUR_INITIAL_GET_PLATFORM_OPERATION_HOUR_STATE;
        let successAction = (response) => {
          this.getPlatformOperationHourCompleteActions(response);
        };

        if (response.complete) {
          this.actionReadRecordComplete(
            response,
            title,
            initialStateAction,
            successAction
          );
        }
      },
      createPlatformOperationHourComplete() {
        let response =
          this.$store.state.platformOperationHour.createPlatformOperationHour;
        let title = i18nHelper.getMessage('label.newPlatformOperationHour');
        let initialStateAction =
          PLATFORM_OPERATION_HOUR_INITIAL_CREATE_PLATFORM_OPERATION_HOUR_STATE;
        let successAction = () => {
          this.actionCloseModal();
          this.initialList();
        };
        let errorAction = () => {
          this.actionCloseModal();
        };

        if (response.complete) {
          this.actionCreateRecordComplete(
            response,
            title,
            initialStateAction,
            successAction,
            errorAction
          );
        }
      },
      updatePlatformOperationHourComplete() {
        let response =
          this.$store.state.platformOperationHour.updatePlatformOperationHour;
        let title = i18nHelper.getMessage('label.updatePlatformOperationHour');
        let initialStateAction =
          PLATFORM_OPERATION_HOUR_INITIAL_UPDATE_PLATFORM_OPERATION_HOUR_STATE;
        let successAction = () => {
          this.actionCloseModal();
          this.initialList();
        };
        let errorAction = () => {
          this.actionCloseModal();
        };

        if (response.complete) {
          this.actionUpdateRecordComplete(
            response,
            title,
            initialStateAction,
            successAction,
            errorAction
          );
        }
      }
    },
    mounted() {
      this.initFormFields();
    },
    methods: {
      initialList() {
        this.$parent.$refs.appSmartList.initAppSmartList();
      },
      getPlatformOperationHourCompleteActions(response) {
        this.platformOperationHourObj = response.data;
        this.modal = true;
      },
      actionDiscard() {
        this.actionCloseModal();
      },
      actionSaveChanges() {
        let obj = this.platformOperationHourObj;
        let startDate = new Date(
          new Date(obj.startDate).setDate(new Date(obj.startDate).getDate() + 1)
        );
        let endDate = obj.endDate
          ? new Date(
              new Date(obj.endDate).setDate(new Date(obj.endDate).getDate() + 1)
            )
          : null;

        if (this.validationCheck('platformOperationHourObj')) {
          let data = {
            startDate: new Date(obj.startDate).getTime(),
            endDate: obj.endDate ? new Date(obj.endDate).getTime() : null,
            startTime: new Date(
              new Date(startDate).toISOString().substr(0, 10) +
                ' ' +
                obj.startTime
            ).getTime(),
            endTime: obj.endTime
              ? new Date(
                  new Date(endDate ? endDate : startDate)
                    .toISOString()
                    .substr(0, 10) +
                    ' ' +
                    obj.endTime
                ).getTime()
              : null
          };

          this.updatePlatformOperationHour(this.id, data);
        }
      },
      actionSubmit() {
        let obj = this.platformOperationHourObj;
        let startDate = new Date(
          new Date(obj.startDate).setDate(new Date(obj.startDate).getDate() + 1)
        );
        let endDate = obj.endDate
          ? new Date(
              new Date(obj.endDate).setDate(new Date(obj.endDate).getDate() + 1)
            )
          : null;

        if (this.validationCheck('platformOperationHourObj')) {
          let data = {
            startDate: new Date(obj.startDate).getTime(),
            endDate: obj.endDate ? new Date(obj.endDate).getTime() : null,
            startTime: new Date(
              new Date(startDate).toISOString().substr(0, 10) +
                ' ' +
                obj.startTime
            ).getTime(),
            endTime: obj.endTime
              ? new Date(
                  new Date(endDate ? endDate : startDate)
                    .toISOString()
                    .substr(0, 10) +
                    ' ' +
                    obj.endTime
                ).getTime()
              : null
          };

          this.createPlatformOperationHour(data);
        }
      },
      resetForm() {
        this.platformOperationHourObj = {
          startDate: '',
          endDate: '',
          startTime: '',
          endTime: '',
          createdBy: '',
          createdAt: '',
          updatedAt: ''
        };
        this.$v.$reset();
      },
      actionCloseModal() {
        this.resetForm();
        this.input(false);
        this.modal = false;
      },
      input(value) {
        this.$emit('input', value);
      },
      getPlatformOperationHour(id) {
        this.$store.dispatch(
          PLATFORM_OPERATION_HOUR_GET_PLATFORM_OPERATION_HOUR,
          {
            id
          }
        );
      },
      createPlatformOperationHour(data) {
        this.$store.dispatch(
          PLATFORM_OPERATION_HOUR_CREATE_PLATFORM_OPERATION_HOUR,
          {
            data
          }
        );
      },
      updatePlatformOperationHour(id, data) {
        this.$store.dispatch(
          PLATFORM_OPERATION_HOUR_UPDATE_PLATFORM_OPERATION_HOUR,
          {
            id,
            data
          }
        );
      },
      initFormFields() {
        this.formFields.push(
          {
            field: 'form',
            model: 'startDate',
            title: 'startDate',
            validators: ['required', 'startDate'],
            formType: 'date'
          },
          {
            field: 'form',
            model: 'endDate',
            title: 'endDate',
            remark: i18nHelper.getMessage('label.optional'),
            validators: ['endDate'],
            formType: 'date'
          },
          {
            field: 'form',
            model: 'startTime',
            title: 'startTime',
            validators: ['required', 'startTime'],
            formType: 'hourSelection',
            config: {
              isStartHour: true
            }
          },
          {
            field: 'form',
            model: 'endTime',
            title: 'endTime',
            validators: ['required', 'endTime'],
            formType: 'hourSelection',
            config: {
              isEndHour: true
            }
          },
          {
            model: 'createdBy',
            title: 'createdBy'
          },
          {
            model: 'createdAt',
            title: 'createdAt'
          },
          {
            model: 'updatedAt',
            title: 'updatedAt'
          }
        );
      }
    },
    validations() {
      return {
        platformOperationHourObj: {
          startDate: {
            required,
            startDate: startDate(this.platformOperationHourObj.endDate, true)
          },
          endDate: {
            endDate: endDate(this.platformOperationHourObj.startDate, true)
          },
          startTime: {
            required,
            startTime: startTime(this.platformOperationHourObj.endTime, true)
          },
          endTime: {
            required,
            endTime: endTime(this.platformOperationHourObj.startTime)
          }
        }
      };
    }
  };
</script>

<style lang="scss"></style>
