<template>
  <b-modal
    v-model="show"
    :title="title"
    centered
    no-close-on-backdrop
    no-close-on-esc
    hide-header-close
    header-class="text-capitalize"
  >
    <template>
      <template v-for="(i, messages) in messages">
        <p :key="messages" class="my-3">{{ i }}</p>
      </template>
    </template>

    <template v-if="this.dropbox != ''">
      <select
        class="my-1 p-2 w-100 form-control form-control-solid"
        name="reason"
        style="appearance: revert"
        id="selectReason"
      >
        <template v-for="(i, dropbox) in dropbox">
          <option class="w-50" :key="dropbox" :value="i.text">
            {{ i.text }}
          </option>
        </template>
      </select>
    </template>

    <template v-if="textbox">
      <template>
        <p class="my-2">{{ $t(`label.remark`) }}</p>
        <input
          class="my-1 w-100 p-2 form-control form-control-solid"
          type="text"
          value=""
          id="txtReason"
        />
      </template>
    </template>

    <template #modal-footer>
      <template v-for="(i, buttons) in buttons">
        <b-button :key="buttons" :variant="i.variant" @click="i.action">
          {{ i.text }}
        </b-button>
      </template>
    </template>
  </b-modal>
</template>

<script>
  import { uiHelper } from '@/core/utils';

  export default {
    name: 'AppModalInfo',
    props: {
      show: {
        type: Boolean,
        required: true
      },
      title: {
        type: String,
        required: false
      },
      messages: {
        type: Array,
        required: false
      },
      buttons: {
        type: Array,
        default: () => {
          return [
            {
              text: 'Ok',
              variant: 'primary',
              action: () => {}
            }
          ];
        },
        required: false
      },
      textbox: {
        // type: Array,
        required: false
      },
      dropbox: {
        type: Array,
        required: false,
        default: () => {
          return [];
        }
      }
    },
    computed: {},
    watch: {
      show() {
        this.show
          ? uiHelper.freezeBodyOverflowY()
          : uiHelper.unFreezeBodyOverflowY();
      }
    },
    created() {},
    methods: {}
  };
</script>

<style lang="scss"></style>
