<template>
  <div class="d-flex">
    <div class="w-70 pr-2" v-if="isDate">
      <VueCtkDateTimePicker
        v-model="datetime"
        id="rangeDatePicker"
        format="YYYY-MM-DD"
        formatted="l"
        label="Select Date For Export"
        color="var(--primary)"
        button-color="var(--primary)"
        only-date
        range
        @input="input"
        @is-hidden="close"
      />
      <span
        variant="danger"
        class="label label-md label-inline label-light-danger"
        v-if="errorMsg"
        >{{ errorMsg }}</span
      >
    </div>
    <b-button
      variant="success"
      class="mr-2 text-capitalize"
      :disabled="exportExcelObj.disabled"
      @click="onClickExportExcel"
    >
      <i class="fas fa-file-export"></i>
      {{ $t('label.exportExcel') }}</b-button
    >
  </div>
</template>

<script>
  import { apiService } from '@/core/services';
  import commonMixin from '@/core/mixins/common.mixin';
  import {
    LOADING_OPEN_LOADING,
    LOADING_CLOSE_LOADING
  } from '@/core/store/loading.module';
  import { apiHelper } from '@/core/utils';
  import { i18nHelper } from '@/core/utils';

  export default {
    name: 'AppExportExcel',
    mixins: [commonMixin],
    props: {
      exportExcelObj: {
        type: Object,
        default: () => ({})
      },
      isDate: {
        Type: Boolean,
        default: false
      }
    },
    data: () => ({
      dateStart: null,
      dateEnd: null,
      datetime: '',
      errorMsg: ''
    }),
    methods: {
      close() {
        if (this.dateStart != null) {
          if (this.dateEnd == null) {
            this.dateEnd = this.dateStart;
          }
          this.datetime = { start: this.dateStart, end: this.dateEnd };
        }
      },
      input(value) {
        if (value != null) {
          this.dateStart = value.start;
          this.dateEnd = value.end;
          this.errorMsg = '';
        } else {
          this.dateStart = null;
          this.dateEnd = null;
        }
      },
      onClickExportExcel() {
        this.getExportExcel();
      },
      getExportExcel() {
        let fileName = this.exportExcelObj.fileName;
        let api = this.exportExcelObj.api;
        let title = this.exportExcelObj.title;
        let requestOptions = {};

        if (this.dateStart != null && this.dateEnd != null) {
          let date = this.dateStart + ',' + this.dateEnd;
          requestOptions = {
            created_at_from: apiHelper.dateTimeStart(date),
            created_at_to: apiHelper.dateTimeEnd(date)
          };
        }

        this.$store.dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
        apiService()
          .get(`/${api}`, {
            responseType: 'blob',
            params: requestOptions
          })
          .then((response) => {
            this.disabled = false;
            this.$store.dispatch(`${LOADING_CLOSE_LOADING}`, null, {
              root: true
            });
            if (response.type == 'application/json') {
              apiService()
                .get(`/${api}`, {})
                .then((response) => {
                  title = i18nHelper.getMessage(`label.${title}`);
                  response = {
                    ...response,
                    message: [response.message]
                  };
                  this.openErrorLayoutAppModalInfo(title, response);
                });
            } else {
              const url = window.URL.createObjectURL(new Blob([response]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', `${fileName}.xlsx`);
              document.body.appendChild(link);
              link.click();
            }
          })
          .catch((error) => {
            this.$store.dispatch(`${LOADING_CLOSE_LOADING}`, null, {
              root: true
            });
            console.log(error);
          });
      }
    }
  };
</script>

<style></style>
