import { apiService } from '@/core/services';
// import { apiHelper } from '@/core/utils';

export const CONFIG_GOOGLE_CALENDAR = {
  getCalendarRefresh,
  getCalendarAip
};

const URL = {
  ADMIN: 'admin',
  PUBLIC_HOLIDAY: 'public-holidays'
};

function getCalendarAip() {
  return apiService().get(`/${URL.ADMIN}/${URL.PUBLIC_HOLIDAY}`);
}

function getCalendarRefresh() {
  return apiService().post(`/${URL.ADMIN}/${URL.PUBLIC_HOLIDAY}`);
}
