<template>
  <b-card no-body data-style="custom">
    <!--begin: Wizard-->
    <div
      class="wizard wizard-2"
      id="pointWizard"
      data-wizard-state="step-first"
      data-wizard-clickable="true"
    >
      <!--begin: Wizard Nav -->
      <div class="wizard-nav border-right py-8 px-8 py-lg-20 px-lg-10">
        <div class="wizard-steps">
          <!--begin: Wizard Steps 1 -->
          <div
            class="wizard-step"
            data-wizard-type="step"
            data-wizard-state="current"
          >
            <div class="wizard-wrapper">
              <div class="wizard-icon">
                <span class="svg-icon svg-icon-2x">
                  <inline-svg
                    src="/media/svg/icons/Communication/Clipboard-list.svg"
                  />
                </span>
              </div>
              <div class="wizard-label text-capitalize">
                <h3 class="wizard-title">
                  {{ $t('label.point') }}
                </h3>
                <div class="wizard-desc">
                  {{ $t('label.fillInInfoAndAction') }}
                </div>
              </div>
            </div>
          </div>
          <!--end: Wizard Steps 1 -->
<!--          &lt;!&ndash;begin: Wizard Steps 2 &ndash;&gt;-->
<!--          <div-->
<!--            v-if="!isCreate"-->
<!--            class="wizard-step"-->
<!--            data-wizard-type="step"-->
<!--            data-wizard-state=""-->
<!--          >-->
<!--            <div class="wizard-wrapper">-->
<!--              <div class="wizard-icon">-->
<!--                <span class="svg-icon svg-icon-2x">-->
<!--                  <inline-svg-->
<!--                    src="/media/svg/icons/Communication/Clipboard-check.svg"-->
<!--                  />-->
<!--                </span>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
          <!--end: Wizard Steps 2 -->
          <!--begin: Wizard Steps 3 -->
          <div class="wizard-step" data-wizard-type="step" data-wizard-state="">
            <div
              class="wizard-wrapper"
              v-if="
                !isCreate && pointObj.applyTo == pointApplyTo.SELECTED_USER
              "
            >
              <div class="wizard-icon">
                <span class="svg-icon svg-icon-2x">
                  <inline-svg
                    src="/media/svg/icons/Communication/Clipboard-check.svg"
                  />
                </span>
              </div>
              <div
                class="wizard-label"
                :class="{
                  'text-primary cursor-pointer': true
                }"
              >
                <h3 class="wizard-title text-capitalize">
                  {{
                    `${pointObj.totalSpecific} ` + $t(`label.assignPoints`)
                  }}
                </h3>
                <div class="wizard-desc">
                  {{ $t('label.leaveItEmptyIfApplyToAllUsers') }}
                </div>
              </div>
            </div>
          </div>
          <!--end: Wizard Steps 3 -->
        </div>
      </div>
      <!--end: Wizard Nav -->

      <!--begin: Wizard Body -->
      <div class="wizard-body py-6 px-8" style="overflow-x: auto; width: 100%">
        <!--begin: Wizard Form-->
        <b-row>
          <b-col offset-xl="2" xl="8">
            <!--begin: Wizard Step 1-->
            <div data-wizard-type="step-content" data-wizard-state="current">
              <PointForm
                ref="PointForm"
                :id="id"
                :pointObj="pointObj"
                :isCreate="isCreate"
              ></PointForm>
            </div>
            <!--end: Wizard Step 1-->
            <!--begin: Wizard Step 2-->
<!--            <div-->
<!--              v-if="!isCreate"-->
<!--              class="pb-5"-->
<!--              data-wizard-type="step-content"-->
<!--              data-wizard-state=""-->
<!--            >-->
<!--              &lt;!&ndash; begin: Point Claimed Table &ndash;&gt;-->
<!--              <PointClaimedList-->
<!--                :id="id"-->
<!--                :pointObj="pointObj"-->
<!--              ></PointClaimedList>-->
<!--              &lt;!&ndash; end: Point Claimed Table &ndash;&gt;-->
<!--            </div>-->
            <!--end: Wizard Step 2-->
            <!--begin: Wizard Step 3-->
            <div
              v-if="!isCreate"
              class="pb-5"
              data-wizard-type="step-content"
              data-wizard-state=""
            >
              <!-- begin: Point Specific User Table -->
              <PointSpecificUserList
                v-if="!isCreate"
                :id="id"
                :pointObj="pointObj"
              ></PointSpecificUserList>
              <!-- end: Point Specific User Table -->
            </div>
            <!--end: Wizard Step 3-->
          </b-col>
          <!--end: Wizard-->
        </b-row>
      </div>
      <!--end: Wizard Body -->
    </div>
    <!--end: Wizard-->
  </b-card>
</template>

<script>
  import PointForm from '@/views/components/point/PointForm';
  //import PointClaimedList from '@/views/components/point/PointClaimedList';
  import PointSpecificUserList from '@/views/components/point/PointSpecificUserList';
  import KTUtil from '@/assets/js/components/util';
  import KTWizard from '@/assets/js/components/wizard';
  import constantsMixin from '@/core/mixins/constants.mixin';
  import dateTimeFormat from '@/core/filters/dateTimeFormat.filter';
  import commonMixin from '@/core/mixins/common.mixin';
  import { GLOBAL, MODULE, ACTION, ROUTE_NAME } from '@/core/constants';
  import { PointApplyTo } from '@/core/constants/enums';
  import { i18nHelper } from '@/core/utils';
  // import { listService } from '@/core/services';
  import {
    POINT_GET_POINT,
    POINT_CREATE_POINT,
    POINT_UPDATE_POINT,
    POINT_INITIAL_GET_POINT_STATE,
    POINT_INITIAL_CREATE_POINT_STATE,
    POINT_INITIAL_UPDATE_POINT_STATE
  } from '@/core/store/point.module';

  export default {
    name: 'PointDetailsTemplate',
    mixins: [commonMixin, constantsMixin],
    components: {
      PointForm,
      //PointClaimedList,
      PointSpecificUserList
    },
    props: {
      isCreate: {
        Type: Boolean,
        defualt: false
      }
    },
    data: () => ({
      moduleName: MODULE.POINT,
      permissionAction: ACTION,
      global: GLOBAL,
      routeName: ROUTE_NAME,
      constWizard: null,
      bannerFileSize: 5,
      pointApplyTo: PointApplyTo,
      pointObj: {
        applyTo: null,
        selectedCreator: null,
        selectedDineMerchant: null,
        selectedBranch: null,
        //products: null,
        pointSource: null,
        //pointType: null,
        pointValidityDay: null,
        eventType: [],
        quantity: 0,
        eventName: null,
        earnType: {
          type: null,
          value: null
        },
        minimumSpending: null,
        title: null,
        description: null,
        //pointCode: null,
        availableFrom: null,
        availableTo: null,
        stackable: false,
        banner: null,
        thumbnail: null,
        redemptionPeriodFrom: null,
        redemptionPeriodTo: null,
        tnc: null,
        isActive: 1
      },
      formFields: [],
      isLoading: false,
      selectedId: null,
      selectedType: null,
      disabledCreator: true,
      disabledMerchant: true,
      disabledBranch: true,
      disabledCookx: false,
      userContactNumber: null,
      getMerchantCreatorId: [],
      getDineInMerchantId: [],
      getMerchantOrCreatorBranch: [],
      isMultiple: false
    }),
    computed: {
      id() {
        return this.$route.params.id;
      },
      pointComplete() {
        return this.$store.state.point.point.complete;
      },
      createPointComplete() {
        return this.$store.state.point.createPoint.complete;
      },
      updatePointComplete() {
        return this.$store.state.point.updatePoint.complete;
      },
      checkUsagePeriodFrom() {
        let now = dateTimeFormat(new Date());
        let UsagePeriodFrom = this.pointObj.usagePeriodFrom;
        if (
          UsagePeriodFrom > now ||
          UsagePeriodFrom == '-' ||
          UsagePeriodFrom == null
        ) {
          return false;
        } else {
          return true;
        }
      }
    },
    watch: {
      async pointComplete() {
        let response = this.$store.state.point.point;
        if (response.complete) {
          this.pointObj = response.data;
          // await this.updateWizard();
          this.pointCompleteAction(response, 'get');
        }
      },
      createPointComplete() {
        let response = this.$store.state.point.createPoint;
        if (response.complete) {
          this.pointCompleteAction(response, 'create');
        }
      },
      updatePointComplete() {
        let response = this.$store.state.point.updatePoint;
        if (response.complete) {
          this.pointCompleteAction(response, 'update');
        }
      }
    },
    mounted() {
      this.initialMerchhantPoint();
    },
    methods: {
      async pointCompleteAction(response, actionType) {
        let title = i18nHelper.getMessage('label.point');
        let messages = response.message;
        let buttons = [];
        switch (actionType) {
          case 'get':
            this.initialGetPointState();
            await this.$refs.PointForm.getPointCompleteActions(response);
            break;
          case 'create':
            this.initialCreatePointState();
            break;
          case 'update':
            this.initialUpdatePointState();
            break;
        }
        if (response.code == 0) {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();

              switch (actionType) {
                case 'create':
                  this.$router.push({
                    name: ROUTE_NAME.POINT_DETAILS,
                    params: { id: response.data.id }
                  });
                  break;
                default:
                  this.getPoint(this.id);
              }
            }
          });
        } else {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();
            }
          });
        }
        if (messages) this.openLayoutAppModalInfo(title, messages, buttons);
      },
      initWizard() {
        // Initialize form wizard
        let vm = this;

        const wizard = new KTWizard('pointWizard', {
          startStep: 1, // initial active step number
          clickableSteps: true // allow step clicking
        });

        vm.constWizard = wizard;
        // wizard.on('submit', () => {
        //   vm.validationCheckBeforeSubmit();
        // });

        //Change event
        wizard.on('change', function (/*wizardObj*/) {
          // if (wizard.currentStep == 1) {
          //   let v1 = vm.validationCheck('pointObj');

          //   if (!v1) {
          //     wizard.stop();
          //   }
          // }

          KTUtil.scrollTop();
        });
      },
      updateWizard() {
        let vm = this;
        const wizard = new KTWizard('pointWizard', {
          startStep: 1, // initial active step number
          clickableSteps: true // allow step clicking
        });
        vm.constWizard = wizard;
        vm.constWizard.updateConstruct();
        vm.constWizard.init();

        this.initWizard();
      },
      goToWizard(value) {
        let vm = this;
        const wizard = new KTWizard('pointWizard', {
          startStep: 1, // initial active step number
          clickableSteps: true // allow step clicking
        });

        vm.constWizard = wizard;
        vm.constWizard.goTo(value);
      },
      // Reset Form Action : Start
      resetForm() {
        this.formFields = [];
        this.pointObj = {
          applyTo: null,
          selectedCreator: null,
          selectedDineMerchant: null,
          selectedBranch: null,
          //products: null,
          pointSource: null,
          //pointType: null,
          pointValidityDay: null,
          eventType: [],
          quantity: 0,
          eventName: null,
          earnType: {
            type: null,
            value: null
          },
          minimumSpending: null,
          title: null,
          description: null,
          //pointCode: null,
          availableFrom: null,
          availableTo: null,
          stackable: false,
          banner: null,
          thumbnail: null,
          redemptionPeriodFrom: null,
          redemptionPeriodTo: null,
          tnc: null,
          isActive: 1
        };
        this.disabledCreator = true;
        this.disabledMerchant = true;
        this.disabledBranch = true;
        this.disabledCookx = false;
        this.selectedType = null;
        this.isMultiple = false;
      },
      // Reset Form End : End
      // Processing Front Data Action : Start
      submitPoint() {
        let formData = new FormData();
        let data = this.pointObj;
        let selectedBranch = data.selectedBranch;
        //let products = data.products;

        let availableFrom = new Date(data.availableFrom);
        let availableTo = new Date(data.availableTo);
        let redemptionPeriodFrom = new Date(data.redemptionPeriodFrom);
        let redemptionPeriodTo = new Date(data.redemptionPeriodTo);

        availableFrom = availableFrom.getTime();
        availableTo = availableTo.getTime();
        redemptionPeriodFrom = redemptionPeriodFrom.getTime();
        redemptionPeriodTo = redemptionPeriodTo.getTime();

        formData.append('apply_to', data.applyTo);
        formData.append('point_source', data.pointSource);
        if (data.selectedDineMerchant) {
          data.selectedDineMerchant.forEach((item) =>
            formData.append('merchant_ids[]', item)
          );
        } else {
          formData.append('merchant_ids[]', '');
        }
        if (data.selectedCreator) {
          data.selectedCreator.forEach((item) =>
            formData.append('creator_ids[]', item)
          );
        } else {
          formData.append('creator_ids[]', '');
        }

        // if (products) {
        //   products.forEach((item) =>
        //     formData.append('product_ids[]', item)
        //   );
        // }

        if (selectedBranch) {
          selectedBranch.forEach((item) =>
              formData.append('branch_ids[]', item)
          );
        } else {
          formData.append('branch_ids[]', '');
        }
        formData.append('grouping_name', data.groupName);
        formData.append(
          'available_from',
          data.availableFrom == '-' || availableFrom == ''
            ? ''
            : availableFrom
        );
        formData.append(
          'available_to',
          data.availableTo == '-' ? '' : availableTo
        );
        formData.append('quantity', data.quantity);
        formData.append('point_validity_day', data.pointValidityDay);
        formData.append('point_type', 'none'); // temporary value
        formData.append('event_type', data.eventType && Array.isArray(data.eventType) ? data.eventType.join(',') : '');
        formData.append('event_name', data.eventName ? data.eventName : '');
        //formData.append('point_code', data.pointCode.toUpperCase());
        formData.append(
            'earn_type',
            data.earnType ? data.earnType.type : null
        );
        formData.append(
          'earn_value',
          data.earnType ? data.earnType.value : null
        );
        formData.append('minimum_spending', data.minimumSpending);
        formData.append(
          'banner',
          !(data.banner instanceof Blob) ? '' : data.banner
        );
        formData.append(
            'thumbnail',
            !(data.thumbnail instanceof Blob) ? '' : data.thumbnail
        );

        formData.append('title', data.title);
        formData.append('description', data.description);
        formData.append(
          'redemption_period_from',
          data.redemptionPeriodFrom == '' ? '' : redemptionPeriodFrom
        );
        formData.append('redemption_period_to', redemptionPeriodTo);
        //formData.append('stackable', data.stackable ? 1 : 0);
        formData.append('stackable', 1); // Always true
        formData.append('is_active', data.isActive ? 1 : 0);
        formData.append('tnc', data.tnc);

        this.isCreate
          ? this.createPoint(formData)
          : this.updatePoint(this.id, formData);
      },
      // Processing Front Data Action : End
      getPoint(id) {
        this.$store.dispatch(POINT_GET_POINT, { id });
      },
      createPoint(formData) {
        this.$store.dispatch(POINT_CREATE_POINT, {
          formData
        });
      },
      updatePoint(id, formData) {
        this.$store.dispatch(POINT_UPDATE_POINT, {
          id,
          formData
        });
      },
      initialGetPointState() {
        this.$store.dispatch(POINT_INITIAL_GET_POINT_STATE);
      },
      initialCreatePointState() {
        this.$store.dispatch(POINT_INITIAL_CREATE_POINT_STATE);
      },
      initialUpdatePointState() {
        this.$store.dispatch(POINT_INITIAL_UPDATE_POINT_STATE);
      },
      initialMerchhantPoint() {
        let breadcrumbData = {
          breadcrumbs: [
            {
              title: i18nHelper.getMessage('label.point'),
              route: { name: ROUTE_NAME.POINT }
            },
            {
              title: i18nHelper.getMessage('label.pointList'),
              route: { name: ROUTE_NAME.POINT_LIST }
            },
            {
              title: i18nHelper.getMessage(
                `label.${this.isCreate ? 'pointCreate' : 'pointDetails'}`
              )
            }
          ],
          actions: [
            {
              text: i18nHelper.getMessage('label.back'),
              function: () => {
                this.$router.go(-1);
              }
            }
          ]
        };
        this.initWizard();
        this.initBreadCrumb(breadcrumbData);
        if (!this.isCreate) this.getPoint(this.id);
      }
    }
  };
</script>

<style lang="scss">
  @import '@/assets/sass/pages/wizard/wizard-2.scss';
</style>
