<template>
  <div>
    <AppSmartList
      ref="app-smart-list"
      :breadcrumbData="breadcrumbData"
      :accessRights="accessRights"
      :filters="filters"
      :actions="actions"
      :tableHeaders="headers"
      :newRecord="newRecord"
    >
    </AppSmartList>
  </div>
</template>

<script>
  import AppSmartList from '@/views/components/bases/smart/AppSmartList.vue';
  import recipePageMixin from '@/core/mixins/recipe-page.mixin';
  import { listService } from '@/core/services';
  import { i18nHelper } from '@/core/utils';
  import {
    ROUTE_NAME,
    MODULE,
    ACTION,
    APP_COMPONENT_NAME,
    SMART_FORM_FIELD_TYPE
  } from '@/core/constants';
  import { RecipePageStatus } from '@/core/constants/enums';
  import {
    RECIPE_PAGE_GET_RECIPE_PAGES,
    RECIPE_PAGE_INITIAL_GET_RECIPE_PAGES_STATE
  } from '@/core/store/recipe-page.module';

  export default {
    name: 'RecipePageList',
    mixins: [recipePageMixin],
    components: {
      AppSmartList
    },
    props: {
      category: {
        type: String,
        default: ''
      }
    },
    data: () => ({
      accessRights: {
        moduleName: MODULE.RECIPE_PAGE,
        actions: {
          read: ACTION.RECIPE_PAGE_READ,
          update: ACTION.RECIPE_PAGE_UPDATE,
          create: ACTION.RECIPE_PAGE_CREATE
        }
      },
      filters: [],
      actions: {
        viewList: {
          module: 'recipePage',
          state: 'recipePages',
          action: RECIPE_PAGE_GET_RECIPE_PAGES,
          initialStateAction: RECIPE_PAGE_INITIAL_GET_RECIPE_PAGES_STATE,
          title: i18nHelper.getMessage('label.getRecipePage')
        }
      },
      headers: [
        {
          key: 'no',
          name: 'no',
          active: true,
          sortable: true
        },
        {
          key: 'id',
          name: 'id',
          active: true,
          sortable: true
        },
        {
          key: 'recipeName',
          name: 'recipeName',
          layout: APP_COMPONENT_NAME.APP_FEATURE_DETAILS_RELATED_BUTTON,
          config: {
            to: ROUTE_NAME.EXPERIENCE_COOKING_DETAILS
          },
          active: true,
          sortable: true,
          class: 'min-w-120px'
        },
        {
          key: 'createdBy',
          name: 'createdBy',
          layout: APP_COMPONENT_NAME.APP_FEATURE_DETAILS_RELATED_BUTTON,
          config: {
            to: ROUTE_NAME.PROFILE_DETAILS_DETAILS
          },
          active: true,
          sortable: true
        },
        {
          key: 'status',
          name: 'status',
          active: true,
          sortable: true,
          layout: APP_COMPONENT_NAME.APP_STATUS_LABEL
        },
        {
          key: 'recipeStatus',
          name: 'recipeStatus',
          active: true,
          sortable: true,
          layout: APP_COMPONENT_NAME.APP_ENUM_LABEL,
          config: {
            enumKey: 'enumRecipePageStatus',
            statusColor: (value) => {
              let color = {
                [RecipePageStatus.PENDING]: 'primary',
                [RecipePageStatus.VERIFIED]: 'warning',
                [RecipePageStatus.DELETED]: 'warning'
              };

              return color[value];
            }
          }
        },
        {
          key: 'ecoPackOption',
          name: 'ecoPackOption',
          active: true,
          sortable: true
        },
        {
          key: 'cookingLevel',
          name: 'cookingLevel',
          active: true,
          sortable: true
        },
        {
          key: 'priority',
          name: 'priority',
          active: true,
          sortable: true
        },
        {
          key: 'topRecipe',
          name: 'topRecipe',
          active: true,
          sortable: true,
          layout: APP_COMPONENT_NAME.APP_CHECK_CIRCLE
        },
        {
          key: 'actions',
          name: 'actions',
          active: true,
          action: true,
          layout: APP_COMPONENT_NAME.APP_TABLE_ACTIONS,
          layoutActions: {
            viewDetailsRouteName: ROUTE_NAME
          }
        }
      ],
      newRecord: {
        show: true,
        buttonTitle: i18nHelper.getMessage('label.addRecipe'),
        routeName: ROUTE_NAME.RECIPE_PAGE_CREATE
      }
    }),
    created() {
      this.initialRecipePageList();
    },
    computed: {},
    watch: {},
    methods: {
      initialRecipePageList() {
        //  Recipe Page Re-write Status, coz BE label active/inactive
        let dynamicList = [
          {
            description: i18nHelper.getMessage('label.pending'),
            value: RecipePageStatus.PENDING
          },
          {
            description: i18nHelper.getMessage('label.verified'),
            value: RecipePageStatus.VERIFIED
          },
          {
            description: i18nHelper.getMessage('label.deleted'),
            value: RecipePageStatus.DELETED
          }
        ];
        this.breadcrumbData = {
          breadcrumbs: [
            {
              title: i18nHelper.getMessage('label.recipePage'),
              route: { name: this.getRecipePageRouteName() }
            },
            {
              title: i18nHelper.getMessage(`label.${this.category}List`)
            }
          ]
        };
        this.filters = [
          {
            label: 'category',
            key: 'category',
            value: this.getRecipeCategoryType(this.category),
            isHidden: true
          },
          {
            label: 'recipeName',
            key: 'recipeName',
            value: ''
          },
          {
            label: 'id',
            key: 'id',
            value: ''
          },
          {
            label: 'status',
            key: 'status',
            value: '',
            options: listService.getStatusList(),
            optionLabel: 'text',
            type: SMART_FORM_FIELD_TYPE.SELECT
          },
          {
            label: 'recipeStatus',
            key: 'recipeStatus',
            value: '',
            options: listService.getDynamicList(dynamicList),
            optionLabel: 'text',
            type: SMART_FORM_FIELD_TYPE.SELECT
          }
        ];

        this.newRecord.routeName = this.getRecipePageNewRouteName(
          this.category
        );
        this.headers.find(
          (x) => x.key == 'actions'
        ).layoutActions.viewDetailsRouteName = this.getRecipePageDetailsRouteName(
          this.category
        );
      }
    }
  };
</script>

<style></style>
