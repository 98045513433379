<template>
  <BannerNew :type="bannerType" :bannerDimension="bannerDimension"></BannerNew>
</template>

<script>
  import BannerNew from '@/views/components/banner/BannerNew.vue';
  import { BannerType } from '@/core/constants/enums';

  export default {
    name: 'BannerXStreetNew',
    components: {
      BannerNew
    },
    data: () => ({
      bannerType: BannerType.X_STREET,
      bannerDimension: {
        height: 1500,
        width: 500,
        ratio: '1:1'
      }
    })
  };
</script>

<style></style>
