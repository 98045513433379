<template>
  <div>
    <RecipeListTemplate
      :breadcrumbData="breadcrumbData"
      category="COOK_LIKE_AN_EXPERT"
    ></RecipeListTemplate>
  </div>
</template>

<script>
  import RecipeListTemplate from '@/views/components/recipe/RecipeListTemplate.vue';

  import { i18nHelper } from '@/core/utils';
  import { ROUTE_NAME } from '@/core/constants';

  export default {
    name: 'RecipeCookLikeAnExpertList',
    components: {
      RecipeListTemplate
    },
    data: () => ({
      breadcrumbData: {
        breadcrumbs: [
          {
            title: i18nHelper.getMessage('label.experienceCooking'),
            route: { name: ROUTE_NAME.EXPERIENCE_COOKING }
          },
          { title: i18nHelper.getMessage('label.experienceCookingList') }
        ]
      }
    })
  };
</script>

<style></style>
