<template>
  <div>
    <AppSmartList
      ref="app-smart-list"
      :breadcrumbData="breadcrumbData"
      :accessRights="accessRights"
      :filters="filters"
      :actions="actions"
      :tableHeaders="headers"
      :newRecord="newRecord"
    >
    </AppSmartList>
  </div>
</template>

<script>
  import AppSmartList from '@/views/components/bases/smart/AppSmartList.vue';
  // import AppExportExcel from '@/views/components/bases/AppExportExcel.vue';
  import { i18nHelper } from '@/core/utils';
  import {
    ROUTE_NAME,
    MODULE,
    ACTION,
    APP_COMPONENT_NAME
  } from '@/core/constants';
  import {
    OFF_DAY_SETTING_GET_OFF_DAY_SETTINGS,
    OFF_DAY_SETTING_INITIAL_GET_OFF_DAY_SETTINGS_STATE
  } from '@/core/store/off-day-setting.module';

  export default {
    name: 'OffDaySettingList',
    components: {
      AppSmartList
      // AppExportExcel
    },
    data: () => ({
      breadcrumbData: {
        breadcrumbs: [
          {
            title: i18nHelper.getMessage('label.offDaySetting'),
            route: { name: ROUTE_NAME.OFF_DAY_SETTING }
          },
          { title: i18nHelper.getMessage('label.offDaySettingList') }
        ]
      },
      accessRights: {
        moduleName: MODULE.OFF_DAY_SETTING,
        actions: {
          read: ACTION.OFF_DAY_SETTING_READ,
          update: ACTION.OFF_DAY_SETTING_UPDATE,
          create: ACTION.OFF_DAY_SETTING_CREATE
        }
      },
      filters: [
        {
          label: 'id',
          key: 'id',
          value: ''
        },
        {
          label: 'eventName',
          key: 'eventName',
          value: ''
        }
      ],
      actions: {
        viewList: {
          module: 'offDaySetting',
          state: 'offDaySettings',
          action: OFF_DAY_SETTING_GET_OFF_DAY_SETTINGS,
          initialStateAction:
            OFF_DAY_SETTING_INITIAL_GET_OFF_DAY_SETTINGS_STATE,
          title: i18nHelper.getMessage('label.getOffDaySetting')
        }
      },
      headers: [
        {
          key: 'no',
          name: 'no',
          active: true,
          sortable: true
        },
        {
          key: 'eventName',
          name: 'eventName',
          active: true,
          sortable: true
        },
        {
          key: 'id',
          name: 'id',
          active: true,
          sortable: true
        },

        {
          key: 'startDateTime',
          name: 'startDateTime',
          active: true,
          sortable: true,
          class: 'py-2'
        },
        {
          key: 'endDateTime',
          name: 'endDateTime',
          active: true,
          sortable: true,
          class: 'py-2'
        },
        {
          key: 'createdBy',
          name: 'createdBy',
          active: true,
          sortable: true
        },
        {
          key: 'actions',
          name: 'actions',
          active: true,
          action: true,
          layout: APP_COMPONENT_NAME.APP_TABLE_ACTIONS,
          layoutActions: {
            viewDetailsRouteName: ROUTE_NAME.OFF_DAY_SETTING_DETAILS
          }
        }
      ],
      newRecord: {
        show: true,
        buttonTitle: i18nHelper.getMessage('label.newOffDay'),
        routeName: ROUTE_NAME.OFF_DAY_SETTING_CREATE
      }
    }),
    computed: {},
    watch: {},
    methods: {}
  };
</script>

<style></style>
