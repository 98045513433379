import Vue from 'vue';
import PrimeVue from 'primevue/config';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import MultiSelect from 'primevue/multiselect';
import OverlayPanel from 'primevue/overlaypanel';
import InputSwitch from 'primevue/inputswitch';
import Editor from 'primevue/editor';

import 'primevue/resources/themes/bootstrap4-light-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';

Vue.component('InputText', InputText);
Vue.component('Dropdown', Dropdown);
Vue.component('MultiSelect', MultiSelect);
Vue.component('OverlayPanel', OverlayPanel);
Vue.component('InputSwitch', InputSwitch);
Vue.component('InputSwitch', InputSwitch);
Vue.component('Editor', Editor);

Vue.use(PrimeVue);
