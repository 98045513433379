<template>
  <div class="app-table-actions">
    <b-button
      v-if="this.item.checkPromotionDeliveryAll"
      class="mx-2"
      variant="primary"
      style="white-space: nowrap"
      @click="$emit('app-table-actions-action-promotion-apply-all', checkId)"
      >Apply All
    </b-button>
    <b-button
      v-if="!this.item.checkPromotionDeliveryAll"
      class="ma-2"
      variant="primary"
      style="white-space: nowrap"
      :disabled="isDisabled"
      @click="$emit('app-table-actions-action-promotion-apply', checkId)"
      >Apply
    </b-button>
    <input type="number" :value="checkId" hidden />
  </div>
</template>

<script>
  import commonMixin from '@/core/mixins/common.mixin';

  export default {
    name: 'AppTableApplyButton',
    mixins: [commonMixin],
    props: {
      item: {
        type: Object,
        default: () => {}
      },

      data: null
    },
    data: () => ({
      disable: true
    }),
    computed: {
      checkId() {
        let ids = [];
        ids.push(this.item.ids);
        return ids;
      },
      isDisabled() {
        let isdisable;
        if (
          this.item.deliveryAmounts == null &&
          this.item.deliveryValidityFrom == null &&
          this.item.deliveryValidityTo == null
        ) {
          isdisable = false;
          return isdisable;
        } else {
          isdisable = true;
          return isdisable;
        }
      }
    },
    methods: {}
  };
</script>

<style></style>
