<template>
  <div class="input-group mb-3" style="max-width: 150px">
    <b-form-input
      :value="value"
      :type="type"
      class="form-control"
      :disabled="disabled"
      @input="input"
    ></b-form-input>
    <div class="input-group-append">
      <button class="btn btn-outline-secondary" type="button" @click="onSave">
        {{ buttonText }}
      </button>
    </div>
    <div v-if="saveActionsOnj.hasClear" class="input-group-append">
      <i
        class="fa la-trash icon-md ml-4 pt-6 cursor-pointer"
        @click="onClear"
      ></i>
    </div>
  </div>
</template>

<script>
  import commonMixin from '@/core/mixins/common.mixin';
  import { i18nHelper } from '@/core/utils';

  export default {
    name: 'AppTextWithButtonFields',
    mixins: [commonMixin],
    props: {
      id: {
        type: Number,
        default: null
      },
      uuid: {
        type: String,
        default: null
      },
      itemKey: {
        type: String,
        default: ''
      },
      value: {
        type: String,
        default: null
      },
      type: {
        type: String,
        default: 'text'
      },
      disabled: {
        type: Boolean,
        default: false
      },
      buttonText: {
        type: String,
        default: 'Save'
      },
      saveActionsOnj: {
        type: Object,
        default: () => {
          return {
            module: '',
            state: '',
            actionSet: '',
            actionInitial: '',
            successAction: () => {},
            errorActionL: () => {},
            title: ''
          };
        }
      }
      // discourdType: null
    },
    data: () => ({
      data: 0,
      precisionNum: 0
      // getValidataType: null
    }),
    computed: {
      updateComplete() {
        let obj = this.saveActionsOnj;

        return this.$store.state[obj.module][obj.state].complete;
      }
    },
    watch: {
      updateComplete() {
        let obj = this.switchActionsOnj;
        let response = this.$store.state[obj.module][obj.state];
        let title = obj.title;
        let initialStateAction = obj.actionInitial;
        let successAction = obj.successAction;
        let errorAction = obj.errorAction;

        if (response.complete) {
          this.actionUpdateRecordCompleteWithoutSuccessModal(
            response,
            title,
            initialStateAction,
            successAction,
            errorAction
          );
        }
      }
    },
    mounted() {
      this.data = this.value;
    },
    methods: {
      input(value) {
        this.data = value;
      },
      onSave() {
        let id = this.id;
        let data = {
          [this.itemKey]: this.data
        };
        if (this.uuid) {
          data.uuid = this.uuid;
        }
        this.$store.dispatch(this.saveActionsOnj.actionSet, { id, data });
      },
      onClear() {
        this.openLayoutAppModalInfo(
          i18nHelper.getMessage('label.deleteRedemptionPoint'),
          [
            i18nHelper.getMessage('message.deleteRedemptionPoint'),
          ],
          [
            {
              text: i18nHelper.getMessage('label.cancel'),
              variant: 'white',
              action: () => {
                this.closeLayoutAppModalInfo();
              }
            },
            {
              text: i18nHelper.getMessage('label.confirm'),
              variant: 'primary',
              action: () => {
                let id = this.id;
                let data = {
                  [this.itemKey]: this.data
                };
                if (this.uuid) {
                  data.uuid = this.uuid;
                }
                data.is_clear = true;

                this.$store.dispatch(this.saveActionsOnj.actionSet, {
                  id,
                  data
                });
                this.closeLayoutAppModalInfo();
              }
            }
          ]
        );
      }
    }
  };
</script>

<style>
  .el-input-number .el-input__inner {
    text-align: left;
  }
</style>
