<template>
  <b-card no-body data-style="custom">
    <!--begin: Wizard-->
    <div
      class="wizard wizard-2"
      id="bannerNewWizard"
      data-wizard-state="step-first"
      data-wizard-clickable="true"
    >
      <!--begin: Wizard Nav -->
      <div class="wizard-nav border-right py-8 px-8 py-lg-20 px-lg-10">
        <div class="wizard-steps">
          <!--begin: Wizard Steps 1 -->
          <div
            class="wizard-step"
            data-wizard-type="step"
            data-wizard-state="current"
          >
            <div class="wizard-wrapper">
              <div class="wizard-icon">
                <span class="svg-icon svg-icon-2x">
                  <inline-svg
                    src="/media/svg/icons/Communication/Clipboard-list.svg"
                  />
                </span>
              </div>
              <div class="wizard-label text-capitalize">
                <h3 class="wizard-title">
                  {{ $t('label.bannerInfo') }}
                </h3>
                <div class="wizard-desc">
                  {{ $t('label.fillInInfoAndAction') }}
                </div>
              </div>
            </div>
          </div>
          <!--end: Wizard Steps 1 -->

          <!--begin: Wizard Steps 2 -->
          <div class="wizard-step" data-wizard-type="step">
            <div class="wizard-wrapper">
              <div class="wizard-icon">
                <span class="svg-icon svg-icon-2x">
                  <inline-svg src="/media/svg/icons/Home/Picture.svg" />
                </span>
              </div>
              <div class="wizard-label text-capitalize">
                <h3 class="wizard-title">
                  {{ $t('label.media') }}
                </h3>
                <div class="wizard-desc">
                  {{ $t('label.uploadBannerMedia') }}
                </div>
              </div>
            </div>
          </div>
          <!--end: Wizard Steps 2 -->
        </div>
      </div>
      <!--end: Wizard Nav -->

      <!--begin: Wizard Body -->
      <div class="wizard-body py-8 px-8 py-lg-20 px-lg-10">
        <!--begin: Wizard Form-->
        <b-row>
          <b-col offset-xl="2" xl="8">
            <b-form class="form" id="kt_form">
              <!--begin: Wizard Step 1-->
              <div
                class="pb-5"
                data-wizard-type="step-content"
                data-wizard-state="current"
              >
                <h6 class="mb-10 font-weight-bold text-dark">
                  {{ $t('label.enterBannerInformationAndChooseAnAction') }}
                </h6>
                <b-row no-gutters>
                  <template
                    v-for="(i, index) in formFields.filter((x) => x.field == 1)"
                  >
                    <!-- begin:: Banner Action Value Field -->
                    <b-col
                      v-if="i.model == 'actionValue'"
                      :key="index + 'actionValue'"
                      xl="6"
                      class="p-xl-1"
                    >
                      <AppLiveSearch
                        v-if="isShowBannerActionValueField"
                        ref="banner-action-value-field"
                        :title="$t('label.actionValue')"
                        v-model="bannerInfo.actionValue"
                        :actionType="bannerInfo.actionType"
                      ></AppLiveSearch>
                    </b-col>
                    <!-- end:: Banner Action Value Field -->

                    <b-col v-else :key="index" xl="6" class="p-xl-1">
                      <AppFormField
                        v-model="$v.bannerInfo[i.model].$model"
                        :state="validateObjState('bannerInfo', i.model)"
                        :validationValue="$v.bannerInfo[i.model]"
                        :title="$t(`label.${i.title}`)"
                        :validators="i.validators"
                        :formType="i.formType"
                        :options="i.options"
                        :validationParams="i.validationParams"
                        :moreRemark="i.moreRemark"
                        solid
                      ></AppFormField>
                      <div style="display: none">
                        <!-- WARNING: I have no idea why this line is here, but removing it breaks everything -->

                        <!-- WARNING: I have no idea why this line is here, but removing it breaks everything -->
                        {{ $v }}
                      </div>
                    </b-col>
                  </template>
                </b-row>
              </div>
            </b-form>
            <!--end: Wizard Step 1-->

            <!--begin: Wizard Step 2-->
            <b-form>
              <div class="pb-5" data-wizard-type="step-content">
                <h6 class="mb-10 font-weight-bold text-dark text-capitalize">
                  {{ $t('label.uploadMedia') }}
                </h6>
                <b-row no-gutters>
                  <template
                    v-for="(i, index) in formFields.filter((x) => x.field == 2)"
                  >
                    <b-col
                      :key="index"
                      v-if="i.type ? bannerMedia.mediaType == i.type : true"
                      xl="6"
                      class="p-xl-1"
                    >
                      <AppFormField
                        v-model="$v.bannerMedia[i.model].$model"
                        :state="validateObjState('bannerMedia', i.model)"
                        :validationValue="$v.bannerMedia[i.model]"
                        :title="$t(`label.${i.title}`)"
                        :solid="true"
                        :validators="i.validators"
                        :formType="i.formType"
                        :options="i.options"
                        :validationParams="i.validationParams"
                        :accept="i.accept"
                        :config="i.config"
                        :moreRemark="i.moreRemark"
                        :horizontal="true"
                      >
                      </AppFormField>
                      <div style="display: none">
                        <!-- WARNING: I have no idea why this line is here, but removing it breaks everything -->
                        {{ $v }}
                      </div>
                      <BannerMediaFileStandardMessage
                        v-if="i.fileStandardMessage"
                        :mediaType="i.mediaType"
                        :bannerType="type"
                        :bannerDimension="bannerDimension"
                      ></BannerMediaFileStandardMessage>
                    </b-col>
                  </template>
                </b-row>
              </div>
            </b-form>
            <!--end: Wizard Step 2-->

            <!--begin: Wizard Actions -->
            <div class="d-flex justify-content-between border-top pt-10">
              <div class="mr-2">
                <button
                  class="btn btn-light-primary font-weight-bold text-uppercase"
                  data-wizard-type="action-prev"
                >
                  {{ $t('label.previous') }}
                </button>
              </div>
              <div>
                <button
                  :disabled="
                    !checkAccessRight(moduleName, [
                      permissionAction.BANNER_CREATE
                    ])
                  "
                  class="btn btn-success font-weight-bold text-uppercase"
                  data-wizard-type="action-submit"
                  type="submit"
                >
                  {{ $t('label.submit') }}
                </button>
                <button
                  class="btn btn-primary font-weight-bold text-uppercase"
                  data-wizard-type="action-next"
                >
                  {{ $t('label.nextStep') }}
                </button>
              </div>
            </div>
            <!--end: Wizard Actions -->
          </b-col>
          <!--end: Wizard-->
        </b-row>
      </div>
      <!--end: Wizard Body -->
    </div>
    <!--end: Wizard-->
  </b-card>
</template>

<script>
  import AppLiveSearch from '@/views/components/bases/AppLiveSearch';
  import BannerMediaFileStandardMessage from '@/views/components/banner/BannerMediaFileStandardMessage';
  import AppFormField from '@/views/components/bases/form-field/AppFormField';
  import KTUtil from '@/assets/js/components/util';
  import KTWizard from '@/assets/js/components/wizard';
  import constantsMixin from '@/core/mixins/constants.mixin';
  import commonMixin from '@/core/mixins/common.mixin';
  import bannerMixin from '@/core/mixins/banner.mixin';
  import { validationMixin } from 'vuelidate';
  import { required, numeric, maxLength } from 'vuelidate/lib/validators';
  import {
    bannerImageType,
    bannerVideoType,
    fileSize,
    fileSizeMb
    // bannerImageDimension
  } from '@/core/constants';
  import { BannerMediaType, BannerActionType } from '@/core/constants/enums';
  import { GLOBAL, MODULE, ACTION, ROUTE_NAME } from '@/core/constants';
  import { i18nHelper, uiHelper } from '@/core/utils';
  import { listService } from '@/core/services';
  import {
    BANNER_CREATE_BANNER,
    BANNER_INITIAL_CREATE_BANNER_STATE
  } from '@/core/store/banner.module';

  export default {
    name: 'BannerNew',
    mixins: [commonMixin, constantsMixin, validationMixin, bannerMixin],
    components: {
      AppFormField,
      BannerMediaFileStandardMessage,
      AppLiveSearch
    },
    props: {
      type: {
        type: Number
      },
      bannerDimension: {
        type: Object
      }
    },
    data: () => ({
      moduleName: MODULE.BANNER,
      permissionAction: ACTION,
      global: GLOBAL,
      routeName: ROUTE_NAME,
      constWizard: null,
      bannerInfo: {
        title: null,
        description: '',
        actionType: null,
        actionValue: null,
        priority: 1,
        contentLabel: '',
        tagLabel: '',
        isActive: 1
      },
      bannerMedia: {
        mediaType: null,
        mediaImage: null,
        mediaVideo: null,
        mediaThumbnailImage: null
      },
      formFields: []
    }),
    computed: {
      createBannerComplete() {
        return this.$store.state.banner.createBanner.complete;
      },
      isShowBannerActionValueField() {
        const actionType = BannerActionType;
        var currentType = this.bannerInfo.actionType;
        var isShow = false;

        if (currentType) {
          if (![actionType.NONE, actionType.COOKIE].includes(currentType)) {
            isShow = true;
          }
        }

        return isShow;
      }
    },
    watch: {
      createBannerComplete() {
        let response = this.$store.state.banner.createBanner;
        if (response.complete) {
          this.createBannerCompleteAction(response);
        }
      },
      mediaType() {
        this.resetMediaValue();
      }
    },
    created() {
      this.initialBannerNew();
    },
    mounted() {
      this.initWizard();
      this.initFormFields();
    },
    methods: {
      createBannerCompleteAction(response) {
        let title = i18nHelper.getMessage('label.createNewBanner');
        let messages = response.message;
        let buttons = [];

        if (response.code == 0) {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.initialCreateBannerState();
              this.closeLayoutAppModalInfo();
              this.$router.push({
                name: this.getBannerListRouteName(this.type)
              });
            }
          });
        } else {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();
              this.initialCreateBannerState();
            }
          });
        }

        this.openLayoutAppModalInfo(title, messages, buttons);
      },
      resetMediaValue() {
        this.bannerMedia.mediaImage = null;
        this.bannerMedia.mediaVideo = null;
        this.bannerMedia.mediaThumbnailImage = null;
        this.bannerMedia.mediaType = null;

        this.$nextTick(() => {
          this.$v.bannerMedia.mediaImage.$reset();
          this.$v.bannerMedia.mediaVideo.$reset();
          this.$v.bannerMedia.mediaThumbnailImage.$reset();
          this.$v.bannerMedia.mediaType.$reset();
        });
      },
      initWizard() {
        // Initialize form wizard
        let vm = this;

        const wizard = new KTWizard('bannerNewWizard', {
          startStep: 1, // initial active step number
          clickableSteps: true // allow step clicking
        });

        vm.constWizard = wizard;

        wizard.on('submit', () => {
          vm.validationCheckBeforeSubmit();
        });

        //Change event
        wizard.on('change', function (/*wizardObj*/) {
          if (wizard.currentStep == 1) {
            let v1 = vm.validationCheck('bannerInfo');
            let v2 = vm.validationActionValueCheck();

            if (!v1 || !v2) {
              wizard.stop();
            }
          }

          KTUtil.scrollTop();
        });
      },
      initFormFields() {
        // let dimension = this.bannerDimension;

        this.formFields.push(
          {
            field: 1,
            model: 'title',
            title: 'title',
            validators: ['required']
          },
          {
            field: 1,
            model: 'description',
            title: 'description',
            formType: 'textarea',
            validators: ['maxLength'],
            validationParams: [
              {
                key: 'maxLength',
                params: [100]
              }
            ],
            moreRemark: 'Maximum 100 characters'
          },
          {
            field: 1,
            model: 'priority',
            title: 'priority',
            validators: ['required', 'numeric']
          },
          {
            field: 1,
            model: 'actionType',
            title: 'actionType',
            validators: ['required'],
            formType: 'select',
            options: listService.getBannerActionTypeList(
              this.constantsData.bannerAction
            )
          },
          {
            field: 1,
            model: 'actionValue'
          },

          {
            field: 1,
            model: 'contentLabel',
            title: 'contentLabel'
          },
          {
            field: 1,
            model: 'tagLabel',
            title: 'tagLabel'
          },
          {
            field: 2,
            model: 'mediaType',
            title: 'mediaType',
            validators: ['required'],
            formType: 'select',
            options: listService.getBannerMediaTypeList(false)
          },
          {
            field: 2,
            model: 'mediaImage',
            title: 'image',
            formType: 'file',
            mediaType: 1, //1 = image, 2 = video
            validators: [
              'required',
              'bannerImageType',
              'fileSize'
              // 'bannerImageDimension'
            ],
            validationParams: [
              {
                key: 'bannerImageType',
                params: [
                  uiHelper.arrayToTrimmedStringWithSpace(
                    GLOBAL.AVAILABLE_BANNER_IMAGE_TYPE,
                    6
                  )
                ]
              },
              {
                key: 'fileSize',
                params: [
                  GLOBAL.BANNER_IMAGE_UPLOAD_SIZE,
                  GLOBAL.BANNER_IMAGE_UPLOAD_SIZE_TYPE
                ]
              }
              // {
              //   key: 'bannerImageDimension',
              //   params: [dimension.height, dimension.width]
              // }
            ],
            accept: GLOBAL.AVAILABLE_BANNER_IMAGE_TYPE.toString(),
            type: BannerMediaType.IMAGE,
            fileStandardMessage: true
          },
          {
            field: 2,
            model: 'mediaVideo',
            title: 'video',
            formType: 'file',
            mediaType: 2, //1 = image, 2 = video
            validators: [
              'required',
              'bannerVideoType',
              'fileSize'
              // 'bannerVideoDimension'
            ],
            validationParams: [
              {
                key: 'bannerVideoType',
                params: [
                  uiHelper.arrayToTrimmedStringWithSpace(
                    GLOBAL.AVAILABLE_BANNER_VIDEO_TYPE,
                    6
                  )
                ]
              },
              {
                key: 'fileSize',
                params: [
                  GLOBAL.BANNER_VIDEO_UPLOAD_SIZE,
                  GLOBAL.BANNER_VIDEO_UPLOAD_SIZE_TYPE
                ]
              }
              // {
              //   key: 'bannerVideoDimension',
              //   params: [dimension.height, dimension.width]
              // }
            ],
            accept: GLOBAL.AVAILABLE_BANNER_VIDEO_TYPE.toString(),
            type: BannerMediaType.VIDEO,
            config: {
              isVideoFile: true
            },
            fileStandardMessage: true
          },
          {
            field: 2,
            model: 'mediaThumbnailImage',
            title: 'thumbnail',
            formType: 'file',
            mediaType: 1, //1 = image, 2 = video
            validators: [
              'required',
              'bannerImageType',
              'fileSize'
              // 'bannerImageDimension'
            ],
            validationParams: [
              {
                key: 'bannerImageType',
                params: [
                  uiHelper.arrayToTrimmedStringWithSpace(
                    GLOBAL.AVAILABLE_BANNER_IMAGE_TYPE,
                    6
                  )
                ]
              },
              {
                key: 'fileSize',
                params: [
                  GLOBAL.BANNER_IMAGE_UPLOAD_SIZE,
                  GLOBAL.BANNER_IMAGE_UPLOAD_SIZE_TYPE
                ]
              }
              // {
              //   key: 'bannerImageDimension',
              //   params: [dimension.height, dimension.width]
              // }
            ],
            accept: GLOBAL.AVAILABLE_BANNER_IMAGE_TYPE.toString(),
            type: BannerMediaType.VIDEO,
            fileStandardMessage: true
          }
        );
      },
      validationCheckBeforeSubmit() {
        //Submit new banner form if all the validation is no error
        if (
          this.validationCheck('bannerInfo') &&
          this.validationObjCheck('bannerMedia', 'mediaType') &&
          this.validationActionValueCheck() &&
          this.validationMediaFileCheck()
        ) {
          this.submitCreateNewBannerForm();
        }
      },
      validationMediaFileCheck() {
        let type = this.bannerMedia.mediaType;
        let validation = true;

        if (type == BannerMediaType.IMAGE) {
          validation = this.validationObjCheck('bannerMedia', 'mediaImage');
        } else if (type == BannerMediaType.VIDEO) {
          validation =
            this.validationObjCheck('bannerMedia', 'mediaVideo') &&
            this.validationObjCheck('bannerMedia', 'mediaThumbnailImage');
        }
        return validation;
      },
      validationActionValueCheck() {
        const actionType = BannerActionType;
        var isValid = true;
        var currentType = this.bannerInfo.actionType;

        if (!['', null, undefined].includes(currentType)) {
          if (![actionType.NONE, actionType.COOKIE].includes(currentType)) {
            isValid = this.$refs['banner-action-value-field'][0].validate();
          }
        } else {
          isValid = false;
        }

        return isValid;
      },
      submitCreateNewBannerForm() {
        const actionType = BannerActionType;
        var bannerInfo = this.bannerInfo;
        var bannerMedia = this.bannerMedia;
        var mediaType = this.bannerMedia.mediaType;
        var formData = new FormData();

        var mediaValue = {
          [BannerMediaType.IMAGE]: bannerMedia.mediaImage,
          [BannerMediaType.VIDEO]: bannerMedia.mediaVideo
        };

        formData.append('title', bannerInfo.title);
        formData.append('description', bannerInfo.description);
        formData.append('action_type', bannerInfo.actionType);
        formData.append('priority', bannerInfo.priority);
        formData.append('is_active', bannerInfo.isActive);
        formData.append('content_label', bannerInfo.contentLabel);
        formData.append('tag_label', bannerInfo.tagLabel);
        formData.append('type', this.type);
        formData.append('media_path', mediaValue[mediaType]);
        if (mediaValue[mediaType] == BannerMediaType.VIDEO) {
          formData.append('thumbnail', bannerMedia.mediaThumbnailImage);
        }
        // Action value is not required when action type is none or cookie
        if (
          ![actionType.NONE, actionType.COOKIE].includes(bannerInfo.actionType)
        ) {
          formData.append('action_value', bannerInfo.actionValue);
        }

        this.createBanner(formData);
      },
      createBanner(formData) {
        this.$store.dispatch(BANNER_CREATE_BANNER, { formData });
      },
      initialCreateBannerState() {
        this.$store.dispatch(BANNER_INITIAL_CREATE_BANNER_STATE);
      },
      initialBannerNew() {
        let type = this.type;
        let breadcrumbData = {
          breadcrumbs: [
            {
              title: i18nHelper.getMessage('label.banners'),
              route: { name: ROUTE_NAME.BANNER }
            },
            {
              title: i18nHelper.getMessage(`enumBannerType.${type}`),
              route: { name: this.getBannerRouteName(type) }
            },
            {
              title: i18nHelper.getMessage(`enumBannerNewType.${type}`)
            }
          ],
          actions: [
            {
              text: i18nHelper.getMessage('label.back'),
              function: () => {
                this.$router.go(-1);
              }
            }
          ]
        };

        this.initBreadCrumb(breadcrumbData);
      }
    },
    validations() {
      // let dimension = this.bannerDimension;

      return {
        bannerInfo: {
          title: {
            required
          },
          description: {
            maxLength: maxLength(100)
          },
          actionType: {
            required
          },
          priority: {
            required,
            numeric
          },
          contentLabel: {},
          tagLabel: {}
        },
        bannerMedia: {
          mediaImage: {
            required,
            bannerImageType,
            fileSize: fileSize(GLOBAL.BANNER_IMAGE_UPLOAD_SIZE)
            // bannerImageDimension: bannerImageDimension(
            //   dimension.height,
            //   dimension.width
            // )
          },
          mediaVideo: {
            required,
            bannerVideoType,
            fileSize: fileSizeMb(GLOBAL.BANNER_VIDEO_UPLOAD_SIZE)
          },
          mediaThumbnailImage: {
            required,
            bannerImageType,
            fileSize: fileSize(GLOBAL.BANNER_IMAGE_UPLOAD_SIZE)
          },
          mediaType: {
            required
          }
        }
      };
    }
  };
</script>

<style lang="scss">
  @import '@/assets/sass/pages/wizard/wizard-2.scss';
</style>
