// This apiService is defualt XMLHttpRequest by axios
import { apiService } from '@/core/services';

// Declare const object name for store methods name/ function name
export const MERCHANT_GROUPING = {
  getMerchantGroupings,
  getMerchantGrouping,
  createMerchantGrouping,
  updateMerchantGrouping,
  deleteMerchantGrouping
};

// Declare const object URL for send a request to a server
const URL = {
  ADMIN: 'admin',
  CUSTOM_OFFDAY_OPERATIONS: 'custom-offday-operations'
};

/***** Methods / Function ::Start *****/
function getMerchantGroupings(data) {
  let requestOptions = {
    params: {
      id: data.id,
      corporateGroup: data.corporateGroup,
      status: data.status,
      per_page: data.perPage,
      page: data.page
    }
  };
  return apiService().get(
    `/${URL.ADMIN}/${URL.CUSTOM_OFFDAY_OPERATIONS}`,
    requestOptions
  );
}

function getMerchantGrouping(id) {
  return apiService().get(
    `/${URL.ADMIN}/${URL.CUSTOM_OFFDAY_OPERATIONS}/${id}`
  );
}

function createMerchantGrouping(data) {
  let requestBody = {
    title: data.eventName,
    start_date: new Date(data.startDateTime).getTime(),
    end_date: new Date(data.endDateTime).getTime(),
    start_time: new Date(data.startDateTime).getTime(),
    end_time: new Date(data.endDateTime).getTime()
  };
  return apiService().post(
    `/${URL.ADMIN}/${URL.CUSTOM_OFFDAY_OPERATIONS}`,
    requestBody
  );
}

function updateMerchantGrouping(id, data) {
  let requestBody = {
    title: data.eventName,
    start_date: new Date(data.startDateTime).getTime(),
    end_date: new Date(data.endDateTime).getTime(),
    start_time: new Date(data.startDateTime).getTime(),
    end_time: new Date(data.endDateTime).getTime()
  };
  return apiService().put(
    `/${URL.ADMIN}/${URL.CUSTOM_OFFDAY_OPERATIONS}/${id}`,
    requestBody
  );
}

function deleteMerchantGrouping(id) {
  return apiService().delete(
    `/${URL.ADMIN}/${URL.CUSTOM_OFFDAY_OPERATIONS}/${id}`
  );
}
/***** Methods / Function ::End *****/
