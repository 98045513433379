<template>
  <div :class="`text-${EcoPackAvailableStatusColor(value)}`">
    {{ value }}
  </div>
</template>

<script>
  export default {
    name: 'EcoPackAvailableTo',
    props: ['value'],
    methods: {
      EcoPackAvailableStatusColor(value) {
        let color;
        let date = new Date();
        value = new Date(value);

        if (value <= date) color = 'danger';

        return color;
      }
    }
  };
</script>

<style></style>
