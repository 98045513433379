import { i18nHelper } from '@/core/utils';
import { REGEX } from '@/core/constants/validation.constants';

export const validationHelper = {
  rulesRequiredField,
  rulesEmail,
  rulesMobileNumber,
  rulesNumeric
};

/* Rules Start */

function rulesRequiredField(value) {
  return [sharedRequiredField(value)];
}

function rulesEmail(value) {
  return [sharedRequiredField(value), emailFormat(value)];
}

function rulesMobileNumber(value) {
  return [sharedRequiredField(value), mobileNumberFormat(value)];
}

function rulesNumeric(value) {
  return [sharedRequiredField(value), numericFormat(value)];
}

/* Rules End */

/* Shared Start */

function sharedRequiredField(value) {
  return !value
    ? i18nHelper.getMessage('fieldErrorMessage.requiredFieldDefault')
    : '';
}

/* Shared End */

/* Format Start */

function emailFormat(value) {
  return !REGEX.email.test(value)
    ? i18nHelper.getMessage('fieldErrorMessage.invalidEmailFormat')
    : '';
}

function mobileNumberFormat(value) {
  return !REGEX.mobileNumberMalaysia.test(value)
    ? i18nHelper.getMessage('fieldErrorMessage.invalidMobileNumberFormat2')
    : '';
}

function numericFormat(value) {
  return !REGEX.number.test(value)
    ? i18nHelper.getMessage('fieldErrorMessage.invalidNumericFormat')
    : '';
}

/* Format End */
