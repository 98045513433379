<template>
  <div @click="ValidataType" style="width: 100px !important">
    <AppFormField
      v-model="amounts"
      :placeholder="`${value !== null ? value.toString() : ''}`"
      :size="'lg'"
      :type="'number'"
    ></AppFormField>
  </div>
</template>

<script>
  import AppFormField from '@/views/components/bases/form-field/AppFormField';

  export default {
    name: 'AppTextFields',
    components: {
      AppFormField
    },
    props: {
      value: {
        type: Number,
        default: null
      },
      item: {
        type: Object,
        default: () => {}
      }
      // discourdType: null
    },
    data: () => ({
      amounts: null,
      precisionNum: 0
      // getValidataType: null
    }),
    computed: {
      solicitorsFeesDisplay: {
        get: function () {
          return this.solicitorsFees.toFixed(2);
        },
        set: function (newValue) {
          this.solicitorsFees = newValue;
        }
      }
      // getValidataType() {
      //   let placeholderMgs;
      //   if (this.item.deliveryAmounts != null) {
      //     placeholderMgs = this.item.deliveryAmounts.toString();
      //   } else if (this.item.deliveryAmounts == null) {
      //     if (this.precisionNum == 0) {
      //       placeholderMgs = '%';
      //     } else {
      //       placeholderMgs = 'RM';
      //     }
      //   }
      //   return placeholderMgs;
      // }
    },
    mounted() {
      this.ValidataType();
    },
    methods: {
      ValidataType() {
        var idlist = 'deliveryOptions' + this.item.ids;
        let selectedTxtElement = document.getElementById(idlist);
        var inputNodes = selectedTxtElement.value;

        if (inputNodes == 'Discount amount') {
          this.precisionNum = 2;
        } else if (inputNodes == 'Discount percentage') {
          this.precisionNum = 0;
        } else {
          this.precisionNum = 2;
        }
        if (this.item.deliveryAmounts != null) {
          this.getValidataType = this.item.deliveryAmounts.toString();
        } else {
          if (this.precisionNum == 0) {
            this.getValidataType = '%';
          } else {
            this.getValidataType = 'RM';
          }
        }
      }
    }
  };
</script>

<style>
  .el-input-number .el-input__inner {
    text-align: left;
  }
</style>
