<template>
  <div>
    <AppSmartList
      ref="app-smart-list"
      :breadcrumbData="breadcrumbData"
      :accessRights="accessRights"
      :filters="filters"
      :actions="actions"
      :tableHeaders="headers"
    >
      <template v-slot:buttons>
        <b-button
          size="sm"
          variant="success"
          class="mr-2 text-capitalize"
          @click="onClickApprovedStatus"
        >
          <i class="flaticon2-notepad"></i>
          {{ $t('label.approvedApplication') }}</b-button
        >
      </template>
    </AppSmartList>
  </div>
</template>

<script>
  import AppSmartList from '@/views/components/bases/smart/AppSmartList.vue';
  import { i18nHelper, uiHelper } from '@/core/utils';
  import {
    ROUTE_NAME,
    MODULE,
    ACTION,
    APP_COMPONENT_NAME,
    SMART_FORM_FIELD_TYPE,
    LOCAL
  } from '@/core/constants';
  import { CreatorType } from '@/core/constants/enums';
  import { listService } from '@/core/services';
  import {
    CREATOR_APPLICATION_GET_CREATOR_APPLICATIONS,
    CREATOR_APPLICATION_INITIAL_GET_CREATOR_APPLICATIONS_STATE
  } from '@/core/store/creator-application.module';

  const CONSTANTS_DATA = JSON.parse(uiHelper.getLocalStorage(LOCAL.CONSTANTS));

  export default {
    name: 'ApplicationList',
    components: {
      AppSmartList
    },
    data: () => ({
      breadcrumbData: {
        breadcrumbs: [
          {
            title: i18nHelper.getMessage('label.application'),
            route: { name: ROUTE_NAME.APPLICATION }
          },
          { title: i18nHelper.getMessage('label.applicationList') }
        ]
      },
      accessRights: {
        moduleName: MODULE.CREATOR_APPLICATION,
        actions: {
          create: ACTION.CREATOR_APPLICATION_CREATE,
          read: ACTION.CREATOR_APPLICATION_READ,
          update: ACTION.CREATOR_APPLICATION_UPDATE,
          delete: ACTION.CREATOR_APPLICATION_DELETE
        }
      },
      filters: [
        {
          label: 'username',
          key: 'username',
          value: ''
        },
        {
          label: 'storeName',
          key: 'storeName',
          value: ''
        },
        {
          label: 'status',
          key: 'status',
          value: '',
          options: listService.getCreatorApplicationStatusList(false),
          optionLabel: 'text',
          type: SMART_FORM_FIELD_TYPE.SELECT
        },
        {
          label: 'submittedDateRange',
          key: 'dateRange',
          value: null,
          type: SMART_FORM_FIELD_TYPE.DATE_RANGE
        },
        {
          label: 'creatorType',
          key: 'creatorType',
          value: null,
          options: listService.getDynamicList(CONSTANTS_DATA.creatorType),
          optionLabel: 'text',
          type: SMART_FORM_FIELD_TYPE.SELECT
        }
      ],
      actions: {
        viewList: {
          module: 'creatorApplication',
          state: 'creatorApplications',
          action: CREATOR_APPLICATION_GET_CREATOR_APPLICATIONS,
          initialStateAction:
            CREATOR_APPLICATION_INITIAL_GET_CREATOR_APPLICATIONS_STATE,
          title: i18nHelper.getMessage('label.getCreatorApplications')
        }
      },
      headers: [
        {
          key: 'no',
          name: 'no',
          active: true,
          sortable: true
        },
        {
          key: 'username',
          name: 'username',
          active: true,
          sortable: true,
          class: 'min-w-130px'
        },
        {
          key: 'creatorType',
          name: 'creatorType',
          active: true,
          sortable: true,
          layout: APP_COMPONENT_NAME.APP_ENUM_LABEL,
          config: {
            enumKey: 'enumCreatorType',
            statusColor: (value) => {
              let color = {
                [CreatorType.INDIVIDUAL]: 'primary',
                [CreatorType.CORPORATE]: 'warning'
              };

              return color[value];
            }
          }
        },
        {
          key: 'storeName',
          name: 'storeName',
          active: true,
          sortable: true
        },
        {
          key: 'status',
          name: 'status',
          active: true,
          sortable: true,
          layout: 'ApplicationStatusLabel',
          class: 'min-w-120px'
        },
        {
          key: 'submittedAt',
          name: 'submittedAt',
          active: true,
          sortable: true
        },
        {
          key: 'actions',
          name: 'actions',
          active: true,
          action: true,
          layout: APP_COMPONENT_NAME.APP_TABLE_ACTIONS,
          layoutActions: {
            viewDetailsRouteName: ROUTE_NAME.APPLICATION_DETAILS
          }
        }
      ]
    }),
    methods: {
      onClickApprovedStatus() {
        this.filters.find((x) => x.key == 'status').value = 'approved';

        this.$refs['app-smart-list'].actionSearch();
      }
    }
  };
</script>

<style></style>
