<template>
  <div class="text-warning">
    <!-- mediaType: 1, //1 = image, 2 = video -->

    <small class="d-block">
      **{{
        $t(`message.mediaDimension`, [
          '',
          bannerDimension.height,
          bannerDimension.width,
          bannerDimension.ratio
        ])
      }}
    </small>
    <small class="d-block">
      **{{
        $t('message.mediaSizeShouldBeLessThan', [
          '',
          mediaType == bannerMediaType.IMAGE
            ? global.BANNER_IMAGE_UPLOAD_SIZE
            : global.BANNER_VIDEO_UPLOAD_SIZE,
          mediaType == bannerMediaType.IMAGE
            ? global.BANNER_IMAGE_UPLOAD_SIZE_TYPE
            : global.BANNER_VIDEO_UPLOAD_SIZE_TYPE
        ])
      }}
    </small>
    <small class="d-block">
      **{{ $t('message.mediaFileType', ['', fileTypes]) }}
    </small>
  </div>
</template>

<script>
  import { uiHelper } from '@/core/utils';
  import { GLOBAL } from '@/core/constants';
  import { BannerMediaType } from '@/core/constants/enums';

  export default {
    name: 'BannerMediaFileStandardMessage',
    props: {
      mediaType: {
        type: Number,
        default: BannerMediaType.IMAGE
      },
      bannerType: {
        type: Number
      },
      bannerDimension: {
        type: Object,
        default: () => {
          return {
            height: 0,
            width: 0,
            ration: '1:1'
          };
        }
      }
    },
    data: () => ({
      global: GLOBAL,
      bannerMediaType: BannerMediaType
    }),
    computed: {
      fileTypes() {
        let types = {
          [BannerMediaType.IMAGE]: GLOBAL.AVAILABLE_BANNER_IMAGE_TYPE,
          [BannerMediaType.VIDEO]: GLOBAL.AVAILABLE_BANNER_VIDEO_TYPE
        };
        return uiHelper.arrayToTrimmedStringWithSpace(types[this.mediaType], 6);
      }
    }
  };
</script>

<style></style>
