<template>
  <div>
    <b-modal
      v-model="isShow"
      centered
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      header-class="text-capitalize"
      body-class="max-h-600px"
      size="lg"
    >
      <template #modal-header>
        <!-- Emulate built in modal header close button action -->
        <div class="w-100 d-flex justify-content-between align-items-center">
          <h3>
            {{ $t('label.addPointToSpecificUser') }}
          </h3>
        </div>
      </template>
      <template v-for="(i, index) in formFields">
        <div :key="index">
          <AppFormField
            v-model="$v.refereeObj[i.model].$model"
            :state="validateObjState('refereeObj', i.model)"
            :validationValue="$v.refereeObj[i.model]"
            :validationParams="i.validationParams"
            :title="$t(`label.${i.title}`)"
            :options="i.options"
            :validators="i.validators"
            :formType="i.formType"
            :isRequires="i.isRequires"
            :prepend="i.prepend"
            :disabled="i.disabled"
            :solid="true"
            :placeholder="i.placeholder"
            :config="i.config"
            @change="i.action"
            @searchChange="i.searchChange"
          >
            <template v-slot:prepend>
              <span class="input-group-text text-uppercase">
                <i class="fas fa-search"></i>
              </span>
            </template>
          </AppFormField>
          <div style="display: none">
            <!-- WARNING: I have no idea why this line is here, but removing it breaks everything -->
            {{ $v }}
          </div>
        </div>
      </template>
      <div v-if="refereeObj.username.length">
        <div class="pb-3">{{ $t(`label.username`) }}</div>
        <div
          class="d-flex align-items-center flex-wrap overflow-auto"
          style="max-height: 200px"
        >
          <template v-for="(i, index) in refereeObj.username">
            <div
              class="label label-md label-inline label-light-primary mr-2 mb-2"
              :key="index"
            >
              {{ i.name }}
            </div>
          </template>
        </div>
      </div>
      <template #modal-footer>
        <template v-if="checkAccessRight(moduleName, [action.POINT_UPDATE])">
          <b-button class="mr-2" variant="white" @click="actionDiscard">{{
            $t('label.discard')
          }}</b-button>
          <b-button variant="primary" @click="actionSaveChanges">{{
            $t('label.save')
          }}</b-button>
        </template>
        <b-button v-else variant="primary" @click="actionDiscard">{{
          $t('label.close')
        }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
  import AppFormField from '@/views/components/bases/form-field/AppFormField';
  import commonMixin from '@/core/mixins/common.mixin';
  import { MODULE, ACTION } from '@/core/constants';
  import { validationMixin } from 'vuelidate';
  import { required } from 'vuelidate/lib/validators';
  import { i18nHelper, uiHelper } from '@/core/utils';
  import { listApiService } from '@/core/services';
  import {
    POINT_CREATE_POINT_SPECIFIC_USERS,
    POINT_INITIAL_CREATE_POINT_SPECIFIC_USERS_STATE
  } from '@/core/store/point.module';

  export default {
    name: 'SpecificUsersCreateModal',
    mixins: [commonMixin, validationMixin],
    components: {
      AppFormField
    },
    props: {
      id: {},
      value: {
        type: Boolean,
        default: false,
        required: true
      },
      isShow: {
        type: Boolean,
        default: false,
        required: true
      }
    },
    data: () => ({
      moduleName: MODULE.POINT,
      action: ACTION,
      modal: false,
      refereeObj: {
        contactNumber: [],
        point: 0,
        username: []
      },
      formFields: [],
      userContactNumber: null
    }),
    mounted() {},
    computed: {
      createPointSpecificUsersComplete() {
        return this.$store.state.point.createPointSpecificUsers.complete;
      }
    },
    watch: {
      value() {
        this.initFormFields();
      },
      modal() {
        this.modal
          ? uiHelper.freezeBodyOverflowY()
          : uiHelper.unFreezeBodyOverflowY();
      },
      createPointSpecificUsersComplete() {
        let response = this.$store.state.point.createPointSpecificUsers;
        if (response.complete) {
          this.pointSpecificUsersCompleteAction(response, 'update');
        }
      }
    },
    methods: {
      pointSpecificUsersCompleteAction(response, actionType) {
        let title = i18nHelper.getMessage('label.addPointToSpecificUser');
        let messages = response.message;
        let buttons = [];
        switch (actionType) {
          case 'update':
            this.initialCreatePointSpecificUsersState();
            break;
        }
        if (response.code == 0) {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              switch (actionType) {
                case 'update':
                  this.$parent.getListDetails(
                    this.id,
                    this.$parent.searchCriteriaListDetails
                  );
                  this.$parent.$parent.initialMerchhantPoint();
                  break;
              }
              this.actionCloseModal();
              this.closeLayoutAppModalInfo();
            }
          });
        } else {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();
            }
          });
        }
        if (messages) this.openLayoutAppModalInfo(title, messages, buttons);
      },
      actionDiscard() {
        this.actionCloseModal();
      },
      actionSaveChanges() {
        if (this.validationCheck('refereeObj')) {
          this.openActionRequestModal();
        }
      },
      resetForm() {
        this.$v.$reset();
        this.refereeObj = {
          contactNumber: [],
          point: 0,
          username: []
        };
      },
      actionCloseModal() {
        this.resetForm();
        this.$emit('input', false);
      },
      async initFormFields() {
        this.formFields = [
          {
            model: 'contactNumber',
            title: 'contactNumber',
            validators: ['required'],
            formType: 'searchSelectAll',
            options: await listApiService.getUserContactNumber(
              this.userContactNumber
            ),
            placeholder: 'Type to search for contact number',
            isRequires: true,
            prepend: true,
            config: {
              trackBy: 'text'
            },
            action: (val) => {
              if (val) {
                this.refereeObj.username = [];
                val.forEach((x) => {
                  this.refereeObj.username.push(JSON.parse(x));
                });
              } else {
                this.refereeObj.username = [];
              }
            },
            searchChange: (val) => {
              this.userContactNumber = val;
              this.initFormFields();
            }
          },
          {
            title: 'point',
            model: 'point',
            isRequires: true,
            validators: ['required', 'numeric'],
            action: () => { },
            searchChange: () => { }
          }
        ];
      },
      openActionRequestModal() {
        let title = i18nHelper.getMessage(`label.addPointToSpecificUser`);

        let messages = [];
        let buttons = [];
        let code = [];
        let textbox = '';
        let dropbox = [];
        messages.push(
          i18nHelper.getMessage('message.requestAddRefereeSelectedMessage')
        );

        buttons.push({
          text: i18nHelper.getMessage('label.cancel'),
          variant: '',
          action: () => {
            this.closeLayoutAppModalInfo();
          }
        });

        buttons.push({
          text: i18nHelper.getMessage('label.confirm'),
          variant: 'success',
          action: () => {
            this.closeLayoutAppModalInfo();
            this.createPointSpecificUsers();
          }
        });

        this.openLayoutAppModalInfo(
          title,
          messages,
          buttons,
          code,
          textbox,
          dropbox
        );
      },
      createPointSpecificUsers() {
        let id = this.id;

        let formData = new FormData();
        this.refereeObj.username.forEach((x) => {
          formData.append('user_ids[]', x.id);
        });

        formData.append('point', this.refereeObj.point);

        this.$store.dispatch(POINT_CREATE_POINT_SPECIFIC_USERS, {
          id,
          formData
        });
      },
      initialCreatePointSpecificUsersState() {
        this.$store.dispatch(
          POINT_INITIAL_CREATE_POINT_SPECIFIC_USERS_STATE,
          {}
        );
      }
    },
    validations: {
      refereeObj: {
        contactNumber: {
          required
        },
        point: {
          required
        }
      }
    }
  };
</script>

<style lang="scss">
  .referral-code {
    .form-group {
      margin: unset !important;
    }
  }
</style>
