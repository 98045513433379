import { PLATFORM_INFO } from '@/core/api';
import { apiHelper } from '@/core/utils';
import {
  LOADING_OPEN_LOADING,
  LOADING_CLOSE_LOADING
} from '@/core/store/loading.module';

/* Define Module Name */
const MODULE_NAME = 'platformInfo/';

/* Define Action Name */
const A_GET_PLATFORM_INFO = 'getPlatformInfo';
const A_UPDATE_PLATFORM_INFO = 'updatePlatformInfo';
const A_UPLOAD_FILE = 'uploadFile';

/* Define Reset State Action Name */
const A_INITIAL_GET_PLATFORM_INFO_STATE = 'initialGetPlatformInfoState';
const A_INITIAL_UPDATE_PLATFORM_INFO_STATE = 'initialUpdatePlatformInfoState';
const A_INITIAL_UPLOAD_FILE_STATE = 'initialUploadFileState';

/* Define Getter Name */

/* Define Mutation Name */
const M_GET_PLATFORM_INFO = 'setGetPlatformInfo';
const M_UPDATE_PLATFORM_INFO = 'setUpdatePlatformInfo';
const M_UPLOAD_FILE = 'setUploadFile';

/* Define Reset State Mutation Name */
const M_INITIAL_GET_PLATFORM_INFO_STATE = 'setInitialGetPlatformInfoState';
const M_INITIAL_UPDATE_PLATFORM_INFO_STATE =
  'setInitialUpdatePlatformInfoState';
const M_INITIAL_UPLOAD_FILE_STATE = 'setInitialUploadFileState';

/* Define Export Name */
export const PLATFORM_INFO_GET_PLATFORM_INFO =
  MODULE_NAME + A_GET_PLATFORM_INFO;
export const PLATFORM_INFO_UPDATE_PLATFORM_INFO =
  MODULE_NAME + A_UPDATE_PLATFORM_INFO;
export const PLATFORM_INFO_UPLOAD_FILE = MODULE_NAME + A_UPLOAD_FILE;

/* Define Reset State Name */
export const PLATFORM_INFO_INITIAL_GET_PLATFORM_INFO_STATE =
  MODULE_NAME + A_INITIAL_GET_PLATFORM_INFO_STATE;
export const PLATFORM_INFO_INITIAL_UPDATE_PLATFORM_INFO_STATE =
  MODULE_NAME + A_INITIAL_UPDATE_PLATFORM_INFO_STATE;
export const PLATFORM_INFO_INITIAL_UPLOAD_FILE_STATE =
  MODULE_NAME + A_INITIAL_UPLOAD_FILE_STATE;

const state = {
  platformInfo: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  updatePlatformInfo: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  uploadFile: {
    code: 0,
    complete: false,
    data: null,
    message: null
  }
};

//Getters
const getters = {};

// Actions
const actions = {
  [A_GET_PLATFORM_INFO]({ dispatch, commit }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    PLATFORM_INFO.getPlatformInfo()
      .then((response) => {
        result = response;
        commit(M_GET_PLATFORM_INFO, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_PLATFORM_INFO, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_UPDATE_PLATFORM_INFO]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    PLATFORM_INFO.updatePlatformInfo(data)
      .then((response) => {
        result = response;
        commit(M_UPDATE_PLATFORM_INFO, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_UPDATE_PLATFORM_INFO, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_UPLOAD_FILE]({ dispatch, commit }, { formData }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    PLATFORM_INFO.uploadFile(formData)
      .then((response) => {
        result = response;
        commit(M_UPLOAD_FILE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_UPLOAD_FILE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_INITIAL_GET_PLATFORM_INFO_STATE]({ commit }) {
    commit(M_INITIAL_GET_PLATFORM_INFO_STATE);
  },
  [A_INITIAL_UPDATE_PLATFORM_INFO_STATE]({ commit }) {
    commit(M_INITIAL_UPDATE_PLATFORM_INFO_STATE);
  },
  [A_INITIAL_UPLOAD_FILE_STATE]({ commit }) {
    commit(M_INITIAL_UPLOAD_FILE_STATE);
  }
};

// Mutations
const mutations = {
  [M_GET_PLATFORM_INFO](state, result) {
    if (result.code === 0) {
      let d = result.data;

      state.platformInfo = {
        code: result.code,
        complete: true,
        data: {
          name: d.name,
          phone: d.phone,
          email: d.email,
          address: d.address,
          instagram: d.instagram,
          facebook: d.facebook,
          whatsapp: d.whatsapp,
          youtube: d.youtube,
          linkedin: d.linkedin,
          tiktok: d.tiktok,
          memberTerms: d.member_terms,
          creatorTerms: d.creator_terms,
          terms: d.terms,
          faqs: d.faqs,
          privacyPolicy: d.privacy_policy,
          termsCondition: d.terms_condition,
          memberCookieTerms: d.member_cookie_terms,
          creatorCookieTerms: d.creator_cookie_terms
        },
        message: null
      };
    } else {
      state.platformInfo = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_UPDATE_PLATFORM_INFO](state, result) {
    state.updatePlatformInfo = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_UPLOAD_FILE](state, result) {
    state.uploadFile = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_INITIAL_GET_PLATFORM_INFO_STATE](state) {
    state.platformInfo = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_UPDATE_PLATFORM_INFO_STATE](state) {
    state.updatePlatformInfo = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_UPLOAD_FILE_STATE](state) {
    state.uploadFile = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
