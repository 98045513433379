var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._l((_vm.formFields.filter(
      (x) => x.field == 1 && x.hidden == false
    )),function(i,index){return [_c('div',{key:index},[_c('label',{staticClass:"font-size-sm text-capitalize"},[_vm._v(_vm._s(_vm.$t(`label.${i.title}`))+" ")]),(i.assets)?_c('div',[_c('b-row',_vm._l((_vm.recipeObj.assets),function(item,index){return _c('b-col',{key:index,attrs:{"cols":"6","md":"4","xl":"3"}},[_c('b-card',{staticClass:"pointer h-100 shadow-sm card-custom rounded-0 overlay text-center",attrs:{"no-body":""}},[_c('div',{staticClass:"card-body p-0"},[_c('div',{staticClass:"overlay-wrapper"},[_c('div',{staticClass:"overflow-hidden"},[(item.mediaType == _vm.mediaType.IMAGE)?_c('div',{staticClass:"h-100px h-sm-150px h-lg-150px h-xl-200px",class:`${
                        _vm.isImage(item.mediaType)
                          ? 'document-image'
                          : 'document'
                      }`,style:(`background-image: url('${_vm.attachmentImage(
                        item.mediaType,
                        item.mediaPath
                      )}'); background-size: 100% 100%;`)}):(item.mediaType == _vm.mediaType.VIDEO)?_c('video',{staticClass:"h-100px h-sm-150px h-lg-150px h-xl-200px"},[_c('source',{attrs:{"src":item.mediaPath}})]):_vm._e()])]),_c('div',{staticClass:"overlay-layer",staticStyle:{"display":"none"}},[_c('div',{staticClass:"text-center"},[_c('AppButtonDocumentView',{attrs:{"attachmentUrl":item.mediaPath,"fileType":item.mediaType,"buttonType":2}})],1)])]),_c('b-form-checkbox',{attrs:{"validationValue":_vm.$v.recipeObj[i.model],"validators":i.validators,"state":_vm.validateObjState('recipeObj', i.model),"value":item.id,"checked":item.id,"size":"lg","switch":""},on:{"input":_vm.input},model:{value:(_vm.$v.recipeObj[i.model].$model),callback:function ($$v) {_vm.$set(_vm.$v.recipeObj[i.model], "$model", $$v)},expression:"$v.recipeObj[i.model].$model"}})],1)],1)}),1),_c('b-form-invalid-feedback',{staticClass:"py-3",attrs:{"state":_vm.validateObjState('recipeObj', i.model)}},[_vm._v(" "+_vm._s(_vm.$t(`fieldErrorMessage.requiredFieldDefault`))+" ")])],1):_c('div',[_c('AppFormField',{attrs:{"title":_vm.$t(`label.${i.title}`),"formType":i.formType,"disabled":i.disabled,"size":'lg',"config":{
            label: _vm.recipeObj?.top,
            routerName: _vm.getRecipePageDetailsRouteName(_vm.category),
            name: _vm.recipeObj?.top?.recipeName,
            id: _vm.recipeObj?.top?.id
          },"horizontal":"","solid":"","noTitle":""},on:{"input":_vm.input},model:{value:(_vm.recipeObj[i.model]),callback:function ($$v) {_vm.$set(_vm.recipeObj, i.model, $$v)},expression:"recipeObj[i.model]"}})],1)])]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }