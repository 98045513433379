<template>
  <div>
    <b-card class="mt-4">
      <b-card-body class="pt-0 px-0">
        <b-tabs pills fill content-class="mt-10">
          <b-tab :title="$t('label.general')">
            <ConfigGeneral></ConfigGeneral>
          </b-tab>
          <b-tab :title="$t('label.cookies')">
            <ConfigCookies></ConfigCookies>
          </b-tab>
          <b-tab :title="$t('label.commission')" :disabled="true"
            >commission</b-tab
          >
        </b-tabs>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
  import ConfigGeneral from '@/views/pages/configuration/ConfigGeneral.vue';
  import ConfigCookies from '@/views/pages/configuration/ConfigCookies.vue';
  import commonMixin from '@/core/mixins/common.mixin';
  import { MODULE, ACTION } from '@/core/constants';
  export default {
    name: 'configuration',
    mixins: [commonMixin],
    components: {
      ConfigGeneral,
      ConfigCookies
    },
    data: () => ({
      accessRights: {
        moduleName: MODULE.CONFIGURATION,
        actions: {
          create: ACTION.CONFIGURATION_CREATE,
          read: ACTION.CONFIGURATION_READ,
          update: ACTION.CONFIGURATION_UPDATE,
          delete: ACTION.CONFIGURATION_DELETE
        }
      }
    })
  };
</script>

<style></style>
