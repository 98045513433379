<template>
  <div>
    <!--begin:: App Smart List-->
    <AppSmartList
      ref="appSmartList"
      :breadcrumbData="breadcrumbData"
      :title="$t('label.appUpdate')"
      :accessRights="accessRights"
      :actions="actions"
      :tableHeaders="headers"
      @app-smart-list-open-modal-details="openModalDetails"
    ></AppSmartList>
    <!--end:: AppSmartList-->

    <!--begin:: App Update Details Modal-->
    <AppUpdateDetailsModal
      v-model="appUpdateDetailsModalObj.isShow"
      :id="appUpdateDetailsModalObj.id"
    ></AppUpdateDetailsModal>
    <!--end:: App Update Details Modal-->
  </div>
</template>

<script>
  import AppSmartList from '@/views/components/bases/smart/AppSmartList.vue';
  import AppUpdateDetailsModal from '@/views/components/app-update/AppUpdateDetailsModal';
  import commonMixin from '@/core/mixins/common.mixin';
  import { i18nHelper } from '@/core/utils';
  import { MODULE, ACTION, APP_COMPONENT_NAME } from '@/core/constants';
  import {
    APP_INFO_GET_APP_INFO_LIST,
    APP_INFO_INITIAL_APP_INFO_LIST_STATE
  } from '@/core/store/app-info.module';

  export default {
    name: 'AppUpdateList',
    components: {
      AppSmartList,
      AppUpdateDetailsModal
    },
    mixins: [commonMixin],
    data: () => ({
      breadcrumbData: {
        breadcrumbs: [{ title: i18nHelper.getMessage('label.appUpdate') }]
      },
      accessRights: {
        moduleName: MODULE.APP_INFO,
        actions: {
          create: ACTION.APP_INFO_CREATE,
          read: ACTION.APP_INFO_READ,
          update: ACTION.APP_INFO_UPDATE,
          delete: ACTION.APP_INFO_DELETE
        }
      },
      actions: {
        viewList: {
          module: 'appInfo',
          state: 'appInfoList',
          action: APP_INFO_GET_APP_INFO_LIST,
          initialStateAction: APP_INFO_INITIAL_APP_INFO_LIST_STATE,
          title: i18nHelper.getMessage('label.getAppInfoList')
        }
      },
      headers: [
        {
          key: 'no',
          name: 'no',
          active: true,
          sortable: true
        },
        {
          key: 'platform',
          name: 'platform',
          active: true,
          sortable: true
        },
        {
          key: 'version',
          name: 'version',
          active: true,
          sortable: true
        },
        {
          key: 'forceUpdate',
          name: 'forceUpdate',
          active: true,
          sortable: true
        },
        {
          key: 'url',
          name: 'url',
          active: true,
          sortable: true
        },
        {
          key: 'message',
          name: 'message',
          active: true,
          sortable: true,
          layout: APP_COMPONENT_NAME.APP_TEXT_TRUNCATE_CONTAINER
        },
        {
          key: 'actions',
          name: 'actions',
          active: true,
          action: true,
          layout: APP_COMPONENT_NAME.APP_TABLE_ACTIONS,
          layoutActions: {
            isModal: true
          }
        }
      ],
      appUpdateDetailsModalObj: {
        isShow: false,
        id: 0
      }
    }),
    methods: {
      openModalDetails(value) {
        this.openAppUpdateDetailsModal(value);
      },
      openAppUpdateDetailsModal(id) {
        this.appUpdateDetailsModalObj = {
          isShow: true,
          id: id
        };
      }
    }
  };
</script>

<style></style>
