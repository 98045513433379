<template>
  <div>
    <!--begin:: App Smart List-->
    <AppSmartList
      ref="appSmartList"
      :breadcrumbData="breadcrumbData"
      :title="$t('label.maintenance')"
      :accessRights="accessRights"
      :filters="filters"
      :actions="actions"
      :tableHeaders="headers"
      :newRecord="newRecord"
      @app-smart-list-action-new-record="actionNewRecord"
      @app-smart-list-open-modal-details="openModalDetails"
    ></AppSmartList>
    <!--end:: AppSmartList-->

    <!--begin:: Maintenance Modal-->
    <MaintenanceModal
      v-model="maintenanceModal.isShow"
      :isNewRecord="maintenanceModal.isNewRecord"
      :id="maintenanceModal.id"
    ></MaintenanceModal>
    <!--end:: Maintenance Modal-->
  </div>
</template>

<script>
  import AppSmartList from '@/views/components/bases/smart/AppSmartList.vue';
  import MaintenanceModal from '@/views/components/maintenance/MaintenanceModal';
  import commonMixin from '@/core/mixins/common.mixin';
  import { i18nHelper } from '@/core/utils';
  import { MODULE, ACTION, APP_COMPONENT_NAME } from '@/core/constants';
  import {
    MAINTENANCE_SCHEDULE_GET_MAINTENANCE_SCHEDULE_LIST,
    MAINTENANCE_SCHEDULE_DELETE_MAINTENANCE_SCHEDULE,
    MAINTENANCE_SCHEDULE_INITIAL_GET_MAINTENANCE_SCHEDULE_LIST_STATE,
    MAINTENANCE_SCHEDULE_INITIAL_DELETE_MAINTENANCE_SCHEDULE_STATE
  } from '@/core/store/maintenance-schedule.module';

  export default {
    name: 'MaintenanceList',
    components: {
      AppSmartList,
      MaintenanceModal
    },
    mixins: [commonMixin],
    data: () => ({
      breadcrumbData: {
        breadcrumbs: [{ title: i18nHelper.getMessage('label.maintenance') }]
      },
      accessRights: {
        moduleName: MODULE.MAINTENANCE_SCHEDULE,
        actions: {
          create: ACTION.MAINTENANCE_SCHEDULE_CREATE,
          read: ACTION.MAINTENANCE_SCHEDULE_READ,
          update: ACTION.MAINTENANCE_SCHEDULE_UPDATE,
          delete: ACTION.MAINTENANCE_SCHEDULE_DELETE
        }
      },
      filters: [],
      actions: {
        viewList: {
          module: 'maintenanceSchedule',
          state: 'maintenanceScheduleList',
          action: MAINTENANCE_SCHEDULE_GET_MAINTENANCE_SCHEDULE_LIST,
          initialStateAction:
            MAINTENANCE_SCHEDULE_INITIAL_GET_MAINTENANCE_SCHEDULE_LIST_STATE,
          title: i18nHelper.getMessage('label.getMaintenanceScheduleList')
        },
        deleteRecord: {
          module: 'maintenanceSchedule',
          state: 'deleteMaintenanceSchedule',
          action: MAINTENANCE_SCHEDULE_DELETE_MAINTENANCE_SCHEDULE,
          initialAction:
            MAINTENANCE_SCHEDULE_INITIAL_DELETE_MAINTENANCE_SCHEDULE_STATE,
          title: i18nHelper.getMessage('label.deleteMaintenanceSchedule'),
          message: i18nHelper.getMessage('message.requestDeleteMessage', [
            i18nHelper.getMessage('label.schedule').toLowerCase()
          ]),
          successAction: () => {}
        }
      },
      headers: [
        {
          key: 'no',
          name: 'no',
          active: true,
          sortable: true
        },
        {
          key: 'title',
          name: 'title',
          active: true,
          sortable: true,
          class: 'min-w-100px'
        },
        {
          key: 'startDateTime',
          name: 'startDateTime',
          active: true,
          sortable: true
        },
        {
          key: 'endDateTime',
          name: 'endDateTime',
          active: true,
          sortable: true
        },
        {
          key: 'createdBy',
          name: 'createdBy',
          active: true,
          sortable: true,
          class: 'min-w-130px'
        },
        {
          key: 'actions',
          name: 'actions',
          active: true,
          action: true,
          layout: APP_COMPONENT_NAME.APP_TABLE_ACTIONS,
          layoutActions: {
            isModal: true,
            hasActionDelete: true
          }
        }
      ],
      newRecord: {
        show: true,
        buttonTitle: i18nHelper.getMessage('label.newSchedule'),
        isModal: true
      },
      maintenanceModal: {
        isShow: false,
        isNewRecord: false,
        id: 0
      }
    }),
    created() {
      this.initMaintenanceScheduleList();
    },
    methods: {
      actionNewRecord() {
        this.openMaintenanceModal(true);
      },
      openModalDetails(value) {
        this.openMaintenanceModal(false, value);
      },
      openMaintenanceModal(isNewRecord, id) {
        this.maintenanceModal = {
          isShow: true,
          isNewRecord: isNewRecord,
          id: id
        };
      },
      importSuccessAction() {
        this.actions.deleteRecord.successAction = () => {
          this.$refs.appSmartList.initAppSmartList();
        };
      },
      initMaintenanceScheduleList() {
        this.importSuccessAction();
      }
    }
  };
</script>

<style></style>
