<template>
  <b-modal
    ref="RecipeRequestEcoPackModal"
    v-model="isShow"
    centered
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
    scrollable
    header-class="text-capitalize"
    size="lg"
  >
    <template #modal-header>
      <!-- Emulate built in modal header close button action -->
      <div class="w-100 d-flex justify-content-between align-items-center">
        <h3>
          {{ $t('label.fulfilByCookx') }}
        </h3>
        <div class="text-right d-flex flex-column">
          <RecipeEcoPackStatusLabel
            :value="recipeObj.ecoPackStatus"
            class="mb-3"
            :size="'sm'"
          ></RecipeEcoPackStatusLabel>
          <AppStatusLabel
            :value="recipeObj.ecoPackSellingStatus"
            :size="'sm'"
          ></AppStatusLabel>
        </div>
      </div>
    </template>
    <b-tabs pills fill content-class="mt-10">
      <b-tab
        :title="$t('label.ecoPackDetails')"
        @click="isEcoPackDetails = true"
      >
        <RecipeEcoPackDetails
          ref="RecipeEcoPackDetails"
          :recipeObj="recipeObj"
          @actionDiscard="actionDiscard"
        >
        </RecipeEcoPackDetails>
      </b-tab>
      <b-tab
        :title="$t('label.variationGroup')"
        @click="isEcoPackDetails = false"
        ><RecipeEcoPackVariations
          ref="RecipeEcoPackVariations"
          :recipeObj="recipeObj"
          @actionDiscard="actionDiscard"
        ></RecipeEcoPackVariations
      ></b-tab>
    </b-tabs>
    <template #modal-footer>
      <b-button class="mr-2" variant="white" @click="actionDiscard">{{
        $t('label.discard')
      }}</b-button>
      <template v-if="checkAccessRight(moduleName, [action.RECIPE_UPDATE])">
        <b-button
          v-if="
            recipeObj.ecoPackStatus == recipeEcoPackStatus.ACCEPTED &&
            recipeObj.ecoPackSellingStatus == activeStatusNum.ACTIVE &&
            isEcoPackDetails
          "
          variant="warning"
          @click="actionTerminateEcoPack"
          >{{ $t('label.terminate') }}</b-button
        >
        <b-button variant="primary" @click="actionSubmitEcopack">{{
          $t('label.submit')
        }}</b-button>
      </template>
    </template>
  </b-modal>
</template>

<script>
  import AppStatusLabel from '@/views/components/bases/AppStatusLabel';
  import RecipeEcoPackStatusLabel from '@/views/components/recipe/eco-pack/RecipeEcoPackStatusLabel';
  import RecipeEcoPackDetails from '@/views/components/recipe/eco-pack/RecipeEcoPackDetails';
  import RecipeEcoPackVariations from '@/views/components/recipe/eco-pack/RecipeEcoPackVariations';
  import commonMixin from '@/core/mixins/common.mixin';
  import { RecipeEcoPackStatus, ActiveStatusNum } from '@/core/constants/enums';
  import { MODULE, ACTION } from '@/core/constants';

  export default {
    name: 'RecipeRequestEcoPackModal',
    mixins: [commonMixin],
    props: {
      recipeObj: {
        type: Object,
        default: () => ({})
      },
      isShow: {
        type: Boolean,
        default: false
      }
    },
    components: {
      AppStatusLabel,
      RecipeEcoPackVariations,
      RecipeEcoPackDetails,
      RecipeEcoPackStatusLabel
    },
    data: () => ({
      moduleName: MODULE.RECIPE,
      modal: false,
      action: ACTION,
      activeStatusNum: ActiveStatusNum,
      recipeEcoPackStatus: RecipeEcoPackStatus,
      isEcoPackDetails: true
    }),
    created() {},
    mounted() {},
    computed: {
      isShowCompleted() {
        return this.isShow;
      }
    },
    watch: {
      async isShowCompleted() {
        let vm = await this;
        vm.$refs.RecipeEcoPackDetails.getEcopackInfo();
      },
      isEcoPackDetails() {
        if (this.isEcoPackDetails) {
          this.$refs.RecipeEcoPackDetails.getEcopackInfo();
        } else {
          this.$refs.RecipeEcoPackVariations.getEcopackVariations();
        }
      }
    },
    methods: {
      actionSubmitEcopack() {
        if (this.isEcoPackDetails) {
          this.$refs.RecipeEcoPackDetails.actionSubmitEcopackDetails();
        } else {
          this.$refs.RecipeEcoPackVariations.actionSubmitEcopackVariations();
        }
      },
      actionTerminateEcoPack() {
        this.$refs.RecipeEcoPackDetails.actionTerminateEcoPack();
      },
      // Discard And Reset Form Start
      actionDiscard() {
        if (this.isEcoPackDetails) {
          this.$refs.RecipeEcoPackDetails.resetForm();
        } else {
          this.$refs.RecipeEcoPackVariations.resetForm();
        }
        this.actionCloseModal();
      },
      actionCloseModal() {
        this.isEcoPackDetails = true;
        this.$emit('input', false);
      }

      // Discard And Reset Form End
    }
  };
</script>

<style>
  .form-group {
    margin-bottom: unset !important;
  }
</style>
