import { apiService } from '@/core/services';

export const OFF_DAY_SETTING = {
  getOffDaySettings,
  getOffDaySetting,
  createOffDaySetting,
  updateOffDaySetting,
  deleteOffDaySetting
};

const URL = {
  ADMIN: 'admin',
  CUSTOM_OFFDAY_OPERATIONS: 'custom-offday-operations'
};

function getOffDaySettings(data) {
  let requestOptions = {
    params: {
      id: data.id,
      title: data.eventName,
      per_page: data.perPage,
      page: data.page
    }
  };
  return apiService().get(
    `/${URL.ADMIN}/${URL.CUSTOM_OFFDAY_OPERATIONS}`,
    requestOptions
  );
}

function getOffDaySetting(id) {
  return apiService().get(
    `/${URL.ADMIN}/${URL.CUSTOM_OFFDAY_OPERATIONS}/${id}`
  );
}

function createOffDaySetting(data) {
  let requestBody = {
    title: data.eventName,
    start_date: new Date(data.startDateTime).getTime(),
    end_date: new Date(data.endDateTime).getTime(),
    start_time: new Date(data.startDateTime).getTime(),
    end_time: new Date(data.endDateTime).getTime()
  };
  return apiService().post(
    `/${URL.ADMIN}/${URL.CUSTOM_OFFDAY_OPERATIONS}`,
    requestBody
  );
}

function updateOffDaySetting(id, data) {
  let requestBody = {
    title: data.eventName,
    start_date: new Date(data.startDateTime).getTime(),
    end_date: new Date(data.endDateTime).getTime(),
    start_time: new Date(data.startDateTime).getTime(),
    end_time: new Date(data.endDateTime).getTime()
  };
  return apiService().put(
    `/${URL.ADMIN}/${URL.CUSTOM_OFFDAY_OPERATIONS}/${id}`,
    requestBody
  );
}

function deleteOffDaySetting(id) {
  return apiService().delete(
    `/${URL.ADMIN}/${URL.CUSTOM_OFFDAY_OPERATIONS}/${id}`
  );
}
