<template>
  <div class="wizard-wrapper">
    <div class="wizard-icon">
      <i :class="icon" class="icon-xl"></i>
    </div>
    <div class="wizard-label">
      <h3 class="wizard-title text-capitalize">
        {{ title }}
      </h3>
      <div class="wizard-desc">
        {{ description }}
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'WizardWrapper',
    props: {
      icon: {
        type: String,
        default: '',
        required: true
      },
      title: {
        type: String,
        default: '',
        required: true
      },
      description: {
        type: String,
        default: '',
        required: true
      }
    }
  };
</script>

<style></style>
