<template>
  <OffDaySettingDetailsTemplate
    :isCreate="false"
  ></OffDaySettingDetailsTemplate>
</template>

<script>
  import OffDaySettingDetailsTemplate from '@/views/components/off-day-setting/OffDaySettingDetailsTemplate.vue';

  export default {
    name: 'OffDaySettingDetails',
    components: {
      OffDaySettingDetailsTemplate
    },
    data: () => ({})
  };
</script>

<style></style>
