import { API_CONSTANTS } from '@/core/constants';

export const apiHelper = {
  sortOutMessage,
  removeParamsWithoutValue,
  getArrayToStringValue,
  getObjectValue,
  dateTimeStart,
  dateTimeEnd
};

function sortOutMessage(data) {
  let message = [];
  let newMessages = [];
  if (typeof data === 'string') {
    newMessages.push(data);
  } else {
    message = Object.values(data);
    message.forEach((v) => {
      newMessages.push(v[0]);
    });
  }
  return newMessages;
}

function removeParamsWithoutValue(obj) {
  for (var keyName in obj) {
    if (
      obj[keyName] === null ||
      obj[keyName] === undefined ||
      obj[keyName] === ''
    ) {
      delete obj[keyName];
    }
  }
  return obj;
}

function getArrayToStringValue(array) {
  let stringData = '';
  let newArray = [];

  if (array) {
    if (array.length >= 1) {
      array.forEach((x) => {
        newArray.push(x.value);
      });
      stringData = newArray.toString();
    }
  }

  return stringData;
}

function getObjectValue(data) {
  let value = null;

  if (!['', null, undefined].includes(data)) {
    value = data.value;
  }

  return value;
}

function dateTimeStart(dateRange) {
  let newDate = '';

  if (!['', null, undefined].includes(dateRange)) {
    let range = dateRange.split(',');

    newDate = new Date(
      range[0] + API_CONSTANTS.DATETIME_FROM_START_TIME
    ).getTime();
  }

  return newDate;
}

function dateTimeEnd(dateRange) {
  let newDate = '';

  if (!['', null, undefined].includes(dateRange)) {
    let range = dateRange.split(',');

    newDate = new Date(range[1] + API_CONSTANTS.DATETIME_TO_END_TIME).getTime();
  }

  return newDate;
}
