<template>
  <MerchantGroupingDetailsTemplate
    :isCreate="true"
  ></MerchantGroupingDetailsTemplate>
</template>

<script>
  import MerchantGroupingDetailsTemplate from '@/views/components/merchant-grouping/MerchantGroupingDetailsTemplate.vue';

  export default {
    name: 'MerchantGroupingCreate',
    components: {
      MerchantGroupingDetailsTemplate
    },
    data: () => ({})
  };
</script>

<style></style>
