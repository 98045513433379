const ASIDE_MENU_CATEGORY = {
  MAIN: 'main',
  ADMIN_USER: 'adminUser',
  MEMBER: 'member',
  MERCHANT: 'merchant',
  POST: 'post',
  COMPANY_WEBSITE: 'companyWebsite',
  MOBILE_APPLICATION: 'mobileApplication',
  PROMOTION: 'promotion',
  TRANSACTIONS: 'transactions',
  PURCHASE: 'purchase',
  CONTENT_MANAGEMENT: 'contentManagement',
  NOTIFICATION: 'notification',
  SETTINGS: 'settings'
};

export { ASIDE_MENU_CATEGORY };
