<template>
  <div class="app-smart-table">
    <!--begin::Data Table-->
    <div class="app-smart-table__table_container">
      <table>
        <!--begin::Table Head-->
        <thead>
          <tr>
            <template v-for="(item, index) in headers">
              <th
                :key="index"
                :class="[{ sortable: item.sortable }]"
                @click="onClickSort(item)"
              >
                <div class="d-flex" :class="item.headersClass">
                  <span
                    class="text-nowrap"
                    :class="[{ 'active-sort': sortBy == item.key }]"
                  >
                    {{ $t(`label.${item.name}`) }}
                  </span>

                  <!-- begin:: Sort Icon Wrapper -->
                  <div v-if="item.sortable" class="sort-icon-wrapper">
                    <i v-if="sortBy != item.key" class="fas fa-sort"></i>
                    <i
                      v-if="
                        sortBy == item.key && currentSortDir == sortOrder.ASC
                      "
                      class="fas fa-sort-amount-up-alt text-primary"
                    ></i>
                    <i
                      v-if="
                        sortBy == item.key && currentSortDir == sortOrder.DESC
                      "
                      class="fas fa-sort-amount-down text-primary"
                    ></i>
                  </div>
                  <!-- end:: Sort Icon Wrapper -->
                </div>
              </th>
            </template>
          </tr>
        </thead>
        <!--end::Table Head-->

        <!--begin::Table Body-->
        <tbody>
          <template v-for="(item, index) in applyAllbtn">
            <tr :key="index" :id="'promotionDelivery'">
              <template v-for="(head, headIndex) in headers">
                <td :key="headIndex">
                  <div :class="head.class">
                    <component
                      v-if="head.layout"
                      :is="head.layout"
                      :item="item"
                      :value="item[head.key]"
                      :header="head"
                      :layoutActions="head.layoutActions"
                      :accessRights="accessRights"
                      :subMenu="subMenu"
                      :config="head.config"
                      :options="head.options"
                      :optionsValue="head.optionsValue"
                      @app-table-actions-read-details="actionReadDetails"
                      @app-table-actions-open-modal-details="
                        actionOpenModalDetails
                      "
                      @app-table-actions-action-delete="actionDelete"
                      @app-table-actions-action-promotion-apply-all="
                        actionPromotionApplyAll
                      "
                      @app-table-actions-action-promotion-delete-all="
                        actionPromotionDeleteAll
                      "
                    >
                    </component>
                    <div v-else>
                      {{ item[head.key] }}
                    </div>
                  </div>
                </td>
              </template>
            </tr>
          </template>
          <template v-for="item in sortedItems">
            <tr :key="item.no" :id="'promotionDelivery' + item.ids">
              <template v-for="(head, headIndex) in headers">
                <td :key="headIndex">
                  <div :class="head.class">
                    <component
                      v-if="head.layout"
                      :is="head.layout"
                      :item="item"
                      :id="overWriteId ? overWriteId : item.id"
                      :uuid="item.uuid"
                      :value="item[head.key]"
                      :itemKey="head.key"
                      :header="head"
                      :layoutActions="head.layoutActions"
                      :accessRights="accessRights"
                      :subMenu="subMenu"
                      :config="head.config"
                      :options="head.options"
                      :optionsValue="head.optionsValue"
                      :saveActionsOnj="saveActionsOnj"
                      @app-table-actions-read-details="actionReadDetails"
                      @app-table-actions-open-modal-details="
                        actionOpenModalDetails
                      "
                      @app-table-actions-download="actionDownload"
                      @app-table-actions-action-delete="actionDelete"
                      @app-table-actions-action-promotion-apply="
                        actionPromotionApply
                      "
                      @app-table-actions-action-promotion-delete="
                        actionPromotionDelete
                      "
                    >
                    </component>
                    <div v-else>
                      {{ item[head.key] }}
                    </div>
                  </div>
                </td>
              </template>
            </tr>
          </template>
          <tr v-if="sortedItems.length == 0" class="no-hover">
            <td :colspan="headers.length">
              <div class="text-center p-2">
                <span>{{ $t('message.thereAreNoRecordsToShow') }}</span>
              </div>
            </td>
          </tr>
        </tbody>
        <!--end::Table Body-->
      </table>
    </div>
    <!--end::Data Table-->

    <!--begin::Table Pagination-->
    <AppTablePagination
      v-if="!hidePagination"
      :pagination="pagination"
      @change="actionPaginationChange"
    ></AppTablePagination>
    <!--end::Table Pagination-->
  </div>
</template>

<script>
  import AppCheckCircle from '@/views/components/bases/AppCheckCircle';
  import AppStatusLabel from '@/views/components/bases/AppStatusLabel';
  import AppTableActions from '@/views/components/bases/AppTableActions';
  import AppDropdown from '@/views/components/bases/AppDropdown';
  import AppTableApplyButton from '@/views/components/bases/AppTableApplyButton';
  import AppTableDeleteButton from '@/views/components/bases/AppTableDeleteButton';
  import AppTextFields from '@/views/components/bases/AppTextFields';
  import AppDateTimePackerFrom from '@/views/components/bases/AppDateTimePackerFrom';
  import AppDateTimePackerTo from '@/views/components/bases/AppDateTimePackerTo';
  import AppMemberTypeLabel from '@/views/components/bases/AppMemberTypeLabel';
  import AppTablePagination from '@/views/components/bases/pagination/AppTablePagination';
  import ApplicationStatusLabel from '@/views/components/application/ApplicationStatusLabel';
  import AppTextTruncateContainer from '@/views/components/bases/AppTextTruncateContainer';
  import AppFeatureDetailsRelatedButton from '@/views/components/bases/AppFeatureDetailsRelatedButton';
  import AppEnumLabel from '@/views/components/bases/AppEnumLabel';
  import DeliveryStatusLabel from '@/views/components/orders/DeliveryStatusLabel';
  import RecipeStatusLabel from '@/views/components/recipe/RecipeStatusLabel';
  import EcoPackAvailableTo from '@/views/components/ecopack/EcoPackAvailableTo';
  import ReferralCodeStatusLabel from '@/views/components/referral-code/ReferralCodeStatusLabel';
  import VoucherStatusLabel from '@/views/components/voucher/VoucherStatusLabel';
  import OrderStatusLabel from '@/views/components/orders/OrderStatusLabel';
  import StudioBookingStatusLabel from '@/views/components/studio/StudioBookingStatusLabel';
  import EquipmentRecipesStatusLabel from '@/views/components/equipment/recipe/EquipmentRecipesStatusLabel';
  import RecipeEcoPackStatusLabel from '@/views/components/recipe/eco-pack/RecipeEcoPackStatusLabel';
  import AppTextWithButtonFields from '@/views/components/bases/AppTextWithButtonFields.vue';
  const SORT_ORDER = {
    ASC: 'asc',
    DESC: 'desc'
  };

  export default {
    name: 'AppSmartTable',
    components: {
      AppCheckCircle,
      AppStatusLabel,
      AppTableActions,
      AppMemberTypeLabel,
      AppTablePagination,
      ApplicationStatusLabel,
      AppTextTruncateContainer,
      AppFeatureDetailsRelatedButton,
      AppEnumLabel,
      AppDropdown,

      AppTextFields,
      AppDateTimePackerFrom,
      AppDateTimePackerTo,
      AppTableApplyButton,
      AppTableDeleteButton,
      DeliveryStatusLabel,
      RecipeStatusLabel,
      EcoPackAvailableTo,
      OrderStatusLabel,
      ReferralCodeStatusLabel,
      VoucherStatusLabel,
      StudioBookingStatusLabel,
      EquipmentRecipesStatusLabel,
      RecipeEcoPackStatusLabel,
      AppTextWithButtonFields
    },
    props: {
      items: {
        type: Array,
        default: () => []
      },
      overWriteId: {
        type: Number,
        default: null
      },
      filters: {
        type: Array,
        default: () => []
      },
      headers: {
        type: Array,
        default: () => [
          {
            key: 'no',
            name: 'no',
            active: true
          }
        ]
      },
      applyAllbtn: {
        type: Array,
        default: () => [
          // {
          //   key: 'no',
          //   name: 'no',
          //   active: true
          // }
        ]
      },
      pagination: {
        type: Object,
        default: () => {
          return {
            currentPage: 1,
            total: 1,
            perPage: 10,
            lastPage: 1
          };
        }
      },
      hidePagination: {
        type: Boolean,
        default: false
      },
      accessRights: {
        type: Object,
        default: () => {
          return {
            moduleName: '',
            actions: {
              create: '',
              read: '',
              update: '',
              delete: ''
            }
          };
        }
      },
      saveActionsOnj: {
        type: Object,
        default: () => {
          return {
            module: '',
            state: '',
            actionSet: '',
            actionInitial: '',
            successAction: () => {},
            errorActionL: () => {},
            title: ''
          };
        }
      },
      subMenu: {
        type: String,
        default: null
      }
    },
    data: () => ({
      deliveryPromotion: [],
      sortOrder: SORT_ORDER,
      sortBy: '',
      currentSortDir: SORT_ORDER.ASC,
      promotionDelivery: {
        ids: {},
        promotionOptions: null,
        DeliveryAmounts: null,
        DeliveryValidityFrom: null,
        DeliveryValidityTo: null
      }
      // applyAllbtn: [
      //   {
      //     // no: null,
      //     name: 'APPLY TO ALL',
      //     ids: 1,
      //     deliveryDiscount: null,
      //     deliveryAmounts: null,
      //     deliveryValidityFrom: null,
      //     deliveryValidityTo: null
      //   }
      // ]
    }),
    computed: {
      sortedItems() {
        const dateProperties = [
          'updatedAt',
          'createdAt',
          'deletedAt',
          'verifiedAt',
          'submittedAt',
          'publishedAt',
          'approvedAt',
          'rejectedAt',
          'startDateTime',
          'endDateTime',
          'scheduledAt',
          'startDate',
          'endDate',
          'availableFrom',
          'availableTo',
          'accountDeletedAt',
          'lastLoginAt'
        ];
        var items = this.items;
        var sortBy = this.sortBy;
        var currentSortDir = this.currentSortDir;
        return items.sort((a, b) => {
          // Sort by date time
          if (dateProperties.includes(sortBy)) {
            if (a[sortBy] != '-' && b[sortBy] != '-') {
              let x = new Date(a[sortBy]).getTime();
              let y = new Date(b[sortBy]).getTime();

              if (currentSortDir === SORT_ORDER.ASC) {
                return x - y;
              } else {
                return y - x;
              }
            } else {
              return -1;
            }
          } else {
            let modifier = 1;

            if (currentSortDir === SORT_ORDER.DESC) modifier = -1;
            if (a[sortBy] < b[sortBy]) return -1 * modifier;
            if (a[sortBy] > b[sortBy]) return 1 * modifier;

            return 0;
          }
        });
      }
    },
    methods: {
      onClickSort(item) {
        if (item.sortable) {
          if (item.key === this.sortBy) {
            this.currentSortDir =
              this.currentSortDir === SORT_ORDER.ASC
                ? SORT_ORDER.DESC
                : SORT_ORDER.ASC;
          }
          this.sortBy = item.key;
        }
      },
      actionDelete(value) {
        this.$emit('app-table-action-delete', value);
      },
      actionDownload(value) {
        this.$emit('app-table-action-download', value);
      },
      actionOpenModalDetails(value, obj) {
        this.$emit('app-table-open-modal-details', value, obj);
      },
      actionReadDetails(value) {
        this.$emit('app-table-read-details', value);
      },
      actionPaginationChange(value) {
        this.$emit('app-smart-table-pagination-change', value);
      },
      actionPromotionApply(value) {
        var idlist = 'promotionDelivery' + value;
        let selectedTxtElement = document.getElementById(idlist);
        var inputNodes = selectedTxtElement.getElementsByTagName('INPUT');
        let data = [];

        for (var i = 0; i < inputNodes.length; ++i) {
          data = data.concat(inputNodes[i].value);
        }

        if (data[0] == 'Discount amount') {
          data[0] = 1;
        } else if (data[0] == 'Discount percentage') {
          data[0] = 2;
        } else if (data[0] == 'Max amount') {
          data[0] = 3;
        } else {
          data[0] = '';
        }

        this.promotionDelivery = {
          promotionOptions: data[0],
          DeliveryAmounts: data[1],
          DeliveryValidityFrom: data[2],
          DeliveryValidityTo: data[3],
          ids: [data[4]]
        };
        this.$emit('app-smart-table-promotion-apply', this.promotionDelivery);
      },
      actionPromotionApplyAll() {
        var idlist = 'promotionDelivery';
        let selectedTxtElement = document.getElementById(idlist);
        var inputNodes = selectedTxtElement.getElementsByTagName('INPUT');
        let data = [];
        let creatorId;

        for (var i = 0; i < inputNodes.length; ++i) {
          data = data.concat(inputNodes[i].value);
        }

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let getUrlParams = urlParams.get('creatorId');
        if (getUrlParams) {
          creatorId = getUrlParams.split(',').map(Number);
        } else {
          creatorId = [];
          for (var x = 0; x < this.filters[0].options.length; ++x) {
            creatorId = creatorId.concat(this.filters[0].options[x].value);
          }
        }

        if (data[0] == 'Discount amount') {
          data[0] = 1;
        } else if (data[0] == 'Discount percentage') {
          data[0] = 2;
        } else if (data[0] == 'Max amount') {
          data[0] = 3;
        } else {
          data[0] = '';
        }

        this.promotionDelivery = {
          promotionOptions: data[0],
          DeliveryAmounts: data[1],
          DeliveryValidityFrom: data[2],
          DeliveryValidityTo: data[3],
          ids: creatorId
        };
        this.$emit(
          'app-smart-table-promotion-apply-all',
          this.promotionDelivery
        );
      },
      actionPromotionDelete(value) {
        this.$emit('app-smart-table-promotion-delete', value);
      },
      actionPromotionDeleteAll() {
        let creatorId;

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let getUrlParams = urlParams.get('creatorId');
        if (getUrlParams) {
          creatorId = getUrlParams.split(',').map(Number);
        } else {
          creatorId = [];
          for (var x = 0; x < this.filters[0].options.length; ++x) {
            creatorId = creatorId.concat(this.filters[0].options[x].value);
          }
        }

        this.$emit('app-smart-table-promotion-delete-all', creatorId);
      }
    }
  };
</script>

<style lang="scss">
  .app-smart-table {
    .app-smart-table__table_container {
      overflow: auto;
      max-width: 100%;
      margin-bottom: 1rem;
      table {
        width: 100%;
        > thead {
          > tr {
            > th {
              color: #b5b5c3;
              font-size: 0.925rem;
              text-transform: uppercase;
              font-weight: 600;
              letter-spacing: 0.1rem;
              padding: 0.5rem 1.2rem;
              border-bottom: 1px solid #ebedf3;
              &.sortable {
                cursor: pointer;
              }

              .sort-icon-wrapper {
                padding-left: 8px;
              }

              span {
                &.active-sort {
                  color: var(--primary) !important;
                }
              }
            }
          }
        }
        > tbody {
          > tr {
            &:hover {
              color: #3f4254;
              background-color: #e4e6ef;
            }
            &.no-hover:hover {
              color: unset !important;
              background-color: unset !important;
            }
            > td {
              padding: 0.4rem 1.2rem;
              vertical-align: middle;
              font-size: 0.925rem;
              border-bottom: 1px solid #ebedf3;
            }
            &:last-child {
              td {
                border-bottom: unset !important;
              }
            }
          }
        }
      }
    }
  }
</style>
