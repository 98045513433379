<template>
  <b-card no-body class="app-smart-list p-8">
    <!--begin::Header-->
    <b-card-header class="d-flex border-0 p-0">
      <b-row no-gutters class="justify-content-end w-100">
        <!--begin::Button Actions-->
        <b-row no-gutters class="justify-content-end w-100">
          <!-- begin:: Additional Buttons -->
          <slot name="buttons" />
          <!-- end:: Additional Buttons -->

          <b-button
            v-if="actions.exportExcel"
            size="sm"
            variant="light-warning"
            class="mr-2 text-capitalize"
            @click="onExport"
          >
            <i class="fas fa-file-export"></i>
            {{ $t('label.exportExcel') }}</b-button
          >
          <b-button
            size="sm"
            variant="light-primary"
            @click="propertiesVisible = !propertiesVisible"
          >
            <i class="flaticon2-dashboard p-0 mr-2"></i>
            {{ $t('label.properties') }}
          </b-button>
          <b-button
            size="sm"
            v-if="
              newRecord.show &&
              checkAccessRight(accessRights.moduleName, [
                accessRights.actions.create
              ])
            "
            variant="primary"
            class="ml-2 text-capitalize"
            @click="actionNewRecord"
          >
            <i class="flaticon2-plus p-0 mr-2"></i>
            {{ newRecord.buttonTitle }}
          </b-button>
        </b-row>
        <!--end::Button Actions-->
      </b-row>
    </b-card-header>
    <!--end::Header-->

    <!--begin::Table Properties Collapse-->
    <AppTablePropertiesCollapse
      :table-header-list="tableHeaders"
      :visible="propertiesVisible"
      @change="actionPropertiesChange"
    >
    </AppTablePropertiesCollapse>
    <!--end::Table Properties Collapse-->

    <!--begin::Body-->
    <b-card-body class="p-0 pt-4">
      <!--begin::Filter Panel-->
      <b-row v-if="filteredFilterFields.length >= 1" class="mb-7">
        <template v-for="(i, index) in filteredFilterFields">
          <b-col :key="index" cols="12" md="4" xl="2">
            <label class="font-size-sm"
              >{{ $t(`label.${i.label}`) }}:
              <span
                v-if="i.tooltip"
                v-b-tooltip.hover
                :title="i.tooltip"
                class="text-primary font-weight-bold"
                >[?]</span
              ></label
            >
            <AppSmartFormField
              v-model="i.value"
              :formId="index"
              :type="i.type"
              :rules="i.rules"
              :isOptional="i.isOptional"
              :options="i.options"
              :optionLabel="i.optionLabel"
              :newStyle="i.newStyle"
            ></AppSmartFormField>
          </b-col>
        </template>

        <b-col class="d-flex justify-content-end align-items-center">
          <b-button
            v-if="filters.length > filterSliceCount"
            variant="light-primary"
            size="sm"
            class="mr-2"
            @click="isExpandFilterFields = !isExpandFilterFields"
          >
            <i
              class="p-0 mr-2"
              :class="
                isExpandFilterFields ? 'flaticon2-shrink' : ' flaticon2-resize'
              "
            ></i>
            {{
              $t(
                `label.${
                  isExpandFilterFields ? 'collapseFilter' : 'expandFilter'
                }`
              )
            }}</b-button
          >
          <b-button
            type="submit"
            variant="primary"
            class="max-h-40px"
            size="sm"
            @click="actionSearch"
          >
            <i class="flaticon-search p-0 mr-2"></i>
            {{ $t('label.search') }}</b-button
          >
        </b-col>
      </b-row>
      <!--end::Filter Panel-->
      <!--begin::Smart Table-->
      <AppSmartTable
        :filters="filters"
        :items="tableItems"
        :headers="filteredTableHeader"
        :pagination="pagination"
        :accessRights="accessRights"
        :subMenu="subMenu"
        :applyAllbtn="applyAllbtn"
        @app-smart-table-pagination-change="actionPaginationChange"
        @app-table-open-modal-details="actionTableOpenModalDetails"
        @app-table-action-delete="actionTableDelete"
        @app-table-action-download="actionTableDownload"
        @app-smart-table-promotion-apply="actionPromotionApply"
        @app-smart-table-promotion-apply-all="actionPromotionApplyAll"
        @app-smart-table-promotion-delete="actionPromotionDelete"
        @app-smart-table-promotion-delete-all="actionPromotionDeleteAll"
      ></AppSmartTable>
      <!--end::Smart Table-->
    </b-card-body>
    <!--end::Body-->
  </b-card>
</template>

<script>
  import AppSmartFormField from '@/views/components/bases/smart/AppSmartFormField';
  import AppSmartTable from '@/views/components/bases/smart/AppSmartTable';
  import AppTablePropertiesCollapse from '@/views/components/bases/AppTablePropertiesCollapse';
  import commonMixin from '@/core/mixins/common.mixin';
  import crudMixin from '@/core/mixins/crud.mixin';
  import { GLOBAL, SMART_FORM_FIELD_TYPE } from '@/core/constants';
  import { i18nHelper, uiHelper } from '@/core/utils';

  export default {
    name: 'AppSmartList',
    components: {
      AppSmartTable,
      AppSmartFormField,
      AppTablePropertiesCollapse
    },
    mixins: [commonMixin, crudMixin],
    props: {
      breadcrumbData: {
        type: Object,
        default: () => {
          return {
            pageTitle: null,
            breadcrumbs: [],
            actions: []
          };
        }
      },
      title: {
        type: String,
        default: 'Title',
        require: true
      },
      moduleName: {
        type: String,
        default: '',
        require: true
      },
      accessRights: {
        type: Object,
        default: () => {
          return {
            moduleName: '',
            actions: {
              create: '',
              read: '',
              update: '',
              delete: ''
            }
          };
        }
      },
      subMenu: {
        type: String,
        default: null
      },
      filters: {
        type: Array,
        default: () => []
      },
      actions: {
        type: Object,
        default: () => {}
      },
      tableHeaders: {
        type: Array,
        default: () => []
      },
      newRecord: {
        type: Object,
        default: () => {
          return {
            show: false,
            buttonTitle: i18nHelper.getMessage('label.newRecord'),
            routeName: ''
          };
        }
      },
      applyAllbtn: {
        type: Array,
        default: () => []
      }
    },
    data: () => ({
      isExpandFilterFields: false,
      searchCriteria: {},
      propertiesVisible: false,
      selectedTableHeader: [],
      tableItems: [],
      pagination: {
        currentPage: 1,
        total: 1,
        perPage: 10,
        lastPage: 1
      },
      windowWidth: window.innerWidth
    }),
    computed: {
      getListComplete() {
        let obj = this.actions.viewList;
        console.log(this.$store.state);

        return this.$store.state[obj.module][obj.state].complete;
      },
      filteredFilterFields() {
        let filters = this.filters.filter((x) => x.isHidden != true);

        return this.isExpandFilterFields
          ? filters
          : filters.slice(0, this.filterSliceCount);
      },
      filteredTableHeader() {
        return this.tableHeaders.filter((x) =>
          this.selectedTableHeader.find((y) => y == x.key)
        );
      },
      filterSliceCount() {
        return this.windowWidth > 1903 ? 6 : 3;
      },
      exportExcelComplete() {
        let obj = this.actions.exportExcel;

        if (obj) {
          return this.$store.state[obj.module][obj.state].complete;
        } else {
          return false;
        }
      }
    },
    watch: {
      getListComplete(newValue) {
        let obj = this.actions.viewList;
        console.log(this.$store.state[obj.module]);

        let response = this.$store.state[obj.module][obj.state];
        let title = obj.title;
        let initialStateAction = obj.initialStateAction;
        let successAction = (response) => {
          this.importData(response);
          this.checkRouteQueryPageValue(response);
        };

        if (newValue) {
          this.actionReadRecordComplete(
            response,
            title,
            initialStateAction,
            successAction
          );
        }
      },
      exportExcelComplete(newValue) {
        let obj = this.actions.exportExcel;
        let response = this.$store.state[obj.module][obj.state];
        let title = obj.title;
        let initialStateAction = obj.initialStateAction;
        let successAction = () => {};
        let errorAction = () => {};

        if (newValue) {
          this.actionUpdateRecordComplete(
            response,
            title,
            initialStateAction,
            successAction,
            errorAction
          );
        }
      }
    },
    created() {
      this.initAppSmartList();
    },
    mounted() {
      window.onresize = () => {
        this.windowWidth = window.innerWidth;
      };
    },
    methods: {
      onExport() {
        let data = {
          ...this.cleanOutFilterData(this.filters)
        };
        this.exportData(data);
      },
      checkRouteQueryPageValue(response) {
        let dsc = {
          ...GLOBAL.DEFAULT_SEARCH_CRITERIA
        };
        let route = this.$route;
        let pagination = response.pagination;

        if (route.query.page > pagination.lastPage) {
          this.updateSearchCriteria(dsc.page, dsc.perPage);
        }
      },
      updateRouteQuery() {
        let newSearchCriteria = {
          ...uiHelper.removeObjectWithoutValue(this.searchCriteria)
        };

        let stringifyNewSearchCriteriaValue = null;

        for (let key in newSearchCriteria) {
          if (typeof newSearchCriteria[key] == 'object') {
            if (newSearchCriteria.creatorId.length > 0) {
              newSearchCriteria[key] = uiHelper.getObjectValue2(
                newSearchCriteria[key]
              );
            } else {
              newSearchCriteria[key] = uiHelper.getObjectValue(
                newSearchCriteria[key]
              );
            }
          }
        }

        stringifyNewSearchCriteriaValue =
          uiHelper.objectValueToString(newSearchCriteria);

        if (
          !uiHelper.objectIsShallowEqual(
            this.$route.query,
            stringifyNewSearchCriteriaValue
          )
        ) {
          this.$router.push({
            query: uiHelper.removeObjectWithoutValue(newSearchCriteria)
          });
        }
      },
      updateParentFilterValue() {
        /** Get the object value from route then
         * update the filter value in parent component */
        let dsc = {
          ...GLOBAL.DEFAULT_SEARCH_CRITERIA
        };
        let queryObj = this.$route.query;

        if (queryObj) {
          if (this.filters.length > 0) {
            Object.keys(queryObj).forEach((key) => {
              let targetFilter = this.$parent.filters.find((y) => y.key == key);
              let filterValue = null;

              if (targetFilter) {
                if (targetFilter.type) {
                  if (targetFilter.type == SMART_FORM_FIELD_TYPE.SELECT) {
                    filterValue = targetFilter.options.find(
                      (z) => z.value == queryObj[key]
                    );
                  } else if (
                    targetFilter.type == SMART_FORM_FIELD_TYPE.DATE_RANGE
                  ) {
                    let range = queryObj[key].split(',');

                    if (range[0] && range[1]) {
                      filterValue = {
                        start: range[0],
                        end: range[1]
                      };
                    } else {
                      filterValue = null;
                    }
                  } else if (
                    targetFilter.type == SMART_FORM_FIELD_TYPE.MULTI_SELECT
                  ) {
                    let newArray = new Array();

                    if (queryObj[key]) {
                      let array = queryObj[key].split(',');

                      array.forEach((x) => {
                        let value = targetFilter.options.find(
                          (y) => y.value == x
                        );
                        newArray.push(value);
                      });
                    }

                    filterValue = newArray;
                  }
                } else {
                  filterValue = queryObj[key];
                }

                this.$parent.filters.find((y) => y.key == key).value =
                  filterValue;
              }
            });
          }

          if (queryObj.page) dsc.page = queryObj.page;
          if (queryObj.perPage) dsc.perPage = queryObj.perPage;
        }

        this.updateSearchCriteria(dsc.page, dsc.perPage);
      },
      cleanOutFilterData(data) {
        let newData = new Object();
        /** Transform array data to object */

        /** Remove object that contain invalid value */
        let data_type = false;

        if (data.length > 0) {
          data_type = data[0].type;
        }

        let SearchSelect = data_type == 'SearchSelect';
        // let SearchSelect = data[0].type == 'multiSelect';
        if (!SearchSelect) {
          newData = uiHelper.arrayToObject(data, 'key', 'value');
        } else {
          newData = uiHelper.arrayToObject2(data, 'key', 'value');
        }
        newData = uiHelper.removeObjectWithoutValue(newData);

        for (let key in newData) {
          /** Map object value to string value */
          if (Array.isArray(newData[key])) {
            /** Transform array value to string value */
            let newArray = [];

            newData[key].forEach((x) => {
              newArray.push(x.value);
            });

            newData[key] = newArray.toString();
          } else if (typeof newData[key] == 'object') {
            /** Transform object value to string value */
            if (newData[key].start && newData[key].end) {
              /** Map date range object */
              newData = {
                ...newData,
                [key]: [newData[key].start, newData[key].end].toString()
              };
            } else {
              /** Get value from object property "value" */
              newData[key] = uiHelper.getObjectValue(newData[key], 'value');
            }
          }
        }

        return newData;
      },
      updateSearchCriteria(page, perPage) {
        this.searchCriteria = {
          ...this.cleanOutFilterData(this.filters),
          page: page,
          perPage: perPage
        };
        this.pagination = {
          ...this.pagination,
          currentPage: page,
          perPage: perPage
        };
        this.updateRouteQuery();
        this.getList(this.searchCriteria);
      },
      actionTableDelete(value) {
        this.requestDelete(value);
      },
      actionTableOpenModalDetails(value, obj) {
        this.$emit('app-smart-list-open-modal-details', value, obj);
      },
      actionTableDownload(value) {
        this.$emit('app-smart-list-action-download', value);
      },
      actionPropertiesChange(value) {
        this.selectedTableHeader = value;
      },
      actionPaginationChange(value) {
        this.updateSearchCriteria(value.page, value.size);
      },
      actionSearch() {
        this.updateSearchCriteria(
          GLOBAL.DEFAULT_SEARCH_CRITERIA.page,
          this.searchCriteria.perPage
        );
      },
      actionNewRecord() {
        if (this.newRecord.isModal) {
          this.$emit('app-smart-list-action-new-record');
        } else {
          this.$router.push({ name: this.newRecord.routeName });
        }
      },
      importData(response) {
        this.tableItems = response.data;
        this.pagination = response.pagination;
      },
      exportData(data) {
        let action = this.actions.exportExcel.action;

        this.$store.dispatch(action, { data });
      },
      getList(data) {
        let action = this.actions.viewList.action;

        this.$store.dispatch(action, { data });
      },
      initAppSmartList() {
        this.updateParentFilterValue();
        this.initBreadCrumb(this.breadcrumbData);
      },
      actionPromotionApply(value) {
        this.$emit('app-smart-list-action-promottion-apply', value);
      },
      actionPromotionApplyAll(value) {
        this.$emit('app-smart-list-action-promottion-apply-all', value);
      },
      actionPromotionDelete(value) {
        this.$emit('app-smart-list-action-promottion-delete', value);
      },
      actionPromotionDeleteAll(value) {
        this.$emit('app-smart-list-action-promottion-delete-all', value);
      }
    }
  };
</script>

<style lang="scss"></style>
