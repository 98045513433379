import { COUNTRY_CODE } from '@/core/constants';

export default function contactNumber(value) {
  let newValue = null;

  if (value) {
    let numberLength = value.length;
    let trimmedLengthEnd = numberLength - 1;
    let trimmedLengthStart = numberLength == 11 ? 2 : 1;

    if (numberLength > 9) {
      value = value.toString().substr(trimmedLengthStart, trimmedLengthEnd);
    }

    newValue = `+${COUNTRY_CODE.MYR}-${value}`;
  } else {
    newValue = '-';
  }

  return newValue;
}
