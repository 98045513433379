<template>
  <div class="d-flex align-items-center font-weight-bolder">
    <InputSwitch
      class="app-switch"
      :disabled="disabled"
      :value="value"
      @input="input"
    />
    <div v-if="stateObj.filterCategory" class="px-3">
      <div>
        <span class="pr-3">:</span>
        <router-link
          :to="{
            name: stateObj.subRouterName
          }"
          class="text-hover-decoration-underline"
          size="sm"
          >{{ stateObj.filterCategoryName }}</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
  import commonMixin from '@/core/mixins/common.mixin';

  export default {
    name: 'AppSwitchWithLabel',
    mixins: [commonMixin],
    data: () => ({
      routeName: null
    }),
    props: {
      value: {
        type: Boolean,
        default: false
      },
      id: {
        type: Number,
        default: null
      },
      itemKey: {
        type: String,
        default: ''
      },
      disabled: {
        type: Boolean,
        default: false
      },
      stateObj: {
        type: Object,
        default: () => []
      },
      switchActionsOnj: {
        type: Object,
        default: () => {
          return {
            module: '',
            state: '',
            actionSet: '',
            actionInitial: '',
            successAction: () => {},
            errorActionL: () => {},
            title: ''
          };
        }
      }
    },
    computed: {
      updateSwitchComplete() {
        let obj = this.switchActionsOnj;
        return this.$store.state[obj.module][obj.state].complete;
      }
    },
    watch: {
      updateSwitchComplete() {
        let obj = this.switchActionsOnj;
        let response = this.$store.state[obj.module][obj.state];
        let title = obj.title;
        let initialStateAction = obj.actionInitial;
        let successAction = obj.successAction;
        let errorAction = obj.errorAction;

        if (response.complete) {
          this.actionUpdateRecordCompleteWithoutSuccessModal(
            response,
            title,
            initialStateAction,
            successAction,
            errorAction
          );
        }
      }
    },
    methods: {
      input(value) {
        let filterCategory;
        const queryString = window.location.pathname.split('/');
        if (value === false || value === null || value === undefined) {
          filterCategory = null;
        } else {
          filterCategory = queryString[4];
        }
        let data = {
          filterCategory: filterCategory
        };
        this.updateAction(this.id, data);
      },
      updateAction(id, data) {
        this.$store.dispatch(this.switchActionsOnj.actionSet, { id, data });
      }
    }
  };
</script>

<style lang="scss">
  .app-switch {
    .p-inputswitch-slider {
      border-radius: 12px !important;
      &::before {
        border-radius: 8px !important;
      }
    }
  }
</style>
