import { apiService } from '@/core/services';

export const STUDIO = {
  getStudioBookings,
  getStudioBookingsCalendar,
  getStudioBooking,
  createStudioBooking,
  updateStudioBooking,
  updateStudioBookingStatus,
  uploadStudioBookingDocuments,
  deleteStudioBookingDocuments,
  deleteStudioBooking
};

const URL = {
  ADMIN: 'admin',
  STUDIOS: 'studios',
  DOCUMENTS: 'documents',
  UPDATE_STATUS: 'update-status',
  UPLOAD_DOCUMENTS: 'upload-documents',
  DESTROY_DOCUMENTS: 'destroy-documents',
  UNAVAIABLE_DATE_BOOKING: 'unavaiable-date-booking'
};

function getStudioBookings(data) {
  let requestOptions = {
    params: {
      company_name: data.companyName,
      person_in_charge: data.name,
      email: data.email,
      status: data.status,
      per_page: data.perPage,
      page: data.page
    }
  };
  return apiService().get(`/${URL.ADMIN}/${URL.STUDIOS}`, requestOptions);
}

function getStudioBookingsCalendar(data) {
  let requestOptions = {
    params: {
      status: data.status,
      today: data.today,
      last_day: data.lastDay
    }
  };
  return apiService().get(
    `/${URL.ADMIN}/${URL.STUDIOS}/${URL.UNAVAIABLE_DATE_BOOKING}`,
    requestOptions
  );
}

function getStudioBooking(id) {
  return apiService().get(`/${URL.ADMIN}/${URL.STUDIOS}/${id}`);
}

function createStudioBooking(data) {
  let requestBody = {
    person_in_charge: data.name,
    contact_number: '60' + data.contactNumber,
    company_name: data.companyName,
    email: data.email,
    preferred_start_date: new Date(data.preferredStart).getTime(),
    preferred_end_date: new Date(data.preferredEnd).getTime(),
    number_of_pax: data.numberOfPax,
    remark: data.remark
  };
  return apiService().post(`/${URL.ADMIN}/${URL.STUDIOS}`, requestBody);
}

function updateStudioBooking(id, data) {
  let requestBody = {
    person_in_charge: data.name,
    contact_number: '60' + data.contactNumber,
    company_name: data.companyName,
    email: data.email,
    preferred_start_date: new Date(data.preferredStart).getTime(),
    preferred_end_date: new Date(data.preferredEnd).getTime(),
    number_of_pax: data.numberOfPax,
    remark: data.remark
  };
  return apiService().put(`/${URL.ADMIN}/${URL.STUDIOS}/${id}`, requestBody);
}

function uploadStudioBookingDocuments(formData) {
  return apiService().post(
    `/${URL.ADMIN}/${URL.STUDIOS}/${URL.UPLOAD_DOCUMENTS}`,
    formData
  );
}

function updateStudioBookingStatus(id, data) {
  let requestBody = {
    status: data
  };
  return apiService().patch(
    `/${URL.ADMIN}/${URL.STUDIOS}/${id}/${URL.UPDATE_STATUS}`,
    requestBody
  );
}

function deleteStudioBookingDocuments(id, fileId) {
  return apiService().delete(
    `/${URL.ADMIN}/${URL.STUDIOS}/${id}/${URL.DESTROY_DOCUMENTS}/${fileId}`
  );
}

function deleteStudioBooking(id) {
  return apiService().delete(`/${URL.ADMIN}/${URL.STUDIOS}/${id}`);
}
