import dateTimeFormat from '@/core/filters/dateTimeFormat.filter';
import { STUDIO } from '@/core/api';
import { apiHelper } from '@/core/utils';
import {
  LOADING_OPEN_LOADING,
  LOADING_CLOSE_LOADING
} from '@/core/store/loading.module';

/* Define Module Name */
const MODULE_NAME = 'studio/';

/* Define Action Name */
const A_GET_BOOKINGS = 'getBookings';
const A_GET_BOOKINGS_CALENDAR = 'getBookingsCalendar';
const A_GET_BOOKING = 'getBooking';
const A_CREATE_BOOKING = 'createBooking';
const A_UPDATE_BOOKING = 'updateBooking';
const A_UPDATE_BOOKING_STATUS = 'updateBookingStatus';
const A_UPLOAD_BOOKING_DOCUMENTS = 'uploadBookingDocuments';
const A_DELETE_BOOKING_DOCUMENTS = 'deleteBookingDocuments';
const A_DELETE_BOOKING = 'deleteBooking';

/* Define Reset State Action Name */
const A_INITIAL_GET_BOOKINGS_STATE = 'initialGetBookingsState';
const A_INITIAL_GET_BOOKINGS_CALENDAR_STATE = 'initialGetBookingsCalendarState';
const A_INITIAL_GET_BOOKING_STATE = 'initialGetBookingState';
const A_INITIAL_CREATE_BOOKING_STATE = 'initialCreateBookingState';
const A_INITIAL_UPDATE_BOOKING_STATE = 'initialUpdateBookingState';
const A_INITIAL_UPDATE_BOOKING_STATUS_STATE = 'initialUpdateBookingStatusState';
const A_INITIAL_UPLOAD_BOOKING_DOCUMENTS_STATE =
  'initialUploadBookingDocumentsState';
const A_INITIAL_DELETE_BOOKING_DOCUMENTS_STATE =
  'initialDeleteBookingDocumentsState';
const A_INITIAL_DELETE_BOOKING_STATE = 'initialDeleteBookingState';

/* Define Getter Name */

/* Define Mutation Name */
const M_GET_BOOKINGS = 'setGetBookings';
const M_GET_BOOKINGS_CALENDAR = 'setGetBookingsCalendar';
const M_GET_BOOKING = 'setGetBooking';
const M_CREATE_BOOKING = 'setCreateBooking';
const M_UPDATE_BOOKING = 'setUpdateBooking';
const M_UPDATE_BOOKING_STATUS = 'setUpdateBookingStatus';
const M_UPLOAD_BOOKING_DOCUMENTS = 'setUploadBookingDocuments';
const M_DELETE_BOOKING_DOCUMENTS = 'setDeleteBookingDocuments';
const M_DELETE_BOOKING = 'setDeleteBooking';

/* Define Reset State Mutation Name */
const M_INITIAL_GET_BOOKINGS_STATE = 'setInitialGetBookingsState';
const M_INITIAL_GET_BOOKINGS_CALENDAR_STATE =
  'setInitialGetBookingsCalendarState';
const M_INITIAL_GET_BOOKING_STATE = 'setInitialGetBookingState';
const M_INITIAL_CREATE_BOOKING_STATE = 'setInitialCreateBookingState';
const M_INITIAL_UPDATE_BOOKING_STATE = 'setInitialUpdateBookingState';
const M_INITIAL_UPDATE_BOOKING_STATUS_STATE =
  'setInitialUpdateBookingStatusState';
const M_INITIAL_UPLOAD_BOOKING_DOCUMENTS_STATE =
  'setInitialUploadBookingDocumentsState';
const M_INITIAL_DELETE_BOOKING_DOCUMENTS_STATE =
  'setInitialDeleteBookingDocumentsState';
const M_INITIAL_DELETE_BOOKING_STATE = 'setInitialDeleteBookingState';

/* Define Export Name */
export const STUDIO_GET_BOOKINGS = MODULE_NAME + A_GET_BOOKINGS;
export const STUDIO_GET_BOOKINGS_CALENDAR =
  MODULE_NAME + A_GET_BOOKINGS_CALENDAR;
export const STUDIO_GET_BOOKING = MODULE_NAME + A_GET_BOOKING;
export const STUDIO_CREATE_BOOKING = MODULE_NAME + A_CREATE_BOOKING;
export const STUDIO_UPDATE_BOOKING = MODULE_NAME + A_UPDATE_BOOKING;
export const STUDIO_UPDATE_BOOKING_STATUS =
  MODULE_NAME + A_UPDATE_BOOKING_STATUS;
export const STUDIO_UPLOAD_BOOKING_DOCUMENTS =
  MODULE_NAME + A_UPLOAD_BOOKING_DOCUMENTS;
export const STUDIO_DELETE_BOOKING_DOCUMENTS =
  MODULE_NAME + A_DELETE_BOOKING_DOCUMENTS;
export const STUDIO_DELETE_BOOKING = MODULE_NAME + A_DELETE_BOOKING;

/* Define Reset State Name */
export const STUDIO_INITIAL_GET_BOOKINGS_STATE =
  MODULE_NAME + A_INITIAL_GET_BOOKINGS_STATE;
export const STUDIO_INITIAL_GET_BOOKINGS_CALENDAR_STATE =
  MODULE_NAME + A_INITIAL_GET_BOOKINGS_CALENDAR_STATE;
export const STUDIO_INITIAL_GET_BOOKING_STATE =
  MODULE_NAME + A_INITIAL_GET_BOOKING_STATE;
export const STUDIO_INITIAL_CREATE_BOOKING_STATE =
  MODULE_NAME + A_INITIAL_CREATE_BOOKING_STATE;
export const STUDIO_INITIAL_UPDATE_BOOKING_STATE =
  MODULE_NAME + A_INITIAL_UPDATE_BOOKING_STATE;
export const STUDIO_INITIAL_UPDATE_BOOKING_STATUS_STATE =
  MODULE_NAME + A_INITIAL_UPDATE_BOOKING_STATUS_STATE;
export const STUDIO_INITIAL_UPLOAD_BOOKING_DOCUMENTS_STATE =
  MODULE_NAME + A_INITIAL_UPLOAD_BOOKING_DOCUMENTS_STATE;
export const STUDIO_INITIAL_DELETE_BOOKING_DOCUMENTS_STATE =
  MODULE_NAME + A_INITIAL_DELETE_BOOKING_DOCUMENTS_STATE;
export const STUDIO_INITIAL_DELETE_BOOKING_STATE =
  MODULE_NAME + A_INITIAL_DELETE_BOOKING_STATE;

const state = {
  bookings: {
    code: 0,
    complete: false,
    data: null,
    message: null,
    pagination: {
      currentPage: 0,
      total: 0,
      perPage: 0,
      lastPage: 0
    }
  },
  bookingsCanlendar: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  booking: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  createBooking: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  updateBooking: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  updateBookingStatus: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  uploadBookingDocuments: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  deleteBookingDocuments: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  deleteBooking: {
    code: 0,
    complete: false,
    data: null,
    message: null
  }
};

//Getters
const getters = {};

// Actions
const actions = {
  [A_GET_BOOKINGS]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    STUDIO.getStudioBookings(data)
      .then((response) => {
        result = response;
        commit(M_GET_BOOKINGS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_BOOKINGS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_BOOKINGS_CALENDAR]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    STUDIO.getStudioBookingsCalendar(data)
      .then((response) => {
        result = response;
        commit(M_GET_BOOKINGS_CALENDAR, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_BOOKINGS_CALENDAR, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_BOOKING]({ dispatch, commit }, { id }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    STUDIO.getStudioBooking(id)
      .then((response) => {
        result = response;
        commit(M_GET_BOOKING, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_BOOKING, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_CREATE_BOOKING]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    STUDIO.createStudioBooking(data)
      .then((response) => {
        result = response;
        commit(M_CREATE_BOOKING, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_CREATE_BOOKING, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_UPDATE_BOOKING]({ dispatch, commit }, { id, data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    STUDIO.updateStudioBooking(id, data)
      .then((response) => {
        result = response;
        commit(M_UPDATE_BOOKING, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_UPDATE_BOOKING, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_UPDATE_BOOKING_STATUS]({ dispatch, commit }, { id, data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    STUDIO.updateStudioBookingStatus(id, data)
      .then((response) => {
        result = response;
        commit(M_UPDATE_BOOKING_STATUS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_UPDATE_BOOKING_STATUS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_UPLOAD_BOOKING_DOCUMENTS]({ dispatch, commit }, { formData }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    STUDIO.uploadStudioBookingDocuments(formData)
      .then((response) => {
        result = response;
        commit(M_UPLOAD_BOOKING_DOCUMENTS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_UPLOAD_BOOKING_DOCUMENTS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_DELETE_BOOKING_DOCUMENTS]({ dispatch, commit }, { id, fileId }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    STUDIO.deleteStudioBookingDocuments(id, fileId)
      .then((response) => {
        result = response;
        commit(M_DELETE_BOOKING_DOCUMENTS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_DELETE_BOOKING_DOCUMENTS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_DELETE_BOOKING]({ dispatch, commit }, { id }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    STUDIO.deleteStudioBooking(id)
      .then((response) => {
        result = response;
        commit(M_DELETE_BOOKING, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_DELETE_BOOKING, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_INITIAL_GET_BOOKINGS_STATE]({ commit }) {
    commit(M_INITIAL_GET_BOOKINGS_STATE);
  },
  [A_INITIAL_GET_BOOKINGS_CALENDAR_STATE]({ commit }) {
    commit(M_INITIAL_GET_BOOKINGS_CALENDAR_STATE);
  },
  [A_INITIAL_GET_BOOKING_STATE]({ commit }) {
    commit(M_INITIAL_GET_BOOKING_STATE);
  },
  [A_INITIAL_CREATE_BOOKING_STATE]({ commit }) {
    commit(M_INITIAL_CREATE_BOOKING_STATE);
  },
  [A_INITIAL_UPDATE_BOOKING_STATE]({ commit }) {
    commit(M_INITIAL_UPDATE_BOOKING_STATE);
  },
  [A_INITIAL_UPDATE_BOOKING_STATUS_STATE]({ commit }) {
    commit(M_INITIAL_UPDATE_BOOKING_STATUS_STATE);
  },
  [A_INITIAL_UPLOAD_BOOKING_DOCUMENTS_STATE]({ commit }) {
    commit(M_INITIAL_UPLOAD_BOOKING_DOCUMENTS_STATE);
  },
  [A_INITIAL_DELETE_BOOKING_DOCUMENTS_STATE]({ commit }) {
    commit(M_INITIAL_DELETE_BOOKING_DOCUMENTS_STATE);
  },
  [A_INITIAL_DELETE_BOOKING_STATE]({ commit }) {
    commit(M_INITIAL_DELETE_BOOKING_STATE);
  }
};

// Mutations
const mutations = {
  [M_GET_BOOKINGS](state, result) {
    if (result.code === 0) {
      let d = result.data;
      let p = result.pagination;
      state.bookings = {
        code: result.code,
        complete: true,
        data: d.map((x, index) => {
          return {
            no: index + 1,
            id: x.id,
            companyName: x.company_name ? x.company_name : '-',
            name: x.person_in_charge,
            contactNumber: x.contact_number.slice(1),
            email: x.email ? x.email : '-',
            numberOfPax: x.number_of_pax,
            status: x.status,
            preferredStart: dateTimeFormat(x.start_date),
            preferredEnd: dateTimeFormat(x.end_date)
          };
        }),
        message: null,
        pagination: {
          currentPage: p.current_page,
          total: p.total,
          perPage: p.per_page,
          lastPage: p.last_page
        }
      };
    } else {
      state.bookings = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message),
        pagination: {
          currentPage: 0,
          total: 0,
          perPage: 0,
          lastPage: 0
        }
      };
    }
  },
  [M_GET_BOOKINGS_CALENDAR](state, result) {
    if (result.code === 0) {
      let d = result.data;

      state.bookingsCanlendar = {
        code: result.code,
        complete: true,
        data: d.map((x, index) => {
          return {
            key: index + 1,
            customData: {
              id: x.studio_id,
              title: x.person_in_charge,
              status: x.status
            },
            // dates: { start: new Date(x.start_date), end: new Date(x.end_date) }
            dates: new Date(x.date)
          };
        }),
        message: null
      };
    } else {
      state.bookingsCanlendar = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_GET_BOOKING](state, result) {
    if (result.code === 0) {
      let d = result.data;

      state.booking = {
        code: result.code,
        complete: true,
        data: {
          id: d.id,
          bookingId: d.book_identifier,
          companyName: d.company_name,
          name: d.person_in_charge,
          contactNumber: d.contact_number.slice(2),
          email: d.email,
          numberOfPax: d.number_of_pax != 0 ? d.number_of_pax : '',
          status: d.status,
          preferredStart: dateTimeFormat(d.start_date),
          preferredEnd: dateTimeFormat(d.end_date),
          remark: d.remark,
          documents: d.documents.map((x) => {
            return {
              id: x.id,
              mediaPath: x.media_path,
              mediaType: x.media_type,
              timer: x.timer
            };
          })
        },
        message: null
      };
    } else {
      state.booking = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_CREATE_BOOKING](state, result) {
    state.createBooking = {
      code: result.code,
      complete: true,
      data: result.data,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_UPDATE_BOOKING](state, result) {
    state.updateBooking = {
      code: result.code,
      complete: true,
      data: result,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_UPDATE_BOOKING_STATUS](state, result) {
    state.updateBookingStatus = {
      code: result.code,
      complete: true,
      data: result,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_UPLOAD_BOOKING_DOCUMENTS](state, result) {
    state.uploadBookingDocuments = {
      code: result.code,
      complete: true,
      data: result,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_DELETE_BOOKING_DOCUMENTS](state, result) {
    state.deleteBookingDocuments = {
      code: result.code,
      complete: true,
      data: result,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_DELETE_BOOKING](state, result) {
    state.deleteBooking = {
      code: result.code,
      complete: true,
      data: result,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_INITIAL_GET_BOOKINGS_STATE](state) {
    state.bookings = {
      code: 0,
      complete: false,
      data: null,
      message: null,
      pagination: {
        currentPage: 0,
        total: 0,
        perPage: 0,
        lastPage: 0
      }
    };
  },
  [M_INITIAL_GET_BOOKINGS_CALENDAR_STATE](state) {
    state.bookingsCanlendar = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_GET_BOOKING_STATE](state) {
    state.booking = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_CREATE_BOOKING_STATE](state) {
    state.createBooking = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_UPDATE_BOOKING_STATE](state) {
    state.updateBooking = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_UPDATE_BOOKING_STATUS_STATE](state) {
    state.updateBookingStatus = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_UPLOAD_BOOKING_DOCUMENTS_STATE](state) {
    state.uploadBookingDocuments = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_DELETE_BOOKING_DOCUMENTS_STATE](state) {
    state.deleteBookingDocuments = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_DELETE_BOOKING_STATE](state) {
    state.deleteBooking = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
