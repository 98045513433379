var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('AppFormField',{attrs:{"title":_vm.$t(`label.documents`),"formType":'file',"change":_vm.uploadBookingDocuments(),"state":_vm.validateState('documents'),"validators":[
      'required',
      'bannerImageType',
      'fileSize'
      // 'bannerImageDimension'
    ],"validationValue":_vm.$v.documents,"validationParams":[
      {
        key: 'bannerImageType',
        params: [
          _vm.uiHelper.arrayToTrimmedStringWithSpace(
            _vm.global.AVAILABLE_IMAGE_TYPE,
            6
          )
        ]
      },
      {
        key: 'fileSize',
        params: [
          _vm.global.BANNER_IMAGE_UPLOAD_SIZE,
          _vm.global.BANNER_IMAGE_UPLOAD_SIZE_TYPE
        ]
      }
      // {
      //   key: 'bannerImageDimension',
      //   params: [dimension.height, dimension.width]
      // }
    ],"accept":_vm.global.AVAILABLE_IMAGE_TYPE.toString(),"solid":""},model:{value:(_vm.$v.documents.$model),callback:function ($$v) {_vm.$set(_vm.$v.documents, "$model", $$v)},expression:"$v.documents.$model"}}),_c('div',{staticStyle:{"display":"none"}},[_vm._v(" "+_vm._s(_vm.$v)+" ")]),_c('b-row',_vm._l((_vm.attachment),function(item,index){return _c('b-col',{key:index,staticClass:"mt-4",attrs:{"cols":"6","md":"4","xl":"3"}},[_c('b-card',{staticClass:"pointer h-100 shadow-sm card-custom rounded-0 overlay",attrs:{"no-body":""}},[_c('div',{staticClass:"card-body p-0"},[_c('div',{staticClass:"overlay-wrapper"},[_c('div',{class:`${
                _vm.isImage(item.mediaType) ? 'overflow-hidden' : 'p-3'
              }`},[_c('div',{staticClass:"h-100px h-sm-150px h-lg-150px h-xl-200px",class:`${
                  _vm.isImage(item.mediaType) ? 'document-image' : 'document'
                }`,style:(`background-image: url('${_vm.attachmentImage(
                  item.mediaType,
                  item.mediaPath
                )}'); background-size: 100% 100%;`)})])]),_c('div',{staticClass:"overlay-layer"},[_c('div',{staticClass:"text-center"},[_c('AppButtonDocumentView',{attrs:{"attachmentUrl":item.mediaPath,"fileType":item.mediaType,"buttonType":2}}),(
                  !_vm.checkAccessRight(_vm.moduleName, [_vm.permissionAction.DELETE])
                )?_c('b-button',{staticClass:"btn btn-danger font-weight-bold text-uppercase mt-3",attrs:{"variant":"shadow-sm font-size-sm"},on:{"click":function($event){return _vm.deleteBookingDocuments(_vm.id, item.id)}}},[_vm._v(" "+_vm._s(_vm.$t(`label.delete`))+" ")]):_vm._e()],1)])])])],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }