<template>
  <div>
    <div class="d-flex align-items-start pb-5">
      <template v-for="(i, index) in formFields">
        <AppFormField
          :key="index"
          v-model="$v.discount[i.model].$model"
          :formType="i.formType"
          :options="i.options"
          :state="validateObjState('discount', i.model)"
          :validators="i.validators"
          :validationValue="$v.discount[i.model]"
          :validationParams="i.validationParams"
          :class="i.class"
          :disabled="disabled"
          class="w-100"
          solid
          noTitle
          @input="input"
        >
        </AppFormField>
      </template>
    </div>
  </div>
</template>

<script>
  import AppFormField from '@/views/components/bases/form-field/AppFormField';
  import commonMixin from '@/core/mixins/common.mixin';
  import { price } from '@/core/constants';
  import { listService } from '@/core/services';

  export default {
    name: 'AppAmountType',
    mixins: [commonMixin],
    components: {
      AppFormField
    },
    props: {
      item: {
        type: Object,
        default: () => {}
      },
      options: {
        type: Array,
        default: () => []
      },
      value: {
        type: Object,
        default: () => ({
          value: null
        })
      },
      solid: {
        type: Boolean,
        default: true
      },
      disabled: {
        type: Boolean,
        default: false
      },
      state: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      formFields: [],
      cashValueType: null,
      cashValue: null
    }),
    computed: {
      discount() {
        return { type: this.value.type, value: this.value.value };
      }
    },
    mounted() {
      this.initFormFields();
    },
    methods: {
      validationCheckDiscountFeild() {
        return this.validationCheck('discount');
      },
      initFormFields() {
        this.formFields = [];
        this.formFields.push(
          {
            model: 'type',
            title: 'type',
            formType: 'select',
            class: 'pr-2',
            options: listService.getAmoutTypeList(false),
            validationParams: []
          },
          {
            title: 'value',
            model: 'value',
            class: 'pl-2',
            validators: ['price'],
            validationParams: []
          }
        );
        this.$v.discount.$reset();
      },
      input() {
        this.$emit('input', this.discount);
      }
    },
    validations() {
      return {
        discount: {
          type: {},
          value: { price }
        }
      };
    }
  };
</script>

<style></style>
