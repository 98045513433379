<template>
  <b-table-simple hover stacked="md" responsive class="border">
    <b-thead>
      <b-tr>
        <b-th>{{ $t('label.property') }}</b-th>
        <b-th>{{ $t('label.from') }}</b-th>
        <b-th>{{ $t('label.to') }} </b-th>
      </b-tr>
    </b-thead>
    <b-tbody>
      <template v-for="(i, index) in value">
        <component :key="index" :is="i.layout" :item="i"></component>
      </template>
    </b-tbody>
  </b-table-simple>
</template>

<script>
  import ChangesUserRow from '@/views/components/audit-log/changes-table-row/ChangesUserRow';
  import ChangesOrderRow from '@/views/components/audit-log/changes-table-row/ChangesOrderRow';

  export default {
    name: 'AuditLogCompareTable',
    components: {
      ChangesUserRow,
      ChangesOrderRow
    },
    props: {
      value: {
        type: Array,
        default: () => []
      },
      type: {
        type: Number,
        default: null
      }
    }
  };
</script>

<style></style>
