<template>
  <div>
    <!--begin: App Smart Table-->
    <AppSmartTable
      :items="tableItems"
      :headers="tableHeaders"
      :pagination="tablePagination"
      @app-smart-table-pagination-change="actionPaginationChange"
      @app-table-read-details="actionReadDetails"
    ></AppSmartTable>
    <!--end: App Smart Table-->

    <!--begin: App Modal Description List-->
    <AppModalDescriptionList
      v-model="appModalDescriptionListObj.show"
      :title="appModalDescriptionListObj.title"
      :descriptions="appModalDescriptionListObj.descriptions"
      :dataObject="appModalDescriptionListObj.dataObject"
    ></AppModalDescriptionList>
    <!--end: App Modal Description List-->
  </div>
</template>

<script>
  import AppSmartTable from '@/views/components/bases/smart/AppSmartTable';
  import AppModalDescriptionList from '@/views/components/bases/modal/AppModalDescriptionList';
  import crudMixin from '@/core/mixins/crud.mixin';
  import { GLOBAL } from '@/core/constants';
  import { i18nHelper } from '@/core/utils';

  export default {
    name: 'UserAddressBookTable',
    components: {
      AppSmartTable,
      AppModalDescriptionList
    },
    mixins: [crudMixin],
    props: {
      id: {
        required: true
      },
      tableHeaders: {
        type: Array,
        default: () => []
      },
      parentActions: {
        type: Object,
        default: () => {}
      },
      descriptions: {
        type: Array,
        default: () => []
      }
    },
    data: () => ({
      searchCriteria: GLOBAL.DEFAULT_SEARCH_CRITERIA,
      tableItems: [],
      tablePagination: GLOBAL.DEFAULT_TABLE_PAGINATION,
      actions: {},
      appModalDescriptionListObj: {
        show: false,
        title: '',
        descriptions: [],
        dataObject: {}
      }
    }),
    created() {
      this.initUserAddressBookTable();
    },
    methods: {
      actionReadDetails(id) {
        this.readRecordWithExtraId(this.id, id);
      },
      actionPaginationChange(value) {
        this.searchCriteria = {
          perPage: value.size,
          page: value.page
        };

        this.readListRecordWithId(this.id, this.searchCriteria);
      },
      getAddressBooks() {
        this.readListRecordWithId(this.id, this.searchCriteria);
      },
      openAddressBookDetailsModal(response) {
        let title = i18nHelper.getMessage('label.creatorAddressBook');

        this.appModalDescriptionListObj = {
          show: true,
          title: title,
          descriptions: this.descriptions,
          dataObject: response.data
        };
      },
      importListData(response) {
        this.tableItems = response.data;
        this.tablePagination = response.pagination;
      },
      importSuccessAction() {
        this.actions.readListRecordWithId.successAction = (response) => {
          this.importListData(response);
        };

        this.actions.readRecordWithExtraId.successAction = (response) => {
          this.openAddressBookDetailsModal(response);
        };
      },
      initUserAddressBookTable() {
        this.actions = this.parentActions;
        this.importSuccessAction();
      }
    }
  };
</script>

<style></style>
