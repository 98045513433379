<template>
  <b-card class="mt-4">
    <b-card-title class="text-capitalize font-weight-bolder">
      {{ $t('label.itemsDetails') }}
    </b-card-title>

    <b-card-text v-if="value.orderItems">
      <div v-if="value.orderItems.length >= 1">
        <template v-for="(item, index) in value.orderItems">
          <div :key="index">
            <div
              v-b-toggle="`collapse-${index}`"
              class="order-item-details-card__collapse-toggle py-3 d-flex justify-content-between flex-stack flex-wrap cursor-pointer"
            >
              <!--begin::Toggle-->
              <div
                class="d-flex align-items-center justify-content-between collapsible"
              >
                <!--begin::Arrow-->
                <div
                  class="order-item-details-card__collapse-toggle-arrow-container me-3"
                >
                  <!--begin::Svg Icon-->
                  <i class="ki ki-arrow-next text-dark"></i>
                  <!--end::Svg Icon-->
                </div>
                <!--end::Arrow-->

                <!--begin::Thumbnail-->
                <img
                  :src="item.thumbnail ? item.thumbnail : dummyThumbnail"
                  class="w-40px h-40px me-3 mx-6"
                  style="object-fit: cover"
                  alt=""
                />
                <!--end::Thumbnail-->

                <!--begin::Summary-->
                <div class="me-3">
                  <div class="d-flex align-items-center">
                    <div class="text-gray-800 fw-bolder mr-4">
                      {{ item.name }}
                    </div>
                    <div
                      class="label label-md label-inline label-light-primary text-capitalize"
                    >
                      {{ item.model }}
                    </div>
                  </div>
                  <div class="text-muted">{{ item.description }}</div>
                </div>

                <!--end::Summary-->
              </div>
              <span class="pl-auto h3">X{{ item.quantity }}</span>
              <!--end::Toggle-->
            </div>

            <b-collapse :id="`collapse-${index}`" class="mt-2">
              <b-row no-gutters class="p-5">
                <template v-for="(option, optionIndex) in orderItemOptions">
                  <b-col
                    v-if="option.type ? item.model == option.type : true"
                    :key="optionIndex"
                    :cols="option.key == 'variation' ? 12 : 6"
                    class="mb-4"
                  >
                    <table>
                      <tbody>
                        <tr>
                          <td class="font-weight-bold min-w-125px align-top">
                            <span> {{ $t(`label.${option.name}`) }}:</span>
                          </td>
                          <td class="w-100">
                            <b-table-simple
                              v-if="option.key == 'variation'"
                              hover
                              stacked="md"
                              responsive
                              class="border"
                            >
                              <b-thead>
                                <b-tr>
                                  <template
                                    v-for="(
                                      variant, variantIndex
                                    ) in variationList"
                                  >
                                    <b-th :key="variantIndex">
                                      {{ $t(`label.${variant.name}`) }}</b-th
                                    >
                                  </template>
                                </b-tr>
                              </b-thead>
                              <b-tbody>
                                <template
                                  v-for="(
                                    variant, variantIndex
                                  ) in variationList"
                                >
                                  <b-td :key="variantIndex">
                                    <component
                                      v-if="variant.layout"
                                      :is="variant.layout"
                                      :value="
                                        item.options[option.key][variant.key]
                                      "
                                    ></component>
                                    <span v-else>
                                      {{
                                        item.options[option.key][variant.key]
                                      }}</span
                                    >
                                  </b-td>
                                </template>
                              </b-tbody>
                            </b-table-simple>

                            <span v-else-if="option.key == 'isEco'">
                              {{
                                $t(`enumRecipeType.${item.options[option.key]}`)
                              }}
                            </span>
                            <span v-else class="text-gray-600">{{
                              item.options[option.key]
                                ? item.options[option.key]
                                : '-'
                            }}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </b-col>
                </template>
              </b-row>
              <!-- begin:: Recipe Options Table -->
              <template v-if="item.model == 'recipe'">
                <!-- begin:: Variations Table -->
                <div class="p-5" v-if="item.options.variations.length">
                  <div class="text-capitalize font-weight-bolder h5 mb-6">
                    {{ $t('label.variations') }}
                  </div>
                  <AppSmartTable
                    :items="item.options.variations"
                    :headers="variationsTableHeader"
                    hidePagination
                  ></AppSmartTable>
                </div>
                <!-- end:: Variations Table -->

                <!-- begin:: Equipment Table -->
                <div class="p-5" v-if="item.options.equipment.length">
                  <div class="text-capitalize font-weight-bolder h5 mb-6">
                    {{ $t('label.equipment') }}
                  </div>
                  <AppSmartTable
                    :items="item.options.equipment"
                    :headers="equipmentTableHeader"
                    hidePagination
                  ></AppSmartTable>
                </div>
                <!-- end:: Equipment Table -->

                <!-- begin:: Ingredients Table -->
                <div class="p-5" v-if="item.options.ingredients.length">
                  <div class="text-capitalize font-weight-bolder h5 mb-6">
                    {{ $t('label.ingredients') }}
                  </div>
                  <AppSmartTable
                    :items="item.options.ingredients"
                    :headers="ingredientsTableHeader"
                    hidePagination
                  ></AppSmartTable>
                </div>
                <!-- end:: Ingredients Table -->
              </template>
              <!-- end:: Recipe Options Table -->

              <!-- begin:: Add On Items Table -->
              <template v-if="item.model == 'product'">
                <!-- begin:: Ingredients Table -->
                <div class="p-5">
                  <div class="text-capitalize font-weight-bolder h5 mb-6">
                    {{ $t('label.addOnItems') }}
                  </div>
                  <AppSmartTable
                    :items="item.options.addOns"
                    :headers="addOnTableHeaders"
                    hidePagination
                  ></AppSmartTable>
                </div>
                <!-- end:: Add On Items Table -->
              </template>
              <!-- end:: Recipe Options Table -->
            </b-collapse>

            <div
              :key="index + 'separator'"
              class="separator separator-dashed"
            ></div>
          </div>
        </template>
      </div>
      <div v-else>{{ $t('label.thereAreNoItemsToShow') }}</div>
    </b-card-text>
  </b-card>
</template>

<script>
  import AppSmartTable from '@/views/components/bases/smart/AppSmartTable';
  import AppCheckCircle from '@/views/components/bases/AppCheckCircle';
  import { APP_COMPONENT_NAME } from '@/core/constants';
  import { RecipeType } from '@/core/constants/enums';

  export default {
    name: 'OrderItemDetailsCard',
    components: {
      AppSmartTable,
      AppCheckCircle
    },
    props: {
      value: {
        type: Object,
        default: () => {}
      }
    },
    data: () => ({
      recipeType: RecipeType,
      orderItemOptions: [
        {
          key: 'isEco',
          name: 'type',
          type: 'recipe'
        },
        {
          key: 'variation',
          name: 'variation',
          type: 'product'
        },
        {
          key: 'remark',
          name: 'remark'
        }
      ],
      variationList: [
        {
          key: 'name',
          name: 'name'
        },
        {
          key: 'price',
          name: 'price'
        },
        {
          key: 'isActive',
          name: 'isActive',
          layout: APP_COMPONENT_NAME.APP_CHECK_CIRCLE
        }
      ],
      variationsTableHeader: [
        {
          key: 'name',
          name: 'name'
        },
        {
          key: 'price',
          name: 'price'
        },
        {
          key: 'isActive',
          name: 'isActive',
          layout: APP_COMPONENT_NAME.APP_CHECK_CIRCLE
        }
      ],
      equipmentTableHeader: [
        {
          key: 'no',
          name: 'no'
        },
        {
          key: 'brandName',
          name: 'brandName'
        },
        {
          key: 'name',
          name: 'name'
        },
        {
          key: 'quantity',
          name: 'quantity'
        },
        {
          key: 'price',
          name: 'price'
        }
      ],
      ingredientsTableHeader: [
        {
          key: 'no',
          name: 'no'
        },
        {
          key: 'name',
          name: 'name'
        },
        {
          key: 'count',
          name: 'count'
        },
        {
          key: 'unit',
          name: 'unit'
        },
        {
          key: 'price',
          name: 'price'
        }
      ],
      addOnTableHeaders: [
        {
          key: 'no',
          name: 'no',
          sortable: true
        },
        {
          key: 'name',
          name: 'name',
          sortable: true
        },
        {
          key: 'price',
          name: 'price',
          sortable: true
        },
        {
          key: 'quantity',
          name: 'quantity',
          sortable: true
        },
        {
          key: 'isActive',
          name: 'isActive',
          sortable: true,
          layout: APP_COMPONENT_NAME.APP_CHECK_CIRCLE
        }
      ],
      dummyThumbnail: '/media/dummy/dummy-no-thumbnail.png'
    })
  };
</script>

<style lang="scss">
  .order-item-details-card__collapse-toggle {
    &.not-collapsed .order-item-details-card__collapse-toggle-arrow-container {
      transform: rotate(90deg);
    }

    .order-item-details-card__collapse-toggle-arrow-container {
      transition: ease-in-out 0.25s;
    }
  }
</style>
