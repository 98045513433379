<template>
  <div>
    <h5 class="mb-10 font-weight-bold text-dark text-capitalize">
      {{ $t('label.help') }}
    </h5>
    <b-row>
      <template v-for="(i, index) in formFields">
        <b-col :key="index" xl="6" class="p-xl-1 py-0">
          <AppFormField
            v-model="$v.platformInfoObj[i.model].$model"
            :state="validateObjState('platformInfoObj', i.model)"
            :validationValue="$v.platformInfoObj[i.model]"
            :title="$t(`label.${i.title}`)"
            :solid="true"
            :validators="i.validators"
            :formType="i.formType"
            :disabled="
              !checkAccessRight(moduleName, [action.PLATFORM_INFO_UPDATE])
            "
          ></AppFormField>
          <div style="display: none">
            <!-- WARNING: I have no idea why this line is here, but removing it breaks everything -->
            {{ $v }}
          </div>
        </b-col>
      </template>
    </b-row>

    <div
      v-if="checkAccessRight(moduleName, [action.PLATFORM_INFO_UPDATE])"
      class="d-flex justify-content-end border-top pt-10"
    >
      <b-button
        variant="light-primary"
        class="font-weight-bold text-uppercase px-9 py-4 mr-4"
        @click="reset"
      >
        {{ $t('label.reset') }}
      </b-button>
      <b-button
        variant="primary"
        class="font-weight-bold text-uppercase px-9 py-4"
        @click="saveChanges"
      >
        {{ $t('label.saveChanges') }}
      </b-button>
    </div>
  </div>
</template>

<script>
  import AppFormField from '@/views/components/bases/form-field/AppFormField';
  import commonMixin from '@/core/mixins/common.mixin';
  import { validationMixin } from 'vuelidate';
  import { email } from 'vuelidate/lib/validators';
  import { MODULE, ACTION, urlOptionalProtocol } from '@/core/constants';
  import { i18nHelper } from '@/core/utils';
  import {
    PLATFORM_INFO_UPDATE_PLATFORM_INFO,
    PLATFORM_INFO_INITIAL_UPDATE_PLATFORM_INFO_STATE
  } from '@/core/store/platform-info.module';

  export default {
    name: 'PlatformInfoContactUsAndHelp',
    mixins: [commonMixin, validationMixin],
    components: {
      AppFormField
    },
    props: {
      platformInfo: {
        type: Object,
        default: () => {}
      }
    },
    data: () => ({
      platformInfoObj: {
        name: '',
        phone: '',
        email: '',
        address: '',
        instagram: '',
        facebook: '',
        whatsapp: '',
        youtube: '',
        linkedin: '',
        tiktok: ''
      },
      formFields: [],
      moduleName: MODULE.PLATFORM_INFO,
      action: ACTION
    }),
    computed: {
      updatePlatformInfoComplete() {
        return this.$store.state.platformInfo.updatePlatformInfo.complete;
      }
    },
    watch: {
      platformInfo() {
        this.importPlatformInfoObj();
      },
      updatePlatformInfoComplete() {
        let response = this.$store.state.platformInfo.updatePlatformInfo;
        let title = i18nHelper.getMessage('label.updatePlatformInfo');
        let initialStateAction =
          PLATFORM_INFO_INITIAL_UPDATE_PLATFORM_INFO_STATE;
        let successAction = () => {
          this.updatePlatformInfoCompleteSuccessAction();
        };

        if (response.complete) {
          this.actionUpdateRecordComplete(
            response,
            title,
            initialStateAction,
            successAction
          );
        }
      }
    },
    mounted() {
      this.initFormFields();
      this.importPlatformInfoObj();
    },
    methods: {
      updatePlatformInfoCompleteSuccessAction() {
        this.$parent.initialPlatformInfo();
        this.$v.$reset();
        window.KTUtil.scrollTop();
      },
      reset() {
        let info = this.platformInfo;

        this.platformInfoObj = {
          name: info.name,
          phone: info.phone,
          email: info.email,
          address: info.address,
          instagram: info.instagram,
          facebook: info.facebook,
          whatsapp: info.whatsapp,
          youtube: info.youtube,
          linkedin: info.linkedin,
          tiktok: info.tiktok
        };

        this.$v.$reset();
        window.KTUtil.scrollTop();
      },
      saveChanges() {
        if (this.validationCheck('platformInfoObj')) {
          this.updatePlatformInfo(this.platformInfoObj);
        }
      },
      importPlatformInfoObj() {
        let info = this.platformInfo;

        if (info) {
          this.platformInfoObj = {
            name: info.name,
            phone: info.phone,
            email: info.email,
            address: info.address,
            instagram: info.instagram,
            facebook: info.facebook,
            whatsapp: info.whatsapp,
            youtube: info.youtube,
            linkedin: info.linkedin,
            tiktok: info.tiktok
          };
        }
      },
      updatePlatformInfo(data) {
        this.$store.dispatch(PLATFORM_INFO_UPDATE_PLATFORM_INFO, { data });
      },
      initFormFields() {
        this.formFields.push(
          {
            field: 1,
            model: 'name',
            title: 'name',
            validators: []
          },
          {
            field: 1,
            model: 'phone',
            title: 'phoneNumber',
            validators: []
          },
          {
            field: 1,
            model: 'email',
            title: 'email',
            validators: ['email']
          },
          {
            field: 1,
            model: 'address',
            title: 'address',
            validators: [],
            formType: 'textarea'
          },
          {
            field: 1,
            model: 'instagram',
            title: 'instagram',
            validators: ['urlOptionalProtocol']
          },
          {
            field: 1,
            model: 'facebook',
            title: 'facebook',
            validators: ['urlOptionalProtocol']
          },
          {
            field: 1,
            model: 'whatsapp',
            title: 'whatsapp',
            validators: []
          },
          {
            field: 1,
            model: 'youtube',
            title: 'youtube',
            validators: []
          },
          {
            field: 1,
            model: 'linkedin',
            title: 'linkedin',
            validators: ['urlOptionalProtocol']
          },
          {
            field: 1,
            model: 'tiktok',
            title: 'tiktok',
            validators: ['urlOptionalProtocol']
          }
        );
      }
    },
    validations: {
      platformInfoObj: {
        name: {},
        phone: {},
        email: {
          email
        },
        address: {},
        instagram: {
          urlOptionalProtocol
        },
        facebook: {
          urlOptionalProtocol
        },
        whatsapp: {},
        youtube: {},
        linkedin: {
          urlOptionalProtocol
        },
        tiktok: {
          urlOptionalProtocol
        }
      }
    }
  };
</script>

<style></style>
