<template>
  <div>
    <div v-for="(i, index) in configuration" :key="index">
      <div class="p-5 py-10">
        <components :is="i.leyout"></components>
      </div>
      <div class="separator separator-solid"></div>
    </div>
  </div>
</template>

<script>
  import ConfigPointSetting from '@/views/components/configuration/ConfigPointSetting.vue';

  export default {
    name: 'config-cookies',
    components: {
      ConfigPointSetting
    },
    data: () => ({
      configuration: [
        //Google API
        {
          leyout: 'ConfigPointSetting'
        }
      ]
    })
  };
</script>

<style></style>
