<template>
  <div>
    <!--begin: User Details Section-->
    <b-row no-gutters class="w-100 mt-4">
      <!--begin: Col 1-->
      <b-col cols="12" md="4">
        <!--begin: Thumbnail-->
        <b-card>
          <b-card-title class="text-capitalize font-weight-bolder">
            {{ $t('label.thumbnail') }}
          </b-card-title>

          <AppMediaViewer :value="recipeThumbnail">
            <template v-slot:placeholder>
              <b-img src="/media/dummy/dummy-no-thumbnail.png" class="w-100">
              </b-img>
            </template>
          </AppMediaViewer>
        </b-card>
        <!--end: Thumbnail-->

        <!--begin: Recipe Basic Info-->
        <b-card class="mt-4">
          <div class="d-flex justify-content-between align-items-center pb-4">
            <b-card-title class="text-capitalize font-weight-bolder m-0">
              {{ $t('label.basicInformation') }}
            </b-card-title>

            <!-- begin: Actions Dropdown -->
            <div>
              <b-dropdown
                v-if="
                  recipeObj.ecoPackOption !== 'Creator' &&
                  recipeObj.status == recipeStatus.VERIFIED &&
                  recipeObj.ecoPackStatus ==
                    recipeEcoPackStatus.PENDING_APPROVAL
                "
                size="sm"
                :text="$t('label.ecoPackRequest')"
                variant="primary"
                :toggle-class="[
                  'text-decoration-none',
                  { 'cursor-default': isActionsDisabled }
                ]"
              >
                <b-dropdown-item @click="onClickApproveEcoPack">{{
                  $t('label.approve')
                }}</b-dropdown-item>
                <b-dropdown-item
                  variant="danger"
                  @click="onClickRejectEcoPack"
                  >{{ $t('label.reject') }}</b-dropdown-item
                >
              </b-dropdown>
              <b-button
                v-else-if="
                  recipeObj.ecoPackOption !== 'Creator' &&
                  recipeObj.status == recipeStatus.VERIFIED
                "
                variant="primary"
                @click="openAppUpdateDetailsModal"
                size="sm"
                >{{ $t('label.fulfilByCookx') }}</b-button
              >
              <b-dropdown
                v-else
                :disabled="isActionsDisabled"
                size="sm"
                :text="$t('label.verification')"
                variant="primary"
                :toggle-class="[
                  'text-decoration-none',
                  { 'cursor-default': isActionsDisabled }
                ]"
              >
                <b-dropdown-item
                  :disabled="!isRecipeVerifiedOrRejected"
                  @click="openRequestVerifyRecipeModal"
                  >{{ $t('label.verify') }}</b-dropdown-item
                >
                <b-dropdown-item
                  variant="danger"
                  :disabled="!isRecipeVerifiedOrRejected"
                  @click="onClickReject"
                  >{{ $t('label.reject') }}</b-dropdown-item
                >
              </b-dropdown>
            </div>
            <!-- end: Actions Dropdown -->
          </div>

          <b-card-text>
            <template v-for="(i, index) in basicInfoList">
              <div
                :key="i + index"
                class="mb-5"
                v-if="!`${i.isHide}`.includes(recipeObj.ecoPackOption)"
              >
                <div class="font-weight-bolder text-capitalize mb-2">
                  {{ $t(`label.${i.name}`) }}:
                </div>
                <component
                  v-if="i.layout"
                  :is="i.layout"
                  :value="recipeObj[i.key]"
                  :id="id"
                  :itemKey="i.key"
                  :toDetailsRouteName="routeName.PRE_ORDER_FOOD_DETAILS"
                  :disabled="
                    recipeObj.deletedAt != '-' ||
                    !checkAccessRight(moduleName, [
                      permissionAction.RECIPE_UPDATE
                    ]) ||
                    i.checkmenu
                  "
                  :storeActionsObj="visibilityStatusStoreActionsObj"
                  :switchActionsOnj="updateRecipeActionsObj"
                  :recipeObj="recipeObj"
                  :config="i.config"
                  :stateObj="recipeObj"
                  class="text-gray-600"
                >
                </component>
                <div
                  v-else-if="['tips', 'remark', 'description'].includes(i.key)"
                  class="text-gray-600"
                  v-html="recipeObj[i.key]"
                ></div>

                <div v-else class="text-gray-600">
                  {{ recipeObj[i.key] }}
                </div>
              </div>
              <div
                v-if="i.key == 'rejectedReason' || i.key == 'isVerified'"
                :key="index + 'separator'"
                class="separator separator-solid my-6"
              ></div>
            </template>
          </b-card-text>
        </b-card>
        <!--end: Recipe Basic Info-->
      </b-col>
      <!--end: Col 1-->

      <!--begin: Col 2-->
      <b-col cols="12" md="8" class="mt-4 mt-md-0 pl-md-4 pl-lg-8">
        <!--begin: Ingredients-->
        <b-card>
          <b-card-title class="text-capitalize font-weight-bolder">
            {{ $t('label.ingredients') }}
          </b-card-title>

          <!--begin::Data Table-->
          <b-table
            stacked="md"
            responsive
            show-empty
            table-class="app-table-new-class"
            :fields="filteredIngredientsTableHeader"
            :items="ingredientsObj.items"
          >
            <template #head()="{ field }">
              <div class="text-nowrap">
                {{ $t(`label.${field.name}`) }}
              </div>
            </template>

            <template #cell(name)="{ value }">
              <div class="min-w-100px">
                {{ value }}
              </div>
            </template>

            <template #cell(price)="{ value }">
              <div class="min-w-70px">
                {{ value | currency }}
              </div>
            </template>

            <template #cell(actions)="{ item }">
              <div>
                <a
                  class="btn btn-sm btn-clean btn-icon"
                  @click="
                    openRecipeDetailsUpdateModal(item, typeUpdate.ingredient)
                  "
                >
                  <i class="la la-edit"></i>
                </a>
              </div>
            </template>
          </b-table>
          <!--end::Data Table-->

          <!--begin::Table Pagination-->
          <AppTablePagination
            :pagination="ingredientsObj.pagination"
            @change="updateIngredientsPagination"
          ></AppTablePagination>
          <!--end::Table Pagination-->
        </b-card>
        <!--end: Ingredients-->

        <!--begin: Variation-->
        <b-card class="mt-4">
          <b-card-title class="text-capitalize font-weight-bolder">
            {{ $t('label.variations') }}
          </b-card-title>
          <!--begin::Data Table-->
          <div v-if="recipeObj.variations">
            <template v-for="(item, index) in recipeObj.variations">
              <div :key="index" class="pb-3">
                <p class="text-capitalize m-0 font-weight-bolder">
                  <!-- {{ `${$t('label.variation')}: ${item.name}` }} -->
                  {{ `${item.name}:` }}
                </p>
                <b-table
                  stacked="md"
                  responsive
                  show-empty
                  table-class="app-table-new-class"
                  :fields="filteredVariationsTableHeader"
                  :items="item.variationOptions"
                >
                  <template #head()="{ field }">
                    <div class="text-nowrap">
                      {{ $t(`label.${field.name}`) }}
                    </div>
                  </template>

                  <template #cell(no)="data">
                    {{ data.index + 1 }}
                  </template>

                  <template #cell(name)="{ value }">
                    <div class="min-w-100px">
                      {{ value }}
                    </div>
                  </template>

                  <template #cell(price)="{ value }">
                    <div class="min-w-70px">
                      {{ value | currency }}
                    </div>
                  </template>

                  <template #cell(isActive)="{ value }">
                    <AppCheckCircle :value="value"></AppCheckCircle>
                  </template>

                  <template #cell(actions)="{ item }">
                    <div>
                      <a
                        class="btn btn-sm btn-clean btn-icon"
                        @click="
                          openRecipeDetailsUpdateModal(
                            item,
                            typeUpdate.ingredient
                          )
                        "
                      >
                        <i class="la la-edit"></i>
                      </a>
                    </div>
                  </template>
                </b-table>
              </div>
            </template>
          </div>
          <span v-else>{{ $t('label.thereAreNoItemsToShow') }}</span>
          <!--end::Data Table-->
        </b-card>
        <!--end: Variation-->

        <!--begin: Equipment-->
        <b-card class="mt-4">
          <b-card-title class="text-capitalize font-weight-bolder">
            {{ $t('label.equipment') }}
          </b-card-title>

          <!--begin::Data Table-->
          <b-table
            stacked="md"
            responsive
            show-empty
            table-class="app-table-new-class"
            :fields="filteredEquipmentTableHeader"
            :items="equipmentObj.items"
          >
            <template #head()="{ field }">
              <div class="text-nowrap">
                {{ $t(`label.${field.name}`) }}
              </div>
            </template>

            <template #cell(name)="{ value }">
              <div class="min-w-100px">
                {{ value }}
              </div>
            </template>

            <template #cell(price)="{ value }">
              <div class="min-w-70px">
                {{ value | currency }}
              </div>
            </template>

            <template #cell(status)="{ value }">
              <AppStatusLabel :value="value"></AppStatusLabel>
            </template>

            <template #cell(actions)="{ item }">
              <div>
                <a
                  class="btn btn-sm btn-clean btn-icon"
                  @click="
                    openRecipeDetailsUpdateModal(item, typeUpdate.equipment)
                  "
                >
                  <i class="la la-edit"></i>
                </a>
              </div>
            </template>
          </b-table>
          <!--end::Data Table-->

          <!--begin::Table Pagination-->
          <AppTablePagination
            :pagination="equipmentObj.pagination"
            @change="updateEquipmentPagination"
          ></AppTablePagination>
          <!--end::Table Pagination-->
        </b-card>
        <!--end: Equipment-->

        <!--begin: Instructions-->
        <b-card class="mt-4">
          <b-card-title class="text-capitalize font-weight-bolder">
            {{ $t('label.instructions') }}
          </b-card-title>

          <div
            v-if="instructionsObj.items.length > 0"
            class="timeline timeline-3"
          >
            <div class="timeline-items">
              <template v-for="(i, index) in instructionsObj.items">
                <div :key="index" class="timeline-item">
                  <div class="timeline-media text-primary font-weight-bolder">
                    {{ i.step }}
                  </div>
                  <div class="timeline-content">
                    <b-row no-gutters>
                      <b-col md="8" class="mb-4 mb-md-0">
                        <div
                          class="d-flex align-items-center justify-content-between mb-3"
                        >
                          <div class="mr-2">
                            <span
                              style="overflow-wrap: anywhere"
                              class="text-primary font-size-h5 font-weight-bolder"
                            >
                              {{ i.title }}
                            </span>
                          </div>
                        </div>
                        <div v-html="i.description"></div>
                        <div class="text-muted">
                          <span class="font-weight-bold"
                            >{{ $t('label.createdAt') }}:
                          </span>
                          {{ i.createdAt }}
                        </div>
                        <div class="text-muted">
                          <span class="font-weight-bold"
                            >{{ $t('label.updatedAt') }}:
                          </span>
                          {{ i.updatedAt }}
                        </div>
                      </b-col>
                      <b-col md="4">
                        <AppImageViewer
                          :value="i.image"
                          imageClass="max-h-150px"
                        ></AppImageViewer>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </template>
              <b-button
                v-if="
                  instructionsObj.pagination.lastPage !=
                  instructionsObj.pagination.currentPage
                "
                variant="primary"
                class="text-capitalize"
                @click="updateInstructionsPagination"
                >{{ $t('label.moreInstructions') }}</b-button
              >
            </div>
          </div>
          <span v-else>{{ $t('label.thereAreNoItemsToShow') }}</span>
        </b-card>
        <!--end: Instructions-->

        <!--begin: Media Assets-->
        <b-card class="mt-4">
          <b-card-title class="text-capitalize font-weight-bolder">
            {{ $t('label.mediaAssets') }}
          </b-card-title>

          <div v-if="recipeObj.assets">
            <AppMediaViewer
              v-if="recipeObj.assets.length >= 1"
              :value="recipeObj.assets"
            ></AppMediaViewer>
            <span v-else>{{ $t('label.thereAreNoMediaAssetToShow') }}</span>
          </div>
        </b-card>
        <!--end: Media Assets-->
      </b-col>
      <!--end Col 2-->
    </b-row>
    <!--begin: Recipe Details Update Modal-->
    <RecipeDetailsUpdateModal
      :show="recipeDetailsUpdateModal.show"
      :title="recipeDetailsUpdateModal.title"
      :type="recipeDetailsUpdateModal.type"
      :id="recipeDetailsUpdateModal.id"
      :initData="recipeDetailsUpdateModal.initData"
      @cancel="closeRecipeDetailsUpdateModal"
      @submit="updateDetailsAction"
    ></RecipeDetailsUpdateModal>
    <!--end: Recipe Details Update Modal-->

    <!-- begin:: Recipe Reject Recipe Modal -->
    <RecipeRejectRecipeModal
      ref="recipe-reject-recipe-modal"
      @reject="rejectAction"
    >
    </RecipeRejectRecipeModal>
    <!-- end:: Recipe Reject Recipe Modal -->

    <!-- begin:: Recipe Reject Recipe EcoPack Modal -->
    <RecipeRejectEcoPackModal
      ref="recipe-reject-ecopack-request-modal"
      @reject="rejectEcoPackAction"
    >
    </RecipeRejectEcoPackModal>
    <!-- end:: Recipe Reject Recipe EcoPack Modal -->

    <!-- begin:: Order Eco-pack Modal -->
    <RecipeRequestEcoPackModal
      v-model="requestEcoPackModalObj.isShow"
      :recipeObj="recipeObj"
      :isShow="requestEcoPackModalObj.isShow"
    ></RecipeRequestEcoPackModal>
    <!-- end:: Order Eco-pack Modal -->
  </div>
</template>

<script>
  import AppCheckCircle from '@/views/components/bases/AppCheckCircle';
  import AppTags from '@/views/components/bases/AppTags';
  import AppVisibilityStatusButton from '@/views/components/bases/AppVisibilityStatusButton';
  import RecipeRequestEcoPackModal from '@/views/components/recipe/eco-pack/RecipeRequestEcoPackModal';
  import RecipeRejectEcoPackModal from '@/views/components/recipe/RecipeRejectEcoPackModal';
  import AppTablePagination from '@/views/components/bases/pagination/AppTablePagination';
  import AppFeatureDetailsRelatedButton from '@/views/components/bases/AppFeatureDetailsRelatedButton';
  import AppSwitch from '@/views/components/bases/AppSwitch';
  import AppSwitchWithLabel from '@/views/components/bases/AppSwitchWithLabel';
  import AppImageViewer from '@/views/components/bases/image-viewer/AppImageViewer';
  import AppAverageRating from '@/views/components/bases/AppAverageRating';
  import AppMediaViewer from '@/views/components/bases/AppMediaViewer';
  import AppEnumLabel from '@/views/components/bases/AppEnumLabel';
  import AppStatusLabel from '@/views/components/bases/AppStatusLabel';
  import RecipeRejectRecipeModal from '@/views/components/recipe/RecipeRejectRecipeModal';
  import RecipeEditStatusButton from '@/views/components/recipe/RecipeEditStatusButton';
  import RecipeDetailsUpdateModal from '@/views/components/recipe/RecipeDetailsUpdateModal';
  import currencyFormat from '@/core/filters/currency-format.filter';
  import constantsMixin from '@/core/mixins/constants.mixin';
  import commonMixin from '@/core/mixins/common.mixin';
  import {
    ROUTE_NAME,
    MODULE,
    ACTION,
    APP_COMPONENT_NAME
  } from '@/core/constants';
  import {
    MediaType,
    CreatorApplicationStatus,
    RecipeStatus,
    RecipeEcoPackStatus
  } from '@/core/constants/enums';
  import { i18nHelper } from '@/core/utils';
  import {
    RECIPE_GET_RECIPE,
    RECIPE_GET_RECIPE_INGREDIENTS,
    RECIPE_GET_RECIPE_EQUIPMENT,
    RECIPE_GET_RECIPE_INSTRUCTIONS,
    RECIPE_INITIAL_GET_RECIPE_STATE,
    RECIPE_UPDATE_RECIPE_INGREDIENT,
    RECIPE_UPDATE_RECIPE_EQUIPMENT,
    RECIPE_VERIFY_RECIPE,
    RECIPE_SET_VISIBILITY,
    RECIPE_SET_EDIT_ECOPACK,
    RECIPE_UPDATE_RECIPE,
    RECIPE_REJECT_RECIPE,
    RECIPE_APPROVE_ECOPACK,
    RECIPE_REJECT_ECOPACK,
    RECIPE_INITIAL_GET_RECIPE_INGREDIENTS_STATE,
    RECIPE_INITIAL_GET_RECIPE_EQUIPMENT_STATE,
    RECIPE_INITIAL_GET_RECIPE_INSTRUCTIONS_STATE,
    RECIPE_INITIAL_UPDATE_RECIPE_INGREDIENT_STATE,
    RECIPE_INITIAL_UPDATE_RECIPE_EQUIPMENT_STATE,
    RECIPE_INITIAL_VERIFY_RECIPE_STATE,
    RECIPE_INITIAL_SET_VISIBILITY_STATE,
    RECIPE_INITIAL_SET_EDIT_ECOPACK_STATE,
    RECIPE_INITIAL_UPDATE_RECIPE_STATE,
    RECIPE_INITIAL_REJECT_RECIPE_STATE,
    RECIPE_INITIAL_APPROVE_ECOPACK_STATE,
    RECIPE_INITIAL_REJECT_ECOPACK_STATE
  } from '@/core/store/recipe.module';

  export default {
    name: 'RecipeDetails',
    mixins: [commonMixin, constantsMixin],
    components: {
      AppCheckCircle,
      AppTablePagination,
      AppStatusLabel,
      RecipeDetailsUpdateModal,
      AppTags,
      AppVisibilityStatusButton,
      RecipeRequestEcoPackModal,
      AppFeatureDetailsRelatedButton,
      AppSwitch,
      AppSwitchWithLabel,
      AppImageViewer,
      AppAverageRating,
      AppMediaViewer,
      AppEnumLabel,
      RecipeRejectRecipeModal,
      RecipeRejectEcoPackModal,
      RecipeEditStatusButton
    },
    data: () => ({
      routeName: ROUTE_NAME,
      moduleName: MODULE.RECIPE,
      permissionAction: ACTION,
      subRouterName: null,
      mediaType: MediaType,
      recipeStatus: RecipeStatus,
      recipeEcoPackStatus: RecipeEcoPackStatus,
      id: null,
      searchCriteriaIngredients: {
        perPage: 10,
        page: 1
      },
      searchCriteriaEquipment: {
        perPage: 10,
        page: 1
      },
      searchCriteriaInstructions: {
        perPage: 10,
        page: 1
      },
      recipeObj: {},
      ingredientsObj: {
        items: [],
        pagination: {
          currentPage: 1,
          total: 1,
          perPage: 10,
          lastPage: 1
        }
      },

      equipmentObj: {
        items: [],
        pagination: {
          currentPage: 1,
          total: 1,
          perPage: 10,
          lastPage: 1
        }
      },
      instructionsObj: {
        items: [],
        pagination: {
          currentPage: 1,
          total: 1,
          perPage: 10,
          lastPage: 1
        }
      },
      recipeDetailsUpdateModal: {
        show: false,
        id: 0,
        title: '',
        type: 0,
        initData: {}
      },
      requestEcoPackModalObj: {
        isShow: false,
        id: 0
      },
      typeUpdate: {
        ingredient: 1,
        equipment: 2
      },
      basicInfoList: [
        {
          key: 'name',
          name: 'recipeName'
        },
        {
          key: 'id',
          name: 'id'
        },
        {
          key: 'userObject',
          name: 'createdBy',
          layout: APP_COMPONENT_NAME.APP_FEATURE_DETAILS_RELATED_BUTTON,
          config: {
            to: ROUTE_NAME.PROFILE_DETAILS_DETAILS
          }
        },
        {
          key: 'creatorApplicationStatus',
          name: 'creatorApplicationStatus',
          layout: APP_COMPONENT_NAME.APP_ENUM_LABEL,
          config: {
            size: 'xl',
            enumKey: 'enumCreatorApplicationStatus[0]',
            statusColor: (value) => {
              let color = {
                [CreatorApplicationStatus.NOT_APPLY]: 'primary',
                [CreatorApplicationStatus.PENDING]: 'warning',
                [CreatorApplicationStatus.APPROVED]: 'success',
                [CreatorApplicationStatus.REJECTED]: 'danger',
                [CreatorApplicationStatus.DOCUMENT_PENDING]: 'warning'
              };

              return color[value];
            }
          }
        },
        {
          key: 'foodType',
          name: 'foodType'
        },
        {
          key: 'filterType',
          name: 'filter',
          layout: APP_COMPONENT_NAME.APP_TAGS
        },
        {
          key: 'servePax',
          name: 'servePax'
        },
        {
          key: 'cookingLevel',
          name: 'cookingLevel'
        },
        {
          key: 'description',
          name: 'basicInfo'
        },
        {
          key: 'displayPrice',
          name: 'ecoPackPrice'
        },
        {
          key: 'promotionPrice',
          name: 'promotionPrice'
        },
        {
          key: 'sellingQuantity',
          name: 'sellingQuantity'
        },
        {
          key: 'ecoPackStatus',
          name: 'ecoPackStatus',
          layout: APP_COMPONENT_NAME.APP_ENUM_LABEL,
          config: {
            size: 'xl',
            enumKey: 'enumRecipeEcoPackStatus',
            statusColor: (value) => {
              let color = {
                [RecipeEcoPackStatus.PENDING_ACCEPTANCE]: 'primary',
                [RecipeEcoPackStatus.ACCEPTED]: 'success',
                [RecipeEcoPackStatus.CANCEL]: 'danger',
                [RecipeEcoPackStatus.EXPIRED]: 'warning',
                [RecipeEcoPackStatus.REJECTED]: 'danger',
                [RecipeEcoPackStatus.PENDING_APPROVAL]: 'primary',
                [RecipeEcoPackStatus.COOKX_ACCEPTED]: 'success'
              };
              return color[value];
            }
          }
        },

        {
          key: 'ecoPackOption',
          name: 'ecoPackOption'
        },
        {
          key: 'deliveryMethod',
          name: 'deliveryMethod'
        },
        {
          key: 'vehicleType',
          name: 'vehicleType'
        },
        {
          key: 'tips',
          name: 'tips'
        },
        {
          key: 'product',
          name: 'relatedPreOrderFood',
          layout: 'AppFeatureDetailsRelatedButton'
        },
        {
          key: 'averageRating',
          name: 'averageRatings',
          layout: APP_COMPONENT_NAME.APP_AVERAGE_RATING,
          config: {
            routeName: ROUTE_NAME.REVIEW_AND_RATING_EXPERIENCE_COOKING_LIST,
            itemName: ''
          }
        },
        {
          key: 'rejectedReason',
          name: 'rejectedReason'
        },
        {
          key: 'isEdit',
          name: 'recipeEditing',
          layout: 'RecipeEditStatusButton',
          isHide: ['None', 'Creator'],
          config: {
            module: 'recipe',
            state: 'setEditEcopack',
            actionSet: RECIPE_SET_EDIT_ECOPACK,
            actionInitial: RECIPE_INITIAL_SET_EDIT_ECOPACK_STATE,
            successAction: () => {},
            errorAction: () => {},
            name: i18nHelper.getMessage('label.allow'),
            title: i18nHelper.getMessage('label.allowRecipeEditing'),
            message: i18nHelper.getMessage('message.requestAllowMessage', [
              i18nHelper.getMessage('AllowMsg.allowEcopackFulfil')
            ])
          }
        },
        {
          key: 'isVisible',
          name: 'visibility',
          layout: APP_COMPONENT_NAME.APP_VISIBILITY_STATUS_BUTTON
        },
        {
          key: 'isActive',
          name: 'isActive',
          layout: APP_COMPONENT_NAME.APP_CHECK_CIRCLE
        },
        {
          key: 'isVerified',
          name: 'verified',
          layout: APP_COMPONENT_NAME.APP_CHECK_CIRCLE
        },
        {
          key: 'isPopular',
          name: 'popular',
          layout: APP_COMPONENT_NAME.APP_SWITCH
        },
        {
          key: 'isTrending',
          name: 'trending',
          layout: APP_COMPONENT_NAME.APP_SWITCH,
          checkmenu: false
        },
        {
          key: 'isTop10',
          name: 'top10',
          layout: APP_COMPONENT_NAME.APP_SWITCH_WITH_LABEL,
          checkmenu: false
        },
        {
          key: 'isRated',
          name: 'rated',
          layout: APP_COMPONENT_NAME.APP_CHECK_CIRCLE
        },
        {
          key: 'isPublished',
          name: 'published',
          layout: APP_COMPONENT_NAME.APP_CHECK_CIRCLE
        },
        {
          key: 'publishedAt',
          name: 'publishedAt'
        },
        {
          key: 'personInCharge',
          name: 'personInCharge'
        },
        {
          key: 'verifiedAt',
          name: 'verifiedAt'
        },
        {
          key: 'rejectedAt',
          name: 'rejectedAt'
        },
        {
          key: 'createdAt',
          name: 'createdAt'
        },
        {
          key: 'updatedAt',
          name: 'updatedAt'
        },
        {
          key: 'deletedAt',
          name: 'deletedAt'
        }
      ],
      ingredientTableHeader: [
        {
          key: 'no',
          name: 'no'
        },
        {
          key: 'name',
          name: 'name'
        },
        {
          key: 'count',
          name: 'count'
        },
        {
          key: 'unit',
          name: 'unit'
        },
        {
          key: 'price',
          name: 'price'
        },
        {
          key: 'createdAt',
          name: 'createdAt'
        },
        {
          key: 'updatedAt',
          name: 'updatedAt'
        }
        // {
        //   key: 'actions',
        //   name: 'actions',
        //   action: true
        // }
      ],
      variationsTableHeader: [
        {
          key: 'no',
          name: 'no'
        },
        {
          key: 'name',
          name: 'name'
        },
        {
          key: 'price',
          name: 'price'
        },
        {
          key: 'isActive',
          name: 'isActive'
        }
      ],
      equipmentTableHeader: [
        {
          key: 'no',
          name: 'no'
        },
        {
          key: 'name',
          name: 'name'
        },
        {
          key: 'count',
          name: 'count'
        },
        {
          key: 'price',
          name: 'price'
        },
        {
          key: 'status',
          name: 'status'
        },
        {
          key: 'createdAt',
          name: 'createdAt'
        },
        {
          key: 'updatedAt',
          name: 'updatedAt'
        },
        {
          key: 'actions',
          name: 'actions',
          action: true
        }
      ],
      visibilityStatusStoreActionsObj: {
        module: 'recipe',
        state: 'setVisibility',
        actionSet: RECIPE_SET_VISIBILITY,
        actionInitial: RECIPE_INITIAL_SET_VISIBILITY_STATE,
        successAction: () => {},
        title: i18nHelper.getMessage('label.setVisibility'),
        message: i18nHelper.getMessage('label.experienceCooking')
      },
      sellingPrice: '-',
      updateRecipeActionsObj: {
        module: 'recipe',
        state: 'updateRecipe',
        actionSet: RECIPE_UPDATE_RECIPE,
        actionInitial: RECIPE_INITIAL_UPDATE_RECIPE_STATE,
        successAction: () => {},
        errorAction: () => {},
        title: i18nHelper.getMessage('label.updateExperienceCooking')
      },
      ecopackStatusStoreActionsObj: {
        module: 'recipe'
      },
      recipeThumbnail: null
    }),
    computed: {
      recipeComplete() {
        return this.$store.state.recipe.recipe.complete;
      },
      recipeIngredientsComplete() {
        return this.$store.state.recipe.ingredients.complete;
      },
      recipeEquipmentComplete() {
        return this.$store.state.recipe.equipment.complete;
      },
      recipeInstructionsComplete() {
        return this.$store.state.recipe.instructions.complete;
      },
      updateRecipeIngredientsComplete() {
        return this.$store.state.recipe.updateIngredient.complete;
      },
      updateRecipeEquipmentComplete() {
        return this.$store.state.recipe.updateEquipment.complete;
      },
      verifyRecipeComplete() {
        return this.$store.state.recipe.verifyRecipe.complete;
      },
      rejectRecipeComplete() {
        return this.$store.state.recipe.rejectRecipe.complete;
      },
      rejectEcoPackComplete() {
        return this.$store.state.recipe.rejectEcoPack.complete;
      },
      approveEcoPackComplete() {
        return this.$store.state.recipe.approveEcoPack.complete;
      },
      filteredIngredientsTableHeader() {
        let tableHeader = this.ingredientTableHeader;

        return this.checkAccessRight(this.moduleName, [ACTION.RECIPE_UPDATE]) &&
          this.recipeObj.deletedAt == '-'
          ? tableHeader
          : tableHeader.filter((x) => x.key != 'actions');
      },
      filteredEquipmentTableHeader() {
        let tableHeader = this.equipmentTableHeader;

        return this.checkAccessRight(this.moduleName, [ACTION.RECIPE_UPDATE]) &&
          this.recipeObj.deletedAt == '-'
          ? tableHeader
          : tableHeader.filter((x) => x.key != 'actions');
      },
      filteredVariationsTableHeader() {
        let tableHeader = this.variationsTableHeader;

        return this.checkAccessRight(this.moduleName, [ACTION.RECIPE_UPDATE]) &&
          this.recipeObj.deletedAt == '-'
          ? tableHeader
          : tableHeader.filter((x) => x.key != 'actions');
      },
      isActionsDisabled() {
        return !this.checkAccessRight(MODULE.RECIPE, [ACTION.RECIPE_UPDATE]);
      },
      isRecipeVerifiedOrRejected() {
        var obj = this.recipeObj;

        return obj.deletedAt == '-' && !obj.isVerified && !obj.isRejected;
      }
    },
    watch: {
      recipeComplete() {
        let response = this.$store.state.recipe.recipe;
        if (response.complete) {
          this.getRecipeCompleteAction(response);
        }
      },
      recipeIngredientsComplete() {
        let response = this.$store.state.recipe.ingredients;
        if (response.complete) {
          this.getRecipeIngredientsCompleteAction(response);
        }
      },
      recipeEquipmentComplete() {
        let response = this.$store.state.recipe.equipment;
        if (response.complete) {
          this.getRecipeEquipmentCompleteAction(response);
        }
      },
      recipeInstructionsComplete() {
        let response = this.$store.state.recipe.instructions;
        if (response.complete) {
          this.getRecipeInstructionsCompleteAction(response);
        }
      },
      updateRecipeIngredientsComplete() {
        let response = this.$store.state.recipe.updateIngredient;
        if (response.complete) {
          this.updateRecipeIngredientsCompleteAction(response);
        }
      },
      updateRecipeEquipmentComplete() {
        let response = this.$store.state.recipe.updateEquipment;
        if (response.complete) {
          this.updateRecipeEquipmentCompleteAction(response);
        }
      },
      verifyRecipeComplete() {
        let response = this.$store.state.recipe.verifyRecipe;
        if (response.complete) {
          this.verifyRecipeCompleteAction(response);
        }
      },
      rejectRecipeComplete() {
        let response = this.$store.state.recipe.rejectRecipe;
        if (response.complete) {
          this.rejectRecipeCompleteAction(response);
        }
      },
      rejectEcoPackComplete() {
        let response = this.$store.state.recipe.rejectEcoPack;
        if (response.complete) {
          this.rejectEcoPackCompleteAction(response);
        }
      },
      approveEcoPackComplete() {
        let response = this.$store.state.recipe.approveEcoPack;
        if (response.complete) {
          this.approveEcoPackCompleteAction(response);
        }
      }
    },
    created() {
      this.initialRecipeDetails();
    },
    beforeDestroy() {
      this.closeLayoutAppModalInfo();
    },
    methods: {
      rejectRecipeCompleteAction(response) {
        let title = i18nHelper.getMessage('label.rejectExperienceCooking');
        let messages = response.message;
        let buttons = [];

        if (response.code == 0) {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();
              this.getRecipe(this.id);
              this.layoutGetNotification();
            }
          });
        } else {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();
            }
          });
        }

        this.openLayoutAppModalInfo(title, messages, buttons);
        this.initialRejectRecipeState();
      },
      rejectEcoPackCompleteAction(response) {
        let title = i18nHelper.getMessage('label.rejectExperienceCooking');
        let messages = response.message;
        let buttons = [];

        if (response.code == 0) {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();
              this.getRecipe(this.id);
              this.layoutGetNotification();
            }
          });
        } else {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();
            }
          });
        }

        this.openLayoutAppModalInfo(title, messages, buttons);
        this.initialRejectEcoPackState();
      },
      approveEcoPackCompleteAction(response) {
        let title = i18nHelper.getMessage('label.approveExperienceCooking');
        let messages = response.message;
        let buttons = [];

        if (response.code == 0) {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();
              this.getRecipe(this.id);
              this.layoutGetNotification();
            }
          });
        } else {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();
            }
          });
        }

        this.openLayoutAppModalInfo(title, messages, buttons);
        this.initialApproveEcoPackState();
      },
      verifyRecipeCompleteAction(response) {
        let title = i18nHelper.getMessage('label.verifyExperienceCooking');
        let messages = response.message;
        let buttons = [];

        if (response.code == 0) {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();
              this.getRecipe(this.id);
              this.layoutGetNotification();
            }
          });
        } else {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();
            }
          });
        }

        this.openLayoutAppModalInfo(title, messages, buttons);
        this.initialVerifyRecipeState();
      },
      updateRecipeEquipmentCompleteAction(response) {
        let title = i18nHelper.getMessage('label.updateEquipment');
        let messages = response.message;
        let buttons = [];

        buttons.push({
          text: i18nHelper.getMessage('label.ok'),
          variant: 'primary',
          action: () => {
            this.getRecipeEquipment(this.id, this.searchCriteriaEquipment);
            this.closeLayoutAppModalInfo();
            this.closeRecipeDetailsUpdateModal();
          }
        });

        this.openLayoutAppModalInfo(title, messages, buttons);
        this.initialUpdateRecipeEquipmentState();
      },
      updateRecipeIngredientsCompleteAction(response) {
        let title = i18nHelper.getMessage('label.updateIngredient');
        let messages = response.message;
        let buttons = [];

        buttons.push({
          text: i18nHelper.getMessage('label.ok'),
          variant: 'primary',
          action: () => {
            this.getRecipeIngredients(this.id, this.searchCriteriaIngredients);
            this.closeLayoutAppModalInfo();
            this.closeRecipeDetailsUpdateModal();
          }
        });

        this.openLayoutAppModalInfo(title, messages, buttons);
        this.initialUpdateRecipeIngredientState();
      },
      getRecipeInstructionsCompleteAction(response) {
        let title = i18nHelper.getMessage('label.getInstructions');
        let messages = response.message;
        let buttons = [];
        let items = this.instructionsObj.items;

        if (response.code == 0) {
          this.instructionsObj = {
            items: items.concat(response.data),
            pagination: response.pagination
          };
        } else {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();
            }
          });
          this.openLayoutAppModalInfo(title, messages, buttons);
        }
        this.initialGetRecipeInstructionsState();
      },
      getRecipeEquipmentCompleteAction(response) {
        let title = i18nHelper.getMessage('label.getEquipment');
        let messages = response.message;
        let buttons = [];

        if (response.code == 0) {
          this.equipmentObj = {
            items: response.data,
            pagination: response.pagination
          };
        } else {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();
            }
          });
          this.openLayoutAppModalInfo(title, messages, buttons);
        }
        this.initialGetRecipeEquipmentState();
      },
      getRecipeIngredientsCompleteAction(response) {
        let title = i18nHelper.getMessage('label.getIngredients');
        let messages = response.message;
        let buttons = [];

        if (response.code == 0) {
          this.ingredientsObj = {
            items: response.data,
            pagination: response.pagination
          };
          this.importRecipeSellingPrice(this.ingredientsObj.items);
        } else {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();
            }
          });
          this.openLayoutAppModalInfo(title, messages, buttons);
        }
        this.initialGetRecipeIngredientsState();
      },
      getRecipeCompleteAction(response) {
        let data = response.data;
        let title = i18nHelper.getMessage('label.getExperienceCookingDetails');
        let messages = response.message;
        let buttons = [];

        if (response.code == 0) {
          this.recipeObj = data;
          this.recipeObj.sellingPrice = this.sellingPrice;
          /*
            For average rating redirect to review and
            rating listing view and filter listing by item name
          */
          this.basicInfoList.find(
            (x) => x.key == 'averageRating'
          ).config.itemName = data.name;

          this.recipeThumbnail = {
            mediaPath: data.originalThumbnail,
            mediaType: MediaType.IMAGE
          };

          /* Top 10 button disable start*/

          const queryString = window.location.pathname.split('/');
          const menu = queryString[4];

          if (
            this.recipeObj.filterCategory !== menu &&
            this.recipeObj.filterCategory !== null
          ) {
            const result = this.basicInfoList.find((o) => {
              return o.key === 'isTop10';
            });
            if (result) {
              result.checkmenu = true;
            }
          }
          if (this.recipeObj.isTrending == true) {
            if (menu !== 'TRENDING') {
              const result = this.basicInfoList.find((o) => {
                return o.key === 'isTrending';
              });
              if (result) {
                result.checkmenu = true;
              }
            }
          }
          /* Top 10 button disable end */
        } else {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();
            }
          });
          this.openLayoutAppModalInfo(title, messages, buttons);
        }
        this.initialGetRecipeState();
      },
      rejectAction(value) {
        var data = {
          rejectedRemark: value.remark,
          rejectedReason: value.reason
        };
        this.rejectRecipe(this.id, data);
      },
      rejectEcoPackAction(value) {
        var data = {
          rejectedReason: value.reason
        };
        this.rejectEcoPack(this.id, data);
      },
      approveEcoPackAction() {
        this.approveEcoPack(this.id);
      },
      onClickApproveEcoPack() {
        this.approveEcoPackAction();
      },
      onClickReject() {
        this.$refs['recipe-reject-recipe-modal'].show();
      },
      onClickRejectEcoPack() {
        this.$refs['recipe-reject-ecopack-request-modal'].show();
      },
      importRecipeSellingPrice(ingredients) {
        let sellingPrice = 0;

        ingredients.forEach((x) => {
          sellingPrice += x.price;
        });

        this.sellingPrice = currencyFormat(sellingPrice);
        this.recipeObj.sellingPrice = currencyFormat(sellingPrice);
      },
      openRequestVerifyRecipeModal() {
        let title = i18nHelper.getMessage('label.verifyExperienceCooking');
        let messages = [];
        let buttons = [];

        messages.push(
          i18nHelper.getMessage('message.requestVerifyExperienceCookingMessage')
        );

        buttons.push(
          {
            text: i18nHelper.getMessage('label.cancel'),
            variant: 'white',
            action: () => {
              this.closeLayoutAppModalInfo();
            }
          },
          {
            text: i18nHelper.getMessage('label.verify'),
            variant: 'primary',
            action: () => {
              this.verifyRecipe(this.id);
              this.closeLayoutAppModalInfo();
            }
          }
        );

        this.openLayoutAppModalInfo(title, messages, buttons);
      },
      openAppUpdateDetailsModal() {
        this.requestEcoPackModalObj = {
          isShow: true
        };
      },
      updateDetailsAction(data) {
        this.updateRecipeEquipment(data.id, data);
      },
      openRecipeDetailsUpdateModal(item, type) {
        this.recipeDetailsUpdateModal = {
          show: true,
          id: item.id,
          title: i18nHelper.getMessage(
            `label.${type == 1 ? 'updateIngredient' : 'updateEquipment'}`
          ),
          type: type,
          initData: item
        };
      },
      closeRecipeDetailsUpdateModal() {
        this.recipeDetailsUpdateModal = {
          show: false,
          id: 0,
          title: '',
          type: 0
        };
      },
      updateInstructionsPagination() {
        let obj = this.instructionsObj;

        if (obj.pagination.lastPage != obj.pagination.currentPage) {
          this.searchCriteriaInstructions.page = obj.pagination.currentPage + 1;
          this.getRecipeInstructions(this.id, this.searchCriteriaInstructions);
        }
      },
      updateIngredientsPagination(value) {
        this.searchCriteriaIngredients.perPage = value.size;
        this.searchCriteriaIngredients.page = value.page;
        this.getRecipeIngredients(this.id, this.searchCriteriaIngredients);
      },
      updateEquipmentPagination(value) {
        this.searchCriteriaEquipment.perPage = value.size;
        this.searchCriteriaEquipment.page = value.page;
        this.getRecipeEquipment(this.id, this.searchCriteriaEquipment);
      },
      importSuccessActions() {
        let id = this.id;

        this.visibilityStatusStoreActionsObj.successAction = () => {
          this.getRecipe(id);
        };

        this.updateRecipeActionsObj.successAction = () => {
          this.getRecipe(id);
        };

        this.ecopackStatusStoreActionsObj.successAction = () => {
          this.getRecipe(id);
        };
      },
      layoutGetNotification() {
        this.$root.$children[0].$refs.layout.getNotification();
      },
      rejectRecipe(id, data) {
        this.$store.dispatch(RECIPE_REJECT_RECIPE, {
          id,
          data
        });
      },
      rejectEcoPack(id, data) {
        this.$store.dispatch(RECIPE_REJECT_ECOPACK, {
          id,
          data
        });
      },
      approveEcoPack(id) {
        this.$store.dispatch(RECIPE_APPROVE_ECOPACK, {
          id
        });
      },
      getRecipe(id, data) {
        this.$store.dispatch(RECIPE_GET_RECIPE, {
          id,
          data
        });
      },
      getRecipeIngredients(id, data) {
        this.$store.dispatch(RECIPE_GET_RECIPE_INGREDIENTS, {
          id,
          data
        });
      },
      getRecipeEquipment(id, data) {
        this.$store.dispatch(RECIPE_GET_RECIPE_EQUIPMENT, {
          id,
          data
        });
      },
      getRecipeInstructions(id, data) {
        this.$store.dispatch(RECIPE_GET_RECIPE_INSTRUCTIONS, {
          id,
          data
        });
      },
      updateRecipeIngredient(id, data, ingredientId) {
        this.$store.dispatch(RECIPE_UPDATE_RECIPE_INGREDIENT, {
          id,
          data,
          ingredientId
        });
      },
      updateRecipeEquipment(id, data, equipmentId) {
        this.$store.dispatch(RECIPE_UPDATE_RECIPE_EQUIPMENT, {
          id,
          data,
          equipmentId
        });
      },
      verifyRecipe(id) {
        this.$store.dispatch(RECIPE_VERIFY_RECIPE, {
          id
        });
      },
      initialGetRecipeState() {
        this.$store.dispatch(RECIPE_INITIAL_GET_RECIPE_STATE);
      },
      initialGetRecipeIngredientsState() {
        this.$store.dispatch(RECIPE_INITIAL_GET_RECIPE_INGREDIENTS_STATE);
      },
      initialGetRecipeEquipmentState() {
        this.$store.dispatch(RECIPE_INITIAL_GET_RECIPE_EQUIPMENT_STATE);
      },
      initialGetRecipeInstructionsState() {
        this.$store.dispatch(RECIPE_INITIAL_GET_RECIPE_INSTRUCTIONS_STATE);
      },
      initialUpdateRecipeIngredientState() {
        this.$store.dispatch(RECIPE_INITIAL_UPDATE_RECIPE_INGREDIENT_STATE);
      },
      initialUpdateRecipeEquipmentState() {
        this.$store.dispatch(RECIPE_INITIAL_UPDATE_RECIPE_EQUIPMENT_STATE);
      },
      initialVerifyRecipeState() {
        this.$store.dispatch(RECIPE_INITIAL_VERIFY_RECIPE_STATE);
      },
      initialApproveEcoPackState() {
        this.$store.dispatch(RECIPE_INITIAL_APPROVE_ECOPACK_STATE);
      },
      initialRejectRecipeState() {
        this.$store.dispatch(RECIPE_INITIAL_REJECT_RECIPE_STATE);
      },
      initialRejectEcoPackState() {
        this.$store.dispatch(RECIPE_INITIAL_REJECT_ECOPACK_STATE);
      },
      initialRecipeDetails() {
        let id = Number(this.$route.params.id);
        let breadcrumbData = {
          breadcrumbs: [
            {
              title: i18nHelper.getMessage('label.experienceCooking'),
              route: { name: ROUTE_NAME.EXPERIENCE_COOKING }
            },
            { title: i18nHelper.getMessage('label.experienceCookingDetails') }
          ],
          actions: [
            {
              text: i18nHelper.getMessage('label.back'),
              function: () => {
                this.$router.go(-1);
              }
            }
          ]
        };

        this.id = id;
        this.getRecipe(id);
        this.getRecipeIngredients(id, this.searchCriteriaIngredients);
        this.getRecipeEquipment(id, this.searchCriteriaEquipment);
        this.getRecipeInstructions(id, this.searchCriteriaInstructions);
        this.initBreadCrumb(breadcrumbData);
        this.importSuccessActions();
        window.KTUtil.scrollTop();
      }
    }
  };
</script>

<style lang="scss">
  .v-application ul {
    padding-left: 0 !important;
  }
</style>
