import dateTimeFormat from '@/core/filters/dateTimeFormat.filter';
import constantsDescription from '@/core/filters/constants-description.filter';
import { i18nHelper } from '@/core/utils';
import { AUDIT_LOG_TYPE } from '@/core/constants/enums';

export const auditLogService = {
  dataMapping
};

const LAYOUT = {
  [AUDIT_LOG_TYPE.USER]: 'ChangesUserRow',
  [AUDIT_LOG_TYPE.ORDER]: 'ChangesOrderRow'
};

const TYPE_COMPARE_LIST = {
  [AUDIT_LOG_TYPE.USER]: ['isActive'],
  [AUDIT_LOG_TYPE.ORDER]: ['deliveryStatus']
};

const MAPPING = {
  [AUDIT_LOG_TYPE.USER]: (data) => userDataMapping(data),
  [AUDIT_LOG_TYPE.ORDER]: (data) => orderDataMapping(data)
};

function dataMapping(type, data) {
  let d = data;
  let obj = {
    from: MAPPING[type](d.from),
    to: MAPPING[type](d.to),
    changes: []
  };

  TYPE_COMPARE_LIST[type].forEach((x) => {
    //Push data if value not same
    if (obj.from[x] != obj.to[x]) {
      obj.changes.push({
        key: x,
        from: obj.from[x],
        to: obj.to[x],
        layout: LAYOUT[type]
      });
    }
  });

  return obj;
}

function userDataMapping(data) {
  return {
    id: data.id,
    bio: data.bio ? data.bio.split(',') : '-',
    email: data.email,
    birthday: dateTimeFormat(data.birthday),
    username: data.username,
    isActive: data.is_active,
    isCreator: data.is_creator,
    lastLoginAt: dateTimeFormat(data.last_login_at),
    contactNumber: data.contact_number ? data.contact_number : '-',
    followingCount: data.following_count,
    phoneVerifiedAt: dateTimeFormat(data.phone_verified_at),
    profilePhotoUrl: data.profile_photo_url,
    foodTypePreferences: constantsDescription(
      'foodType',
      data.food_type_preferences
    ),
    profileBackground_url: data.profile_background_url,
    creatorApplicationStatus: {
      value: data.creator_application_status,
      name: i18nHelper.getMessage(
        `enumCreatorApplicationStatus[0].${data.creator_application_status}`
      )
    }
  };
}
function orderDataMapping(data) {
  return {
    deliveryStatus: data.delivery_status
  };
}
