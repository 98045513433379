import { ROLE } from '@/core/api';
import { apiHelper } from '@/core/utils';
import {
  LOADING_OPEN_LOADING,
  LOADING_CLOSE_LOADING
} from '@/core/store/loading.module';
import dateTimeFormat from '@/core/filters/dateTimeFormat.filter';

/* Define Module Name */
const MODULE_NAME = 'role/';

/* Define Action Name */
const A_GET_ROLES = 'getRoles';
const A_GET_ROLE = 'getRole';
const A_CREATE_ROLE = 'createRole';
const A_UPDATE_ROLE = 'updateRole';
const A_DELETE_ROLE = 'deleteRole';
/* Define Reset State Action Name */
const A_INITIAL_GET_ROLES_STATE = 'initialGetRolesState';
const A_INITIAL_GET_ROLE_STATE = 'initialGetRoleState';
const A_INITIAL_CREATE_ROLE_STATE = 'initialCreateRoleState';
const A_INITIAL_UPDATE_ROLE_STATE = 'initialUpdateRoleState';
const A_INITIAL_DELETE_ROLE_STATE = 'initialDeleteRoleState';

/* Define Getter Name */

/* Define Mutation Name */
const M_GET_ROLES = 'setGetRoles';
const M_GET_ROLE = 'setGetRole';
const M_CREATE_ROLE = 'setCreateRole';
const M_UPDATE_ROLE = 'setUpdateRole';
const M_DELETE_ROLE = 'setDeleteRole';
/* Define Reset State Mutation Name */
const M_INITIAL_GET_ROLES_STATE = 'setInitialGetRolesState';
const M_INITIAL_GET_ROLE_STATE = 'setInitialGetRoleState';
const M_INITIAL_CREATE_ROLE_STATE = 'setInitialCreateRoleState';
const M_INITIAL_UPDATE_ROLE_STATE = 'setInitialUpdateRoleState';
const M_INITIAL_DELETE_ROLE_STATE = 'setInitialDeleteRoleState';

/* Define Export Name */
export const ROLE_GET_ROLES = MODULE_NAME + A_GET_ROLES;
export const ROLE_GET_ROLE = MODULE_NAME + A_GET_ROLE;
export const ROLE_CREATE_ROLE = MODULE_NAME + A_CREATE_ROLE;
export const ROLE_UPDATE_ROLE = MODULE_NAME + A_UPDATE_ROLE;
export const ROLE_DELETE_ROLE = MODULE_NAME + A_DELETE_ROLE;
/* Define Reset State Name */
export const ROLE_INITIAL_GET_ROLES_STATE =
  MODULE_NAME + A_INITIAL_GET_ROLES_STATE;
export const ROLE_INITIAL_GET_ROLE_STATE =
  MODULE_NAME + A_INITIAL_GET_ROLE_STATE;
export const ROLE_INITIAL_CREATE_ROLE_STATE =
  MODULE_NAME + A_INITIAL_CREATE_ROLE_STATE;
export const ROLE_INITIAL_UPDATE_ROLE_STATE =
  MODULE_NAME + A_INITIAL_UPDATE_ROLE_STATE;
export const ROLE_INITIAL_DELETE_ROLE_STATE =
  MODULE_NAME + A_INITIAL_DELETE_ROLE_STATE;

const state = {
  roles: {
    code: 0,
    complete: false,
    data: null,
    message: null,
    pagination: {
      currentPage: 0,
      total: 0,
      perPage: 0,
      lastPage: 0
    }
  },
  role: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  newRole: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  updateRole: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  deleteRole: {
    code: 0,
    complete: false,
    data: null,
    message: null
  }
};

//Getters
const getters = {};

// Actions
const actions = {
  async [A_GET_ROLES]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    await ROLE.getRoles(data)
      .then((respone) => {
        let result = respone;
        commit(M_GET_ROLES, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_GET_ROLES, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_ROLE]({ dispatch, commit }, { id }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    ROLE.getRole(id)
      .then((respone) => {
        let result = respone;
        commit(M_GET_ROLE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_GET_ROLE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_CREATE_ROLE]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    ROLE.createRole(data)
      .then((respone) => {
        let result = respone;
        commit(M_CREATE_ROLE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_CREATE_ROLE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_UPDATE_ROLE]({ dispatch, commit }, { id, data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    ROLE.updateRole(id, data)
      .then((respone) => {
        let result = respone;
        commit(M_UPDATE_ROLE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_UPDATE_ROLE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_DELETE_ROLE]({ dispatch, commit }, { id }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    ROLE.deleteRole(id)
      .then((respone) => {
        let result = respone;
        commit(M_DELETE_ROLE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_DELETE_ROLE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_INITIAL_GET_ROLES_STATE]({ commit }) {
    commit(M_INITIAL_GET_ROLES_STATE);
  },
  [A_INITIAL_GET_ROLE_STATE]({ commit }) {
    commit(M_INITIAL_GET_ROLE_STATE);
  },
  [A_INITIAL_CREATE_ROLE_STATE]({ commit }) {
    commit(M_INITIAL_CREATE_ROLE_STATE);
  },
  [A_INITIAL_UPDATE_ROLE_STATE]({ commit }) {
    commit(M_INITIAL_UPDATE_ROLE_STATE);
  },
  [A_INITIAL_DELETE_ROLE_STATE]({ commit }) {
    commit(M_INITIAL_DELETE_ROLE_STATE);
  }
};

// Mutations
const mutations = {
  [M_GET_ROLES](state, result) {
    if (result.code === 0) {
      let d = result.data;
      let p = result.pagination;
      let roles = [];

      d.forEach((x, index) => {
        roles.push({
          no: index + 1,
          id: x.id,
          name: x.name,
          createdAt: dateTimeFormat(x.created_at),
          updatedAt: dateTimeFormat(x.updated_at)
        });
      });
      state.roles = {
        code: result.code,
        complete: true,
        data: roles,
        pagination: {
          currentPage: p.current_page,
          total: p.total,
          perPage: p.per_page,
          lastPage: p.last_page
        }
      };
    } else {
      state.roles = {
        code: result.code,
        complete: true,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_GET_ROLE](state, result) {
    if (result.code == 0) {
      let d = result.data;
      let permissions = [];
      d.permissions.forEach((x) => {
        permissions.push(x);
      });

      state.role = {
        code: result.code,
        complete: true,
        data: {
          id: d.id,
          name: d.name,
          permissions: [
            ...permissions
            // For test new permission, if Backend not update permission on API
            // 'test-permissions.read',
            // 'test-permissions.create',
            // 'test-permissions.update',
            // 'test-permissions.delete'
          ]
        }
      };
    } else {
      state.role = {
        code: result.code,
        complete: true,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_CREATE_ROLE](state, result) {
    state.newRole = {
      code: result.code,
      complete: true,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_UPDATE_ROLE](state, result) {
    state.updateRole = {
      code: result.code,
      complete: true,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_DELETE_ROLE](state, result) {
    state.deleteRole = {
      code: result.code,
      complete: true,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_INITIAL_GET_ROLES_STATE](state) {
    state.roles = {
      code: 0,
      complete: false,
      data: null,
      message: null,
      pagination: {
        currentPage: 0,
        total: 0,
        perPage: 0,
        lastPage: 0
      }
    };
  },
  [M_INITIAL_GET_ROLE_STATE](state) {
    state.role = {
      code: 0,
      complete: false,
      data: null
    };
  },
  [M_INITIAL_CREATE_ROLE_STATE](state) {
    state.newRole = {
      code: 0,
      complete: false,
      message: null
    };
  },
  [M_INITIAL_UPDATE_ROLE_STATE](state) {
    state.updateRole = {
      code: 0,
      complete: false,
      message: null
    };
  },
  [M_INITIAL_DELETE_ROLE_STATE](state) {
    state.deleteRole = {
      code: 0,
      complete: false,
      message: null
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
