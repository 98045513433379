<template>
  <div>
    <b-row no-gutters>
      <b-col cols="12" class="pb-5">
        <div class="wizard-label">
          <h3 class="wizard-title text-capitalize">
            {{ $t(`label.newOrderMessageNotification`) }}
          </h3>
          <div class="wizard-desc">
            {{ $t(`message.newOrderMessageNotificationMessage`) }}
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row no-gutters>
      <b-col cols="12">
        <div v-if="notifyCreatorObj.length < 6">
          <ConfigNumberFormField
            :editable="true"
            @save="createNotifyCreatorNumberAction"
          >
          </ConfigNumberFormField>
        </div>
        <div v-for="(item, index) in notifyCreatorObj" :key="index">
          <ConfigNumberFormField
            :item="item"
            :value="item.phone"
            :editable="true"
            @save="updateNotifyCreatorNumberAction"
            @delete="deleteNotifyCreatorNumberAction"
          >
          </ConfigNumberFormField>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import commonMixin from '@/core/mixins/common.mixin';
  import { i18nHelper } from '@/core/utils';
  import ConfigNumberFormField from '@/views/components/configuration/form-field/ConfigNumberFormField.vue';
  import {
    GENERAL_GET_NOTIFY_CREATOR,
    GENERAL_INITIAL_GET_NOTIFY_CREATOR_STATE,
    GENERAL_CREATE_NOTIFY_CREATOR,
    GENERAL_INITIAL_CREATE_NOTIFY_CREATOR_STATE,
    GENERAL_UPDATE_NOTIFY_CREATOR,
    GENERAL_INITIAL_UPDATE_NOTIFY_CREATOR_STATE,
    GENERAL_DELETE_NOTIFY_CREATOR,
    GENERAL_INITIAL_DELETE_NOTIFY_CREATOR_STATE
  } from '@/core/store/configuration/config-notify-creator.module';

  export default {
    name: 'ConfigNotifyCreator',
    mixins: [commonMixin],
    components: {
      ConfigNumberFormField
    },
    data: () => ({
      notifyCreatorObj: []
    }),
    mounted() {
      this.initialConfiguration();
    },
    computed: {
      notifyCreatorComplete() {
        return this.$store.state.configNotifyCreator.notifyCreator.complete;
      },
      createNotifyCreatorComplete() {
        return this.$store.state.configNotifyCreator.createNotifyCreator
          .complete;
      },
      updateNotifyCreatorComplete() {
        return this.$store.state.configNotifyCreator.updateNotifyCreator
          .complete;
      },
      deleteNotifyCreatorComplete() {
        return this.$store.state.configNotifyCreator.deleteNotifyCreator
          .complete;
      }
    },
    watch: {
      notifyCreatorComplete() {
        let response = this.$store.state.configNotifyCreator.notifyCreator;
        let title = i18nHelper.getMessage('label.newOrderMessageNotification');
        let initialStateAction = GENERAL_INITIAL_GET_NOTIFY_CREATOR_STATE;
        let successAction = (response) => {
          this.importNotifyCreatorObj(response);
        };
        if (response.complete) {
          this.actionReadRecordComplete(
            response,
            title,
            initialStateAction,
            successAction
          );
        }
      },
      createNotifyCreatorComplete() {
        let response =
          this.$store.state.configNotifyCreator.createNotifyCreator;
        if (response.complete) {
          this.getNotifyCreator();
          this.initialCreateNotifyCreator();
        }
      },
      updateNotifyCreatorComplete() {
        let response =
          this.$store.state.configNotifyCreator.updateNotifyCreator;
        if (response.complete) {
          this.getNotifyCreator();
          this.initialUpdateNotifyCreator();
        }
      },
      deleteNotifyCreatorComplete() {
        let response =
          this.$store.state.configNotifyCreator.deleteNotifyCreator;
        if (response.complete) {
          this.getNotifyCreator();
          this.initialDeleteNotifyCreator();
        }
      }
    },
    methods: {
      importNotifyCreatorObj(response) {
        this.notifyCreatorObj = response.data;
      },
      getNotifyCreator() {
        this.$store.dispatch(GENERAL_GET_NOTIFY_CREATOR);
      },
      createNotifyCreatorNumberAction(value) {
        let data = {
          contactNumber: 60 + value.contactNumber
        };
        this.$store.dispatch(GENERAL_CREATE_NOTIFY_CREATOR, { data });
      },
      updateNotifyCreatorNumberAction(value) {
        let id = value.id;
        let data = {
          contactNumber: 60 + value.contactNumber
        };
        this.notifyCreatorObj = '';
        this.$store.dispatch(GENERAL_UPDATE_NOTIFY_CREATOR, { id, data });
      },
      deleteNotifyCreatorNumberAction(value) {
        let id = value.id;
        this.$store.dispatch(GENERAL_DELETE_NOTIFY_CREATOR, { id });
      },
      initialCreateNotifyCreator() {
        this.$store.dispatch(GENERAL_INITIAL_CREATE_NOTIFY_CREATOR_STATE, {});
      },
      initialUpdateNotifyCreator() {
        this.$store.dispatch(GENERAL_INITIAL_UPDATE_NOTIFY_CREATOR_STATE, {});
      },
      initialDeleteNotifyCreator() {
        this.$store.dispatch(GENERAL_INITIAL_DELETE_NOTIFY_CREATOR_STATE, {});
      },
      initialConfiguration() {
        this.getNotifyCreator();
      }
    }
  };
</script>

<style></style>
