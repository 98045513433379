<template>
  <!--begin::Card-->
  <b-card data-style="custom">
    <div class="px-7">
      <b-form>
        <!--begin::Banner Information Section-->
        <AppSectionContainer1>
          <!--begin::Tab Title-->
          <AppField1>
            <h6 class="text-dark font-weight-bold mb-10 text-capitalize">
              {{ $t('label.bannerInformation') }}:
            </h6>
          </AppField1>
          <!--end::Tab Title-->
          <template v-for="(i, index) in formFields.filter((x) => x.field == 1)">
            <AppField1 v-if="i.model[1] != 'actionValue'" :key="index" :title="$t(`label.${i.title}`)">
              <AppFormField v-if="i.isObjectModel" v-model="$v[i.model[0]][i.model[1]].$model"
                :state="validateObjState(i.model[0], i.model[1])" :validationValue="$v[i.model[0]][i.model[1]]"
                :title="$t(`label.${i.title}`)" :validators="i.validators" :validationParams="i.validationParams"
                :disabled="isFieldDisabled" :formType="i.formType" :options="i.options" :moreRemark="i.moreRemark" noTitle
                solid @change="change"></AppFormField>
              <AppFormField v-else v-model="$v[i.model[0]].$model" :state="validateState(i.model[0])"
                :validationValue="$v[i.model[0]]" :title="$t(`label.${i.title}`)" :validators="i.validators"
                :validationParams="i.validationParams" :disabled="isFieldDisabled" :formType="i.formType"
                :options="i.options" :textField="i.textField" :moreRemark="i.moreRemark" noTitle solid></AppFormField>
              <div style="display: none">
                <!-- WARNING: I have no idea why this line is here, but removing it breaks everything -->

                <!-- WARNING: I have no idea why this line is here, but removing it breaks everything -->
                {{ $v }}
              </div>
            </AppField1>

            <!-- begin:: Banner Action Value Field -->
            <AppField1 v-else-if="isShowBannerActionValueField" :key="index" :title="$t(`label.${i.title}`)">
              <AppLiveSearch ref="banner-action-value-field" v-model="bannerObj.actionValue"
                :title="$t('label.actionValue')" :actionType="bannerObj.actionType" :actionValue="bannerObj.actionValue"
                :disabled="isFieldDisabled" noTitle isExisting></AppLiveSearch>
            </AppField1>
            <!-- end:: Banner Action Value Field -->
          </template>

          <!--begin::Is Active Field-->
          <AppField1 :title="$t('label.isActive')">
            <b-button :class="{
              'cursor-default': isFieldDisabled
            }" :variant="`light-${bannerObj.isActive ? 'success' : 'danger'}`" :disabled="isFieldDisabled" @click="
  openRequestupdateBannerActiveInactiveModal(
    id,
    bannerObj.isActive
  )
  ">{{
    $t(`enumActivationStatus.${Number(bannerObj.isActive)}`)
  }}</b-button>
          </AppField1>
          <!--end::Is Active Field-->
        </AppSectionContainer1>
        <!--end::Banner Information Section-->

        <!--begin::Media Field-->
        <AppSectionContainer1 class="my-5">
          <div class="pb-5">
            <template v-for="(i, index) in formFields.filter((x) => x.field == 2)">
              <AppField1 :key="index" v-if="index == 0" :title="$t(`label.${i.title}`)">
                <AppFormField v-model="$v.bannerMedia[i.model].$model" :state="validateObjState('bannerMedia', i.model)"
                  :validationValue="$v.bannerMedia[i.model]" :title="$t(`label.${i.title}`)" :solid="true"
                  :validators="i.validators" :formType="i.formType" :options="i.options"
                  :validationParams="i.validationParams" :accept="i.accept" :config="i.config" :moreRemark="i.moreRemark"
                  @change="i.action" :horizontal="true" noTitle></AppFormField>
                <div style="display: none">
                  <!-- WARNING: I have no idea why this line is here, but removing it breaks everything -->

                  <!-- WARNING: I have no idea why this line is here, but removing it breaks everything -->
                  {{ $v }}
                </div>
              </AppField1>
            </template>
          </div>

          <!--begin::Media Video/Image Field-->
          <AppField1 :title="$t(`label.${mediaFieldTitle}`)">
            <div v-if="!bannerMedia.mediaVideo && !bannerMedia.mediaImage">
              <div v-if="!isFieldDisabled" class="w-50 position-relative">
                <b-button squared variant="warning" class="position-absolute media-delete-btn"
                  @click="clickedReplaceMedia()">
                  <i class="flaticon-upload p-0"></i></b-button>
              </div>
              <AppImageViewer v-if="bannerObj.mediaType == bannerMediaType.IMAGE" :value="bannerObj.mediaPath"
                class="w-50"></AppImageViewer>
              <video v-else-if="bannerObj.mediaType == bannerMediaType.VIDEO" :key="bannerObj.mediaPath" controls autoplay
                muted class="w-50">
                <source :src="bannerObj.mediaPath" type="video/mp4" />
              </video>
            </div>
            <template v-for="(i, index) in formFields.filter((x) => x.field == 2)">
              <div :key="index" :style="`display: ${bannerMedia[i.model] ? 'block' : 'none'}`">
                <div v-if="i.mediaType == bannerMedia.mediaType &&
                  (index == 1 || index == 2)
                  " class="pb-5">
                  <AppFormField v-model="$v.bannerMedia[i.model].$model" :id="'replaceMediaFile'"
                    :state="validateObjState('bannerMedia', i.model)" :validationValue="$v.bannerMedia[i.model]"
                    :title="$t(`label.${i.title}`)" :solid="true" :validators="i.validators" :formType="i.formType"
                    :options="i.options" :validationParams="i.validationParams" :accept="i.accept" :config="i.config"
                    :moreRemark="i.moreRemark" :horizontal="true" noTitle></AppFormField>
                  <div style="display: none">
                    <!-- WARNING: I have no idea why this line is here, but removing it breaks everything -->

                    <!-- WARNING: I have no idea why this line is here, but removing it breaks everything -->
                    {{ $v }}
                  </div>

                  <BannerMediaFileStandardMessage v-if="i.fileStandardMessage" :mediaType="i.mediaType" :bannerType="type"
                    :bannerDimension="bannerDimension"></BannerMediaFileStandardMessage>
                </div>
              </div>
            </template>
          </AppField1>
          <!--end::Media Video/Image Field-->

          <!--begin::Media Thumbnail Field-->
          <AppField1 v-if="bannerMedia.mediaType != 1" :title="$t(`label.thumbnail`)">
            <div v-if="!bannerMedia.mediaThumbnailImage">
              <div v-if="!isFieldDisabled" class="w-50 position-relative">
                <b-button squared variant="warning" class="position-absolute media-delete-btn"
                  @click="clickedReplaceMediaThumbnail()">
                  <i class="flaticon-upload p-0"></i></b-button>
              </div>
              <AppImageViewer v-if="bannerObj.thumbnailPath" :value="bannerObj.thumbnailPath" class="w-50">
              </AppImageViewer>
            </div>
            <template v-for="(i, index) in formFields.filter((x) => x.field == 2)">
              <div :key="index" v-if="i.model.includes('mediaThumbnailImage')"
                :style="`display: ${bannerMedia[i.model] ? 'block' : 'none'}`">
                <AppFormField v-model="$v.bannerMedia[i.model].$model" :id="'replaceMediaThumbnail'"
                  :state="validateObjState('bannerMedia', i.model)" :validationValue="$v.bannerMedia[i.model]"
                  :title="$t(`label.${i.title}`)" :solid="true" :validators="i.validators" :formType="i.formType"
                  :options="i.options" :validationParams="i.validationParams" :accept="i.accept" :config="i.config"
                  :moreRemark="i.moreRemark" :horizontal="true" noTitle></AppFormField>
                <div style="display: none">
                  <!-- WARNING: I have no idea why this line is here, but removing it breaks everything -->
                  {{ $v }}
                </div>

                <BannerMediaFileStandardMessage v-if="i.fileStandardMessage" :mediaType="i.mediaType" :bannerType="type"
                  :bannerDimension="bannerDimension"></BannerMediaFileStandardMessage>
              </div>
            </template>
          </AppField1>
          <!--end::Media Thumbnail Field-->

          <!--end::Media Field-->
        </AppSectionContainer1>

        <!--begin::Action Section-->
        <AppSectionContainer1 v-if="checkAccessRight(moduleName, [permissionAction.BANNER_UPDATE])">
          <!--begin::Action Buttons-->
          <AppField1>
            <!--begin::Reset Button-->
            <!-- <b-button @click="resetForm">{{ $t('label.reset') }}</b-button> -->
            <!--begin::Reset Button-->

            <!--begin::Save Changes Button-->
            <b-button variant="light-primary" class="font-weight-bold ml-2" @click="updateBannerAction()">{{
              $t('label.saveChanges') }}</b-button>
            <!--end::Save Changes Button-->
          </AppField1>
          <!--end::Action Buttons-->
        </AppSectionContainer1>
        <!--end::Action Section-->

        <div class="separator separator-dashed my-10"></div>

        <!--begin::Date Field-->
        <b-row class="justify-content-center">
          <b-col md="9" lg="8" xl="7" class="my-2">
            <template v-for="(item, index) in dateFields">
              <AppField1 :key="index" :title="$t(`label.${item}`)">
                <label class="text-muted">{{ bannerObj[item] }}</label>
              </AppField1>
            </template>
          </b-col>
        </b-row>
        <!--begin::Date Field-->

        <template v-if="checkAccessRight(moduleName, [permissionAction.BANNER_DELETE])">
          <div class="separator separator-dashed my-5"></div>

          <!--begin::Action Section-->
          <AppSectionContainer1><!--begin::Action Buttons-->
            <AppField1>
              <b-button variant="light-danger" class="font-weight-bold" @click="openRequestDeleteBannerModal(id)">{{
                $t('label.deleteThisBanner') }}</b-button>
            </AppField1>
          </AppSectionContainer1>
        </template>
      </b-form>
    </div>
  </b-card>
  <!--end::Card-->
</template>

<script>
import KTUtil from '@/assets/js/components/util';
import BannerMediaFileStandardMessage from '@/views/components/banner/BannerMediaFileStandardMessage';
import AppFormField from '@/views/components/bases/form-field/AppFormField';
import AppField1 from '@/views/components/bases/field/AppField-1.vue';
import AppSectionContainer1 from '@/views/components/bases/section-container/AppSectionContainer-1.vue';
import AppImageViewer from '@/views/components/bases/image-viewer/AppImageViewer';
import AppLiveSearch from '@/views/components/bases/AppLiveSearch';
import constantsMixin from '@/core/mixins/constants.mixin';
import commonMixin from '@/core/mixins/common.mixin';
import bannerMixin from '@/core/mixins/banner.mixin';
import { validationMixin } from 'vuelidate';
import {
  MODULE,
  ACTION,
  ROUTE_NAME,
  GLOBAL,
  bannerImageType,
  bannerVideoType,
  fileSize,
  fileSizeMb
  // bannerImageDimension
} from '@/core/constants';
import { BannerActionType, BannerMediaType } from '@/core/constants/enums';
import { i18nHelper, uiHelper } from '@/core/utils';
import { listService } from '@/core/services';
import { required, numeric, maxLength } from 'vuelidate/lib/validators';
import {
  BANNER_GET_BANNER,
  BANNER_UPDATE_BANNER,
  BANNER_INITIAL_GET_BANNER_STATE,
  BANNER_INITIAL_UPDATE_BANNER_STATE
} from '@/core/store/banner.module';

export default {
  name: 'BannerDetails',
  components: {
    AppFormField,
    AppField1,
    AppSectionContainer1,
    AppImageViewer,
    BannerMediaFileStandardMessage,
    AppLiveSearch
  },
  mixins: [commonMixin, constantsMixin, bannerMixin, validationMixin],
  props: {
    type: {
      type: Number
    },
    bannerDimension: {
      type: Object
    }
  },
  data: () => ({
    id: null,
    moduleName: MODULE.BANNER,
    routeName: ROUTE_NAME,
    permissionAction: ACTION,
    bannerMediaType: BannerMediaType,
    replaceMedia: false,
    replaceMediaThumbnail: false,
    bannerObj: {
      id: null,
      title: null,
      description: '',
      type: null,
      mediaPath: null,
      mediaType: null,
      actionType: null,
      contentLabel: '',
      tagLabel: '',
      actionValue: null,
      priority: null,
      isActive: null,
      createdAt: null,
      updatedAt: null
    },
    bannerMedia: {
      mediaType: null,
      mediaImage: null,
      mediaVideo: null,
      mediaThumbnailImage: null
    },
    dateFields: ['createdAt', 'updatedAt'],
    formFields: []
  }),
  computed: {
    bannerComplete() {
      return this.$store.state.banner.banner.complete;
    },
    updateBannerComplete() {
      return this.$store.state.banner.updateBanner.complete;
    },
    updateBannerActiveInactiveComplete() {
      return this.$store.state.banner.updateBannerActiveInactive.complete;
    },
    deleteBannerComplete() {
      return this.$store.state.banner.deleteBanner.complete;
    },
    appUserRecipeComplete() {
      return this.$store.state.lookup.appUserRecipe.complete;
    },
    mediaFieldTitle() {
      let model = {
        [BannerMediaType.IMAGE]: 'image',
        [BannerMediaType.VIDEO]: 'video'
      };

      return model[this.bannerMedia.mediaType]
        ? model[this.bannerMedia.mediaType]
        : 'image';
    },
    isFieldDisabled() {
      return !this.checkAccessRight(MODULE.BANNER, [ACTION.BANNER_UPDATE]);
    },
    isShowBannerActionValueField() {
      const actionType = BannerActionType;
      var currentType = this.bannerObj.actionType;
      var isShow = false;

      if (currentType) {
        if (![actionType.NONE, actionType.COOKIE].includes(currentType)) {
          isShow = true;
        }
      }

      return isShow;
    }
  },
  watch: {
    bannerComplete() {
      let response = this.$store.state.banner.banner;
      if (response.complete) {
        this.getBannerCompleteAction(response);
      }
    },
    updateBannerComplete() {
      let response = this.$store.state.banner.updateBanner;
      if (response.complete) {
        this.updateBannerCompleteAction(response);
      }
    },
    updateBannerActiveInactiveComplete() {
      let response = this.$store.state.banner.updateBannerActiveInactive;
      if (response.complete) {
        this.updateBannerActiveInactiveCompleteAction(response);
      }
    },
    deleteBannerComplete() {
      let response = this.$store.state.banner.deleteBanner;
      if (response.complete) {
        this.deleteBannerCompleteAction(response);
      }
    }
  },
  created() {
    this.initialBannerDetails();
  },
  mounted() {
    this.initFormFields();
  },
  methods: {
    change() {
      // this.initialBannerObjActionValue();
    },
    clickedReplaceMediaThumbnail() {
      this.replaceMediaThumbnail = true;
      document.getElementById('replaceMediaThumbnail').click();
    },
    clickedReplaceMedia() {
      this.replaceMedia = true;
      document.getElementById('replaceMediaFile').click();
    },
    deleteBannerCompleteAction(response) {
      let title = i18nHelper.getMessage('label.deleteBanner');
      let messages = response.message;
      let buttons = [];

      if (response.code == 0) {
        buttons.push({
          text: i18nHelper.getMessage('label.ok'),
          variant: 'primary',
          action: () => {
            this.closeLayoutAppModalInfo();
            this.$router.push({
              name: this.getBannerListRouteName(this.type)
            });
          }
        });
      } else {
        buttons.push({
          text: i18nHelper.getMessage('label.ok'),
          variant: 'primary',
          action: () => {
            this.closeLayoutAppModalInfo();
          }
        });
      }

      this.openLayoutAppModalInfo(title, messages, buttons);
      this.initialDeleteBannerState();
    },
    updateBannerActiveInactiveCompleteAction(response) {
      let title = i18nHelper.getMessage('label.updateBannerStatus');
      let messages = response.message;
      let buttons = [];

      buttons.push({
        text: i18nHelper.getMessage('label.ok'),
        variant: 'primary',
        action: () => {
          this.closeLayoutAppModalInfo();
          this.resetForm();
        }
      });
      this.openLayoutAppModalInfo(title, messages, buttons);

      this.initialUpdateBannerActiveInactiveState();
    },
    updateBannerCompleteAction(response) {
      let title = i18nHelper.getMessage('label.updateBanner');
      let messages = response.message;
      let buttons = [];

      buttons.push({
        text: i18nHelper.getMessage('label.ok'),
        variant: 'primary',
        action: () => {
          this.closeLayoutAppModalInfo();
          if (response.code == 0) {
            KTUtil.scrollTop();
            this.resetForm();
          }
        }
      });
      this.openLayoutAppModalInfo(title, messages, buttons);

      this.initialUpdateBannerState();
    },
    getBannerCompleteAction(response) {
      const actionType = BannerActionType;
      var data = response.data;
      var title = i18nHelper.getMessage('label.bannerDetails');

      if (response.code == 0) {
        this.bannerObj = data;
        this.bannerMedia.mediaType = data.mediaType;

        this.$nextTick(() => {
          let currentType = this.bannerObj.actionType;

          this.$v.$reset(data);

          if (
            ![
              actionType.NONE,
              actionType.COOKIE,
              actionType.VOUCHER
            ].includes(currentType)
          ) {
            this.$refs['banner-action-value-field'][0].initActionValueField();
          }
        });
      } else {
        this.openErrorLayoutAppModalInfo(title, response);
      }
      this.initialGetBannerState();
    },
    initialBannerObjActionValue() {
      this.bannerObj.actionValue = null;
    },
    resetForm() {
      this.replaceMedia = false;
      this.replaceMediaThumbnail = false;
      this.bannerMedia = {
        mediaImage: null,
        mediaVideo: null,
        mediaThumbnailImage: null,
        mediaType: null
      };
      this.getBanner(this.id);
    },
    updateBannerAction() {
      const actionType = BannerActionType;
      let bannerInfo = this.bannerObj;
      let bannerMedia = this.bannerMedia;
      let formData = new FormData();

      var mediaPath = {
        [BannerMediaType.IMAGE]: this.bannerMedia.mediaImage,
        [BannerMediaType.VIDEO]: this.bannerMedia.mediaVideo
      };

      formData.append('title', bannerInfo.title);
      formData.append('description', bannerInfo.description ?? '');
      formData.append('action_type', bannerInfo.actionType);
      formData.append('priority', bannerInfo.priority);
      formData.append('content_label', bannerInfo.contentLabel ?? '');
      formData.append('tag_label', bannerInfo.tagLabel ?? '');
      formData.append('is_active', Number(bannerInfo.isActive));
      formData.append('type', this.type);
      if (mediaPath[bannerMedia.mediaType])
        formData.append('media_path', mediaPath[bannerMedia.mediaType]);
      if (
        bannerMedia.mediaThumbnailImage &&
        this.bannerMedia.mediaType == BannerMediaType.VIDEO
      )
        formData.append('thumbnail', bannerMedia.mediaThumbnailImage);

      // Action value is not required when action type is none or cookie
      if (
        ![actionType.NONE, actionType.COOKIE].includes(bannerInfo.actionType)
      ) {
        formData.append('action_value', bannerInfo.actionValue);
      }
      if (
        this.validationCheck('bannerObj') &&
        this.validationActionValueCheck() &&
        (this.replaceMedia || this.replaceMediaThumbnail
          ? this.validationMediaCheck()
          : true)
      ) {
        this.openRequestUpdateBannerModal(formData, this.id);
      }
    },
    openRequestUpdateBannerModal(formData, id) {
      let title = i18nHelper.getMessage('label.updateBannerDetails');
      let messages = [];
      let buttons = [];

      messages.push(
        i18nHelper.getMessage('message.requestModifyBannerMessage', [
          i18nHelper.getMessage('label.update').toLowerCase()
        ])
      );

      buttons.push({
        text: i18nHelper.getMessage('label.cancel'),
        variant: '',
        action: () => {
          this.closeLayoutAppModalInfo();
        }
      });

      buttons.push({
        text: i18nHelper.getMessage('label.confirm'),
        variant: 'primary',
        action: () => {
          this.updateBanner(formData, id);
          this.closeLayoutAppModalInfo();
        }
      });

      this.openLayoutAppModalInfo(title, messages, buttons);
    },
    validationActionValueCheck() {
      const actionType = BannerActionType;
      var isValid = true;
      var currentType = this.bannerObj.actionType;

      if (!['', null, undefined].includes(currentType)) {
        if (![actionType.NONE, actionType.COOKIE].includes(currentType)) {
          isValid = this.$refs['banner-action-value-field'][0].validate();
        }
      } else {
        isValid = false;
      }

      return isValid;
    },
    validationMediaCheck() {
      let validation = true;

      switch (this.bannerMedia.mediaType) {
        case BannerMediaType.IMAGE:
          validation = this.validationObjCheck('bannerMedia', 'mediaImage');
          break;
        // case BannerMediaType.VIDEO:
        //   validation =
        //     this.validationObjCheck('bannerMedia', 'mediaVideo') &&
        //     this.validationObjCheck('bannerMedia', 'mediaThumbnailImage');
        //   break;
      }
      return validation;
    },
    initFormFields() {
      this.formFields = [];
      // let dimension = this.bannerDimension;

      this.formFields.push(
        {
          field: 1,
          model: ['bannerObj', 'title'],
          title: 'title',
          validators: ['required'],
          isObjectModel: true
        },
        {
          field: 1,
          model: ['bannerObj', 'description'],
          title: 'description',
          isObjectModel: true,
          formType: 'textarea',
          validators: ['maxLength'],
          validationParams: [
            {
              key: 'maxLength',
              params: [100]
            }
          ],
          moreRemark: 'Maximum 100 characters'
        },
        {
          field: 1,
          model: ['bannerObj', 'priority'],
          title: 'priority',
          validators: ['required', 'numeric'],
          isObjectModel: true
        },
        {
          field: 1,
          model: ['bannerObj', 'actionType'],
          title: 'actionType',
          validators: ['required'],
          formType: 'select',
          options: listService.getBannerActionTypeList(
            this.constantsData.bannerAction
          ),
          isObjectModel: true
        },
        {
          field: 1,
          model: ['bannerObj', 'actionValue'],
          title: 'actionValue'
        },
        {
          field: 1,
          model: ['bannerObj', 'contentLabel'],
          title: 'contentLabel',
          isObjectModel: true
        },
        {
          field: 1,
          model: ['bannerObj', 'tagLabel'],
          title: 'tagLabel',
          isObjectModel: true
        },
        {
          field: 2,
          model: ['mediaType'],
          title: 'mediaType',
          // validators: ['required'],
          formType: 'select',
          options: listService.getBannerMediaTypeList(false),
          isObjectModel: false,
          action: (val) => {
            this.mediaTypeChanged(val);
          }
        },
        {
          field: 2,
          model: ['mediaImage'],
          title: 'media',
          formType: 'file',
          mediaType: 1, //1 = image, 2 = video
          validators: [
            'required',
            'bannerImageType',
            'fileSize'
            // 'bannerImageDimension'
          ],
          validationParams: [
            {
              key: 'bannerImageType',
              params: [
                uiHelper.arrayToTrimmedStringWithSpace(
                  GLOBAL.AVAILABLE_BANNER_IMAGE_TYPE,
                  6
                )
              ]
            },
            {
              key: 'fileSize',
              params: [
                GLOBAL.BANNER_IMAGE_UPLOAD_SIZE,
                GLOBAL.BANNER_IMAGE_UPLOAD_SIZE_TYPE
              ]
            }
            // {
            //   key: 'bannerImageDimension',
            //   params: [dimension.height, dimension.width]
            // }
          ],
          accept: GLOBAL.AVAILABLE_BANNER_IMAGE_TYPE.toString(),
          type: BannerMediaType.IMAGE,
          isObjectModel: false,
          fileStandardMessage: true
        },
        {
          field: 2,
          model: ['mediaVideo'],
          title: 'media',
          formType: 'file',
          mediaType: 2, //1 = image, 2 = video
          validators: [
            'required',
            'bannerVideoType',
            'fileSize'
            // 'bannerVideoDimension'
          ],
          validationParams: [
            {
              key: 'bannerVideoType',
              params: [
                uiHelper.arrayToTrimmedStringWithSpace(
                  GLOBAL.AVAILABLE_BANNER_VIDEO_TYPE,
                  6
                )
              ]
            },
            {
              key: 'fileSize',
              params: [
                GLOBAL.BANNER_VIDEO_UPLOAD_SIZE,
                GLOBAL.BANNER_VIDEO_UPLOAD_SIZE_TYPE
              ]
            }
            // {
            //   key: 'bannerVideoDimension',
            //   params: [dimension.height, dimension.width]
            // }
          ],
          accept: GLOBAL.AVAILABLE_BANNER_VIDEO_TYPE.toString(),
          type: BannerMediaType.VIDEO,
          isObjectModel: false,
          config: {
            isVideoFile: true
          },
          fileStandardMessage: true
        },
        {
          field: 2,
          model: ['mediaThumbnailImage'],
          title: 'thumbnail',
          formType: 'file',
          mediaType: 1, //1 = image, 2 = video
          validators: [
            'required',
            'bannerImageType',
            'fileSize'
            // 'bannerImageDimension'
          ],
          validationParams: [
            {
              key: 'bannerImageType',
              params: [
                uiHelper.arrayToTrimmedStringWithSpace(
                  GLOBAL.AVAILABLE_BANNER_IMAGE_TYPE,
                  6
                )
              ]
            },
            {
              key: 'fileSize',
              params: [
                GLOBAL.BANNER_IMAGE_UPLOAD_SIZE,
                GLOBAL.BANNER_IMAGE_UPLOAD_SIZE_TYPE
              ]
            }
            // {
            //   key: 'bannerImageDimension',
            //   params: [dimension.height, dimension.width]
            // }
          ],
          accept: GLOBAL.AVAILABLE_BANNER_IMAGE_TYPE.toString(),
          type: BannerMediaType.VIDEO,
          isObjectModel: false,
          fileStandardMessage: true
        }
      );
    },
    mediaTypeChanged(val) {
      if (val == this.bannerObj.mediaType) {
        this.bannerMedia.mediaImage = null;
        this.bannerMedia.mediaVideo = null;
        this.bannerMedia.mediaThumbnailImage = null;
      } else {
        this.bannerMedia.mediaImage = [];
        this.bannerMedia.mediaVideo = [];
        this.bannerMedia.mediaThumbnailImage = [];
      }

      // this.replaceMedia = true;
      // this.replaceMediaThumbnail = true;
    },
    getBanner(id) {
      this.$store.dispatch(BANNER_GET_BANNER, {
        id
      });
    },
    updateBanner(formData, id) {
      this.$store.dispatch(BANNER_UPDATE_BANNER, { formData, id });
    },
    initialGetBannerState() {
      this.$store.dispatch(BANNER_INITIAL_GET_BANNER_STATE);
    },
    initialUpdateBannerState() {
      this.$store.dispatch(BANNER_INITIAL_UPDATE_BANNER_STATE);
    },
    initialBannerDetails() {
      let type = this.type;
      let id = this.$route.params.id;

      let breadcrumbData = {
        breadcrumbs: [
          {
            title: i18nHelper.getMessage('label.banners'),
            route: { name: ROUTE_NAME.BANNER }
          },
          {
            title: i18nHelper.getMessage(`enumBannerType.${type}`),
            route: { name: this.getBannerRouteName(type) }
          },
          {
            title: i18nHelper.getMessage(`enumBannerDetailsType.${type}`)
          }
        ],
        actions: [
          {
            text: i18nHelper.getMessage('label.back'),
            function: () => {
              this.$router.go(-1);
            }
          }
        ]
      };

      this.id = id;
      this.getBanner(id);
      this.initBreadCrumb(breadcrumbData);
    }
  },
  validations() {
    // let dimension = this.bannerDimension;

    return {
      bannerObj: {
        title: {
          required
        },
        description: {
          maxLength: maxLength(100)
        },
        actionType: {
          required
        },
        priority: {
          required,
          numeric
        },
        contentLabel: {},
        tagLabel: {},
        mediaType: {
          required
        }
      },
      bannerMedia: {
        mediaType: {
          // required
        },
        mediaImage: {
          required,
          bannerImageType,
          fileSize: fileSize(GLOBAL.BANNER_IMAGE_UPLOAD_SIZE)
          // bannerImageDimension: bannerImageDimension(
          //   dimension.height,
          //   dimension.width
          // )
        },
        mediaVideo: {
          required,
          bannerVideoType,
          fileSize: fileSizeMb(GLOBAL.BANNER_VIDEO_UPLOAD_SIZE)
        },
        mediaThumbnailImage: {
          required,
          bannerImageType,
          fileSize: fileSize(GLOBAL.BANNER_IMAGE_UPLOAD_SIZE)
          // bannerImageDimension: bannerImageDimension(
          //   dimension.height,
          //   dimension.width
          // )
        }
      }
    };
  }
};
</script>

<style lang="scss">
.media-delete-btn {
  right: 0;
  z-index: 1;
  border-radius: 0 0 0 33.33% !important;
}
</style>
