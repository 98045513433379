import { apiService } from '@/core/services';
// import { apiHelper } from '@/core/utils';

export const CONFIG_NOTIFY_STUDIO = {
  getNotifyStudio,
  createNotifyStudio,
  updateNotifyStudio,
  deleteNotifyStudio
};

const URL = {
  ADMIN: 'admin',
  CONTACT_NUMBERS: 'contact-numbers'
};

function getNotifyStudio() {
  let requestOption = {
    params: {
      type: 2
    }
  };
  return apiService().get(
    `/${URL.ADMIN}/${URL.CONTACT_NUMBERS}`,
    requestOption
  );
}

function createNotifyStudio(data) {
  let requestBody = {
    contact_number: data.contactNumber,
    type: 2
  };
  return apiService().post(`/${URL.ADMIN}/${URL.CONTACT_NUMBERS}`, requestBody);
}

function updateNotifyStudio(id, data) {
  let requestBody = {
    contact_number: data.contactNumber,
    type: 2
  };
  return apiService().put(
    `/${URL.ADMIN}/${URL.CONTACT_NUMBERS}/${id}`,
    requestBody
  );
}

function deleteNotifyStudio(id) {
  return apiService().delete(`/${URL.ADMIN}/${URL.CONTACT_NUMBERS}/${id}`);
}
