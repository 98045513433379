<template>
  <b-modal
    :visible="visible"
    centered
    hide-header-close
    no-close-on-esc
    no-close-on-backdrop
    :title="$t('label.cancelOrder')"
    header-class="text-capitalize"
  >
    <p>{{ $t('message.requestRejectOrderMessage') }}</p>

    <AppFormField
      v-model="$v.rejectedReason.$model"
      solid
      :state="validateState('rejectedReason')"
      :validationValue="$v.rejectedReason"
      :title="$t('label.reason')"
      :validators="['required']"
    >
    </AppFormField>
    <div style="display: none">
      <!-- WARNING: I have no idea why this line is here, but removing it breaks everything -->
      {{ $v }}
    </div>
    <template #modal-footer>
      <b-button @click="onClickCancel">{{ $t('label.cancel') }}</b-button>
      <b-button variant="danger" @click="onClickReject">{{
        $t('label.submit')
      }}</b-button>
    </template>
  </b-modal>
</template>

<script>
  import AppFormField from '@/views/components/bases/form-field/AppFormField';
  import CommonMixin from '@/core/mixins/common.mixin';
  import { validationMixin } from 'vuelidate';
  import { required } from 'vuelidate/lib/validators';

  export default {
    name: 'OrderRejectOrderModal',
    components: {
      AppFormField
    },
    mixins: [CommonMixin, validationMixin],
    data: () => ({
      visible: false,
      rejectedReason: null
    }),
    methods: {
      onClickCancel() {
        this.close();
      },
      onClickReject() {
        if (this.validationCheck('rejectedReason')) {
          this.$emit('reject', this.rejectedReason);
          this.close();
        }
      },
      show() {
        this.visible = true;
      },
      close() {
        this.visible = false;
        this.rejectedReason = null;
        this.$v.$reset();
      }
    },
    validations: {
      rejectedReason: {
        required
      }
    }
  };
</script>

<style></style>
