<template>
  <div class="input-group color-picker" ref="colorpicker">
    <input
      type="text"
      class="form-control form-control form-control-md form-control-solid color-picker"
      v-model="color"
      @focus="showPicker()"
      @input="updateFromInput"
      readonly
    />
    <span
      class="input-group-addon color-picker-container"
      :class="displayPicker ? 'open-color-picker' : 'close-color-picker'"
    >
      <span
        class="current-color"
        :style="'background-color: ' + color"
        @click="togglePicker()"
      ></span>
      <chrome-picker
        :value="colors"
        @input="updateFromPicker"
        v-if="displayPicker"
      />
    </span>
  </div>
</template>

<script>
  import { Chrome } from 'vue-color';

  export default {
    name: 'ColorPicker',
    components: {
      'chrome-picker': Chrome
    },
    props: ['color'],
    data() {
      return {
        colors: {
          hex: '#000000'
        },
        colorValue: '',
        displayPicker: false
      };
    },
    mounted() {
      this.setColor(this.color || '#000000');
    },
    methods: {
      setColor(color) {
        this.updateColors(color);
        this.colorValue = color;
      },
      updateColors(color) {
        if (color.slice(0, 1) == '#') {
          this.colors = {
            hex: color
          };
        } else if (color.slice(0, 4) == 'rgba') {
          var rgba = color.replace(/^rgba?\(|\s+|\)$/g, '').split(','),
            hex =
              '#' +
              (
                (1 << 24) +
                (parseInt(rgba[0]) << 16) +
                (parseInt(rgba[1]) << 8) +
                parseInt(rgba[2])
              )
                .toString(16)
                .slice(1);
          this.colors = {
            hex: hex,
            a: rgba[3]
          };
        }
      },
      showPicker() {
        document.addEventListener('click', this.documentClick);
        this.displayPicker = true;
      },
      hidePicker() {
        document.removeEventListener('click', this.documentClick);
        this.displayPicker = false;
      },
      togglePicker() {
        this.displayPicker ? this.hidePicker() : this.showPicker();
      },
      updateFromInput() {
        this.updateColors(this.colorValue);
      },
      updateFromPicker(color) {
        this.colors = color;
        if (color.rgba.a == 1) {
          this.colorValue = color.hex;
        } else {
          this.colorValue =
            'rgba(' +
            color.rgba.r +
            ', ' +
            color.rgba.g +
            ', ' +
            color.rgba.b +
            ', ' +
            color.rgba.a +
            ')';
        }
      },
      documentClick(e) {
        var el = this.$refs.colorpicker,
          target = e.target;
        if (el !== target && !el.contains(target)) {
          this.hidePicker();
        }
      }
    },
    watch: {
      colorValue(val) {
        if (val) {
          this.updateColors(val);
          this.$emit('input', val);
          //document.body.style.background = val;
        }
      }
    }
  };
</script>

<style>
  .color-picker-container {
    position: absolute;
    right: 0;
    top: -10px;
  }
  .current-color {
    height: 25px;
    width: 25px;
    display: block;
    top: 17px;
    position: absolute;
    right: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  .color-picker {
    cursor: pointer;
  }
  .open-color-picker {
    z-index: 100;
  }
</style>
