import dateTimeFormat from '@/core/filters/dateTimeFormat.filter';
import { CONFIG_EMAIL_RECIPIENT } from '@/core/api';
import { apiHelper } from '@/core/utils';
import {
  LOADING_OPEN_LOADING,
  LOADING_CLOSE_LOADING
} from '@/core/store/loading.module';

/* Define Module Name */
const MODULE_NAME = 'configEmailRecipient/';

/* Define Action Name */
const A_GET_EMAIL_RECIPIENT = 'getEmailRecipient';
const A_CREATE_EMAIL_RECIPIENT = 'createEmailRecipient';
const A_UPDATE_EMAIL_RECIPIENT = 'updateEmailRecipient';
const A_DELETE_EMAIL_RECIPIENT = 'deleteEmailRecipient';

/* Define Reset State Action Name */
const A_INITIAL_GET_EMAIL_RECIPIENT_STATE = 'initialGetEmailRecipientState';
const A_INITIAL_CREATE_EMAIL_RECIPIENT_STATE =
  'initialcreateEmailRecipientState';
const A_INITIAL_UPDATE_EMAIL_RECIPIENT_STATE =
  'initialupdateEmailRecipientState';
const A_INITIAL_DELETE_EMAIL_RECIPIENT_STATE =
  'initialDeleteEmailRecipientState';

/* Define Getter Name */

/* Define Mutation Name */
const M_GET_EMAIL_RECIPIENT = 'setGetEmailRecipient';
const M_CREATE_EMAIL_RECIPIENT = 'setCreateEmailRecipient';
const M_UPDATE_EMAIL_RECIPIENT = 'setUpdateEmailRecipient';
const M_DELETE_EMAIL_RECIPIENT = 'setDeleteEmailRecipient';

/* Define Reset State Mutation Name */
const M_INITIAL_GET_EMAIL_RECIPIENT_STATE = 'setInitialGetEmailRecipientState';
const M_INITIAL_CREATE_EMAIL_RECIPIENT_STATE =
  'setInitialCreateEmailRecipientState';
const M_INITIAL_UPDATE_EMAIL_RECIPIENT_STATE =
  'setInitialUpdateEmailRecipientState';
const M_INITIAL_DELETE_EMAIL_RECIPIENT_STATE =
  'setInitialDeleteEmailRecipientState';

/* Define Export Name */
export const GENERAL_GET_EMAIL_RECIPIENT = MODULE_NAME + A_GET_EMAIL_RECIPIENT;
export const GENERAL_CREATE_EMAIL_RECIPIENT =
  MODULE_NAME + A_CREATE_EMAIL_RECIPIENT;
export const GENERAL_UPDATE_EMAIL_RECIPIENT =
  MODULE_NAME + A_UPDATE_EMAIL_RECIPIENT;
export const GENERAL_DELETE_EMAIL_RECIPIENT =
  MODULE_NAME + A_DELETE_EMAIL_RECIPIENT;

/* Define Reset State Name */
export const GENERAL_INITIAL_GET_EMAIL_RECIPIENT_STATE =
  MODULE_NAME + A_INITIAL_GET_EMAIL_RECIPIENT_STATE;
export const GENERAL_INITIAL_CREATE_EMAIL_RECIPIENT_STATE =
  MODULE_NAME + A_INITIAL_CREATE_EMAIL_RECIPIENT_STATE;
export const GENERAL_INITIAL_UPDATE_EMAIL_RECIPIENT_STATE =
  MODULE_NAME + A_INITIAL_UPDATE_EMAIL_RECIPIENT_STATE;
export const GENERAL_INITIAL_DELETE_EMAIL_RECIPIENT_STATE =
  MODULE_NAME + A_INITIAL_DELETE_EMAIL_RECIPIENT_STATE;

const state = {
  emailRecipient: {
    code: 0,
    complete: false,
    data: null,
    message: null,
    pagination: {
      currentPage: 0,
      total: 0,
      perPage: 0,
      lastPage: 0
    }
  },
  createEmailRecipient: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  updateEmailRecipient: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  deleteEmailRecipient: {
    code: 0,
    complete: false,
    data: null,
    message: null
  }
};

//Getters
const getters = {};

// Actions
const actions = {
  [A_GET_EMAIL_RECIPIENT]({ dispatch, commit }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    CONFIG_EMAIL_RECIPIENT.getEmailRecipient()
      .then((response) => {
        result = response;
        commit(M_GET_EMAIL_RECIPIENT, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_EMAIL_RECIPIENT, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_CREATE_EMAIL_RECIPIENT]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    CONFIG_EMAIL_RECIPIENT.createEmailRecipient(data)
      .then((response) => {
        result = response;
        commit(M_CREATE_EMAIL_RECIPIENT, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_CREATE_EMAIL_RECIPIENT, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_UPDATE_EMAIL_RECIPIENT]({ dispatch, commit }, { id, data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    CONFIG_EMAIL_RECIPIENT.updateEmailRecipient(id, data)
      .then((response) => {
        result = response;
        commit(M_UPDATE_EMAIL_RECIPIENT, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_UPDATE_EMAIL_RECIPIENT, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_DELETE_EMAIL_RECIPIENT]({ dispatch, commit }, { id, data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    CONFIG_EMAIL_RECIPIENT.deleteEmailRecipient(id, data)
      .then((response) => {
        result = response;
        commit(M_DELETE_EMAIL_RECIPIENT, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_DELETE_EMAIL_RECIPIENT, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_INITIAL_CREATE_EMAIL_RECIPIENT_STATE]({ commit }) {
    commit(M_INITIAL_CREATE_EMAIL_RECIPIENT_STATE);
  },
  [A_INITIAL_GET_EMAIL_RECIPIENT_STATE]({ commit }) {
    commit(M_INITIAL_GET_EMAIL_RECIPIENT_STATE);
  },
  [A_INITIAL_UPDATE_EMAIL_RECIPIENT_STATE]({ commit }) {
    commit(M_INITIAL_UPDATE_EMAIL_RECIPIENT_STATE);
  },
  [A_INITIAL_DELETE_EMAIL_RECIPIENT_STATE]({ commit }) {
    commit(M_INITIAL_DELETE_EMAIL_RECIPIENT_STATE);
  }
};

// Mutations
const mutations = {
  [M_GET_EMAIL_RECIPIENT](state, result) {
    let d = result.data;
    state.emailRecipient = {
      code: result.code,
      complete: true,
      data: d.map((x) => ({
        id: x.id,
        created_by: x.created_by,
        email: x.email,
        updatedAt: dateTimeFormat(x.updated_at),
        createdAt: dateTimeFormat(x.created_at)
      })),
      message: apiHelper.sortOutMessage(result.message),
      pagination: {
        currentPage: 0,
        total: 0,
        perPage: 0,
        lastPage: 0
      }
    };
  },
  [M_CREATE_EMAIL_RECIPIENT](state, result) {
    state.createEmailRecipient = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result.message),
      pagination: {
        currentPage: 0,
        total: 0,
        perPage: 0,
        lastPage: 0
      }
    };
  },
  [M_UPDATE_EMAIL_RECIPIENT](state, result) {
    state.updateEmailRecipient = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result.message),
      pagination: {
        currentPage: 0,
        total: 0,
        perPage: 0,
        lastPage: 0
      }
    };
  },
  [M_DELETE_EMAIL_RECIPIENT](state, result) {
    state.deleteEmailRecipient = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result.message),
      pagination: {
        currentPage: 0,
        total: 0,
        perPage: 0,
        lastPage: 0
      }
    };
  },
  [M_INITIAL_GET_EMAIL_RECIPIENT_STATE](state) {
    state.emailRecipient = {
      code: 0,
      complete: false,
      data: null,
      message: null,
      pagination: {
        currentPage: 0,
        total: 0,
        perPage: 0,
        lastPage: 0
      }
    };
  },
  [M_INITIAL_CREATE_EMAIL_RECIPIENT_STATE](state) {
    state.createEmailRecipient = {
      code: 0,
      complete: false,
      data: null,
      message: null,
      pagination: {
        currentPage: 0,
        total: 0,
        perPage: 0,
        lastPage: 0
      }
    };
  },
  [M_INITIAL_UPDATE_EMAIL_RECIPIENT_STATE](state) {
    state.updateEmailRecipient = {
      code: 0,
      complete: false,
      data: null,
      message: null,
      pagination: {
        currentPage: 0,
        total: 0,
        perPage: 0,
        lastPage: 0
      }
    };
  },
  [M_INITIAL_DELETE_EMAIL_RECIPIENT_STATE](state) {
    state.deleteEmailRecipient = {
      code: 0,
      complete: false,
      data: null,
      message: null,
      pagination: {
        currentPage: 0,
        total: 0,
        perPage: 0,
        lastPage: 0
      }
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
