import { AUTH } from '@/core/api';
import { LOCAL } from '@/core/constants';
import {
  jwtHelper,
  uiHelper,
  i18nHelper,
  permissionHelper,
  apiHelper
} from '@/core/utils';
import {
  LOADING_OPEN_LOADING,
  LOADING_CLOSE_LOADING
} from '@/core/store/loading.module';

/* Define Module Name */
const MODULE_NAME = 'auth/';

/* Define Action Name */
const A_ME = 'me';
const A_LOGIN = 'login';
const A_LOGOUT = 'logout';
const A_REFRESH = 'refresh';
/* Define Reset State Action Name */
const A_INITIAL_LOGIN_STATE = 'initialLoginState';
const A_INITIAL_ME_STATE = 'initialMeState';

/* Define Getter Name */
const G_IS_AUTHENTICATED = 'isAuthenticated';

/* Define Mutation Name */
const M_ME = 'setMe';
const M_LOGIN = 'setLogin';
const M_LOGOUT = 'setLogout';
const M_LOGOUT_SESSION_EXPIRED = 'setLogoutSessionExpired';
const M_REFRESH = 'setRefresh';

/* Define Reset State Mutation Name */
const M_INITIAL_LOGIN_STATE = 'setInitialLoginState';
const M_INITIAL_ME_STATE = 'setInitialMeState';

/* Define Export Name */
export const AUTH_ME = MODULE_NAME + A_ME;
export const AUTH_LOGIN = MODULE_NAME + A_LOGIN;
export const AUTH_LOGOUT = MODULE_NAME + A_LOGOUT;
export const AUTH_REFRESH = MODULE_NAME + A_REFRESH;
export const AUTH_IS_AUTHENTICATED = MODULE_NAME + G_IS_AUTHENTICATED;

/* Define Reset State Name */
export const AUTH_INITIAL_LOGIN_STATE = MODULE_NAME + A_INITIAL_LOGIN_STATE;
export const AUTH_INITIAL_ME_STATE = MODULE_NAME + A_INITIAL_ME_STATE;

const state = {
  me: {
    code: 0,
    complete: false,
    data: {
      id: null,
      username: null,
      name: null,
      email: null,
      emailVerifiedAt: null,
      createdAt: null,
      updatedAt: null
    },
    message: null
  },
  loginResponse: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  logoutResponse: {
    code: 0,
    complete: false,
    data: null,
    message: null
  }
};

//Getters
const getters = {
  [G_IS_AUTHENTICATED]: (state) => {
    let result = false;
    let token = jwtHelper.getToken();

    if (state.loginResponse.complete) {
      token = jwtHelper.getToken();
    }

    if (![null, undefined, ''].includes(token)) {
      result = true;
    }

    return result;
  }
};

// Actions
const actions = {
  [A_ME]({ dispatch, commit }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    AUTH.me()
      .then((respone) => {
        let result = respone;
        commit(M_ME, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_ME, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  async [A_LOGIN]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    await AUTH.login(data)
      .then((respone) => {
        let result = respone;
        commit(M_LOGIN, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_LOGIN, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  async [A_LOGOUT]({ dispatch, commit }, { isSessionExpired }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    if (!isSessionExpired) {
      await AUTH.logout()
        .then((respone) => {
          let result = respone;
          commit(M_LOGOUT, result);
          dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
        })
        .catch((error) => {
          let result = error;
          commit(M_LOGOUT, result);
          dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
        });
    } else {
      commit(M_LOGOUT_SESSION_EXPIRED, isSessionExpired);
    }
  },
  async [A_REFRESH]({ commit }) {
    await AUTH.refresh()
      .then((respone) => {
        let result = respone;
        commit(M_REFRESH, result);
      })
      .catch((error) => {
        let result = error;
        commit(M_REFRESH, result);
      });
  },
  [A_INITIAL_LOGIN_STATE]({ commit }) {
    commit(M_INITIAL_LOGIN_STATE);
  },
  [A_INITIAL_ME_STATE]({ commit }) {
    commit(M_INITIAL_ME_STATE);
  }
};

// Mutations
const mutations = {
  [M_ME](state, result) {
    if (result.code === 0) {
      let data = result.data;
      state.me = {
        code: result.code,
        complete: true,
        data: {
          id: data.id,
          username: data.username,
          name: data.name,
          email: data.email,
          emailVerifiedAt: data.email_verified_at,
          createdAt: data.created_at,
          updatedAt: data.updated_at
        },
        message: null
      };
    } else {
      state.me = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_LOGIN](state, result) {
    if (result.code === 0) {
      let data = result.data;

      state.loginResponse = {
        code: result.code,
        complete: true,
        message: i18nHelper.getMessage('message.loginSuccessfully'),
        data: {
          accessToken: data.access_token,
          tokenType: data.token_type,
          expiryIn: data.expires_in
        }
      };

      jwtHelper.setToken(data.access_token);
      jwtHelper.setSessionExpirationTime(data.expires_in);
    } else {
      state.loginResponse = {
        code: result.code,
        complete: true,
        message: result.message
      };
    }
  },
  [M_LOGOUT](state, result) {
    if (result.code === 0) {
      state.logoutResponse = {
        code: result.code,
        complete: true,
        message: result.message
      };
    } else {
      state.logoutResponse = {
        code: result.code,
        complete: true,
        message: result.error
      };
    }
    jwtHelper.removeToken();
    jwtHelper.removeLastActivityTime();
    jwtHelper.removeSessionExpirationTime();
    permissionHelper.removeUserPermissions();
    uiHelper.removeLocalStorage(LOCAL.CONSTANTS);
    uiHelper.reloadPage();
  },
  [M_LOGOUT_SESSION_EXPIRED](isSessionExpired) {
    if (isSessionExpired) {
      jwtHelper.removeToken();
      jwtHelper.removeLastActivityTime();
      jwtHelper.removeSessionExpirationTime();
      permissionHelper.removeUserPermissions();
      uiHelper.removeLocalStorage(LOCAL.CONSTANTS);
      uiHelper.openPage('/', '_self');
    }
  },
  [M_REFRESH](state, result) {
    if (result.code === 0) {
      let data = result.data;
      state.loginResponse = {
        code: result.code,
        complete: true,
        message: null,
        data: {
          accessToken: data.access_token,
          tokenType: data.token_type,
          expiryIn: data.expires_in
        }
      };

      jwtHelper.setToken(data.access_token);
      jwtHelper.setSessionExpirationTime(data.expires_in);
    } else {
      state.loginResponse = {
        code: result.code,
        complete: true,
        message: result.error
      };
    }
  },
  [M_INITIAL_LOGIN_STATE](state) {
    state.loginResponse = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_ME_STATE](state) {
    state.me = {
      code: 0,
      complete: false,
      data: {
        ...state.me.data
      },
      message: null
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
