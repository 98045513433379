<template>
  <div>
    <el-select
      :id="'deliveryOptions' + item.ids"
      v-model="deliveryOptions"
      clearable
      :placeholder="
        item.deliveryDiscount == '-'
          ? 'Select Delivery Method'
          : item.deliveryDiscount
      "
      @change="$emit('get-Delivery-Options', deliveryOptions)"
    >
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.text"
        :value="item.value"
      >
      </el-option>
    </el-select>
  </div>
</template>

<script>
  export default {
    name: 'AppDropdown',
    props: {
      item: {
        type: Object,
        default: () => {}
      },
      options: {
        type: Array,
        default: () => []
      }
    },
    data: () => ({
      deliveryOptions: null
    })
  };
</script>

<style>
  /* .el-select .el-input__inner {
    border: rgb(0, 0, 0, 0.2) solid 1px;
    font-size: 14px;
    text-align: left;
    padding-left: 12px !important;
  }

  .el-select input::placeholder {
    color: rgb(0, 0, 0, 0.6);
    font-size: 14px;
  } */
</style>
