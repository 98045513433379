import { apiService } from '@/core/services';

export const OFFLINE_MERCHANT = {
  getOfflineMerchantsList,
  getOfflineMerchantDetails,
  getOfflineMerchantBranchDetails,
  getOfflineMerchantVouchers,
  createOfflineMerchant,
  createOfflineMerchantBranch,
  updateOfflineMerchant,
  updateOfflineMerchantBranch,
  updateOfflineMerchantActivation,
  updateOfflineMerchantBranchActivation,
  deleteOfflineMerchantBranch
};

const URL = {
  ADMIN: 'admin',
  MERCHANTS: 'merchants',
  BRANCHES: 'branches',
  UPDATE_STATUS: 'update-status',
  VOUCHERS: 'vouchers'
};

function getOfflineMerchantsList(data) {
  let requestOptions = {
    params: {
      business_name: data.businessName,
      id: data.id,
      status: data.status,
      per_page: data.perPage,
      page: data.page
    }
  };
  return apiService().get(`/${URL.ADMIN}/${URL.MERCHANTS}`, requestOptions);
}

function getOfflineMerchantDetails(id) {
  return apiService().get(`/${URL.ADMIN}/${URL.MERCHANTS}/${id}`);
}

function getOfflineMerchantBranchDetails(id) {
  return apiService().get(
    `/${URL.ADMIN}/${URL.MERCHANTS}/${id}/${URL.BRANCHES}`
  );
}

function getOfflineMerchantVouchers(id) {
  return apiService().get(
    `/${URL.ADMIN}/${URL.MERCHANTS}/${id}/${URL.VOUCHERS}`
  );
}

function createOfflineMerchant(formData) {
  return apiService().post(`/${URL.ADMIN}/${URL.MERCHANTS}`, formData);
}

function createOfflineMerchantBranch(id, data) {
  let additionalContact = [];

  // if (data.contactNumber2 || data.contactNumber3) {
  if (data.contactNumber2) {
    additionalContact.push({
      contact_name: data.contactPerson2,
      contact_number: data.contactNumber2
    });
  }
  if (data.contactNumber3) {
    additionalContact.push({
      contact_name: data.contactPerson3,
      contact_number: data.contactNumber3
    });
  }
  // }
  let requestBody = {
    register_company_name: data.registerCompanyName,
    business_number: data.businessNumber,
    place_name: data.addressName,
    street_address: data.streetAddress,
    address_details: data.addressDetails,
    postcode: 10000,
    city: 'test',
    state: 'test',
    hide_address: data.hideBusinessAddressInProfilePage,
    contact_name: data.contactPerson1,
    contact_number: data.contactNumber1 ?? '',
    additional_contact: additionalContact,
    email: data.email,
    pin_verification: data.pinVerification
  };
  return apiService().post(
    `/${URL.ADMIN}/${URL.MERCHANTS}/${id}/${URL.BRANCHES}`,
    requestBody
  );
}

function updateOfflineMerchant(id, formData) {
  return apiService().post(`/${URL.ADMIN}/${URL.MERCHANTS}/${id}/`, formData);
}

function updateOfflineMerchantBranch(id, data, branchId) {
  let additionalContact = [];

  // if (data.contactNumber2 != null || data.contactNumber3 != null) {
  if (data.contactNumber2) {
    additionalContact.push({
      contact_name: data.contactPerson2,
      contact_number: data.contactNumber2
    });
  }
  if (data.contactNumber3) {
    additionalContact.push({
      contact_name: data.contactPerson3,
      contact_number: data.contactNumber3
    });
    // }
  }
  let requestBody = {
    register_company_name: data.registerCompanyName,
    business_number: data.businessNumber,
    place_name: data.addressName,
    street_address: data.streetAddress,
    address_details: data.addressDetails,
    hide_address: data.hideBusinessAddressInProfilePage,
    contact_name: data.contactPerson1,
    contact_number: data.contactNumber1 ?? '',
    additional_contact: additionalContact,
    email: data.email,
    pin_verification: data.pinVerification
      ? data.pinVerification.toUpperCase()
      : ''
  };
  return apiService().put(
    `/${URL.ADMIN}/${URL.MERCHANTS}/${id}/${URL.BRANCHES}/${branchId}`,
    requestBody
  );
}

function updateOfflineMerchantActivation(id, data) {
  let requestBody = {
    status: data
  };
  return apiService().patch(
    `/${URL.ADMIN}/${URL.MERCHANTS}/${id}/${URL.UPDATE_STATUS}`,
    requestBody
  );
}

function updateOfflineMerchantBranchActivation(id, data, branchId) {
  let requestBody = {
    status: data
  };
  return apiService().patch(
    `/${URL.ADMIN}/${URL.MERCHANTS}/${id}/${URL.BRANCHES}/${branchId}/${URL.UPDATE_STATUS}`,
    requestBody
  );
}

function deleteOfflineMerchantBranch(id, branchId) {
  return apiService().delete(
    `/${URL.ADMIN}/${URL.MERCHANTS}/${id}/${URL.BRANCHES}/${branchId}`
  );
}
