<template>
  <ReferralCodeDetailsTemplate :isCreate="true"></ReferralCodeDetailsTemplate>
</template>

<script>
  import ReferralCodeDetailsTemplate from '@/views/components/referral-code/ReferralCodeDetailsTemplate.vue';

  export default {
    name: 'ReferralCodeCreate',
    components: {
      ReferralCodeDetailsTemplate
    }
  };
</script>

<style></style>
