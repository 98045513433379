<template>
  <label
    class="label label-md label-inline"
    :class="`label-light-${RecipeStatusColor(value)}`"
  >
    {{ $t(`enumRecipeStatus.${value}`) }}
  </label>
</template>

<script>
  export default {
    name: 'RecipeStatusLabel',
    props: ['value'],
    methods: {
      RecipeStatusColor(value) {
        let color;
        value == null
          ? (color = '')
          : value == 1 || value == 4
          ? (color = 'primary')
          : value == 2 || value == 5
          ? (color = 'success')
          : (color = 'danger');

        return color;
      }
    }
  };
</script>

<style></style>
