<template>
  <label
    class="label label-md label-inline"
    :class="`label-light-${StudioBookingStatusColor(value)}`"
    :style="styleName"
  >
    {{ $t(`enumStudioBookingStatus.${value}`) }}
  </label>
</template>

<script>
  import { StudioBookingStatus } from '@/core/constants/enums';

  export default {
    name: 'StudioBookingStatusLabel',
    props: {
      value: {
        type: Number,
        default: StudioBookingStatus.PENDING
      },
      styleName: {
        type: String,
        default: ''
      }
    },
    methods: {
      StudioBookingStatusColor(value) {
        let color = {
          [StudioBookingStatus.PENDING]: 'primary',
          [StudioBookingStatus.CONFIRMED]: 'success',
          [StudioBookingStatus.CANCELLED]: 'danger'
        };

        return color[value];
      }
    }
  };
</script>

<style></style>
