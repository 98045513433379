import { apiService } from '@/core/services';
import { apiHelper } from '@/core/utils';

export const XSTREET = {
  getXStreetList,
  getXStreetPost,
  setVisibility
};

const URL = {
  ADMIN: 'admin',
  XSTREET: 'xstreet',
  SET_VISIBLE: 'set-visible'
};

function getXStreetList(data) {
  let requestOptions = {
    params: {
      title: data.title,
      username: data.username,
      is_visible: data.isVisible,
      created_at_from: apiHelper.dateTimeStart(data.dateRange),
      created_at_to: apiHelper.dateTimeEnd(data.dateRange),
      per_page: data.perPage,
      page: data.page
    }
  };
  return apiService().get(`/${URL.ADMIN}/${URL.XSTREET}`, requestOptions);
}

function getXStreetPost(id) {
  return apiService().get(`/${URL.ADMIN}/${URL.XSTREET}/${id}`);
}

function setVisibility(id, data) {
  let requestBody = {
    is_visible: data.isVisible
  };

  return apiService().put(
    `/${URL.ADMIN}/${URL.XSTREET}/${id}/${URL.SET_VISIBLE}`,
    requestBody
  );
}
