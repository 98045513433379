// import dateTimeFormat from '@/core/filters/dateTimeFormat.filter';
import dateFormat from '@/core/filters/dateFormat.filter';
import constantsDescription from '@/core/filters/constants-description.filter';
import { POPULAR_SEARCH } from '@/core/api';
import { apiHelper } from '@/core/utils';
import {
  LOADING_OPEN_LOADING,
  LOADING_CLOSE_LOADING
} from '@/core/store/loading.module';

/* Define Module Name */
const MODULE_NAME = 'popularSearch/';

/* Define Action Name */
const A_GET_POPULAR_SEARCHES = 'getPopularSearches';
const A_GET_POPULAR_SEARCH = 'getPopularSearch';
const A_CREATE_POPULAR_SEARCH = 'createPopularSearch';
const A_UPDATE_POPULAR_SEARCH = 'updatePopularSearch';
const A_DELETE_POPULAR_SEARCH = 'deletePopularSearch';

/* Define Reset State Action Name */
const A_INITIAL_GET_POPULAR_SEARCHES_STATE = 'initialGetPopularSearchesState';
const A_INITIAL_GET_POPULAR_SEARCH_STATE = 'initialGetPopularSearchState';
const A_INITIAL_CREATE_POPULAR_SEARCH_STATE = 'initialCreatePopularSearchState';
const A_INITIAL_UPDATE_POPULAR_SEARCH_STATE = 'initialUpdatePopularSearchState';
const A_INITIAL_DELETE_POPULAR_SEARCH_STATE = 'initialDeletePopularSearchState';

/* Define Getter Name */

/* Define Mutation Name */
const M_GET_POPULAR_SEARCHES = 'setGetPopularSearches';
const M_GET_POPULAR_SEARCH = 'setGetPopularSearch';
const M_CREATE_POPULAR_SEARCH = 'setCreatePopularSearch';
const M_UPDATE_POPULAR_SEARCH = 'setUpdatePopularSearch';
const M_DELETE_POPULAR_SEARCH = 'setDeletePopularSearch';

/* Define Reset State Mutation Name */
const M_INITIAL_GET_POPULAR_SEARCHES_STATE =
  'setInitialGetPopularSearchesState';
const M_INITIAL_GET_POPULAR_SEARCH_STATE = 'setInitialGetPopularSearchState';
const M_INITIAL_CREATE_POPULAR_SEARCH_STATE =
  'setInitialCreatePopularSearchState';
const M_INITIAL_UPDATE_POPULAR_SEARCH_STATE =
  'setInitialUpdatePopularSearchState';
const M_INITIAL_DELETE_POPULAR_SEARCH_STATE =
  'setInitialDeletePopularSearchState';

/* Define Export Name */
export const POPULAR_SEARCH_GET_POPULAR_SEARCHES =
  MODULE_NAME + A_GET_POPULAR_SEARCHES;
export const POPULAR_SEARCH_GET_POPULAR_SEARCH =
  MODULE_NAME + A_GET_POPULAR_SEARCH;
export const POPULAR_SEARCH_CREATE_POPULAR_SEARCH =
  MODULE_NAME + A_CREATE_POPULAR_SEARCH;
export const POPULAR_SEARCH_UPDATE_POPULAR_SEARCH =
  MODULE_NAME + A_UPDATE_POPULAR_SEARCH;
export const POPULAR_SEARCH_DELETE_POPULAR_SEARCH =
  MODULE_NAME + A_DELETE_POPULAR_SEARCH;

/* Define Reset State Name */
export const POPULAR_SEARCH_INITIAL_GET_POPULAR_SEARCHES_STATE =
  MODULE_NAME + A_INITIAL_GET_POPULAR_SEARCHES_STATE;
export const POPULAR_SEARCH_INITIAL_GET_POPULAR_SEARCH_STATE =
  MODULE_NAME + A_INITIAL_GET_POPULAR_SEARCH_STATE;
export const POPULAR_SEARCH_INITIAL_CREATE_POPULAR_SEARCH_STATE =
  MODULE_NAME + A_INITIAL_CREATE_POPULAR_SEARCH_STATE;
export const POPULAR_SEARCH_INITIAL_UPDATE_POPULAR_SEARCH_STATE =
  MODULE_NAME + A_INITIAL_UPDATE_POPULAR_SEARCH_STATE;
export const POPULAR_SEARCH_INITIAL_DELETE_POPULAR_SEARCH_STATE =
  MODULE_NAME + A_INITIAL_DELETE_POPULAR_SEARCH_STATE;

const state = {
  popularSearches: {
    code: 0,
    complete: false,
    data: null,
    message: null,
    pagination: {
      currentPage: 0,
      total: 0,
      perPage: 0,
      lastPage: 0
    }
  },
  popularSearch: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  createPopularSearch: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  updatePopularSearch: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  deletePopularSearch: {
    code: 0,
    complete: false,
    data: null,
    message: null
  }
};

//Getters
const getters = {};

// Actions
const actions = {
  [A_GET_POPULAR_SEARCHES]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    POPULAR_SEARCH.getPopularSearches(data)
      .then((response) => {
        result = response;
        commit(M_GET_POPULAR_SEARCHES, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_POPULAR_SEARCHES, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_POPULAR_SEARCH]({ dispatch, commit }, { id }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    POPULAR_SEARCH.getPopularSearch(id)
      .then((response) => {
        result = response;
        commit(M_GET_POPULAR_SEARCH, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_POPULAR_SEARCH, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_CREATE_POPULAR_SEARCH]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    POPULAR_SEARCH.createPopularSearch(data)
      .then((response) => {
        result = response;
        commit(M_CREATE_POPULAR_SEARCH, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_CREATE_POPULAR_SEARCH, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_UPDATE_POPULAR_SEARCH]({ dispatch, commit }, { id, data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    POPULAR_SEARCH.updatePopularSearch(id, data)
      .then((response) => {
        result = response;
        commit(M_UPDATE_POPULAR_SEARCH, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_UPDATE_POPULAR_SEARCH, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_DELETE_POPULAR_SEARCH]({ dispatch, commit }, { id }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    POPULAR_SEARCH.deletePopularSearch(id)
      .then((response) => {
        result = response;
        commit(M_DELETE_POPULAR_SEARCH, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_DELETE_POPULAR_SEARCH, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_INITIAL_GET_POPULAR_SEARCHES_STATE]({ commit }) {
    commit(M_INITIAL_GET_POPULAR_SEARCHES_STATE);
  },
  [A_INITIAL_GET_POPULAR_SEARCH_STATE]({ commit }) {
    commit(M_INITIAL_GET_POPULAR_SEARCH_STATE);
  },
  [A_INITIAL_CREATE_POPULAR_SEARCH_STATE]({ commit }) {
    commit(M_INITIAL_CREATE_POPULAR_SEARCH_STATE);
  },
  [A_INITIAL_UPDATE_POPULAR_SEARCH_STATE]({ commit }) {
    commit(M_INITIAL_UPDATE_POPULAR_SEARCH_STATE);
  },
  [A_INITIAL_DELETE_POPULAR_SEARCH_STATE]({ commit }) {
    commit(M_INITIAL_DELETE_POPULAR_SEARCH_STATE);
  }
};

// Mutations
const mutations = {
  [M_GET_POPULAR_SEARCHES](state, result) {
    if (result.code === 0) {
      let d = result.data;
      let p = result.pagination;
      state.popularSearches = {
        code: result.code,
        complete: true,
        data: d.map((x, index) => {
          return {
            no: index + 1,
            id: x.id,
            merchantName: x.name,
            merchantType: x.merchant_type,
            searchCategory: constantsDescription('merchantCategory', x.category)
          };
        }),
        message: null,
        pagination: {
          currentPage: p.current_page,
          total: p.total,
          perPage: p.per_page,
          lastPage: p.last_page
        }
      };
    } else {
      state.popularSearches = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message),
        pagination: {
          currentPage: 0,
          total: 0,
          perPage: 0,
          lastPage: 0
        }
      };
    }
  },
  [M_GET_POPULAR_SEARCH](state, result) {
    if (result.code === 0) {
      let d = result.data;

      state.popularSearch = {
        code: result.code,
        complete: true,
        data: {
          id: d.id,
          category: d.category,
          merchantType: d.merchant_type,
          merchantName: d.name,
          type: d.type,
          createdAt: dateFormat(d.created_at),
          updatedAt: dateFormat(d.updated_at)
        },
        message: null
      };
    } else {
      state.popularSearch = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_CREATE_POPULAR_SEARCH](state, result) {
    state.createPopularSearch = {
      code: result.code,
      complete: true,
      data: result.data,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_UPDATE_POPULAR_SEARCH](state, result) {
    state.updatePopularSearch = {
      code: result.code,
      complete: true,
      data: result,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_DELETE_POPULAR_SEARCH](state, result) {
    state.deletePopularSearch = {
      code: result.code,
      complete: true,
      data: result,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_INITIAL_GET_POPULAR_SEARCHES_STATE](state) {
    state.popularSearches = {
      code: 0,
      complete: false,
      data: null,
      message: null,
      pagination: {
        currentPage: 0,
        total: 0,
        perPage: 0,
        lastPage: 0
      }
    };
  },
  [M_INITIAL_GET_POPULAR_SEARCH_STATE](state) {
    state.popularSearch = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_CREATE_POPULAR_SEARCH_STATE](state) {
    state.createPopularSearch = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_UPDATE_POPULAR_SEARCH_STATE](state) {
    state.updatePopularSearch = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_DELETE_POPULAR_SEARCH_STATE](state) {
    state.deletePopularSearch = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
