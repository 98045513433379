import { CURRENCY } from '@/core/constants';
import { i18nHelper } from '@/core/utils';

/**
 * Return value with currency and number format
 * @param {number} value The number to format.
 * @return {String}
 */
export default function currencyFormat(value) {
  let USLocale = new Intl.NumberFormat('en-US');
  let newValue = null;
  let splitValueByDecimal = String(value).split('.');
  let newDecimalValue = '00';

  if (value) {
    if (splitValueByDecimal.length > 1) {
      if (splitValueByDecimal[1].length < 2) {
        /**
         * Fix initial decimal value to two digit decimal
         * when the value is coming with one digit decimal
         */
        newDecimalValue = `${splitValueByDecimal[1]}0`;
      } else if (splitValueByDecimal[1].length > 2) {
        /* Trim value to two digit decimal when the value decimal */
        newDecimalValue = splitValueByDecimal[1].substr(0, 2);
      } else {
        /**
         * Assign value to newDecimalValue
         * if decimal value is coming with two digit decimal
         */
        newDecimalValue = splitValueByDecimal[1];
      }
    }
    /**
     * Assign all the display element into newValue
     * Eg: newValue = RM 9,999.99
     */
    newValue = `${i18nHelper.getMessage(
      `currency.${CURRENCY.MYR}`
    )} ${USLocale.format(splitValueByDecimal[0])}.${newDecimalValue}`;
  } else {
    /* If value is empty show symbol "-"" */
    newValue = '-';
  }

  return newValue;
}
