import { LOOKUP } from '@/core/api';
import { apiHelper, permissionHelper, i18nHelper } from '@/core/utils';
import {
  LOADING_OPEN_LOADING,
  LOADING_CLOSE_LOADING
} from '@/core/store/loading.module';

/* Define Module Name */
const MODULE_NAME = 'lookup/';

/* Define Action Name */
const A_GET_USER_PERMISSIONS = 'getUserPermissions';
const A_GET_APP_USER_RECIPES = 'getAppUserRecipes';
const A_GET_APP_USER_RECIPE = 'getAppUserRecipe';
const A_GET_NOTIFICATION = 'getNotification';

/* Define Reset State Action Name */
const A_INITIAL_USER_PERMISSIONS_STATE = 'initialUserPermissionsState';
const A_INITIAL_GET_APP_USER_RECIPES = 'initialGetAppUserRecipes';
const A_INITIAL_GET_APP_USER_RECIPE = 'initialGetAppUserRecipe';
const A_INITIAL_NOTIFICATION = 'initialNotification';

/* Define Getter Name */

/* Define Mutation Name */
const M_GET_USER_PERMISSIONS = 'setUserPermissions';
const M_GET_APP_USER_RECIPES = 'setGetAppUserRecipes';
const M_GET_APP_USER_RECIPE = 'setGetAppUserRecipe';
const M_NOTIFICATION = 'setNotification';

/* Define Reset State Mutation Name */
const M_INITIAL_USER_PERMISSIONS_STATE = 'setInitialUserPermissionsState';
const M_INITIAL_GET_APP_USER_RECIPES = 'setInitialGetAppUserRecipes';
const M_INITIAL_GET_APP_USER_RECIPE = 'setInitialGetAppUserRecipe';
const M_INITIAL_NOTIFICATION = 'setInitialNotification';

/* Define Export Name */
export const LOOKUP_GET_USER_PERMISSIONS = MODULE_NAME + A_GET_USER_PERMISSIONS;
export const LOOKUP_GET_APP_USER_RECIPES = MODULE_NAME + A_GET_APP_USER_RECIPES;
export const LOOKUP_GET_APP_USER_RECIPE = MODULE_NAME + A_GET_APP_USER_RECIPE;
export const LOOKUP_GET_NOTIFICATION = MODULE_NAME + A_GET_NOTIFICATION;

/* Define Export Reset State Name */
export const LOOKUP_INITIAL_USER_PERMISSIONS_STATE =
  MODULE_NAME + A_INITIAL_USER_PERMISSIONS_STATE;
export const LOOKUP_INITIAL_GET_APP_USER_RECIPES =
  MODULE_NAME + A_INITIAL_GET_APP_USER_RECIPES;
export const LOOKUP_INITIAL_GET_APP_USER_RECIPE =
  MODULE_NAME + A_INITIAL_GET_APP_USER_RECIPE;
export const LOOKUP_INITIAL_NOTIFICATION = MODULE_NAME + A_INITIAL_NOTIFICATION;

/* Define Export Getter Name */

const state = {
  userPermissions: {
    code: 0,
    complete: false,
    data: [],
    message: null
  },
  appUserRecipes: {
    code: 0,
    complete: false,
    data: [],
    message: null
  },
  appUserRecipe: {
    code: 0,
    complete: false,
    data: [],
    message: null
  },
  notification: {
    code: 0,
    complete: false,
    data: {
      application: 0,
      recipes: 0,
      cancelledOrderDeliveries: 0
    },
    message: null
  }
};

//Getters
const getters = {};

// Actions
const actions = {
  async [A_GET_USER_PERMISSIONS]({ dispatch, commit }) {
    await dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    LOOKUP.getUserPermissions()
      .then((respone) => {
        let result = respone;
        commit(M_GET_USER_PERMISSIONS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_GET_USER_PERMISSIONS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  async [A_GET_APP_USER_RECIPES]({ dispatch, commit }, { data }) {
    let result;
    await dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    LOOKUP.getAppUserRecipes(data)
      .then((response) => {
        result = response;
        commit(M_GET_APP_USER_RECIPES, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_APP_USER_RECIPES, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  async [A_GET_APP_USER_RECIPE]({ dispatch, commit }, { data }) {
    let result;
    await dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    LOOKUP.getAppUserRecipes(data)
      .then((response) => {
        result = response;
        commit(M_GET_APP_USER_RECIPE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_APP_USER_RECIPE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_NOTIFICATION]({ commit }) {
    let result;
    LOOKUP.getNotification()
      .then((response) => {
        result = response;
        commit(M_NOTIFICATION, result);
      })
      .catch((error) => {
        result = error;
        commit(M_NOTIFICATION, result);
      });
  },
  [A_INITIAL_USER_PERMISSIONS_STATE]({ commit }) {
    commit(M_INITIAL_USER_PERMISSIONS_STATE);
  },
  [A_INITIAL_GET_APP_USER_RECIPES]({ commit }) {
    commit(M_INITIAL_GET_APP_USER_RECIPES);
  },
  [A_INITIAL_GET_APP_USER_RECIPE]({ commit }) {
    commit(M_INITIAL_GET_APP_USER_RECIPE);
  },
  [A_INITIAL_NOTIFICATION]({ commit }) {
    commit(M_INITIAL_NOTIFICATION);
  }
};

// Mutations
const mutations = {
  [M_GET_USER_PERMISSIONS](state, result) {
    if (result.code == 0) {
      result.data = [
        ...result.data
        // For test new permission, if Backend not update permission on API
        // 'test-permissions.read',
        // 'test-permissions.create',
        // 'test-permissions.update',
        // 'test-permissions.delete'
      ];
      state.userPermissions = {
        code: result.code,
        complete: true,
        data: result.data
      };

      permissionHelper.setUserPermissions(result.data);
    } else {
      state.userPermissions = {
        code: result.code,
        complete: true,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_GET_APP_USER_RECIPES](state, result) {
    function sortByName(a, b) {
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
    }

    if (result.code === 0) {
      let d = result.data;
      let recipes = [];

      d.forEach((x) => {
        recipes.push({
          id: x.id,
          name: x.name,
          username: x.username,
          displayName: x.name + ' - ' + x.username
        });
      });

      state.appUserRecipes = {
        code: result.code,
        complete: true,
        data: recipes.sort(sortByName),
        message: null
      };
    } else {
      state.appUserRecipes = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_GET_APP_USER_RECIPE](state, result) {
    if (result.code === 0) {
      let d = result.data[0];
      let data;

      if (result.data.length > 0) {
        data = {
          id: d.id,
          name: d.name,
          username: d.username,
          displayName: d.name + ' - ' + d.username
        };
      } else {
        data = {
          id: '',
          name: '',
          username: '',
          displayName: i18nHelper.getMessage('label.invalidRecipe')
        };
      }

      state.appUserRecipe = {
        code: result.code,
        complete: true,
        data: data,
        message: null
      };
    } else {
      state.appUserRecipe = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_NOTIFICATION](state, result) {
    if (result.code === 0) {
      let d = result.data.badge_number;

      state.notification = {
        code: result.code,
        complete: true,
        data: {
          application: d.creator_application.pending_approve_count,
          ecoPackFulfil:
            d.ecopack_fulfil_by_cookx.pending_offer_acceptance_count,
          recipes: d.post.recipe.all.pending_verify_count,
          orders: {
            all: d.order.all.status_pending_count,
            ecoPack: d.order.ecopack_fulfil_by_cookx.status_pending_count,
            xChefs: d.order.xchefs.status_pending_count
          },
          ordersBadge: d.order.all.status_pending_count,
          ordersCancelled: d.order.all.status_cancel_count
        },
        message: null
      };
    } else {
      state.notification = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_INITIAL_USER_PERMISSIONS_STATE](state) {
    state.userPermissions = {
      code: 0,
      complete: false,
      data: [],
      message: null
    };
  },
  [M_INITIAL_GET_APP_USER_RECIPES](state) {
    state.appUserRecipes = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_GET_APP_USER_RECIPE](state) {
    state.appUserRecipe = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_NOTIFICATION](state) {
    state.notification = {
      code: 0,
      complete: false,
      data: {
        ...state.notification.data
      },
      message: null
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
