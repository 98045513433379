var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-button',{class:{
      'cursor-default':
        _vm.userObj.creatorApplicationStatus.value !=
          _vm.creatorApplicationStatus.PENDING ||
        !_vm.checkAccessRight(_vm.module.CREATOR_APPLICATION, [
          _vm.permissionAction.CREATOR_APPLICATION_READ
        ])
    },attrs:{"size":"sm","variant":`light-${_vm.creatorApplicationStatusColor(
      _vm.userObj.creatorApplicationStatus.value
    )}`,"disabled":_vm.userObj.creatorApplicationStatus.value !=
        _vm.creatorApplicationStatus.PENDING ||
      !_vm.checkAccessRight(_vm.module.CREATOR_APPLICATION, [
        _vm.permissionAction.CREATOR_APPLICATION_READ
      ])},on:{"click":function($event){return _vm.reviewApplicationManagement(_vm.userObj.id)}}},[_vm._v(_vm._s(_vm.userObj.creatorApplicationStatus.value == _vm.creatorApplicationStatus.PENDING && _vm.checkAccessRight(_vm.module.CREATOR_APPLICATION, [ _vm.permissionAction.CREATOR_APPLICATION_READ ]) ? _vm.$t('label.pendingApprovalClickToReviewApplication') : _vm.userObj.creatorApplicationStatus.name))])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }