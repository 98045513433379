<template>
  <div>
    <b-form-checkbox
      v-model="pic"
      size="lg"
      :disabled="disabled"
      @change="change"
    >
      {{ value || $t('label.checkToCheckIn') }}
    </b-form-checkbox>
  </div>
</template>

<script>
  import { DeliveryStatus, DeliveryMethod } from '@/core/constants/enums';
  import { MODULE, ACTION } from '@/core/constants';
  import commonMixin from '@/core/mixins/common.mixin';

  export default {
    name: 'OrderPersonInChargeCheckbox',
    mixins: [commonMixin],
    props: {
      value: {
        type: String,
        default: ''
      },
      parentObject: {
        type: Object,
        default: () => ({})
      }
    },
    data: () => ({
      pic: false
    }),
    watch: {
      value() {
        if (this.value) this.pic = true;
      }
    },
    computed: {
      meData() {
        return this.$store.state.auth.me.data;
      },
      disabled() {
        let status = this.parentObject.deliveryStatus;
        let method = this.parentObject.deliveryMethod;

        return !(
          (!this.value &&
            this.checkAccessRight(MODULE.ORDERS, [ACTION.ORDERS_UPDATE]) &&
            method == DeliveryMethod.PRE_ORDER &&
            status == DeliveryStatus.CANCELLED) ||
          (!this.value &&
            this.checkAccessRight(MODULE.ORDERS, [ACTION.ORDERS_UPDATE]) &&
            method == DeliveryMethod.DELIVERY_BY_CAR &&
            status == DeliveryStatus.CANCELLED) ||
          (!this.value &&
            this.checkAccessRight(MODULE.ORDERS, [ACTION.ORDERS_UPDATE]) &&
            status == DeliveryStatus.MANUAL)
        );
      }
    },
    mounted() {
      if (this.value) this.pic = true;
    },
    methods: {
      change(value) {
        let username = this.meData.id;

        if (value) this.$emit('add-persona-in-charge', username);
      }
    }
  };
</script>

<style></style>
