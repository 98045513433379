<template>
  <label
    class="label label-md label-inline"
    :class="`label-light-${OrderStatusColor(value)}`"
  >
    {{ $t(`enumOrderStatus.${value}`) }}
  </label>
</template>

<script>
  import { OrderStatus } from '@/core/constants/enums';

  export default {
    name: 'OrderStatusLabel',
    props: {
      value: {
        type: Number,
        default: OrderStatus.PENDING
      }
    },
    methods: {
      OrderStatusColor(value) {
        let color = {
          [OrderStatus.PENDING]: 'primary',
          [OrderStatus.CONFIRMED]: 'success',
          [OrderStatus.REJECTED]: 'danger',
          [OrderStatus.DELIVERED]: 'success',
          [OrderStatus.PICKED_UP]: 'warning',
          [OrderStatus.REFUNDED]: 'danger',
          [OrderStatus.CANCELLED]: 'danger',
          [OrderStatus.MANUAL]: 'info'
        };

        return color[value];
      }
    }
  };
</script>

<style></style>
