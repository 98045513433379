<template>
  <div>
    <b-row class="justify-content-between align-items-center pb-3" no-gutters>
      <div class="text-capitalize text-md-left">
        {{ $t(`label.variationGroup`) }}
      </div>
      <b-button variant="primary" size="sm" @click="addNewVariationGroup">
        <i class="fa-solid fa-plus"></i>
        {{ $t('label.addVariation') }}</b-button>
    </b-row>
    <b-tabs v-model="variationTab" fills>
      <b-tab v-for="(item, index) in variationObj" :key="index">
        <template slot="title">
          <span :class="[
            {
              'd-block invalid-feedback': !validationCheckEach(
                $v.variationObj.$each[index]
              )
            }
          ]">
            {{ [null, ''].includes(item.name) ? 'New Group' : item.name }}
          </span>
          <i class="fa-solid fa-xmark pl-3" @click="actionDeleteEcoPackVariations(index)"></i>
        </template>

        <!-- Variation Group Name ::Start -->
        <b-row class="align-items-center pt-4" no-gutters>
          <b-row v-for="i in formFields.filter((x) => x.section == 0)" :key="i.length"
            class="align-items-center col-sm-10 col-12" no-gutters>
            <div class="text-capitalize text-md-left col-sm-4 col-12">
              {{ $t(`label.${i.name}`) }}
              <span v-if="i.isRequires" class="text-danger">*</span>
            </div>
            <div class="col-sm-8 col-12">
              <AppFormField v-model="$v.variationObj.$each[index][i.model].$model"
                :validationValue="$v.variationObj.$each[index][i.model]" :validators="i.validators"
                :state="validateObjState('variationObj', i.model, true, index)" solid noTitle horizontal>
              </AppFormField>
            </div>
          </b-row>
          <b-row class="justify-content-center col-sm-2 col-12" no-gutters>
            <i v-if="item.isActive" @click="
              item.isActive = false;
            clickVariationObjActive(item);
            " class="fas fa-check-circle text-success icon-lg"></i>
            <i v-else @click="
              item.isActive = true;
            clickVariationObjActive(item);
            " class="far fa-circle icon-lg"></i>
          </b-row>
        </b-row>

        <!-- Variation Group  ::End -->

        <!-- Add Item Option ::Start -->
        <div class="pt-6">
          <b-row class="justify-content-between align-items-center" no-gutters>
            <div class="text-capitalize text-md-left">
              {{ $t(`label.addItemOption`) }}
            </div>
            <b-button class="" variant="primary" size="sm" @click="addNewVariationOption(item)">
              <i class="fa-solid fa-plus"></i>
              {{ $t('label.addOption') }}</b-button>
          </b-row>
          <hr width="100%" class="mt-2 mb-6" />
        </div>
        <b-row v-for="(y, items) in item.variationOptions" :key="items" class="align-items-center pb-5" no-gutters>
          <div class="col-sm-10 col-12">
            <b-row v-for="(i, x) in formFields.filter((x) => x.section == 1)" :key="x" class="pb-5 align-items-center"
              no-gutters>
              <div for="title" class="text-capitalize text-md-left col-sm-4 col-12">
                <span>
                  {{ `${$t(`label.${i.name}`)} ${items + 1}` }}
                  <span v-if="i.isRequires" class="text-danger">*</span>
                </span>
              </div>
              <div class="col-sm-8 col-12">
                <AppFormField v-model="$v.variationObj.$each[index].variationOptions.$each[items][
                  i.model
                ].$model
                  " :validationValue="$v.variationObj.$each[index].variationOptions.$each[items][
    i.model
  ]
    " :state="validateObjEachState(
    $v.variationObj.$each[index].variationOptions.$each[
    items
    ][i.model]
  )
    " :title="$t(`label.${i.name}`)" :type="i.type" :validators="i.validators" :validationParams="i.validationParams"
                  :prepend="i.prepend" solid noTitle>
                  <template v-slot:prepend>
                    <span class="input-group-text text-uppercase">{{
                      $t(`currency.${currency.MYR}`)
                    }}</span>
                  </template>
                </AppFormField>
              </div>
            </b-row>
          </div>
          <b-row class="justify-content-around col-sm-2 col-12 mb-2" no-gutters>
            <div>
              <i v-if="y.isActive" @click="
                y.isActive = false;
              clickVariationOptionsActive(item);
              " class="fas fa-check-circle text-success icon-lg"></i>
              <i v-else @click="
                y.isActive = true;
              clickVariationOptionsActive(item);
              " class="far fa-circle icon-lg"></i>
            </div>
            <div v-if="item.variationOptions.filter((x) => x.isActive === true)
              .length > 1
              ">
              <i class="far fa-trash-alt icon-lg" @click="
                removeVariationOption(y, index);
              clickVariationOptionsActive(item);
              "></i>
            </div>
          </b-row>
        </b-row>
        <!-- Add Item Option ::End -->

        <!-- Requirement ::Start -->
        <div class="">
          <b-row class="justify-content-between align-items-center" no-gutters>
            <div class="text-capitalize text-md-left">
              {{ $t(`label.requirement`) }}
            </div>
          </b-row>
          <hr width="100%" class="mt-2 mb-6" />
          <b-row v-for="(i, x) in formFields.filter((x) => x.section == 2)" :key="x" class="align-items-center p-0"
            no-gutters>
            <b-row class="col-sm-10 col-12" no-gutters>
              <div class="text-capitalize text-md-left col-sm-4 col-12">
                <span>
                  {{ `${$t(`label.${i.name}`)} ` }}
                </span>
              </div>
              <div class="col-sm-8 col-12">
                <AppFormField v-model="$v.variationObj.$each[index].variationRequirement[i.model]
                  .$model
                  " :validationValue="$v.variationObj.$each[index].variationRequirement[i.model]
    " :state="validateObjEachState(
    $v.variationObj.$each[index].variationRequirement[i.model]
  )
    " :formType="i.formType" :type="i.type" :validators="i.validators" :validationParams="[
    {
      key: 'maxValue',
      params: [
        item.variationOptions.filter((x) => x.isActive === true)
          .length
      ]
    }
  ]" :min="i.max" :max="item.variationOptions.filter((x) => x.isActive === true)
  .length
  " :disabled="!variationObj[index].variationRequirement.isActive" solid noTitle>
                </AppFormField>
              </div>
            </b-row>
            <div class="col-sm-2 col-12 text-center">
              <i v-if="variationObj[index].variationRequirement.isActive" @click="
                variationObj[index].variationRequirement.isActive = false;
              clickVariationRequirementActive(variationObj[index]);
              " class="fas fa-check-circle text-success icon-lg"></i>
              <i v-else @click="
                variationObj[index].variationRequirement.isActive = true;
              clickVariationRequirementActive(variationObj[index]);
              " class="far fa-circle icon-lg"></i>
            </div>
          </b-row>
        </div>
        <!-- Requirement ::End -->
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import AppFormField from '@/views/components/bases/form-field/AppFormField';
import commonMixin from '@/core/mixins/common.mixin';
import { validationMixin } from 'vuelidate';
import { RecipeEcoPackStatus } from '@/core/constants/enums';
import { required } from 'vuelidate/lib/validators';
import { MODULE, ACTION, price, CURRENCY } from '@/core/constants';
import { i18nHelper } from '@/core/utils';
import {
  RECIPE_GET_RECIPE,
  RECIPE_UPDATE_ECO_PACK_VARIATIONS,
  RECIPE_INITIAL_UPDATE_ECO_PACK_VARIATIONS_STATE
} from '@/core/store/recipe.module';

export default {
  name: 'RecipeEcoPackVariations',
  mixins: [commonMixin, validationMixin],
  props: {
    recipeObj: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    AppFormField
  },
  data: () => ({
    moduleName: MODULE.RECIPE,
    modal: false,
    action: ACTION,
    currency: CURRENCY,
    recipeEcoPackStatus: RecipeEcoPackStatus,
    variationTab: 0,
    formFields: [],
    variationObj: {
      name: null,
      variationOptions: [
        {
          name: null,
          price: null
        }
      ],
      noOfRequirement: 0
    }
  }),
  mounted() {


  },
  created() {
    this.getVariationsObj();
  },
  computed: {
    updateEcoPackVariationsComplete() {
      return this.$store.state.recipe.updateEcoPackVariations.complete;
    }
  },
  watch: {
    updateEcoPackVariationsComplete() {
      let response = this.$store.state.recipe.updateEcoPackVariations;
      if (response.complete) {
        this.requestEcoPackCompleteAction(response, 'update');
      }
    }
  },
  methods: {
    clickVariationObjActive(item) {
      if (!item.isActive) {
        item.variationOptions.map((x) => (x.isActive = false));
        item.variationRequirement.isActive = false;
        item.variationRequirement.noOfRequirement = 0;
        item.variationRequirement.activeVariationOptions = 0;
      }
    },
    clickVariationOptionsActive(item) {
      if (
        item.variationRequirement.noOfRequirement >
        item.variationOptions.filter((x) => x.isActive === true).length
      ) {
        item.variationRequirement.noOfRequirement =
          item.variationOptions.filter((x) => x.isActive === true).length;
      }

      item.variationRequirement.activeVariationOptions =
        item.variationOptions.filter((x) => x.isActive === true).length || 0;
    },
    clickVariationRequirementActive(item) {
      if (!item.variationRequirement.isActive) {
        item.variationRequirement.noOfRequirement = 0;
      }
    },
    removeVariationOption(val, index) {
      if (val.uuid != '') {
        this.variationObj[index].variationOptions = this.variationObj[
          index
        ].variationOptions.filter((option) => option.uuid !== val.uuid);
      } else {
        this.variationObj[index].variationOptions = this.variationObj[
          index
        ].variationOptions.filter((option) => option.name !== val.name);
      }
    },
    async addNewVariationGroup() {
      if (!this.variationObj) {
        this.variationObj = [];
      }
      await this.variationObj.push({
        name: null,
        isActive: true,
        variationRequirement: {
          activeVariationOptions: 0,
          noOfRequirement: 0,
          isActive: true
        },
        variationOptions: [
          {
            name: null,
            price: null,
            isActive: true,
            uuid: ''
          }
        ]
      });
    },
    addNewVariationOption(item) {
      console.log(item);
      if (item.variationRequirement.activeVariationOptions == null) {
        item.variationRequirement.activeVariationOptions = 1;
      } else {
        item.variationRequirement.activeVariationOptions =
          item.variationRequirement.activeVariationOptions + 1;
      }
      console.log(item);
      item.variationOptions.push({
        name: null,
        price: null,
        isActive: true,
        uuid: ''
      });
    },
    getVariationsObj() {
      this.variationObj = this.recipeObj.variations;
    },
    requestEcoPackCompleteAction(response, actionType) {
      let title = i18nHelper.getMessage('label.ecoPackRequest');
      let messages = response.message;
      let buttons = [];
      switch (actionType) {
        case 'update':
          this.initialUpdateEcoPackVariationState();
          break;
      }

      if (response.code == 0) {
        switch (actionType) {
          case 'update':
            buttons.push({
              text: i18nHelper.getMessage('label.ok'),
              variant: 'primary',
              action: () => {
                this.closeLayoutAppModalInfo();
                this.$emit('actionDiscard', true);
                this.getRecipe(this.recipeObj.id);
              }
            });
            break;
          default:
            buttons.push({
              text: i18nHelper.getMessage('label.ok'),
              variant: 'primary',
              action: () => {
                this.closeLayoutAppModalInfo();
                this.$emit('actionDiscard', true);
                this.getRecipe(this.recipeObj.id);
              }
            });
        }
      } else {
        buttons.push({
          text: i18nHelper.getMessage('label.ok'),
          variant: 'primary',
          action: () => {
            this.closeLayoutAppModalInfo();
          }
        });
      }
      if (messages) this.openLayoutAppModalInfo(title, messages, buttons);
    },
    // initial Form Start
    getEcopackVariations() {
      this.initFormFields();
    },
    initFormFields() {
      this.formFields = [
        {
          section: 0,
          name: 'variationGroupName',
          model: 'name',
          validators: ['required'],
          isRequires: true
        },
        {
          section: 1,
          name: 'optionName',
          model: 'name',
          validators: ['required'],
          isRequires: true
        },
        {
          section: 1,
          name: 'additionalPrice',
          model: 'price',
          type: 'number',
          prepend: true,
          validators: ['required', 'price'],
          validationParams: [
            {
              key: 'between',
              params: [
                i18nHelper.getMessage(`currency.${CURRENCY.MYR}`),
                i18nHelper.getMessage(`currency.${CURRENCY.MYR}`)
              ],
              customizeMessage: 'invalidPriceRange'
            }
          ],
          isRequires: true
        },
        {
          section: 2,
          name: 'optionsMinimumSelect',
          model: 'noOfRequirement',
          formType: 'range',
          validators: ['required', 'maxValue'],
          min: 0
        }
      ];
    },
    // initial Form End
    // Submit Selling Ecopack And Return Value TO Parent Call API Start
    actionSubmitEcopackVariations() {
      if (
        this.validationCheck('variationObj')
        //  &&
        // !this.$refs.AppRange.map((x) => x.checkValidation()).includes(false)
      ) {
        this.updateEcoPackVariations(this.recipeObj.id, this.variationObj);
      }
    },
    actionDeactivateEcoPackVariations() {
      this.updateEcoPackVariations(this.recipeObj.id, this.variationObj);
    },
    actionDeleteEcoPackVariations(index) {
      this.variationObj.splice(index, 1);
    },
    // Discard And Reset Form Start
    resetForm() {
      this.$v.$reset();
      this.variationObj = {
        name: null,
        variationOptions: [
          {
            name: null,
            price: null
          }
        ],
        noOfRequirement: 0,
        activeVariationOptions: 0
      };
      this.getRecipe(this.recipeObj.id);
    },
    // Discard And Reset Form End
    // Call API Start
    getRecipe(id) {
      this.$store.dispatch(RECIPE_GET_RECIPE, {
        id
      });
    },
    updateEcoPackVariations(id, data) {
      this.$store.dispatch(RECIPE_UPDATE_ECO_PACK_VARIATIONS, { id, data });
    },
    initialUpdateEcoPackVariationState() {
      this.$store.dispatch(RECIPE_INITIAL_UPDATE_ECO_PACK_VARIATIONS_STATE);
    }
    // Call API End
  },
  validations() {
    return {
      variationObj: {
        $each: {
          name: {
            required
          },
          variationOptions: {
            $each: {
              name: {
                required
              },
              price: {
                required,
                price
              }
            }
          },
          variationRequirement: {
            noOfRequirement: {
              required,
              maxValue: (value, obj) => {
                return value <= obj.noOfRequirement;
              }
            }
          }
        }
      }
    };
  }
};
</script>

<style>
.form-group {
  margin-bottom: unset !important;
}
</style>
