import { apiService } from '@/core/services';
import { apiHelper } from '@/core/utils';

export const ORDERS = {
  getOrders,
  getOrder,
  updateOrderStatus,
  exportOrderExcel,
  updateOrder,
  rejectOrder,
  cancelOrder,
  confirmOrder
};

const URL = {
  ADMIN: 'admin',
  ORDERS: 'orders',
  XCHEFS_ORDER: 'xchefs-orders',
  UPDATE_DELIVERY_STATUS: 'update-delivery-status',
  EXPORT: 'export',
  EXCEL: 'excel',
  REJECT: 'reject ',
  CONFIRM: 'confirm',
  PANDING: 'pending',
  CANCEL: 'cancel'
};

function getOrders(data) {
  let requestOptions = {
    params: {
      order_identifier: data.orderIdentifier,
      transaction_identifier: data.transactionIdentifier,
      order_status: data.orderStatus,
      delivery_status: data.deliveryStatus,
      order_from: data.orderFrom,
      order_by: data.orderBy,
      created_at_from: apiHelper.dateTimeStart(data.orderRequestDateRange),
      created_at_to: apiHelper.dateTimeEnd(data.orderRequestDateRange),
      updated_at_from: apiHelper.dateTimeStart(data.orderLastUpdatedDateRange),
      updated_at_to: apiHelper.dateTimeEnd(data.orderLastUpdatedDateRange),
      total: data.total,
      delivery_order_id: data.deliveryOrderId,
      stripe_payment_intent_id: data.stripePaymentIntentId,
      scheduled_at_from: apiHelper.dateTimeStart(data.scheduledDateRange),
      scheduled_at_to: apiHelper.dateTimeEnd(data.scheduledDateRange),
      per_page: data.perPage,
      page: data.page,
      is_cookx_eco_pack: data.isCookxEcoPack,
      is_xchefs: data.is_xchefs
    }
  };
  return apiService().get(`/${URL.ADMIN}/${URL.ORDERS}`, requestOptions);
}

function getOrder(id) {
  return apiService().get(`/${URL.ADMIN}/${URL.ORDERS}/${id}`);
}

function updateOrderStatus(id, data) {
  let requestBody = {
    delivery_status: data.deliveryStatus
  };

  return apiService().put(
    `/${URL.ADMIN}/${URL.ORDERS}/${id}/${URL.UPDATE_DELIVERY_STATUS}`,
    requestBody
  );
}

function rejectOrder(id, data) {
  let requestBody = {
    reason: data.rejectedReason
  };
  return apiService().put(
    `/${URL.ADMIN}/${URL.XCHEFS_ORDER}/${id}/${URL.REJECT}`,
    requestBody
  );
}

function confirmOrder(id) {
  return apiService().put(
    `/${URL.ADMIN}/${URL.XCHEFS_ORDER}/${id}/${URL.CONFIRM}`
  );
}

function exportOrderExcel(data) {
  let requestOptions = {
    params: {
      order_identifier: data.orderIdentifier,
      transaction_identifier: data.transactionIdentifier,
      order_status: data.orderStatus,
      delivery_status: data.deliveryStatus,
      order_from: data.orderFrom,
      order_by: data.orderBy,
      created_at_from: apiHelper.dateTimeStart(data.orderRequestDateRange),
      created_at_to: apiHelper.dateTimeEnd(data.orderRequestDateRange),
      updated_at_from: apiHelper.dateTimeStart(data.orderLastUpdatedDateRange),
      updated_at_to: apiHelper.dateTimeEnd(data.orderLastUpdatedDateRange),
      delivery_order_id: data.deliveryOrderId,
      stripe_payment_intent_id: data.stripePaymentIntentId
    }
  };
  return apiService().get(
    `/${URL.ADMIN}/${URL.ORDERS}/${URL.EXPORT}/${URL.EXCEL}`,
    requestOptions
  );
}

function updateOrder(id, data) {
  let requestBody = {
    delivery_fee_2: data.deliveryFee,
    share_link: data.shareLink,
    reassigned_by_admin_id: data.reassignedByAdminId,
    reassigned_driver_comment: data.reassignedDriverComment
  };

  return apiService().put(`/${URL.ADMIN}/${URL.ORDERS}/${id}`, requestBody);
}

function cancelOrder(id, data) {
  let requestBody = {
    reason: data.cancelReason
  };
  return apiService().put(
    `/${URL.ADMIN}/${URL.ORDERS}/${id}/${URL.CANCEL}`,
    requestBody
  );
}
