import dateTimeFormat from '@/core/filters/dateTimeFormat.filter';
import { XSTREET } from '@/core/api';
import { apiHelper } from '@/core/utils';
import {
  LOADING_OPEN_LOADING,
  LOADING_CLOSE_LOADING
} from '@/core/store/loading.module';

/* Define Module Name */
const MODULE_NAME = 'xstreet/';

/* Define Action Name */
const A_GET_XSTREET_LIST = 'getXStreetList';
const A_GET_XSTREET_POST = 'getXStreetPost';
const A_SET_VISIBILITY = 'setVisibility';

/* Define Reset State Action Name */
const A_INITIAL_GET_XSTREET_LIST_STATE = 'initialGetXStreetListState';
const A_INITIAL_GET_XSTREET_POST_STATE = 'initialGetXStreetPostState';
const A_INITIAL_SET_VISIBILITY_STATE = 'initialSetVisibilityState';

/* Define Getter Name */

/* Define Mutation Name */
const M_GET_XSTREET_LIST = 'setGetXStreetList';
const M_GET_XSTREET_POST = 'setGetXStreetPost';
const M_SET_VISIBILITY = 'setSetVisibility';

/* Define Reset State Mutation Name */
const M_INITIAL_GET_XSTREET_LIST_STATE = 'setInitialGetXStreetListState';
const M_INITIAL_GET_XSTREET_POST_STATE = 'setInitialGetXStreetPostState';
const M_INITIAL_SET_VISIBILITY_STATE = 'setInitialSetVisibilityState';

/* Define Export Name */
export const XSTREET_GET_XSTREET_LIST = MODULE_NAME + A_GET_XSTREET_LIST;
export const XSTREET_GET_XSTREET_POST = MODULE_NAME + A_GET_XSTREET_POST;
export const XSTREET_SET_VISIBILITY = MODULE_NAME + A_SET_VISIBILITY;

/* Define Reset State Name */
export const XSTREET_INITIAL_GET_XSTREET_LIST_STATE =
  MODULE_NAME + A_INITIAL_GET_XSTREET_LIST_STATE;
export const XSTREET_INITIAL_GET_XSTREET_POST_STATE =
  MODULE_NAME + A_INITIAL_GET_XSTREET_POST_STATE;
export const XSTREET_INITIAL_SET_VISIBILITY_STATE =
  MODULE_NAME + A_INITIAL_SET_VISIBILITY_STATE;

const state = {
  XStreetList: {
    code: 0,
    complete: false,
    data: null,
    message: null,
    pagination: {
      currentPage: 0,
      total: 0,
      perPage: 0,
      lastPage: 0
    }
  },
  xStreetPost: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  setVisibility: {
    code: 0,
    complete: false,
    data: null,
    message: null
  }
};

//Getters
const getters = {};

// Actions
const actions = {
  [A_GET_XSTREET_LIST]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    XSTREET.getXStreetList(data)
      .then((response) => {
        result = response;
        commit(M_GET_XSTREET_LIST, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_XSTREET_LIST, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_XSTREET_POST]({ dispatch, commit }, { id }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result;
    XSTREET.getXStreetPost(id)
      .then((response) => {
        result = response;
        commit(M_GET_XSTREET_POST, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_XSTREET_POST, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_SET_VISIBILITY]({ dispatch, commit }, { id, data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    XSTREET.setVisibility(id, data)
      .then((response) => {
        result = response;
        commit(M_SET_VISIBILITY, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_SET_VISIBILITY, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_INITIAL_GET_XSTREET_LIST_STATE]({ commit }) {
    commit(M_INITIAL_GET_XSTREET_LIST_STATE);
  },
  [A_INITIAL_GET_XSTREET_POST_STATE]({ commit }) {
    commit(M_INITIAL_GET_XSTREET_POST_STATE);
  },
  [A_INITIAL_SET_VISIBILITY_STATE]({ commit }) {
    commit(M_INITIAL_SET_VISIBILITY_STATE);
  }
};

// Mutations
const mutations = {
  [M_GET_XSTREET_LIST](state, result) {
    if (result.code === 0) {
      let d = result.data;
      let p = result.pagination;

      state.XStreetList = {
        code: result.code,
        complete: true,
        data: d.map((x, index) => {
          return {
            no: index + 1,
            id: x.id,
            userId: x.user_id,
            title: x.title,
            description: x.description,
            createdAt: dateTimeFormat(x.created_at),
            updatedAt: dateTimeFormat(x.updated_at),
            isVisible: Boolean(x.is_visible),
            username: x.username,
            imageUrl: x.image_url,
            thumbnailUrl: x.thumbnail_url
          };
        }),
        message: null,
        pagination: {
          currentPage: p.current_page,
          total: p.total,
          perPage: p.per_page,
          lastPage: p.last_page
        }
      };
    } else {
      state.XStreetList = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message),
        pagination: {
          currentPage: 0,
          total: 0,
          perPage: 0,
          lastPage: 0
        }
      };
    }
  },
  [M_GET_XSTREET_POST](state, result) {
    if (result.code === 0) {
      let d = result.data;

      state.xStreetPost = {
        code: result.code,
        complete: true,
        data: {
          id: d.id,
          userId: d.user_id,
          title: d.title,
          description: d.description,
          createdAt: dateTimeFormat(d.created_at),
          updatedAt: dateTimeFormat(d.updated_at),
          isVisible: Boolean(d.is_visible),
          username: d.username,
          imageUrl: d.image_url,
          thumbnailUrl: d.thumbnail_url
        },
        message: null
      };
    } else {
      state.xStreetPost = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_SET_VISIBILITY](state, result) {
    state.setVisibility = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_INITIAL_GET_XSTREET_LIST_STATE](state) {
    state.XStreetList = {
      code: 0,
      complete: false,
      data: null,
      message: null,
      pagination: {
        currentPage: 0,
        total: 0,
        perPage: 0,
        lastPage: 0
      }
    };
  },
  [M_INITIAL_GET_XSTREET_POST_STATE](state) {
    state.xStreetPost = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_SET_VISIBILITY_STATE](state) {
    state.setVisibility = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
