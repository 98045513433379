import constantsDescription from '@/core/filters/constants-description.filter';
import { PROMOTION_DELIVERY } from '@/core/api';
import { apiHelper /*i18nHelper*/ } from '@/core/utils';
import {
  LOADING_OPEN_LOADING,
  LOADING_CLOSE_LOADING
} from '@/core/store/loading.module';

/* Define Module Name */
const MODULE_NAME = 'promotionProductsDelivery/';

/* Define Action Name */
const A_GET_PRODUCTS_DELIVERY = 'getProductsDelivery';
const A_UPDATE_PRODUCTS_DELIVERY = 'updateProductsDelivery';
const A_UPDATE_PRODUCTS_ALL_DELIVERY = 'updateProductsAllDelivery';
const A_DELETE_PRODUCTS_DELIVERY = 'deleteProductsDelivery';
const A_DELETE_PRODUCTS_ALL_DELIVERY = 'deleteProductsAllDelivery';

/* Define Reset State Action Name */
const A_INITIAL_GET_PRODUCTS_DELIVERY = 'initialGetProductsDeliveryState';
const A_INITIAL_UPDATE_PRODUCTS_DELIVERY = 'initialUpdateProductsDeliveryState';
const A_INITIAL_UPDATE_PRODUCTS_ALL_DELIVERY =
  'initialUpdateProductsAllDeliveryState';
const A_INITIAL_DELETE_PRODUCTS_DELIVERY = 'initialDeleteProductsDeliveryState';
const A_INITIAL_DELETE_PRODUCTS_ALL_DELIVERY =
  'initialDeleteProductsAllDeliveryState';

/* Define Getter Name */

/* Define Mutation Name */
const M_GET_PRODUCTS_DELIVERY = 'setGetProductsDelivery';
const M_UPDATE_PRODUCTS_DELIVERY = 'setUpdateProductsDelivery';
const M_UPDATE_PRODUCTS_ALL_DELIVERY = 'setUpdateProductsAllDelivery';
const M_DELETE_PRODUCTS_DELETE_DELIVERY = 'setDeleteProductsDelivery';
const M_DELETE_PRODUCTS_DELETE_ALL_DELIVERY = 'setDeleteProductsAllDelivery';

/* Define Reset State Mutation Name */
const M_INITIAL_GET_PRODUCTS_DELIVERY = 'setInitialGetProductsDeliveryState';
const M_INITIAL_PRODUCTS_DELIVERY = 'setInitialUpdateProductsDeliveryState';
const M_INITIAL_PRODUCTS_ALL_DELIVERY =
  'setInitialUpdateProductsAllDeliveryState';
const M_INITIAL_PRODUCTS_DELETE_DELIVERY =
  'setInitialDeleteProductsDeliveryState';
const M_INITIAL_PRODUCTS_DELETE_ALL_DELIVERY =
  'setInitialDeleteProductsAllDeliveryState';

/* Define Export Name */
export const PROMOTION_PRODUCTS_GET_DELIVERYS =
  MODULE_NAME + A_GET_PRODUCTS_DELIVERY;
export const PROMOTION_PRODUCTS_UPDATE_DELIVERY =
  MODULE_NAME + A_UPDATE_PRODUCTS_DELIVERY;
export const PROMOTION_PRODUCTS_UPDATE_ALL_DELIVERY =
  MODULE_NAME + A_UPDATE_PRODUCTS_ALL_DELIVERY;
export const PROMOTION_PRODUCTS_DELETE_DELIVERY =
  MODULE_NAME + A_DELETE_PRODUCTS_DELIVERY;
export const PROMOTION_PRODUCTS_DELETE_ALL_DELIVERY =
  MODULE_NAME + A_DELETE_PRODUCTS_ALL_DELIVERY;

/* Define Reset State Name */
export const PROMOTION_PRODUCTS_INITIAL_GET_DELIVERYS_STATE =
  MODULE_NAME + A_INITIAL_GET_PRODUCTS_DELIVERY;
export const PROMOTION_PRODUCTS_INITIAL_UPDATE_DELIVERY_STATE =
  MODULE_NAME + A_INITIAL_UPDATE_PRODUCTS_DELIVERY;
export const PROMOTION_PRODUCTS_INITIAL_UPDATE_ALL_DELIVERY_STATE =
  MODULE_NAME + A_INITIAL_UPDATE_PRODUCTS_ALL_DELIVERY;
export const PROMOTION_PRODUCTS_INITIAL_DELETE_DELIVERY_STATE =
  MODULE_NAME + A_INITIAL_DELETE_PRODUCTS_DELIVERY;
export const PROMOTION_PRODUCTS_INITIAL_DELETE_ALL_DELIVERY_STATE =
  MODULE_NAME + A_INITIAL_DELETE_PRODUCTS_ALL_DELIVERY;

const state = {
  productsDelivery: {
    code: 0,
    complete: false,
    data: null,
    message: null,
    pagination: {
      currentPage: 0,
      total: 0,
      perPage: 0,
      lastPage: 0
    }
  },
  updateProductsDelivery: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  updateAllProductsDelivery: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  deleteProductsDelivery: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  deleteAllProductsDelivery: {
    code: 0,
    complete: false,
    data: null,
    message: null
  }
};

//Getters
const getters = {};

// Actions
const actions = {
  [A_GET_PRODUCTS_DELIVERY]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    PROMOTION_DELIVERY.getProductsDeliver(data)
      .then((response) => {
        result = response;
        commit(M_GET_PRODUCTS_DELIVERY, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_PRODUCTS_DELIVERY, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_UPDATE_PRODUCTS_DELIVERY]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    PROMOTION_DELIVERY.applyProductsDelivery(data)
      .then((response) => {
        result = response;
        commit(M_UPDATE_PRODUCTS_DELIVERY, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_UPDATE_PRODUCTS_DELIVERY, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_UPDATE_PRODUCTS_ALL_DELIVERY]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    PROMOTION_DELIVERY.applyAllProductsDelivery(data)
      .then((response) => {
        result = response;
        commit(M_UPDATE_PRODUCTS_ALL_DELIVERY, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_UPDATE_PRODUCTS_ALL_DELIVERY, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_DELETE_PRODUCTS_DELIVERY]({ dispatch, commit }, { id }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    PROMOTION_DELIVERY.deleteProductsDelivery(id)
      .then((response) => {
        result = response;
        commit(M_DELETE_PRODUCTS_DELETE_DELIVERY, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_DELETE_PRODUCTS_DELETE_DELIVERY, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  async [A_DELETE_PRODUCTS_ALL_DELIVERY]({ dispatch, commit }, { id }) {
    await dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    PROMOTION_DELIVERY.deleteAllProductsDelivery(id)
      .then((response) => {
        result = response;
        commit(M_DELETE_PRODUCTS_DELETE_ALL_DELIVERY, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_DELETE_PRODUCTS_DELETE_ALL_DELIVERY, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_INITIAL_GET_PRODUCTS_DELIVERY]({ commit }) {
    commit(M_INITIAL_GET_PRODUCTS_DELIVERY);
  },
  [A_INITIAL_UPDATE_PRODUCTS_DELIVERY]({ commit }) {
    commit(M_INITIAL_PRODUCTS_DELIVERY);
  },
  [A_INITIAL_UPDATE_PRODUCTS_ALL_DELIVERY]({ commit }) {
    commit(M_INITIAL_PRODUCTS_ALL_DELIVERY);
  },
  [A_INITIAL_DELETE_PRODUCTS_DELIVERY]({ commit }) {
    commit(M_INITIAL_PRODUCTS_DELETE_DELIVERY);
  },
  [A_INITIAL_DELETE_PRODUCTS_ALL_DELIVERY]({ commit }) {
    commit(M_INITIAL_PRODUCTS_DELETE_ALL_DELIVERY);
  }
};

// Mutations
const mutations = {
  [M_GET_PRODUCTS_DELIVERY](state, result) {
    if (result.code === 0) {
      let d = result.data;
      let p = result.pagination;
      let promotionProductsDeliverylist = [];

      // constantsDescription('deliveryDiscount', 1);
      d.forEach((x, index) => {
        promotionProductsDeliverylist.push({
          no: index + 1,
          id: x.id,
          ids: x.product_id,
          name: x.product_name,
          deliveryDiscount: constantsDescription('deliveryDiscount', x.type),
          // deliveryDiscount: x.type,
          deliveryAmounts: x.value,
          deliveryValidityFrom: x.applied_from,
          deliveryValidityTo: x.applied_to,
          creatorName: x.creator_name
        });
      });

      state.productsDelivery = {
        code: result.code,
        complete: true,
        data: promotionProductsDeliverylist,
        message: null,
        pagination: {
          currentPage: p.current_page,
          total: p.total,
          perPage: p.per_page,
          lastPage: p.last_page
        }
      };
    } else {
      state.productsDelivery = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message),
        pagination: {
          currentPage: 0,
          total: 0,
          perPage: 0,
          lastPage: 0
        }
      };
    }
  },
  [M_UPDATE_PRODUCTS_DELIVERY](state, result) {
    state.updateProductsDelivery = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_UPDATE_PRODUCTS_ALL_DELIVERY](state, result) {
    state.updateAllProductsDelivery = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_DELETE_PRODUCTS_DELETE_DELIVERY](state, result) {
    state.deleteProductsDelivery = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_DELETE_PRODUCTS_DELETE_ALL_DELIVERY](state, result) {
    state.deleteAllProductsDelivery = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_INITIAL_GET_PRODUCTS_DELIVERY](state) {
    state.productsDelivery = {
      code: 0,
      complete: false,
      data: null,
      message: null,
      pagination: {
        currentPage: 0,
        total: 0,
        perPage: 0,
        lastPage: 0
      }
    };
  },
  [M_INITIAL_PRODUCTS_DELIVERY](state) {
    state.updateProductsDelivery = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_PRODUCTS_ALL_DELIVERY](state) {
    state.updateAllProductsDelivery = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_PRODUCTS_DELETE_DELIVERY](state) {
    state.deleteProductsDelivery = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_PRODUCTS_DELETE_ALL_DELIVERY](state) {
    state.deleteAllProductsDelivery = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
