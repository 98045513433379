import dateTimeFormat from '@/core/filters/dateTimeFormat.filter';
import { CONFIG_NOTIFY_CREATOR } from '@/core/api';
import { apiHelper } from '@/core/utils';
import {
  LOADING_OPEN_LOADING,
  LOADING_CLOSE_LOADING
} from '@/core/store/loading.module';

/* Define Module Name */
const MODULE_NAME = 'configNotifyCreator/';

/* Define Action Name */
const A_GET_NOTIFY_CREATOR = 'getNotifyCreator';
const A_CREATE_NOTIFY_CREATOR = 'createNotifyCreator';
const A_UPDATE_NOTIFY_CREATOR = 'updateNotifyCreator';
const A_DELETE_NOTIFY_CREATOR = 'deleteNotifyCreator';

/* Define Reset State Action Name */
const A_INITIAL_GET_NOTIFY_CREATOR_STATE = 'initialGetNotifyCreatorState';
const A_INITIAL_CREATE_NOTIFY_CREATOR_STATE = 'initialcreateNotifyCreatorState';
const A_INITIAL_UPDATE_NOTIFY_CREATOR_STATE = 'initialupdateNotifyCreatorState';
const A_INITIAL_DELETE_NOTIFY_CREATOR_STATE = 'initialDeleteNotifyCreatorState';

/* Define Getter Name */

/* Define Mutation Name */
const M_GET_NOTIFY_CREATOR = 'setGetNotifyCreator';
const M_CREATE_NOTIFY_CREATOR = 'setCreateNotifyCreator';
const M_UPDATE_NOTIFY_CREATOR = 'setUpdateNotifyCreator';
const M_DELETE_NOTIFY_CREATOR = 'setDeleteNotifyCreator';

/* Define Reset State Mutation Name */
const M_INITIAL_GET_NOTIFY_CREATOR_STATE = 'setInitialGetNotifyCreatorState';
const M_INITIAL_CREATE_NOTIFY_CREATOR_STATE =
  'setInitialCreateNotifyCreatorState';
const M_INITIAL_UPDATE_NOTIFY_CREATOR_STATE =
  'setInitialUpdateNotifyCreatorState';
const M_INITIAL_DELETE_NOTIFY_CREATOR_STATE =
  'setInitialDeleteNotifyCreatorState';

/* Define Export Name */
export const GENERAL_GET_NOTIFY_CREATOR = MODULE_NAME + A_GET_NOTIFY_CREATOR;
export const GENERAL_CREATE_NOTIFY_CREATOR =
  MODULE_NAME + A_CREATE_NOTIFY_CREATOR;
export const GENERAL_UPDATE_NOTIFY_CREATOR =
  MODULE_NAME + A_UPDATE_NOTIFY_CREATOR;
export const GENERAL_DELETE_NOTIFY_CREATOR =
  MODULE_NAME + A_DELETE_NOTIFY_CREATOR;

/* Define Reset State Name */
export const GENERAL_INITIAL_GET_NOTIFY_CREATOR_STATE =
  MODULE_NAME + A_INITIAL_GET_NOTIFY_CREATOR_STATE;
export const GENERAL_INITIAL_CREATE_NOTIFY_CREATOR_STATE =
  MODULE_NAME + A_INITIAL_CREATE_NOTIFY_CREATOR_STATE;
export const GENERAL_INITIAL_UPDATE_NOTIFY_CREATOR_STATE =
  MODULE_NAME + A_INITIAL_UPDATE_NOTIFY_CREATOR_STATE;
export const GENERAL_INITIAL_DELETE_NOTIFY_CREATOR_STATE =
  MODULE_NAME + A_INITIAL_DELETE_NOTIFY_CREATOR_STATE;

const state = {
  notifyCreator: {
    code: 0,
    complete: false,
    data: null,
    message: null,
    pagination: {
      currentPage: 0,
      total: 0,
      perPage: 0,
      lastPage: 0
    }
  },
  createNotifyCreator: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  updateNotifyCreator: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  deleteNotifyCreator: {
    code: 0,
    complete: false,
    data: null,
    message: null
  }
};

//Getters
const getters = {};

// Actions
const actions = {
  [A_GET_NOTIFY_CREATOR]({ dispatch, commit }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    CONFIG_NOTIFY_CREATOR.getNotifyCreator()
      .then((response) => {
        result = response;
        commit(M_GET_NOTIFY_CREATOR, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_NOTIFY_CREATOR, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_CREATE_NOTIFY_CREATOR]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    CONFIG_NOTIFY_CREATOR.createNotifyCreator(data)
      .then((response) => {
        result = response;
        commit(M_CREATE_NOTIFY_CREATOR, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_CREATE_NOTIFY_CREATOR, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_UPDATE_NOTIFY_CREATOR]({ dispatch, commit }, { id, data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    CONFIG_NOTIFY_CREATOR.updateNotifyCreator(id, data)
      .then((response) => {
        result = response;
        commit(M_UPDATE_NOTIFY_CREATOR, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_UPDATE_NOTIFY_CREATOR, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_DELETE_NOTIFY_CREATOR]({ dispatch, commit }, { id, data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    CONFIG_NOTIFY_CREATOR.deleteNotifyCreator(id, data)
      .then((response) => {
        result = response;
        commit(M_DELETE_NOTIFY_CREATOR, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_DELETE_NOTIFY_CREATOR, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_INITIAL_CREATE_NOTIFY_CREATOR_STATE]({ commit }) {
    commit(M_INITIAL_CREATE_NOTIFY_CREATOR_STATE);
  },
  [A_INITIAL_GET_NOTIFY_CREATOR_STATE]({ commit }) {
    commit(M_INITIAL_GET_NOTIFY_CREATOR_STATE);
  },
  [A_INITIAL_UPDATE_NOTIFY_CREATOR_STATE]({ commit }) {
    commit(M_INITIAL_UPDATE_NOTIFY_CREATOR_STATE);
  },
  [A_INITIAL_DELETE_NOTIFY_CREATOR_STATE]({ commit }) {
    commit(M_INITIAL_DELETE_NOTIFY_CREATOR_STATE);
  }
};

// Mutations
const mutations = {
  [M_GET_NOTIFY_CREATOR](state, result) {
    let d = result.data;
    let NotifyCreateObj = [];
    d.forEach((x) => {
      NotifyCreateObj.push({
        id: x.id,
        created_by: x.created_by,
        phone: x.phone.substring(2),
        updatedAt: dateTimeFormat(x.updated_at),
        createdAt: dateTimeFormat(x.created_at)
      });
    });
    state.notifyCreator = {
      code: result.code,
      complete: true,
      data: NotifyCreateObj,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_CREATE_NOTIFY_CREATOR](state, result) {
    state.createNotifyCreator = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_UPDATE_NOTIFY_CREATOR](state, result) {
    state.updateNotifyCreator = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_DELETE_NOTIFY_CREATOR](state, result) {
    state.deleteNotifyCreator = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_INITIAL_GET_NOTIFY_CREATOR_STATE](state) {
    state.notifyCreator = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_CREATE_NOTIFY_CREATOR_STATE](state) {
    state.createNotifyCreator = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_UPDATE_NOTIFY_CREATOR_STATE](state) {
    state.updateNotifyCreator = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_DELETE_NOTIFY_CREATOR_STATE](state) {
    state.deleteNotifyCreator = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
