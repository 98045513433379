import dateTimeFormat from '@/core/filters/dateTimeFormat.filter';
import { SUBSCRIPTION } from '@/core/api';
import { apiHelper } from '@/core/utils';
import {
  LOADING_OPEN_LOADING,
  LOADING_CLOSE_LOADING
} from '@/core/store/loading.module';

/* Define Module Name */
const MODULE_NAME = 'subscription/';

/* Define Action Name */
const A_GET_SUBSCRIPTIONS = 'getSubscriptions';
const A_EXPORT_SUBSCRIPTION_EXCEL = 'exportSubscriptionExcel';

/* Define Reset State Action Name */
const A_INITIAL_SUBSCRIPTIONS_STATE = 'initialSubscriptionsState';
const A_INITIAL_EXPORT_SUBSCRIPTION_EXCEL_STATE =
  'initialExportSubscriptionExcelState';

/* Define Getter Name */

/* Define Mutation Name */
const M_SUBSCRIPTIONS = 'setSubscriptions';
const M_EXPORT_SUBSCRIPTION_EXCEL = 'setExportSubscriptionExcel';

/* Define Reset State Mutation Name */
const M_INITIAL_SUBSCRIPTIONS_STATE = 'setInitialSubscriptionsState';
const M_INITIAL_EXPORT_SUBSCRIPTION_EXCEL_STATE =
  'setInitialExportSubscriptionExcelState';

/* Define Export Name */
export const SUBSCRIPTION_GET_SUBSCRIPTIONS = MODULE_NAME + A_GET_SUBSCRIPTIONS;
export const SUBSCRIPTION_EXPORT_SUBSCRIPTION_EXCEL =
  MODULE_NAME + A_EXPORT_SUBSCRIPTION_EXCEL;

/* Define Reset State Name */
export const SUBSCRIPTION_INITIAL_SUBSCRIPTIONS_STATE =
  MODULE_NAME + A_INITIAL_SUBSCRIPTIONS_STATE;
export const SUBSCRIPTION_INITIAL_EXPORT_SUBSCRIPTION_EXCEL_STATE =
  MODULE_NAME + A_INITIAL_EXPORT_SUBSCRIPTION_EXCEL_STATE;

const state = {
  subscriptions: {
    code: 0,
    complete: false,
    data: null,
    message: null,
    pagination: {
      currentPage: 0,
      total: 0,
      perPage: 0,
      lastPage: 0
    }
  },
  exportSubscriptionExcel: {
    code: 0,
    complete: false,
    data: null,
    message: null
  }
};

//Getters
const getters = {};

// Actions
const actions = {
  [A_GET_SUBSCRIPTIONS]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    SUBSCRIPTION.getSubscriptionsList(data)
      .then((response) => {
        result = response;
        commit(M_SUBSCRIPTIONS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_SUBSCRIPTIONS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_EXPORT_SUBSCRIPTION_EXCEL]({ dispatch, commit }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    SUBSCRIPTION.exportSubscriptionExcel()
      .then((response) => {
        result = response;
        commit(M_EXPORT_SUBSCRIPTION_EXCEL, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_EXPORT_SUBSCRIPTION_EXCEL, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_INITIAL_SUBSCRIPTIONS_STATE]({ commit }) {
    commit(M_INITIAL_SUBSCRIPTIONS_STATE);
  },
  [A_INITIAL_EXPORT_SUBSCRIPTION_EXCEL_STATE]({ commit }) {
    commit(M_INITIAL_EXPORT_SUBSCRIPTION_EXCEL_STATE);
  }
};

// Mutations
const mutations = {
  [M_SUBSCRIPTIONS](state, result) {
    if (result.code === 0) {
      let d = result.data;
      let p = result.pagination;

      state.subscriptions = {
        code: result.code,
        complete: true,
        data: d.map((x, index) => {
          return {
            no: index + 1,
            email: x.email,
            subscriptionAt: dateTimeFormat(x.subcription_at)
          };
        }),
        message: null,
        pagination: {
          currentPage: p.current_page,
          total: p.total,
          perPage: p.per_page,
          lastPage: p.last_page
        }
      };
    } else {
      state.subscriptions = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message),
        pagination: {
          currentPage: 0,
          total: 0,
          perPage: 0,
          lastPage: 0
        }
      };
    }
  },
  [M_EXPORT_SUBSCRIPTION_EXCEL](state, result) {
    state.exportSubscriptionExcel = {
      code: 0,
      complete: true,
      data: result,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_INITIAL_SUBSCRIPTIONS_STATE](state) {
    state.subscriptions = {
      code: 0,
      complete: false,
      data: null,
      message: null,
      pagination: {
        currentPage: 0,
        total: 0,
        perPage: 0,
        lastPage: 0
      }
    };
  },
  [M_INITIAL_EXPORT_SUBSCRIPTION_EXCEL_STATE](state) {
    state.exportSubscriptionExcel = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
