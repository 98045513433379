var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"menu-nav new-menu-nav"},[_vm._l((_vm.menuCategoryItems),function(item,index){return [(
        item != _vm.asideMenuCategory.MAIN &&
        _vm.asideNavigationList.find((x) => x.category == item)
      )?_c('li',{key:index,staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t(`label.${item}`)))]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})]):_vm._e(),_vm._l((_vm.asideNavigationList.filter(
        (x) => x.category == item
      )),function(i,routesListIndex){return [(i.children.length > 0)?_c('li',{key:`${routesListIndex}-${i.category}`,staticClass:"menu-item menu-item-submenu",class:{ 'menu-item-open': _vm.hasActiveChildren(i.path) },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{class:`menu-icon ${i.icon}`}),_c('span',{staticClass:"menu-text text-capitalize"},[_vm._v(_vm._s(_vm.$t(`label.${i.name}`)))]),_c('i',{staticClass:"menu-arrow pr-3"}),_c('NotificationBadge',{attrs:{"name":i.notification}})],1),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_c('li',{staticClass:"menu-item menu-item-parent",attrs:{"aria-haspopup":"true"}},[_c('span',{staticClass:"menu-link"},[_c('span',{staticClass:"menu-text text-capitalize"},[_vm._v(_vm._s(i.name))])])]),_vm._l((i.children),function(secondLayer,secondLayerIndex){return [(secondLayer.children.length > 0)?_c('li',{key:secondLayerIndex,staticClass:"menu-item menu-item-submenu",class:{
                  'menu-item-open': _vm.hasActiveChildren(secondLayer.path),
                },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_vm._m(0,true),_c('span',{staticClass:"menu-text text-capitalize"},[_vm._v(_vm._s(_vm.$t(`label.${secondLayer.name}`)))]),_c('i',{staticClass:"menu-arrow"})]),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_vm._m(1,true),_vm._l((secondLayer.children),function(thirdLayer,thirdLayerIndex){return [(!thirdLayer.isHidden)?_c('router-link',{key:thirdLayerIndex,attrs:{"to":`${i.path}/${secondLayer.path}/${thirdLayer.path}`,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                            isActive && 'menu-item-active',
                            isExactActive && 'menu-item-active',
                          ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text text-capitalize"},[_vm._v(_vm._s(_vm.$t(`label.${thirdLayer.name}`)))])])])]}}],null,true)}):_vm._e()]})],2)])]):_c('router-link',{key:secondLayerIndex,attrs:{"to":`${i.path}/${secondLayer.path}`,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                  ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text text-capitalize"},[_vm._v(_vm._s(_vm.$t(`label.${secondLayer.name}`)))]),_c('NotificationBadge',{attrs:{"name":secondLayer.notification,"type":i.type}})],1)])]}}],null,true)})]})],2)])]):_c('router-link',{key:`${routesListIndex}-${i.category}`,attrs:{"to":i.path,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{class:`menu-icon ${i.icon}`}),_c('span',{staticClass:"menu-text text-capitalize"},[_vm._v(_vm._s(_vm.$t(`label.${i.name}`)))]),_c('NotificationBadge',{attrs:{"name":i.notification}})],1)])]}}],null,true)})]})]})],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-item menu-item-parent",attrs:{"aria-haspopup":"true"}},[_c('span',{staticClass:"menu-link"},[_c('span',{staticClass:"menu-text text-capitalize"})])])
}]

export { render, staticRenderFns }