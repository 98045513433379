<template>
  <label
    class="label label-md label-inline text-capitalize"
    :class="`label-light-${DeliveryStatusColor(value)}`"
  >
    {{ $t(`enumDeliveryStatus.${value}`) }}
  </label>
</template>

<script>
  import { DeliveryStatus } from '@/core/constants/enums';

  export default {
    name: 'DeliveryStatusLabel',
    props: {
      value: {
        type: Number,
        default: DeliveryStatus.NO_DRIVER_ASSIGNED
      }
    },
    methods: {
      DeliveryStatusColor(value) {
        let color = {
          [DeliveryStatus.NO_DRIVER_ASSIGNED]: 'danger',
          [DeliveryStatus.PROCESSING]: 'warning',
          [DeliveryStatus.ASSIGNING_DRIVER]: 'warning',
          [DeliveryStatus.ON_GOING]: 'warning',
          [DeliveryStatus.PICKED_UP]: 'warning',
          [DeliveryStatus.COMPLETED]: 'success',
          [DeliveryStatus.REJECTED]: 'danger',
          [DeliveryStatus.CANCELLED]: 'danger',
          [DeliveryStatus.EXPIRED]: 'danger',
          [DeliveryStatus.MANUAL]: 'info',
          [DeliveryStatus.SELF_PICKUP]: 'success'
        };

        return color[value];
      }
    }
  };
</script>

<style></style>
