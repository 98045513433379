<template>
  <b-button
    size="sm"
    :variant="`light-${value ? 'success' : 'danger'}`"
    :class="{ 'cursor-default': disabled }"
    :disabled="disabled"
    class="d-block"
    @click="changeVisibility(value)"
    >{{ $t(`enumVisibilityStatus.${Number(value)}`) }}</b-button
  >
</template>

<script>
  import commonMixin from '@/core/mixins/common.mixin';
  import { i18nHelper } from '@/core/utils';
  import { VisibilityStatus } from '@/core/constants/enums';

  export default {
    name: 'AppVisibilityStatusButton',
    mixins: [commonMixin],
    props: {
      value: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      id: {
        type: Number,
        default: null
      },
      storeActionsObj: {
        type: Object,
        default: () => {
          return {
            module: '',
            state: '',
            actionSet: '',
            actionInitial: '',
            successAction: () => {},
            title: '',
            message: ''
          };
        }
      }
    },
    computed: {
      setVisibilityComplete() {
        let obj = this.storeActionsObj;

        return this.$store.state[obj.module][obj.state].complete;
      }
    },
    watch: {
      setVisibilityComplete() {
        let obj = this.storeActionsObj;
        let response = this.$store.state[obj.module][obj.state];
        let title = obj.title;
        let initialStateAction = obj.actionInitial;
        let successAction = obj.successAction;

        if (response.complete) {
          this.actionUpdateRecordComplete(
            response,
            title,
            initialStateAction,
            successAction
          );
        }
      }
    },
    methods: {
      openRequestSetVisibilityModal(status) {
        let actionObj = this.storeActionsObj;
        let title = actionObj.title;
        let messages = [];
        let buttons = [];
        let data = {
          isVisible: status
            ? VisibilityStatus.INVISIBLE
            : VisibilityStatus.VISIBLE
        };

        messages.push(
          i18nHelper.getMessage('message.requestSetVisibilityStatusMessage', [
            actionObj.message.toLowerCase(),
            i18nHelper
              .getMessage(`enumVisibilityStatus.${status ? 0 : 1}`)
              .toLowerCase()
          ])
        );

        buttons.push(
          {
            text: i18nHelper.getMessage('label.cancel'),
            variant: '',
            action: () => {
              this.closeLayoutAppModalInfo();
            }
          },
          {
            text: i18nHelper.getMessage(
              `enumVisibilityStatus.${status ? 0 : 1}`
            ),
            isText: false,
            variant: status ? 'danger' : 'success',
            action: () => {
              this.closeLayoutAppModalInfo();
              this.setVisibility(this.id, data);
            }
          }
        );

        this.openLayoutAppModalInfo(title, messages, buttons);
      },
      changeVisibility(value) {
        this.openRequestSetVisibilityModal(value);
      },
      setVisibility(id, data) {
        this.$store.dispatch(this.storeActionsObj.actionSet, { id, data });
      }
    }
  };
</script>

<style></style>
