import { apiService } from '@/core/services';

export const CONSTANTS = {
  getConstants
};

const URL = {
  ADMIN: 'admin',
  CONSTANTS: 'constants'
};

function getConstants() {
  return apiService().get(`/${URL.ADMIN}/${URL.CONSTANTS}`);
}
