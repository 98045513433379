<template>
  <div class="full-screen d-flex justify-content-center align-items-center">
    <div
      class="font-weight-lighter home-title mb-30 text-center d-flex justify-content-center align-items-start"
    >
      {{ $t('label.welcomeTo') + ' ' + $t('title.projectName') }}
    </div>
  </div>
</template>

<script>
  import commonMixin from '@/core/mixins/common.mixin';
  import { i18nHelper } from '@/core/utils';

  export default {
    name: 'Home',
    mixins: [commonMixin],
    created() {
      this.initialHome();
    },
    methods: {
      initialHome() {
        let breadcrumbData = {
          pageTitle: i18nHelper.getMessage(`label.home`),
          breadcrumbs: [
            {
              title: i18nHelper.getMessage('label.home')
            }
          ]
        };

        this.initBreadCrumb(breadcrumbData);
      }
    }
  };
</script>

<style lang="scss">
  .home-title {
    font-size: 4rem;
  }

  @media (max-width: 1264px) {
    .home-title {
      font-size: 3rem;
    }
  }

  @media (max-width: 600px) {
    .home-title {
      font-size: 2rem;
    }
  }
</style>
