<template>
  <div>
    <b-row
      v-for="(i, index) in formFields"
      :key="index"
      no-gutters
      class="pt-5 align-items-center"
    >
      <div for="title" class="text-capitalize text-md-left col-sm-5 col-12">
        <span>
          {{ $t(`label.${i.name}`) }}
        </span>
        <span style="color: red">
          {{ `${i.isRequires ? '*' : ''}` }}
        </span>
      </div>
      <div class="col-sm-7 col-12">
        <component
          v-if="i.layout"
          v-model="$v.sellEcopackObj[i.model].$model"
          :is="i.layout"
          :options="i.options"
          :state="validateObjState('sellEcopackObj', i.model)"
          :validationValue="$v.sellEcopackObj[i.model]"
          :validators="i.validators"
        >
        </component>
        <AppFormField
          v-else-if="i.showOnly == 1"
          v-model="sellEcopackObj[i.model]"
          :noTitle="true"
          disabled
        ></AppFormField>
        <AppFormField
          v-else
          v-model="$v.sellEcopackObj[i.model].$model"
          :state="validateObjState('sellEcopackObj', i.model)"
          :validationValue="$v.sellEcopackObj[i.model]"
          :type="i.type"
          :validators="i.validators"
          :formType="i.formType"
          :options="i.options"
          :textTransform="i.textTransform"
          :disabled="i.disabled"
          :validationParams="i.validationParams"
          :max="i.max"
          :config="i.config"
          :min="i.min"
          :prepend="i.prepend"
          solid
          noTitle
        >
          <template v-slot:prepend>
            <span class="input-group-text text-uppercase">{{
              $t(`currency.${currency.MYR}`)
            }}</span>
          </template>
        </AppFormField>
        <div style="display: none">
          <!-- WARNING: I have no idea why this line is here, but removing it breaks everything -->
          {{ $v }}
        </div>
      </div>
    </b-row>
  </div>
</template>

<script>
  import AppFormField from '@/views/components/bases/form-field/AppFormField';
  import AppAmountType from '@/views/components/bases/AppAmountType';
  import RecipeEcoPackDeliveryMethod from '@/views/components/recipe/RecipeEcoPackDeliveryMethod';
  import commonMixin from '@/core/mixins/common.mixin';
  import { RecipeEcoPackStatus } from '@/core/constants/enums';
  import { validationMixin } from 'vuelidate';
  import { required } from 'vuelidate/lib/validators';
  import { MODULE, ACTION, price, CURRENCY } from '@/core/constants';
  import { i18nHelper } from '@/core/utils';
  // import { listService } from '@/core/services';
  import {
    RECIPE_GET_RECIPE,
    RECIPE_SET_REQUEST_ECOPACK,
    RECIPE_UPDATE_REQUEST_ECOPACK,
    RECIPE_UPDATE_TERMINATE_ECOPACK,
    RECIPE_NOTIFY_CREATOR_ECOPACK,
    RECIPE_INITIAL_SET_REQUEST_ECOPACK_STATE,
    RECIPE_INITIAL_UPDATE_REQUEST_ECOPACK_STATE,
    RECIPE_INITIAL_UPDATE_TERMINATE_ECOPACK_STATE,
    RECIPE_INITIAL_NOTIFY_CREATOR_ECOPACK_STATE
  } from '@/core/store/recipe.module';

  export default {
    name: 'RecipeEcoPackDetails',
    mixins: [commonMixin, validationMixin],
    props: {
      recipeObj: {
        type: Object,
        default: () => ({})
      }
    },
    components: {
      AppFormField,
      AppAmountType,
      RecipeEcoPackDeliveryMethod
    },
    data: () => ({
      moduleName: MODULE.RECIPE,
      modal: false,
      action: ACTION,
      currency: CURRENCY,
      recipeEcoPackStatus: RecipeEcoPackStatus,
      formFields: [],
      sellEcopackObj: {
        ecoPackPrice: null,
        promoPrice: null,
        // sellingQuantity: {
        //   type: null,
        //   value: null
        // },
        vehicleType: null,
        deliveryObj: {
          deliveryMethod: [2],
          deliveryBufferDay: null,
          deliveryBufferHour: null,
          pickupBufferDay: null,
          pickupBufferHour: null
        },
        sellingPeriodFrom: null,
        sellingPeriodTo: null
      }
    }),
    mounted() {},
    computed: {
      createRequestEcoPackComplete() {
        return this.$store.state.recipe.setRequestEcopack.complete;
      },
      updateRequestEcoPackComplete() {
        return this.$store.state.recipe.updateRequestEcoPack.complete;
      },
      updateTerminateEcoPackComplete() {
        return this.$store.state.recipe.updateTerminateEcoPack.complete;
      },
      notifyCreatorEcoPackComplete() {
        return this.$store.state.recipe.notifyCreatorEcoPack.complete;
      }
    },
    watch: {
      createRequestEcoPackComplete() {
        let response = this.$store.state.recipe.setRequestEcopack;
        if (response.complete) {
          this.requestEcoPackCompleteAction(response, 'create');
        }
      },
      updateRequestEcoPackComplete() {
        let response = this.$store.state.recipe.updateRequestEcoPack;
        if (response.complete) {
          this.requestEcoPackCompleteAction(response, 'update');
        }
      },
      updateTerminateEcoPackComplete() {
        let response = this.$store.state.recipe.updateTerminateEcoPack;
        if (response.complete) {
          this.requestEcoPackCompleteAction(response, 'terminate');
        }
      },
      notifyCreatorEcoPackComplete() {
        let response = this.$store.state.recipe.notifyCreatorEcoPack;
        if (response.complete) {
          this.requestEcoPackCompleteAction(response, 'notify');
        }
      }
    },
    methods: {
      requestEcoPackCompleteAction(response, actionType) {
        let title = i18nHelper.getMessage('label.ecoPackRequest');
        let messages = response.message;
        let buttons = [];
        switch (actionType) {
          case 'create':
            this.initialCreateRequestEcoPackState();
            break;
          case 'terminate':
            this.initialUpdateTerminateEcoPackState();
            break;
          case 'update':
            this.initialUpdateRequestEcoPackState();
            break;
          case 'notify':
            this.initialNotifyCreatorEcoPackState();
            break;
        }

        if (response.code == 0) {
          switch (actionType) {
            case 'update':
              buttons.push(
                {
                  text: i18nHelper.getMessage('label.notifyCreator'),
                  variant: 'primary',
                  action: () => {
                    this.closeLayoutAppModalInfo();
                    this.notifyCreatorEcoPack(this.recipeObj.id);
                  }
                },
                {
                  text: i18nHelper.getMessage('label.ok'),
                  variant: 'primary',
                  action: () => {
                    this.closeLayoutAppModalInfo();
                    this.$emit('actionDiscard', true);
                    this.getRecipe(this.recipeObj.id);
                  }
                }
              );
              break;
            default:
              buttons.push({
                text: i18nHelper.getMessage('label.ok'),
                variant: 'primary',
                action: () => {
                  this.closeLayoutAppModalInfo();
                  this.$emit('actionDiscard', true);
                  this.getRecipe(this.recipeObj.id);
                }
              });
          }
        } else {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();
            }
          });
        }
        if (messages) this.openLayoutAppModalInfo(title, messages, buttons);
      },
      // initial Form Start
      getEcopackInfo() {
        this.sellEcopackObj =
          this.recipeObj.ecoPackSelling ?? this.sellEcopackObj;
        this.initFormFields();
      },
      initFormFields() {
        this.formFields = [
          {
            showOnly: 1,
            name: 'recipe',
            model: 'recipe'
          },
          {
            name: 'ecoPackPrice',
            model: 'ecoPackPrice',
            type: 'number',
            isRequires: true,
            prepend: true,
            validators: ['required', 'price'],
            validationParams: [
              {
                key: 'between',
                params: [
                  i18nHelper.getMessage(`currency.${CURRENCY.MYR}`),
                  i18nHelper.getMessage(`currency.${CURRENCY.MYR}`)
                ],
                customizeMessage: 'invalidPriceRange'
              }
            ]
          },
          {
            name: 'promoPrice',
            model: 'promoPrice',
            type: 'number',
            prepend: true,
            validators: ['price'],
            validationParams: [
              {
                key: 'between',
                params: [
                  i18nHelper.getMessage(`currency.${CURRENCY.MYR}`),
                  i18nHelper.getMessage(`currency.${CURRENCY.MYR}`)
                ],
                customizeMessage: 'invalidPriceRange'
              }
            ]
          },
          // {
          //   name: 'sellingQuantity',
          //   model: 'sellingQuantity',
          //   layout: 'AppAmountType',
          //   options: listService.getsellingQuantityList()
          // },
          {
            name: 'deliveryMethod',
            model: 'deliveryObj',
            layout: 'RecipeEcoPackDeliveryMethod',
            options: [
              { text: 'Self-pickup (with buffer time)', value: 1 },
              { text: 'Delivery (with buffer time)', value: 2, disabled: true }
            ]
          },
          {
            name: 'vehicleType',
            model: 'vehicleType',
            formType: 'select',
            options: [
              {
                text: 'Any Vehicle',
                value: 2
              },
              {
                text: 'Car Only',
                value: 3
              }
            ]
          },
          {
            name: 'sellingPeriodFrom',
            model: 'sellingPeriodFrom',
            formType: 'date',
            isRequires: true,
            validators: ['required']
          },
          {
            name: 'sellingPeriodTo',
            model: 'sellingPeriodTo',
            formType: 'date',
            isRequires: true,
            validators: ['required'],
            config: {
              min: new Date()
            }
          }
        ];
      },
      // initial Form End
      // Submit Selling Ecopack And Return Value TO Parent Call API Start
      actionSubmitEcopackDetails() {
        if (this.validationCheck('sellEcopackObj')) {
          // this.recipeObj.ecoPackStatus == RecipeEcoPackStatus.ACCEPTED
          if (this.recipeObj.ecoPackOption == 'Fulfilled by cookx') {
            this.updateRequestEcoPack(this.recipeObj.id, this.sellEcopackObj);
          } else {
            this.createRequestEcoPack(this.recipeObj.id, this.sellEcopackObj);
          }
        }
      },
      // Submit Selling Ecopack And Return Value TO Parent Call API End
      actionTerminateEcoPack() {
        this.openActionRequestModal(this.recipeObj.id);
      },
      openActionRequestModal(id) {
        let title = i18nHelper.getMessage(`label.addNewReferee`);
        let messages = [];
        let buttons = [];
        messages.push(
          i18nHelper.getMessage('message.requestTerminateMessage', [
            i18nHelper.getMessage('label.ecoPackFulifiment')
          ])
        );

        buttons.push(
          {
            text: i18nHelper.getMessage('label.cancel'),
            variant: '',
            action: () => {
              this.closeLayoutAppModalInfo();
            }
          },
          {
            text: i18nHelper.getMessage('label.confirm'),
            variant: 'success',
            action: () => {
              this.closeLayoutAppModalInfo();
              this.updateTerminateEcoPack(id);
            }
          }
        );

        this.openLayoutAppModalInfo(title, messages, buttons);
      },
      // Discard And Reset Form Start
      resetForm() {
        this.$v.$reset();
        this.sellEcopackObj = {
          ecoPackPrice: null,
          promoPrice: null,
          // sellingQuantity: {
          //   type: null,
          //   value: null
          // },
          vehicleType: null,
          deliveryObj: {
            deliveryMethod: [2],
            deliveryBufferDay: null,
            deliveryBufferHour: null,
            pickupBufferDay: null,
            pickupBufferHour: null
          },
          sellingPeriodFrom: null,
          sellingPeriodTo: null
        };
        this.getRecipe(this.recipeObj.id);
      },
      // Discard And Reset Form End
      // Call API Start
      getRecipe(id) {
        this.$store.dispatch(RECIPE_GET_RECIPE, {
          id
        });
      },
      createRequestEcoPack(id, data) {
        this.$store.dispatch(RECIPE_SET_REQUEST_ECOPACK, { id, data });
      },
      updateRequestEcoPack(id, data) {
        this.$store.dispatch(RECIPE_UPDATE_REQUEST_ECOPACK, { id, data });
      },
      updateTerminateEcoPack(id) {
        this.$store.dispatch(RECIPE_UPDATE_TERMINATE_ECOPACK, { id });
      },
      notifyCreatorEcoPack(id) {
        this.$store.dispatch(RECIPE_NOTIFY_CREATOR_ECOPACK, { id });
      },
      initialCreateRequestEcoPackState() {
        this.$store.dispatch(RECIPE_INITIAL_SET_REQUEST_ECOPACK_STATE);
      },
      initialUpdateRequestEcoPackState() {
        this.$store.dispatch(RECIPE_INITIAL_UPDATE_REQUEST_ECOPACK_STATE);
      },
      initialUpdateTerminateEcoPackState() {
        this.$store.dispatch(RECIPE_INITIAL_UPDATE_TERMINATE_ECOPACK_STATE);
      },
      initialNotifyCreatorEcoPackState() {
        this.$store.dispatch(RECIPE_INITIAL_NOTIFY_CREATOR_ECOPACK_STATE);
      }
      // Call API End
    },
    validations: {
      sellEcopackObj: {
        ecoPackPrice: {
          required,
          price
        },
        promoPrice: {
          price
        },
        // sellingQuantity: {},
        vehicleType: {},
        deliveryObj: {},
        sellingPeriodFrom: { required },
        sellingPeriodTo: { required }
      }
    }
  };
</script>

<style>
  .form-group {
    margin-bottom: unset !important;
  }
</style>
