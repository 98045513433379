import Vue from 'vue';
import App from './App.vue';
import router from '@/core/router';
import store from '@/core/store';
import { i18nService } from '@/core/services';

Vue.config.productionTip = false;

// Global 3rd party plugins
import 'popper.js';
import 'tooltip.js';
import PerfectScrollbar from 'perfect-scrollbar';
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from 'clipboard';
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from '@/core/plugins/i18n.js';
import toast from '@/core/plugins/toast.js';
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import vuetify from '@/core/plugins/vuetify';
import '@/core/plugins/elementUI.js';
import '@/core/plugins/vue-multiselect';
import '@/core/plugins/portal-vue';
import '@/core/plugins/bootstrap-vue';
import '@/core/plugins/perfect-scrollbar';
import '@/core/plugins/highlight-js';
import '@/core/plugins/inline-svg';
import '@/core/plugins/apexcharts';
import '@/core/plugins/treeselect';
import '@/core/plugins/metronic';
import '@mdi/font/css/materialdesignicons.css';
import '@/core/plugins/formvalidation';
import '@/core/plugins/prime-vue';
import '@/core/plugins/v-viewer';
import '@/core/plugins/vuelidate';
import '@/core/plugins/fontawesome';
import '@/core/plugins/google-map';
import '@/core/plugins/v-calendar';
import '@/core/filters';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';

import '@/assets/sass/main.scss';

i18nService.init();

Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker);

new Vue({
  router,
  store,
  i18n,
  toast,
  vuetify,
  render: (h) => h(App)
}).$mount('#app');
