<template>
  <RecipePageDetails
    :isCreate="true"
    :category="recipeType"
  ></RecipePageDetails>
</template>

<script>
  import RecipePageDetails from '@/views/components/recipe-page/RecipePageDetails.vue';
  import { RecipePageType } from '@/core/constants/enums';

  export default {
    name: 'RecipePageBakeryCreate',
    components: {
      RecipePageDetails
    },
    data: () => ({
      recipeType: RecipePageType.BAKERY
    })
  };
</script>

<style></style>
