import dateTimeFormat from '@/core/filters/dateTimeFormat.filter';
import dateFormat from '@/core/filters/dateFormat.filter';
import currencyFormat from '@/core/filters/currency-format.filter';
import constantsDescription from '@/core/filters/constants-description.filter';
import {
  RecipeStatus,
  DeliveryMethod,
  VehicleType
} from '@/core/constants/enums';
import { ROUTE_NAME } from '@/core/constants';
import { RECIPE } from '@/core/api';
import { apiHelper, i18nHelper } from '@/core/utils';
import {
  LOADING_OPEN_LOADING,
  LOADING_CLOSE_LOADING
} from '@/core/store/loading.module';

/* Define Module Name */
const MODULE_NAME = 'recipe/';

/* Define Action Name */
const A_GET_RECIPES = 'getRecipes';
const A_GET_RECIPE = 'getRecipe';
const A_GET_ECOPACK_RECIPES = 'getEcoPackRecipes';
const A_GET_RECIPE_INGREDIENTS = 'getRecipeIngredients';
const A_GET_RECIPE_EQUIPMENT = 'getRecipeEquipment';
const A_GET_RECIPE_INSTRUCTIONS = 'getRecipeInstructions';
const A_UPDATE_RECIPE_INGREDIENT = 'updateRecipeIngredient';
const A_UPDATE_RECIPE_EQUIPMENT = 'updateRecipeEquipment';
const A_UPDATE_TERMINATE_ECOPACK = 'updateTerminateEcoPack';
const A_UPDATE_REQUEST_ECOPACK = 'updateRequestEcoPack';
const A_UPDATE_ECO_PACK_VARIATIONS = 'updateEcoPackVariations';
const A_VERIFY_RECIPE = 'verifyRecipe';
const A_SET_VISIBILITY = 'setVisibility';
const A_SET_EDIT_ECOPACK = 'setEditEcopack';
const A_SET_REQUEST_ECOPACK = 'setRequestEcopack';
const A_UPDATE_RECIPE = 'updateRecipe';
const A_REJECT_RECIPE = 'rejectRecipe';
const A_REJECT_ECOPACK = 'rejectEcoPack';
const A_APPROVE_ECOPACK = 'approveEcoPack';
const A_NOTIFY_CREATOR_ECOPACK = 'notifyCreatorEcoPack';

/* Define Reset State Action Name */
const A_INITIAL_GET_RECIPES_STATE = 'initialGetRecipesState';
const A_INITIAL_GET_RECIPE_STATE = 'initialGetRecipeState';
const A_INITIAL_GET_RECIPE_INGREDIENTS_STATE =
  'initialGetRecipeIngredientsState';
const A_INITIAL_GET_RECIPE_EQUIPMENT_STATE = 'initialGetRecipeEquipmentState';
const A_INITIAL_GET_RECIPE_INSTRUCTIONS_STATE =
  'initialGetRecipeInstructionsState';
const A_INITIAL_UPDATE_RECIPE_INGREDIENT_STATE =
  'initialUpdateRecipeIngredientState';
const A_INITIAL_UPDATE_RECIPE_EQUIPMENT_STATE =
  'initialUpdateRecipeEquipmentState';
const A_INITIAL_UPDATE_TERMINATE_ECOPACK_STATE =
  'initialUpdateTerminateEcoPackState';
const A_INITIAL_UPDATE_REQUEST_ECOPACK_STATE =
  'initialUpdateRequestEcoPackState';
const A_INITIAL_UPDATE_ECO_PACK_VARIATIONS_STATE =
  'initialUpdateEcoPackVariationsState';
const A_INITIAL_VERIFY_RECIPE_STATE = 'initialVerifyRecipeState';
const A_INITIAL_SET_VISIBILITY_STATE = 'initialSetVisibilityState';
const A_INITIAL_SET_EDIT_ECOPACK_STATE = 'initialSetEditEcopackState';
const A_INITIAL_SET_REQUEST_ECOPACK_STATE = 'initialSetRequestEcopackState';
const A_INITIAL_UPDATE_RECIPE = 'initialUpdateRecipe';
const A_INITIAL_REJECT_RECIPE_STATE = 'initialRejectRecipeState';
const A_INITIAL_REJECT_ECOPACK_STATE = 'initialRejectEcoPackState';
const A_INITIAL_APPROVE_ECOPACK_STATE = 'initialApproveEcoPackState';
const A_INITIAL_NOTIFY_CREATOR_ECOPACK_STATE =
  'initialNotifyCreatorEcoPackState';

/* Define Getter Name */

/* Define Mutation Name */
const M_GET_RECIPES = 'setGetRecipes';
const M_GET_RECIPE = 'setGetRecipe';
const M_GET_RECIPE_INGREDIENTS = 'setGetRecipeIngredients';
const M_GET_RECIPE_EQUIPMENT = 'setGetRecipeEquipment';
const M_GET_RECIPE_INSTRUCTIONS = 'setGetRecipeInstructions';
const M_UPDATE_RECIPE_INGREDIENT = 'setUpdateRecipeIngredient';
const M_UPDATE_RECIPE_EQUIPMENT = 'setUpdateRecipeEquipment';
const M_UPDATE_TERMINATE_ECOPACK = 'setUpdateTerminateEcoPack';
const M_UPDATE_REQUEST_ECOPACK = 'setUpdateRequestEcoPack';
const M_UPDATE_ECO_PACK_VARIATIONS = 'setUpdateEcoPackVariations';
const M_VERIFY_RECIPE = 'setVerifyRecipe';
const M_SET_VISIBILITY = 'setSetVisibility';
const M_SET_EDIT_ECOPACK = 'setSetEditEcopack';
const M_SET_REQUEST_ECOPACK = 'setSetRequestEcopack';
const M_UPDATE_RECIPE = 'setUpdateRecipe';
const M_REJECT_RECIPE = 'setRejectRecipe';
const M_REJECT_ECOPACK = 'setRejectEcoPack';
const M_APPROVE_ECOPACK = 'setApproveEcoPack';
const M_NOTIFY_CREATOR_ECOPACK = 'setNotifyCreatorEcoPack';

/* Define Reset State Mutation Name */
const M_INITIAL_GET_RECIPES_STATE = 'setInitialGetRecipesState';
const M_INITIAL_GET_RECIPE_STATE = 'setInitialGetRecipeState';
const M_INITIAL_GET_RECIPE_INGREDIENTS_STATE =
  'setInitialGetRecipeIngredientsState';
const M_INITIAL_GET_RECIPE_EQUIPMENT_STATE =
  'setInitialGetRecipeEquipmentState';
const M_INITIAL_GET_RECIPE_INSTRUCTIONS_STATE =
  'setInitialGetRecipeInstructionsState';
const M_INITIAL_UPDATE_RECIPE_INGREDIENT_STATE =
  'setInitialUpdateRecipeIngredientState';
const M_INITIAL_UPDATE_RECIPE_EQUIPMENT_STATE =
  'setInitialUpdateRecipeEquipmentState';
const M_INITIAL_UPDATE_TERMINATE_ECOPACK_STATE =
  'setInitialUpdateTerminateEcoPackState';
const M_INITIAL_UPDATE_REQUEST_ECOPACK_STATE =
  'setInitialUpdateRequestEcoPackState';
const M_INITIAL_UPDATE_ECO_PACK_VARIATIONS_STATE =
  'setInitialUpdateEcoPackVariationsState';
const M_INITIAL_VERIFY_RECIPE_STATE = 'setInitialVerifyRecipeState';
const M_INITIAL_SET_VISIBILITY_STATE = 'setInitialSetVisibilityState';
const M_INITIAL_SET_EDIT_ECOPACK_STATE = 'setInitialSetEditEcopackState';
const M_INITIAL_SET_REQUEST_ECOPACK_STATE = 'setInitialSetRequestEcopackState';
const M_INITIAL_UPDATE_RECIPE = 'setInitialUpdateRecipeState';
const M_INITIAL_REJECT_RECIPE_STATE = 'setInitialRejectRecipeState';
const M_INITIAL_REJECT_ECOPACK_STATE = 'setInitialRejectEcoPackState';
const M_INITIAL_APPROVE_ECOPACK_STATE = 'setInitialApproveEcoPackState';
const M_INITIAL_NOTIFY_CREATOR_ECOPACK_STATE =
  'setInitialNotifyCreatorEcoPackState';

/* Define Export Name */
export const RECIPE_GET_RECIPES = MODULE_NAME + A_GET_RECIPES;
export const RECIPE_GET_RECIPE = MODULE_NAME + A_GET_RECIPE;
export const RECIPE_GET_ECOPACK_RECIPES = MODULE_NAME + A_GET_ECOPACK_RECIPES;
export const RECIPE_GET_RECIPE_INGREDIENTS =
  MODULE_NAME + A_GET_RECIPE_INGREDIENTS;
export const RECIPE_GET_RECIPE_EQUIPMENT = MODULE_NAME + A_GET_RECIPE_EQUIPMENT;
export const RECIPE_GET_RECIPE_INSTRUCTIONS =
  MODULE_NAME + A_GET_RECIPE_INSTRUCTIONS;
export const RECIPE_UPDATE_RECIPE_INGREDIENT =
  MODULE_NAME + A_UPDATE_RECIPE_INGREDIENT;
export const RECIPE_UPDATE_RECIPE_EQUIPMENT =
  MODULE_NAME + A_UPDATE_RECIPE_EQUIPMENT;
export const RECIPE_UPDATE_TERMINATE_ECOPACK =
  MODULE_NAME + A_UPDATE_TERMINATE_ECOPACK;
export const RECIPE_UPDATE_REQUEST_ECOPACK =
  MODULE_NAME + A_UPDATE_REQUEST_ECOPACK;
export const RECIPE_UPDATE_ECO_PACK_VARIATIONS =
  MODULE_NAME + A_UPDATE_ECO_PACK_VARIATIONS;
export const RECIPE_VERIFY_RECIPE = MODULE_NAME + A_VERIFY_RECIPE;
export const RECIPE_SET_VISIBILITY = MODULE_NAME + A_SET_VISIBILITY;
export const RECIPE_SET_EDIT_ECOPACK = MODULE_NAME + A_SET_EDIT_ECOPACK;
export const RECIPE_SET_REQUEST_ECOPACK = MODULE_NAME + A_SET_REQUEST_ECOPACK;
export const RECIPE_UPDATE_RECIPE = MODULE_NAME + A_UPDATE_RECIPE;
export const RECIPE_REJECT_RECIPE = MODULE_NAME + A_REJECT_RECIPE;
export const RECIPE_REJECT_ECOPACK = MODULE_NAME + A_REJECT_ECOPACK;
export const RECIPE_APPROVE_ECOPACK = MODULE_NAME + A_APPROVE_ECOPACK;
export const RECIPE_NOTIFY_CREATOR_ECOPACK =
  MODULE_NAME + A_NOTIFY_CREATOR_ECOPACK;

/* Define Reset State Name */
export const RECIPE_INITIAL_GET_RECIPES_STATE =
  MODULE_NAME + A_INITIAL_GET_RECIPES_STATE;
export const RECIPE_INITIAL_GET_RECIPE_STATE =
  MODULE_NAME + A_INITIAL_GET_RECIPE_STATE;
export const RECIPE_INITIAL_GET_RECIPE_INGREDIENTS_STATE =
  MODULE_NAME + A_INITIAL_GET_RECIPE_INGREDIENTS_STATE;
export const RECIPE_INITIAL_GET_RECIPE_EQUIPMENT_STATE =
  MODULE_NAME + A_INITIAL_GET_RECIPE_EQUIPMENT_STATE;
export const RECIPE_INITIAL_GET_RECIPE_INSTRUCTIONS_STATE =
  MODULE_NAME + A_INITIAL_GET_RECIPE_INSTRUCTIONS_STATE;
export const RECIPE_INITIAL_UPDATE_RECIPE_INGREDIENT_STATE =
  MODULE_NAME + A_INITIAL_UPDATE_RECIPE_INGREDIENT_STATE;
export const RECIPE_INITIAL_UPDATE_RECIPE_EQUIPMENT_STATE =
  MODULE_NAME + A_INITIAL_UPDATE_RECIPE_EQUIPMENT_STATE;
export const RECIPE_INITIAL_UPDATE_TERMINATE_ECOPACK_STATE =
  MODULE_NAME + A_INITIAL_UPDATE_TERMINATE_ECOPACK_STATE;
export const RECIPE_INITIAL_UPDATE_REQUEST_ECOPACK_STATE =
  MODULE_NAME + A_INITIAL_UPDATE_REQUEST_ECOPACK_STATE;
export const RECIPE_INITIAL_UPDATE_ECO_PACK_VARIATIONS_STATE =
  MODULE_NAME + A_INITIAL_UPDATE_ECO_PACK_VARIATIONS_STATE;
export const RECIPE_INITIAL_VERIFY_RECIPE_STATE =
  MODULE_NAME + A_INITIAL_VERIFY_RECIPE_STATE;
export const RECIPE_INITIAL_SET_VISIBILITY_STATE =
  MODULE_NAME + A_INITIAL_SET_VISIBILITY_STATE;
export const RECIPE_INITIAL_SET_EDIT_ECOPACK_STATE =
  MODULE_NAME + A_INITIAL_SET_EDIT_ECOPACK_STATE;
export const RECIPE_INITIAL_SET_REQUEST_ECOPACK_STATE =
  MODULE_NAME + A_INITIAL_SET_REQUEST_ECOPACK_STATE;
export const RECIPE_INITIAL_UPDATE_RECIPE_STATE =
  MODULE_NAME + A_INITIAL_UPDATE_RECIPE;
export const RECIPE_INITIAL_REJECT_RECIPE_STATE =
  MODULE_NAME + A_INITIAL_REJECT_RECIPE_STATE;
export const RECIPE_INITIAL_REJECT_ECOPACK_STATE =
  MODULE_NAME + A_INITIAL_REJECT_ECOPACK_STATE;
export const RECIPE_INITIAL_APPROVE_ECOPACK_STATE =
  MODULE_NAME + A_INITIAL_APPROVE_ECOPACK_STATE;
export const RECIPE_INITIAL_NOTIFY_CREATOR_ECOPACK_STATE =
  MODULE_NAME + A_INITIAL_NOTIFY_CREATOR_ECOPACK_STATE;

const state = {
  recipes: {
    code: 0,
    complete: false,
    data: null,
    message: null,
    pagination: {
      currentPage: 0,
      total: 0,
      perPage: 0,
      lastPage: 0
    }
  },
  recipe: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  ingredients: {
    code: 0,
    complete: false,
    data: null,
    message: null,
    pagination: {
      currentPage: 0,
      total: 0,
      perPage: 0,
      lastPage: 0
    }
  },
  equipment: {
    code: 0,
    complete: false,
    data: null,
    message: null,
    pagination: {
      currentPage: 0,
      total: 0,
      perPage: 0,
      lastPage: 0
    }
  },
  instructions: {
    code: 0,
    complete: false,
    data: null,
    message: null,
    pagination: {
      currentPage: 0,
      total: 0,
      perPage: 0,
      lastPage: 0
    }
  },
  updateIngredient: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  updateEquipment: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  updateTerminateEcoPack: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  updateRequestEcoPack: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  updateEcoPackVariations: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  verifyRecipe: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  setVisibility: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  setEditEcopack: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  setRequestEcopack: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  updateRecipe: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  rejectRecipe: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  rejectEcoPack: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  approveEcoPack: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  notifyCreatorEcoPack: {
    code: 0,
    complete: false,
    data: null,
    message: null
  }
};

//Getters
const getters = {};

// Actions
const actions = {
  async [A_GET_RECIPES]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    await RECIPE.getRecipes(data)
      .then((response) => {
        result = response;
        commit(M_GET_RECIPES, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_RECIPES, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_ECOPACK_RECIPES]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    RECIPE.getEcoPackRecipes(data)
      .then((response) => {
        result = response;
        commit(M_GET_RECIPES, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_RECIPES, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_RECIPE]({ dispatch, commit }, { id, data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    RECIPE.getRecipe(id, data)
      .then((response) => {
        result = response;
        commit(M_GET_RECIPE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_RECIPE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_RECIPE_INGREDIENTS]({ dispatch, commit }, { id, data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    RECIPE.getIngredients(id, data)
      .then((response) => {
        result = response;
        commit(M_GET_RECIPE_INGREDIENTS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_RECIPE_INGREDIENTS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_RECIPE_EQUIPMENT]({ dispatch, commit }, { id, data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    RECIPE.getEquipment(id, data)
      .then((response) => {
        result = response;
        commit(M_GET_RECIPE_EQUIPMENT, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_RECIPE_EQUIPMENT, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_RECIPE_INSTRUCTIONS]({ dispatch, commit }, { id, data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    RECIPE.getInstructions(id, data)
      .then((response) => {
        result = response;
        commit(M_GET_RECIPE_INSTRUCTIONS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_RECIPE_INSTRUCTIONS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_UPDATE_RECIPE_INGREDIENT](
    { dispatch, commit },
    { id, data, ingredientId }
  ) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    RECIPE.updateIngredients(id, data, ingredientId)
      .then((response) => {
        result = response;
        commit(M_UPDATE_RECIPE_INGREDIENT, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_UPDATE_RECIPE_INGREDIENT, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_UPDATE_RECIPE_EQUIPMENT]({ dispatch, commit }, { id, data, equipmentId }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    RECIPE.updateEquipment(id, data, equipmentId)
      .then((response) => {
        result = response;
        commit(M_UPDATE_RECIPE_EQUIPMENT, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_UPDATE_RECIPE_EQUIPMENT, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_UPDATE_TERMINATE_ECOPACK]({ dispatch, commit }, { id }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    RECIPE.updateTerminateEcoPack(id)
      .then((response) => {
        result = response;
        commit(M_UPDATE_TERMINATE_ECOPACK, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_UPDATE_TERMINATE_ECOPACK, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_UPDATE_REQUEST_ECOPACK]({ dispatch, commit }, { id, data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    RECIPE.updateRequestEcopack(id, data)
      .then((response) => {
        result = response;
        commit(M_UPDATE_REQUEST_ECOPACK, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_UPDATE_REQUEST_ECOPACK, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_UPDATE_ECO_PACK_VARIATIONS]({ dispatch, commit }, { id, data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    RECIPE.updateEcoPackVariations(id, data)
      .then((response) => {
        result = response;
        commit(M_UPDATE_ECO_PACK_VARIATIONS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_UPDATE_ECO_PACK_VARIATIONS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_VERIFY_RECIPE]({ dispatch, commit }, { id }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    RECIPE.verifyRecipe(id)
      .then((response) => {
        result = response;
        commit(M_VERIFY_RECIPE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_VERIFY_RECIPE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_SET_VISIBILITY]({ dispatch, commit }, { id, data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    RECIPE.setVisibility(id, data)
      .then((response) => {
        result = response;
        commit(M_SET_VISIBILITY, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_SET_VISIBILITY, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_SET_EDIT_ECOPACK]({ dispatch, commit }, { id, data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    RECIPE.setEditEcopack(id, data)
      .then((response) => {
        result = response;
        commit(M_SET_EDIT_ECOPACK, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_SET_EDIT_ECOPACK, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_SET_REQUEST_ECOPACK]({ dispatch, commit }, { id, data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    RECIPE.setRequestEcopack(id, data)
      .then((response) => {
        result = response;
        commit(M_SET_REQUEST_ECOPACK, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_SET_REQUEST_ECOPACK, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_UPDATE_RECIPE]({ dispatch, commit }, { id, data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    RECIPE.updateRecipe(id, data)
      .then((response) => {
        result = response;
        commit(M_UPDATE_RECIPE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_UPDATE_RECIPE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_REJECT_RECIPE]({ dispatch, commit }, { id, data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    RECIPE.rejectRecipe(id, data)
      .then((response) => {
        result = response;
        commit(M_REJECT_RECIPE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_REJECT_RECIPE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_REJECT_ECOPACK]({ dispatch, commit }, { id, data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    RECIPE.rejectEcoPack(id, data)
      .then((response) => {
        result = response;
        commit(M_REJECT_ECOPACK, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_REJECT_ECOPACK, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_APPROVE_ECOPACK]({ dispatch, commit }, { id }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    RECIPE.approveEcoPack(id)
      .then((response) => {
        result = response;
        commit(M_APPROVE_ECOPACK, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_APPROVE_ECOPACK, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_NOTIFY_CREATOR_ECOPACK]({ dispatch, commit }, { id }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    RECIPE.notifyCreatorEcoPack(id)
      .then((response) => {
        result = response;
        commit(M_NOTIFY_CREATOR_ECOPACK, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_NOTIFY_CREATOR_ECOPACK, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_INITIAL_GET_RECIPES_STATE]({ commit }) {
    commit(M_INITIAL_GET_RECIPES_STATE);
  },
  [A_INITIAL_GET_RECIPE_STATE]({ commit }) {
    commit(M_INITIAL_GET_RECIPE_STATE);
  },
  [A_INITIAL_GET_RECIPE_INGREDIENTS_STATE]({ commit }) {
    commit(M_INITIAL_GET_RECIPE_INGREDIENTS_STATE);
  },
  [A_INITIAL_GET_RECIPE_EQUIPMENT_STATE]({ commit }) {
    commit(M_INITIAL_GET_RECIPE_EQUIPMENT_STATE);
  },
  [A_INITIAL_GET_RECIPE_INSTRUCTIONS_STATE]({ commit }) {
    commit(M_INITIAL_GET_RECIPE_INSTRUCTIONS_STATE);
  },
  [A_INITIAL_UPDATE_RECIPE_INGREDIENT_STATE]({ commit }) {
    commit(M_INITIAL_UPDATE_RECIPE_INGREDIENT_STATE);
  },
  [A_INITIAL_UPDATE_RECIPE_EQUIPMENT_STATE]({ commit }) {
    commit(M_INITIAL_UPDATE_RECIPE_EQUIPMENT_STATE);
  },
  [A_INITIAL_UPDATE_TERMINATE_ECOPACK_STATE]({ commit }) {
    commit(M_INITIAL_UPDATE_TERMINATE_ECOPACK_STATE);
  },
  [A_INITIAL_UPDATE_REQUEST_ECOPACK_STATE]({ commit }) {
    commit(M_INITIAL_UPDATE_REQUEST_ECOPACK_STATE);
  },
  [A_INITIAL_UPDATE_ECO_PACK_VARIATIONS_STATE]({ commit }) {
    commit(M_INITIAL_UPDATE_ECO_PACK_VARIATIONS_STATE);
  },
  [A_INITIAL_VERIFY_RECIPE_STATE]({ commit }) {
    commit(M_INITIAL_VERIFY_RECIPE_STATE);
  },
  [A_INITIAL_SET_VISIBILITY_STATE]({ commit }) {
    commit(M_INITIAL_SET_VISIBILITY_STATE);
  },
  [A_INITIAL_SET_EDIT_ECOPACK_STATE]({ commit }) {
    commit(M_INITIAL_SET_EDIT_ECOPACK_STATE);
  },
  [A_INITIAL_SET_REQUEST_ECOPACK_STATE]({ commit }) {
    commit(M_INITIAL_SET_REQUEST_ECOPACK_STATE);
  },
  [A_INITIAL_UPDATE_RECIPE]({ commit }) {
    commit(M_INITIAL_UPDATE_RECIPE);
  },
  [A_INITIAL_REJECT_RECIPE_STATE]({ commit }) {
    commit(M_INITIAL_REJECT_RECIPE_STATE);
  },
  [A_INITIAL_REJECT_ECOPACK_STATE]({ commit }) {
    commit(M_INITIAL_REJECT_ECOPACK_STATE);
  },
  [A_INITIAL_APPROVE_ECOPACK_STATE]({ commit }) {
    commit(M_INITIAL_APPROVE_ECOPACK_STATE);
  },
  [A_INITIAL_NOTIFY_CREATOR_ECOPACK_STATE]({ commit }) {
    commit(M_INITIAL_NOTIFY_CREATOR_ECOPACK_STATE);
  }
};

// Mutations
const mutations = {
  [M_GET_RECIPES](state, result) {
    if (result.code === 0) {
      let d = result.data;
      let p = result.pagination;
      let recipes = [];

      d.forEach((x, index) => {
        let status = null;

        if (!x.verified_at && !x.deleted_at) {
          status = RecipeStatus.PENDING;
        } else if (x.verified_at && !x.deleted_at) {
          status = RecipeStatus.VERIFIED;
        } else if (x.deleted_at) {
          status = RecipeStatus.DELETED;
        }

        recipes.push({
          no: index + 1,
          id: x.id,
          uuid: x.uuid,
          userId: x.user_id,
          name: x.name,
          draftName: x.draft_name ? x.draft_name : '-',
          description: x.description,
          minutesToCook: x.minutes_to_cook,
          cookingLevel: constantsDescription('cookingLevel', x.cooking_level),
          servePax: x.serve_pax,
          cuisineType: constantsDescription('cuisineType', x.cuisine_type),
          foodCategory: constantsDescription('foodCategory', x.food_category),
          foodType: constantsDescription('foodType', x.food_type),
          priceRange: constantsDescription('priceRange', x.price_range),
          price: x.price,
          ecoPackOption: constantsDescription(
            'ecoPackOption',
            x.eco_pack_option
          ),
          ecoPackOfferedAt: x.cookx_eco_pack_offered_at,
          availableFrom: dateTimeFormat(x.available_from),
          availableTo: dateTimeFormat(x.available_to),
          promotionPrice: x.promotion_price ? x.promotion_price : '-',
          tips: x.tips ? d.tips : '-',
          isCookxEcoPack: Boolean(x.is_cookx_eco_pack),
          isPopular: x.is_popular,
          isTrending: x.is_trending,
          isTop10: x.is_top10,
          isRecommended: x.is_recommended,
          isRated: x.is_rated,
          isActive: x.is_active,
          createdAt: dateTimeFormat(x.created_at),
          updatedAt: dateTimeFormat(x.updated_at),
          publishedAt: dateTimeFormat(x.published_at),
          verifiedAt: dateTimeFormat(x.verified_at),
          deletedAt: dateTimeFormat(x.deleted_at),
          hashtag: x.hashtag ? x.hashtag.split(',') : '-',
          remark: x.remark ? x.remark : '-',
          deliveryMethod: getDeliveryMethod(d.delivery_method),
          isVisible: Boolean(x.is_visible),
          username: x.username,
          displayPrice: x.display_price,
          averageRating: x.average_rating
            ? Number(x.average_rating.toString().substr(0, 4))
            : 0,
          productId: x.product_id,
          productName: x.product_name,
          product: {
            id: x.product_id,
            name: x.product_name
          },
          status: status,
          ecoPackStatus: x.is_cookx_eco_pack
        });
      });

      state.recipes = {
        code: result.code,
        complete: true,
        data: recipes,
        message: null,
        pagination: {
          currentPage: p.current_page,
          total: p.total,
          perPage: p.per_page,
          lastPage: p.last_page
        }
      };
    } else {
      state.recipes = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message),
        pagination: {
          currentPage: 0,
          total: 0,
          perPage: 0,
          lastPage: 0
        }
      };
    }
  },
  [M_GET_RECIPE](state, result) {
    function assertSortByOrder(a, b) {
      if (a.order > b.order) return 1;
      if (a.order < b.order) return -1;
    }

    if (result.code === 0) {
      let d = result.data;
      let status = null;
      let subRouterName = null;
      let filterCategoryName = null;

      if (!d.verified_at && !d.deleted_at) {
        status = RecipeStatus.PENDING;
      } else if (d.verified_at && !d.deleted_at) {
        status = RecipeStatus.VERIFIED;
      } else if (d.deleted_at) {
        status = RecipeStatus.DELETED;
      }

      switch (d.filter_category) {
        case 'ALL':
          filterCategoryName = 'ALL';
          subRouterName = ROUTE_NAME.EXPERIENCE_COOKING_ALL;
          break;
        case 'TRENDING':
          filterCategoryName = 'TRENDING';
          subRouterName = ROUTE_NAME.EXPERIENCE_COOKING_TRENDING;
          break;
        case 'BAKERY':
          filterCategoryName = 'BAKERY';
          subRouterName = ROUTE_NAME.EXPERIENCE_COOKING_BAKERY;
          break;
        case 'QUICK_AND_EASY':
          filterCategoryName = 'QUICK AND EASY';
          subRouterName = ROUTE_NAME.EXPERIENCE_COOKING_QUICK_EASY;
          break;
        case 'COOK_LIKE_AN_EXPERT':
          filterCategoryName = 'COOK LIKE AN EXPERT';
          subRouterName = ROUTE_NAME.EXPERIENCE_COOKING_COOK_LIKE_AND_EXPERT;
      }

      const calculateDay = (hours) => {
        return Math.floor(hours / 24);
      };
      const calculateHour = (hours) => {
        return hours % 24;
      };

      state.recipe = {
        code: result.code,
        complete: true,
        data: {
          id: d.id,
          uuid: d.uuid,
          userId: d.user_id,
          name: d.name,
          draftName: d.draft_name ? d.draft_name : '-',
          description: d.description,
          minutesToCook: d.minutes_to_cook ? d.minutes_to_cook : '-',
          cookingLevel: constantsDescription('cookingLevel', d.cooking_level),
          servePax: d.serve_pax,
          cuisineType: constantsDescription('cuisineType', d.cuisine_type),
          foodCategory: constantsDescription('foodCategory', d.food_category),
          foodType: constantsDescription('foodType', d.food_type),
          filterType: (
            constantsDescription('cuisineType', d.cuisine_type) +
            ',' +
            constantsDescription('filterType', d.filter_type)
          ).split(','),
          priceRange: constantsDescription('priceRange', d.price_range),
          price: currencyFormat(d.price),
          ecoPackType: d.eco_pack_type,
          ecoPackOption: constantsDescription(
            'ecoPackOption',
            d.eco_pack_option
          ),
          ecoPackOfferedAt: d.cookx_eco_pack_offered_at,
          isEdit: Boolean(!d.is_allow_creator_edit_cookx_eco_pack),
          availableFrom: dateTimeFormat(d.available_from),
          availableTo: dateTimeFormat(d.available_to),
          promotionPrice: currencyFormat(d.promotion_price),
          sellingQuantity: `${constantsDescription('limitType', d.limit_type)}${
            d.quantity != null ? ',' + Number(d.quantity) : ''
          }`,
          tips: d.tips ? d.tips : '-',
          isCookxEcoPack: Boolean(d.is_cookx_eco_pack),
          isPopular: d.is_popular,
          isTrending: d.is_trending,
          isTop10: d.is_top10,
          isRecommended: d.is_recommended,
          isRated: d.is_rated,
          isPublished: Boolean(d.published_at),
          isVerified: Boolean(d.verified_at),
          isRejected: Boolean(d.rejected_at),
          isActive: d.is_active,
          filterCategory: d.filter_category,
          filterCategoryName: filterCategoryName,
          createdAt: dateTimeFormat(d.created_at),
          updatedAt: dateTimeFormat(d.updated_at),
          publishedAt: dateTimeFormat(d.published_at),
          verifiedAt: dateTimeFormat(d.verified_at),
          rejectedAt: dateTimeFormat(d.rejected_at),
          rejectedReason: d.rejected_reason ? d.rejected_reason : '-',
          deletedAt: dateTimeFormat(d.deleted_at),
          remark: d.remark ? d.remark : '-',
          deliveryMethod: getDeliveryMethod(d.delivery_method),
          isVisible: Boolean(d.is_visible),
          username: d.username,
          displayPrice: currencyFormat(d.display_price),
          averageRating: d.average_rating
            ? Number(d.average_rating.toString().substr(0, 4))
            : 0,
          productId: d.product_id,
          product: {
            id: d.product_id,
            name: d.product_name
          },
          status: status,
          assets: d.assets
            .map((x) => {
              return {
                id: x.id,
                mediaPath: x.media_path,
                mediaType: x.media_type,
                timer: x.timer,
                order: x.order
              };
            })
            .sort(assertSortByOrder),
          originalThumbnail: d.original_thumbnail,
          thumbnail: d.thumbnail,
          creatorApplicationStatus: d.creator_application_status,
          userObject: {
            id: d.user_id,
            name: d.username
          },
          vehicleType: getVehicleType(d.delivery_method),
          personInCharge: d.person_in_charge ? d.person_in_charge : '-',
          subRouterName: subRouterName,
          ecoPackStatus: d.eco_pack_status,
          ecoPackSellingStatus: d.eco_pack_selling_status,
          ecoPackSelling: {
            recipe: d.name,
            ecoPackPrice: d.price,
            promoPrice: d.promotion_price,
            vehicleType: d.delivery_method?.some((val) => val == 3) ? 3 : 2,
            sellingQuantity: {
              type: d.limit_type,
              value: d.quantity
            },
            deliveryObj: {
              deliveryMethod: d.delivery_method ?? [2],
              pickupBufferDay: calculateDay(d.pickup_buffer_hour),
              pickupBufferHour: calculateHour(d.pickup_buffer_hour),
              deliveryBufferDay: calculateDay(d.delivery_buffer_hour),
              deliveryBufferHour: calculateHour(d.delivery_buffer_hour)
            },
            sellingPeriodFrom: dateFormat(d.available_from),
            sellingPeriodTo: dateFormat(d.available_to)
          },
          variations: d.variations
            ? d.variations.map((x) => {
                return {
                  name: x.name,
                  isActive: x.is_active,
                  variationRequirement: {
                    noOfRequirement: x.variation_requirement.no_of_requirement,
                    isActive: x.variation_requirement.is_active,
                    activeVariationOptions: x.variation_options.filter(
                      (x) => x.is_active === true
                    ).length
                  },
                  variationOptions: x.variation_options.map((x) => {
                    return {
                      name: x.name,
                      price: x.price,
                      isActive: x.is_active,
                      uuid: x.uuid
                    };
                  })
                };
              })
            : null
        },
        message: null
      };
    } else {
      state.recipe = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_GET_RECIPE_INGREDIENTS](state, result) {
    if (result.code === 0) {
      let p = result.pagination;
      let ingredients = [];

      result.data.forEach((x, index) => {
        ingredients.push({
          no: index + 1,
          id: x.id,
          uuid: x.uuid,
          recipeId: x.recipe_id,
          count: x.count,
          unit: constantsDescription('unitType', x.unit),
          name: x.name,
          price: x.price,
          createdAt: dateTimeFormat(x.created_at),
          updatedAt: dateTimeFormat(x.updated_at)
        });
      });

      state.ingredients = {
        code: result.code,
        complete: true,
        data: ingredients,
        message: null,
        pagination: {
          currentPage: p.current_page,
          total: p.total,
          perPage: p.per_page,
          lastPage: p.last_page
        }
      };
    } else {
      state.ingredients = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message),
        pagination: {
          currentPage: 0,
          total: 0,
          perPage: 0,
          lastPage: 0
        }
      };
    }
  },
  [M_GET_RECIPE_EQUIPMENT](state, result) {
    if (result.code === 0) {
      let p = result.pagination;
      let equipment = [];

      result.data.forEach((x, index) => {
        equipment.push({
          no: index + 1,
          id: x.id,
          uuid: x.uuid,
          recipeId: x.recipe_id,
          count: x.count,
          status: x.status,
          unit: constantsDescription('unitType', x.unit),
          name: x.name,
          price: x.offer_price,
          createdAt: dateTimeFormat(x.created_at),
          updatedAt: dateTimeFormat(x.updated_at),
          equipmentId: x.equipment_id,
          sellingPeriodFrom: dateTimeFormat(x.available_from),
          sellingPeriodTo: dateTimeFormat(x.available_to)
        });
      });

      state.equipment = {
        code: result.code,
        complete: true,
        data: equipment,
        message: null,
        pagination: {
          currentPage: p.current_page,
          total: p.total,
          perPage: p.per_page,
          lastPage: p.last_page
        }
      };
    } else {
      state.equipment = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message),
        pagination: {
          currentPage: 0,
          total: 0,
          perPage: 0,
          lastPage: 0
        }
      };
    }
  },
  [M_GET_RECIPE_INSTRUCTIONS](state, result) {
    if (result.code === 0) {
      let p = result.pagination;
      let instructions = [];

      result.data.forEach((x, index) => {
        instructions.push({
          no: index + 1,
          id: x.id,
          recipeId: x.recipe_id,
          step: x.step,
          title: x.title,
          description: x.description,
          createdAt: dateTimeFormat(x.created_at),
          updatedAt: dateTimeFormat(x.updated_at),
          image: x.image
        });
      });

      state.instructions = {
        code: result.code,
        complete: true,
        data: instructions,
        message: null,
        pagination: {
          currentPage: p.current_page,
          total: p.total,
          perPage: p.per_page,
          lastPage: p.last_page
        }
      };
    } else {
      state.instructions = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message),
        pagination: {
          currentPage: 0,
          total: 0,
          perPage: 0,
          lastPage: 0
        }
      };
    }
  },
  [M_UPDATE_RECIPE_INGREDIENT](state, result) {
    state.updateIngredient = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_UPDATE_RECIPE_EQUIPMENT](state, result) {
    state.updateEquipment = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_UPDATE_TERMINATE_ECOPACK](state, result) {
    state.updateTerminateEcoPack = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_UPDATE_REQUEST_ECOPACK](state, result) {
    state.updateRequestEcoPack = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_UPDATE_ECO_PACK_VARIATIONS](state, result) {
    state.updateEcoPackVariations = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_VERIFY_RECIPE](state, result) {
    state.verifyRecipe = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_SET_VISIBILITY](state, result) {
    state.setVisibility = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_SET_EDIT_ECOPACK](state, result) {
    state.setEditEcopack = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_SET_REQUEST_ECOPACK](state, result) {
    state.setRequestEcopack = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_UPDATE_RECIPE](state, result) {
    state.updateRecipe = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_REJECT_RECIPE](state, result) {
    state.rejectRecipe = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_REJECT_ECOPACK](state, result) {
    state.rejectEcoPack = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_APPROVE_ECOPACK](state, result) {
    state.approveEcoPack = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_NOTIFY_CREATOR_ECOPACK](state, result) {
    state.notifyCreatorEcoPack = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_INITIAL_GET_RECIPES_STATE](state) {
    state.recipes = {
      code: 0,
      complete: false,
      data: null,
      message: null,
      pagination: {
        currentPage: 0,
        total: 0,
        perPage: 0,
        lastPage: 0
      }
    };
  },
  [M_INITIAL_GET_RECIPE_STATE](state) {
    state.recipe = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_GET_RECIPE_INGREDIENTS_STATE](state) {
    state.ingredients = {
      code: 0,
      complete: false,
      data: null,
      message: null,
      pagination: {
        currentPage: 0,
        total: 0,
        perPage: 0,
        lastPage: 0
      }
    };
  },
  [M_INITIAL_GET_RECIPE_EQUIPMENT_STATE](state) {
    state.equipment = {
      code: 0,
      complete: false,
      data: null,
      message: null,
      pagination: {
        currentPage: 0,
        total: 0,
        perPage: 0,
        lastPage: 0
      }
    };
  },
  [M_INITIAL_GET_RECIPE_INSTRUCTIONS_STATE](state) {
    state.instructions = {
      code: 0,
      complete: false,
      data: null,
      message: null,
      pagination: {
        currentPage: 0,
        total: 0,
        perPage: 0,
        lastPage: 0
      }
    };
  },
  [M_INITIAL_UPDATE_RECIPE_INGREDIENT_STATE](state) {
    state.updateIngredient = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_UPDATE_RECIPE_EQUIPMENT_STATE](state) {
    state.updateEquipment = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_UPDATE_TERMINATE_ECOPACK_STATE](state) {
    state.updateTerminateEcoPack = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_UPDATE_REQUEST_ECOPACK_STATE](state) {
    state.updateRequestEcoPack = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_UPDATE_ECO_PACK_VARIATIONS_STATE](state) {
    state.updateEcoPackVariations = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_VERIFY_RECIPE_STATE](state) {
    state.verifyRecipe = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_SET_VISIBILITY_STATE](state) {
    state.setVisibility = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_SET_EDIT_ECOPACK_STATE](state) {
    state.setEditEcopack = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_SET_REQUEST_ECOPACK_STATE](state) {
    state.setRequestEcopack = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_UPDATE_RECIPE](state) {
    state.updateRecipe = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_REJECT_RECIPE_STATE](state) {
    state.rejectRecipe = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_REJECT_ECOPACK_STATE](state) {
    state.rejectEcoPack = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_APPROVE_ECOPACK_STATE](state) {
    state.approveEcoPack = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_NOTIFY_CREATOR_ECOPACK_STATE](state) {
    state.notifyCreatorEcoPack = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export function getDeliveryMethod(data) {
  var deliveryMethod = data;
  data
    ? (deliveryMethod = constantsDescription('deliveryMethod', deliveryMethod))
    : (deliveryMethod = '-');

  return deliveryMethod;
}

export function getVehicleType(data) {
  var vehicle = null;
  var isDelivery = false;

  if (data) {
    data.forEach((x) => {
      if (
        x == DeliveryMethod.PRE_ORDER ||
        x == DeliveryMethod.DELIVERY_BY_CAR
      ) {
        isDelivery = true;
      }
    });
  } else {
    vehicle = '-';
  }

  if (isDelivery) {
    data.forEach((x) => {
      if ([VehicleType.CAR].includes(x)) {
        vehicle = i18nHelper.getMessage(`enumVehicleType.${x}`);
      }
    });

    if (!vehicle) {
      vehicle = i18nHelper.getMessage(
        `enumVehicleType.${VehicleType.ANY_VEHICLE}`
      );
    }
  } else {
    vehicle = '-';
  }
  return vehicle;
}
