<template>
  <div>
    <b-card data-style="custom">
      <b-card-title class="text-capitalize font-weight-bolder">
        {{ $t('label.auditLogs') }}
      </b-card-title>
      <!--begin: App Smart Table-->
      <AppSmartTable
        :items="tableItems"
        :headers="tableHeaders"
        :pagination="tablePagination"
        @app-smart-table-pagination-change="actionPaginationChange"
        @app-table-read-details="actionReadDetails"
      ></AppSmartTable>
      <!--end: App Smart Table-->
    </b-card>

    <!--begin: App Modal Description List-->
    <AppModalDescriptionList
      v-model="appModalDescriptionListObj.show"
      :title="appModalDescriptionListObj.title"
      :descriptions="appModalDescriptionListObj.descriptions"
      :dataObject="appModalDescriptionListObj.dataObject"
    ></AppModalDescriptionList>
    <!--end: App Modal Description List-->
  </div>
</template>

<script>
  import AppSmartTable from '@/views/components/bases/smart/AppSmartTable';
  import AppModalDescriptionList from '@/views/components/bases/modal/AppModalDescriptionList';
  import commonMixin from '@/core/mixins/common.mixin';
  import { GLOBAL, APP_COMPONENT_NAME } from '@/core/constants';
  import { AUDIT_LOG_TYPE } from '@/core/constants/enums';
  import { i18nHelper } from '@/core/utils';
  import {
    AUDIT_LOG_GET_AUDIT_LOGS,
    AUDIT_LOG_GET_AUDIT_LOG,
    AUDIT_LOG_INITIAL_GET_AUDIT_LOGS_STATE,
    AUDIT_LOG_INITIAL_GET_AUDIT_LOG_STATE
  } from '@/core/store/audit-log.module';

  export default {
    name: 'AuditLogTable',
    components: {
      AppSmartTable,
      AppModalDescriptionList
    },
    mixins: [commonMixin],
    props: {
      type: {
        type: Number,
        default: null
      },
      modelId: {
        type: Number,
        default: null
      }
    },
    data: () => ({
      searchCriteria: GLOBAL.DEFAULT_SEARCH_CRITERIA,
      tableHeaders: [
        {
          key: 'no',
          name: 'no',
          sortable: true
        },
        {
          key: 'adminUsername',
          name: 'performedBy',
          sortable: true
        },
        {
          key: 'action',
          name: 'action',
          sortable: true
        },
        {
          key: 'createdAt',
          name: 'actionDateTime',
          sortable: true
        },
        {
          key: 'actions',
          name: 'actions',
          active: true,
          action: true,
          layout: APP_COMPONENT_NAME.APP_TABLE_ACTIONS
        }
      ],
      tableItems: [],
      tablePagination: GLOBAL.DEFAULT_TABLE_PAGINATION,
      appModalDescriptionListObj: {
        show: false,
        title: '',
        descriptions: [],
        dataObject: {}
      }
    }),
    computed: {
      auditLogsComplete() {
        return this.$store.state.auditLog.auditLogs.complete;
      },
      auditLogComplete() {
        return this.$store.state.auditLog.auditLog.complete;
      }
    },
    watch: {
      auditLogsComplete() {
        var response = this.$store.state.auditLog.auditLogs;
        var title = i18nHelper.getMessage('label.getAuditLogs');
        var initialStateAction = AUDIT_LOG_INITIAL_GET_AUDIT_LOGS_STATE;
        var successAction = (response) => {
          this.importListData(response);
        };

        if (response.complete)
          this.actionReadRecordComplete(
            response,
            title,
            initialStateAction,
            successAction
          );
      },
      auditLogComplete() {
        var response = this.$store.state.auditLog.auditLog;
        var title = i18nHelper.getMessage('label.getAuditLog');
        var initialStateAction = AUDIT_LOG_INITIAL_GET_AUDIT_LOG_STATE;
        var successAction = (response) => {
          this.openDetailsModal(response);
        };

        if (response.complete)
          this.actionReadRecordComplete(
            response,
            title,
            initialStateAction,
            successAction
          );
      }
    },
    created() {
      this.initAuditLogTable();
    },
    methods: {
      actionReadDetails(id) {
        this.getAuditLog(id);
      },
      actionPaginationChange(value) {
        this.searchCriteria.perPage = value.size;
        this.searchCriteria.page = value.page;

        this.getAuditLogs(this.searchCriteria);
      },
      openDetailsModal(response) {
        var descriptions = new Array();
        var type = this.type;

        if ([AUDIT_LOG_TYPE.USER, AUDIT_LOG_TYPE.ORDER].includes(type)) {
          descriptions = [
            {
              label: 'performedBy',
              key: 'adminUsername'
            },
            {
              label: 'action',
              key: 'action'
            },
            {
              label: 'changes',
              key: 'changes',
              layout: 'AuditLogCompareTable'
            },
            {
              label: 'actionDateTime',
              key: 'createdAt'
            }
          ];
        }

        this.appModalDescriptionListObj = {
          show: true,
          title: i18nHelper.getMessage('label.auditLog'),
          descriptions: descriptions,
          dataObject: response.data
        };
      },
      importListData(response) {
        this.tableItems = response.data;
        this.tablePagination = response.pagination;
      },
      getAuditLog(id) {
        this.$store.dispatch(AUDIT_LOG_GET_AUDIT_LOG, { id });
      },
      getAuditLogs(data) {
        this.$store.dispatch(AUDIT_LOG_GET_AUDIT_LOGS, { data });
      },
      initAuditLogTable() {
        this.searchCriteria.type = this.type;
        this.searchCriteria.modelId = this.modelId;
        this.getAuditLogs(this.searchCriteria);
      }
    }
  };
</script>

<style></style>
