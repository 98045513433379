<template>
  <label
    class="label label-md label-inline"
    :class="`label-light-${creatorApplicationStatus2Color(value.value)}`"
  >
    {{ value.name }}
  </label>
</template>

<script>
  import { CreatorApplicationStatus2 } from '@/core/constants/enums';

  export default {
    name: 'ApplicationStatusLabel',
    props: ['value'],
    methods: {
      creatorApplicationStatus2Color(value) {
        let color = {
          [CreatorApplicationStatus2.PENDING]: () => 'primary',
          [CreatorApplicationStatus2.DOCUMENT_PENDING]: () => 'primary',
          [CreatorApplicationStatus2.PENDING_APPROVED]: () => 'warning',
          [CreatorApplicationStatus2.APPROVED]: () => 'success',
          [CreatorApplicationStatus2.REJECTED]: () => 'danger'
        };
        return color[value]();
      }
    }
  };
</script>

<style></style>
