<template>
  <div>
    <b-row no-gutters>
      <b-col cols="6">
        <div class="wizard-label">
          <h3 class="wizard-title text-capitalize">
            {{ $t(`label.configWATIwhatsappTesting`) }}
          </h3>
          <div class="wizard-desc">
            {{ $t(`message.configWATIwhatsappTestingMessage`) }}
          </div>
        </div>
      </b-col>
      <b-col cols="6" class="d-flex align-items-center">
        <AppFormField
          v-model="watiTestingObj.status"
          :formType="'singleCheckbox'"
          :disabled="false"
          :size="'lg'"
          style="transform: scale(1.5); position: absolute; left: 10px"
          noTitle
          @change="this.clickedToggle"
        >
        </AppFormField>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import commonMixin from '@/core/mixins/common.mixin';
  import AppFormField from '@/views/components/bases/form-field/AppFormField';
  import { i18nHelper } from '@/core/utils';
  import { MODULE, ACTION } from '@/core/constants';
  import {
    GENERAL_GET_WATI_TESTING,
    GENERAL_INITIAL_GET_WATI_TESTING_STATE,
    GENERAL_UPDATE_WATI_TESTING,
    GENERAL_INITIAL_UPDATE_WATI_TESTING_STATE
  } from '@/core/store/configuration/config-wati-testing.module';

  export default {
    name: 'ConfigWATIWhatsappTesting',
    mixins: [commonMixin],
    components: {
      AppFormField
    },
    data: () => ({
      accessRights: {
        moduleName: MODULE.CONFIGURATION,
        actions: {
          create: ACTION.CONFIGURATION_CREATE,
          read: ACTION.CONFIGURATION_READ,
          update: ACTION.CONFIGURATION_UPDATE,
          delete: ACTION.CONFIGURATION_DELETE
        }
      },
      watiTestingObj: {}
    }),
    computed: {
      getWATIwhatsappTestingComplete() {
        return this.$store.state.configWATITesting.getWATITesting.complete;
      },
      updateWATIwhatsappTestingComplete() {
        return this.$store.state.configWATITesting.updateWATITesting.complete;
      }
    },
    watch: {
      getWATIwhatsappTestingComplete() {
        let response = this.$store.state.configWATITesting.getWATITesting;
        let title = i18nHelper.getMessage('label.configWATIwhatsappTesting');
        let initialStateAction = GENERAL_INITIAL_GET_WATI_TESTING_STATE;
        let successAction = (response) => {
          this.importWatiTestingObj(response);
        };
        if (response.complete) {
          this.actionReadRecordComplete(
            response,
            title,
            initialStateAction,
            successAction
          );
        }
      },
      updateWATIwhatsappTestingComplete() {
        let response = this.$store.state.configWATITesting.updateWATITesting;
        let title = i18nHelper.getMessage('label.configWATIwhatsappTesting');
        let initialStateAction = GENERAL_INITIAL_UPDATE_WATI_TESTING_STATE;
        let successAction = (response) => {
          this.getWATIwhatsappTesting(response);
        };
        if (response.complete) {
          this.actionReadRecordComplete(
            response,
            title,
            initialStateAction,
            successAction
          );
        }
      }
    },
    mounted() {
      this.initialConfiguration();
    },
    methods: {
      clickedToggle(val) {
        this.updateWATIwhatsappTesting(val);
      },
      importWatiTestingObj(response) {
        this.watiTestingObj = response.data[0];
      },
      getWATIwhatsappTesting() {
        this.$store.dispatch(GENERAL_GET_WATI_TESTING);
      },
      updateWATIwhatsappTesting(data) {
        this.$store.dispatch(GENERAL_UPDATE_WATI_TESTING, { data });
      },
      initialConfiguration() {
        let breadcrumbData = {
          breadcrumbs: [
            {
              title: i18nHelper.getMessage('label.configuration')
            }
          ]
        };
        this.getWATIwhatsappTesting();
        this.initBreadCrumb(breadcrumbData);
      }
    }
  };
</script>

<style></style>
