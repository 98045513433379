<template>
  <div>
    <!--begin: Details Section-->
    <b-row no-gutters class="w-100 mt-4">
      <!--begin: Col 1-->
      <b-col cols="12" md="4">
        <!--begin: XStreet Basic Info-->

        <b-card no-body>
          <b-card-body>
            <b-card-title class="text-capitalize font-weight-bolder">
              {{ $t('label.basicInformation') }}
            </b-card-title>

            <b-card-text>
              <template v-for="(i, index) in basicInfoList">
                <div :key="i + index" class="mb-5">
                  <div class="font-weight-bolder text-capitalize mb-2">
                    {{ $t(`label.${i.name}`) }}:
                  </div>
                  <component
                    v-if="i.layout"
                    :is="i.layout"
                    :value="xStreetObj[i.key]"
                    :id="id"
                    :disabled="
                      !checkAccessRight(moduleName, [
                        permissionAction.XSTREET_UPDATE
                      ])
                    "
                    :storeActionsObj="visibilityStatusStoreActionsObj"
                  >
                  </component>

                  <div
                    v-else
                    class="text-gray-600"
                    :class="[{ 'white-space-pre ': i.key == 'description' }]"
                  >
                    {{ xStreetObj[i.key] }}
                  </div>
                </div>
                <div
                  v-if="i.key == 'isVisible'"
                  :key="index + 'separator'"
                  class="separator separator-solid my-6"
                ></div>
              </template>
            </b-card-text>
          </b-card-body>
        </b-card>
        <!--end: XStreet Basic Info-->
      </b-col>
      <!--end: Col 1-->

      <!--begin: Col 2-->
      <b-col cols="12" md="8" class="mt-4 mt-md-0 pl-md-4 pl-lg-8">
        <!--begin: Media-->
        <b-card no-body>
          <b-card-body>
            <b-card-title class="text-capitalize font-weight-bolder">
              {{ $t('label.media') }}
            </b-card-title>

            <div>
              <AppImageViewer
                v-if="xStreetObj.imageUrl"
                :value="xStreetObj.imageUrl"
              ></AppImageViewer>
              <span v-else>{{ $t('label.thereAreNoImagesToShow') }}</span>
            </div>
          </b-card-body>
        </b-card>
        <!--end: Media-->
      </b-col>
      <!--end Col 2-->
    </b-row>
    <!--end: Details Section-->
  </div>
</template>

<script>
  import AppVisibilityStatusButton from '@/views/components/bases/AppVisibilityStatusButton';
  import AppImageViewer from '@/views/components/bases/image-viewer/AppImageViewer';
  import commonMixin from '@/core/mixins/common.mixin';
  import {
    ROUTE_NAME,
    MODULE,
    ACTION,
    APP_COMPONENT_NAME
  } from '@/core/constants';
  import { i18nHelper } from '@/core/utils';
  import {
    XSTREET_GET_XSTREET_POST,
    XSTREET_SET_VISIBILITY,
    XSTREET_INITIAL_GET_XSTREET_POST_STATE,
    XSTREET_INITIAL_SET_VISIBILITY_STATE
  } from '@/core/store/xstreet.module';

  export default {
    name: 'XStreetDetails',
    mixins: [commonMixin],
    components: {
      AppVisibilityStatusButton,
      AppImageViewer
    },
    data: () => ({
      routeName: ROUTE_NAME,
      moduleName: MODULE.XSTREET,
      permissionAction: ACTION,
      id: null,
      xStreetObj: {},
      basicInfoList: [
        {
          key: 'title',
          name: 'title'
        },
        {
          key: 'description',
          name: 'description'
        },
        {
          key: 'username',
          name: 'createdBy'
        },
        {
          key: 'isVisible',
          name: 'visibility',
          layout: APP_COMPONENT_NAME.APP_VISIBILITY_STATUS_BUTTON
        },
        {
          key: 'createdAt',
          name: 'createdAt'
        },
        {
          key: 'updatedAt',
          name: 'updatedAt'
        }
      ],
      visibilityStatusStoreActionsObj: {
        module: 'xstreet',
        state: 'setVisibility',
        actionSet: XSTREET_SET_VISIBILITY,
        actionInitial: XSTREET_INITIAL_SET_VISIBILITY_STATE,
        successAction: () => {},
        title: i18nHelper.getMessage('label.setVisibility'),
        message: i18nHelper.getMessage('label.bulletinPost')
      }
    }),
    computed: {
      xStreetPostComplete() {
        return this.$store.state.xstreet.xStreetPost.complete;
      }
    },
    watch: {
      xStreetPostComplete() {
        let response = this.$store.state.xstreet.xStreetPost;
        let title = i18nHelper.getMessage('label.getBulletinPostDetails');
        let initialStateAction = XSTREET_INITIAL_GET_XSTREET_POST_STATE;
        let successAction = (response) => {
          this.importXStreetObj(response);
        };

        if (response.complete) {
          this.actionReadRecordComplete(
            response,
            title,
            initialStateAction,
            successAction
          );
        }
      }
    },
    created() {
      this.initialXStreetDetails();
    },
    methods: {
      importXStreetObj(response) {
        this.xStreetObj = response.data;
      },
      getXStreetPost(id) {
        this.$store.dispatch(XSTREET_GET_XSTREET_POST, {
          id
        });
      },
      importSuccessActions() {
        this.visibilityStatusStoreActionsObj.successAction = () => {
          this.initialXStreetDetails();
        };
      },
      initialXStreetDetails() {
        let id = Number(this.$route.params.id);
        let breadcrumbData = {
          breadcrumbs: [
            {
              title: i18nHelper.getMessage('label.bulletin'),
              route: { name: ROUTE_NAME.XSTREET }
            },
            { title: i18nHelper.getMessage('label.bulletinDetails') }
          ],
          actions: [
            {
              text: i18nHelper.getMessage('label.back'),
              function: () => {
                this.$router.go(-1);
              }
            }
          ]
        };

        this.id = id;
        this.getXStreetPost(id);
        this.initBreadCrumb(breadcrumbData);
        this.importSuccessActions();
        window.KTUtil.scrollTop();
      }
    }
  };
</script>

<style lang="scss"></style>
