import dateTimeFormat from '@/core/filters/dateTimeFormat.filter';
import contactNumber from '@/core/filters/contact-number-format.filter';
import {
  CreatorApplicationStatus2,
  CreatorApplicationDocumentType
} from '@/core/constants/enums';
import { CREATOR_APPLICATION } from '@/core/api';
import { apiHelper, i18nHelper } from '@/core/utils';
import {
  LOADING_OPEN_LOADING,
  LOADING_CLOSE_LOADING
} from '@/core/store/loading.module';

/* Define Module Name */
const MODULE_NAME = 'creatorApplication/';

/* Define Action Name */
const A_GET_CREATOR_APPLICATIONS = 'getCreatorApplications';
const A_GET_CREATOR_APPLICATION = 'getCreatorApplication';
const A_APPROVE_CREATOR_APPLICATION = 'approveCreatorApplication';
const A_REJECT_CREATOR_APPLICATION = 'rejectCreatorApplication';
const A_GET_CREATOR_DOCUMENT = 'getCreatorDocument';
const A_UPLOAD_DOCUMENT = 'uploadDocument';
const A_REJECT_REASON = 'rejectReason';

/* Define Reset State Action Name */
const A_INITIAL_GET_CREATOR_APPLICATIONS_STATE =
  'initialGetCreatorApplicationsState';
const A_INITIAL_GET_CREATOR_APPLICATION_STATE =
  'initialGetCreatorApplicationState';
const A_INITIAL_APPROVE_CREATOR_APPLICATION_STATE =
  'initialApproveCreatorApplicationState';
const A_INITIAL_REJECT_CREATOR_APPLICATION_STATE =
  'initialRejectCreatorApplicationState';
const A_INITIAL_GET_CREATOR_DOCUMENT_STATE = 'initialGetCreatorDocumentState';
const A_INITIAL_UPLOAD_DOCUMENT_STATE = 'initialUploadDocumentState';
const A_INITIAL_REJECT_REASON_STATE = 'initialRejectReasonState';

/* Define Getter Name */

/* Define Mutation Name */
const M_GET_CREATOR_APPLICATIONS = 'setGetCreatorApplications';
const M_GET_CREATOR_APPLICATION = 'setGetCreatorApplication';
const M_APPROVE_CREATOR_APPLICATION = 'setApproveCreatorApplication';
const M_REJECT_CREATOR_APPLICATION = 'setRejectCreatorApplication';
const M_GET_CREATOR_DOCUMENT = 'setGetCreatorDocument';
const M_UPLOAD_DOCUMENT = 'setUploadDocument';
const M_REJECT_REASON = 'setRejectReason';

/* Define Reset State Mutation Name */
const M_INITIAL_GET_CREATOR_APPLICATIONS_STATE =
  'setInitialGetCreatorApplicationsState';
const M_INITIAL_GET_CREATOR_APPLICATION_STATE =
  'setInitialGetCreatorApplicationState';
const M_INITIAL_APPROVE_CREATOR_APPLICATION_STATE =
  'setInitialApproveCreatorApplicationState';
const M_INITIAL_REJECT_CREATOR_APPLICATION_STATE =
  'setInitialRejectCreatorApplicationState';
const M_INITIAL_GET_CREATOR_DOCUMENT_STATE =
  'setInitialGetCreatorDocumentState';
const M_INITIAL_UPLOAD_DOCUMENT_STATE = 'setInitialUploadDocumentState';
const M_INITIAL_REJECT_REASON_STATE = 'setInitialRejectReasonState';

/* Define Export Name */
export const CREATOR_APPLICATION_GET_CREATOR_APPLICATIONS =
  MODULE_NAME + A_GET_CREATOR_APPLICATIONS;
export const CREATOR_APPLICATION_GET_CREATOR_APPLICATION =
  MODULE_NAME + A_GET_CREATOR_APPLICATION;
export const CREATOR_APPLICATION_APPROVE_CREATOR_APPLICATION =
  MODULE_NAME + A_APPROVE_CREATOR_APPLICATION;
export const CREATOR_APPLICATION_REJECT_CREATOR_APPLICATION =
  MODULE_NAME + A_REJECT_CREATOR_APPLICATION;
export const CREATOR_APPLICATION_GET_CREATOR_DOCUMENT =
  MODULE_NAME + A_GET_CREATOR_DOCUMENT;
export const CREATOR_APPLICATION_UPLOAD_DOCUMENT =
  MODULE_NAME + A_UPLOAD_DOCUMENT;
export const CREATOR_APPLICATION_REJECT_REASON = MODULE_NAME + A_REJECT_REASON;

/* Define Reset State Name */
export const CREATOR_APPLICATION_INITIAL_GET_CREATOR_APPLICATIONS_STATE =
  MODULE_NAME + A_INITIAL_GET_CREATOR_APPLICATIONS_STATE;
export const CREATOR_APPLICATION_INITIAL_GET_CREATOR_APPLICATION_STATE =
  MODULE_NAME + A_INITIAL_GET_CREATOR_APPLICATION_STATE;
export const CREATOR_APPLICATION_INITIAL_APPROVE_CREATOR_APPLICATION_STATE =
  MODULE_NAME + A_INITIAL_APPROVE_CREATOR_APPLICATION_STATE;
export const CREATOR_APPLICATION_INITIAL_REJECT_CREATOR_APPLICATION_STATE =
  MODULE_NAME + A_INITIAL_REJECT_CREATOR_APPLICATION_STATE;
export const CREATOR_APPLICATION_INITIAL_GET_CREATOR_DOCUMENT_STATE =
  MODULE_NAME + A_INITIAL_GET_CREATOR_DOCUMENT_STATE;
export const CREATOR_APPLICATION_INITIAL_UPLOAD_DOCUMENT_STATE =
  MODULE_NAME + A_INITIAL_UPLOAD_DOCUMENT_STATE;
export const CREATOR_APPLICATION_INITIAL_REJECT_REASON_STATE =
  MODULE_NAME + A_INITIAL_REJECT_REASON_STATE;

const state = {
  creatorApplications: {
    code: 0,
    complete: false,
    data: null,
    message: null,
    pagination: {
      currentPage: 0,
      total: 0,
      perPage: 0,
      lastPage: 0
    }
  },
  creatorApplication: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  approveCreatorApplication: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  rejectCreatorApplication: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  uploadDocument: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  creatorDocument: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  rejectReason: {
    code: 0,
    complete: false,
    data: null,
    message: null
  }
};

//Getters
const getters = {};

// Actions
const actions = {
  async [A_GET_CREATOR_APPLICATIONS]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    await CREATOR_APPLICATION.getCreatorApplications(data)
      .then((respone) => {
        let result = respone;
        commit(M_GET_CREATOR_APPLICATIONS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_GET_CREATOR_APPLICATIONS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_CREATOR_APPLICATION]({ dispatch, commit }, { id }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    CREATOR_APPLICATION.getCreatorApplication(id)
      .then((respone) => {
        let result = respone;
        commit(M_GET_CREATOR_APPLICATION, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_GET_CREATOR_APPLICATION, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_APPROVE_CREATOR_APPLICATION]({ dispatch, commit }, { id }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    CREATOR_APPLICATION.approveCreatorApplication(id)
      .then((respone) => {
        let result = respone;
        commit(M_APPROVE_CREATOR_APPLICATION, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_APPROVE_CREATOR_APPLICATION, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_REJECT_CREATOR_APPLICATION]({ dispatch, commit }, { id, data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    CREATOR_APPLICATION.rejectCreatorApplication(id, data)
      .then((respone) => {
        let result = respone;
        commit(M_REJECT_CREATOR_APPLICATION, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_REJECT_CREATOR_APPLICATION, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_CREATOR_DOCUMENT]({ dispatch, commit }, { id, data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    CREATOR_APPLICATION.creatorDocument(id, data)
      .then((response) => {
        let result = response;
        commit(M_GET_CREATOR_DOCUMENT, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_GET_CREATOR_DOCUMENT, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_UPLOAD_DOCUMENT]({ dispatch, commit }, { id, data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    CREATOR_APPLICATION.uploadDocument(id, data)
      .then((response) => {
        let result = response;
        commit(M_UPLOAD_DOCUMENT, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_UPLOAD_DOCUMENT, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_REJECT_REASON]({ dispatch, commit }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    CREATOR_APPLICATION.getRejectReasonApplication()
      .then((response) => {
        let result = response;
        commit(M_REJECT_REASON, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_REJECT_REASON, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_INITIAL_GET_CREATOR_APPLICATIONS_STATE]({ commit }) {
    commit(M_INITIAL_GET_CREATOR_APPLICATIONS_STATE);
  },
  [A_INITIAL_GET_CREATOR_APPLICATION_STATE]({ commit }) {
    commit(M_INITIAL_GET_CREATOR_APPLICATION_STATE);
  },
  [A_INITIAL_APPROVE_CREATOR_APPLICATION_STATE]({ commit }) {
    commit(M_INITIAL_APPROVE_CREATOR_APPLICATION_STATE);
  },
  [A_INITIAL_REJECT_CREATOR_APPLICATION_STATE]({ commit }) {
    commit(M_INITIAL_REJECT_CREATOR_APPLICATION_STATE);
  },
  [A_INITIAL_GET_CREATOR_DOCUMENT_STATE]({ commit }) {
    commit(M_INITIAL_GET_CREATOR_DOCUMENT_STATE);
  },
  [A_INITIAL_UPLOAD_DOCUMENT_STATE]({ commit }) {
    commit(M_INITIAL_UPLOAD_DOCUMENT_STATE);
  },
  [A_INITIAL_REJECT_REASON_STATE]({ commit }) {
    commit(M_INITIAL_REJECT_REASON_STATE);
  }
};

// Mutations
const mutations = {
  [M_GET_CREATOR_APPLICATIONS](state, result) {
    if (result.code === 0) {
      let d = result.data;
      let p = result.pagination;
      let applications = [];

      d.forEach((x, index) => {
        let statusName;
        if (
          x.rejected_at == null &&
          x.approved_at == null &&
          x.submitted_at == null &&
          !x.is_document_exists
        ) {
          statusName = CreatorApplicationStatus2.DOCUMENT_PENDING;
        } else if (
          x.rejected_at == null &&
          x.approved_at == null &&
          x.submitted_at == null &&
          x.is_document_exists
        ) {
          statusName = CreatorApplicationStatus2.DOCUMENT_PENDING;
        } else if (
          x.rejected_at == null &&
          x.approved_at == null &&
          x.submitted_at != null &&
          x.is_document_exists
        ) {
          statusName = CreatorApplicationStatus2.PENDING_APPROVED;
        } else if (x.approved_at != null && x.rejected_at == null) {
          statusName = CreatorApplicationStatus2.APPROVED;
        } else if (x.approved_at == null && x.rejected_at != null) {
          statusName = CreatorApplicationStatus2.REJECTED;
        }

        applications.push({
          no: index + 1,
          id: x.id,
          username: x.username,
          storeName: x.store_name,
          creatorType: x.creator_type,
          isDocument: x.is_document_exists,
          submittedAt: dateTimeFormat(x.submitted_at),
          approvedAt: dateTimeFormat(x.approved_at),
          rejectedAt: dateTimeFormat(x.rejected_at),
          status: {
            value: statusName,
            name: i18nHelper.getMessage(
              `enumCreatorApplicationStatus[2].${statusName}`
            )
          }
        });
      });

      state.creatorApplications = {
        code: result.code,
        complete: true,
        data: applications,
        message: null,
        pagination: {
          currentPage: p.current_page,
          total: p.total,
          perPage: p.per_page,
          lastPage: p.last_page
        }
      };
    } else {
      state.creatorApplications = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message),
        pagination: {
          currentPage: 0,
          total: 0,
          perPage: 0,
          lastPage: 0
        }
      };
    }
  },
  [M_GET_CREATOR_APPLICATION](state, result) {
    if (result.code === 0) {
      var d = result.data;
      var address = d.address;
      var newAddress = new Object();

      if (address) {
        newAddress = {
          id: address.id,
          placeName: address.place_name,
          contactNumber: contactNumber(address.contact_number),
          contactEmail: address.contact_email,
          address: address.address,
          postcode: address.postcode,
          city: address.city,
          state: address.state,
          remark: address.remark ? address.remark : '-',
          latitude: address.latitude,
          longitude: address.longitude
        };
      }

      state.creatorApplication = {
        code: result.code,
        complete: true,
        data: {
          id: d.id,
          userId: d.user_id,
          username: d.username,
          storeName: d.store_name,
          creatorType: d.creator_type,
          profileImage: d.profile_image,
          creatorName: d.creator_name,
          backgroundImage: d.background_image,
          hashtag: d.hashtag ? d.hashtag.split(',') : '-',
          isApproved: d.approved_at ? true : false,
          isRejected: d.rejected_at ? true : false,
          submittedAt: dateTimeFormat(d.submitted_at),
          approvedAt: dateTimeFormat(d.approved_at),
          rejectedAt: dateTimeFormat(d.rejected_at),
          createdAt: dateTimeFormat(d.created_at),
          updatedAt: dateTimeFormat(d.updated_at),
          businessUrl: d.business_url,
          documents: getDocuments(d.documents),
          followerCount: d.follower_count,
          recipeCount: d.recipe_count,
          productCount: d.product_count,
          address: address ? newAddress : {},
          existingDocuments: getDocuments(d.existing_documents),
          personInCharge: d.person_in_charge ? d.person_in_charge : '-'
        },
        message: null
      };
    } else {
      state.creatorApplication = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_APPROVE_CREATOR_APPLICATION](state, result) {
    state.approveCreatorApplication = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_REJECT_CREATOR_APPLICATION](state, result) {
    state.rejectCreatorApplication = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_GET_CREATOR_DOCUMENT](state, result) {
    if (result.code === 0) {
      var d = result.data;

      state.creatorDocument = {
        code: result.code,
        complete: true,
        data: {
          creatorType: d.creator_type,
          icFront: d.ic_front,
          icBack: d.ic_back,
          bankStatement: d.bank_statement,
          halalCert: d.halal_cert,
          sstCert: d.sst_cert,
          businessLicense: d.document,
          documentExists: d.is_document_exists

          // creatorType: 0,
          // icFront:
          //   'https://cookx.sgp1.digitaloceanspaces.com/dev/creator-documents/3127/9752c581-4702-4bfc-a659-618e8742b8d7.png',
          // icBack:
          //   'https://cookx.sgp1.digitaloceanspaces.com/dev/creator-documents/3127/9752c581-4702-4bfc-a659-618e8742b8d7.png',
          // bankStatement:
          //   'https://cookx.sgp1.digitaloceanspaces.com/dev/creator-documents/3127/9752c581-4702-4bfc-a659-618e8742b8d7.png',
          // halalCert:
          //   'https://cookx.sgp1.digitaloceanspaces.com/dev/creator-documents/3127/9752c581-4702-4bfc-a659-618e8742b8d7.png',
          // sstCert: null,
          // businessLicense: d.document,
          // documentExists: d.is_document_exists
        },
        message: null
      };
    } else {
      state.creatorDocument = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_UPLOAD_DOCUMENT](state, result) {
    state.uploadDocument = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_REJECT_REASON](state, result) {
    state.rejectReason = {
      code: result.code,
      complete: true,
      data: result.data,
      message: null
    };
  },
  [M_INITIAL_GET_CREATOR_APPLICATIONS_STATE](state) {
    state.creatorApplications = {
      code: 0,
      complete: false,
      data: null,
      message: null,
      pagination: {
        currentPage: 0,
        total: 0,
        perPage: 0,
        lastPage: 0
      }
    };
  },
  [M_INITIAL_GET_CREATOR_APPLICATION_STATE](state) {
    state.creatorApplication = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_APPROVE_CREATOR_APPLICATION_STATE](state) {
    state.approveCreatorApplication = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_REJECT_CREATOR_APPLICATION_STATE](state) {
    state.rejectCreatorApplication = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_GET_CREATOR_DOCUMENT_STATE](state) {
    state.creatorDocument = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_UPLOAD_DOCUMENT_STATE](state) {
    state.uploadDocument = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_REJECT_REASON_STATE](state) {
    state.rejectReason = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

function getDocuments(data) {
  const documentType = CreatorApplicationDocumentType;
  const documentOrder = {
    [documentType.IC_FRONT]: 0,
    [documentType.IC_BACK]: 1,
    [documentType.BANK_STATEMENT]: 2,
    [documentType.DOCUMENT]: 3,
    [documentType.HALAL_CERT]: 4
  };
  var newDocuments = [];

  data.forEach((x) => {
    let type = x.original_url.split('.');

    newDocuments.push({
      uuid: x.uuid,
      originalUrl: x.original_url,
      documentType: x.document_type,
      documentTypeName: i18nHelper.getMessage(
        `enumCreatorApplicationDocumentType.${x.document_type}`
      ),
      fileType: type[type.length - 1].toLowerCase()
    });
  });

  /**
   * Sort the documents by order
   * IC_FRONT > IC_BACK > BANK_STATEMENT > DOCUMENT > HALAL_CERT
   */
  newDocuments.sort(function (docA, docB) {
    if (documentOrder[docA.documentType] > documentOrder[docB.documentType])
      return 1;
    if (documentOrder[docA.documentType] < documentOrder[docB.documentType])
      return -1;
  });

  return newDocuments;
}
