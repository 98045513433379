<template>
  <!--begin: Wizard Step 1-->
  <div>
    <h6 class="mb-10 font-weight-bold text-dark text-capitalize">
      {{ $t('label.parentCompany') }}
    </h6>

    <b-form class="form" id="kt_form">
      <template v-for="(i, index) in formFields.filter((x) => x.field == 1)">
        <div :key="index" v-if="i.noCreate !== isCreate || !isCreate">
          <AppFormField
            v-model="$v.detailsObj[i.model].$model"
            :state="validateObjState('detailsObj', i.model)"
            :validationValue="$v.detailsObj[i.model]"
            :title="$t(`label.${i.title}`)"
            :validators="i.validators"
            :formType="i.formType"
            :config="i.config"
            :validationParams="i.validationParams"
            :moreRemark="i.moreRemark"
            :isRequires="i.isRequires"
            :prepend="i.prepend"
            :disabled="i.disabled"
            :min="i.min"
            :solid="!i.disabled"
          >
            <template v-slot:prepend>
              <span class="input-group-text text-uppercase">+60</span>
            </template>
          </AppFormField>
          <div style="display: none">
            <!-- WARNING: I have no idea why this line is here, but removing it breaks everything -->
            {{ $v }}
          </div>
        </div>
      </template>
    </b-form>
  </div>
</template>

<script>
  import AppFormField from '@/views/components/bases/form-field/AppFormField';
  import constantsMixin from '@/core/mixins/constants.mixin';
  import commonMixin from '@/core/mixins/common.mixin';
  import { validationMixin } from 'vuelidate';
  import { required, numeric } from 'vuelidate/lib/validators';
  import { mobileNumber } from '@/core/constants';

  export default {
    name: 'MerchantGroupingParentCompanyForm',
    mixins: [commonMixin, constantsMixin, validationMixin],
    components: {
      AppFormField
    },
    props: {
      id: {},
      isCreate: {
        type: Boolean,
        default: false
      },
      detailsObj: {
        type: Object,
        default: () => {}
      }
    },
    data: () => ({
      formFields: []
    }),
    mounted() {
      this.initFormFields();
    },
    computed: {},
    watch: {},
    methods: {
      initFormFields() {
        this.formFields.push(
          {
            field: 1,
            model: 'corporateGroupName',
            title: 'corporateGroupName',
            validators: ['required'],
            isRequires: true
          },
          {
            field: 1,
            model: 'parentCompanyName',
            title: 'parentCompanyName',
            validators: ['required'],
            isRequires: true
          },
          {
            field: 1,
            model: 'contactPerson1',
            title: 'contactPerson1',
            validators: ['required'],
            isRequires: true
          },
          {
            field: 1,
            model: 'contactNumber1',
            title: 'contactNumber1',
            type: 'number',
            validators: ['required', 'mobileNumber', 'numeric'],
            prepend: true,
            isRequires: true
          },
          {
            field: 1,
            model: 'contactPerson2',
            title: 'contactPerson2',
            validators: []
          },

          {
            field: 1,
            model: 'contactNumber2',
            title: 'contactNumber2',
            type: 'number',
            validators: ['mobileNumber', 'numeric'],
            prepend: true
          }
        );
      },
      validationCheckForm() {
        //Submit new banner form if all the validation is no error
        return this.validationCheck('detailsObj');
      }
    },
    validations() {
      return {
        detailsObj: {
          corporateGroupName: { required },
          parentCompanyName: { required },
          contactPerson1: { required },
          contactNumber1: { required, mobileNumber, numeric },
          contactPerson2: {},
          contactNumber2: { mobileNumber, numeric }
        }
      };
    }
  };
</script>

<style></style>
