<template>
  <div>
    <!-- {{ item }} -->
    <b-row v-if="editable" no-gutters class="d-flex mb-3">
      <b-col cols="3">
        <AppFormField
          v-model="$v.contactNumber.$model"
          :state="validateState('contactNumber')"
          :validationValue="$v.contactNumber"
          :validators="validators"
          :disabled="disabled"
          noTitle
          prepend
        >
          <template v-slot:prepend>
            <span class="input-group-text text-uppercase">+60</span>
          </template>
        </AppFormField>
        <div style="display: none">
          <!-- WARNING: I have no idea why this line is here, but removing it breaks everything -->
          {{ $v }}
        </div>
      </b-col>

      <b-col cols="">
        <i
          v-if="disabled"
          class="fa la-edit icon-xl ml-4 pt-2 cursor-pointer"
          @click="disabled = false"
        ></i>
        <div v-else class="ml-4">
          <i
            class="fa la-times icon-xl pt-2 cursor-pointer"
            @click="onReset"
          ></i>
          <i
            class="fa la-save icon-xl ml-4 pt-2 cursor-pointer"
            @click="onSave"
          ></i>
          <i
            class="fa la-trash icon-xl ml-4 pt-2 cursor-pointer"
            @click="onDelete"
          ></i>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import AppFormField from '@/views/components/bases/form-field/AppFormField';
  import commonMixin from '@/core/mixins/common.mixin';
  import { validationMixin } from 'vuelidate';
  import { required } from 'vuelidate/lib/validators';
  import { MODULE, ACTION, mobileNumber } from '@/core/constants';

  export default {
    name: 'ConfigNumberFormField',
    mixins: [commonMixin, validationMixin],
    components: {
      AppFormField
    },
    props: {
      item: {
        type: Object,
        default: () => ({})
      },
      value: {
        type: [Number, String],
        default: null
      },
      editable: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      module: MODULE,
      action: ACTION,
      contactNumber: null,
      disabled: true,
      validators: ['required', 'mobileNumber']
    }),
    watch: {
      value() {
        this.contactNumber = this.value;
      }
    },
    mounted() {
      this.contactNumber = this.value;
    },
    methods: {
      onSave() {
        let data = {
          id: this.item.id,
          contactNumber: this.contactNumber
        };
        this.$emit('save', data);
        this.contactNumber = null;
        this.disabled = true;
        this.$v.$reset();
      },
      onDelete() {
        let data = {
          id: this.item.id
        };
        this.$emit('delete', data);
        this.contactNumber = null;
        this.disabled = true;
        this.$v.$reset();
      },
      onReset() {
        this.contactNumber = this.value;
        this.disabled = true;
        this.$v.$reset();
      }
    },
    validations: {
      contactNumber: {
        required,
        mobileNumber
      }
    }
  };
</script>

<style lang="scss"></style>
