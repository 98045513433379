<template>
  <div>
    <AppSmartList
      ref="app-smart-list"
      :breadcrumbData="breadcrumbData"
      :accessRights="accessRights"
      :filters="filters"
      :actions="actions"
      :tableHeaders="headers"
      subMenu="XChefs"
    >
      <template v-slot:buttons>
        <b-button
          size="sm"
          variant="success"
          class="mr-2 text-capitalize"
          @click="onClickTodaysDeliverySchedule"
        >
          <i class="flaticon-clock-2"></i>
          {{ $t('label.todaysDeliverySchedule') }}</b-button
        >
      </template>
    </AppSmartList>
  </div>
</template>

<script>
  import AppSmartList from '@/views/components/bases/smart/AppSmartList.vue';
  import { i18nHelper, uiHelper } from '@/core/utils';
  import {
    ROUTE_NAME,
    MODULE,
    ACTION,
    SMART_FORM_FIELD_TYPE,
    LOCAL,
    APP_COMPONENT_NAME
  } from '@/core/constants';
  import { listService } from '@/core/services';
  import {
    ORDERS_GET_XCHEFS_ORDERS,
    ORDERS_EXPORT_ORDER_EXCEL,
    ORDERS_INITIAL_ORDERS_STATE,
    ORDERS_INITIAL_EXPORT_ORDER_EXCEL_STATE
  } from '@/core/store/orders.module';

  const CONSTANTS_DATA = JSON.parse(uiHelper.getLocalStorage(LOCAL.CONSTANTS));
  const moment = require('moment-timezone');

  export default {
    name: 'OrdersXChefsList',
    components: {
      AppSmartList
    },
    data: () => ({
      breadcrumbData: {
        breadcrumbs: [
          {
            title: i18nHelper.getMessage('label.orderXChefs'),
            route: { name: ROUTE_NAME.ORDERS_XCHEFS }
          },
          { title: i18nHelper.getMessage('label.orderXChefsList') }
        ]
      },
      accessRights: {
        moduleName: MODULE.ORDERS,
        actions: {
          read: ACTION.ORDERS_READ,
          update: ACTION.ORDERS_UPDATE
        }
      },
      filters: [
        {
          label: 'orderId',
          key: 'orderIdentifier',
          value: null,
          tooltip: i18nHelper.getMessage('label.specificId')
        },
        {
          label: 'transactionId',
          key: 'transactionIdentifier',
          value: null,
          tooltip: i18nHelper.getMessage('label.parentId')
        },
        {
          label: 'orderStatus',
          key: 'orderStatus',
          value: null,
          options: listService.getDynamicList(CONSTANTS_DATA.orderStatus),
          optionLabel: 'text',
          type: SMART_FORM_FIELD_TYPE.SELECT
        },
        {
          label: 'deliveryStatus',
          key: 'deliveryStatus',
          value: null,
          options: listService.getDynamicList(CONSTANTS_DATA.deliveryStatus),
          optionLabel: 'text',
          type: SMART_FORM_FIELD_TYPE.SELECT
        },
        {
          label: 'username',
          key: 'orderBy',
          value: null,
          tooltip: i18nHelper.getMessage('label.orderFromWhom')
        },
        {
          label: 'creator',
          key: 'orderFrom',
          value: null,
          tooltip: i18nHelper.getMessage('label.orderToWhom')
        },
        {
          label: 'requestDateTime',
          key: 'orderRequestDateRange',
          value: null,
          type: SMART_FORM_FIELD_TYPE.DATE_RANGE
        },
        {
          label: 'lastUpdatedDateTime',
          key: 'orderLastUpdatedDateRange',
          value: null,
          type: SMART_FORM_FIELD_TYPE.DATE_RANGE
        },
        {
          label: 'scheduleDeliveryDateTime',
          key: 'scheduledDateRange',
          value: null,
          type: SMART_FORM_FIELD_TYPE.DATE_RANGE
        },
        {
          label: 'orderTrackingId',
          key: 'deliveryOrderId',
          value: null
        },
        {
          label: 'stripePaymentIntentId',
          key: 'stripePaymentIntentId',
          value: null
        }
      ],
      actions: {
        viewList: {
          module: 'orders',
          state: 'orders',
          action: ORDERS_GET_XCHEFS_ORDERS,
          initialStateAction: ORDERS_INITIAL_ORDERS_STATE,
          title: i18nHelper.getMessage('label.getOrders')
        },
        exportExcel: {
          module: 'orders',
          state: 'exportOrderExcel',
          action: ORDERS_EXPORT_ORDER_EXCEL,
          initialStateAction: ORDERS_INITIAL_EXPORT_ORDER_EXCEL_STATE,
          title: i18nHelper.getMessage('label.exportExcel')
        }
      },
      headers: [
        {
          key: 'no',
          name: 'no',
          active: true,
          sortable: true
        },
        {
          key: 'orderBy',
          name: 'username',
          active: true,
          sortable: true,
          class: 'min-w-130px'
        },
        {
          key: 'orderIdentifier',
          name: 'orderId',
          active: true,
          sortable: true,
          class: 'min-w-150px'
        },
        {
          key: 'deliveryOrderId',
          name: 'orderTrackingId',
          active: true,
          sortable: true
        },
        {
          key: 'transactionIdentifier',
          name: 'transactionId',
          active: true,
          sortable: true,
          class: 'min-w-150px'
        },
        {
          key: 'orderFrom',
          name: 'creator',
          active: true,
          sortable: true,
          class: 'min-w-130px'
        },
        {
          key: 'orderStatus',
          name: 'orderStatus',
          active: true,
          sortable: true,
          class: 'min-w-100px',
          layout: 'OrderStatusLabel'
        },
        {
          key: 'deliveryStatus',
          name: 'deliveryStatus',
          active: true,
          sortable: true,
          class: 'min-w-100px',
          layout: 'DeliveryStatusLabel'
        },
        {
          key: 'deliveryMethod',
          name: 'deliveryMethod',
          active: true,
          sortable: true
        },
        {
          key: 'scheduledAt',
          name: 'scheduleDeliveryDateTime',
          active: true,
          sortable: true
        },
        {
          key: 'reassignedByAdminUsername',
          name: 'agentName',
          active: true,
          sortable: true
        },
        {
          key: 'createdAt',
          name: 'requestDateTime',
          active: true,
          sortable: true
        },
        {
          key: 'updatedAt',
          name: 'lastUpdatedDateTime',
          active: true,
          sortable: true
        },
        {
          key: 'actions',
          name: 'actions',
          active: true,
          action: true,
          layout: APP_COMPONENT_NAME.APP_TABLE_ACTIONS,
          layoutActions: {
            viewDetailsRouteName: ROUTE_NAME.ORDERS_DETAILS
          }
        }
      ]
    }),
    methods: {
      onClickTodaysDeliverySchedule() {
        var today = moment().tz('Asia/Kuala_Lumpur').format('YYYY-MM-DD');

        this.filters.find((x) => x.key == 'scheduledDateRange').value = {
          end: today,
          shortcut: undefined,
          start: today
        };

        this.$refs['app-smart-list'].actionSearch();
      }
    }
  };
</script>

<style></style>
