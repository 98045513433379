<template>
  <div>
    <AppSmartList
      ref="app-smart-list"
      :breadcrumbData="breadcrumbData"
      :accessRights="accessRights"
      :filters="filters"
      :actions="actions"
      :tableHeaders="headers"
      :newRecord="newRecord"
    >
    </AppSmartList>
  </div>
</template>

<script>
  import AppSmartList from '@/views/components/bases/smart/AppSmartList.vue';
  // import AppExportExcel from '@/views/components/bases/AppExportExcel.vue';
  import { i18nHelper } from '@/core/utils';
  import { listService } from '@/core/services';
  import {
    ROUTE_NAME,
    MODULE,
    ACTION,
    APP_COMPONENT_NAME,
    SMART_FORM_FIELD_TYPE
  } from '@/core/constants';
  import {
    STUDIO_GET_BOOKINGS,
    STUDIO_INITIAL_GET_BOOKINGS_STATE
  } from '@/core/store/studio.module';

  export default {
    name: 'StudioBookingList',
    components: {
      AppSmartList
      // AppExportExcel
    },
    data: () => ({
      breadcrumbData: {
        breadcrumbs: [
          {
            title: i18nHelper.getMessage('label.studioBooking'),
            route: { name: ROUTE_NAME.STUDIO }
          },
          { title: i18nHelper.getMessage('label.studioBookingList') }
        ]
      },
      accessRights: {
        moduleName: MODULE.STUDIO,
        actions: {
          read: ACTION.STUDIO_READ,
          update: ACTION.STUDIO_UPDATE,
          create: ACTION.STUDIO_CREATE
        }
      },
      filters: [
        {
          label: 'contactPersonName',
          key: 'name',
          value: ''
        },
        {
          label: 'companyName',
          key: 'companyName',
          value: ''
        },
        {
          label: 'status',
          key: 'status',
          value: '',
          options: listService.getStudioBookingStatusList(),
          optionLabel: 'text',
          type: SMART_FORM_FIELD_TYPE.SELECT
        }
      ],
      actions: {
        viewList: {
          module: 'studio',
          state: 'bookings',
          action: STUDIO_GET_BOOKINGS,
          initialStateAction: STUDIO_INITIAL_GET_BOOKINGS_STATE,
          title: i18nHelper.getMessage('label.getStudioBooking')
        }
      },
      headers: [
        {
          key: 'no',
          name: 'no',
          active: true,
          sortable: true
        },
        {
          key: 'id',
          name: 'id',
          active: true,
          sortable: true
        },
        {
          key: 'companyName',
          name: 'companyName',
          active: true,
          sortable: true,
          class: 'py-2'
        },
        {
          key: 'name',
          name: 'contactPersonName',
          active: true,
          sortable: true,
          class: 'py-2'
        },
        {
          key: 'contactNumber',
          name: 'contactNumber',
          active: true,
          sortable: true,
          class: 'py-2'
        },
        {
          key: 'email',
          name: 'email',
          active: true,
          sortable: true,
          class: 'py-2'
        },
        {
          key: 'status',
          name: 'status',
          active: true,
          sortable: true,
          layout: 'StudioBookingStatusLabel'
        },
        {
          key: 'preferredStart',
          name: 'preferredStartDateTime',
          active: true,
          sortable: true
        },
        {
          key: 'preferredEnd',
          name: 'preferredEndDateTime',
          active: true,
          sortable: true
        },
        {
          key: 'actions',
          name: 'actions',
          active: true,
          action: true,
          layout: APP_COMPONENT_NAME.APP_TABLE_ACTIONS,
          layoutActions: {
            viewDetailsRouteName: ROUTE_NAME.STUDIO_BOOKING_DETAILS
          }
        }
      ],
      newRecord: {
        show: true,
        buttonTitle: i18nHelper.getMessage('label.newBooking'),
        routeName: ROUTE_NAME.STUDIO_BOOKING_CREATE
      }
    }),
    computed: {},
    watch: {},
    methods: {}
  };
</script>

<style></style>
