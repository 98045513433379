<template>
  <div>
    <!--begin: Details Section-->
    <b-row no-gutters class="w-100 mt-4">
      <!--begin: Col 1-->
      <b-col cols="12" md="4">
        <!--begin: Product Basic Info-->
        <b-card>
          <div class="d-flex justify-content-between align-items-start">
            <b-card-title class="text-capitalize font-weight-bolder">
              {{ $t('label.basicInformation') }}
            </b-card-title>
            <!-- begin: Actions Dropdown -->
            <div>
              <b-dropdown
                size="sm"
                :text="$t('label.actions')"
                variant="primary"
                v-if="orderObj.orderStatus != 2"
              >
                <b-dropdown-item
                  :disabled="!isOrderVerifiedOrRejected"
                  @click="openRequestConfirmOrderModal"
                  >{{ $t('label.confirmOrder') }}</b-dropdown-item
                >

                <b-dropdown-item
                  variant="danger"
                  :disabled="!isOrderVerifiedOrRejected"
                  @click="onClickReject"
                  >{{ $t('label.cancelOrder') }}</b-dropdown-item
                >
              </b-dropdown>
              <b-dropdown
                size="sm"
                :text="$t('label.actions')"
                variant="primary"
                v-else
              >
                <b-dropdown-item
                  variant="primary"
                  :disabled="isOrderTime"
                  @click="openRequestManualDeliveryOrderModal"
                  >{{ $t('label.manualDelivery') }}</b-dropdown-item
                >
                <b-dropdown-item variant="danger" @click="onClickCancel"
                  >{{ $t('label.cancelOrder') }}
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
          <!-- end: Actions Dropdown -->

          <b-card-text>
            <template v-for="(i, index) in basicInfoList">
              <div :key="i + index" class="mb-5">
                <div class="font-weight-bolder text-capitalize mb-2">
                  {{ $t(`label.${i.name}`) }}:
                </div>
                <component
                  v-if="i.layout"
                  :is="i.layout"
                  :value="orderObj[i.key]"
                  :id="id"
                  :parentObject="orderObj"
                  :itemKey="i.key"
                  :disabled="
                    orderObj.deletedAt != '-' ||
                    !checkAccessRight(moduleName, [
                      permissionAction.ORDERS_UPDATE
                    ])
                  "
                  :appFeatureDetailsRelatedButtonDisabled="
                    i.disabled
                      ? !checkAccessRight(i.disabled.module, i.disabled.actions)
                      : false
                  "
                  :config="i.config"
                  @select-delivery-status="updateDeliveryStatus"
                  @save-delivery-fee-action="updateDeliveryFee2"
                  @add-persona-in-charge="updateReassignedByAdminUsername"
                >
                </component>
                <div v-else class="text-gray-600">
                  <span v-if="i.key == 'deliveryMethod'">
                    {{ orderObj[i.key] | constants('deliveryMethod') }}</span
                  >
                  <span v-else>
                    {{ orderObj[i.key] || '-' }}
                  </span>
                </div>
              </div>
              <div
                v-if="i.key == 'priceBreakdownObject'"
                :key="index + 'separator'"
                class="separator separator-solid my-6"
              ></div>
            </template>
          </b-card-text>
        </b-card>
        <!--end: Product Basic Info-->
      </b-col>
      <!--end: Col 1-->

      <!--begin: Col 2-->
      <b-col cols="12" md="8" class="mt-4 mt-md-0 pl-md-4 pl-lg-8">
        <!-- begin:: Order details tabs -->
        <b-tabs
          content-class="mt-4"
          nav-class="mt-3 order-tabs"
          active-nav-item-class="order-tab-active"
        >
          <!-- begin:: Tab Overview -->
          <b-tab
            :title="$t('label.overview')"
            title-item-class="h4 mb-0 font-weight-bold"
            active
          >
            <!--begin: Order Delivery Location Card-->
            <OrderDeliveryLocationCard
              v-if="orderObj.deliveryMethod != deliveryMethod.SELF_PICKUP"
              :value="orderObj"
              @save-new-share-link="updateShareLink"
            ></OrderDeliveryLocationCard>
            <!--end: Order Delivery Location Card-->

            <!--begin: Order Pickup Location Card-->
            <OrderPickupLocationCard
              v-if="orderObj.deliveryMethod == deliveryMethod.SELF_PICKUP"
              :value="orderObj"
            ></OrderPickupLocationCard>
            <!--end: Order Pickup Location Card-->

            <OrderItemDetailsCard :value="orderObj"></OrderItemDetailsCard>
          </b-tab>
          <!-- end:: Tab Overview -->

          <!-- begin:: Tab Logs -->
          <b-tab
            :title="$t('label.logs')"
            title-item-class="h4 mb-0 font-weight-bold"
          >
            <AuditLogTable
              v-if="isShowAuditLogTable"
              ref="auditLogTable"
              :type="auditLogType.ORDER"
              :modelId="id"
            ></AuditLogTable>
          </b-tab>
          <!-- end:: Tab Logs -->
        </b-tabs>
        <!-- end:: Order details tabs -->
      </b-col>
      <!--end Col 2-->
    </b-row>
    <!--end: Details Section-->

    <!-- begin: Order Reassigned Driver Comment Modal -->
    <OrderReassignedDriverCommentModal
      v-model="assignedDriverCommentModal.show"
      @submit="reassignedDriverCommentModalSubmitAction"
    ></OrderReassignedDriverCommentModal>
    <!-- end: Order Reassigned Driver Comment Modal -->
    <OrderRejectOrderModal
      ref="order-reject-order-modal"
      @reject="rejectAction"
    >
    </OrderRejectOrderModal>
    <OrderCancelOrderModal
      ref="order-cancel-order-modal"
      @cancel="cancelAction"
    >
    </OrderCancelOrderModal>
  </div>
</template>

<script>
  import AppFeatureDetailsRelatedButton from '@/views/components/bases/AppFeatureDetailsRelatedButton';
  import AppSmartTable from '@/views/components/bases/smart/AppSmartTable';
  import OrderStatusLabel from '@/views/components/orders/OrderStatusLabel';
  import DeliveryStatusLabel from '@/views/components/orders/DeliveryStatusLabel';
  import OrderPriceBreakdown from '@/views/components/orders/OrderPriceBreakdown';
  import OrderItemDetailsCard from '@/views/components/orders/OrderItemDetailsCard';
  import DeliveryStatusDropdown from '@/views/components/orders/DeliveryStatusDropdown';
  import OrderDeliveryLocationCard from '@/views/components/orders/OrderDeliveryLocationCard';
  import OrderPickupLocationCard from '@/views/components/orders/OrderPickupLocationCard';
  import OrderPersonInChargeCheckbox from '@/views/components/orders/OrderPersonInChargeCheckbox';
  import OrderReassignedDriverCommentModal from '@/views/components/orders/OrderReassignedDriverCommentModal';
  import OrderRejectOrderModal from '@/views/components/orders/OrderRejectOrderModal';
  import OrderCancelOrderModal from '@/views/components/orders/OrderCancelOrderModal';

  import AuditLogTable from '@/views/components/audit-log/AuditLogTable';
  import commonMixin from '@/core/mixins/common.mixin';
  import {
    ROUTE_NAME,
    MODULE,
    ACTION,
    APP_COMPONENT_NAME
  } from '@/core/constants';
  import {
    DeliveryMethod,
    DeliveryStatus,
    AUDIT_LOG_TYPE
  } from '@/core/constants/enums';
  import { i18nHelper } from '@/core/utils';
  import {
    ORDERS_GET_ORDER,
    ORDERS_UPDATE_ORDER_STATUS,
    ORDERS_UPDATE_ORDER,
    ORDERS_UPDATE_ORDER_REASSIGNED_DRIVER_COMMENT,
    ORDER_CONFIRM_ORDER,
    ORDER_REJECT_ORDER,
    ORDER_CANCEL_ORDER,
    ORDERS_INITIAL_ORDER_STATE,
    ORDERS_INITIAL_UPDATE_ORDER_STATUS_STATE,
    ORDERS_INITIAL_UPDATE_ORDER_STATE,
    ORDERS_INITIAL_UPDATE_ORDER_REASSIGNED_DRIVER_COMMENT_STATE,
    ORDERS_INITIAL_CONFIRM_ORDER_STATE,
    ORDERS_INITIAL_REJECT_ORDER_STATE,
    ORDERS_INITIAL_CANCEL_ORDER_STATE
  } from '@/core/store/orders.module';

  export default {
    name: 'OrdersDetails',
    mixins: [commonMixin],
    components: {
      AppFeatureDetailsRelatedButton,
      AppSmartTable,
      OrderStatusLabel,
      DeliveryStatusLabel,
      OrderPriceBreakdown,
      OrderItemDetailsCard,
      DeliveryStatusDropdown,
      OrderDeliveryLocationCard,
      OrderPickupLocationCard,
      OrderPersonInChargeCheckbox,
      OrderReassignedDriverCommentModal,
      AuditLogTable,
      OrderRejectOrderModal,
      OrderCancelOrderModal
    },
    props: ['menu'],
    data: () => ({
      routeName: ROUTE_NAME,
      moduleName: MODULE.ORDERS,
      permissionAction: ACTION,
      deliveryMethod: DeliveryMethod,
      auditLogType: AUDIT_LOG_TYPE,
      id: null,
      orderObj: {},
      basicInfoList: [
        {
          key: 'orderIdentifier',
          name: 'orderId'
        },
        {
          key: 'deliveryOrderId',
          name: 'orderTrackingId'
        },
        {
          key: 'transactionIdentifier',
          name: 'transactionId'
        },
        {
          key: 'paymentIntentId',
          name: 'paymentIntentId'
        },
        {
          key: 'orderByObject',
          name: 'username',
          layout: APP_COMPONENT_NAME.APP_FEATURE_DETAILS_RELATED_BUTTON,
          config: {
            to: ROUTE_NAME.PROFILE_DETAILS_DETAILS
          },
          disabled: {
            module: MODULE.USER,
            actions: [ACTION.USER_READ]
          }
        },
        {
          key: 'orderFromObject',
          name: 'creator',
          layout: APP_COMPONENT_NAME.APP_FEATURE_DETAILS_RELATED_BUTTON,
          config: {
            to: ROUTE_NAME.PROFILE_DETAILS_DETAILS
          },
          disabled: {
            module: MODULE.USER,
            actions: [ACTION.USER_READ]
          }
        },
        {
          key: 'deliveryMethod',
          name: 'deliveryMethod'
        },
        {
          key: 'orderStatus',
          name: 'orderStatus',
          layout: 'OrderStatusLabel'
        },
        {
          key: 'deliveryStatus',
          name: 'deliveryStatus',
          layout: 'DeliveryStatusDropdown'
        },
        {
          key: 'reassignedDriverComment',
          name: 'reassignedDriverComment'
        },
        {
          key: 'reassignedByAdminUsername',
          name: 'personInCharge',
          layout: 'OrderPersonInChargeCheckbox'
        },
        {
          key: 'priceBreakdownObject',
          name: 'price',
          layout: 'OrderPriceBreakdown'
        },
        {
          key: 'createdAt',
          name: 'requestDateTime'
        },
        {
          key: 'updatedAt',
          name: 'lastUpdatedDateTime'
        },
        {
          key: 'scheduledAt',
          name: 'scheduleDeliveryDateTime'
        },
        {
          key: 'completedAt',
          name: 'completedDateTime'
        }
      ],
      deliveryFromDetailsList: [
        {
          key: 'name',
          name: 'name'
        },
        {
          key: 'address',
          name: 'address'
        },
        {
          key: 'contactNumber',
          name: 'contactNumber'
        }
      ],
      deliveryToDetailsList: [
        {
          key: 'name',
          name: 'name'
        },
        {
          key: 'address',
          name: 'address'
        },
        {
          key: 'contactNumber',
          name: 'contactNumber'
        },
        {
          key: 'shareLink',
          name: 'shareLink'
        },
        {
          key: 'deliveryRetriedAt',
          name: 'deliveryRetriedAt'
        }
      ],
      assignedDriverCommentModal: {
        show: false
      }
    }),
    computed: {
      orderComplete() {
        return this.$store.state.orders.order.complete;
      },
      updateOrderStatusComplete() {
        return this.$store.state.orders.updateOrderStatus.complete;
      },
      updateOrderComplete() {
        return this.$store.state.orders.updateOrder.complete;
      },
      updateOrderAssignedDriverCommentComplete() {
        return this.$store.state.orders.updateOrderAssignedDriverComment
          .complete;
      },
      confirmOrderComplete() {
        return this.$store.state.orders.confirmOrder.complete;
      },
      rejectOrderComplete() {
        return this.$store.state.orders.rejectOrder.complete;
      },
      cancelOrderComplete() {
        return this.$store.state.orders.cancelOrder.complete;
      },
      isShowAuditLogTable() {
        return this.checkAccessRight(MODULE.AUDIT_LOG, [ACTION.AUDIT_LOG_READ]);
      },
      isOrderVerifiedOrRejected() {
        var obj = this.orderObj;
        return obj.orderStatus == '1';
      },
      isOrderTime() {
        let dateTime;
        let admin = this.orderObj.reassignedByAdminUsername;
        var afterScheduledAt = this.orderObj.scheduledAt;
        var orderStatus = this.orderObj.orderStatus;
        var deliveryMethod = this.orderObj.deliveryMethod;
        var deliveryStatus = this.orderObj.deliveryStatus;

        dateTime = new Date();
        afterScheduledAt = new Date(afterScheduledAt);
        afterScheduledAt.setMinutes(afterScheduledAt.getMinutes() - 35);

        if (
          !admin &&
          dateTime < afterScheduledAt &&
          deliveryMethod != 1 && //NOT SELF_PICKUP SHOW BTN
          orderStatus != 1 && //NOT PENDING SHOW BTN
          orderStatus != 3 && //NOT REJECTED SHOW BTN
          deliveryStatus != 5 && //NOT CONFIRMED SHOW BTN
          deliveryStatus != 9 //NOT MANUAL SHOW BTN
        ) {
          return false;
        } else {
          return true;
        }
      },
      is24hOrderTime() {
        var afterScheduledAt = this.orderObj.scheduledAt;

        let dateTime = new Date();
        afterScheduledAt = new Date(afterScheduledAt);
        afterScheduledAt.setDate(afterScheduledAt.getDate() + 1);
        return afterScheduledAt > dateTime;
      }
    },
    watch: {
      orderComplete() {
        let response = this.$store.state.orders.order;
        let title = i18nHelper.getMessage('label.getOrderDetails');
        let initialStateAction = ORDERS_INITIAL_ORDER_STATE;
        let successAction = (response) => {
          this.importOrderObj(response);
        };

        if (response.complete) {
          this.actionReadRecordComplete(
            response,
            title,
            initialStateAction,
            successAction
          );
        }
      },
      updateOrderStatusComplete() {
        let response = this.$store.state.orders.updateOrderStatus;
        let title = i18nHelper.getMessage('label.updateOrderStatus');
        let initialStateAction = ORDERS_INITIAL_UPDATE_ORDER_STATUS_STATE;
        let successAction = () => {
          this.initialOrderDetails();
        };

        if (response.complete) {
          this.actionUpdateRecordCompleteWithoutSuccessModal(
            response,
            title,
            initialStateAction,
            successAction
          );
        }
      },
      updateOrderComplete() {
        let response = this.$store.state.orders.updateOrder;
        let title = i18nHelper.getMessage('label.updateOrder');
        let initialStateAction = ORDERS_INITIAL_UPDATE_ORDER_STATE;
        let successAction = () => {
          this.initialOrderDetails();
        };

        if (response.complete) {
          this.actionUpdateRecordComplete(
            response,
            title,
            initialStateAction,
            successAction
          );
        }
      },
      updateOrderAssignedDriverCommentComplete() {
        let response =
          this.$store.state.orders.updateOrderAssignedDriverComment;
        let title = i18nHelper.getMessage('label.updateOrder');
        let initialStateAction =
          ORDERS_INITIAL_UPDATE_ORDER_REASSIGNED_DRIVER_COMMENT_STATE;
        let successAction = () => {
          this.updateOrderDeliveryStatusToOnGoingAction();
        };

        if (response.complete) {
          this.actionUpdateRecordCompleteWithoutSuccessModal(
            response,
            title,
            initialStateAction,
            successAction
          );
        }
      },
      confirmOrderComplete() {
        let response = this.$store.state.orders.confirmOrder;
        let title = i18nHelper.getMessage('label.confirmOrder');
        let initialStateAction = ORDERS_INITIAL_CONFIRM_ORDER_STATE;
        let successAction = () => {
          this.initialOrderDetails();
        };
        if (response.complete) {
          this.actionUpdateRecordComplete(
            response,
            title,
            initialStateAction,
            successAction
          );
        }
      },
      rejectOrderComplete() {
        let response = this.$store.state.orders.rejectOrder;
        let title = i18nHelper.getMessage('label.rejectOrder');
        let initialStateAction = ORDERS_INITIAL_REJECT_ORDER_STATE;
        let successAction = () => {
          this.initialOrderDetails();
        };
        if (response.complete) {
          this.actionUpdateRecordComplete(
            response,
            title,
            initialStateAction,
            successAction
          );
        }
      },
      cancelOrderComplete() {
        let response = this.$store.state.orders.cancelOrder;
        let title = i18nHelper.getMessage('label.cancelOrder');
        let initialStateAction = ORDERS_INITIAL_CANCEL_ORDER_STATE;
        let successAction = () => {
          this.initialOrderDetails();
        };
        if (response.complete) {
          this.actionUpdateRecordComplete(
            response,
            title,
            initialStateAction,
            successAction
          );
        }
      }
    },
    created() {
      this.initialOrderDetails();
    },
    methods: {
      reassignedDriverCommentModalSubmitAction(value) {
        let data = {
          reassignedDriverComment: value
        };

        this.updateOrderAssignedDriverComment(this.id, data);
      },
      updateOrderDeliveryStatusToOnGoingAction() {
        let data = {
          deliveryStatus: DeliveryStatus.ON_GOING
        };

        this.updateOrderStatus(this.id, data);
      },
      updateReassignedByAdminUsername(value) {
        let data = {
          reassignedByAdminId: value
        };

        this.updateOrder(this.id, data);
      },
      updateShareLink(value) {
        let data = {
          shareLink: value
        };

        this.updateOrder(this.id, data);
      },
      updateDeliveryFee2(value) {
        let data = {
          deliveryFee: value
        };

        this.updateOrder(this.id, data);
      },
      updateDeliveryStatus(value) {
        let data = {
          deliveryStatus: value
        };

        if (value == DeliveryStatus.ON_GOING) {
          this.assignedDriverCommentModal.show = true;
        } else {
          this.updateOrderStatus(this.id, data);
        }
      },
      importOrderObj(response) {
        let data = response.data;
        this.orderObj = data;
      },
      updateOrderAssignedDriverComment(id, data) {
        this.$store.dispatch(ORDERS_UPDATE_ORDER_REASSIGNED_DRIVER_COMMENT, {
          id,
          data
        });
      },
      updateOrder(id, data) {
        this.$store.dispatch(ORDERS_UPDATE_ORDER, { id, data });
      },
      updateOrderStatus(id, data) {
        this.$store.dispatch(ORDERS_UPDATE_ORDER_STATUS, { id, data });
      },
      getOrder(id) {
        this.$store.dispatch(ORDERS_GET_ORDER, {
          id
        });
      },
      initialOrderDetails() {
        let id = Number(this.$route.params.id);
        let breadcrumbData = {
          breadcrumbs: [
            {
              title: i18nHelper.getMessage('label.orders'),
              route: { name: ROUTE_NAME.ORDERS }
            },
            { title: i18nHelper.getMessage('label.orderDetails') }
          ],
          actions: [
            {
              text: i18nHelper.getMessage('label.back'),
              function: () => {
                this.$router.go(-1);
              }
            }
          ]
        };

        this.id = id;
        this.getOrder(id);
        this.initBreadCrumb(breadcrumbData);
        window.KTUtil.scrollTop();
      },
      onClickReject() {
        this.$refs['order-reject-order-modal'].show();
      },
      onClickCancel() {
        this.$refs['order-cancel-order-modal'].show();
      },
      rejectAction(value) {
        var data = {
          rejectedReason: value
        };

        this.rejectRecipe(this.id, data);
      },
      cancelAction(value) {
        let admin = this.$store.state.auth.me.data;
        let adminId = { reassignedByAdminId: admin.id };

        var data = {
          cancelReason: value,
          adminId: adminId
        };

        this.cancelOrder(this.id, data);
      },
      cancelOrder(id, data) {
        this.$store.dispatch(ORDER_CANCEL_ORDER, {
          id,
          data
        });
      },
      rejectRecipe(id, data) {
        this.$store.dispatch(ORDER_REJECT_ORDER, {
          id,
          data
        });
      },
      openRequestConfirmOrderModal() {
        let title = i18nHelper.getMessage('label.confirmOrder');
        let messages = [];
        let buttons = [];

        messages.push(
          i18nHelper.getMessage('message.requestConfirmOrderMessage')
        );

        buttons.push(
          {
            text: i18nHelper.getMessage('label.cancel'),
            variant: 'white',
            action: () => {
              this.closeLayoutAppModalInfo();
            }
          },
          {
            text: i18nHelper.getMessage('label.confirm'),
            variant: 'primary',
            action: () => {
              this.confirmOrder(this.id);
              this.closeLayoutAppModalInfo();
            }
          }
        );

        this.openLayoutAppModalInfo(title, messages, buttons);
      },
      confirmOrder(id) {
        this.$store.dispatch(ORDER_CONFIRM_ORDER, {
          id
        });
      },
      openRequestManualDeliveryOrderModal() {
        let title = i18nHelper.getMessage('label.manualDelivery');
        let messages = [];
        let buttons = [];

        messages.push(
          i18nHelper.getMessage('message.requestManualDeliveryMessage')
        );

        buttons.push(
          {
            text: i18nHelper.getMessage('label.cancel'),
            variant: 'white',
            action: () => {
              this.closeLayoutAppModalInfo();
            }
          },
          {
            text: i18nHelper.getMessage('label.confirm'),
            variant: 'primary',
            action: () => {
              this.confirmManualDeliveryOrder(this.id);
              this.closeLayoutAppModalInfo();
            }
          }
        );

        this.openLayoutAppModalInfo(title, messages, buttons);
      },
      confirmManualDeliveryOrder(id) {
        let deliveryStatus = { deliveryStatus: 9 };
        let admin = this.$store.state.auth.me.data;
        let adminId = { reassignedByAdminId: admin.id };
        this.updateOrderStatus(id, deliveryStatus);
        this.updateOrder(id, adminId);
      }
    }
  };
</script>

<style lang="scss">
  .order-tabs {
    .nav-link {
      &:hover {
        border-color: transparent !important;
        border-bottom: 1px solid var(--primary) !important;
      }
    }
    .order-tab-active {
      background-color: transparent !important;
      border-color: transparent !important;
      border-bottom: 1px solid var(--primary) !important;
    }
  }
</style>
