import { MAINTENANCE_SCHEDULE } from '@/core/api';
import { apiHelper } from '@/core/utils';
import {
  LOADING_OPEN_LOADING,
  LOADING_CLOSE_LOADING
} from '@/core/store/loading.module';
import dateTimeFormat from '@/core/filters/dateTimeFormat.filter';

/* Define Module Name */
const MODULE_NAME = 'maintenanceSchedule/';

/* Define Action Name */
const A_GET_MAINTENANCE_SCHEDULE_LIST = 'getMaintenanceScheduleList';
const A_GET_MAINTENANCE_SCHEDULE = 'getMaintenanceSchedule';
const A_CREATE_MAINTENANCE_SCHEDULE = 'createMaintenanceSchedule';
const A_UPDATE_MAINTENANCE_SCHEDULE = 'updateMaintenanceSchedule';
const A_DELETE_MAINTENANCE_SCHEDULE = 'deleteMaintenanceSchedule';

/* Define Reset State Action Name */
const A_INITIAL_GET_MAINTENANCE_SCHEDULE_LIST_STATE =
  'initialGetMaintenanceScheduleListState';
const A_INITIAL_GET_MAINTENANCE_SCHEDULE_STATE =
  'initialGetMaintenanceScheduleState';
const A_INITIAL_CREATE_MAINTENANCE_SCHEDULE_STATE =
  'initialCreateMaintenanceScheduleState';
const A_INITIAL_UPDATE_MAINTENANCE_SCHEDULE_STATE =
  'initialUpdateMaintenanceScheduleState';
const A_INITIAL_DELETE_MAINTENANCE_SCHEDULE_STATE =
  'initialDeleteMaintenanceScheduleState';

/* Define Getter Name */

/* Define Mutation Name */
const M_GET_MAINTENANCE_SCHEDULE_LIST = 'setGetMaintenanceScheduleList';
const M_GET_MAINTENANCE_SCHEDULE = 'setGetMaintenanceSchedule';
const M_CREATE_MAINTENANCE_SCHEDULE = 'setCreateMaintenanceSchedule';
const M_UPDATE_MAINTENANCE_SCHEDULE = 'setUpdateMaintenanceSchedule';
const M_DELETE_MAINTENANCE_SCHEDULE = 'setDeleteMaintenanceSchedule';

/* Define Reset State Mutation Name */
const M_INITIAL_GET_MAINTENANCE_SCHEDULE_LIST_STATE =
  'setInitialMaintenanceScheduleListState';
const M_INITIAL_GET_MAINTENANCE_SCHEDULE_STATE =
  'setInitialMaintenanceScheduleState';
const M_INITIAL_CREATE_MAINTENANCE_SCHEDULE_STATE =
  'setInitialCreateMaintenanceScheduleState';
const M_INITIAL_UPDATE_MAINTENANCE_SCHEDULE_STATE =
  'setInitialUpdateMaintenanceScheduleState';
const M_INITIAL_DELETE_MAINTENANCE_SCHEDULE_STATE =
  'setInitialDeleteMaintenanceScheduleState';

/* Define Export Name */
export const MAINTENANCE_SCHEDULE_GET_MAINTENANCE_SCHEDULE_LIST =
  MODULE_NAME + A_GET_MAINTENANCE_SCHEDULE_LIST;
export const MAINTENANCE_SCHEDULE_GET_MAINTENANCE_SCHEDULE =
  MODULE_NAME + A_GET_MAINTENANCE_SCHEDULE;
export const MAINTENANCE_SCHEDULE_CREATE_MAINTENANCE_SCHEDULE =
  MODULE_NAME + A_CREATE_MAINTENANCE_SCHEDULE;
export const MAINTENANCE_SCHEDULE_UPDATE_MAINTENANCE_SCHEDULE =
  MODULE_NAME + A_UPDATE_MAINTENANCE_SCHEDULE;
export const MAINTENANCE_SCHEDULE_DELETE_MAINTENANCE_SCHEDULE =
  MODULE_NAME + A_DELETE_MAINTENANCE_SCHEDULE;

/* Define Reset State Name */
export const MAINTENANCE_SCHEDULE_INITIAL_GET_MAINTENANCE_SCHEDULE_LIST_STATE =
  MODULE_NAME + A_INITIAL_GET_MAINTENANCE_SCHEDULE_LIST_STATE;
export const MAINTENANCE_SCHEDULE_INITIAL_GET_MAINTENANCE_SCHEDULE_STATE =
  MODULE_NAME + A_INITIAL_GET_MAINTENANCE_SCHEDULE_STATE;
export const MAINTENANCE_SCHEDULE_INITIAL_CREATE_MAINTENANCE_SCHEDULE_STATE =
  MODULE_NAME + A_INITIAL_CREATE_MAINTENANCE_SCHEDULE_STATE;
export const MAINTENANCE_SCHEDULE_INITIAL_UPDATE_MAINTENANCE_SCHEDULE_STATE =
  MODULE_NAME + A_INITIAL_UPDATE_MAINTENANCE_SCHEDULE_STATE;
export const MAINTENANCE_SCHEDULE_INITIAL_DELETE_MAINTENANCE_SCHEDULE_STATE =
  MODULE_NAME + A_INITIAL_DELETE_MAINTENANCE_SCHEDULE_STATE;

const state = {
  maintenanceScheduleList: {
    code: 0,
    complete: false,
    data: null,
    message: null,
    pagination: {
      currentPage: 0,
      total: 0,
      perPage: 0,
      lastPage: 0
    }
  },
  maintenanceSchedule: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  createMaintenanceSchedule: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  updateMaintenanceSchedule: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  deleteMaintenanceSchedule: {
    code: 0,
    complete: false,
    data: null,
    message: null
  }
};

//Getters
const getters = {};

// Actions
const actions = {
  [A_GET_MAINTENANCE_SCHEDULE_LIST]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    MAINTENANCE_SCHEDULE.getMaintenanceScheduleList(data)
      .then((response) => {
        let result = response;
        commit(M_GET_MAINTENANCE_SCHEDULE_LIST, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_GET_MAINTENANCE_SCHEDULE_LIST, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_MAINTENANCE_SCHEDULE]({ dispatch, commit }, { id }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    MAINTENANCE_SCHEDULE.getMaintenanceSchedule(id)
      .then((response) => {
        let result = response;
        commit(M_GET_MAINTENANCE_SCHEDULE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_GET_MAINTENANCE_SCHEDULE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_CREATE_MAINTENANCE_SCHEDULE]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    MAINTENANCE_SCHEDULE.createMaintenanceSchedule(data)
      .then((response) => {
        let result = response;
        commit(M_CREATE_MAINTENANCE_SCHEDULE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_CREATE_MAINTENANCE_SCHEDULE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_UPDATE_MAINTENANCE_SCHEDULE]({ dispatch, commit }, { id, data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    MAINTENANCE_SCHEDULE.updateMaintenanceSchedule(id, data)
      .then((response) => {
        let result = response;
        commit(M_UPDATE_MAINTENANCE_SCHEDULE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_UPDATE_MAINTENANCE_SCHEDULE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_DELETE_MAINTENANCE_SCHEDULE]({ dispatch, commit }, { id }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    MAINTENANCE_SCHEDULE.deleteMaintenanceSchedule(id)
      .then((response) => {
        let result = response;
        commit(M_DELETE_MAINTENANCE_SCHEDULE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_DELETE_MAINTENANCE_SCHEDULE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_INITIAL_GET_MAINTENANCE_SCHEDULE_LIST_STATE]({ commit }) {
    commit(M_INITIAL_GET_MAINTENANCE_SCHEDULE_LIST_STATE);
  },
  [A_INITIAL_GET_MAINTENANCE_SCHEDULE_STATE]({ commit }) {
    commit(M_INITIAL_GET_MAINTENANCE_SCHEDULE_STATE);
  },
  [A_INITIAL_CREATE_MAINTENANCE_SCHEDULE_STATE]({ commit }) {
    commit(M_INITIAL_CREATE_MAINTENANCE_SCHEDULE_STATE);
  },
  [A_INITIAL_UPDATE_MAINTENANCE_SCHEDULE_STATE]({ commit }) {
    commit(M_INITIAL_UPDATE_MAINTENANCE_SCHEDULE_STATE);
  },
  [A_INITIAL_DELETE_MAINTENANCE_SCHEDULE_STATE]({ commit }) {
    commit(M_INITIAL_DELETE_MAINTENANCE_SCHEDULE_STATE);
  }
};

// Mutations
const mutations = {
  [M_GET_MAINTENANCE_SCHEDULE_LIST](state, result) {
    if (result.code === 0) {
      let d = result.data;
      let p = result.pagination;

      state.maintenanceScheduleList = {
        code: result.code,
        complete: true,
        data: d.map((x, index) => {
          return {
            no: index + 1,
            id: x.id,
            title: x.title,
            startDateTime: dateTimeFormat(x.start_date_time),
            endDateTime: dateTimeFormat(x.end_date_time),
            createdBy: x.created_by,
            createdAt: dateTimeFormat(x.created_at),
            updatedAt: dateTimeFormat(x.updated_at)
          };
        }),
        message: null,
        pagination: {
          currentPage: p.current_page,
          total: p.total,
          perPage: p.per_page,
          lastPage: p.last_page
        }
      };
    } else {
      state.maintenanceScheduleList = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_GET_MAINTENANCE_SCHEDULE](state, result) {
    if (result.code === 0) {
      let d = result.data;

      state.maintenanceSchedule = {
        code: result.code,
        complete: true,
        data: {
          id: d.id,
          title: d.title,
          startDateTime: dateTimeFormat(d.start_date_time),
          endDateTime: dateTimeFormat(d.end_date_time),
          createdBy: d.created_by,
          createdAt: dateTimeFormat(d.created_at),
          updatedAt: dateTimeFormat(d.updated_at)
        },
        message: null
      };
    } else {
      state.maintenanceSchedule = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_CREATE_MAINTENANCE_SCHEDULE](state, result) {
    state.createMaintenanceSchedule = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_UPDATE_MAINTENANCE_SCHEDULE](state, result) {
    state.updateMaintenanceSchedule = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_DELETE_MAINTENANCE_SCHEDULE](state, result) {
    state.deleteMaintenanceSchedule = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_INITIAL_GET_MAINTENANCE_SCHEDULE_LIST_STATE](state) {
    state.maintenanceScheduleList = {
      code: 0,
      complete: false,
      data: null,
      message: null,
      pagination: {
        currentPage: 0,
        total: 0,
        perPage: 0,
        lastPage: 0
      }
    };
  },
  [M_INITIAL_GET_MAINTENANCE_SCHEDULE_STATE](state) {
    state.maintenanceSchedule = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_CREATE_MAINTENANCE_SCHEDULE_STATE](state) {
    state.createMaintenanceSchedule = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_UPDATE_MAINTENANCE_SCHEDULE_STATE](state) {
    state.updateMaintenanceSchedule = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_DELETE_MAINTENANCE_SCHEDULE_STATE](state) {
    state.deleteMaintenanceSchedule = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
