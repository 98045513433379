<template>
  <div>
    <b-row no-gutters>
      <b-col cols="12" class="pb-5">
        <div class="wizard-label">
          <h3 class="wizard-title text-capitalize">
            {{ $t(`label.cookXEmailRecipients`) }}
          </h3>
          <div class="wizard-desc">
            {{ $t(`message.cookXEmailRecipientsMessage`) }}
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row no-gutters class="flex-wrap">
      <b-col cols="4" v-if="recipientEmailObj.length < 15">
        <ConfigEmailFormField
          :editable="true"
          @save="createRecipientEmailAction"
        >
        </ConfigEmailFormField>
      </b-col>
      <b-col cols="4" v-for="(item, index) in recipientEmailObj" :key="index">
        <ConfigEmailFormField
          :item="item"
          :value="item.email"
          :editable="true"
          @save="updateRecipientEmailAction"
          @delete="deleteRecipientEmailAction"
        >
        </ConfigEmailFormField>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import commonMixin from '@/core/mixins/common.mixin';
  import { i18nHelper } from '@/core/utils';
  import ConfigEmailFormField from '@/views/components/configuration/form-field/ConfigEmailFormField.vue';
  import {
    GENERAL_GET_EMAIL_RECIPIENT,
    GENERAL_INITIAL_GET_EMAIL_RECIPIENT_STATE,
    GENERAL_CREATE_EMAIL_RECIPIENT,
    GENERAL_INITIAL_CREATE_EMAIL_RECIPIENT_STATE,
    GENERAL_UPDATE_EMAIL_RECIPIENT,
    GENERAL_INITIAL_UPDATE_EMAIL_RECIPIENT_STATE,
    GENERAL_DELETE_EMAIL_RECIPIENT,
    GENERAL_INITIAL_DELETE_EMAIL_RECIPIENT_STATE
  } from '@/core/store/configuration/config-email-recipient.module';

  export default {
    name: 'ConfigEmailsRecipient',
    mixins: [commonMixin],
    components: {
      ConfigEmailFormField
    },
    data: () => ({
      recipientEmailObj: []
    }),
    mounted() {
      this.initialConfiguration();
    },
    computed: {
      emailRecipientComplete() {
        return this.$store.state.configEmailRecipient.emailRecipient.complete;
      },
      createEmailRecipientComplete() {
        return this.$store.state.configEmailRecipient.createEmailRecipient
          .complete;
      },
      updateEmailRecipientComplete() {
        return this.$store.state.configEmailRecipient.updateEmailRecipient
          .complete;
      },
      deleteEmailRecipientComplete() {
        return this.$store.state.configEmailRecipient.deleteEmailRecipient
          .complete;
      }
    },
    watch: {
      emailRecipientComplete() {
        let response = this.$store.state.configEmailRecipient.emailRecipient;
        let title = i18nHelper.getMessage('label.newOrderMessageNotification');
        let initialStateAction = GENERAL_INITIAL_GET_EMAIL_RECIPIENT_STATE;
        let successAction = (response) => {
          this.importEmailRecipientObj(response);
        };
        if (response.complete) {
          this.actionReadRecordComplete(
            response,
            title,
            initialStateAction,
            successAction
          );
        }
      },
      createEmailRecipientComplete() {
        let response =
          this.$store.state.configEmailRecipient.createEmailRecipient;
        if (response.complete) {
          this.getEmailRecipient();
          this.initialCreateEmailRecipient();
        }
      },
      updateEmailRecipientComplete() {
        let response =
          this.$store.state.configEmailRecipient.updateEmailRecipient;
        if (response.complete) {
          this.getEmailRecipient();
          this.initialUpdateEmailRecipient();
        }
      },
      deleteEmailRecipientComplete() {
        let response =
          this.$store.state.configEmailRecipient.deleteEmailRecipient;
        if (response.complete) {
          this.getEmailRecipient();
          this.initialDeleteEmailRecipient();
        }
      }
    },
    methods: {
      importEmailRecipientObj(response) {
        this.recipientEmailObj = response.data;
      },
      getEmailRecipient() {
        this.$store.dispatch(GENERAL_GET_EMAIL_RECIPIENT);
      },
      createRecipientEmailAction(value) {
        let data = {
          email: value.email
        };
        this.$store.dispatch(GENERAL_CREATE_EMAIL_RECIPIENT, { data });
      },
      updateRecipientEmailAction(value) {
        let id = value.id;
        let data = {
          email: value.email
        };
        this.recipientEmailObj = '';
        this.$store.dispatch(GENERAL_UPDATE_EMAIL_RECIPIENT, { id, data });
      },
      deleteRecipientEmailAction(value) {
        let id = value.id;
        this.$store.dispatch(GENERAL_DELETE_EMAIL_RECIPIENT, { id });
      },
      initialCreateEmailRecipient() {
        this.$store.dispatch(GENERAL_INITIAL_CREATE_EMAIL_RECIPIENT_STATE, {});
      },
      initialUpdateEmailRecipient() {
        this.$store.dispatch(GENERAL_INITIAL_UPDATE_EMAIL_RECIPIENT_STATE, {});
      },
      initialDeleteEmailRecipient() {
        this.$store.dispatch(GENERAL_INITIAL_DELETE_EMAIL_RECIPIENT_STATE, {});
      },
      initialConfiguration() {
        this.getEmailRecipient();
      }
    }
  };
</script>

<style></style>
