<template>
  <EquipmentDetailsTemplate :isCreate="false"></EquipmentDetailsTemplate>
</template>

<script>
  import EquipmentDetailsTemplate from '@/views/components/equipment/EquipmentDetailsTemplate.vue';

  export default {
    name: 'EquipmentDetails',
    components: {
      EquipmentDetailsTemplate
    }
  };
</script>

<style></style>
