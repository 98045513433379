<template>
  <div>
    <div class="">
      <div class="d-flex justify-content-between align-items-center mb-5 mt-5">
        <h6 class="m-0 font-weight-bold text-dark text-capitalize">
          {{ $t('label.branch') }}
        </h6>
        <b-button
          variant="primary"
          class="text-capitalize"
          @click="openBranchModal"
        >
          <i class="flaticon2-plus p-0 mr-2"></i>
          {{ $t('label.addNewSpecificUser') }}
        </b-button>
      </div>
      <div
        class="d-flex align-items-center flex-wrap overflow-auto"
        style="max-height: 200px"
      >
        <template v-for="(i, index) in 5">
          <b-button
            variant="light-primary"
            class="cursor-pointer mr-4 mb-4 font-size-base px-4 py-2"
            :key="index"
            @click="openBranchModal"
          >
            {{ i * 20 + ' test' + index }}
          </b-button>
        </template>
      </div>
    </div>

    <b-modal
      v-model="isShow"
      centered
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      header-class="text-capitalize"
      body-class="max-h-600px"
      size="ml"
    >
      <!-- Form Title -->
      <template #modal-header>
        <!-- Emulate built in modal header close button action -->
        <div class="w-100 d-flex justify-content-between align-items-center">
          {{ $t('label.branch') }}
        </div>
      </template>

      <!-- Form Field  -->
      <b-form class="form" id="kt_form">
        <template v-for="(i, index) in formFields.filter((x) => x.field == 1)">
          <div :key="index" v-if="i.noCreate !== isCreate || !isCreate">
            <component
              v-if="i.layout"
              v-model="$v.branchObj[i.model].$model"
              :ref="i.ref"
              :refName="i.ref"
              :is="i.layout"
              :title="$t(`label.${i.title}`)"
              :state="validateObjState('branchObj', i.model)"
              :disabled="i.disabled"
              :actionType="actionType.RECIPE"
              :category="actionType.RECIPE"
              :isExisting="!isCreate"
              :solid="!i.disabled"
              :config="i.config"
              @valueObj="valueObj"
              @branchName="$v.subsidiaryObj[i.model]"
            >
            </component>
            <AppFormField
              v-else
              v-model="$v.branchObj[i.model].$model"
              :ref="i.ref"
              :title="$t(`label.${i.title}`)"
              :state="validateObjState('branchObj', i.model)"
              :disabled="i.disabled"
              :actionType="actionType.CREATOR"
              :category="actionType.CREATOR"
              :isExisting="!isCreate"
              :solid="!i.disabled"
            ></AppFormField>
            <div style="display: none">
              <!-- WARNING: I have no idea why this line is here, but removing it breaks everything -->
              {{ $v }}
            </div>
          </div>
        </template>
      </b-form>
      <!-- Action Button -->
      <template #modal-footer>
        <template
          v-if="
            checkAccessRight(moduleName, [
              permissionAction.MERCHANT_GROUPING_CREATE
            ])
          "
        >
          <b-button class="mr-2" variant="white" @click="actionDiscard">{{
            $t('label.discard')
          }}</b-button>
          <b-button variant="primary" @click="actionSaveChanges">{{
            $t('label.save')
          }}</b-button>
        </template>
        <b-button v-else variant="primary" @click="actionDiscard">{{
          $t('label.close')
        }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
  import AppFormField from '@/views/components/bases/form-field/AppFormField';
  import BranchLiveSearch from '@/views/components/merchant-grouping/field/BranchLiveSearch';
  import constantsMixin from '@/core/mixins/constants.mixin';
  import commonMixin from '@/core/mixins/common.mixin';
  import { validationMixin } from 'vuelidate';
  import { required, numeric } from 'vuelidate/lib/validators';
  import { mobileNumber } from '@/core/constants';
  import { BannerActionType } from '@/core/constants/enums';

  export default {
    name: 'MerchantGroupingBranch',
    mixins: [commonMixin, constantsMixin, validationMixin],
    components: {
      AppFormField,
      BranchLiveSearch
    },
    props: {
      id: {},
      isCreate: {
        type: Boolean,
        default: false
      },
      moduleName: {},
      permissionAction: {}
    },
    data: () => ({
      isShow: false,
      branchObj: {
        branchName: null,
        contactPerson1: null,
        contactNumber1: null,
        contactPerson2: null,
        contactNumber2: null
      },
      formFields: [],
      actionType: BannerActionType
    }),
    mounted() {
      this.initFormFields();
    },
    computed: {},
    watch: {},
    methods: {
      openBranchModal() {
        this.isShow = true;
      },
      actionDiscard() {
        this.actionCloseModal();
      },
      actionCloseModal() {
        this.resetForm();
        this.isShow = false;
      },
      resetForm() {
        this.$v.$reset();
        this.branchObj = {
          branchName: null,
          contactPerson1: null,
          contactNumber1: null,
          contactPerson2: null,
          contactNumber2: null
        };
      },
      actionSaveChanges() {
        if (this.validationActionValueCheck() || this.validationCheckForm()) {
          this.openActionRequestModal();
        }
      },
      valueObj(val) {
        this.branchObj.contactPerson1 = val.username;
        this.branchObj.contactNumber1 = val.contactNumber.split('-')[1];
      },
      initFormFields() {
        this.formFields.push(
          {
            field: 1,
            model: 'branchName',
            title: 'branchName',
            ref: 'branchName',
            validators: ['required'],
            isRequires: true,
            layout: 'BranchLiveSearch'
          },
          {
            field: 1,
            model: 'contactPerson1',
            title: 'contactPerson1',
            validators: ['required'],
            disabled: true,
            isRequires: true
          },
          {
            field: 1,
            model: 'contactNumber1',
            title: 'contactNumber1',
            type: 'number',
            validators: ['required', 'mobileNumber', 'numeric'],
            prepend: true,
            disabled: true,
            isRequires: true
          },
          {
            field: 1,
            model: 'contactPerson2',
            title: 'contactPerson2',
            validators: []
          },

          {
            field: 1,
            model: 'contactNumber2',
            title: 'contactNumber2',
            type: 'number',
            validators: ['mobileNumber', 'numeric'],
            prepend: true
          }
        );
      },
      validationCheckForm() {
        //Submit new banner form if all the validation is no error
        return this.validationCheck('branchObj');
      },
      validationActionValueCheck() {
        var isValid = true;
        isValid = this.$refs['branchName'][0].validate();
        return isValid;
      }
    },
    validations() {
      return {
        branchObj: {
          branchName: { required },
          contactPerson1: { required },
          contactNumber1: { required, mobileNumber, numeric },
          contactPerson2: {},
          contactNumber2: { mobileNumber, numeric }
        }
      };
    }
  };
</script>

<style></style>
