<template>
  <label
    class="label label-md label-inline"
    :class="`label-light-${voucherStatusColor(value)}`"
    :style="styleName"
  >
    {{ $t(`enumVoucherStatus.${value}`) }}
  </label>
</template>

<script>
  import { VoucherStatus } from '@/core/constants/enums';

  export default {
    name: 'VoucherStatusLabel',
    props: {
      value: { default: 0 },
      styleName: { default: 0 }
    },
    methods: {
      voucherStatusColor(value) {
        let color = {
          [VoucherStatus.INACTIVE]: () => 'danger',
          [VoucherStatus.ACTIVE]: () => 'success',
          [VoucherStatus.EXPIRED]: () => ''
        };
        return color[value]();
      }
    }
  };
</script>

<style></style>
