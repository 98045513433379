<template>
  <div>
    <!-- begin: Referral Code -->
    <b-row no-gutters class="p-5">
      <b-col cols="4">
        <label class="text-capitalize">{{ $t(`label.referralCode`) }}</label>
      </b-col>
      <b-col cols="8">
        <div v-if="!disabled">
          {{ value.referralCode }}
          <i
            class="fa la-edit icon-xl ml-4 pt-2 cursor-pointer"
            @click="disabled = true"
          ></i>
        </div>
        <div v-else>
          <ProfileDetailsReferralFormField
            :item="value"
            :value="value.referralCode"
            :editable="true"
            @save="updateReferralCodeAction"
            @disable="updateDisableAction"
          ></ProfileDetailsReferralFormField>
        </div>
      </b-col>
    </b-row>

    <div class="separator separator-solid"></div>

    <!-- begin: Referrer -->
    <b-row no-gutters class="p-5">
      <b-col cols="4">
        <label class="text-capitalize">{{ $t(`label.referrer`) }}</label>
      </b-col>
      <b-col cols="8">
        <AppFeatureDetailsRelatedButton
          :value="value.referralObj"
          :toDetailsRouteName="routeName.PROFILE_DETAILS_DETAILS"
        ></AppFeatureDetailsRelatedButton>
      </b-col>
    </b-row>
    <!-- end: Referrer -->

    <div class="separator separator-solid"></div>

    <!-- begin: Referees -->
    <b-row no-gutters class="p-5">
      <b-col cols="4">
        <label class="text-capitalize">{{ $t(`label.referees`) }}</label>
      </b-col>
      <b-col cols="8">
        <span
          :class="{
            'text-primary cursor-pointer': userReferees.pagination.total
          }"
          @click="openUserRefereesTableModal(userReferees.pagination.total)"
        >
          {{ userReferees.pagination.total }}</span
        >
      </b-col>
    </b-row>
    <!-- end: Referees -->

    <div class="separator separator-solid"></div>

    <!-- begin: User Referees Table Modal -->
    <b-modal
      v-model="userRefereesTableModal"
      :title="$t('label.referees')"
      centered
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      header-class="text-capitalize"
    >
      <AppSmartTable
        stickyHeader
        :stacked="false"
        :items="userReferees.items"
        :headers="userReferees.header"
        :pagination="userReferees.pagination"
        @app-smart-table-pagination-change="actionPaginationChange"
      ></AppSmartTable>
    </b-modal>
    <!-- end: User Referees Table Modal -->
  </div>
</template>

<script>
  import userMixin from '@/core/mixins/user.mixin';
  import ProfileDetailsReferralFormField from '@/views/components/profile-details/ProfileDetailsReferralFormField';
  import AppSmartTable from '@/views/components/bases/smart/AppSmartTable';
  import AppFeatureDetailsRelatedButton from '@/views/components/bases/AppFeatureDetailsRelatedButton';
  import { ROUTE_NAME } from '@/core/constants';
  import { i18nHelper } from '@/core/utils';
  import commonMixin from '@/core/mixins/common.mixin';
  import {
    USER_GET_USER,
    USER_GET_USER_REFEREES,
    USER_INITIAL_GET_USER_REFEREES
  } from '@/core/store/user.module';

  export default {
    name: 'ProfileDetailsReferralDetails',
    components: {
      AppSmartTable,
      AppFeatureDetailsRelatedButton,
      ProfileDetailsReferralFormField
    },
    mixins: [commonMixin, userMixin],
    props: {
      value: {
        type: Object,
        default: () => ({})
      }
    },
    data: () => ({
      routeName: ROUTE_NAME,
      userRefereesTableModal: false,
      searchCriteriaUserReferees: {
        perPage: 10,
        page: 1
      },
      userReferees: {
        header: [
          {
            key: 'no',
            name: 'no',
            sortable: true
          },
          {
            key: 'userObj',
            name: 'referee',
            sortable: true,
            layout: 'AppFeatureDetailsRelatedButton'
          },
          {
            key: 'createdAt',
            name: 'dateJoined',
            sortable: true
          }
        ],
        items: [],
        pagination: {
          currentPage: 1,
          total: 1,
          perPage: 10,
          lastPage: 1
        }
      },
      disabled: false
    }),
    computed: {
      userRefereesComplete() {
        return this.$store.state.user.userReferees.complete;
      },
      route() {
        return this.$route;
      }
    },
    watch: {
      value() {
        if (this.value.id) {
          this.getUserReferees(this.value.id, this.searchCriteriaUserReferees);
        }
      },
      userRefereesComplete() {
        let response = this.$store.state.user.userReferees;
        let title = i18nHelper.getMessage('label.getUserReferees');
        let initialStateAction = USER_INITIAL_GET_USER_REFEREES;
        let successAction = () => {
          this.importUserReferees(response);
        };

        if (response.complete) {
          this.actionReadRecordComplete(
            response,
            title,
            initialStateAction,
            successAction
          );
        }
      },
      route() {
        this.userRefereesTableModal = false;
      },
      updateUserReferralCodeComplete() {
        let response = this.$store.state.user.updateUserReferralCode;
        if (response.complete) {
          this.updateUserReferralCodeCompleteAction(response);
        }
      }
    },
    methods: {
      openUserRefereesTableModal(total) {
        if (total) this.userRefereesTableModal = true;
      },
      importUserReferees(response) {
        this.userReferees.items = response.data;
        this.userReferees.pagination = response.pagination;
      },
      actionPaginationChange(value) {
        this.searchCriteriaUserReferees = {
          perPage: value.size,
          page: value.page
        };

        this.getUserReferees(this.value.id, this.searchCriteriaUserReferees);
      },
      getUserReferees(id, data) {
        this.$store.dispatch(USER_GET_USER_REFEREES, { id, data });
      },
      updateDisableAction(value) {
        this.disabled = value;
      },
      updateReferralCodeAction(data) {
        this.openRequestUpdateUserReferralCodeModal(data);
      },
      updateUserReferralCodeCompleteAction(response) {
        let id = this.$route.params.id;
        let title =
          response.code == 0
            ? i18nHelper.getMessage('label.updateReferralCode')
            : i18nHelper.getMessage('label.unableUpdateReferralCode');
        let messages = response.message;
        let buttons = [
          {
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              if (response.code == 0) this.getUser(id);
              this.initialUpdateReferralCodeState();
              this.closeLayoutAppModalInfo();
              if (this.isAllowReadAuditLog)
                this.$refs.auditLogTable.initAuditLogTable();
            }
          }
        ];

        this.openLayoutAppModalInfo(title, messages, buttons);
      },
      getUser(id) {
        this.$store.dispatch(USER_GET_USER, { id });
      }
    }
  };
</script>

<style></style>
