<template>
  <b-card no-body>
    <!--begin: Card Header-->
    <b-card-header class="d-flex border-0 pb-md-0">
      <b-row no-gutters class="justify-content-between full-width">
        <!--begin::Card Header Title-->
        <b-col cols="6">
          <b-card-title class="text-capitalize mb-0 mb-md-8">{{
            $t('label.details')
          }}</b-card-title>
        </b-col>
        <!--end::Card Header Title-->

        <!--begin::Card Header Buttons-->
        <b-col cols="auto">
          <b-button :to="{ name: routeName.ROLE_PERMISSION_LIST }">
            {{ $t('label.back') }}
          </b-button>
        </b-col>
        <!--end::Card Header Buttons-->
      </b-row>
    </b-card-header>
    <!--end: Card Header-->

    <!--begin: Card Body-->
    <b-card-body>
      <!--begin: Role Permission Selection Table-->
      <RolePermissionSelectionTable
        ref="RolePermissionSelectionTable"
        :isNew="false"
        :existingRoleName="roleObj.name"
        :existingPermission="roleObj.permission"
        :inject="injectPermission"
        @submit="updateRoleDetails"
        @delete-role-action="openRequestDeleteRoleModal(id)"
        @reset-inject="resetInject"
      ></RolePermissionSelectionTable>
      <!--end: Role Permission Selection Table-->
    </b-card-body>
    <!--end: Card Body-->
  </b-card>
</template>

<script>
  import RolePermissionSelectionTable from '@/views/components/role-permission/RolePermissionSelectionTable';
  import constantsMixin from '@/core/mixins/constants.mixin';
  import commonMixin from '@/core/mixins/common.mixin';
  import { ROUTE_NAME } from '@/core/constants';
  import { i18nHelper, permissionHelper } from '@/core/utils';
  import {
    ROLE_GET_ROLE,
    ROLE_UPDATE_ROLE,
    ROLE_DELETE_ROLE,
    ROLE_INITIAL_GET_ROLE_STATE,
    ROLE_INITIAL_UPDATE_ROLE_STATE,
    ROLE_INITIAL_DELETE_ROLE_STATE
  } from '@/core/store/role.module';

  export default {
    name: 'RolePermissionDetails',
    mixins: [commonMixin, constantsMixin],
    components: {
      RolePermissionSelectionTable
    },
    data: () => ({
      routeName: ROUTE_NAME,
      id: null,
      roleObj: {
        name: '',
        permission: {}
      },
      injectPermission: false
    }),
    computed: {
      roleComplete() {
        return this.$store.state.role.role.complete;
      },
      updateRoleComplete() {
        return this.$store.state.role.updateRole.complete;
      },
      deleteRoleComplete() {
        return this.$store.state.role.deleteRole.complete;
      }
    },
    watch: {
      roleComplete() {
        let response = this.$store.state.role.role;
        if (response.complete) {
          this.getRoleCompleteAction(response);
        }
      },
      updateRoleComplete() {
        let response = this.$store.state.role.updateRole;
        if (response.complete) {
          this.updateRoleCompleteAction(response);
        }
      },
      deleteRoleComplete() {
        let response = this.$store.state.role.deleteRole;
        if (response.complete) {
          this.deleteRoleCompleteAction(response);
        }
      },
      constantsComplete() {
        let response = this.$store.state.constants.constants;
        if (response.complete) {
          this.getConstantsCompleteAction(response);
          this.getRole(this.id);
        }
      }
    },
    created() {
      this.initialRoleDetails();
    },
    methods: {
      deleteRoleCompleteAction(response) {
        let title = i18nHelper.getMessage('label.deleteRole');
        let messages = response.message;
        let buttons = [];

        if (response.code == 0) {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();
              this.initialDeleteRoleState();
              this.$router.push({ name: ROUTE_NAME.ROLE_PERMISSION_LIST });
            }
          });
        } else {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();
              this.initialDeleteRoleState();
              this.getRole(this.id);
              this.resetChildValidation();
            }
          });
        }

        this.openLayoutAppModalInfo(title, messages, buttons);
      },
      updateRoleCompleteAction(response) {
        let title = i18nHelper.getMessage('label.updateRoleDetails');
        let messages = response.message;
        let buttons = [];

        buttons.push({
          text: i18nHelper.getMessage('label.ok'),
          variant: 'primary',
          action: () => {
            this.closeLayoutAppModalInfo();
            this.initialUpdateRoleState();
            this.getRole(this.id);
            this.resetChildValidation();
          }
        });

        this.openLayoutAppModalInfo(title, messages, buttons);
      },
      getRoleCompleteAction(response) {
        let data = response.data;
        let title = i18nHelper.getMessage('label.roleDetails');
        let messages = response.message;
        let buttons = [];

        if (response.code == 0) {
          this.roleObjDataMapping(data);
        } else {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();
            }
          });
          this.openLayoutAppModalInfo(title, messages, buttons);
        }
        this.initialGetRoleState();
      },
      openRequestDeleteRoleModal(id) {
        let title = i18nHelper.getMessage('label.deleteRole');
        let messages = [];
        let buttons = [];

        messages.push(
          i18nHelper.getMessage('message.requestDeleteMessage', [
            i18nHelper.getMessage('label.role').toLowerCase()
          ])
        );

        buttons.push(
          {
            text: i18nHelper.getMessage('label.cancel'),
            variant: 'secondary',
            action: () => {
              this.closeLayoutAppModalInfo();
            }
          },
          {
            text: i18nHelper.getMessage('label.delete'),
            isText: false,
            variant: 'danger',
            action: () => {
              this.closeLayoutAppModalInfo();
              this.deleteRole(id);
            }
          }
        );

        this.openLayoutAppModalInfo(title, messages, buttons);
      },
      roleObjDataMapping(data) {
        let permission =
          permissionHelper.existingPermissionsSortedByModuleFeatureObject(
            data.permissions
          );
        this.roleObj = {
          name: data.name,
          permission: permission
        };

        this.injectPermission = true;
      },
      resetInject(value) {
        this.injectPermission = value;
      },
      resetChildValidation() {
        this.$refs.RolePermissionSelectionTable.resetValidation();
      },
      updateRoleDetails(data) {
        this.updateRole(this.id, data);
      },
      updateRole(id, data) {
        this.$store.dispatch(ROLE_UPDATE_ROLE, { id, data });
      },
      getRole(id) {
        this.$store.dispatch(ROLE_GET_ROLE, { id });
      },
      deleteRole(id) {
        this.$store.dispatch(ROLE_DELETE_ROLE, { id });
      },
      initialUpdateRoleState() {
        this.$store.dispatch(ROLE_INITIAL_UPDATE_ROLE_STATE);
      },
      initialGetRoleState() {
        this.$store.dispatch(ROLE_INITIAL_GET_ROLE_STATE);
      },
      initialDeleteRoleState() {
        this.$store.dispatch(ROLE_INITIAL_DELETE_ROLE_STATE);
      },
      initialRoleDetails() {
        let breadcrumbData = {
          breadcrumbs: [
            {
              title: i18nHelper.getMessage('label.rolePermission'),
              route: { name: ROUTE_NAME.ROLE_PERMISSION }
            },
            { title: i18nHelper.getMessage('label.rolePermissionDetails') }
          ],
          actions: [
            {
              text: i18nHelper.getMessage('label.back'),
              function: () => {
                this.$router.go(-1);
              }
            }
          ]
        };

        this.id = this.$route.params.id;
        this.getRole(this.id);
        this.initBreadCrumb(breadcrumbData);
      }
    }
  };
</script>

<style lang="scss"></style>
