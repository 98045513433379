<template>
  <div :class="!horizontal ? 'form-group' : 'no-form-group'">
    <div v-if="documentName(attachmentUrl)" class="w-100 text-break">
      <span
        class="mb-0 font-size-sm form-control form-control-md overflow-hidden"
        :class="{
          'form-control-solid': solid
        }"
        style="white-space: nowrap; text-overflow: ellipsis"
      >
        {{ documentName(attachmentUrl) }}
      </span>
      <div class="d-flex">
        <label
          v-if="!noReplace"
          class="text-primary cursor-pointer mb-0 mr-4 font-size-sm"
          @click="$emit('replace-action', modelName)"
        >
          {{ $t('label.replace') }}
        </label>
        <AppButtonDocumentView
          :attachmentUrl="attachmentUrl"
          :fileType="fileType"
        ></AppButtonDocumentView>
      </div>
    </div>
    <div v-else>
      <!-- <img width="225px" :src="previewImage" class="uploading-image" /> -->
      <AppFormField
        :noTitle="true"
        :state="state"
        :formType="'file'"
        :validators="validators"
        :validationParams="validationParams"
        :validationValue="validationValue"
        :accept="accept"
        :config="config"
        :moreRemark="moreRemark"
        @change="uploadImage"
        prepend
      >
        <template v-if="config.mediaType" v-slot:fileMessages>
          <AppMediaFileStandardMessage
            :mediaType="config.mediaType"
            :dimension="config.dimension"
            :uploadSize="config.uploadSize"
            :sizeType="config.sizeType"
          ></AppMediaFileStandardMessage>
        </template>
      </AppFormField>
    </div>
  </div>
</template>

<script>
  import AppFormField from '@/views/components/bases/form-field/AppFormField';
  import AppButtonDocumentView from '@/views/components/bases/button/AppButtonDocumentView';
  import AppMediaFileStandardMessage from '@/views/components/bases/AppMediaFileStandardMessage';

  export default {
    name: 'AppViewAndUpload',
    components: {
      AppFormField,
      AppButtonDocumentView,
      AppMediaFileStandardMessage
    },
    props: {
      value: {},
      attachmentUrl: {
        Type: String,
        default: ''
      },
      accept: {},
      modelName: {},
      state: {
        Type: Boolean,
        default: true
      },
      validators: {},
      validationParams: {},
      validationValue: {},
      horizontal: {
        Type: Boolean,
        default: false
      },
      fileType: {
        Type: String,
        default: 'png'
      },
      moreRemark: {
        Type: String,
        default: ''
      },
      noReplace: {
        Type: Boolean,
        default: false
      },
      solid: {
        Type: Boolean,
        default: false
      },
      disabled: {
        Type: Boolean,
        default: false
      },
      config: {
        Type: Object,
        default: () => ({
          mediaType: null
        })
      }
    },
    data: () => ({
      previewImage: null
    }),
    mounted() {},
    computed: {},
    methods: {
      documentName(data) {
        return typeof data === 'string' ? data.split('/').reverse()[0] : '';
      },
      uploadImage(e) {
        const image = e.target.files[0];
        const reader = new FileReader();
        if (image) {
          reader.readAsDataURL(image);
          reader.onload = (e) => {
            this.previewImage = e.target.result;
            this.$emit('input', image);
          };
        } else {
          this.$emit('input', '');
        }
      }
    }
  };
</script>

<style></style>
