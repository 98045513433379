<template>
  <ul class="menu-nav new-menu-nav">
    <template v-for="(item, index) in menuCategoryItems">
      <!-- begin:: Menu Category Title -->
      <li
        v-if="
          item != asideMenuCategory.MAIN &&
          asideNavigationList.find((x) => x.category == item)
        "
        :key="index"
        class="menu-section"
      >
        <h4 class="menu-text">{{ $t(`label.${item}`) }}</h4>
        <i class="menu-icon flaticon-more-v2"></i>
      </li>
      <!-- end:: Menu Category Title -->
      <!-- begin:: Routes List -->
      <template
        v-for="(i, routesListIndex) in asideNavigationList.filter(
          (x) => x.category == item
        )"
      >
        <!-- begin:: First Layer Menu -->
        <!-- begin:: Has Children  -->
        <li
          v-if="i.children.length > 0"
          :key="`${routesListIndex}-${i.category}`"
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item menu-item-submenu"
          v-bind:class="{ 'menu-item-open': hasActiveChildren(i.path) }"
        >
          <a href="#" class="menu-link menu-toggle">
            <i :class="`menu-icon ${i.icon}`"></i>
            <span class="menu-text text-capitalize">{{
              $t(`label.${i.name}`)
            }}</span>
            <i class="menu-arrow pr-3"></i>
            <NotificationBadge :name="i.notification"></NotificationBadge>
          </a>
          <div class="menu-submenu">
            <span class="menu-arrow"></span>
            <ul class="menu-subnav">
              <li aria-haspopup="true" class="menu-item menu-item-parent">
                <span class="menu-link">
                  <span class="menu-text text-capitalize">{{ i.name }}</span>
                </span>
              </li>
              <!-- begin:: Second Layer Menu -->
              <template v-for="(secondLayer, secondLayerIndex) in i.children">
                <!-- begin:: Has Children  -->
                <li
                  v-if="secondLayer.children.length > 0"
                  :key="secondLayerIndex"
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item menu-item-submenu"
                  :class="{
                    'menu-item-open': hasActiveChildren(secondLayer.path),
                  }"
                >
                  <a href="#" class="menu-link menu-toggle">
                    <i class="menu-bullet menu-bullet-dot">
                      <span></span>
                    </i>
                    <span class="menu-text text-capitalize">{{
                      $t(`label.${secondLayer.name}`)
                    }}</span>
                    <i class="menu-arrow"></i>
                  </a>
                  <div class="menu-submenu">
                    <span class="menu-arrow"></span>
                    <ul class="menu-subnav">
                      <li
                        aria-haspopup="true"
                        class="menu-item menu-item-parent"
                      >
                        <span class="menu-link">
                          <span class="menu-text text-capitalize"></span>
                        </span>
                      </li>

                      <!-- begin:: Third Layer Menu -->
                      <template
                        v-for="(
                          thirdLayer, thirdLayerIndex
                        ) in secondLayer.children"
                      >
                        <router-link
                          v-if="!thirdLayer.isHidden"
                          :key="thirdLayerIndex"
                          :to="`${i.path}/${secondLayer.path}/${thirdLayer.path}`"
                          v-slot="{ href, navigate, isActive, isExactActive }"
                          custom
                        >
                          <li
                            aria-haspopup="true"
                            data-menu-toggle="hover"
                            class="menu-item"
                            :class="[
                              isActive && 'menu-item-active',
                              isExactActive && 'menu-item-active',
                            ]"
                          >
                            <a :href="href" class="menu-link" @click="navigate">
                              <i class="menu-bullet menu-bullet-dot">
                                <span></span>
                              </i>
                              <span class="menu-text text-capitalize">{{
                                $t(`label.${thirdLayer.name}`)
                              }}</span>
                            </a>
                          </li>
                        </router-link>
                      </template>
                      <!-- end:: Third Layer Menu -->
                    </ul>
                  </div>
                </li>
                <!-- end:: Has Children  -->

                <!-- begin:: No Children  -->
                <router-link
                  v-else
                  :key="secondLayerIndex"
                  :to="`${i.path}/${secondLayer.path}`"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                  custom
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-bullet menu-bullet-dot">
                        <span></span>
                      </i>
                      <span class="menu-text text-capitalize">{{
                        $t(`label.${secondLayer.name}`)
                      }}</span>
                      <NotificationBadge
                        :name="secondLayer.notification"
                        :type="i.type"
                      ></NotificationBadge>
                    </a>
                  </li>
                </router-link>
                <!-- end:: No Children  -->
              </template>
              <!-- end:: Second Layer Menu -->
            </ul>
          </div>
        </li>
        <!-- end:: Has Children  -->

        <!-- begin:: No Children  -->
        <router-link
          v-else
          :key="`${routesListIndex}-${i.category}`"
          :to="i.path"
          v-slot="{ href, navigate, isActive, isExactActive }"
          custom
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i :class="`menu-icon ${i.icon}`"></i>
              <span class="menu-text text-capitalize">{{
                $t(`label.${i.name}`)
              }}</span>
              <NotificationBadge :name="i.notification"></NotificationBadge>
            </a>
          </li>
        </router-link>
        <!-- end:: No Children  -->
      </template>
      <!-- end:: First Layer Menu -->
    </template>
  </ul>
</template>

<script>
import NotificationBadge from "@/views/components/notification/NotificationBadge";
import { ASIDE_MENU_CATEGORY } from "@/core/constants/enums";
import { accessRightsService } from "@/core/services";

export default {
  name: "KTMenu",
  components: {
    NotificationBadge,
  },
  data: () => ({
    asideNavigationList: accessRightsService.authorizedNavigationList(),
    asideMenuCategory: ASIDE_MENU_CATEGORY,
    menuCategoryItems: [
      ASIDE_MENU_CATEGORY.MAIN,
      ASIDE_MENU_CATEGORY.MEMBER,
      ASIDE_MENU_CATEGORY.MERCHANT,
      ASIDE_MENU_CATEGORY.PURCHASE,
      ASIDE_MENU_CATEGORY.POST,
      ASIDE_MENU_CATEGORY.COMPANY_WEBSITE,
      ASIDE_MENU_CATEGORY.MOBILE_APPLICATION,
      ASIDE_MENU_CATEGORY.PROMOTION,
      ASIDE_MENU_CATEGORY.TRANSACTIONS,
      ASIDE_MENU_CATEGORY.CONTENT_MANAGEMENT,
      ASIDE_MENU_CATEGORY.ADMIN_USER,
      ASIDE_MENU_CATEGORY.SETTINGS,
    ],
  }),
  mounted() {
    // console.log(this.asideNavigationList);
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
  },
};
</script>

<style lang="scss">
.new-menu-nav {
  &.menu-nav {
    .menu-section {
      margin-top: 2rem !important;
      margin-bottom: 0.5rem !important;
      height: unset !important;
    }
    .menu-item {
      .menu-link {
        min-height: unset !important;
        padding-top: 0.75rem !important;
        padding-bottom: 0.75rem !important;
        .menu-text {
          font-size: 0.925rem !important;
        }
        .menu-icon {
          font-size: 1.2rem !important;
        }
      }
    }
  }
}
</style>
