<template>
  <div>
    <h6 class="mb-10 font-weight-bold text-dark text-capitalize">
      {{ $t('label.uploadFormMedia', [$t('label.equipment')]) }}
    </h6>

    <div v-if="attachment.length < 10">
      <template v-for="(i, index) in formFields">
        <AppFormField
          :key="index"
          v-model="$v.documents[i.model].$model"
          :title="$t(`label.${i.title}`)"
          :formType="i.formType"
          :state="validateObjState('documents', i.model)"
          :disabled="i.disabled"
          :validators="i.validators"
          :validationValue="$v.documents[i.model]"
          :validationParams="i.validationParams"
          :accept="i.accept"
          solid
          isRequires
          :fileUploadButton="!isCreate"
          @upload-file="i.action"
          @change="i.change"
        >
        </AppFormField>
      </template>

      <div style="display: none">
        <!-- WARNING: I have no idea why this line is here, but removing it breaks everything -->
        {{ $v }}
      </div>
      <AppMediaFileStandardMessage
        :mediaType="4"
        :dimension="{ height: '1500', width: '500', ratio: '1:1' }"
      >
        <template v-slot:otherMessages>
          <small class="d-block">
            **
            {{
              $t(`message.mediaSizeShouldBeLessThan`, [
                'Image',
                global.BANNER_IMAGE_UPLOAD_SIZE,
                'KB'
              ])
            }}
          </small>
          <small class="d-block">
            **
            {{
              $t(`message.mediaSizeShouldBeLessThan`, [
                'Video',
                global.BANNER_VIDEO_UPLOAD_SIZE,
                'MB'
              ])
            }}
          </small>
          <small class="d-block"
            >** {{ $t('message.maximumNumberFileUpload', [10]) }}
          </small>
        </template>
      </AppMediaFileStandardMessage>
    </div>
    <template>
      <div v-if="attachment.length" class="py-5 text-center font-size-lg">
        {{ attachment.length }}/10
      </div>
      <b-row>
        <b-col
          v-for="(item, index) in attachment"
          :key="index"
          cols="6"
          md="4"
          xl="3"
          class="mt-4"
        >
          <b-card
            no-body
            class="pointer h-100 shadow-sm card-custom rounded-0 overlay"
          >
            <div class="card-body p-0">
              <div class="overlay-wrapper">
                <div
                  :class="`${
                    isImage(item.mediaType) ? 'overflow-hidden' : 'p-3'
                  }`"
                >
                  <div
                    v-if="item.mediaType == mediaType.IMAGE"
                    class="h-100px h-sm-150px h-lg-150px h-xl-200px"
                    :class="`${
                      isImage(item.mediaType) ? 'document-image' : 'document'
                    }`"
                    :style="`background-image: url('${attachmentImage(
                      item.mediaType,
                      item.mediaPath
                    )}'); background-size: 100% 100%;`"
                  ></div>
                  <video
                    v-else-if="item.mediaType == mediaType.VIDEO"
                    class="h-100px h-sm-150px h-lg-150px h-xl-200px"
                  >
                    <source :src="item.mediaPath" />
                  </video>
                </div>
              </div>
              <div class="overlay-layer">
                <div class="text-center">
                  <AppButtonDocumentView
                    :attachmentUrl="item.mediaPath"
                    :fileType="item.mediaType"
                    :buttonType="2"
                  ></AppButtonDocumentView>
                  <b-button
                    v-if="
                      !checkAccessRight(moduleName, [permissionAction.DELETE])
                    "
                    :disabled="attachment.length == 1 ? true : false"
                    variant="shadow-sm font-size-sm"
                    class="btn btn-danger font-weight-bold text-uppercase mt-3"
                    @click="deleteEquipmentDocuments(id, item.id)"
                  >
                    {{ $t(`label.delete`) }}
                  </b-button>
                </div>
              </div>
            </div>
          </b-card>
        </b-col></b-row
      >
    </template>
  </div>
</template>

<script>
  import AppFormField from '@/views/components/bases/form-field/AppFormField';
  import AppButtonDocumentView from '@/views/components/bases/button/AppButtonDocumentView';
  import AppMediaFileStandardMessage from '@/views/components/bases/AppMediaFileStandardMessage';
  import commonMixin from '@/core/mixins/common.mixin';
  import {
    GLOBAL,
    MODULE,
    ACTION,
    ROUTE_NAME,
    bannerVideoType,
    bannerImageType,
    fileSizeImage,
    fileSizeMb
  } from '@/core/constants';
  import { MediaType } from '@/core/constants/enums';
  import { required } from 'vuelidate/lib/validators';
  import { i18nHelper, uiHelper } from '@/core/utils';
  import {
    EQUIPMENT_UPLOAD_EQUIPMENT_DOCUMENTS,
    EQUIPMENT_DELETE_EQUIPMENT_DOCUMENTS,
    EQUIPMENT_INITIAL_UPLOAD_EQUIPMENT_DOCUMENTS_STATE,
    EQUIPMENT_INITIAL_DELETE_EQUIPMENT_DOCUMENTS_STATE
  } from '@/core/store/equipment.module';

  export default {
    name: 'EquipmentDocuments',
    mixins: [commonMixin],
    components: {
      AppFormField,
      AppButtonDocumentView,
      AppMediaFileStandardMessage
    },
    props: {
      id: {
        Type: String,
        default: ''
      },
      equipmentInfo: {
        Type: Object,
        default: {}
      },
      isCreate: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      global: GLOBAL,
      moduleName: MODULE.EQUIPMENTS,
      permissionAction: ACTION,
      routeName: ROUTE_NAME,
      mediaType: MediaType,
      documents: {
        image: null,
        video: null
      },
      formFields: [],
      disabledUpload: {
        video: false,
        image: false
      }
    }),

    mounted() {
      this.initFormFields();
    },
    computed: {
      attachment() {
        if (this.equipmentInfo.documents) {
          return this.equipmentInfo.documents;
        } else {
          return '';
        }
      },
      uploadEquipmentDocumentsComplete() {
        return this.$store.state.equipment.uploadEquipmentDocuments.complete;
      },
      deleteEquipmentDocumentsComplete() {
        return this.$store.state.equipment.deleteEquipmentDocuments.complete;
      }
    },
    watch: {
      uploadEquipmentDocumentsComplete() {
        let response = this.$store.state.equipment.uploadEquipmentDocuments;
        if (response.complete) {
          this.equipmentCompleteAction(response);
        }
      },
      deleteEquipmentDocumentsComplete() {
        let response = this.$store.state.equipment.deleteEquipmentDocuments;
        if (response.complete) {
          this.equipmentCompleteAction(response);
        }
      }
    },
    methods: {
      equipmentCompleteAction(response) {
        let title = i18nHelper.getMessage('label.equipment');
        let messages = response.message;
        let data = response.data;
        let buttons = [];
        if (response.code == 0) {
          this.documents = null;
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              if (this.isCreate) {
                this.$router.push({
                  name: ROUTE_NAME.EQUIPMENT_DETAILS,
                  params: { id: data.id }
                });
              } else {
                this.$parent.getEquipment(this.id);
              }
              this.closeLayoutAppModalInfo();
              this.initialUploadEquipmentDocuments();
              this.initialDeleteEquipmentDocuments();
              this.resetForm();
            }
          });
        } else {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();
              this.initialUploadEquipmentDocuments();
              this.initialDeleteEquipmentDocuments();
            }
          });
        }
        if (messages) this.openLayoutAppModalInfo(title, messages, buttons);
      },
      validationCheckEquipmentDocuments(type) {
        return this.validationObjCheck('documents', type);
      },
      initFormFields() {
        this.formFields = [];
        this.formFields.push(
          {
            model: 'image',
            title: 'image',
            formType: 'file',
            disabled: this.disabledUpload.image,
            validators: ['required', 'bannerImageType', 'fileSize'],
            validationParams: [
              {
                key: 'bannerImageType',
                params: [
                  uiHelper.arrayToTrimmedStringWithSpace(
                    GLOBAL.AVAILABLE_IMAGE_TYPE,
                    6
                  )
                ]
              },
              {
                key: 'fileSize',
                params: [
                  GLOBAL.BANNER_IMAGE_UPLOAD_SIZE,
                  GLOBAL.BANNER_IMAGE_UPLOAD_SIZE_TYPE
                ]
              }
            ],
            accept: GLOBAL.AVAILABLE_IMAGE_TYPE.toString(),
            action: () => {
              this.uploadEquipmentDocumentsAction(this.id, 'image');
            },
            change: (val) => {
              this.actionDisabledUploadFile(val, 'image');
            }
          },
          {
            model: 'video',
            title: 'video',
            formType: 'file',
            disabled: this.disabledUpload.video,
            validators: ['required', 'bannerVideoType', 'fileSize'],
            validationParams: [
              {
                key: 'bannerVideoType',
                params: [
                  uiHelper.arrayToTrimmedStringWithSpace(
                    GLOBAL.AVAILABLE_VIDEO_TYPE,
                    6
                  )
                ]
              },
              {
                key: 'fileSize',
                params: [
                  GLOBAL.BANNER_VIDEO_UPLOAD_SIZE,
                  GLOBAL.BANNER_VIDEO_UPLOAD_SIZE_TYPE
                ]
              }
            ],
            accept: GLOBAL.AVAILABLE_VIDEO_TYPE.toString(),
            action: () => {
              this.uploadEquipmentDocumentsAction(this.id, 'video');
            },
            change: (val) => {
              this.actionDisabledUploadFile(val, 'video');
            }
          }
        );
        this.$v.documents.$reset();
      },
      actionDisabledUploadFile(val, type) {
        this.disabledUpload = {
          video: true,
          image: true
        };
        if (val.srcElement.files[0]) {
          this.disabledUpload[type] = false;
        } else {
          this.disabledUpload = {
            video: false,
            image: false
          };
        }
        this.$emit('input', type);
        this.initFormFields();
      },
      isImage(type) {
        let isImage = false;

        if (type == MediaType.IMAGE) {
          isImage = true;
        }
        return isImage;
      },
      attachmentImage(type, originalUrl) {
        let src = '';

        if (type == MediaType.IMAGE) {
          src = originalUrl;
        } else if (type == MediaType.APPLICATION) {
          src = '/media/svg/files/pdf.svg';
        } else if (type == 'docx' || type == 'doc') {
          src = '/media/svg/files/doc.svg';
        }
        return src;
      },
      resetForm() {
        this.$v.$reset();
        this.documents = {
          image: null,
          video: null
        };
        this.disabledUpload = {
          video: false,
          image: false
        };
        this.initFormFields();
      },
      uploadEquipmentDocumentsAction(id, type) {
        if (this.validationObjCheck('documents', type)) {
          let formData = new FormData();

          formData.append('equipment_id', id);
          formData.append('attachments[]', this.documents[type]);

          this.uploadEquipmentDocuments(id, formData);
        }
      },
      deleteEquipmentDocuments(id, fileId) {
        this.$store.dispatch(EQUIPMENT_DELETE_EQUIPMENT_DOCUMENTS, {
          id,
          fileId
        });
      },
      uploadEquipmentDocuments(id, formData) {
        this.$store.dispatch(EQUIPMENT_UPLOAD_EQUIPMENT_DOCUMENTS, {
          id,
          formData
        });
      },
      initialUploadEquipmentDocuments() {
        this.$store.dispatch(
          EQUIPMENT_INITIAL_UPLOAD_EQUIPMENT_DOCUMENTS_STATE
        );
      },
      initialDeleteEquipmentDocuments() {
        this.$store.dispatch(
          EQUIPMENT_INITIAL_DELETE_EQUIPMENT_DOCUMENTS_STATE
        );
      }
    },
    validations() {
      return {
        documents: {
          image: {
            required,
            bannerImageType,
            fileSize: fileSizeImage(GLOBAL.BANNER_IMAGE_UPLOAD_SIZE)
          },
          video: {
            required,
            bannerVideoType,
            fileSize: fileSizeMb(GLOBAL.BANNER_VIDEO_UPLOAD_SIZE)
          }
        }
      };
    }
  };
</script>

<style></style>
