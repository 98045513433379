-
<template>
  <div>
    <b-button
      variant="light-warning"
      :disabled="disabled"
      :toggle-class="[{ 'cursor-default': disabled }]"
      @click="showUploadDocumentModal"
    >
      <i class="flaticon-upload"></i>
      {{ $t('label.upload') }}
    </b-button>

    <b-modal
      ref="upload-document-modal"
      :title="$t('label.uploadDocument')"
      centered
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      header-class="text-capitalize"
    >
      <div v-for="(i, index) in formFields" :key="index">
        <div v-if="i.layout">
          <label class="font-size-sm"
            >{{ $t(`label.${i.title}`) }}
            <span v-if="i.isRequires" class="text-danger">*</span></label
          >
          <component
            v-model="$v.creatorDocumentObj[i.model].$model"
            :is="i.layout"
            :attachmentUrl="creatorDocumentObj[i.model]"
            :state="validateObjState('creatorDocumentObj', i.model)"
            :accept="availableApplicationDocumentFileType"
            :modelName="i.model"
            @replace-action="replaceAction"
          >
          </component>
        </div>

        <AppFormField
          v-else
          v-model="$v.creatorDocumentObj[i.model].$model"
          :state="validateObjState('creatorDocumentObj', i.model)"
          :validationValue="$v.creatorDocumentObj[i.model]"
          :title="$t(`label.${i.title}`)"
          :solid="true"
          :validators="i.validators"
          :formType="i.formType"
          :options="i.options"
          :disabled="!checkAccessRight(moduleName, [action.APP_INFO_UPDATE])"
          @change="initFormFields"
        ></AppFormField>
        <div style="display: none">
          <!-- WARNING: I have no idea why this line is here, but removing it breaks everything -->
          {{ $v.creatorDocumentObj }}
        </div>
      </div>
      <template #modal-footer>
        <template v-if="checkAccessRight(moduleName, [action.APP_INFO_UPDATE])">
          <b-button
            class="mr-2"
            variant="white"
            @click="hideUploadDocumentModal"
            >{{ $t('label.discard') }}</b-button
          >

          <b-button variant="primary" @click="actionSaveChanges">{{
            $t('label.saveChanges')
          }}</b-button>
        </template>
        <b-button v-else variant="primary" @click="hideUploadDocumentModal">{{
          $t('label.close')
        }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
  import AppFormField from '@/views/components/bases/form-field/AppFormField';
  import commonMixin from '@/core/mixins/common.mixin';
  import AppViewAndUpload from '@/views/components/bases/AppViewAndUpload';
  import { CreatorType } from '@/core/constants/enums';
  import { GLOBAL, MODULE, ACTION } from '@/core/constants';
  import { i18nHelper } from '@/core/utils';
  import { required } from 'vuelidate/lib/validators';
  import { listService } from '@/core/services';
  import { validationMixin } from 'vuelidate';
  import {
    CREATOR_APPLICATION_GET_CREATOR_DOCUMENT,
    CREATOR_APPLICATION_UPLOAD_DOCUMENT,
    CREATOR_APPLICATION_INITIAL_GET_CREATOR_DOCUMENT_STATE,
    CREATOR_APPLICATION_INITIAL_UPLOAD_DOCUMENT_STATE
  } from '@/core/store/creator-application.module';

  export default {
    name: 'ApplicationUploadDocumentDropdown',
    mixins: [commonMixin, validationMixin],
    components: {
      AppViewAndUpload,
      AppFormField
    },
    props: {
      id: {
        type: Number,
        default: 0,
        required: true
      },
      creatorType: {
        type: Number,
        default: CreatorType.INDIVIDUAL
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      moduleName: MODULE.APP_INFO,
      action: ACTION,
      creatorDocumentObj: {
        creatorType: null,
        icFront: null,
        icBack: null,
        bankStatement: null,
        businessLicense: null,
        halalCert: null,
        sstCert: null
      },
      formFields: []
    }),
    computed: {
      creatorDocumentComplete() {
        return this.$store.state.creatorApplication.creatorDocument.complete;
      },
      updateCreatorDocumentComplete() {
        return this.$store.state.creatorApplication.uploadDocument.complete;
      },
      availableApplicationDocumentFileType() {
        return GLOBAL.AVAILABLE_BANNER_IMAGE_TYPE.toString();
      }
    },
    watch: {
      creatorDocumentComplete() {
        let response = this.$store.state.creatorApplication.creatorDocument;
        let title = i18nHelper.getMessage('label.getAppInfo');
        let initialStateAction =
          CREATOR_APPLICATION_INITIAL_GET_CREATOR_DOCUMENT_STATE;
        let successAction = (response) => {
          this.getCreatorDocumentCompleteActions(response);
        };
        if (response.complete) {
          this.actionReadRecordComplete(
            response,
            title,
            initialStateAction,
            successAction
          );
        }
      },
      updateCreatorDocumentComplete() {
        var response = this.$store.state.creatorApplication.uploadDocument;
        var title = i18nHelper.getMessage('label.uploadDocument');
        var initialStateAction =
          CREATOR_APPLICATION_INITIAL_UPLOAD_DOCUMENT_STATE;
        var successAction = () => {
          this.uploadDocumentCompleteAction();
        };

        if (response.complete)
          this.actionUpdateRecordComplete(
            response,
            title,
            initialStateAction,
            successAction
          );
      }
    },
    methods: {
      // Modal Show & Get Data :Start
      showUploadDocumentModal() {
        this.getCreatorDocument(this.id);
      },
      getCreatorDocument(id) {
        this.$store.dispatch(CREATOR_APPLICATION_GET_CREATOR_DOCUMENT, {
          id
        });
      },
      getCreatorDocumentCompleteActions(response) {
        this.creatorDocumentObj = response.data;
        this.$v.$reset();
        this.$refs['upload-document-modal'].show();
        this.initFormFields();
      },
      initFormFields() {
        this.formFields = [];
        if (this.creatorDocumentObj.creatorType == 0) {
          this.formFields.push(
            {
              model: 'creatorType',
              title: 'creatorType',
              validators: ['required'],
              formType: 'select',
              options: listService.getCreatorTypeList()
            },
            {
              model: 'icFront',
              title: 'icFront',
              layout: 'AppViewAndUpload',
              isRequires: true
            },
            {
              model: 'icBack',
              title: 'icBack',
              layout: 'AppViewAndUpload',
              isRequires: true
            },
            {
              model: 'bankStatement',
              title: 'bankStatement',
              layout: 'AppViewAndUpload',
              isRequires: true
            }
          );
        } else {
          this.formFields.push(
            {
              model: 'creatorType',
              title: 'creatorType',
              validators: ['required'],
              formType: 'select',
              options: listService.getCreatorTypeList()
            },
            {
              model: 'businessLicense',
              title: 'businessLicense',
              layout: 'AppViewAndUpload',
              isRequires: true
            },
            {
              model: 'bankStatement',
              title: 'bankStatement',
              layout: 'AppViewAndUpload',
              isRequires: true
            },
            {
              model: 'halalCert',
              title: 'halalCert',
              layout: 'AppViewAndUpload'
            },
            {
              model: 'sstCert',
              title: 'sstCert',
              layout: 'AppViewAndUpload'
            }
          );
        }
      },
      // Modal Show & Get Data :End
      // Modal Hide & Reset Data :Start
      hideUploadDocumentModal() {
        this.resetForm();
        this.$refs['upload-document-modal'].hide();
      },
      resetForm() {
        this.$v.$reset();
        this.formFields = [];
        this.creatorDocumentObj = {
          creatorType: null,
          icFront: null,
          icBack: null,
          bankStatement: null,
          businessLicense: null,
          halalCert: null,
          sstCert: null
        };
      },
      // Modal Hide & Reset Data :End
      // Replace Document Action : Start
      replaceAction(value) {
        this.creatorDocumentObj[value] = null;
      },
      // Replace Document Action : End
      // Upload Creator Document Action :Start
      uploadDocumentCompleteAction() {
        this.$emit('complete');
        this.hideUploadDocumentModal();
      },
      actionSaveChanges() {
        // if (this.validationCheck('creatorDocumentObj')) {
        this.uploadDocument(this.id, this.creatorDocumentObj);
        // }
      },
      uploadDocument(id, data) {
        this.$store.dispatch(CREATOR_APPLICATION_UPLOAD_DOCUMENT, { id, data });
      }
      // Upload Creator Document Action :End
    },
    validations: {
      creatorDocumentObj: {
        creatorType: {
          required
        },
        icFront: {
          required
        },
        icBack: {},
        bankStatement: {},
        businessLicense: {},
        halalCert: {},
        sstCert: {}
      }
    }
  };
</script>

<style></style>
