import { apiService } from '@/core/services';
// import { apiHelper } from '@/core/utils';

export const CONFIG_WATI_TESTING = {
  getWATITesting,
  updateWATITesting
};

const URL = {
  ADMIN: 'admin',
  CONFIGURATIONS: 'configurations',
  UPDATE_STATUS: 'update-status'
};

function getWATITesting() {
  return apiService().get(`/${URL.ADMIN}/${URL.CONFIGURATIONS}`);
}

function updateWATITesting(data) {
  // status: 0 - Inactive 1 - Active
  let id = 1; // defualt 1 (this id for now only got 1)
  let status = data ? 1 : 0;
  let requestBody = {
    status: status
  };
  return apiService().patch(
    `/${URL.ADMIN}/${URL.CONFIGURATIONS}/${id}/${URL.UPDATE_STATUS}`,
    requestBody
  );
}
