<template>
  <b-tr>
    <b-td :stacked-heading="$t('label.property')">{{
      $t(`label.${key(item.key)}`)
    }}</b-td>
    <template v-for="(i, index) in keys">
      <b-td :key="index" :stacked-heading="$t(`label.${i}`)">
        <span v-if="item.key == 'isActive'">
          {{ $t(`enumActivationStatus.${Number(item[i])}`) }}
        </span>
        <div v-else>
          {{ item[i] }}
        </div>
      </b-td>
    </template>
  </b-tr>
</template>

<script>
  export default {
    name: 'ChangesUserRow',
    props: {
      item: {
        type: Object,
        default: () => {}
      }
    },
    data: () => ({
      keys: ['from', 'to']
    }),
    methods: {
      key(value) {
        switch (value) {
          case 'isActive':
            return 'status';
        }
      }
    }
  };
</script>

<style></style>
