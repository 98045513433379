<template>
  <VoucherDetailsTemplate></VoucherDetailsTemplate>
</template>

<script>
  import VoucherDetailsTemplate from '@/views/components/voucher/VoucherDetailsTemplate.vue';

  export default {
    name: 'VoucherDetails',
    components: {
      VoucherDetailsTemplate
    }
  };
</script>

<style></style>
