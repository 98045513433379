import { apiService } from '@/core/services';

export const EQUIPMENT = {
  getEquipments,
  getEquipment,
  getEquipmentRecipes,
  getEquipmentDeliveryFees,
  createEquipment,
  updateEquipment,
  updateEquipmentStatus,
  updateEquipmentDeliveryFees,
  uploadEquipmentDocuments,
  deleteEquipment,
  deleteEquipmentDocuments
};

const URL = {
  ADMIN: 'admin',
  EQUIPMENTS: 'equipments',
  RECIPES: 'recipe',
  DOCUMENTS: 'documents',
  SET_ACTIVE: 'set-active',
  UPLOAD_DOCUMENTS: 'upload-documents',
  DESTROY_DOCUMENTS: 'destroy-documents',
  DELIVERY_FEES: 'delivery-fees'
};

function getEquipments(data) {
  let requestOptions = {
    params: {
      name: data.name,
      brand_name: data.brandName,
      id: data.id,
      per_page: data.perPage,
      page: data.page
    }
  };
  return apiService().get(`/${URL.ADMIN}/${URL.EQUIPMENTS}`, requestOptions);
}

function getEquipment(id) {
  return apiService().get(`/${URL.ADMIN}/${URL.EQUIPMENTS}/${id}`);
}

function getEquipmentRecipes(id, data) {
  let requestOptions = {
    params: {
      per_page: data.perPage,
      page: data.page
    }
  };
  return apiService().get(
    `/${URL.ADMIN}/${URL.EQUIPMENTS}/${id}/${URL.RECIPES}`,
    requestOptions
  );
}

function getEquipmentDeliveryFees(id) {
  return apiService().get(
    `/${URL.ADMIN}/${URL.EQUIPMENTS}/${id}/${URL.DELIVERY_FEES}`
  );
}

function createEquipment(data) {
  let formData = new FormData();

  formData.append('brand_name', data.brandName);
  formData.append('name', data.equipmentName);
  formData.append('thumbnail', data.thumbnail);
  formData.append('price', data.sellingPrice);
  formData.append('discount', data.discountValueType.value);
  formData.append('discount_type', data.discountValueType.type);
  formData.append('feature', data.features);
  formData.append('specification', data.specification);
  formData.append('warranty_info', data.warrantyInfo);
  formData.append('product_assurance', data.productAssurance);
  formData.append('term_and_condition', data.termsAndConditionApply);
  formData.append('remark', data.remark);

  return apiService().post(`/${URL.ADMIN}/${URL.EQUIPMENTS}`, formData);
}

function updateEquipment(id, data) {
  let formData = new FormData();

  formData.append('brand_name', data.brandName);
  formData.append('name', data.equipmentName);
  formData.append(
    'thumbnail',
    !(data.thumbnail instanceof Blob) ? '' : data.thumbnail
  );
  formData.append('price', data.sellingPrice);
  formData.append('discount', data.discountValueType.value);
  formData.append('discount_type', data.discountValueType.type);
  formData.append('feature', data.features);
  formData.append('specification', data.specification);
  formData.append('warranty_info', data.warrantyInfo);
  formData.append('product_assurance', data.productAssurance);
  formData.append('term_and_condition', data.termsAndConditionApply);
  formData.append('remark', data.remark);

  return apiService().post(`/${URL.ADMIN}/${URL.EQUIPMENTS}/${id}`, formData);
}

function updateEquipmentDeliveryFees(id, data) {
  let requestBody = {
    fees: data.deliveryFees,
    discount_type: data.discountValueType.type, // can be null if didn't select discount type : 1 - fix amount, 2 - percentage
    discount_value: data.discountValueType.value
  };
  return apiService().patch(
    `/${URL.ADMIN}/${URL.EQUIPMENTS}/${id}/${URL.DELIVERY_FEES}`,
    requestBody
  );
}

function uploadEquipmentDocuments(formData) {
  return apiService().post(
    `/${URL.ADMIN}/${URL.EQUIPMENTS}/${URL.UPLOAD_DOCUMENTS}`,
    formData
  );
}

function updateEquipmentStatus(id, data) {
  let requestBody = {
    is_active: data
  };
  return apiService().put(
    `/${URL.ADMIN}/${URL.EQUIPMENTS}/${id}/${URL.SET_ACTIVE}`,
    requestBody
  );
}

function deleteEquipmentDocuments(id, fileId) {
  return apiService().delete(
    `/${URL.ADMIN}/${URL.EQUIPMENTS}/${id}/${URL.DESTROY_DOCUMENTS}/${fileId}`
  );
}

function deleteEquipment(id) {
  return apiService().delete(`/${URL.ADMIN}/${URL.EQUIPMENTS}/${id}`);
}
