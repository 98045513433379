<template>
  <div class="app-table-actions">
    <!-- begin:: Read Actions -->
    <router-link
      v-if="layoutActions.viewDetailsRouteName"
      :to="{
        name: layoutActions.viewDetailsRouteName,
        params: { id: item.id, menu: subMenu }
      }"
      class="btn btn-sm btn-clean btn-icon"
    >
      <i class="icon-nm" :class="readOrEditIcons"></i>
    </router-link>
    <a
      v-else-if="layoutActions.isDownload"
      class="btn btn-sm btn-clean btn-icon"
      @click="actionDownload(item)"
    >
      <i class="icon-nm" :class="readOrEditIcons"></i>
    </a>
    <a
      v-else-if="isShowDeleteButton"
      class="btn btn-sm btn-clean btn-icon"
      @click="actionDelete(item.id)"
    >
      <i class="far fa-trash-alt icon-nm"></i>
    </a>
    <a
      v-else
      class="btn btn-sm btn-clean btn-icon"
      @click="actionReadDetails(item.id, item)"
    >
      <i class="icon-nm" :class="readOrEditIcons"></i>
    </a>
    <!-- end:: Read Actions -->

    <!-- begin:: Delete Action -->
    <!-- <a
      v-if="isShowDeleteButton"
      class="btn btn-sm btn-clean btn-icon"
      @click="actionDelete(item.id)"
    >
      <i class="far fa-trash-alt icon-nm"></i>
    </a> -->
    <!-- begin:: Delete Action -->
  </div>
</template>

<script>
  import commonMixin from '@/core/mixins/common.mixin';

  export default {
    name: 'AppTableActions',
    mixins: [commonMixin],
    props: {
      item: {
        type: Object,
        default: () => {}
      },
      layoutActions: {
        type: Object,
        default: () => {
          return {
            hasActionDelete: false,
            viewDetailsRouteName: ''
          };
        }
      },
      accessRights: {
        type: Object,
        default: () => {
          return {
            moduleName: '',
            actions: {
              create: '',
              read: '',
              update: '',
              delete: ''
            }
          };
        }
      },
      subMenu: {
        type: String,
        default: null
      }
    },
    computed: {
      readOrEditIcons() {
        let accessRights = this.accessRights;
        if (
          this.checkAccessRight(accessRights.moduleName, [
            accessRights.actions.update
          ]) &&
          !this.layoutActions.isDownload
        ) {
          return 'far fa-edit';
        } else if (this.layoutActions.isDownload) {
          return 'fa fa-download';
        } else {
          return 'far fa-eye';
        }
      },
      isShowDeleteButton() {
        var accessRights = this.accessRights;

        return (
          this.layoutActions.hasActionDelete &&
          this.checkAccessRight(accessRights.moduleName, [
            accessRights.actions.delete
          ])
        );
      }
    },
    methods: {
      actionDelete(value) {
        this.$emit('app-table-actions-action-delete', value);
      },
      actionReadDetails(value, obj) {
        this.$emit('app-table-actions-open-modal-details', value, obj);
      },
      actionDownload(value) {
        this.$emit('app-table-actions-download', value);
      }
    }
  };
</script>

<style></style>
