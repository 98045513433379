<template>
  <!-- 0 - Pending, 1 - Confirm, 2 - Cancel -->
  <b-dropdown
    size="xl"
    class="pr-3"
    :text="$t('label.actions')"
    variant="primary"
  >
    <b-dropdown-item
      v-if="studioBookingInfo.status != 1"
      :disabled="
        !checkAccessRight(moduleName, [permissionAction.STUDIO_UPDATE])
      "
      variant="success"
      @click="updateBookingStatus(1)"
      >{{ $t('label.confirm') }}</b-dropdown-item
    >
    <b-dropdown-item
      v-if="studioBookingInfo.status != 2"
      :disabled="
        !checkAccessRight(moduleName, [permissionAction.STUDIO_UPDATE])
      "
      variant=""
      @click="updateBookingStatus(2)"
      >{{ $t('label.cancel') }}</b-dropdown-item
    >
    <b-dropdown-item
      :disabled="
        !checkAccessRight(moduleName, [permissionAction.STUDIO_DELETE])
      "
      variant=""
      @click="deleteStudioBooking(id)"
      >{{ $t('label.delete') }}</b-dropdown-item
    >
  </b-dropdown>
</template>

<script>
  import commonMixin from '@/core/mixins/common.mixin';
  import { MODULE, ACTION, ROUTE_NAME } from '@/core/constants';
  import { i18nHelper } from '@/core/utils';
  import {
    STUDIO_UPDATE_BOOKING_STATUS,
    STUDIO_DELETE_BOOKING,
    STUDIO_INITIAL_UPDATE_BOOKING_STATUS_STATE,
    STUDIO_INITIAL_DELETE_BOOKING_STATE
  } from '@/core/store/studio.module';

  export default {
    name: 'StudioBookingStatusBtn',
    mixins: [commonMixin],
    data: () => ({
      moduleName: MODULE.STUDIO,
      permissionAction: ACTION,
      routeName: ROUTE_NAME
    }),
    props: {
      id: {
        Type: Number,
        default: null
      },
      studioBookingInfo: {
        Type: Object,
        default: {
          status: null
        }
      }
    },
    computed: {
      updateStudioBookingStatusComplete() {
        return this.$store.state.studio.updateBookingStatus.complete;
      },
      deleteStudioBookingComplete() {
        return this.$store.state.studio.deleteBooking.complete;
      }
    },
    watch: {
      updateStudioBookingStatusComplete() {
        let response = this.$store.state.studio.updateBookingStatus;
        if (response.complete) {
          this.studioBookingCompleteAction(response, 'update');
        }
      },
      deleteStudioBookingComplete() {
        let response = this.$store.state.studio.deleteBooking;
        if (response.complete) {
          this.studioBookingCompleteAction(response, 'delete');
        }
      }
    },
    methods: {
      studioBookingCompleteAction(response, actionType) {
        let title = i18nHelper.getMessage('label.studioBooking');
        let messages = response.message;
        let buttons = [];
        switch (actionType) {
          case 'update':
            this.initialUpdateStudioBookingStatus();
            break;
          case 'delete':
            this.initialDeleteStudioBookingState();
            break;
        }
        if (response.code == 0) {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();
              switch (actionType) {
                case 'delete':
                  this.$router.push({
                    name: ROUTE_NAME.STUDIO_BOOKING_LIST
                  });
                  break;
                default:
                  this.$parent.$parent.getStudioBooking(this.id);
              }
            }
          });
        } else {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();
            }
          });
        }
        if (messages) this.openLayoutAppModalInfo(title, messages, buttons);
      },
      updateBookingStatus(data) {
        let id = this.id;
        this.$store.dispatch(STUDIO_UPDATE_BOOKING_STATUS, { id, data });
      },
      deleteStudioBooking(id) {
        this.$store.dispatch(STUDIO_DELETE_BOOKING, { id });
      },
      initialUpdateStudioBookingStatus() {
        this.$store.dispatch(STUDIO_INITIAL_UPDATE_BOOKING_STATUS_STATE);
      },
      initialDeleteStudioBookingState() {
        this.$store.dispatch(STUDIO_INITIAL_DELETE_BOOKING_STATE);
      }
    }
  };
</script>

<style></style>
