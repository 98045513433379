<template>
  <div>
    <!--begin: Status label -->
    <div class="d-flex justify-content-end">
      <VoucherStatusLabel v-if="!isCreate" :styleName="'font-size: 14px; padding: 15px; '" :value="voucherObj.status">
      </VoucherStatusLabel>
    </div>
    <!--end: Status label -->
    <!--begin: Form -->
    <b-form class="form" id="kt_form">
      <b-row no-gutters>
        <template v-for="(i, index) in formFields.filter((x) => x.field == 1)">
          <b-col :key="index" xl="6" class="p-xl-1" v-if="!i.isHidden">
            <h6 v-if="index == 0 || index == 8" class="w-100 pt-5 font-weight-bolder text-capitalize">
              <div>
                {{
                  index == 0
                  ? $t(`label.basicInformation`)
                  : $t(`label.rewardSetting`)
                }}
              </div>
              <hr width="100%" />
            </h6>
            <div v-if="i.layout">
              <label class="font-size-sm text-capitalize">{{ $t(`label.${i.title}`) }}
                <span v-if="i.isRequires" class="text-danger">*</span></label>
              <component v-model="$v.voucherObj[i.model].$model" :state="validateObjState('voucherObj', i.model)"
                :is="i.layout" :validators="i.validators" :validationParams="i.validationParams"
                :validationValue="$v.voucherObj[i.model]" :item="voucherObj" :value="voucherObj[i.model]"
                :options="i.options" :optionsValue="i.optionsValue" :attachmentUrl="voucherObj[i.model]"
                :fileType="i.fileType" :isCreate="isCreate" :disabled="i.disabled" :solid="!i.disabled ?? true"
                :modelName="i.model" :config="i.config" :accept="i.accept" @replace-action="replaceAction"></component>
            </div>
            <AppFormField v-else v-model="$v.voucherObj[i.model].$model" :title="$t(`label.${i.title}`)"
              :validators="i.validators" :validationParams="i.validationParams" :validationValue="$v.voucherObj[i.model]"
              :state="validateObjState('voucherObj', i.model)" :type="i.type" :formType="i.formType" :options="i.options"
              :disabled="i.disabled" :textTransform="i.textTransform" :isRequires="i.isRequires" :max="i.max"
              :solid="!i.disabled" :config="i.config" @change="i.action" @searchChange="i.searchChange">
            </AppFormField>
            <div style="display: none">
              <!-- WARNING: I have no idea why this line is here, but removing it breaks everything -->
              {{ $v }}
            </div>
          </b-col>
        </template>
      </b-row>
    </b-form>
    <!--end: Form -->
    <!--begin: Actions -->
    <div class="d-flex justify-content-end border-top pt-10">
      <VoucherStatusBtn v-if="!isCreate" :id="id" :voucherObj="voucherObj"></VoucherStatusBtn>
      <button :disabled="!checkAccessRight(moduleName, [
        isCreate
          ? permissionAction.VOUCHER_CREATE
          : permissionAction.VOUCHER_UPDATE
      ])
        " @click="validationCheckBeforeSubmit()" class="btn btn-primary font-weight-bold text-caitalize">
        {{ $t(`label.${isCreate ? 'create' : 'update'}`) }}
      </button>
    </div>
    <!--end: Actions -->
  </div>
</template>

<script>
import AppFormField from '@/views/components/bases/form-field/AppFormField';
import AppAmountType from '@/views/components/bases/AppAmountType';
import AppViewAndUpload from '@/views/components/bases/AppViewAndUpload';
import VoucherStatusLabel from '@/views/components/voucher/VoucherStatusLabel';
import VoucherStatusBtn from '@/views/components/voucher/VoucherStatusBtn.vue';
import { MediaType, VoucherSourceType } from '@/core/constants/enums';
import { uiHelper } from '@/core/utils';
import { listService, listApiService } from '@/core/services';
import { validationMixin } from 'vuelidate';
import commonMixin from '@/core/mixins/common.mixin';
import {
  required,
  requiredIf,
  minLength,
  maxLength,
  numeric
} from 'vuelidate/lib/validators';
import {
  GLOBAL,
  MODULE,
  LOCAL,
  ACTION,
  ROUTE_NAME,
  fileSize,
  bannerImageType,
  price,
  platformInfoApplicationType
} from '@/core/constants';

import {
  LOADING_OPEN_LOADING,
  LOADING_CLOSE_LOADING
} from '@/core/store/loading.module';

const CONSTANTS_DATA = JSON.parse(uiHelper.getLocalStorage(LOCAL.CONSTANTS));

export default {
  name: 'voucherForm',
  mixins: [commonMixin, validationMixin],
  components: {
    AppFormField,
    AppAmountType,
    AppViewAndUpload,
    VoucherStatusLabel,
    VoucherStatusBtn
  },
  props: {
    id: {
      Type: [Number, String],
      defualt: null
    },
    voucherObj: {
      Type: Object,
      default: () => { }
    },
    isCreate: {
      Type: Boolean,
      default: false
    }
  },
  data: () => ({
    moduleName: MODULE.VOUCHER,
    permissionAction: ACTION,
    global: GLOBAL,
    routeName: ROUTE_NAME,
    formFields: [],
    isLoading: false,
    selectedId: null,
    selectedType: null,
    disabledCreator: true,
    disabledMerchant: true,
    disabledBranch: true,
    disabledCookx: false,
    getMerchantCreatorId: [],
    getDineInMerchantId: [],
    getMerchantOrCreatorBranch: [],
    isAllDineIn: false,
    isMultiple: false
  }),
  created() {
    this.initFormFields();
  },
  computed: {},
  watch: {},
  methods: {
    validationCheckBeforeSubmit() {
      //Submit new banner form if all the validation is no error
      if (this.validationCheck('voucherObj')) {
        this.$parent.submitVoucher(this.voucherObj);
      }
    },
    // Replace Document Action : Start
    replaceAction(value) {
      this.voucherObj[value] = null;
    },
    // Replace Document Action : End
    // Get Object Action : Start
    async getVoucherCompleteActions(response) {
      await this.actionSeletedTypeChange(response.data.voucherSource);
      if (response.data.voucherSource == VoucherSourceType.MERCHANT) {
        await this.actionSeletedTypeIdChange(
          'creator',
          response.data.selectedCreator
        );
      } else if (response.data.voucherSource == VoucherSourceType.CREATOR) {
        await this.actionSeletedTypeIdChange(
          'merchant',
          response.data.selectedDineMerchant
        );
      }
      await this.initFormFields();

      if (!this.isCreate) {
        this.voucherObj = response.data;
      }
    },
    // Get Object Action : End
    // Check And Process Creator/ Merchant Id To Get Branch List Action : Start
    async actionSeletedTypeIdChange(key, value) {
      this.$store.dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });

      if (this.isCreate) this.voucherObj.selectedBranch = null;

      value.length > 1 ? (this.isMultiple = true) : (this.isMultiple = false);

      if (key && value)
        this.getMerchantOrCreatorBranch =
          await listApiService.getMerchantOrCreatorBranch(key, value);
      this.isAllDineIn = this.getMerchantOrCreatorBranch.allDineIn;
      this.getMerchantOrCreatorBranch = this.getMerchantOrCreatorBranch.list;
      if (this.isCreate && key == 'creator') {
        if (this.isAllDineIn) {
          this.voucherObj.voucherType = 1;
          this.disabledCookx = !this.isAllDineIn;
        } else {
          // voucherType 2 is Delivery/Pickup
          this.voucherObj.voucherType = 2;
          this.disabledCookx = !this.isAllDineIn;
        }
      }

      await this.initFormFields();
      this.$store.dispatch(`${LOADING_CLOSE_LOADING}`, null, {
        root: true
      });
    },
    // Check And Process Creator/ Merchant Id To Get Branch List Action : End
    // Check Seleted Voucher Source Type And Show Selet Creator/ Merchant ID Action : Start
    async actionSeletedTypeChange(val) {
      // 1 = Creator
      // 2 = Dine-in Merchant
      // 3 = CookX
      this.$store.dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
      this.disabledCreator = true;
      this.disabledMerchant = true;
      this.disabledBranch = true;
      this.disabledCookx = false;
      this.selectedType = null;

      if (this.isCreate) {
        this.voucherObj.selectedDineMerchant = null;
        this.voucherObj.selectedCreator = null;
        this.voucherObj.selectedBranch = null;
      }

      switch (val) {
        case 1:
          this.disabledCreator = false;
          this.disabledBranch = false;
          this.getMerchantCreatorId =
            await listApiService.getMerchantCreatorId();
          break;
        case 2:
          this.disabledMerchant = false;
          this.disabledBranch = false;
          this.disabledCookx = true;
          this.voucherObj.voucherType = 1;
          this.getDineInMerchantId =
            await listApiService.getDineInMerchantId();

          break;
        case 3:
          this.disabledCookx = true;
          this.voucherObj.voucherType = 2;
          break;
      }
      await this.initFormFields();
      this.$store.dispatch(`${LOADING_CLOSE_LOADING}`, null, {
        root: true
      });
    },
    // Check Seleted Voucher Source Type And Show Selet Creator/ Merchant ID Action : End
    // inital Form Fields Action : Start
    // refesh commit
    async initFormFields() {
      this.formFields = [
        {
          field: 1,
          title: 'applyTo',
          model: 'applyTo',
          formType: 'select',
          options: listService.getDynamicList(CONSTANTS_DATA.voucherApplyTo),
          disabled: !this.isCreate,
          isRequires: true,
          validators: ['required'],
          action: () => { },
          searchChange: () => { }
        },
        {
          field: 1,
          title: 'voucherSource',
          model: 'voucherSource',
          formType: 'select',
          options: [
            { text: 'Creator', value: 1 },
            { text: 'Dine-in Merchant', value: 2 },
            { text: 'CookX', value: 3 }
          ],
          disabled: !this.isCreate,
          isRequires: true,
          validators: ['required'],
          action: (val) => {
            this.actionSeletedTypeChange(val);
          },
          searchChange: () => { }
        },
        {
          field: 1,
          title: 'selectedCreator',
          model: 'selectedCreator',
          formType: 'searchSelectAll',
          options: this.getMerchantCreatorId,
          isRequires: true,
          validators: ['required'],
          isHidden: this.disabledCreator,
          disabled: !this.isCreate,
          action: (val) => {
            this.actionSeletedTypeIdChange('creator', val);
          },
          searchChange: () => { }
        },
        {
          field: 1,
          title: 'selectedDineMerchant',
          model: 'selectedDineMerchant',
          formType: 'searchSelectAll',
          options: this.getDineInMerchantId,
          isRequires: true,
          validators: ['required'],
          isHidden: this.disabledMerchant,
          disabled: !this.isCreate,
          config: {
            isLoading: this.isLoading
          },
          action: (val) => {
            this.actionSeletedTypeIdChange('merchant', val);
          },
          searchChange: () => { }
        },
        {
          field: 1,
          title: 'selectedBranch',
          model: 'selectedBranch',
          formType: 'searchSelectAll',
          options: this.getMerchantOrCreatorBranch,
          isRequires: true,
          validators: ['required'],
          isHidden: this.disabledBranch,
          config: {
            isLoading: this.isLoading
          },
          action: () => { },
          searchChange: () => { }
        },
        {
          field: 1,
          title: 'groupName',
          model: 'groupName',
          isRequires: true,
          validators: ['required'],
          isHidden: !this.isMultiple,
          action: () => { },
          searchChange: () => { }
        },
        {
          field: 1,
          title: 'usagePeriodFrom',
          model: 'usagePeriodFrom',
          formType: 'date',
          isRequires: true,
          validators: ['required'],
          action: () => { },
          searchChange: () => { }
        },
        {
          field: 1,
          title: 'usagePeriodTo',
          model: 'usagePeriodTo',
          formType: 'date',
          isRequires: true,
          validators: ['required'],
          action: () => { },
          searchChange: () => { }
        },
        {
          field: 1,
          title: 'redemptionQuantity',
          model: 'quantity',
          type: 'number',
          validators: ['numeric'],
          action: () => { },
          searchChange: () => { }
        },
        {
          field: 1,
          title: 'eventName',
          model: 'eventName',
          action: () => { },
          searchChange: () => { }
        },
        {
          field: 1,
          title: 'voucherPrefix',
          model: 'voucherPrefix',
          validators: ['required', 'minMaxLength'],
          disabled: !this.isCreate,
          isRequires: true,
          validationParams: [
            {
              key: 'minMaxLength',
              params: [3, 5]
            }
          ],
          textTransform: true,
          action: () => { },
          searchChange: () => { }
        },
        {
          field: 1,
          title: 'discount',
          model: 'voucherValueType',
          layout: 'AppAmountType',
          disabled: !this.isCreate,
          isRequires: true,
          options: listService.getAmoutTypeList(false),
          searchChange: () => { }
        },
        {
          field: 1,
          title: 'minimumSpending',
          model: 'minimumSpending',
          // type: 'number',
          isRequires: true,
          validators: ['required', 'price'],
          action: () => { },
          searchChange: () => { }
        },
        {
          field: 1,
          title: 'voucherType',
          model: 'voucherType',
          formType: 'radios',
          isRequires: true,
          disabled: !this.isCreate || this.disabledCookx,
          options: listService.getVoucherTypeList(false),
          action: () => { },
          searchChange: () => { }
        },
        {
          field: 1,
          title: 'banner',
          model: 'banner',
          formType: 'file',
          isRequires: true,
          layout: 'AppViewAndUpload',
          validators: [
            'required',
            'bannerImageType',
            'fileSize'
            // 'bannerImageDimension'
          ],
          validationParams: [
            {
              key: 'bannerImageType',
              params: [
                uiHelper.arrayToTrimmedStringWithSpace(
                  GLOBAL.AVAILABLE_BANNER_IMAGE_TYPE,
                  6
                )
              ]
            },
            {
              key: 'fileSize',
              params: [
                GLOBAL.BANNER_IMAGE_UPLOAD_SIZE,
                GLOBAL.BANNER_IMAGE_UPLOAD_SIZE_TYPE
              ]
            }
            // {
            //   key: 'bannerImageDimension',
            //   params: [dimension.height, dimension.width]
            // }
          ],
          accept: GLOBAL.AVAILABLE_BANNER_IMAGE_TYPE.toString(),
          config: {
            dimension: {
              height: 400,
              width: 400
            },
            uploadSize: 100,
            sizeType: 'KB',
            mediaType: MediaType.IMAGE
          },
          action: () => { },
          searchChange: () => { }
        },
        {
          field: 1,
          title: 'thumbnail',
          model: 'thumbnail',
          formType: 'file',
          isRequires: true,
          layout: 'AppViewAndUpload',
          validators: [
            'required',
            'bannerImageType',
            'fileSize'
            // 'bannerImageDimension'
          ],
          validationParams: [
            {
              key: 'bannerImageType',
              params: [
                uiHelper.arrayToTrimmedStringWithSpace(
                  GLOBAL.AVAILABLE_BANNER_IMAGE_TYPE,
                  6
                )
              ]
            },
            {
              key: 'fileSize',
              params: [
                GLOBAL.BANNER_IMAGE_UPLOAD_SIZE,
                GLOBAL.BANNER_IMAGE_UPLOAD_SIZE_TYPE
              ]
            }
            // {
            //   key: 'bannerImageDimension',
            //   params: [dimension.height, dimension.width]
            // }
          ],
          accept: GLOBAL.AVAILABLE_BANNER_IMAGE_TYPE.toString(),
          config: {
            dimension: {
              height: 400,
              width: 400
            },
            uploadSize: 100,
            sizeType: 'KB',
            mediaType: MediaType.IMAGE
          },
          action: () => { },
          searchChange: () => { }
        },
        {
          field: 1,
          title: 'voucherTitle',
          model: 'voucherTitle',
          validators: ['required'],
          isRequires: true,
          action: () => { },
          searchChange: () => { }
        },
        {
          field: 1,
          title: 'description',
          model: 'description',
          isRequires: true,
          formType: 'textarea',
          validators: ['required', 'maxLength'],
          validationParams: [
            {
              key: 'maxLength',
              params: [500]
            }
          ],
          action: () => { },
          searchChange: () => { }
        },
        {
          field: 1,
          title: 'redemptionPeriodFrom',
          isRequires: true,
          formType: 'date',
          model: 'redemptionPeriodFrom',
          validators: ['required'],
          action: () => { },
          searchChange: () => { }
        },
        {
          field: 1,
          title: 'redemptionPeriodTo',
          isRequires: true,
          formType: 'date',
          model: 'redemptionPeriodTo',
          validators: ['required'],
          action: () => { },
          searchChange: () => { }
        },
        {
          field: 1,
          title: 'termsAndConditions',
          model: 'tnc',
          formType: 'textEditor',
          isRequires: true,
          validators: ['required'],
          action: () => { },
          searchChange: () => { }
        }
      ];
    }
    // inital Form Fields Action : End
  },
  validations() {
    // let bannerFileSize = this.bannerFileSize;
    const hasFile = (val, type) => {
      if (typeof val === 'string') {
        return false;
      } else {
        if (type == GLOBAL.BANNER_IMAGE_UPLOAD_SIZE) {
          return fileSize(GLOBAL.BANNER_IMAGE_UPLOAD_SIZE);
        } else if (type == 'bannerImageType') {
          return bannerImageType;
        } else if (type == GLOBAL.AVAILABLE_PLATFORM_INFO_APPLICATION_TYPE) {
          return platformInfoApplicationType;
        }
      }
    };

    return {
      voucherObj: {
        applyTo: { required },
        voucherSource: { required },
        selectedCreator: {
          required: requiredIf(function () {
            return !this.disabledCreator;
          })
        },
        selectedDineMerchant: {
          required: requiredIf(function () {
            return !this.disabledMerchant;
          })
        },
        selectedBranch: {
          required: requiredIf(function () {
            return !this.disabledBranch;
          })
        },
        groupName: {
          required: requiredIf(function () {
            return this.isMultiple;
          })
        },
        usagePeriodFrom: { required },
        usagePeriodTo: { required },
        quantity: { numeric },
        eventName: {},
        voucherPrefix: {
          required,
          minPrefixLength: minLength(3),
          maxPrefixLength: maxLength(5)
        },
        voucherValueType: { required },
        minimumSpending: { required, price },
        voucherType: { required },
        banner: {
          required,
          bannerImageType: hasFile(this.voucherObj.banner, 'bannerImageType'),
          fileSize: hasFile(
            this.voucherObj.banner,
            GLOBAL.BANNER_IMAGE_UPLOAD_SIZE
          )
        },
        thumbnail: {
          required,
          bannerImageType: hasFile(
            this.voucherObj.thumbnail,
            'bannerImageType'
          ),
          fileSize: hasFile(
            this.voucherObj.thumbnail,
            GLOBAL.BANNER_IMAGE_UPLOAD_SIZE
          )
        },
        voucherTitle: { required },
        description: { required, maxLength: maxLength(500) },
        redemptionPeriodFrom: { required },
        redemptionPeriodTo: { required },
        tnc: {
          required
        }
      }
    };
  }
};
</script>

<style></style>
