<template>
  <div>
    <!-- begin: User Referees Table -->
    <div class="d-flex justify-content-end pb-5">
      <b-button
        variant="primary"
        class="mr-3 text-capitalize"
        @click="openNewSpecificUsersModal"
      >
        <i class="flaticon2-plus p-0 mr-2"></i>
        {{ $t('label.addPointToSpecificUser') }}
      </b-button>
    </div>
    <AppSmartTable
      stickyHeader
      :stacked="false"
      :items="User.items"
      :headers="User.header"
      :pagination="User.pagination"
      :accessRights="accessRights"
      @app-smart-table-pagination-change="actionPaginationChange"
      @app-table-action-delete="actionDelete"
    ></AppSmartTable>
    <!-- end: User Referees Table -->
    <!-- begin: Create New User Referees  Modal -->
    <SpecificUsersCreateModal
      v-model="specificUserObj.isShow"
      :id="specificUserObj.id"
      :isShow="specificUserObj.isShow"
    ></SpecificUsersCreateModal>
    <!-- end: Create New User Referees  Modal -->
  </div>
</template>

<script>
  import userMixin from '@/core/mixins/user.mixin';
  import AppSmartTable from '@/views/components/bases/smart/AppSmartTable';
  import SpecificUsersCreateModal from '@/views/components/point/specific-users/SpecificUsersCreateModal';
  import {
    MODULE,
    ACTION,
    //APP_COMPONENT_NAME,
    ROUTE_NAME
  } from '@/core/constants';
  import { i18nHelper } from '@/core/utils';
  import commonMixin from '@/core/mixins/common.mixin';
  import {
    POINT_GET_POINT_SPECIFIC_USERS,
    POINT_DELETE_POINT_SPECIFIC_USERS,
    POINT_INITIAL_GET_POINT_SPECIFIC_USERS_STATE,
    POINT_INITIAL_DELETE_POINT_SPECIFIC_USERS_STATE
  } from '@/core/store/point.module';

  export default {
    name: 'PointSpecificList',
    components: {
      AppSmartTable,
      SpecificUsersCreateModal
    },
    mixins: [commonMixin, userMixin],
    props: {
      id: {
        Type: Number,
        default: null
      },
      pointObj: {
        type: Object,
        default: () => ({})
      }
    },
    data: () => ({
      routeName: ROUTE_NAME,
      accessRights: {
        moduleName: MODULE.BANNER,
        actions: {
          create: ACTION.BANNER_CREATE,
          read: ACTION.BANNER_READ,
          update: ACTION.BANNER_UPDATE,
          delete: ACTION.BANNER_DELETE
        }
      },
      specificUserObj: {},
      searchCriteriaListDetails: {
        perPage: 10,
        page: 1
      },
      User: {
        header: [
          {
            key: 'no',
            name: 'no',
            sortable: true
          },
          {
            key: 'id',
            name: 'id',
            sortable: true
          },
          {
            key: 'username',
            name: 'username',
            sortable: true,
            layout: 'AppFeatureDetailsRelatedButton',
            config: {
              to: ROUTE_NAME.PROFILE_DETAILS_DETAILS
            }
          },
          {
            key: 'point',
            name: 'point',
            sortable: true
          }
        ],
        items: [],
        pagination: {
          currentPage: 1,
          total: 1,
          perPage: 10,
          lastPage: 1
        }
      },
      disabled: false
    }),
    computed: {
      pointSpecificUserComplete() {
        return this.$store.state.point.pointSpecificUsers.complete;
      },
      deletePointSpecificUserComplete() {
        return this.$store.state.point.deletePointSpecificUsers.complete;
      },
      route() {
        return this.$route;
      }
    },
    watch: {
      id() {
        if (this.id) {
          this.getListDetails(this.id, this.searchCriteriaListDetails);
        }
      },
      pointSpecificUserComplete() {
        let response = this.$store.state.point.pointSpecificUsers;
        let title = i18nHelper.getMessage('label.assignPoints');
        let initialStateAction =
          POINT_INITIAL_GET_POINT_SPECIFIC_USERS_STATE;
        let successAction = () => {
          this.importListDetails(response);
        };

        if (response.complete) {
          this.actionReadRecordComplete(
            response,
            title,
            initialStateAction,
            successAction
          );
        }
      },
      deletePointSpecificUserComplete() {
        let response = this.$store.state.point.deletePointSpecificUsers;
        if (response.complete) {
          this.pointSpecificUsersCompleteAction(response, 'delete');
        }
      }
    },
    mounted() {
      this.getListDetails(this.id, this.searchCriteriaListDetails);
    },
    methods: {
      pointSpecificUsersCompleteAction(response, actionType) {
        let title = i18nHelper.getMessage('label.assignPoints');
        let messages = response.message;
        let buttons = [];
        switch (actionType) {
          case 'delete':
            this.initialDeletePointSpecificUsersState();
            break;
        }
        if (response.code == 0) {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              switch (actionType) {
                case 'delete':
                  this.getListDetails(this.id, this.searchCriteriaListDetails);
                  this.$parent.getPoint(this.id);
                  break;
              }
              this.closeLayoutAppModalInfo();
            }
          });
        } else {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();
            }
          });
        }
        if (messages) this.openLayoutAppModalInfo(title, messages, buttons);
      },
      openNewSpecificUsersModal() {
        this.specificUserObj = {
          isShow: true,
          id: this.id
        };
      },
      importListDetails(response) {
        this.User.items = response.data;
        this.User.pagination = response.pagination;
      },
      actionPaginationChange(value) {
        this.searchCriteriaListDetails = {
          perPage: value.size,
          page: value.page
        };

        this.getListDetails(this.id, this.searchCriteriaListDetails);
      },
      actionDelete(value) {
        let id = this.id;
        let formData = new FormData();
        formData.append('user_ids[]', value);
        this.$store.dispatch(POINT_DELETE_POINT_SPECIFIC_USERS, {
          id,
          formData
        });
      },
      getListDetails(id, data) {
        this.$store.dispatch(POINT_GET_POINT_SPECIFIC_USERS, {
          id,
          data
        });
      },
      initialDeletePointSpecificUsersState() {
        this.$store.dispatch(
          POINT_INITIAL_DELETE_POINT_SPECIFIC_USERS_STATE
        );
      }
    }
  };
</script>

<style></style>
