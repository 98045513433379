import { apiService } from '@/core/services';
// import { apiHelper } from '@/core/utils';

export const CONFIG_EMAIL_RECIPIENT = {
  getEmailRecipient,
  createEmailRecipient,
  updateEmailRecipient,
  deleteEmailRecipient
};

const URL = {
  ADMIN: 'admin',
  CONTACT_EMIALS: 'contact-emails'
};

function getEmailRecipient() {
  let requestOption = {
    params: {
      per_page: 15
    }
  };
  return apiService().get(`/${URL.ADMIN}/${URL.CONTACT_EMIALS}`, requestOption);
}

function createEmailRecipient(data) {
  let requestBody = {
    email: data.email
  };
  return apiService().post(`/${URL.ADMIN}/${URL.CONTACT_EMIALS}`, requestBody);
}

function updateEmailRecipient(id, data) {
  let requestBody = {
    email: data.email
  };
  return apiService().put(
    `/${URL.ADMIN}/${URL.CONTACT_EMIALS}/${id}`,
    requestBody
  );
}

function deleteEmailRecipient(id) {
  return apiService().delete(`/${URL.ADMIN}/${URL.CONTACT_EMIALS}/${id}`);
}
