import { i18nHelper } from '@/core/utils';
import { ROUTE_NAME } from '@/core/constants';
import { BannerType, ActiveStatus } from '@/core/constants/enums';
import {
  LOOKUP_GET_APP_USER_RECIPES,
  LOOKUP_INITIAL_GET_APP_USER_RECIPES
} from '@/core/store/lookup.module';
import {
  BANNER_DELETE_BANNER,
  BANNER_UPDATE_BANNER_ACTIVE_INACTIVE,
  BANNER_INITIAL_DELETE_BANNER_STATE,
  BANNER_INITIAL_UPDATE_BANNER_ACTIVE_INACTIVE
} from '@/core/store/banner.module';

const bannerMixin = {
  methods: {
    openRequestDeleteBannerModal(id) {
      let title = i18nHelper.getMessage('label.deleteBanner');
      let messages = [];
      let buttons = [];

      messages.push(
        i18nHelper.getMessage('message.requestDeleteMessage', [
          i18nHelper.getMessage('label.banner').toLowerCase()
        ])
      );

      buttons.push(
        {
          text: i18nHelper.getMessage('label.cancel'),
          action: () => {
            this.closeLayoutAppModalInfo();
          }
        },
        {
          text: i18nHelper.getMessage('label.delete'),
          variant: 'danger',
          action: () => {
            this.closeLayoutAppModalInfo();
            this.deleteBanner(id);
          }
        }
      );

      this.openLayoutAppModalInfo(title, messages, buttons);
    },
    openRequestupdateBannerActiveInactiveModal(id, status) {
      let title = i18nHelper.getMessage('label.updateBannerStatus');
      let messages = [];
      let buttons = [];
      let isActive = status ? ActiveStatus.INACTIVE : ActiveStatus.ACTIVE;

      messages.push(
        i18nHelper.getMessage('message.requestUpdateStatusMessage', [
          i18nHelper.getMessage('label.banner').toLowerCase(),
          i18nHelper
            .getMessage(`enumActivationStatus.${Number(!status)}`)
            .toLowerCase()
        ])
      );

      buttons.push(
        {
          text: i18nHelper.getMessage('label.cancel'),
          action: () => {
            this.closeLayoutAppModalInfo();
          }
        },
        {
          text: i18nHelper.getMessage(
            `enumActivationStatus.${Number(!status)}`
          ),
          variant: status ? 'danger' : 'success',
          action: () => {
            this.closeLayoutAppModalInfo();
            this.updateBannerActiveInactive(id, isActive);
          }
        }
      );

      this.openLayoutAppModalInfo(title, messages, buttons);
    },
    getBannerListRouteName(type) {
      let routeName = {
        [BannerType.HOME]: ROUTE_NAME.BANNER_HOME_LIST,
        [BannerType.DISCOVER]: ROUTE_NAME.BANNER_DISCOVER_LIST,
        [BannerType.X_STREET]: ROUTE_NAME.BANNER_X_STREET_LIST
      };

      return routeName[type];
    },
    getBannerRouteName(type) {
      let routeName = {
        [BannerType.HOME]: ROUTE_NAME.BANNER_HOME,
        [BannerType.DISCOVER]: ROUTE_NAME.BANNER_DISCOVER,
        [BannerType.X_STREET]: ROUTE_NAME.BANNER_X_STREET
      };

      return routeName[type];
    },
    deleteBanner(id) {
      this.$store.dispatch(BANNER_DELETE_BANNER, {
        id
      });
    },
    initialDeleteBannerState() {
      this.$store.dispatch(BANNER_INITIAL_DELETE_BANNER_STATE);
    },
    updateBannerActiveInactive(id, isActive) {
      this.$store.dispatch(BANNER_UPDATE_BANNER_ACTIVE_INACTIVE, {
        id,
        isActive
      });
    },
    initialUpdateBannerActiveInactiveState() {
      this.$store.dispatch(BANNER_INITIAL_UPDATE_BANNER_ACTIVE_INACTIVE);
    },
    async getAppUserRecipes(data) {
      await this.$store.dispatch(LOOKUP_GET_APP_USER_RECIPES, { data });
    },
    initialGetAppUserRecipesState() {
      this.$store.dispatch(LOOKUP_INITIAL_GET_APP_USER_RECIPES);
    }
  }
};

export default bannerMixin;
