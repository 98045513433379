<template>
  <div>
    <!-- {{ attachment }} -->
    <AppFormField
      v-model="$v.documents.$model"
      :title="$t(`label.documents`)"
      :formType="'file'"
      :change="uploadBookingDocuments()"
      :state="validateState('documents')"
      :validators="[
        'required',
        'bannerImageType',
        'fileSize'
        // 'bannerImageDimension'
      ]"
      :validationValue="$v.documents"
      :validationParams="[
        {
          key: 'bannerImageType',
          params: [
            uiHelper.arrayToTrimmedStringWithSpace(
              global.AVAILABLE_IMAGE_TYPE,
              6
            )
          ]
        },
        {
          key: 'fileSize',
          params: [
            global.BANNER_IMAGE_UPLOAD_SIZE,
            global.BANNER_IMAGE_UPLOAD_SIZE_TYPE
          ]
        }
        // {
        //   key: 'bannerImageDimension',
        //   params: [dimension.height, dimension.width]
        // }
      ]"
      :accept="global.AVAILABLE_IMAGE_TYPE.toString()"
      solid
    ></AppFormField>
    <div style="display: none">
      <!-- WARNING: I have no idea why this line is here, but removing it breaks everything -->
      {{ $v }}
    </div>

    <b-row>
      <b-col
        v-for="(item, index) in attachment"
        :key="index"
        cols="6"
        md="4"
        xl="3"
        class="mt-4"
      >
        <b-card
          no-body
          class="pointer h-100 shadow-sm card-custom rounded-0 overlay"
        >
          <div class="card-body p-0">
            <div class="overlay-wrapper">
              <div
                :class="`${
                  isImage(item.mediaType) ? 'overflow-hidden' : 'p-3'
                }`"
              >
                <div
                  class="h-100px h-sm-150px h-lg-150px h-xl-200px"
                  :class="`${
                    isImage(item.mediaType) ? 'document-image' : 'document'
                  }`"
                  :style="`background-image: url('${attachmentImage(
                    item.mediaType,
                    item.mediaPath
                  )}'); background-size: 100% 100%;`"
                ></div>
              </div>
            </div>
            <div class="overlay-layer">
              <div class="text-center">
                <AppButtonDocumentView
                  :attachmentUrl="item.mediaPath"
                  :fileType="item.mediaType"
                  :buttonType="2"
                ></AppButtonDocumentView>

                <b-button
                  v-if="
                    !checkAccessRight(moduleName, [permissionAction.DELETE])
                  "
                  variant="shadow-sm font-size-sm"
                  class="btn btn-danger font-weight-bold text-uppercase mt-3"
                  @click="deleteBookingDocuments(id, item.id)"
                >
                  {{ $t(`label.delete`) }}
                </b-button>
              </div>
            </div>
          </div>
        </b-card>
      </b-col></b-row
    >
  </div>
</template>

<script>
  import AppFormField from '@/views/components/bases/form-field/AppFormField';
  import AppButtonDocumentView from '@/views/components/bases/button/AppButtonDocumentView';
  import commonMixin from '@/core/mixins/common.mixin';
  import {
    GLOBAL,
    MODULE,
    ACTION,
    ROUTE_NAME,
    bannerImageType,
    fileSize
  } from '@/core/constants';
  import { MediaType } from '@/core/constants/enums';
  import { required } from 'vuelidate/lib/validators';
  import { i18nHelper, uiHelper } from '@/core/utils';
  import {
    STUDIO_UPLOAD_BOOKING_DOCUMENTS,
    STUDIO_DELETE_BOOKING_DOCUMENTS,
    STUDIO_INITIAL_UPLOAD_BOOKING_DOCUMENTS_STATE,
    STUDIO_INITIAL_DELETE_BOOKING_DOCUMENTS_STATE
  } from '@/core/store/studio.module';

  export default {
    name: 'StudioBookingDocuments',
    mixins: [commonMixin],
    components: {
      AppFormField,
      AppButtonDocumentView
    },
    data: () => ({
      global: GLOBAL,
      moduleName: MODULE.STUDIO,
      permissionAction: ACTION,
      routeName: ROUTE_NAME,
      uiHelper: uiHelper,
      documents: null
    }),
    props: {
      id: {
        Type: String,
        default: ''
      },
      studioBookingInfo: {
        Type: Object,
        default: {}
      }
    },
    computed: {
      attachment() {
        if (this.studioBookingInfo.documents) {
          return this.studioBookingInfo.documents;
        } else {
          return '';
        }
      },
      uploadBookingDocumentsComplete() {
        return this.$store.state.studio.uploadBookingDocuments.complete;
      },
      deleteBookingDocumentsComplete() {
        return this.$store.state.studio.deleteBookingDocuments.complete;
      }
    },
    watch: {
      uploadBookingDocumentsComplete() {
        let response = this.$store.state.studio.uploadBookingDocuments;
        if (response.complete) {
          this.studioBookingCompleteAction(response);
        }
      },
      deleteBookingDocumentsComplete() {
        let response = this.$store.state.studio.deleteBookingDocuments;
        if (response.complete) {
          this.studioBookingCompleteAction(response);
        }
      }
    },
    methods: {
      studioBookingCompleteAction(response) {
        let title = i18nHelper.getMessage('label.studioBooking');
        let messages = response.message;
        let buttons = [];

        if (response.code == 0) {
          this.documents = null;
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();
              this.initialuploadBookingDocuments();
              this.initialdeleteBookingDocuments();
              this.$parent.getStudioBooking(this.id);
              this.$v.documents.$reset();
            }
          });
        } else {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();
              this.initialuploadBookingDocuments();
              this.initialdeleteBookingDocuments();
            }
          });
        }
        if (messages) this.openLayoutAppModalInfo(title, messages, buttons);
      },
      uploadBookingDocuments() {
        if (this.documents && this.validationCheck('documents')) {
          let formData = new FormData();
          formData.append('studio_id', this.id);
          formData.append('attachments[]', this.documents);
          this.$store.dispatch(STUDIO_UPLOAD_BOOKING_DOCUMENTS, { formData });
        }
      },
      deleteBookingDocuments(id, fileId) {
        this.$store.dispatch(STUDIO_DELETE_BOOKING_DOCUMENTS, { id, fileId });
      },
      initialuploadBookingDocuments() {
        this.$store.dispatch(STUDIO_INITIAL_UPLOAD_BOOKING_DOCUMENTS_STATE);
      },
      initialdeleteBookingDocuments() {
        this.$store.dispatch(STUDIO_INITIAL_DELETE_BOOKING_DOCUMENTS_STATE);
      },
      isImage(type) {
        let isImage = false;

        if (type == MediaType.IMAGE) {
          isImage = true;
        }
        return isImage;
      },
      attachmentImage(type, originalUrl) {
        let src = '';

        if (type == MediaType.IMAGE) {
          src = originalUrl;
        } else if (type == MediaType.APPLICATION) {
          src = '/media/svg/files/pdf.svg';
        } else if (type == 'docx' || type == 'doc') {
          src = '/media/svg/files/doc.svg';
        }
        return src;
      }
    },
    validations() {
      return {
        documents: {
          required,
          bannerImageType,
          fileSize: fileSize(GLOBAL.BANNER_IMAGE_UPLOAD_SIZE)
        }
      };
    }
  };
</script>

<style></style>
