<template>
  <b-modal
    v-model="modal"
    :title="
      isNewRecord
        ? $t('label.newPopularSearchSetting')
        : $t('label.popularSearchSetting')
    "
    centered
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
    header-class="text-capitalize"
    :body-class="isNewRecord ? '' : 'max-h-325px'"
  >
    <b-form-group class="mb-0">
      <template v-for="(i, index) in formFields">
        <div :key="index" class="px-2">
          <div v-if="!isNewRecord == i.isExist">
            <div>
              <label class="font-size-sm text-capitalize"
                >{{ $t(`label.${i.title}`) }}
                <span v-if="i.isRequires" class="text-danger">*</span>
              </label>
            </div>
            <div
              class="label label-md label-inline label-light-primary mr-2 mb-2"
            >
              {{ popularSearchObj[i.model] }}
            </div>
          </div>
          <AppFormField
            v-else
            v-model="$v.popularSearchObj[i.model].$model"
            :value="$v.popularSearchObj[i.model].$model"
            :state="validateObjState('popularSearchObj', i.model)"
            :validationValue="$v.popularSearchObj[i.model]"
            :title="$t(`label.${i.title}`)"
            :solid="true"
            :validators="i.validators"
            :formType="i.formType"
            :options="i.options"
            :isRequires="i.isRequires"
            :disabled="i.disabled"
            :prepend="i.prepend"
            @change="i.action"
            @searchChange="i.searchChange"
          >
            <template v-slot:prepend>
              <span class="input-group-text text-uppercase">
                <i class="fas fa-search"></i>
              </span>
            </template>
          </AppFormField>
        </div>
      </template>
      <div style="display: none">
        <!-- WARNING: I have no idea why this line is here, but removing it breaks everything -->
        {{ $v }}
      </div>
    </b-form-group>
    <template #modal-footer>
      <b-button
        v-if="
          !isNewRecord &&
          !checkAccessRight(moduleName, [action.POPULAR_SEARCH_UPDATE])
        "
        variant="primary"
        @click="actionDiscard"
        >{{ $t('label.close') }}</b-button
      >
      <b-button v-else class="mr-2" variant="white" @click="actionDiscard">{{
        $t('label.discard')
      }}</b-button>
      <b-button
        v-if="
          isNewRecord &&
          checkAccessRight(moduleName, [action.POPULAR_SEARCH_CREATE])
        "
        variant="primary"
        @click="actionSubmit"
        >{{ $t('label.save') }}</b-button
      >
      <b-button
        v-if="
          !isNewRecord &&
          checkAccessRight(moduleName, [action.POPULAR_SEARCH_DELETE])
        "
        variant="danger"
        @click="actionDelete"
        >{{ $t('label.delete') }}</b-button
      >
      <b-button
        v-if="
          !isNewRecord &&
          checkAccessRight(moduleName, [action.POPULAR_SEARCH_UPDATE])
        "
        variant="primary"
        @click="actionSaveChanges"
        >{{ $t('label.saveChanges') }}</b-button
      >
    </template>
  </b-modal>
</template>

<script>
  import AppFormField from '@/views/components/bases/form-field/AppFormField';
  import commonMixin from '@/core/mixins/common.mixin';
  import constantsMixin from '@/core/mixins/constants.mixin';
  import { listService, listApiService } from '@/core/services';
  import { MODULE, ACTION } from '@/core/constants';
  import { validationMixin } from 'vuelidate';
  import { required } from 'vuelidate/lib/validators';
  import { i18nHelper, uiHelper } from '@/core/utils';
  import {
    POPULAR_SEARCH_GET_POPULAR_SEARCH,
    POPULAR_SEARCH_CREATE_POPULAR_SEARCH,
    POPULAR_SEARCH_UPDATE_POPULAR_SEARCH,
    POPULAR_SEARCH_DELETE_POPULAR_SEARCH,
    POPULAR_SEARCH_INITIAL_GET_POPULAR_SEARCH_STATE,
    POPULAR_SEARCH_INITIAL_CREATE_POPULAR_SEARCH_STATE,
    POPULAR_SEARCH_INITIAL_UPDATE_POPULAR_SEARCH_STATE,
    POPULAR_SEARCH_INITIAL_DELETE_POPULAR_SEARCH_STATE
  } from '@/core/store/popular-search.module';

  export default {
    name: 'PopularSearchModal',
    mixins: [commonMixin, validationMixin, constantsMixin],
    components: {
      AppFormField
    },
    props: {
      value: {
        type: Boolean,
        default: false,
        required: true
      },
      id: {
        type: Number,
        default: 0,
        required: true
      },
      isNewRecord: {
        type: Boolean,
        default: false,
        required: true
      }
    },
    data: () => ({
      moduleName: MODULE.POPULAR_SEARCH,
      action: ACTION,
      modal: false,
      popularSearchObj: {
        category: 0,
        merchantName: null
      },
      formFields: [],
      searchName: null
    }),
    computed: {
      popularSearchComplete() {
        return this.$store.state.popularSearch.popularSearch.complete;
      },
      createPopularSearchComplete() {
        return this.$store.state.popularSearch.createPopularSearch.complete;
      },
      updatePopularSearchComplete() {
        return this.$store.state.popularSearch.updatePopularSearch.complete;
      },
      deletePopularSearchComplete() {
        return this.$store.state.popularSearch.deletePopularSearch.complete;
      }
    },
    watch: {
      value() {
        if (this.value) {
          if (this.isNewRecord) {
            this.modal = true;
          } else {
            this.getPopularSearch(this.id);
          }
        }
        this.$v.$reset();
      },
      modal() {
        this.modal
          ? uiHelper.freezeBodyOverflowY()
          : uiHelper.unFreezeBodyOverflowY();
      },
      popularSearchComplete() {
        let response = this.$store.state.popularSearch.popularSearch;
        let title = i18nHelper.getMessage('label.getPopularSearch');
        let initialStateAction =
          POPULAR_SEARCH_INITIAL_GET_POPULAR_SEARCH_STATE;
        let successAction = (response) => {
          this.getPopularSearchCompleteActions(response);
        };

        if (response.complete) {
          this.actionReadRecordComplete(
            response,
            title,
            initialStateAction,
            successAction
          );
        }
      },
      createPopularSearchComplete() {
        let response = this.$store.state.popularSearch.createPopularSearch;
        let title = i18nHelper.getMessage('label.newPopularSearch');
        let initialStateAction =
          POPULAR_SEARCH_INITIAL_CREATE_POPULAR_SEARCH_STATE;
        let successAction = () => {
          this.actionCloseModal();
          this.initialList();
        };
        let errorAction = () => {};

        if (response.complete) {
          this.actionCreateRecordComplete(
            response,
            title,
            initialStateAction,
            successAction,
            errorAction
          );
        }
      },
      updatePopularSearchComplete() {
        let response = this.$store.state.popularSearch.updatePopularSearch;
        let title = i18nHelper.getMessage('label.updatePopularSearch');
        let initialStateAction =
          POPULAR_SEARCH_INITIAL_UPDATE_POPULAR_SEARCH_STATE;
        let successAction = () => {
          this.actionCloseModal();
          this.initialList();
        };
        let errorAction = () => {};

        if (response.complete) {
          this.actionUpdateRecordComplete(
            response,
            title,
            initialStateAction,
            successAction,
            errorAction
          );
        }
      },
      deletePopularSearchComplete() {
        let response = this.$store.state.popularSearch.deletePopularSearch;
        let title = i18nHelper.getMessage('label.deletePopularSearch');
        let initialStateAction =
          POPULAR_SEARCH_INITIAL_DELETE_POPULAR_SEARCH_STATE;
        let successAction = () => {
          this.actionCloseModal();
          this.initialList();
        };
        let errorAction = () => {};

        if (response.complete) {
          this.actionUpdateRecordComplete(
            response,
            title,
            initialStateAction,
            successAction,
            errorAction
          );
        }
      }
    },
    mounted() {
      this.initFormFields();
    },
    methods: {
      initialList() {
        this.$parent.$refs.appSmartList.initAppSmartList();
      },
      getPopularSearchCompleteActions(response) {
        this.popularSearchObj = response.data;
        this.modal = true;
        this.$v.$reset();
      },
      actionDiscard() {
        this.actionCloseModal();
      },
      actionSaveChanges() {
        if (this.validationCheck('popularSearchObj')) {
          this.updatePopularSearch(this.id, this.popularSearchObj);
        }
      },
      actionSubmit() {
        if (this.validationCheck('popularSearchObj')) {
          this.createPopularSearch(this.popularSearchObj);
        }
      },
      actionDelete() {
        this.deletePopularSearch(this.id);
      },
      resetForm() {
        this.$v.$reset();
        this.popularSearchObj = {
          category: 0,
          merchantName: null
        };
      },
      actionCloseModal() {
        this.resetForm();
        this.input(false);
        this.modal = false;
      },
      input(value) {
        this.$emit('input', value);
      },
      getPopularSearch(id) {
        this.$store.dispatch(POPULAR_SEARCH_GET_POPULAR_SEARCH, {
          id
        });
      },
      createPopularSearch(data) {
        this.$store.dispatch(POPULAR_SEARCH_CREATE_POPULAR_SEARCH, {
          data
        });
      },
      updatePopularSearch(id, data) {
        this.$store.dispatch(POPULAR_SEARCH_UPDATE_POPULAR_SEARCH, {
          id,
          data
        });
      },
      deletePopularSearch(id) {
        this.$store.dispatch(POPULAR_SEARCH_DELETE_POPULAR_SEARCH, {
          id
        });
      },
      initFormFields() {
        this.formFields = [
          {
            model: 'category',
            title: 'category',
            formType: 'select',
            isRequires: true,
            options: listService.getDynamicList(
              this.constantsData.merchantCategory
            ),
            validators: ['required'],
            action: () => {
              this.initFormFields();
            },
            searchChange: () => {}
          },
          {
            isExist: true,
            model: 'merchantName',
            title: 'merchantName',
            isRequires: true,
            isCreate: true,
            prepend: true,
            formType: 'searchSelect',
            disabled: this.popularSearchObj.category == 0 ? true : false,
            options: listApiService.getMerchantName(
              this.searchName,
              this.popularSearchObj.category
            ),
            validators: ['required'],
            action: () => {},
            searchChange: (val) => {
              this.searchName = val;
              this.initFormFields();
            }
          }
        ];
      }
    },
    validations: {
      popularSearchObj: {
        category: {
          required
        },
        merchantName: {
          required
        }
      }
    }
  };
</script>

<style lang="scss"></style>
