const CreatorApplicationDocumentType = {
  IC_FRONT: 0,
  IC_BACK: 1,
  DOCUMENT: 2,
  BANK_STATEMENT: 3,
  HALAL_CERT: 4,
  SST_CERT: 5
};

export { CreatorApplicationDocumentType };
