<template>
  <div v-if="!isCreate" class="d-flex border-top pt-5">
    <button
      v-if="checkAccessRight(moduleName, [permissionAction.EQUIPMENTS_UPDATE])"
      class="btn btn-secondary font-weight-bold text-caitalize mr-3"
      @click="resetEquipment"
    >
      {{ $t('label.reset') }}
    </button>
    <b-row class="justify-content-end" no-gutters>
      <button
        v-if="
          checkAccessRight(moduleName, [permissionAction.EQUIPMENTS_DELETE])
        "
        class="btn btn-light-danger font-weight-bold text-caitalize mr-3"
        @click="deleteEquipment"
      >
        {{ $t('label.delete') }}
      </button>
      <button
        v-if="
          checkAccessRight(moduleName, [permissionAction.EQUIPMENTS_UPDATE])
        "
        class="btn btn-warning font-weight-bold text-caitalize mr-3"
        @click="activateEquipment"
      >
        {{
          equipmentInfo.status == 1
            ? $t('label.deactivate')
            : $t('label.activate')
        }}
      </button>
      <button
        :disabled="
          !checkAccessRight(moduleName, [permissionAction.EQUIPMENTS_UPDATE])
        "
        @click="validationCheckBeforeSubmit()"
        class="btn btn-success font-weight-bold text-uppercase"
      >
        {{ $t(`label.${isCreate ? 'create' : 'update'}`) }}
      </button>
    </b-row>
  </div>
</template>

<script>
  import commonMixin from '@/core/mixins/common.mixin';
  import { MODULE, ACTION, ROUTE_NAME } from '@/core/constants';
  import { i18nHelper } from '@/core/utils';
  import {
    EQUIPMENT_CREATE_EQUIPMENT,
    EQUIPMENT_UPDATE_EQUIPMENT,
    EQUIPMENT_UPDATE_EQUIPMENT_STATUS,
    EQUIPMENT_DELETE_EQUIPMENT,
    EQUIPMENT_INITIAL_CREATE_EQUIPMENT_STATE,
    EQUIPMENT_INITIAL_UPDATE_EQUIPMENT_STATE,
    EQUIPMENT_INITIAL_UPDATE_EQUIPMENT_STATUS_STATE,
    EQUIPMENT_INITIAL_DELETE_EQUIPMENT_STATE
  } from '@/core/store/equipment.module';

  export default {
    name: 'EquipmentStatusBtn',
    mixins: [commonMixin],
    data: () => ({
      moduleName: MODULE.EQUIPMENTS,
      permissionAction: ACTION,
      routeName: ROUTE_NAME
    }),
    props: {
      id: {
        Type: Number,
        default: null
      },
      equipmentInfo: {
        Type: Object,
        default: {
          status: null
        }
      },
      isCreate: {
        Type: Boolean,
        defualt: false
      },
      deliveryFees: {},
      fileMediaType: {}
    },
    computed: {
      createEquipmentComplete() {
        return this.$store.state.equipment.createEquipment.complete;
      },
      updateEquipmentComplete() {
        return this.$store.state.equipment.updateEquipment.complete;
      },
      updateEquipmentStatusComplete() {
        return this.$store.state.equipment.updateEquipmentStatus.complete;
      },
      deleteEquipmentComplete() {
        return this.$store.state.equipment.deleteEquipment.complete;
      }
    },
    watch: {
      createEquipmentComplete() {
        let response = this.$store.state.equipment.createEquipment;
        if (response.complete) {
          this.equipmentCompleteAction(response, 'create');
        }
      },
      updateEquipmentComplete() {
        let response = this.$store.state.equipment.updateEquipment;
        if (response.complete) {
          this.equipmentCompleteAction(response, 'update');
        }
      },
      updateEquipmentStatusComplete() {
        let response = this.$store.state.equipment.updateEquipmentStatus;
        if (response.complete) {
          this.equipmentCompleteAction(response, 'updateStatus');
        }
      },
      deleteEquipmentComplete() {
        let response = this.$store.state.equipment.deleteEquipment;
        if (response.complete) {
          this.equipmentCompleteAction(response, 'delete');
        }
      }
    },
    methods: {
      equipmentCompleteAction(response, actionType) {
        let title = i18nHelper.getMessage('label.equipment');
        let messages = response.message;
        let data = response.data;
        let buttons = [];
        switch (actionType) {
          case 'create':
            this.initialCreateEquipmentState();
            break;
          case 'update':
            this.initialUpdateEquipmentState();
            break;
          case 'updateStatus':
            this.initialUpdateEquipmentStatusState();
            break;
          case 'delete':
            this.initialDeleteEquipmentState();
            break;
        }
        if (response.code == 0) {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();
              switch (actionType) {
                case 'create':
                  this.$parent.$refs.EquipmentDocuments.uploadEquipmentDocumentsAction(
                    data.id,
                    this.fileMediaType
                  );
                  this.$parent.$refs.EquipmentDeliveryFees.updateEquipmentDeliveryFeesAction(
                    data.id,
                    this.deliveryFees
                  );
                  break;
                case 'update':
                  this.$parent.$refs.equipmentInfoForm.resetForm();
                  this.$parent.getEquipment(this.id);

                  break;

                case 'delete':
                  this.$router.push({
                    name: ROUTE_NAME.EQUIPMENT_LIST
                  });
                  break;
                default:
                  this.$parent.getEquipment(this.id);
              }
            }
          });
        } else {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();
            }
          });
        }
        if (messages) this.openLayoutAppModalInfo(title, messages, buttons);
      },
      validationCheckBeforeSubmit() {
        //Submit new banner form if all the validation is no error
        if (
          this.$parent.$refs.equipmentInfoForm.validationCheckEquipmentInfo()
        ) {
          this.submitEquipmentFormAction();
        }
      },
      submitEquipmentFormAction() {
        var data = this.equipmentInfo;
        this.isCreate
          ? this.createEquipment(data)
          : this.updateEquipment(this.id, data);
      },
      createEquipment(data) {
        this.$store.dispatch(EQUIPMENT_CREATE_EQUIPMENT, { data });
      },
      updateEquipment(id, data) {
        this.$store.dispatch(EQUIPMENT_UPDATE_EQUIPMENT, { id, data });
      },
      activateEquipment() {
        let id = this.id;
        let data;
        switch (this.equipmentInfo.status) {
          case 0:
            data = 1;
            break;
          case 1:
            data = 0;
            break;
          case 2:
            data = 1;
            break;
        }
        this.$store.dispatch(EQUIPMENT_UPDATE_EQUIPMENT_STATUS, {
          id,
          data
        });
      },
      resetEquipment() {
        let id = this.id;
        this.$parent.getEquipment(id);
      },
      deleteEquipment() {
        let id = this.id;
        this.$store.dispatch(EQUIPMENT_DELETE_EQUIPMENT, { id });
      },
      initialCreateEquipmentState() {
        this.$store.dispatch(EQUIPMENT_INITIAL_CREATE_EQUIPMENT_STATE);
      },
      initialUpdateEquipmentState() {
        this.$store.dispatch(EQUIPMENT_INITIAL_UPDATE_EQUIPMENT_STATE);
      },
      initialUpdateEquipmentStatusState() {
        this.$store.dispatch(EQUIPMENT_INITIAL_UPDATE_EQUIPMENT_STATUS_STATE);
      },
      initialDeleteEquipmentState() {
        this.$store.dispatch(EQUIPMENT_INITIAL_DELETE_EQUIPMENT_STATE);
      }
    }
  };
</script>

<style></style>
