<template>
  <div>
    <div class="card app-smart-list p-8">
      <b-card-body class="border-0 p-0">
        <b-row class="w-100">
          <b-col cols="3">
            <VueCtkDateTimePicker
              v-model="datetime"
              id="rangeDatePicker"
              format="YYYY-MM-DD"
              formatted="l"
              label="Select Date"
              color="var(--primary)"
              button-color="var(--primary)"
              only-date
              range
              @input="input"
              @is-hidden="close"
            />
          </b-col>
          <b-col cols="3">
            <b-button
              size="md"
              variant="light-warning"
              class="mr-2 text-capitalize"
              style="height: 42px; line-height: 2"
              @click="onExport"
              :disabled="disabled"
            >
              <i class="fas fa-file-export"></i>
              {{ $t('label.exportExcel') }}</b-button
            >
          </b-col>
        </b-row>
      </b-card-body>
    </div>
  </div>
</template>
<script>
  import commonMixin from '@/core/mixins/common.mixin';
  import { i18nHelper, apiHelper } from '@/core/utils';
  import { apiService } from '@/core/services';
  import {
    LOADING_OPEN_LOADING,
    LOADING_CLOSE_LOADING
  } from '@/core/store/loading.module';

  export default {
    name: 'Reports',
    mixins: [commonMixin],
    created() {
      this.initialPlatformInfo();
    },
    data: () => ({
      dateStart: null,
      dateEnd: null,
      datetime: '',
      disabled: false
    }),
    methods: {
      initialPlatformInfo() {
        let breadcrumbData = {
          breadcrumbs: [
            {
              title: i18nHelper.getMessage('label.transactions')
            },
            {
              title: i18nHelper.getMessage('label.financeReport')
            }
          ]
        };
        this.initBreadCrumb(breadcrumbData);
      },
      close() {
        if (this.dateStart != null) {
          if (this.dateEnd == null) {
            this.dateEnd = this.dateStart;
          }

          this.datetime = { start: this.dateStart, end: this.dateEnd };
        }
      },
      input(value) {
        if (value != null) {
          this.dateStart = value.start;
          this.dateEnd = value.end;
        }
      },
      onExport() {
        this.$store.dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
        this.disabled = true;
        let requestOptions = {};

        if (this.dateStart != null && this.dateEnd != null) {
          let date = this.dateStart + ',' + this.dateEnd;

          requestOptions = {
            created_at_from: apiHelper.dateTimeStart(date),
            created_at_to: apiHelper.dateTimeEnd(date)
          };
        }

        apiService()
          .get('/admin/orders/report/finance/download', {
            responseType: 'blob',
            params: requestOptions
          })
          .then((response) => {
            this.disabled = false;
            this.$store.dispatch(`${LOADING_CLOSE_LOADING}`, null, {
              root: true
            });

            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'finance_report.xlsx');
            document.body.appendChild(link);
            link.click();
          });
      }
    }
  };
</script>
<style></style>
