<template>
  <b-card no-body data-style="custom">
    <!--begin: Wizard-->
    <div
      class="wizard wizard-2"
      id="initailWizard"
      data-wizard-state="step-first"
      data-wizard-clickable="true"
    >
      <!--begin: Wizard Nav -->
      <div class="wizard-nav border-right py-8 px-8 py-lg-20 px-lg-10">
        <div class="wizard-steps">
          <!--begin: Wizard Steps 1 -->
          <template
            v-for="(i, index) in wizardSteps.filter(
              (x) => x.hideCreate !== isCreate
            )"
          >
            <div
              :key="index"
              class="wizard-step"
              data-wizard-type="step"
              :data-wizard-state="index < 1 ? 'current' : ''"
            >
              <WizardWrapper
                :icon="i.icon"
                :title="$t(`label.${i.title}`)"
                :description="$t(`label.${i.description}`)"
              ></WizardWrapper>
            </div>
          </template>
          <!--end: Wizard Steps 1 -->
        </div>
      </div>
      <!--end: Wizard Nav -->

      <!--begin: Wizard Body -->
      <div class="wizard-body py-8 px-8 py-lg-20 px-lg-10">
        <!--begin: Wizard Form-->
        <b-row>
          <b-col offset-xl="2" xl="8" class="pb-5">
            <!--begin: Wizard Step 1 -->
            <div data-wizard-type="step-content" :data-wizard-state="'current'">
              <OffDaySettingForm
                ref="OffDaySettingForm"
                v-model="offDaySettingObj"
                :id="id"
                :isCreate="isCreate"
                :offDaySettingObj="offDaySettingObj"
                @getRecipeObj="getRecipeObj"
              ></OffDaySettingForm>
            </div>
            <div class="d-flex justify-content-between border-top pt-10">
              <div class="mr-2">
                <!-- <button
                  class="btn btn-light-primary font-weight-bold text-uppercase"
                  data-wizard-type="action-prev"
                >
                  {{ $t('label.previous') }}
                </button> -->
              </div>
              <div>
                <button
                  v-if="!isCreate"
                  :disabled="
                    !checkAccessRight(moduleName, [
                      permissionAction.OFF_DAY_SETTING_CREATE
                    ])
                  "
                  class="btn btn-danger font-weight-bold text-uppercase mr-3"
                  @click="deleteOffDaySetting(id)"
                >
                  {{ $t('label.delete') }}
                </button>
                <button
                  :disabled="
                    !checkAccessRight(moduleName, [
                      permissionAction.OFF_DAY_SETTING_CREATE
                    ])
                  "
                  class="btn btn-success font-weight-bold text-uppercase"
                  data-wizard-type="action-submit"
                  type="submit"
                >
                  {{ $t(`label.${isCreate ? 'create' : 'update'}`) }}
                </button>
                <button
                  class="btn btn-primary font-weight-bold text-uppercase"
                  data-wizard-type="action-next"
                >
                  {{ $t('label.nextStep') }}
                </button>
              </div>
            </div>
            <!--end: Wizard Step 1 -->
          </b-col>
          <!--end: Wizard-->
        </b-row>
      </div>
      <!--end: Wizard Body -->
    </div>
    <!--end: Wizard-->
  </b-card>
</template>

<script>
  import WizardWrapper from '@/views/components/wizard/WizardWrapper';
  import OffDaySettingForm from '@/views/components/off-day-setting/OffDaySettingForm.vue';
  import KTUtil from '@/assets/js/components/util';
  import KTWizard from '@/assets/js/components/wizard';
  import constantsMixin from '@/core/mixins/constants.mixin';
  import commonMixin from '@/core/mixins/common.mixin';
  import { i18nHelper } from '@/core/utils';
  import { GLOBAL, MODULE, ACTION, ROUTE_NAME } from '@/core/constants';
  import {
    OFF_DAY_SETTING_GET_OFF_DAY_SETTING,
    OFF_DAY_SETTING_CREATE_OFF_DAY_SETTING,
    OFF_DAY_SETTING_UPDATE_OFF_DAY_SETTING,
    OFF_DAY_SETTING_DELETE_OFF_DAY_SETTING,
    OFF_DAY_SETTING_INITIAL_GET_OFF_DAY_SETTING_STATE,
    OFF_DAY_SETTING_INITIAL_CREATE_OFF_DAY_SETTING_STATE,
    OFF_DAY_SETTING_INITIAL_UPDATE_OFF_DAY_SETTING_STATE,
    OFF_DAY_SETTING_INITIAL_DELETE_OFF_DAY_SETTING_STATE
  } from '@/core/store/off-day-setting.module';

  export default {
    name: 'OffDaySettingDetailsTemplate',
    mixins: [commonMixin, constantsMixin],
    components: {
      WizardWrapper,
      OffDaySettingForm
    },
    props: {
      isCreate: {
        Type: Boolean,
        defualt: false
      }
    },
    data: () => ({
      moduleName: MODULE.OFF_DAY_SETTING,
      permissionAction: ACTION,
      global: GLOBAL,
      constWizard: {
        currentStep: 1
      },
      wizardSteps: [
        {
          step: 1,
          title: 'offDaySettingDetails',
          description: 'fillInInfoAndAction',
          icon: 'fab fa-wpforms'
        }
      ],
      offDaySettingObj: {
        eventName: null,
        startDateTime: null,
        endDateTime: null
      }
    }),
    computed: {
      id() {
        return this.$route.params.id;
      },
      getOffDaySettingComplete() {
        return this.$store.state.offDaySetting.offDaySetting.complete;
      },
      createOffDaySettingComplete() {
        return this.$store.state.offDaySetting.createOffDaySetting.complete;
      },
      updateOffDaySettingComplete() {
        return this.$store.state.offDaySetting.updateOffDaySetting.complete;
      },
      deleteOffDaySettingComplete() {
        return this.$store.state.offDaySetting.deleteOffDaySetting.complete;
      }
    },
    watch: {
      getOffDaySettingComplete() {
        let response = this.$store.state.offDaySetting.offDaySetting;
        if (response.complete) {
          this.offDaySettingObj = response.data;
          this.offDaySettingCompleteAction(response, 'get');
        }
      },
      createOffDaySettingComplete() {
        let response = this.$store.state.offDaySetting.createOffDaySetting;
        if (response.complete) {
          this.offDaySettingCompleteAction(response, 'create');
        }
      },
      updateOffDaySettingComplete() {
        let response = this.$store.state.offDaySetting.updateOffDaySetting;
        if (response.complete) {
          this.offDaySettingCompleteAction(response, 'update');
        }
      },
      deleteOffDaySettingComplete() {
        let response = this.$store.state.offDaySetting.deleteOffDaySetting;
        if (response.complete) {
          this.offDaySettingCompleteAction(response, 'delete');
        }
      }
    },
    mounted() {
      this.initialOffDaySetting();
    },
    methods: {
      offDaySettingCompleteAction(response, actionType) {
        let title = i18nHelper.getMessage('label.offDaySetting');
        let messages = response.message;
        let buttons = [];
        switch (actionType) {
          case 'get':
            this.initialGetOffDaySettingState();
            break;
          case 'create':
            this.initialCreateOffDaySettingState();
            break;
          case 'update':
            this.initialUpdateOffDaySettingState();
            break;
          case 'delete':
            this.initialDeleteOffDaySettingState();
            break;
        }
        if (response.code == 0) {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();
              switch (actionType) {
                case 'create':
                  this.$router.push({
                    name: ROUTE_NAME.OFF_DAY_SETTING_DETAILS,
                    params: { id: response.data.id }
                  });
                  break;
                case 'delete':
                  this.$router.push({
                    name: ROUTE_NAME.OFF_DAY_SETTING_LIST
                  });
                  break;
                default:
                  this.getOffDaySetting(this.id);
              }
            }
          });
        } else {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();
            }
          });
        }
        if (messages) this.openLayoutAppModalInfo(title, messages, buttons);
      },

      initWizard() {
        // Initialize form wizard
        let vm = this;

        const wizard = new KTWizard('initailWizard', {
          startStep: 1, // initial active step number
          clickableSteps: true // allow step clicking
        });

        vm.constWizard = wizard;

        wizard.on('submit', () => {
          vm.validationCheckBeforeSubmit();
        });

        //Change event
        wizard.on('change', function (/*wizardObj*/) {
          if (wizard.currentStep == 1) {
            let v1 = vm.$refs.OffDaySettingForm.validationCheckForm();

            if (!v1) {
              wizard.stop();
            }
          }

          KTUtil.scrollTop();
        });
      },
      initialOffDaySetting() {
        let breadcrumbData = {
          breadcrumbs: [
            {
              title: i18nHelper.getMessage('label.offDaySetting'),
              route: { name: ROUTE_NAME.OFF_DAY_SETTING_LIST }
            },
            {
              title: i18nHelper.getMessage(`label.offDaySettingList`),
              route: { name: ROUTE_NAME.OFF_DAY_SETTING_LIST }
            },
            {
              title: i18nHelper.getMessage(
                `label.${
                  this.isCreate ? 'createOffDaySetting' : 'offDaySettingDetails'
                }`
              )
            }
          ],
          actions: [
            {
              text: i18nHelper.getMessage('label.back'),
              function: () => {
                this.$router.go(-1);
              }
            }
          ]
        };
        this.initWizard();
        this.initBreadCrumb(breadcrumbData);
        if (!this.isCreate) this.getOffDaySetting(this.id);
      },
      getRecipeObj(value) {
        if (this.isCreate) {
          this.offDaySettingObj = value;
        }
      },
      validationCheckBeforeSubmit() {
        //Submit new banner form if all the validation is no error
        if (this.$refs.OffDaySettingForm.validationCheckForm()) {
          this.submitCreateOffDaySettingForm();
        }
      },
      submitCreateOffDaySettingForm() {
        let data = {
          ...this.offDaySettingObj
        };
        this.isCreate
          ? this.createOffDaySetting(data)
          : this.updateOffDaySetting(this.id, data);
      },
      getOffDaySetting(id) {
        this.$store.dispatch(OFF_DAY_SETTING_GET_OFF_DAY_SETTING, { id });
      },
      createOffDaySetting(data) {
        this.$store.dispatch(OFF_DAY_SETTING_CREATE_OFF_DAY_SETTING, { data });
      },
      updateOffDaySetting(id, data) {
        this.$store.dispatch(OFF_DAY_SETTING_UPDATE_OFF_DAY_SETTING, {
          id,
          data
        });
      },
      deleteOffDaySetting(id) {
        this.$store.dispatch(OFF_DAY_SETTING_DELETE_OFF_DAY_SETTING, { id });
      },
      initialGetOffDaySettingState() {
        this.$store.dispatch(OFF_DAY_SETTING_INITIAL_GET_OFF_DAY_SETTING_STATE);
      },
      initialCreateOffDaySettingState() {
        this.$store.dispatch(
          OFF_DAY_SETTING_INITIAL_CREATE_OFF_DAY_SETTING_STATE
        );
      },
      initialUpdateOffDaySettingState() {
        this.$store.dispatch(
          OFF_DAY_SETTING_INITIAL_UPDATE_OFF_DAY_SETTING_STATE
        );
      },
      initialDeleteOffDaySettingState() {
        this.$store.dispatch(
          OFF_DAY_SETTING_INITIAL_DELETE_OFF_DAY_SETTING_STATE
        );
      }
    }
  };
</script>

<style lang="scss">
  @import '@/assets/sass/pages/wizard/wizard-2.scss';
</style>
