<template>
  <b-row class="justify-content-center">
    <b-col md="9" lg="8" xl="7" class="my-2">
      <slot />
    </b-col>
  </b-row>
</template>

<script>
  export default {
    name: 'AppSectionContainer1'
  };
</script>

<style></style>
