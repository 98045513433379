import { apiService } from '@/core/services';

export const POPULAR_SEARCH = {
  getPopularSearches,
  getPopularSearch,
  createPopularSearch,
  updatePopularSearch,
  deletePopularSearch
};

const URL = {
  ADMIN: 'admin',
  MERCHANT_POPULARS: 'merchant-populars'
};

function getPopularSearches(data) {
  let requestOptions = {
    params: {
      merchant_name: data.merchantName,
      category: data.category,
      per_page: data.perPage,
      page: data.page
    }
  };
  return apiService().get(
    `/${URL.ADMIN}/${URL.MERCHANT_POPULARS}`,
    requestOptions
  );
}

function getPopularSearch(id) {
  return apiService().get(`/${URL.ADMIN}/${URL.MERCHANT_POPULARS}/${id}`);
}

function createPopularSearch(data) {
  const IdType = () => {
    var type;
    if (data.merchantName.merchantId)
      type = { merchant_id: data.merchantName.merchantId };
    if (data.merchantName.creatorId)
      type = { creator_id: data.merchantName.creatorId };
    return type;
  };
  let requestBody = {
    category: data.category, // get from constant api "merchant_category"
    ...IdType()
  };
  return apiService().post(
    `/${URL.ADMIN}/${URL.MERCHANT_POPULARS}`,
    requestBody
  );
}

function updatePopularSearch(id, data) {
  let requestBody = {
    category: data.category // get from constant api "merchant_category"
  };
  return apiService().put(
    `/${URL.ADMIN}/${URL.MERCHANT_POPULARS}/${id}`,
    requestBody
  );
}

function deletePopularSearch(id) {
  return apiService().delete(`/${URL.ADMIN}/${URL.MERCHANT_POPULARS}/${id}`);
}
