<template>
  <div>
    <b-row no-gutters>
      <b-col cols="12" class="pb-5">
        <div class="wizard-label">
          <h3 class="wizard-title text-capitalize">
            {{ $t(`label.campaignPointSetting`) }}
          </h3>
          <div class="wizard-desc">
            {{ $t(`message.campaignPointSettingMessage`) }}
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row no-gutters class="flex-wrap">
      <b-col cols="6">
        <b-row no-gutters class="d-flex mb-3">
          <b-col cols="5" class="d-flex" style="align-items: center">
            {{ $t(`label.pointEqualTo`) }}
          </b-col>
          <b-col cols="4">
            <AppFormField
                v-model="pointValue"
                noTitle
            >
            </AppFormField>
          </b-col>

          <b-col cols="">
            <div class="ml-4">
              <i
                  class="fa la-save icon-xl ml-4 pt-2 cursor-pointer"
                  @click="onSave"
              ></i>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!--    Redeemable Product-->
    <b-row no-gutters>
      <b-col cols="12" class="pb-5 border-top pt-10">
        <div class="wizard-label">
          <h3 class="wizard-title text-capitalize">
            {{ $t(`label.redeemableProduct`) }}
          </h3>
        </div>
      </b-col>
    </b-row>
    <b-form class="form" id="kt_form">
      <b-row no-gutters>
        <template v-for="(i, index) in formFields.filter((x) => x.field == 1)">
          <b-col :key="index" xl="6" class="p-xl-1" v-if="!i.isHidden">
            <div v-if="i.layout">
              <label class="font-size-sm text-capitalize">{{ $t(`label.${i.title}`) }}</label>
              <component v-model="redeemableProduct[i.model]" :is="i.layout" :item="redeemableProduct" :value="redeemableProduct[i.model]"
                         :options="i.options" :optionsValue="i.optionsValue" :attachmentUrl="redeemableProduct[i.model]"
                         :fileType="i.fileType" :disabled="i.disabled" :solid="!i.disabled ?? true"
                         :modelName="i.model" :config="i.config" :accept="i.accept"></component>
            </div>
            <AppFormField v-else v-model="redeemableProduct[i.model]" :title="$t(`label.${i.title}`)"
                          :type="i.type" :formType="i.formType" :options="i.options"
                          :disabled="i.disabled" :textTransform="i.textTransform" :isRequires="i.isRequires" :max="i.max"
                          :solid="!i.disabled" :config="i.config" @change="i.action" @searchChange="i.searchChange">
            </AppFormField>
<!--            <div style="display: none">-->
<!--              &lt;!&ndash; WARNING: I have no idea why this line is here, but removing it breaks everything &ndash;&gt;-->
<!--              {{ $v }}-->
<!--            </div>-->
          </b-col>
        </template>
      </b-row>
    </b-form>
    <div class="d-flex justify-content-end border-top pt-10">
      <button @click="submitRedeemableProduct()" class="btn btn-primary font-weight-bold text-caitalize">
        {{ isRedeemableCreate ? $t(`label.create`) : $t(`label.update`) }}
      </button>
    </div>
  </div>
</template>

<script>
  import commonMixin from '@/core/mixins/common.mixin';
  import {i18nHelper} from '@/core/utils';
  import AppFormField from '@/views/components/bases/form-field/AppFormField';
  import {
    GENERAL_GET_POINT_SETTING,
    GENERAL_INITIAL_GET_POINT_SETTING_STATE,
    GENERAL_UPDATE_POINT_SETTING,
    GENERAL_INITIAL_UPDATE_POINT_SETTING_STATE,
    GENERAL_UPDATE_REDEEMABLE_PRODUCT
  } from '@/core/store/configuration/config-point-setting.module';
  import {listApiService, listService} from "@/core/services";
  import {GLOBAL} from "@/core/constants";
  import {MediaType} from "@/core/constants/enums";
  import AppViewAndUpload from '@/views/components/bases/AppViewAndUpload';
  import PointStatusBtn from "@/views/components/point/PointStatusBtn.vue";


  export default {
    name: 'ConfigPointFormField',
    mixins: [commonMixin],
    components: {
      PointStatusBtn,
      AppFormField,
      AppViewAndUpload
    },
    data: () => ({
      pointValue: null,
      getDineInMerchantId: [],
      isRedeemableCreate: true,
      redeemableProduct: {
        name: '',
        description: '',
        redeemablePoint: null,
        validity: null,
        merchant: 'Meltkies',
        selectedBranch: [],
        availableFrom: '',
        availableTo: '',
        terms: '',
        status: 0
      },
      formFields: []
    }),
    mounted() {
      this.initialConfiguration();
    },
    computed: {
      PointComplete() {
        return this.$store.state.configPointSetting.PointSetting.complete;
      },
      updatePointComplete() {
        return this.$store.state.configPointSetting.updatePointSetting
          .complete;
      }
    },
    watch: {
      PointComplete() {
        let response = this.$store.state.configPointSetting.PointSetting;
        let title = i18nHelper.getMessage('label.newOrderMessageNotification');
        let initialStateAction = GENERAL_INITIAL_GET_POINT_SETTING_STATE;
        let successAction = (response) => {
          let data = response.data;
          let pointSetting = data.filter(item => item.key === "POINT_SETTING")
          let redeemableProduct = data.filter(item => item.key === "REDEEMABLE_PRODUCT")

          if(pointSetting && pointSetting.length > 0) {
            this.pointValue = pointSetting[0].value;
          }
          if(redeemableProduct && redeemableProduct.length > 0) {
            redeemableProduct = JSON.parse(redeemableProduct[0].value);
            this.isRedeemableCreate = false
            this.redeemableProduct.name = redeemableProduct.name ?? null;
            this.redeemableProduct.description = redeemableProduct.description ?? null;
            this.redeemableProduct.validity = redeemableProduct.validity ?? null;
            this.redeemableProduct.selectedBranch = redeemableProduct.branch_ids ?? [];
            this.redeemableProduct.availableFrom = redeemableProduct.available_from ? redeemableProduct.available_from.split('T')[0] : null;
            this.redeemableProduct.availableTo = redeemableProduct.available_to ? redeemableProduct.available_to.split('T')[0] : null;
            this.redeemableProduct.tnc = redeemableProduct.tnc ?? null;
            this.redeemableProduct.redeemablePoint = redeemableProduct.redeemablePoint ?? null;
            this.redeemableProduct.status = JSON.parse(redeemableProduct.status) ?? false;
          }
        };
        if (response.complete) {
          this.actionReadRecordComplete(
            response,
            title,
            initialStateAction,
            successAction
          );
        }
      },
      updatePointComplete() {
        let response =
          this.$store.state.configPointSetting.updatePointSetting;
        if (response.complete) {
          this.$toast.success("Update successfully")
          this.getPoint();
          this.initialUpdatePoint();
        }
      },
    },
    methods: {
      getPoint() {
        this.$store.dispatch(GENERAL_GET_POINT_SETTING);
      },
      onSave() {
        this.$store.dispatch(GENERAL_UPDATE_POINT_SETTING, this.pointValue);
      },
      initialUpdatePoint() {
        this.$store.dispatch(GENERAL_INITIAL_UPDATE_POINT_SETTING_STATE, {});
      },
      async initialConfiguration() {
        this.getPoint();
        this.getDineInMerchantId = await listApiService.getDineInMerchantId();
        const meltkiesObjects = await this.getDineInMerchantId.filter(item => item.text === "Meltkies");
        this.redeemableProduct.merchant = meltkiesObjects[0].text
        this.getMerchantOrCreatorBranch = await listApiService.getMerchantOrCreatorBranch('merchant', [meltkiesObjects[0].value]);
        if(this.getMerchantOrCreatorBranch.list) {
          this.getMerchantOrCreatorBranch = this.getMerchantOrCreatorBranch.list
        }
        this.initFormFields();
      },
      initFormFields() {
        this.formFields = [
          {
            field: 1,
            title: 'name',
            model: 'name',
            action: () => { },
            searchChange: () => { }
          },
          {
            field: 1,
            title: 'description',
            model: 'description',
            formType: 'textarea',
            action: () => { },
            searchChange: () => { }
          },
          {
            field: 1,
            title: 'merchant',
            model: 'merchant',
            disabled: true,
            config: {
              isLoading: this.isLoading
            },
            action: () => { },
            searchChange: () => { }
          },
          {
            field: 1,
            title: 'selectedBranch',
            model: 'selectedBranch',
            formType: 'searchSelectAll',
            options: this.getMerchantOrCreatorBranch,
            config: {
              isLoading: this.isLoading
            },
            action: () => { },
            searchChange: () => { }
          },
          {
            field: 1,
            title: 'availableFrom',
            model: 'availableFrom',
            formType: 'date',
            action: () => { },
            searchChange: () => { }
          },
          {
            field: 1,
            title: 'availableTo',
            model: 'availableTo',
            formType: 'date',
            action: () => { },
            searchChange: () => { }
          },
          {
            field: 1,
            title: 'validity',
            model: 'validity',
            type: 'number',
            action: () => { },
            searchChange: () => { }
          },
          {
            field: 1,
            title: 'redeemablePoint',
            model: 'redeemablePoint',
            type: 'number',
            action: () => { },
            searchChange: () => { }
          },
          {
            field: 1,
            title: 'termsAndConditions',
            model: 'tnc',
            formType: 'textEditor',
            action: () => { },
            searchChange: () => { }
          },
          {
            field: 1,
            title: 'thumbnail',
            model: 'thumbnail',
            formType: 'file',
            layout: 'AppViewAndUpload',
            accept: GLOBAL.AVAILABLE_BANNER_IMAGE_TYPE.toString(),
            config: {
              dimension: {
                height: 400,
                width: 400
              },
              uploadSize: 100,
              sizeType: 'KB',
              mediaType: MediaType.IMAGE
            },
            action: () => { },
            searchChange: () => { }
          },
          {
            field: 1,
            title: 'status',
            model: 'status',
            formType: 'radios',
            options: listService.getYesNoList(false),
            action: () => { },
            searchChange: () => { }
          }
        ]
      },
      submitRedeemableProduct() {
        let formData = new FormData();
        let data = this.redeemableProduct;
        let selectedBranch = data.selectedBranch;

        let availableFrom = new Date(data.availableFrom);
        let availableTo = new Date(data.availableTo);

        availableFrom = availableFrom.getTime();
        availableTo = availableTo.getTime();

        if (selectedBranch) {
          selectedBranch.forEach((item) =>
              formData.append('branch_ids[]', item)
          );
        } else {
          formData.append('branch_ids[]', '');
        }
        if(data.availableFrom) {
          formData.append(
              'available_from',
              data.availableFrom == '-' || availableFrom == ''
                  ? ''
                  : availableFrom
          );
        }
        if(data.availableTo) {
          formData.append(
              'available_to',
              data.availableTo == '-' ? '' : availableTo
          );
        }
        if(data.validity) {
          formData.append('validity', data.validity);
        }
        if(data.thumbnail instanceof Blob) {
          formData.append(
              'thumbnail',
              !(data.thumbnail instanceof Blob) ? '' : data.thumbnail
          );
        }
        if(data.name) {
          formData.append('name', data.name);
        }
        if(data.description) {
          formData.append('description', data.description);
        }
        if(data.redeemablePoint) {
          formData.append('redeemablePoint', data.redeemablePoint);
        }

        //formData.append('stackable', data.stackable ? 1 : 0);
        if(data.tnc) {
          formData.append('tnc', data.tnc);
        }

        formData.append('status', data.status ? data.status : 0);

        console.log(data)
        console.log(formData.values)
        this.$store.dispatch(GENERAL_UPDATE_REDEEMABLE_PRODUCT, {
          formData
        });

      },
    }
  };
</script>

<style></style>
