<template>
  <b-card>
    <b-card-title class="text-capitalize font-weight-bolder">
      {{ $t('label.deliveryLocation') }}
    </b-card-title>

    <b-card-text>
      <div>
        <div class="text-capitalize font-weight-bolder mb-4">
          {{ $t('label.deliveryFrom') }}
        </div>
        <div>
          <template v-for="(item, index) in deliveryFromDetailsList">
            <b-row :key="index" class="mb-3">
              <b-col
                cols="3"
                class="text-capitalize font-weight-bolder d-flex justify-content-between text-gray-600"
              >
                <div>
                  {{ $t(`label.${item.name}`) }}
                </div>
                <div>:</div>
              </b-col>
              <b-col cols="9">
                <span v-if="value.deliveryFrom" class="text-break">
                  {{ value.deliveryFrom[item.key] }}
                </span>
              </b-col>
            </b-row>
          </template>
        </div>
      </div>

      <div class="separator separator-solid my-6"></div>

      <div>
        <div class="text-capitalize font-weight-bolder mb-4">
          {{ $t('label.deliveryTo') }}
        </div>

        <div v-if="value.deliveryTo">
          <template v-for="(item, index) in deliveryToDetailsList">
            <b-row :key="index" class="mb-3">
              <b-col
                cols="3"
                class="text-capitalize font-weight-bolder d-flex justify-content-between text-gray-600"
              >
                <div>
                  {{ $t(`label.${item.name}`) }}
                </div>
                <div>:</div>
              </b-col>
              <b-col cols="9">
                <div v-if="item.key == 'shareLink'">
                  <OrderDeliveryShareLinkFormField
                    :value="value.deliveryTo[item.key]"
                    :editable="editable"
                    @save="saveNewShareLink"
                  ></OrderDeliveryShareLinkFormField>
                </div>
                <span v-else> {{ value.deliveryTo[item.key] || '-' }}</span>
              </b-col>
            </b-row>
          </template>
        </div>
        <div v-else>{{ $t('label.thereAreNoInformationToShow') }}</div>
      </div>
    </b-card-text>
  </b-card>
</template>

<script>
  import OrderDeliveryShareLinkFormField from '@/views/components/orders/OrderDeliveryShareLinkFormField.vue';
  import { MODULE, ACTION } from '@/core/constants';
  import { DeliveryStatus, DeliveryMethod } from '@/core/constants/enums';
  import commonMixin from '@/core/mixins/common.mixin';

  export default {
    name: 'OrderDeliveryLocationCard',
    mixins: [commonMixin],
    components: {
      OrderDeliveryShareLinkFormField
    },
    props: {
      value: {
        type: Object,
        default: () => {}
      }
    },
    data: () => ({
      deliveryFromDetailsList: [
        {
          key: 'name',
          name: 'name'
        },
        {
          key: 'address',
          name: 'address'
        },
        {
          key: 'contactNumber',
          name: 'contactNumber'
        }
      ],
      deliveryToDetailsList: [
        {
          key: 'name',
          name: 'name'
        },
        {
          key: 'address',
          name: 'address'
        },
        {
          key: 'remark',
          name: 'remark'
        },
        {
          key: 'contactNumber',
          name: 'contactNumber'
        },
        {
          key: 'shareLink',
          name: 'shareLink'
        },
        {
          key: 'deliveryRetriedAt',
          name: 'deliveryRetriedAt'
        }
      ]
    }),
    computed: {
      meData() {
        return this.$store.state.auth.me.data;
      },
      editable() {
        let editable = false;
        let status = this.value.deliveryStatus;
        let method = this.value.deliveryMethod;
        let pic = this.value.reassignedByAdminUsername;
        if (
          (this.checkAccessRight(MODULE.ORDERS, [ACTION.ORDERS_UPDATE]) &&
            method == DeliveryMethod.PRE_ORDER &&
            status == DeliveryStatus.CANCELLE) ||
          (this.checkAccessRight(MODULE.ORDERS, [ACTION.ORDERS_UPDATE]) &&
            method == DeliveryMethod.DELIVERY_BY_CAR &&
            status == DeliveryStatus.CANCELLED) ||
          (this.checkAccessRight(MODULE.ORDERS, [ACTION.ORDERS_UPDATE]) &&
            status == DeliveryStatus.MANUAL)
        ) {
          if (pic) {
            if (pic == this.meData.username) {
              editable = true;
            }
          } else {
            editable = false;
          }
        }

        return editable;
      }
    },
    methods: {
      saveNewShareLink(value) {
        this.$emit('save-new-share-link', value);
      }
    }
  };
</script>

<style></style>
