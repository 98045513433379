var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-row',{staticClass:"justify-content-between align-items-center pb-3",attrs:{"no-gutters":""}},[_c('div',{staticClass:"text-capitalize text-md-left"},[_vm._v(" "+_vm._s(_vm.$t(`label.variationGroup`))+" ")]),_c('b-button',{attrs:{"variant":"primary","size":"sm"},on:{"click":_vm.addNewVariationGroup}},[_c('i',{staticClass:"fa-solid fa-plus"}),_vm._v(" "+_vm._s(_vm.$t('label.addVariation')))])],1),_c('b-tabs',{attrs:{"fills":""},model:{value:(_vm.variationTab),callback:function ($$v) {_vm.variationTab=$$v},expression:"variationTab"}},_vm._l((_vm.variationObj),function(item,index){return _c('b-tab',{key:index},[_c('template',{slot:"title"},[_c('span',{class:[
          {
            'd-block invalid-feedback': !_vm.validationCheckEach(
              _vm.$v.variationObj.$each[index]
            )
          }
        ]},[_vm._v(" "+_vm._s([null, ''].includes(item.name) ? 'New Group' : item.name)+" ")]),_c('i',{staticClass:"fa-solid fa-xmark pl-3",on:{"click":function($event){return _vm.actionDeleteEcoPackVariations(index)}}})]),_c('b-row',{staticClass:"align-items-center pt-4",attrs:{"no-gutters":""}},[_vm._l((_vm.formFields.filter((x) => x.section == 0)),function(i){return _c('b-row',{key:i.length,staticClass:"align-items-center col-sm-10 col-12",attrs:{"no-gutters":""}},[_c('div',{staticClass:"text-capitalize text-md-left col-sm-4 col-12"},[_vm._v(" "+_vm._s(_vm.$t(`label.${i.name}`))+" "),(i.isRequires)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]),_c('div',{staticClass:"col-sm-8 col-12"},[_c('AppFormField',{attrs:{"validationValue":_vm.$v.variationObj.$each[index][i.model],"validators":i.validators,"state":_vm.validateObjState('variationObj', i.model, true, index),"solid":"","noTitle":"","horizontal":""},model:{value:(_vm.$v.variationObj.$each[index][i.model].$model),callback:function ($$v) {_vm.$set(_vm.$v.variationObj.$each[index][i.model], "$model", $$v)},expression:"$v.variationObj.$each[index][i.model].$model"}})],1)])}),_c('b-row',{staticClass:"justify-content-center col-sm-2 col-12",attrs:{"no-gutters":""}},[(item.isActive)?_c('i',{staticClass:"fas fa-check-circle text-success icon-lg",on:{"click":function($event){item.isActive = false;
          _vm.clickVariationObjActive(item);}}}):_c('i',{staticClass:"far fa-circle icon-lg",on:{"click":function($event){item.isActive = true;
          _vm.clickVariationObjActive(item);}}})])],2),_c('div',{staticClass:"pt-6"},[_c('b-row',{staticClass:"justify-content-between align-items-center",attrs:{"no-gutters":""}},[_c('div',{staticClass:"text-capitalize text-md-left"},[_vm._v(" "+_vm._s(_vm.$t(`label.addItemOption`))+" ")]),_c('b-button',{attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.addNewVariationOption(item)}}},[_c('i',{staticClass:"fa-solid fa-plus"}),_vm._v(" "+_vm._s(_vm.$t('label.addOption')))])],1),_c('hr',{staticClass:"mt-2 mb-6",attrs:{"width":"100%"}})],1),_vm._l((item.variationOptions),function(y,items){return _c('b-row',{key:items,staticClass:"align-items-center pb-5",attrs:{"no-gutters":""}},[_c('div',{staticClass:"col-sm-10 col-12"},_vm._l((_vm.formFields.filter((x) => x.section == 1)),function(i,x){return _c('b-row',{key:x,staticClass:"pb-5 align-items-center",attrs:{"no-gutters":""}},[_c('div',{staticClass:"text-capitalize text-md-left col-sm-4 col-12",attrs:{"for":"title"}},[_c('span',[_vm._v(" "+_vm._s(`${_vm.$t(`label.${i.name}`)} ${items + 1}`)+" "),(i.isRequires)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()])]),_c('div',{staticClass:"col-sm-8 col-12"},[_c('AppFormField',{attrs:{"validationValue":_vm.$v.variationObj.$each[index].variationOptions.$each[items][
  i.model
],"state":_vm.validateObjEachState(
  _vm.$v.variationObj.$each[index].variationOptions.$each[
  items
  ][i.model]
),"title":_vm.$t(`label.${i.name}`),"type":i.type,"validators":i.validators,"validationParams":i.validationParams,"prepend":i.prepend,"solid":"","noTitle":""},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('span',{staticClass:"input-group-text text-uppercase"},[_vm._v(_vm._s(_vm.$t(`currency.${_vm.currency.MYR}`)))])]},proxy:true}],null,true),model:{value:(_vm.$v.variationObj.$each[index].variationOptions.$each[items][
                i.model
              ].$model
                ),callback:function ($$v) {_vm.$set(_vm.$v.variationObj.$each[index].variationOptions.$each[items][
                i.model
              ], "$model", $$v)},expression:"$v.variationObj.$each[index].variationOptions.$each[items][\n                i.model\n              ].$model\n                "}})],1)])}),1),_c('b-row',{staticClass:"justify-content-around col-sm-2 col-12 mb-2",attrs:{"no-gutters":""}},[_c('div',[(y.isActive)?_c('i',{staticClass:"fas fa-check-circle text-success icon-lg",on:{"click":function($event){y.isActive = false;
            _vm.clickVariationOptionsActive(item);}}}):_c('i',{staticClass:"far fa-circle icon-lg",on:{"click":function($event){y.isActive = true;
            _vm.clickVariationOptionsActive(item);}}})]),(item.variationOptions.filter((x) => x.isActive === true)
            .length > 1
            )?_c('div',[_c('i',{staticClass:"far fa-trash-alt icon-lg",on:{"click":function($event){_vm.removeVariationOption(y, index);
            _vm.clickVariationOptionsActive(item);}}})]):_vm._e()])],1)}),_c('div',{},[_c('b-row',{staticClass:"justify-content-between align-items-center",attrs:{"no-gutters":""}},[_c('div',{staticClass:"text-capitalize text-md-left"},[_vm._v(" "+_vm._s(_vm.$t(`label.requirement`))+" ")])]),_c('hr',{staticClass:"mt-2 mb-6",attrs:{"width":"100%"}}),_vm._l((_vm.formFields.filter((x) => x.section == 2)),function(i,x){return _c('b-row',{key:x,staticClass:"align-items-center p-0",attrs:{"no-gutters":""}},[_c('b-row',{staticClass:"col-sm-10 col-12",attrs:{"no-gutters":""}},[_c('div',{staticClass:"text-capitalize text-md-left col-sm-4 col-12"},[_c('span',[_vm._v(" "+_vm._s(`${_vm.$t(`label.${i.name}`)} `)+" ")])]),_c('div',{staticClass:"col-sm-8 col-12"},[_c('AppFormField',{attrs:{"validationValue":_vm.$v.variationObj.$each[index].variationRequirement[i.model],"state":_vm.validateObjEachState(
  _vm.$v.variationObj.$each[index].variationRequirement[i.model]
),"formType":i.formType,"type":i.type,"validators":i.validators,"validationParams":[
  {
    key: 'maxValue',
    params: [
      item.variationOptions.filter((x) => x.isActive === true)
        .length
    ]
  }
],"min":i.max,"max":item.variationOptions.filter((x) => x.isActive === true)
.length,"disabled":!_vm.variationObj[index].variationRequirement.isActive,"solid":"","noTitle":""},model:{value:(_vm.$v.variationObj.$each[index].variationRequirement[i.model]
                .$model
                ),callback:function ($$v) {_vm.$set(_vm.$v.variationObj.$each[index].variationRequirement[i.model]
                , "$model", $$v)},expression:"$v.variationObj.$each[index].variationRequirement[i.model]\n                .$model\n                "}})],1)]),_c('div',{staticClass:"col-sm-2 col-12 text-center"},[(_vm.variationObj[index].variationRequirement.isActive)?_c('i',{staticClass:"fas fa-check-circle text-success icon-lg",on:{"click":function($event){_vm.variationObj[index].variationRequirement.isActive = false;
            _vm.clickVariationRequirementActive(_vm.variationObj[index]);}}}):_c('i',{staticClass:"far fa-circle icon-lg",on:{"click":function($event){_vm.variationObj[index].variationRequirement.isActive = true;
            _vm.clickVariationRequirementActive(_vm.variationObj[index]);}}})])],1)})],2)],2)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }