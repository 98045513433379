// import { i18nHelper } from '@/core/utils';
import { ROUTE_NAME } from '@/core/constants';
import { RecipePageType } from '@/core/constants/enums';
import { i18nHelper } from '@/core/utils';
import {
  RECIPE_PAGE_GET_RECIPE_PAGE,
  RECIPE_PAGE_CREATE_RECIPE_PAGE,
  RECIPE_PAGE_UPDATE_RECIPE_PAGE,
  RECIPE_PAGE_DELETE_RECIPE_PAGE,
  RECIPE_PAGE_INITIAL_GET_RECIPE_PAGE_STATE,
  RECIPE_PAGE_INITIAL_CREATE_RECIPE_PAGE_STATE,
  RECIPE_PAGE_INITIAL_UPDATE_RECIPE_PAGE_STATE,
  RECIPE_PAGE_INITIAL_DELETE_RECIPE_PAGE_STATE
} from '@/core/store/recipe-page.module';

const recipePageMixin = {
  computed: {
    getRecipePageComplete() {
      return this.$store.state.recipePage.recipePage.complete;
    },
    createRecipePageComplete() {
      return this.$store.state.recipePage.createRecipePage.complete;
    },
    updateRecipePageComplete() {
      return this.$store.state.recipePage.updateRecipePage.complete;
    },
    deleteRecipePageComplete() {
      return this.$store.state.recipePage.deleteRecipePage.complete;
    }
  },
  watch: {
    getRecipePageComplete() {
      let response = this.$store.state.recipePage.recipePage;
      if (response.complete) {
        this.recipeObj = response.data;
        this.recipePageCompleteAction(response, 'get');
      }
    },
    createRecipePageComplete() {
      let response = this.$store.state.recipePage.createRecipePage;
      if (response.complete) {
        this.recipePageCompleteAction(response, 'create');
      }
    },
    updateRecipePageComplete() {
      let response = this.$store.state.recipePage.updateRecipePage;
      if (response.complete) {
        this.recipePageCompleteAction(response, 'update');
      }
    },
    deleteRecipePageComplete() {
      let response = this.$store.state.recipePage.deleteRecipePage;
      if (response.complete) {
        this.recipePageCompleteAction(response, 'delete');
      }
    }
  },
  methods: {
    getRecipePageRouteName(type) {
      let routeName = {
        [RecipePageType.BAKERY]: ROUTE_NAME.RECIPE_PAGE_BAKERY_LIST,
        [RecipePageType.ASIAN]: ROUTE_NAME.RECIPE_PAGE_ASIAN_LIST,
        [RecipePageType.WESTERN]: ROUTE_NAME.RECIPE_PAGE_WESTERN_LIST
      };
      return routeName[type];
    },
    getRecipePageDetailsRouteName(type) {
      let routeName = {
        [RecipePageType.BAKERY]: ROUTE_NAME.RECIPE_PAGE_BAKERY_DETAILS,
        [RecipePageType.ASIAN]: ROUTE_NAME.RECIPE_PAGE_ASIAN_DETAILS,
        [RecipePageType.WESTERN]: ROUTE_NAME.RECIPE_PAGE_WESTERN_DETAILS
      };
      return routeName[type];
    },
    getRecipePageNewRouteName(type) {
      let routeName = {
        [RecipePageType.BAKERY]: ROUTE_NAME.RECIPE_PAGE_BAKERY_CREATE,
        [RecipePageType.ASIAN]: ROUTE_NAME.RECIPE_PAGE_ASIAN_CREATE,
        [RecipePageType.WESTERN]: ROUTE_NAME.RECIPE_PAGE_WESTERN_CREATE
      };
      return routeName[type];
    },
    getRecipeCategoryType(type) {
      // 1 - Asian | 2 - Western | 3 - Bakery
      let categoryType = {
        [RecipePageType.ASIAN]: 1,
        [RecipePageType.WESTERN]: 2,
        [RecipePageType.BAKERY]: 3
      };
      return categoryType[type];
    },
    recipePageCompleteAction(response, actionType) {
      let title = i18nHelper.getMessage('label.recipePage');
      let messages = response.message;
      let buttons = [];
      switch (actionType) {
        case 'get':
          this.initialGetRecipePageState();
          break;
        case 'create':
          this.initialCreateRecipePageState();
          break;
        case 'update':
          this.initialUpdateRecipePageState();
          break;
        case 'delete':
          this.initialDeleteRecipePageState();
          break;
      }
      if (response.code == 0) {
        buttons.push({
          text: i18nHelper.getMessage('label.ok'),
          variant: 'primary',
          action: () => {
            this.closeLayoutAppModalInfo();
            switch (actionType) {
              case 'create':
                this.$router.push({
                  name: this.getRecipePageDetailsRouteName(this.category),
                  params: { id: response.data.id }
                });
                break;
              case 'delete':
                this.$router.push({
                  name: this.getRecipePageRouteName(this.category)
                });
                break;
              default:
                this.getRecipePage(this.id);
            }
          }
        });
      } else {
        buttons.push({
          text: i18nHelper.getMessage('label.ok'),
          variant: 'primary',
          action: () => {
            this.closeLayoutAppModalInfo();
          }
        });
      }
      if (messages) this.openLayoutAppModalInfo(title, messages, buttons);
    },
    getRecipePage(id) {
      this.$store.dispatch(RECIPE_PAGE_GET_RECIPE_PAGE, { id });
    },
    createRecipePage(data) {
      this.$store.dispatch(RECIPE_PAGE_CREATE_RECIPE_PAGE, { data });
    },
    updateRecipePage(id, data) {
      this.$store.dispatch(RECIPE_PAGE_UPDATE_RECIPE_PAGE, { id, data });
    },
    deleteRecipePage(id) {
      this.$store.dispatch(RECIPE_PAGE_DELETE_RECIPE_PAGE, { id });
    },
    initialGetRecipePageState() {
      this.$store.dispatch(RECIPE_PAGE_INITIAL_GET_RECIPE_PAGE_STATE);
    },
    initialCreateRecipePageState() {
      this.$store.dispatch(RECIPE_PAGE_INITIAL_CREATE_RECIPE_PAGE_STATE);
    },
    initialUpdateRecipePageState() {
      this.$store.dispatch(RECIPE_PAGE_INITIAL_UPDATE_RECIPE_PAGE_STATE);
    },
    initialDeleteRecipePageState() {
      this.$store.dispatch(RECIPE_PAGE_INITIAL_DELETE_RECIPE_PAGE_STATE);
    }
  }
};

export default recipePageMixin;
