import constantsDescription from '@/core/filters/constants-description.filter';
import { RecipeStatus } from '@/core/constants/enums';
import { MOBILE_APPLICATION } from '@/core/api';
import { apiHelper } from '@/core/utils';
import {
  LOADING_OPEN_LOADING,
  LOADING_CLOSE_LOADING
} from '@/core/store/loading.module';

/* Define Module Name */
const MODULE_NAME = 'mobileApplication/';

/* Define Action Name */
const A_GET_MOBILE_APPLICATION_ALL_VIDEOS = 'setGetMobileApplicationAllVideos';
const A_GET_MOBILE_APPLICATION_ECO_PACKS = 'getMobileApplicationEcoPacks';
const A_GET_MOBILE_APPLICATION_MERCHANTS = 'getMobileApplicationMerchants';
const A_UPDATE_MOBILE_APPLICATION_ALL_VIDEO = 'updateMobileApplicationAllVideo';
const A_UPDATE_MOBILE_APPLICATION_ECO_PACK = 'updateMobileApplicationEcoPack';
const A_UPDATE_MOBILE_APPLICATION_MERCHANT = 'updateMobileApplicationMerchant';

/* Define Reset State Action Name */
const A_INITIAL_GET_MOBILE_APPLICATION_ALL_VIDEOS_STATE =
  'initialGetMobileApplicationAllVideosState';
const A_INITIAL_GET_MOBILE_APPLICATION_ECO_PACKS_STATE =
  'initialGetMobileApplicationEcoPacksState';
const A_INITIAL_GET_MOBILE_APPLICATION_MERCHANTS_STATE =
  'initialGetMobileApplicationMerchantsState';
const A_INITIAL_UPDATE_MOBILE_APPLICATION_ALL_VIDEO_STATE =
  'initialUpdateMobileApplicationAllVideoState';
const A_INITIAL_UPDATE_MOBILE_APPLICATION_ECO_PACK_STATE =
  'initialUpdateMobileApplicationEcoPackState';
const A_INITIAL_UPDATE_MOBILE_APPLICATION_MERCHANT_STATE =
  'initialUpdateMobileApplicationMerchantState';

/* Define Getter Name */

/* Define Mutation Name */
const M_GET_MOBILE_APPLICATION_ALL_VIDEOS = 'setGetMobileApplicationAllVideos';
const M_GET_MOBILE_APPLICATION_ECO_PACKS = 'setGetMobileApplicationEcoPacks';
const M_GET_MOBILE_APPLICATION_MERCHANTS = 'setGetMobileApplicationMerchants';
const M_UPDATE_MOBILE_APPLICATION_ALL_VIDEO =
  'setUpdateMobileApplicationAllVideo';
const M_UPDATE_MOBILE_APPLICATION_ECO_PACK =
  'setUpdateMobileApplicationEcoPack';
const M_UPDATE_MOBILE_APPLICATION_MERCHANT =
  'setUpdateMobileApplicationMerchant';

/* Define Reset State Mutation Name */
const M_INITIAL_GET_MOBILE_APPLICATION_ALL_VIDEOS_STATE =
  'setInitialGetMobileApplicationAllVideosState';
const M_INITIAL_GET_MOBILE_APPLICATION_ECO_PACKS_STATE =
  'setInitialGetMobileApplicationEcoPacksState';
const M_INITIAL_GET_MOBILE_APPLICATION_MERCHANTS_STATE =
  'setInitialGetMobileApplicationEcoPacksState2';
const M_INITIAL_UPDATE_MOBILE_APPLICATION_ALL_VIDEO_STATE =
  'setInitialUpdateMobileApplicationAllVideoState';
const M_INITIAL_UPDATE_MOBILE_APPLICATION_ECO_PACK_STATE =
  'setInitialUpdateMobileApplicationEcoPackState';
const M_INITIAL_UPDATE_MOBILE_APPLICATION_MERCHANT_STATE =
  'setInitialUpdateMobileApplicationMerchantState';

/* Define Export Name */
export const MOBILE_APPLICATION_GET_MOBILE_APPLICATION_ALL_VIDEOS =
  MODULE_NAME + A_GET_MOBILE_APPLICATION_ALL_VIDEOS;
export const MOBILE_APPLICATION_GET_MOBILE_APPLICATION_ECO_PACKS =
  MODULE_NAME + A_GET_MOBILE_APPLICATION_ECO_PACKS;
export const MOBILE_APPLICATION_GET_MOBILE_APPLICATION_MERCHANTS =
  MODULE_NAME + A_GET_MOBILE_APPLICATION_MERCHANTS;
export const MOBILE_APPLICATION_UPDATE_MOBILE_APPLICATION_ALL_VIDEO =
  MODULE_NAME + A_UPDATE_MOBILE_APPLICATION_ALL_VIDEO;
export const MOBILE_APPLICATION_UPDATE_MOBILE_APPLICATION_ECO_PACK =
  MODULE_NAME + A_UPDATE_MOBILE_APPLICATION_ECO_PACK;
export const MOBILE_APPLICATION_UPDATE_MOBILE_APPLICATION_MERCHANT =
  MODULE_NAME + A_UPDATE_MOBILE_APPLICATION_MERCHANT;

/* Define Reset State Name */
export const MOBILE_APPLICATION_INITIAL_GET_MOBILE_APPLICATION_ALL_VIDEOS_STATE =
  MODULE_NAME + A_INITIAL_GET_MOBILE_APPLICATION_ALL_VIDEOS_STATE;
export const MOBILE_APPLICATION_INITIAL_GET_MOBILE_APPLICATION_ECO_PACKS_STATE =
  MODULE_NAME + A_INITIAL_GET_MOBILE_APPLICATION_ECO_PACKS_STATE;
export const MOBILE_APPLICATION_INITIAL_GET_MOBILE_APPLICATION_MERCHANTS_STATE =
  MODULE_NAME + A_INITIAL_GET_MOBILE_APPLICATION_MERCHANTS_STATE;
export const MOBILE_APPLICATION_INITIAL_UPDATE_MOBILE_APPLICATION_ALL_VIDEO_STATE =
  MODULE_NAME + A_INITIAL_UPDATE_MOBILE_APPLICATION_ALL_VIDEO_STATE;
export const MOBILE_APPLICATION_INITIAL_UPDATE_MOBILE_APPLICATION_ECO_PACK_STATE =
  MODULE_NAME + A_INITIAL_UPDATE_MOBILE_APPLICATION_ECO_PACK_STATE;
export const MOBILE_APPLICATION_INITIAL_UPDATE_MOBILE_APPLICATION_MERCHANT_STATE =
  MODULE_NAME + A_INITIAL_UPDATE_MOBILE_APPLICATION_MERCHANT_STATE;

const state = {
  mobileApplicationAllVideos: {
    code: 0,
    complete: false,
    data: null,
    message: null,
    pagination: {
      currentPage: 0,
      total: 0,
      perPage: 0,
      lastPage: 0
    }
  },
  mobileApplicationEcoPacks: {
    code: 0,
    complete: false,
    data: null,
    message: null,
    pagination: {
      currentPage: 0,
      total: 0,
      perPage: 0,
      lastPage: 0
    }
  },
  mobileApplicationMerchants: {
    code: 0,
    complete: false,
    data: null,
    message: null,
    pagination: {
      currentPage: 0,
      total: 0,
      perPage: 0,
      lastPage: 0
    }
  },
  updateMobileApplicationAllVideo: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  updateMobileApplicationEcoPack: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  updateMobileApplicationMerchant: {
    code: 0,
    complete: false,
    data: null,
    message: null
  }
};

//Getters
const getters = {};

// Actions
const actions = {
  [A_GET_MOBILE_APPLICATION_ALL_VIDEOS]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    MOBILE_APPLICATION.getMobileApplicationAllVideos(data)
      .then((response) => {
        result = response;
        commit(M_GET_MOBILE_APPLICATION_ALL_VIDEOS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_MOBILE_APPLICATION_ALL_VIDEOS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_MOBILE_APPLICATION_ECO_PACKS]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    MOBILE_APPLICATION.getMobileApplicationEcoPacks(data)
      .then((response) => {
        result = response;
        commit(M_GET_MOBILE_APPLICATION_ECO_PACKS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_MOBILE_APPLICATION_ECO_PACKS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_MOBILE_APPLICATION_MERCHANTS]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    MOBILE_APPLICATION.getMobileApplicationMerchants(data)
      .then((response) => {
        result = response;
        commit(M_GET_MOBILE_APPLICATION_MERCHANTS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_MOBILE_APPLICATION_MERCHANTS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_UPDATE_MOBILE_APPLICATION_ALL_VIDEO]({ dispatch, commit }, { id, data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    MOBILE_APPLICATION.updateMobileApplicationAllVideo(id, data)
      .then((response) => {
        result = response;
        commit(M_UPDATE_MOBILE_APPLICATION_ALL_VIDEO, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_UPDATE_MOBILE_APPLICATION_ALL_VIDEO, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_UPDATE_MOBILE_APPLICATION_ECO_PACK]({ dispatch, commit }, { id, data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    MOBILE_APPLICATION.updateMobileApplicationEcoPack(id, data)
      .then((response) => {
        result = response;
        commit(M_UPDATE_MOBILE_APPLICATION_ECO_PACK, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_UPDATE_MOBILE_APPLICATION_ECO_PACK, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_UPDATE_MOBILE_APPLICATION_MERCHANT]({ dispatch, commit }, { id, data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    MOBILE_APPLICATION.updateMobileApplicationMerchant(id, data)
      .then((response) => {
        result = response;
        commit(M_UPDATE_MOBILE_APPLICATION_MERCHANT, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_UPDATE_MOBILE_APPLICATION_MERCHANT, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_INITIAL_GET_MOBILE_APPLICATION_ALL_VIDEOS_STATE]({ commit }) {
    commit(M_INITIAL_GET_MOBILE_APPLICATION_ALL_VIDEOS_STATE);
  },
  [A_INITIAL_GET_MOBILE_APPLICATION_ECO_PACKS_STATE]({ commit }) {
    commit(M_INITIAL_GET_MOBILE_APPLICATION_ECO_PACKS_STATE);
  },
  [A_INITIAL_GET_MOBILE_APPLICATION_MERCHANTS_STATE]({ commit }) {
    commit(M_INITIAL_GET_MOBILE_APPLICATION_MERCHANTS_STATE);
  },
  [A_INITIAL_UPDATE_MOBILE_APPLICATION_ALL_VIDEO_STATE]({ commit }) {
    commit(M_INITIAL_UPDATE_MOBILE_APPLICATION_ALL_VIDEO_STATE);
  },
  [A_INITIAL_UPDATE_MOBILE_APPLICATION_ECO_PACK_STATE]({ commit }) {
    commit(M_INITIAL_UPDATE_MOBILE_APPLICATION_ECO_PACK_STATE);
  },
  [A_INITIAL_UPDATE_MOBILE_APPLICATION_MERCHANT_STATE]({ commit }) {
    commit(M_INITIAL_UPDATE_MOBILE_APPLICATION_MERCHANT_STATE);
  }
};

// Mutations
const mutations = {
  [M_GET_MOBILE_APPLICATION_ALL_VIDEOS](state, result) {
    if (result.code === 0) {
      let d = result.data;
      let p = result.pagination;

      state.mobileApplicationAllVideos = {
        code: result.code,
        complete: true,
        data: d.map((x, index) => {
          let status = null;

          if (!x.verified_at && !x.deleted_at) {
            status = RecipeStatus.PENDING;
          } else if (x.verified_at && !x.deleted_at) {
            status = RecipeStatus.VERIFIED;
          } else if (x.deleted_at) {
            status = RecipeStatus.DELETED;
          }

          return {
            no: index + 1,
            id: x.id,
            name: {
              name: x.name,
              id: x.id
            },
            createdBy: {
              name: x.store_name ?? x.username,
              id: x.user_id
            },
            cookingLevel: constantsDescription('cookingLevel', x.cooking_level),
            foodType: constantsDescription(
              'foodType',
              x.food_type ?? x.extra.food_type
            ),
            status: status,
            ecoPackOption: constantsDescription(
              'ecoPackOption',
              x.eco_pack_option
            ),
            priority: x.priority != 0 ? x.priority : '-'
          };
        }),
        message: null,
        pagination: {
          currentPage: p.current_page,
          total: p.total,
          perPage: p.per_page,
          lastPage: p.last_page
        }
      };
    } else {
      state.mobileApplicationAllVideos = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message),
        pagination: {
          currentPage: 0,
          total: 0,
          perPage: 0,
          lastPage: 0
        }
      };
    }
  },
  [M_GET_MOBILE_APPLICATION_ECO_PACKS](state, result) {
    if (result.code === 0) {
      let d = result.data;
      let p = result.pagination;

      state.mobileApplicationEcoPacks = {
        code: result.code,
        complete: true,
        data: d.map((x, index) => {
          let status = null;

          if (!x.verified_at && !x.deleted_at) {
            status = RecipeStatus.PENDING;
          } else if (x.verified_at && !x.deleted_at) {
            status = RecipeStatus.VERIFIED;
          } else if (x.deleted_at) {
            status = RecipeStatus.DELETED;
          }

          return {
            no: index + 1,
            id: x.id,
            name: {
              name: x.name,
              id: x.id
            },
            createdBy: {
              name: x.store_name ?? x.username,
              id: x.user_id
            },
            cookingLevel: constantsDescription('cookingLevel', x.cooking_level),
            foodType: constantsDescription(
              'foodType',
              x.food_type ?? x.extra.food_type
            ),
            status: status,
            ecoPackOption: constantsDescription(
              'ecoPackOption',
              x.eco_pack_option
            ),
            priority: x.priority != 0 ? x.priority : '-'
          };
        }),
        message: null,
        pagination: {
          currentPage: p.current_page,
          total: p.total,
          perPage: p.per_page,
          lastPage: p.last_page
        }
      };
    } else {
      state.mobileApplicationEcoPacks = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message),
        pagination: {
          currentPage: 0,
          total: 0,
          perPage: 0,
          lastPage: 0
        }
      };
    }
  },
  [M_GET_MOBILE_APPLICATION_MERCHANTS](state, result) {
    if (result.code === 0) {
      let d = result.data;
      let p = result.pagination;
      state.mobileApplicationMerchants = {
        code: result.code,
        complete: true,
        data: d.map((x, index) => {
          return {
            no: index + 1,
            id: x.id,
            name: x.name,
            merchantType: x.merchant_type,
            serviceType: x.service_type_description,
            priority: x.priority != 0 ? x.priority : '-'
          };
        }),
        message: null,
        pagination: {
          currentPage: p.current_page,
          total: p.total,
          perPage: p.per_page,
          lastPage: p.last_page
        }
      };
    } else {
      state.mobileApplicationMerchants = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message),
        pagination: {
          currentPage: 0,
          total: 0,
          perPage: 0,
          lastPage: 0
        }
      };
    }
  },
  [M_UPDATE_MOBILE_APPLICATION_ALL_VIDEO](state, result) {
    state.updateMobileApplicationAllVideo = {
      code: result.code,
      complete: true,
      data: result.data,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_UPDATE_MOBILE_APPLICATION_ECO_PACK](state, result) {
    state.updateMobileApplicationEcoPack = {
      code: result.code,
      complete: true,
      data: result,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_UPDATE_MOBILE_APPLICATION_MERCHANT](state, result) {
    state.updateMobileApplicationMerchant = {
      code: result.code,
      complete: true,
      data: result,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_INITIAL_GET_MOBILE_APPLICATION_ALL_VIDEOS_STATE](state) {
    state.mobileApplicationAllVideos = {
      code: 0,
      complete: false,
      data: null,
      message: null,
      pagination: {
        currentPage: 0,
        total: 0,
        perPage: 0,
        lastPage: 0
      }
    };
  },
  [M_INITIAL_GET_MOBILE_APPLICATION_ECO_PACKS_STATE](state) {
    state.mobileApplicationEcoPacks = {
      code: 0,
      complete: false,
      data: null,
      message: null,
      pagination: {
        currentPage: 0,
        total: 0,
        perPage: 0,
        lastPage: 0
      }
    };
  },
  [M_INITIAL_GET_MOBILE_APPLICATION_MERCHANTS_STATE](state) {
    state.mobileApplicationMerchants = {
      code: 0,
      complete: false,
      data: null,
      message: null,
      pagination: {
        currentPage: 0,
        total: 0,
        perPage: 0,
        lastPage: 0
      }
    };
  },
  [M_INITIAL_UPDATE_MOBILE_APPLICATION_ALL_VIDEO_STATE](state) {
    state.updateMobileApplicationAllVideo = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_UPDATE_MOBILE_APPLICATION_ECO_PACK_STATE](state) {
    state.updateMobileApplicationEcoPack = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_UPDATE_MOBILE_APPLICATION_MERCHANT_STATE](state) {
    state.updateMobileApplicationMerchant = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
