<template>
  <div>
    <!-- Form Title -->
    <h6 class="mb-8 mt-5 font-weight-bold text-dark text-capitalize">
      {{ $t('label.subsidiaryCompany') }}
    </h6>
    <!-- Form Field  -->
    <b-form class="form" id="kt_form">
      <template v-for="(i, index) in formFields.filter((x) => x.field == 1)">
        <div :key="index" v-if="i.noCreate !== isCreate || !isCreate">
          <component
            v-if="i.layout"
            v-model="$v.subsidiaryObj[i.model].$model"
            :ref="i.ref"
            :refName="i.ref"
            :is="i.layout"
            :title="$t(`label.${i.title}`)"
            :state="validateObjState('subsidiaryObj', i.model)"
            :disabled="i.disabled"
            :actionType="actionType.PRODUCT"
            :category="actionType.PRODUCT"
            :isExisting="!isCreate"
            :solid="!i.disabled"
            :config="i.config"
            @valueObj="valueObj"
            @subsidiaryCompanyName="$v.subsidiaryObj[i.model]"
          >
          </component>
          <AppFormField
            v-else
            v-model="$v.subsidiaryObj[i.model].$model"
            :state="validateObjState('subsidiaryObj', i.model)"
            :validationValue="$v.subsidiaryObj[i.model]"
            :title="$t(`label.${i.title}`)"
            :validators="i.validators"
            :formType="i.formType"
            :config="i.config"
            :validationParams="i.validationParams"
            :moreRemark="i.moreRemark"
            :isRequires="i.isRequires"
            :prepend="i.prepend"
            :disabled="i.disabled"
            :min="i.min"
            :solid="!i.disabled"
          >
            <template v-slot:prepend>
              <span class="input-group-text text-uppercase">+60</span>
            </template>
          </AppFormField>
          <div style="display: none">
            <!-- WARNING: I have no idea why this line is here, but removing it breaks everything -->
            {{ $v }}
          </div>
        </div>
      </template>
    </b-form>
  </div>
</template>

<script>
  import AppFormField from '@/views/components/bases/form-field/AppFormField';
  import SubsidiaryLiveSearch from '@/views/components/merchant-grouping/field/SubsidiaryLiveSearch';
  import constantsMixin from '@/core/mixins/constants.mixin';
  import commonMixin from '@/core/mixins/common.mixin';
  import { validationMixin } from 'vuelidate';
  import { required, numeric } from 'vuelidate/lib/validators';
  import { mobileNumber } from '@/core/constants';
  import { BannerActionType } from '@/core/constants/enums';

  export default {
    name: 'MerchantGroupingParentCompanyForm',
    mixins: [commonMixin, constantsMixin, validationMixin],
    components: {
      AppFormField,
      SubsidiaryLiveSearch
    },
    props: {
      id: {},
      isCreate: {
        type: Boolean,
        default: false
      },
      moduleName: {},
      permissionAction: {}
    },
    data: () => ({
      formFields: [],
      actionType: BannerActionType,
      subsidiaryObj: {
        subsidiaryCompanyName: null,
        contactPerson1: null,
        contactNumber1: null,
        contactPerson2: null,
        contactNumber2: null
      }
    }),
    mounted() {
      this.initFormFields();
    },
    computed: {},
    watch: {},
    methods: {
      valueObj(val) {
        this.subsidiaryObj.contactPerson1 = val.username;
        this.subsidiaryObj.contactNumber1 = val.contactNumber.split('-')[1];
      },
      initFormFields() {
        this.formFields.push(
          {
            field: 1,
            model: 'subsidiaryCompanyName',
            title: 'subsidiaryCompanyName',
            ref: 'subsidiaryCompanyName',
            validators: ['required'],
            isRequires: true,
            layout: 'SubsidiaryLiveSearch'
          },
          {
            field: 1,
            model: 'contactPerson1',
            title: 'contactPerson1',
            validators: ['required'],
            disabled: true,
            isRequires: true
          },
          {
            field: 1,
            model: 'contactNumber1',
            title: 'contactNumber1',
            type: 'number',
            validators: ['required', 'mobileNumber', 'numeric'],
            prepend: true,
            disabled: true,
            isRequires: true
          },
          {
            field: 1,
            model: 'contactPerson2',
            title: 'contactPerson2',
            validators: []
          },

          {
            field: 1,
            model: 'contactNumber2',
            title: 'contactNumber2',
            type: 'number',
            validators: ['mobileNumber', 'numeric'],
            prepend: true
          }
        );
      },
      validationCheckForm() {
        //Submit new banner form if all the validation is no error
        return this.validationCheck('subsidiaryObj');
      },
      validationActionValueCheck() {
        var isValid = true;
        isValid = this.$refs['subsidiaryCompanyName'][0].validate();
        return isValid;
      }
    },
    validations() {
      return {
        subsidiaryObj: {
          subsidiaryCompanyName: { required },
          contactPerson1: { required },
          contactNumber1: { required, mobileNumber, numeric },
          contactPerson2: {},
          contactNumber2: { mobileNumber, numeric }
        }
      };
    }
  };
</script>

<style></style>
