<template>
  <div>
    <AppSmartList
      :breadcrumbData="breadcrumbData"
      :accessRights="accessRights"
      :filters="filters"
      :actions="actions"
      :tableHeaders="headers"
      :subMenu="category"
    >
      <template v-slot:buttons>
        <AppExportExcel
          :exportExcelObj="exportExcel"
          :isDate="true"
        ></AppExportExcel>
      </template>
    </AppSmartList>
  </div>
</template>

<script>
  import AppSmartList from '@/views/components/bases/smart/AppSmartList.vue';
  import AppExportExcel from '@/views/components/bases/AppExportExcel.vue';
  import { uiHelper } from '@/core/utils';
  import {
    ROUTE_NAME,
    MODULE,
    ACTION,
    APP_COMPONENT_NAME,
    SMART_FORM_FIELD_TYPE,
    LOCAL
  } from '@/core/constants';
  import { ActiveStatusNum } from '@/core/constants/enums';
  import { i18nHelper } from '@/core/utils';
  import { listService } from '@/core/services';
  import {
    RECIPE_GET_RECIPES,
    RECIPE_INITIAL_GET_RECIPES_STATE
  } from '@/core/store/recipe.module';
  const CONSTANTS_DATA = JSON.parse(uiHelper.getLocalStorage(LOCAL.CONSTANTS));

  export default {
    name: 'RecipeListTemplate',
    components: {
      AppSmartList,
      AppExportExcel
    },
    props: {
      breadcrumbData: {
        type: Object,
        defualt: () => {}
      },
      category: {
        type: String,
        defualt: ''
      }
    },
    data: () => ({
      accessRights: {
        moduleName: MODULE.RECIPE,
        actions: {
          create: ACTION.RECIPE_CREATE,
          read: ACTION.RECIPE_READ,
          update: ACTION.RECIPE_UPDATE,
          delete: ACTION.RECIPE_DELETE
        }
      },
      filters: [],
      headers: [
        {
          key: 'no',
          name: 'no',
          active: true,
          sortable: true
        },
        {
          key: 'name',
          name: 'name',
          active: true,
          sortable: true,
          class: 'min-w-100px'
        },
        {
          key: 'id',
          name: 'id',
          active: true,
          sortable: true
        },
        {
          key: 'username',
          name: 'createdBy',
          active: true,
          sortable: true,
          class: 'min-w-130px'
        },
        {
          key: 'cookingLevel',
          name: 'cookingLevel',
          active: true,
          sortable: true
        },
        {
          key: 'foodType',
          name: 'foodType',
          active: true,
          sortable: true
        },
        {
          key: 'status',
          name: 'status',
          active: true,
          sortable: true,
          layout: 'RecipeStatusLabel'
        },
        {
          key: 'ecoPackOption',
          name: 'ecoPackOption',
          active: true,
          sortable: true
        },
        {
          key: 'isActive',
          name: 'active',
          active: true,
          sortable: true,
          layout: APP_COMPONENT_NAME.APP_CHECK_CIRCLE
        },
        {
          key: 'isPopular',
          name: 'popular',
          active: true,
          sortable: true,
          layout: APP_COMPONENT_NAME.APP_CHECK_CIRCLE
        },
        {
          key: 'isTrending',
          name: 'trending',
          active: true,
          sortable: true,
          layout: APP_COMPONENT_NAME.APP_CHECK_CIRCLE
        },
        {
          key: 'isTop10',
          name: 'top10',
          active: true,
          sortable: true,
          layout: APP_COMPONENT_NAME.APP_CHECK_CIRCLE
        },
        {
          key: 'publishedAt',
          name: 'publishedAt',
          active: true,
          sortable: true
        },
        {
          key: 'actions',
          name: 'actions',
          active: true,
          action: true,
          layout: APP_COMPONENT_NAME.APP_TABLE_ACTIONS,
          layoutActions: {
            viewDetailsRouteName: ROUTE_NAME.EXPERIENCE_COOKING_DETAILS
          }
        }
      ],
      exportExcel: {
        disabled: false,
        fileName: 'Experience Cooking list',
        api: 'admin/recipes/report/listing/download'
      },
      actions: {
        viewList: {
          module: 'recipe',
          state: 'recipes',
          action: RECIPE_GET_RECIPES,
          initialStateAction: RECIPE_INITIAL_GET_RECIPES_STATE,
          title: i18nHelper.getMessage('label.getExperienceCookingList')
        }
      }
    }),
    created() {
      this.newFilters();
    },
    methods: {
      newFilters() {
        this.filters.push(
          {
            label: 'prefilter',
            key: 'prefilter',
            value: this.category,
            isHidden: true
          },
          {
            label: 'name',
            key: 'name',
            value: ''
          },
          {
            label: 'id',
            key: 'id',
            value: null
          },
          {
            label: 'userId',
            key: 'userId',
            value: null
          },
          {
            label: 'createdBy',
            key: 'username',
            value: ''
          },
          {
            label: 'cookingLevel',
            key: 'cookingLevel',
            value: [],
            options: listService.getDynamicList(CONSTANTS_DATA.cookingLevel),
            optionLabel: 'text',
            type: SMART_FORM_FIELD_TYPE.MULTI_SELECT
          },
          {
            label: 'foodType',
            key: 'foodType',
            value: [],
            options: listService.getDynamicList(CONSTANTS_DATA.foodType),
            optionLabel: 'text',
            type: SMART_FORM_FIELD_TYPE.MULTI_SELECT
          },
          {
            label: 'ecoPackOption',
            key: 'ecoPackOption',
            value: '',
            options: listService.getDynamicList(CONSTANTS_DATA.ecoPackOption),
            optionLabel: 'text',
            type: SMART_FORM_FIELD_TYPE.SELECT
          },
          {
            label: 'popular',
            key: 'isPopular',
            value: '',
            options: listService.getYesNoList(false),
            optionLabel: 'text',
            type: SMART_FORM_FIELD_TYPE.SELECT
          },
          {
            label: 'trending',
            key: 'isTrending',
            value: '',
            options: listService.getYesNoList(false),
            optionLabel: 'text',
            type: SMART_FORM_FIELD_TYPE.SELECT
          },
          {
            label: 'status',
            key: 'status',
            value: '',
            options: listService.getRecipeStatusList(),
            optionLabel: 'text',
            type: SMART_FORM_FIELD_TYPE.SELECT
          },
          {
            label: 'top10',
            key: 'isTop10',
            value: '',
            options: listService.getYesNoList(false),
            optionLabel: 'text',
            type: SMART_FORM_FIELD_TYPE.SELECT
          },
          {
            label: 'active',
            key: 'isActive',
            value: ActiveStatusNum.ACTIVE,
            options: listService.getYesNoList(false),
            optionLabel: 'text',
            type: SMART_FORM_FIELD_TYPE.SELECT
          }
        );
      }
    }
  };
</script>

<style></style>
