import { OFFLINE_MERCHANT } from '@/core/api';
import { apiHelper } from '@/core/utils';
import {
  LOADING_OPEN_LOADING,
  LOADING_CLOSE_LOADING
} from '@/core/store/loading.module';
import { i18nHelper } from '@/core/utils';
import dateFormat from '@/core/filters/dateFormat.filter';
import constantsDescription from '@/core/filters/constants-description.filter';

/* Define Module Name */
const MODULE_NAME = 'offlineMerchant/';

/* Define Action Name */
const A_GET_OFFLINE_MERCHANTS = 'getOfflineMerchants';
const A_GET_OFFLINE_MERCHANT = 'getOfflineMerchant';
const A_GET_OFFLINE_MERCHANT_BRANCH = 'getOfflineMerchantBranch';
const A_GET_OFFLINE_MERCHANT_VOUCHERS = 'getgetOfflineMerchantVouchers';
const A_CREATE_OFFLINE_MERCHANT = 'createOfflineMerchant';
const A_CREATE_OFFLINE_MERCHANT_BRANCH = 'createOfflineMerchantBranch';
const A_UPDATE_OFFLINE_MERCHANT = 'updateOfflineMerchant';
const A_UPDATE_OFFLINE_MERCHANT_BRANCH = 'updateOfflineMerchantBranch';
const A_UPDATE_OFFLINE_MERCHANT_ACTIVATION = 'updateOfflineMerchantActivation';
const A_UPDATE_OFFLINE_MERCHANT_BRANCH_ACTIVATION =
  'updateOfflineMerchantBranchActivation';
const A_DELETE_OFFLINE_MERCHANT_BRANCH = 'deleteOfflineMerchantBranch';

/* Define Reset State Action Name */
const A_INITIAL_GET_OFFLINE_MERCHANTS_STATE = 'initialGetOfflineMerchantsState';
const A_INITIAL_GET_OFFLINE_MERCHANT_STATE = 'initialGetOfflineMerchantState';
const A_INITIAL_GET_OFFLINE_MERCHANT_BRANCH_STATE =
  'initialGetOfflineMerchantBranchState';
const A_INITIAL_GET_OFFLINE_MERCHANT_VOUCHERS_STATE =
  'initialGetOfflineMerchantVouchersState';
const A_INITIAL_CREATE_OFFLINE_MERCHANT_STATE =
  'initialCreateOfflineMerchantState';
const A_INITIAL_CREATE_OFFLINE_MERCHANT_BRANCH_STATE =
  'initialCreateOfflineMerchantBranchState';
const A_INITIAL_UPDATE_OFFLINE_MERCHANT_STATE =
  'initialUpdateOfflineMerchantState';
const A_INITIAL_UPDATE_OFFLINE_MERCHANT_BRANCH_STATE =
  'initialUpdateOfflineMerchantBranchState';
const A_INITIAL_UPDATE_OFFLINE_MERCHANT_ACTIVATION_STATE =
  'initialUpdateOfflineMerchantActivationState';
const A_INITIAL_UPDATE_OFFLINE_MERCHANT_BRANCH_ACTIVATION_STATE =
  'initialupdateOfflineMerchantBranchActivationState';
const A_INITIAL_DELETE_OFFLINE_MERCHANT_BRANCH_STATE =
  'initialDeleteOfflineMerchantBranchState';

/* Define Getter Name */

/* Define Mutation Name */
const M_GET_OFFLINE_MERCHANTS = 'setGetOfflineMerchants';
const M_GET_OFFLINE_MERCHANT = 'setGetOfflineMerchant';
const M_GET_OFFLINE_MERCHANT_BRANCH = 'setGetOfflineMerchantBranch';
const M_GET_OFFLINE_MERCHANT_VOUCHERS = 'setGetOfflineMerchantVouchers';
const M_CREATE_OFFLINE_MERCHANT = 'setCreateOfflineMerchant';
const M_CREATE_OFFLINE_MERCHANT_BRANCH = 'setCreateOfflineMerchantBranch';
const M_UPDATE_OFFLINE_MERCHANT = 'setUpdateOfflineMerchant';
const M_UPDATE_OFFLINE_MERCHANT_BRANCH = 'setUpdateOfflineMerchantBranch';
const M_UPDATE_OFFLINE_MERCHANT_ACTIVATION =
  'setUpdateOfflineMerchantActivation';
const M_UPDATE_OFFLINE_MERCHANT_BRANCH_ACTIVATION =
  'setupdateOfflineMerchantBranchActivation';
const M_DELETE_OFFLINE_MERCHANT_BRANCH = 'setDeleteOfflineMerchantBranch';

/* Define Reset State Mutation Name */
const M_INITIAL_GET_OFFLINE_MERCHANTS_STATE =
  'setInitialGetOfflineMerchantsState';
const M_INITIAL_GET_OFFLINE_MERCHANT_STATE =
  'setInitialGetOfflineMerchantState';
const M_INITIAL_GET_OFFLINE_MERCHANT_BRANCH_STATE =
  'setInitialGetOfflineMerchantBranchState';
const M_INITIAL_GET_OFFLINE_MERCHANT_VOUCHERS_STATE =
  'setInitialGetOfflineMerchantVouchersState';
const M_INITIAL_CREATE_OFFLINE_MERCHANT_STATE =
  'setInitialCreateOfflineMerchantState';
const M_INITIAL_CREATE_OFFLINE_MERCHANT_BRANCH_STATE =
  'setInitialCreateOfflineMerchantBranchState';
const M_INITIAL_UPDATE_OFFLINE_MERCHANT_STATE =
  'setInitialUpdateOfflineMerchantState';
const M_INITIAL_UPDATE_OFFLINE_MERCHANT_BRANCH_STATE =
  'setInitialUpdateOfflineMerchantBranchState';
const M_INITIAL_UPDATE_OFFLINE_MERCHANT_ACTIVATION_STATE =
  'setInitialUpdateOfflineMerchantActivationState';
const M_INITIAL_UPDATE_OFFLINE_MERCHANT_BRANCH_ACTIVATION_STATE =
  'setInitialupdateOfflineMerchantBranchActivationState';
const M_INITIAL_DELETE_OFFLINE_MERCHANT_BRANCH_STATE =
  'setInitialDeleteOfflineMerchantBranchState';

/* Define Export Name */
export const OFFLINE_MERCHANT_GET_OFFLINE_MERCHANTS =
  MODULE_NAME + A_GET_OFFLINE_MERCHANTS;
export const OFFLINE_MERCHANT_GET_OFFLINE_MERCHANT =
  MODULE_NAME + A_GET_OFFLINE_MERCHANT;
export const OFFLINE_MERCHANT_GET_OFFLINE_MERCHANT_BRANCH =
  MODULE_NAME + A_GET_OFFLINE_MERCHANT_BRANCH;
export const OFFLINE_MERCHANT_GET_OFFLINE_MERCHANT_VOUCHERS =
  MODULE_NAME + A_GET_OFFLINE_MERCHANT_VOUCHERS;
export const OFFLINE_MERCHANT_CREATE_OFFLINE_MERCHANT =
  MODULE_NAME + A_CREATE_OFFLINE_MERCHANT;
export const OFFLINE_MERCHANT_CREATE_OFFLINE_MERCHANT_BRANCH =
  MODULE_NAME + A_CREATE_OFFLINE_MERCHANT_BRANCH;
export const OFFLINE_MERCHANT_UPDATE_OFFLINE_MERCHANT =
  MODULE_NAME + A_UPDATE_OFFLINE_MERCHANT;
export const OFFLINE_MERCHANT_UPDATE_OFFLINE_MERCHANT_BRANCH =
  MODULE_NAME + A_UPDATE_OFFLINE_MERCHANT_BRANCH;
export const OFFLINE_MERCHANT_UPDATE_OFFLINE_MERCHANT_ACTIVATION =
  MODULE_NAME + A_UPDATE_OFFLINE_MERCHANT_ACTIVATION;
export const OFFLINE_MERCHANT_UPDATE_OFFLINE_MERCHANT_BRANCH_ACTIVATION =
  MODULE_NAME + A_UPDATE_OFFLINE_MERCHANT_BRANCH_ACTIVATION;
export const OFFLINE_MERCHANT_DELETE_OFFLINE_MERCHANT_BRANCH =
  MODULE_NAME + A_DELETE_OFFLINE_MERCHANT_BRANCH;

/* Define Reset State Name */
export const OFFLINE_MERCHANT_INITIAL_GET_OFFLINE_MERCHANTS_STATE =
  MODULE_NAME + A_INITIAL_GET_OFFLINE_MERCHANTS_STATE;
export const OFFLINE_MERCHANT_INITIAL_GET_OFFLINE_MERCHANT_STATE =
  MODULE_NAME + A_INITIAL_GET_OFFLINE_MERCHANT_STATE;
export const OFFLINE_MERCHANT_INITIAL_GET_OFFLINE_MERCHANT_BRANCH_STATE =
  MODULE_NAME + A_INITIAL_GET_OFFLINE_MERCHANT_BRANCH_STATE;
export const OFFLINE_MERCHANT_INITIAL_GET_OFFLINE_MERCHANT_VOUCHERS_STATE =
  MODULE_NAME + A_INITIAL_GET_OFFLINE_MERCHANT_VOUCHERS_STATE;
export const OFFLINE_MERCHANT_INITIAL_CREATE_OFFLINE_MERCHANT_STATE =
  MODULE_NAME + A_INITIAL_CREATE_OFFLINE_MERCHANT_STATE;
export const OFFLINE_MERCHANT_INITIAL_CREATE_OFFLINE_MERCHANT_BRANCH_STATE =
  MODULE_NAME + A_INITIAL_CREATE_OFFLINE_MERCHANT_BRANCH_STATE;
export const OFFLINE_MERCHANT_INITIAL_UPDATE_OFFLINE_MERCHANT_STATE =
  MODULE_NAME + A_INITIAL_UPDATE_OFFLINE_MERCHANT_STATE;
export const OFFLINE_MERCHANT_INITIAL_UPDATE_OFFLINE_MERCHANT_BRANCH_STATE =
  MODULE_NAME + A_INITIAL_UPDATE_OFFLINE_MERCHANT_BRANCH_STATE;
export const OFFLINE_MERCHANT_INITIAL_UPDATE_OFFLINE_MERCHANT_ACTIVATION_STATE =
  MODULE_NAME + A_INITIAL_UPDATE_OFFLINE_MERCHANT_ACTIVATION_STATE;
export const OFFLINE_MERCHANT_INITIAL_UPDATE_OFFLINE_MERCHANT_BRANCH_ACTIVATION_STATE =
  MODULE_NAME + A_INITIAL_UPDATE_OFFLINE_MERCHANT_BRANCH_ACTIVATION_STATE;
export const OFFLINE_MERCHANT_INITIAL_DELETE_OFFLINE_MERCHANT_BRANCH_STATE =
  MODULE_NAME + A_INITIAL_DELETE_OFFLINE_MERCHANT_BRANCH_STATE;

const state = {
  offlineMerchants: {
    code: 0,
    complete: false,
    data: null,
    message: null,
    pagination: {
      currentPage: 0,
      total: 0,
      perPage: 0,
      lastPage: 0
    }
  },
  offlineMerchant: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  offlineMerchantBranch: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  offlineMerchantVouchers: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  createOfflineMerchant: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  createOfflineMerchantBranch: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  updateOfflineMerchant: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  updateOfflineMerchantBranch: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  updateOfflineMerchantActivation: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  updateOfflineMerchantBranchActivation: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  deleteOfflineMerchantBranch: {
    code: 0,
    complete: false,
    data: null,
    message: null
  }
};

//Getters
const getters = {};

// Actions
const actions = {
  [A_GET_OFFLINE_MERCHANTS]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    OFFLINE_MERCHANT.getOfflineMerchantsList(data)
      .then((response) => {
        result = response;
        commit(M_GET_OFFLINE_MERCHANTS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_OFFLINE_MERCHANTS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_OFFLINE_MERCHANT]({ dispatch, commit }, { id }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    OFFLINE_MERCHANT.getOfflineMerchantDetails(id)
      .then((response) => {
        result = response;
        commit(M_GET_OFFLINE_MERCHANT, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_OFFLINE_MERCHANT, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_OFFLINE_MERCHANT_BRANCH]({ dispatch, commit }, { id }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    OFFLINE_MERCHANT.getOfflineMerchantBranchDetails(id)
      .then((response) => {
        result = response;
        commit(M_GET_OFFLINE_MERCHANT_BRANCH, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_OFFLINE_MERCHANT_BRANCH, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_OFFLINE_MERCHANT_VOUCHERS]({ dispatch, commit }, { id }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    OFFLINE_MERCHANT.getOfflineMerchantVouchers(id)
      .then((response) => {
        result = response;
        commit(M_GET_OFFLINE_MERCHANT_VOUCHERS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_OFFLINE_MERCHANT_VOUCHERS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_CREATE_OFFLINE_MERCHANT]({ dispatch, commit }, { formData }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    OFFLINE_MERCHANT.createOfflineMerchant(formData)
      .then((response) => {
        result = response;
        commit(M_CREATE_OFFLINE_MERCHANT, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_CREATE_OFFLINE_MERCHANT, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_CREATE_OFFLINE_MERCHANT_BRANCH]({ dispatch, commit }, { id, data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    OFFLINE_MERCHANT.createOfflineMerchantBranch(id, data)
      .then((response) => {
        result = response;
        commit(M_CREATE_OFFLINE_MERCHANT_BRANCH, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_CREATE_OFFLINE_MERCHANT_BRANCH, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_UPDATE_OFFLINE_MERCHANT]({ dispatch, commit }, { id, formData }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    OFFLINE_MERCHANT.updateOfflineMerchant(id, formData)
      .then((response) => {
        result = response;
        commit(M_UPDATE_OFFLINE_MERCHANT, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_UPDATE_OFFLINE_MERCHANT, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_UPDATE_OFFLINE_MERCHANT_BRANCH](
    { dispatch, commit },
    { id, data, branchId }
  ) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    OFFLINE_MERCHANT.updateOfflineMerchantBranch(id, data, branchId)
      .then((response) => {
        result = response;
        commit(M_UPDATE_OFFLINE_MERCHANT_BRANCH, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_UPDATE_OFFLINE_MERCHANT_BRANCH, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_UPDATE_OFFLINE_MERCHANT_ACTIVATION]({ dispatch, commit }, { id, data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    OFFLINE_MERCHANT.updateOfflineMerchantActivation(id, data)
      .then((response) => {
        result = response;
        commit(M_UPDATE_OFFLINE_MERCHANT_ACTIVATION, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_UPDATE_OFFLINE_MERCHANT_ACTIVATION, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_UPDATE_OFFLINE_MERCHANT_BRANCH_ACTIVATION](
    { dispatch, commit },
    { id, data, branchId }
  ) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    OFFLINE_MERCHANT.updateOfflineMerchantBranchActivation(id, data, branchId)
      .then((response) => {
        result = response;
        commit(M_UPDATE_OFFLINE_MERCHANT_BRANCH_ACTIVATION, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_UPDATE_OFFLINE_MERCHANT_BRANCH_ACTIVATION, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_DELETE_OFFLINE_MERCHANT_BRANCH]({ dispatch, commit }, { id, branchId }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    OFFLINE_MERCHANT.deleteOfflineMerchantBranch(id, branchId)
      .then((response) => {
        result = response;
        commit(M_DELETE_OFFLINE_MERCHANT_BRANCH, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_DELETE_OFFLINE_MERCHANT_BRANCH, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_INITIAL_GET_OFFLINE_MERCHANTS_STATE]({ commit }) {
    commit(M_INITIAL_GET_OFFLINE_MERCHANTS_STATE);
  },
  [A_INITIAL_GET_OFFLINE_MERCHANT_STATE]({ commit }) {
    commit(M_INITIAL_GET_OFFLINE_MERCHANT_STATE);
  },
  [A_INITIAL_GET_OFFLINE_MERCHANT_BRANCH_STATE]({ commit }) {
    commit(M_INITIAL_GET_OFFLINE_MERCHANT_BRANCH_STATE);
  },
  [A_INITIAL_GET_OFFLINE_MERCHANT_VOUCHERS_STATE]({ commit }) {
    commit(M_INITIAL_GET_OFFLINE_MERCHANT_VOUCHERS_STATE);
  },
  [A_INITIAL_CREATE_OFFLINE_MERCHANT_STATE]({ commit }) {
    commit(M_INITIAL_CREATE_OFFLINE_MERCHANT_STATE);
  },
  [A_INITIAL_CREATE_OFFLINE_MERCHANT_BRANCH_STATE]({ commit }) {
    commit(M_INITIAL_CREATE_OFFLINE_MERCHANT_BRANCH_STATE);
  },
  [A_INITIAL_UPDATE_OFFLINE_MERCHANT_STATE]({ commit }) {
    commit(M_INITIAL_UPDATE_OFFLINE_MERCHANT_STATE);
  },
  [A_INITIAL_UPDATE_OFFLINE_MERCHANT_BRANCH_STATE]({ commit }) {
    commit(M_INITIAL_UPDATE_OFFLINE_MERCHANT_BRANCH_STATE);
  },
  [A_INITIAL_UPDATE_OFFLINE_MERCHANT_ACTIVATION_STATE]({ commit }) {
    commit(M_INITIAL_UPDATE_OFFLINE_MERCHANT_ACTIVATION_STATE);
  },
  [A_INITIAL_UPDATE_OFFLINE_MERCHANT_BRANCH_ACTIVATION_STATE]({ commit }) {
    commit(M_INITIAL_UPDATE_OFFLINE_MERCHANT_BRANCH_ACTIVATION_STATE);
  },
  [A_INITIAL_DELETE_OFFLINE_MERCHANT_BRANCH_STATE]({ commit }) {
    commit(M_INITIAL_DELETE_OFFLINE_MERCHANT_BRANCH_STATE);
  }
};

// Mutations
const mutations = {
  [M_GET_OFFLINE_MERCHANTS](state, result) {
    if (result.code === 0) {
      let d = result.data;
      let p = result.pagination;

      state.offlineMerchants = {
        code: result.code,
        complete: true,
        data: d.map((x, index) => {
          return {
            no: index + 1,
            id: x.id,
            businessName: x.name,
            cuisineType: cuisineType(x.cuisine_type),
            totalBranch: x.total_branch,
            status: x.status
          };
        }),
        message: null,
        pagination: {
          currentPage: p.current_page,
          total: p.total,
          perPage: p.per_page,
          lastPage: p.last_page
        }
      };
    } else {
      state.offlineMerchants = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message),
        pagination: {
          currentPage: 0,
          total: 0,
          perPage: 0,
          lastPage: 0
        }
      };
    }
  },
  [M_GET_OFFLINE_MERCHANT](state, result) {
    if (result.code === 0) {
      let d = result.data;

      let contactPerson1 = '';
      let contactNumber1 = '';
      let contactPerson2 = '';
      let contactNumber2 = '';

      if (d.additional_contact) {
        if (d.additional_contact[0]) {
          contactPerson1 = d.additional_contact[0].contact_name;
          contactNumber1 = d.additional_contact[0].contact_number?.slice(1);
        }
        if (d.additional_contact[1]) {
          contactPerson2 = d.additional_contact[1].contact_name;
          contactNumber2 = d.additional_contact[1].contact_number?.slice(1);
        }
      }

      state.offlineMerchant = {
        code: result.code,
        complete: true,
        data: {
          id: d.id,
          businessName: d.name,
          businessLogo: d.logo,
          businessBanner: d.banner,
          contactPerson1: contactPerson1,
          contactNumber1: contactNumber1,
          contactPerson2: contactPerson2,
          contactNumber2: contactNumber2,
          description: d.description,
          serviceType: 1,
          pinVerification: d.pin_verification,
          cuisineType: d.cuisine_type,
          websiteLink: d.website_link,
          totalVoucher: d.total_voucher,
          status: d.status
        },
        message: null
      };
    } else {
      state.offlineMerchant = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_GET_OFFLINE_MERCHANT_BRANCH](state, result) {
    if (result.code === 0) {
      let d = result.data;
      let p = result.pagination;

      state.offlineMerchantBranch = {
        code: result.code,
        complete: true,
        data: d.map((x) => {
          let contactPerson2 = '';
          let contactNumber2 = '';
          let contactPerson3 = '';
          let contactNumber3 = '';

          if (x.additional_contact[0]) {
            contactPerson2 = x.additional_contact[0].contact_name;
            contactNumber2 = x.additional_contact[0].contact_number?.slice(1);
          }
          if (x.additional_contact[1]) {
            contactPerson3 = x.additional_contact[1].contact_name;
            contactNumber3 = x.additional_contact[1].contact_number?.slice(1);
          }

          return {
            id: x.id,
            merchant_id: x.merchant_id,
            registerCompanyName: x.register_company_name,
            businessNumber: x.business_number,
            addressName: x.place_name,
            streetAddress: x.full_address,
            addressDetails: x.address_details,
            hideBusinessAddressInProfilePage: x.hide_address,
            contactPerson1: x.contact_name,
            contactNumber1: x.contact_number?.slice(1),
            contactPerson2: contactPerson2,
            contactNumber2: contactNumber2,
            contactPerson3: contactPerson3,
            contactNumber3: contactNumber3,
            additionalContact: x.additional_contact
              ? x.additional_contact.map((y) => {
                  return {
                    contactPerson: y.contact_name,
                    contactNumber: y.contact_number?.slice(1)
                  };
                })
              : '-',
            email: x.email,
            pinVerification: x.pin_verification,
            status: x.status,
            statusDescription: x.status_description
          };
        }),
        message: null,
        pagination: {
          currentPage: p.current_page,
          total: p.total,
          perPage: p.per_page,
          lastPage: p.last_page
        }
      };
    } else {
      state.offlineMerchantBranch = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message),
        pagination: {
          currentPage: 0,
          total: 0,
          perPage: 0,
          lastPage: 0
        }
      };
    }
  },
  [M_GET_OFFLINE_MERCHANT_VOUCHERS](state, result) {
    if (result.code === 0) {
      let d = result.data;
      let p = result.pagination;
      state.offlineMerchantVouchers = {
        code: result.code,
        complete: true,
        data: d.map((x, index) => {
          return {
            no: index + 1,
            id: x.id,
            eventName: x.voucher_merchant.event_name,
            voucherTitle: x.title,
            voucherType: constantsDescription('serviceType', x.voucher_type),
            quantity: x.voucher_merchant.quantity,
            minimumSpending: x.minimum_spending,
            status: x.voucher_merchant.status,
            totalClaim: x.total_claim,
            totalRedemption: x.total_redeem,
            availableFrom: dateFormat(x.voucher_merchant.available_from),
            availableTo: dateFormat(x.voucher_merchant.available_to)
          };
        }),
        message: null,
        pagination: {
          currentPage: p.current_page,
          total: p.total,
          perPage: p.per_page,
          lastPage: p.last_page
        }
      };
    } else {
      state.offlineMerchantVouchers = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message),
        pagination: {
          currentPage: 0,
          total: 0,
          perPage: 0,
          lastPage: 0
        }
      };
    }
  },
  [M_CREATE_OFFLINE_MERCHANT](state, result) {
    if (result.code === 0) {
      let d = result.data;
      state.createOfflineMerchant = {
        code: result.code,
        complete: true,
        data: {
          id: d.id,
          businessName: d.name,
          businessLogo: d.logo,
          businessBanner: d.banner,
          description: d.description,
          serviceType: 1,
          pinVerification: d.pin_verification,
          cuisineType: d.cuisine_type,
          websiteLink: d.website_link,
          status: d.status
        },
        message: apiHelper.sortOutMessage(result.message)
      };
    } else {
      state.createOfflineMerchant = {
        code: result.code,
        complete: true,
        data: result,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_CREATE_OFFLINE_MERCHANT_BRANCH](state, result) {
    state.createOfflineMerchantBranch = {
      code: result.code,
      complete: true,
      data: result,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_UPDATE_OFFLINE_MERCHANT](state, result) {
    state.updateOfflineMerchant = {
      code: result.code,
      complete: true,
      data: result,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_UPDATE_OFFLINE_MERCHANT_BRANCH](state, result) {
    state.updateOfflineMerchantBranch = {
      code: result.code,
      complete: true,
      data: result,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_UPDATE_OFFLINE_MERCHANT_ACTIVATION](state, result) {
    state.updateOfflineMerchantActivation = {
      code: result.code,
      complete: true,
      data: result,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_UPDATE_OFFLINE_MERCHANT_BRANCH_ACTIVATION](state, result) {
    state.updateOfflineMerchantBranchActivation = {
      code: result.code,
      complete: true,
      data: result,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_DELETE_OFFLINE_MERCHANT_BRANCH](state, result) {
    state.deleteOfflineMerchantBranch = {
      code: result.code,
      complete: true,
      data: result,
      message: apiHelper.sortOutMessage(result.message)
    };
  },
  [M_INITIAL_GET_OFFLINE_MERCHANTS_STATE](state) {
    state.offlineMerchants = {
      code: 0,
      complete: false,
      data: null,
      message: null,
      pagination: {
        currentPage: 0,
        total: 0,
        perPage: 0,
        lastPage: 0
      }
    };
  },
  [M_INITIAL_GET_OFFLINE_MERCHANT_STATE](state) {
    state.offlineMerchant = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_GET_OFFLINE_MERCHANT_BRANCH_STATE](state) {
    state.offlineMerchantBranch = {
      code: 0,
      complete: false,
      data: null,
      message: null,
      pagination: {
        currentPage: 0,
        total: 0,
        perPage: 0,
        lastPage: 0
      }
    };
  },
  [M_INITIAL_GET_OFFLINE_MERCHANT_VOUCHERS_STATE](state) {
    state.offlineMerchantVouchers = {
      code: 0,
      complete: false,
      data: null,
      message: null,
      pagination: {
        currentPage: 0,
        total: 0,
        perPage: 0,
        lastPage: 0
      }
    };
  },
  [M_INITIAL_CREATE_OFFLINE_MERCHANT_STATE](state) {
    state.createOfflineMerchant = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_CREATE_OFFLINE_MERCHANT_BRANCH_STATE](state) {
    state.createOfflineMerchantBranch = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_UPDATE_OFFLINE_MERCHANT_STATE](state) {
    state.updateOfflineMerchant = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_UPDATE_OFFLINE_MERCHANT_BRANCH_STATE](state) {
    state.updateOfflineMerchantBranch = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_UPDATE_OFFLINE_MERCHANT_ACTIVATION_STATE](state) {
    state.updateOfflineMerchantActivation = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_UPDATE_OFFLINE_MERCHANT_BRANCH_ACTIVATION_STATE](state) {
    state.updateOfflineMerchantBranchActivation = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_DELETE_OFFLINE_MERCHANT_BRANCH_STATE](state) {
    state.deleteOfflineMerchantBranch = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export function cuisineType(value) {
  let description;
  let newArray = [];

  value.forEach((x) => {
    newArray.push(getCuisineType(x));
  });
  description = newArray.join(', ');
  return description;
}

function getCuisineType(value) {
  if (value == 1) {
    // CuisineType.WESTERN
    return i18nHelper.getMessage(`enumCuisineType.${1}`);
  } else {
    // CuisineType.ASIAN
    return i18nHelper.getMessage(`enumCuisineType.${2}`);
  }
}
