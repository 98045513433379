<template>
  <div>
    <div class="card app-smart-list p-8">
      <b-card-body class="border-0 p-0">
        <div class="px-7">
          <b-form>
            <div class="row justify-content-center">
              <div class="my-2 col-md-9 col-lg-8 col-xl-7">
                <div class="row">
                  <div class="text-md-right col-sm-3 col-12"></div>
                  <div class="col-sm-9 col-12">
                    <h6
                      class="text-dark font-weight-bold mb-10 text-capitalize"
                    >
                      {{ $t('label.experienceCooking') }}:
                    </h6>
                  </div>
                </div>
                <div class="row mb-5">
                  <div class="text-md-right col-sm-3 col-12">
                    <label for="title" class="text-capitalize">Title</label>
                  </div>
                  <div class="col-sm-9 col-12">
                    <textarea
                      v-model="title"
                      class="form-control form-control form-control-md form-control-solid"
                    ></textarea>
                  </div>
                </div>
                <div class="row mb-5">
                  <div class="text-md-right col-sm-3 col-12">
                    <label for="description" class="text-capitalize"
                      >Description</label
                    >
                  </div>
                  <div class="col-sm-9 col-12">
                    <textarea
                      v-model="description"
                      class="form-control form-control form-control-md form-control-solid"
                    ></textarea>
                  </div>
                </div>
                <div class="row mb-5">
                  <div class="text-md-right col-sm-3 col-12">
                    <label for="titlefontcolor" class="text-capitalize"
                      >Title Font Color</label
                    >
                  </div>
                  <div class="col-sm-9 col-12">
                    <AppColorPicker :color="fontColor" v-model="fontColor" />
                  </div>
                </div>
                <div class="row mb-5">
                  <div class="text-md-right col-sm-3 col-12">
                    <label for="descriptionfontcolor" class="text-capitalize"
                      >Description Font Color</label
                    >
                  </div>
                  <div class="col-sm-9 col-12">
                    <AppColorPicker
                      :color="descriptionColor"
                      v-model="descriptionColor"
                    />
                  </div>
                </div>
                <div class="row mb-5">
                  <div class="text-md-right col-sm-3 col-12">
                    <label for="image" class="text-capitalize">Image</label>
                  </div>
                  <div class="col-sm-9 col-12">
                    <div
                      v-if="!image"
                      class="custom-file b-form-file app-form-field__file-field d-inline-flex b-custom-control-md"
                    >
                      <input
                        type="file"
                        accept="image/jpeg,image/jpg,image/png,image/gif"
                        class="custom-file-input"
                        style="z-index: -5"
                        id="image"
                        @change="onFileChange"
                      /><label
                        data-browse="Browse"
                        class="custom-file-label"
                        for="image"
                        ><span class="d-block form-file-text"
                          >No file chosen</span
                        ></label
                      >
                    </div>
                    <div v-else>
                      <div class="imagePreview">
                        <button
                          type="button"
                          class="btn position-absolute media-delete-btn btn-danger rounded-0"
                          @click="removeImage"
                        >
                          <i class="flaticon-delete p-0"></i>
                        </button>
                        <img class="imagePreview" :src="image" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="text-md-right col-sm-3 col-12">
                    <label class="text-capitalize"></label>
                  </div>
                  <div class="col-sm-9 col-12">
                    <button
                      type="button"
                      class="btn font-weight-bold btn-light-primary"
                      @click="saveChange"
                      :disabled="disabled"
                    >
                      {{ $t('label.saveChanges') }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </b-form>
        </div>
      </b-card-body>
    </div>
  </div>
</template>
<script>
  import commonMixin from '@/core/mixins/common.mixin';
  import { i18nHelper } from '@/core/utils';
  import { apiService } from '@/core/services';
  import AppColorPicker from '@/views/components/bases/AppColorPicker';
  import {
    LOADING_OPEN_LOADING,
    LOADING_CLOSE_LOADING
  } from '@/core/store/loading.module';

  export default {
    name: 'Home-boards-experience-cooking',
    components: {
      AppColorPicker
    },
    mixins: [commonMixin],
    created() {
      this.initialPlatformInfo();
    },
    data: () => ({
      title: '',
      description: '',
      fontColor: '',
      descriptionColor: '',
      image: '',
      imageFile: null,
      disabled: false
    }),
    methods: {
      initialPlatformInfo() {
        let breadcrumbData = {
          breadcrumbs: [
            {
              title: i18nHelper.getMessage('label.homePageButton')
            },
            {
              title: i18nHelper.getMessage('label.experienceCooking')
            }
          ]
        };
        this.initBreadCrumb(breadcrumbData);
        this.getHomePage();
      },
      getHomePage() {
        this.$store.dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });

        apiService()
          .get('/admin/home-board/2')
          .then((response) => {
            this.$store.dispatch(`${LOADING_CLOSE_LOADING}`, null, {
              root: true
            });

            if (response.code == 0) {
              this.title = response.data.title;
              this.description = response.data.description;
              this.fontColor = response.data.title_font_color;
              this.descriptionColor = response.data.description_font_color;
              this.image = response.data.media_path;
            }
          });
      },
      saveChange() {
        this.$store.dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
        this.disabled = true;

        let formData = new FormData();
        formData.set('title', this.title);
        formData.append('description', this.description);
        formData.append('title_font_color', this.fontColor);
        formData.append('desc_font_color', this.descriptionColor);

        if (this.imageFile) {
          formData.append('media_path', this.imageFile);
        }

        apiService()
          .post('/admin/home-board/2', formData)
          .then((response) => {
            this.disabled = false;
            this.$store.dispatch(`${LOADING_CLOSE_LOADING}`, null, {
              root: true
            });

            if (response.code == 0) {
              let title = i18nHelper.getMessage('label.message');
              let messages = [];
              let buttons = [];

              messages.push(response.message);

              buttons.push({
                text: i18nHelper.getMessage('label.ok'),
                variant: 'primary',
                action: () => {
                  this.closeLayoutAppModalInfo();
                }
              });
              this.openLayoutAppModalInfo(title, messages, buttons);
            }
          });
      },
      onFileChange(e) {
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length) return;
        this.createImage(files[0]);
      },
      createImage(file) {
        this.imageFile = file;
        var reader = new FileReader(),
          vm = this;

        reader.onload = (e) => {
          vm.image = e.target.result;
        };
        reader.readAsDataURL(file);
      },
      removeImage: function () {
        this.image = '';
      }
    }
  };
</script>
<style>
  .imagePreview {
    max-width: 300px;
    max-height: 300px;
    position: relative;
  }
  .imagePreview .media-delete-btn {
    right: 0;
    z-index: 100;
  }
</style>
