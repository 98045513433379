<template>
  <ReviewAndRatingList
    :breadcrumbData="breadcrumbData"
    :actions="actions"
    :accessRights="accessRights"
    :headers="headers"
  ></ReviewAndRatingList>
</template>

<script>
  import ReviewAndRatingList from '@/views/components/review-and-rating/ReviewAndRatingList';
  import {
    ROUTE_NAME,
    MODULE,
    ACTION,
    APP_COMPONENT_NAME
  } from '@/core/constants';
  import { i18nHelper } from '@/core/utils';
  import {
    RECIPE_REVIEW_GET_RECIPE_REVIEWS,
    RECIPE_REVIEW_INITIAL_RECIPE_REVIEWS_STATE
  } from '@/core/store/recipe-review.module';

  export default {
    name: 'ReviewAndRatingRecipeList',
    components: {
      ReviewAndRatingList
    },
    data: () => ({
      breadcrumbData: {
        breadcrumbs: [
          {
            title: i18nHelper.getMessage('label.reviewAndRating'),
            route: { name: ROUTE_NAME.REVIEW_AND_RATING }
          },
          {
            title: i18nHelper.getMessage('label.experienceCooking'),
            route: { name: ROUTE_NAME.REVIEW_AND_RATING_EXPERIENCE_COOKING }
          },
          {
            title: i18nHelper.getMessage(
              'label.experienceCookingReviewAndRatingList'
            )
          }
        ]
      },
      accessRights: {
        moduleName: MODULE.RECIPE_REVIEW,
        actions: {
          create: ACTION.RECIPE_REVIEW_CREATE,
          read: ACTION.RECIPE_REVIEW_READ,
          update: ACTION.RECIPE_REVIEW_UPDATE,
          delete: ACTION.RECIPE_REVIEW_DELETE
        }
      },
      actions: {
        viewList: {
          module: 'recipeReview',
          state: 'recipeReviews',
          action: RECIPE_REVIEW_GET_RECIPE_REVIEWS,
          initialStateAction: RECIPE_REVIEW_INITIAL_RECIPE_REVIEWS_STATE,
          title: i18nHelper.getMessage('label.getExperienceCookingReviewList')
        }
      },
      headers: [
        {
          key: 'no',
          name: 'no',
          active: true,
          sortable: true
        },
        {
          key: 'itemName',
          name: 'itemName',
          active: true,
          sortable: true,
          class: 'min-w-100px'
        },
        {
          key: 'description',
          name: 'review',
          active: true,
          sortable: true,
          layout: APP_COMPONENT_NAME.APP_TEXT_TRUNCATE_CONTAINER
        },
        {
          key: 'rating',
          name: 'rating',
          active: true,
          sortable: true
        },
        {
          key: 'username',
          name: 'createdBy',
          active: true,
          sortable: true
        },
        {
          key: 'createdAt',
          name: 'createdAt',
          active: true,
          sortable: true
        },
        {
          key: 'isVisible',
          name: 'visibility',
          active: true,
          sortable: true,
          layout: APP_COMPONENT_NAME.APP_CHECK_CIRCLE
        },
        {
          key: 'actions',
          name: 'actions',
          active: true,
          action: true,
          layout: APP_COMPONENT_NAME.APP_TABLE_ACTIONS,
          layoutActions: {
            viewDetailsRouteName:
              ROUTE_NAME.REVIEW_AND_RATING_EXPERIENCE_COOKING_DETAILS
          }
        }
      ]
    })
  };
</script>

<style></style>
