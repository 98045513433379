<template>
  <div>
    <b-input-group>
      <b-form-select
          v-model="selectedOptions"
          placeholder="Please select"
      >
        <b-form-select-option value="">{{ $t('label.pleaseSelect') }}</b-form-select-option>
        <b-form-select-option v-for="option in options" :value="option.value" v-bind:key="option.value">{{ option.text }}</b-form-select-option>
      </b-form-select>
      <b-input-group-append>
        <b-button variant="outline-secondary" size="md" @click="onSave()">Save</b-button>
      </b-input-group-append>
    </b-input-group>
  </div>
</template>

<script>
import commonMixin from "@/core/mixins/common.mixin";

  export default {
    name: 'AppDropdownWithButton',
    mixins: [commonMixin],
    props: {
      id: {
        type: Number,
        default: null
      },
      itemKey: {
        type: String,
        default: ''
      },
      value: {
        type: Number,
        default: null
      },
      options: {
        type: Array,
        default: () => []
      },
      saveActionsOnj: {
        type: Object,
        default: () => {
          return {
            module: '',
            state: '',
            actionSet: '',
            actionInitial: '',
            successAction: () => {},
            errorActionL: () => {},
            title: ''
          };
        }
      }
    },
    data: () => ({
      selectedOptions: null
    }),
    computed: {
      updateComplete() {
        let obj = this.saveActionsOnj;

        return this.$store.state[obj.module][obj.state].complete;
      }
    },
    watch: {
      updateComplete() {
        let obj = this.switchActionsOnj;
        let response = this.$store.state[obj.module][obj.state];
        let title = obj.title;
        let initialStateAction = obj.actionInitial;
        let successAction = obj.successAction;
        let errorAction = obj.errorAction;

        if (response.complete) {
          this.actionUpdateRecordCompleteWithoutSuccessModal(
              response,
              title,
              initialStateAction,
              successAction,
              errorAction
          );
        }
      }
    },
    mounted() {
      this.selectedOptions = this.value;
    },
    methods: {
      onSave() {
        let id = this.id
        let data = {
          [this.itemKey]: this.selectedOptions
        };
        this.$store.dispatch(this.saveActionsOnj.actionSet, { id, data });
      }
    }
  };
</script>

<style>
</style>
