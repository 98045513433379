const BannerActionType = {
  NONE: 0,
  RECIPE: 1,
  WEBVIEW: 2,
  PRODUCT: 3,
  CREATOR: 4,
  COOKIE: 5,
  VOUCHER: 6,
  RECIPE_PAGE: 7
};

export { BannerActionType };
